import React from 'react'

import type { Message } from '../../types'

const NewMessageContext = React.createContext({
  message: {},
}) as React.Context<{
  message: Message
}>

export default NewMessageContext
