import React from 'react'
import { useTranslation } from 'react-i18next'

import { useCollectionGames } from '@/modules/my-games/useGameGrid'

import { trackHomeToGameEvt } from '../trackHomeToGameEvt'
import { GameGridWithLoadMoreMemo } from './GameGridWithLoadMoreMemo'
import { BuiltInGameLabel } from './types'

function FavoritedGameGrid(props: { goAlternate?: () => void }) {
  const { goAlternate } = props
  const { t } = useTranslation()
  const collectionGames = useCollectionGames()

  return (
    <GameGridWithLoadMoreMemo
      list={collectionGames}
      onItemClick={trackHomeToGameEvt}
      viewAllHref={`/game/all?cate=${BuiltInGameLabel.Favorite}&label=-1`}
      emptyProps={{
        onButtonClick: goAlternate,
        emptyText: String(t('NO_FAVORITE_GAME')),
      }}
    />
  )
}
export const FavoritedGameGridMemo = React.memo(FavoritedGameGrid)
