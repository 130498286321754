import * as Sentry from '@sentry/react'

import { isProduction } from '../env'

/* eslint-disable no-console */

/** 断言，如果truth != true 则输出 error message */
export function assert(truth: boolean, message?: string) {
  if (!truth) {
    error(`[assert failed]: ${message}`)
  }
}

/** debug会原样将数据传递给console，以方便打印对象 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debug(...args: any[]) {
  if (!isProduction()) {
    console.debug(...args)
  }
}

/** 日志内容只支持基础类型，方便日志序列化传输 */
type LogValue = string | number | boolean | null | undefined | any

/** 用于记录系统状态，信息 */
export function info(...args: LogValue[]) {
  if (isProduction()) {
    // TODO:
  } else {
    console.log(...args)
  }
}

/** 警告异常状态，但是不影响当前逻辑的执行 */
export function warn(...args: LogValue[]) {
  if (isProduction()) {
    // TODO:
  } else {
    console.warn(...args)
  }
}

/** 当前逻辑执行失败，但是不影响系统稳定 */
export function error(...args: LogValue[]) {
  if (isProduction()) {
    // TODO:
    Sentry.captureMessage('global_error', {
      extra: {
        ...args,
      },
    })
  } else {
    console.error(...args)
  }
}

/** 严重错误，系统无法正常运行 */
export function fatal(...args: LogValue[]) {
  if (isProduction()) {
    // TODO:
  } else {
    console.error(...args)
  }
}
