import type { IconProps, ModalProps } from '@chakra-ui/react'
import {
  Icon,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'

import { isFromIpa, judgeBigScreen } from '@/common/platform/utils'
import { Icon as SharedIcon } from '@/utils/atom-shared'

export type IDialog<P> = P & {
  isOpen: boolean
  onClose: () => void
  motionPreset?: Pick<ModalProps, 'motionPreset'>
  iconStyle?: IconProps
  maxH?: string
  maxW?: string
} & ModalProps

export type DialogProps = IDialog<PropsWithChildren>

export default function Dialog({
  motionPreset = 'none',
  iconStyle = {},
  ...props
}: DialogProps) {
  const closeBtnBgColor = 'gray.300'
  const closeIconColor = 'text.base'
  const closeIconColorMobile = 'text.base'
  const contentProps = {}
  !!props.maxH && Object.assign(contentProps, { maxH: props.maxH })
  !!props.maxW && Object.assign(contentProps, { maxW: props.maxW })

  return (
    <Modal
      autoFocus={false}
      trapFocus={false}
      isCentered
      initialFocusRef={undefined}
      {...props}
      isOpen={props.isOpen}
      onClose={props.onClose}
      motionPreset={motionPreset}
    >
      <ModalOverlay />
      <ModalContent
        overflowX={{
          base: 'hidden',
          '3xl': 'auto',
        }}
        {...contentProps}
      >
        <IconButton
          as={ModalCloseButton}
          icon={
            <Icon
              as={SharedIcon.Close}
              boxSize={7}
              color={{
                base: closeIconColorMobile,
                md: closeIconColor,
              }}
              {...iconStyle}
            />
          }
          aria-label='close'
          variant='unstyled'
          size='sm'
          display='flex'
          alignItems='center'
          justifyContent='center'
          position='absolute'
          right={{ base: '3', md: '-3' }}
          top={{
            base: isFromIpa() && judgeBigScreen() ? '10' : '1.5',
            md: isFromIpa() && judgeBigScreen() ? '10' : '-3',
          }}
          bgColor={{ base: 'none', md: closeBtnBgColor }}
          borderRadius={{ base: 'none', md: 'full' }}
          w={{ base: '6', md: '9' }}
          minW={{ base: '6', md: '9' }}
          h={{ base: '6', md: '9' }}
          _hover={{ bgColor: { base: 'none', md: closeBtnBgColor } }}
          _active={{ bgColor: { base: 'none', md: closeBtnBgColor } }}
        />
        {props.children}
      </ModalContent>
    </Modal>
  )
}
