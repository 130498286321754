import type { ComponentStyleConfig } from '@chakra-ui/react'

const NAME = 'PlinkAmountControl'
const parts = ['box', 'input', 'text', 'button', 'switch', 'switchTag']
const componentStyle: ComponentStyleConfig = {
  parts,
  baseStyle: {
    box: {
      bgColor: 'gray.400',
    },
    input: {
      color: 'prim.500',
    },
    button: {
      background: 'gray.500',
    },
    switch: {
      background: 'gray.500',
    },
  },
}

export const name = NAME
export default componentStyle
