import React, { useCallback } from 'react'
import type { unstable_BlockerFunction as BlockerFunction } from 'react-router-dom'
import { matchPath, unstable_useBlocker as useBlocker } from 'react-router-dom'

import { getVariable } from '@/common/env'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  getBindCurrency,
  setCallBackType,
  switchBindModal,
} from '@/modules/bind-currency/currencySlice'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { selectUserIsLogin } from '@/modules/user/userSlice'
import property from '@/websites/current/property.json'

import { setLoginAfterRedirect } from '../app-layout/app-layout-slice'

const SHOW_BIND_SELECT = getVariable('SHOW_BIND_SELECT')
const REGION_LIMIT_LIST = getVariable('REGION_LIMIT_LIST') ?? []

const supportCurrencies = property.locale.currencies ?? []

const LOGIN_PATH_PATTERNS = ['/user/*', '/referral/*', '/activity/ranking/*']
export function isPathNeedLogin(pathname: string) {
  return LOGIN_PATH_PATTERNS.some(pattern => {
    const match = matchPath(pattern, pathname)
    return !!match
  })
}

export function useCurrencyPrompt() {
  const dispatch = useAppDispatch()
  const boundCurrency = useAppSelector(getBindCurrency) ?? ''
  return React.useCallback(
    (callback: string) => {
      if (!boundCurrency) {
        dispatch(switchBindModal(undefined))
        dispatch(setCallBackType({ callBackType: callback }))
      } else if (!supportCurrencies.includes(boundCurrency)) {
        dispatch(openModal({ key: GlobalModalKey.RegionLimit }))
      }
    },
    [dispatch, boundCurrency],
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export function useCurrencyBindingBlocker() {
  const prompt = useCurrencyPrompt()
  const dispatch = useAppDispatch()
  const boundCurrency = useAppSelector(getBindCurrency) ?? ''
  const isLogin = !!useAppSelector(selectUserIsLogin)

  const blockForNoLogin = useCallback(
    (pathname: string) => isPathNeedLogin(pathname) && !isLogin,
    [isLogin],
  )

  const blockForNoBoundCurrency = useCallback(
    (pathname: string) =>
      Boolean(
        REGION_LIMIT_LIST.includes(pathname) &&
          isLogin &&
          SHOW_BIND_SELECT &&
          !supportCurrencies.includes(boundCurrency),
      ),
    [isLogin, boundCurrency],
  )

  const shouldBlock = React.useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      if (currentLocation.pathname !== nextLocation.pathname) {
        return (
          blockForNoLogin(nextLocation.pathname) ||
          blockForNoBoundCurrency(nextLocation.pathname)
        )
      } else {
        return false
      }
    },
    [blockForNoLogin, blockForNoBoundCurrency],
  )

  const blocker = useBlocker(shouldBlock)

  React.useEffect(() => {
    if (blocker.state === 'blocked') {
      if (blockForNoLogin(blocker.location.pathname)) {
        dispatch(setLoginAfterRedirect(blocker.location.pathname))
        dispatch(openModal({ key: GlobalModalKey.Login }))
      } else if (blockForNoBoundCurrency(blocker.location.pathname)) {
        dispatch(setLoginAfterRedirect(''))
        prompt(`href_${blocker.location.pathname}`)
      }
      blocker.reset()
    }
  }, [blocker, prompt, blockForNoLogin, blockForNoBoundCurrency, dispatch])
}
