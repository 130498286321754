import { Container } from '@chakra-ui/react'
import isMobile from 'ismobilejs'
import type { Ref } from 'react'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

type AtomFullScreenProps = {
  children: React.ReactNode | ((isFullScreen: boolean) => React.ReactNode)
  fullScreenInEnter?: boolean
}

export type AtomFullScreenRef = {
  enterFullScreen: () => void
  exitFullScreen: () => void
  toggleFullScreen: () => void
  isFullScreen: boolean
}

// 基础样式
const baseStyle = {
  position: 'fixed',
  top: '0px',
  left: '0px',
  bottom: '0px',
  right: '0px',
  width: '100vw',
  zIndex: 'var(--chakra-zIndices-modal)',
}

const AtomFullScreen = forwardRef(
  (props: AtomFullScreenProps, ref: Ref<AtomFullScreenRef>) => {
    const { children, fullScreenInEnter = false } = props

    const isMobileResult = isMobile(window.navigator)

    const isIOS = isMobileResult.apple.device

    const isAndroid = isMobileResult.android.device

    const inMobile = useMemo(() => isIOS || isAndroid, [isIOS, isAndroid])

    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      const isBodyOverflowHidden = document.body.style.overflow === 'hidden'
      if (inMobile) {
        document.body.style.overflow = 'hidden'
      } else {
        if (isBodyOverflowHidden) {
          document.body.style.overflow = 'auto'
        }
      }
      return () => {
        if (inMobile) {
          document.body.style.overflow = 'auto'
        }
      }
    }, [inMobile])

    //   是否处于全屏状态 ,
    const [isFullScreen, setIsFullScreen] = useState(false)

    //   容器样式
    const [containerStyle, setContainerStyle] = useState(() => {
      // 为了解决 浏览器模拟器下的全屏问题
      const platform = window.navigator.platform
      const isInPc =
        platform.indexOf('Win') > -1 || platform.indexOf('Mac') > -1
      if (isIOS) {
        return {
          ...baseStyle,
          minHeight: isInPc ? '100vh' : 'fill-available',
          height: 'fill-available',
        }
      }
      if (isAndroid) {
        return {
          ...baseStyle,
          height: '100%',
        }
      }
      return {}
    })

    //   处理全屏事件(进入全屏)
    const enterFullScreen = useCallback(() => {
      setIsFullScreen(true)
      if (inMobile) {
        // If on mobile, set the container style
        setContainerStyle(prev => {
          // If the previous style is empty, return a new style with baseStyle and height set to 100vh
          if (!Object.keys(prev).length) {
            return {
              ...baseStyle,
              height: '100vh',
            }
          }
          // Otherwise, return the previous style
          return prev
        })
      } else {
        containerRef && containerRef.current?.requestFullscreen()
      }
    }, [inMobile])

    //   退出全屏事件
    const exitFullScreen = useCallback(() => {
      setIsFullScreen(false)
      if (inMobile) {
        setContainerStyle({
          ...baseStyle,
          ...(isAndroid && { height: '100%' }),
          ...(isIOS && { minHeight: '100vh', height: 'fill-available' }),
        })
      } else {
        document.exitFullscreen && document.exitFullscreen()
      }
    }, [inMobile, isAndroid, isIOS])
    useEffect(() => {
      window.addEventListener('fullscreenchange', () => {
        if (!document.fullscreenElement) {
          exitFullScreen()
        } else {
          enterFullScreen()
        }
      })
      return () => {
        window.removeEventListener('fullscreenchange', () => {
          if (!document.fullscreenElement) {
            exitFullScreen()
          } else {
            enterFullScreen()
          }
        })
      }
    }, [enterFullScreen, exitFullScreen])

    useEffect(() => {
      if (fullScreenInEnter) {
        enterFullScreen()
      }
    }, [fullScreenInEnter, enterFullScreen])

    useImperativeHandle(ref, () => ({
      enterFullScreen,
      exitFullScreen,
      isFullScreen,
      toggleFullScreen: () => {
        if (isFullScreen) {
          exitFullScreen()
        } else {
          enterFullScreen()
        }
      },
    }))

    const renderChildren = () => {
      if (typeof children === 'function') {
        return children(isFullScreen)
      } else {
        return children
      }
    }

    return (
      <Container
        maxW='container.lg'
        padding='0px 0px'
        id='full-screen-container'
        ref={containerRef}
        position='relative'
        marginBottom='50px'
        {...containerStyle}
      >
        {renderChildren()}
      </Container>
    )
  },
)

AtomFullScreen.displayName = 'AtomFullScreen'

export default AtomFullScreen
