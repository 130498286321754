import dayjs from 'dayjs'
import { inRange, random } from 'lodash-es'

import { localStorage } from '@/utils/localStorage'

import { openModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { getUserId } from '../user/storage'
import { LoginModalTab } from './LoginModalTab'

export function isLoginModalTab(value: unknown): value is LoginModalTab {
  return typeof value === 'number' && value in LoginModalTab
}

const increaseList = [
  {
    start: 9,
    end: 17,
    increasePerson: [2, 6],
    increaseBouns: [1869, 5099],
  },
  {
    start: 17,
    end: 23.5,
    increasePerson: [1, 5],
    increaseBouns: [986, 3289],
  },
]

export function calcBonusAndPerson(basePerson: number, baseBonus: number) {
  const hours = new Date().getHours()

  const increasItem = increaseList.find(item =>
    inRange(hours, item.start, item.end),
  )
  if (increasItem) {
    const { increasePerson, increaseBouns } = increasItem
    basePerson += random(increasePerson[0], increasePerson[1])
    baseBonus += random(increaseBouns[0], increaseBouns[1])
  }
  return {
    person: basePerson,
    bouns: baseBonus,
  }
}
export function openTgModal(dispath: any) {
  const key = 'tgModalVisibleState'
  const dataStr = localStorage.getItem(key)
  const today = dayjs().format('YYYY-MM-DD')
  const userId = getUserId()

  if (dataStr) {
    const data = JSON.parse(dataStr)
    if (data[userId] !== today) {
      dispath(openModal({ key: GlobalModalKey.TgModal }))
    } else {
      localStorage.setItem('modalName', 'loginRewardModal')
    }
  } else {
    dispath(openModal({ key: GlobalModalKey.TgModal }))
  }
}

export function getVerifyCodeErrorMessage(code: string) {
  const errorMessageMap = {
    1003: 'VERIFY_CODE_ERR2',
    // 获取验证码过快
    1030: 'GET_VERIfY_CODE_TOO_FAST',
    // 获取验证码次数过多
    1031: 'VERIFY_CODE_ERR2',
  } as Record<string, string>

  return errorMessageMap[code] ?? ''
}

export function checkPasswordStrength(password: string) {
  const hasNumber = /\d/.test(password) // 检查是否包含数字
  const hasSymbol = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password) // 检查是否包含符号
  const hasEnglish = /[a-zA-Z]/.test(password) // 检查是否包含英文字母

  if (
    (hasNumber && !hasSymbol && !hasEnglish) ||
    (!hasNumber && hasSymbol && !hasEnglish) ||
    (!hasNumber && !hasSymbol && hasEnglish)
  ) {
    return 'high' // 只有一种组合，高风险状态
  }

  if (
    (hasNumber && hasSymbol && !hasEnglish) ||
    (hasNumber && !hasSymbol && hasEnglish) ||
    (!hasNumber && hasSymbol && hasEnglish)
  ) {
    return 'middle' // 任意两种组合，中风险状态
  }
  if (hasNumber && hasSymbol && hasEnglish) {
    return 'low' // 三种组合，低风险状态
  }

  return 'high' // 密码不符合要求
}
