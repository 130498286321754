import type { FC } from 'react'
import { useCallback, useMemo } from 'react'

import type { DialogProps } from '@/components/modal/Dialog'
import Dialog from '@/components/modal/Dialog'
import type { TitledDialogProps } from '@/components/modal/TitledDialog'
import TitledDialog from '@/components/modal/TitledDialog'
import { hide, show } from '@/modules/app/modalSlice'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'

import type { PickPartial } from '../typecast'

interface UseModalReturn {
  /** 是否销毁 */
  destoryed: boolean

  /** just hiding */
  visible: boolean

  /** eg: show(payload) */
  payload: unknown

  show: (payload?: unknown) => Promise<any>
  hide: () => void
  resolve: (payload?: unknown) => void
}

const modalCallbacks: Record<string, (value: unknown) => void> = {}

export function useAtomModal(modalId: string): UseModalReturn {
  const dispatch = useAppDispatch()
  const modalCache = useAppSelector(state => state.modals[modalId])

  const _show = useCallback(
    (args: unknown) => {
      return new Promise(resolve => {
        modalCallbacks[modalId] = resolve
        dispatch(
          show({
            modalId,
            args,
          }),
        )
      })
    },
    [dispatch, modalId],
  )
  const resolve = useCallback(
    (args: unknown) => {
      if (modalCallbacks[modalId]) {
        modalCallbacks[modalId](args)
        delete modalCallbacks[modalId]
      }
    },
    [modalId],
  )

  const destoryed = useMemo(() => {
    return modalCache === undefined
  }, [modalCache])

  const visible = useMemo(() => {
    return !!modalCache?.visible
  }, [modalCache?.visible])

  const payload = useMemo(() => {
    return modalCache?.payload
  }, [modalCache?.payload])

  const _hide = useCallback(
    (force = false) => {
      dispatch(hide({ modalId, force }))
    },
    [dispatch, modalId],
  )

  return useMemo(
    () => ({
      destoryed,
      visible,
      payload,
      show: _show,
      hide: _hide,
      resolve,
    }),
    [_hide, destoryed, visible, _show, payload, resolve],
  )
}

type Normal = PickPartial<DialogProps, 'isOpen' | 'onClose'> & {
  type: 'normal'
  id: string
}

type Title = PickPartial<TitledDialogProps, 'isOpen' | 'onClose'> & {
  type: 'title'
  id: string
}

type AtomModalProps = Normal | Title

function AtomModal({ id, children, ...props }: AtomModalProps) {
  const modal = useAtomModal(id)

  if (props.type === 'title') {
    return (
      <TitledDialog
        isOpen={modal.visible}
        onClose={() => modal.hide()}
        {...props}
      >
        {children}
      </TitledDialog>
    )
  } else {
    return (
      <Dialog isOpen={modal.visible} onClose={() => modal.hide()} {...props}>
        {children}
      </Dialog>
    )
  }
}

export function createModal<P extends Record<string, any>>(
  name: string,
  Component: FC<P>,
): FC<P> {
  const Comp: FC<P> = props => {
    const { destoryed } = useAtomModal(name)
    if (destoryed) return null

    return <Component {...props} />
  }

  return Comp
}

export default AtomModal
