import type { SystemStyleObject } from '@chakra-ui/react'
import {
  Box,
  Button,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useNumberInput,
} from '@chakra-ui/react'
import { floor, toNumber, toString } from 'lodash-es'
import React, { memo, useEffect, useState } from 'react'

import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useCustomizedStyle } from '@/utils/hooks'

import { getLimitedResult } from '../../utils'
import MaskLayer from '../mask-layer/MaskLayer'

export const PARTS = ['box', 'label', 'button', 'input', 'text'] as const
export const NAME = 'Cashout'

export interface CashOutProps {
  /** value */
  value: number

  /** min value */
  minValue?: number

  /** max value */
  maxValue?: number

  /** disabled status */
  disabled?: boolean

  /** title text */
  title: string

  /**
   *
   * The precision to round down to:
   * precision = 2,
   * input: 0.5264,
   * output: 0.52,
   *
   * */
  precision?: number | undefined

  /** value change callback */
  onChange?: (value: number) => void

  onBlur?: (value: number) => void
}

const box: SystemStyleObject = {
  padding: '2.5',
  bgColor: 'gray.50',
  borderRadius: 'lg',
  overflow: 'hidden',
}

const input: SystemStyleObject = {
  boxShadow: 'none',
  border: 'none',
  fontSize: '14px',
  fontWeight: '800',
  textAlign: 'center',
  _focusVisible: {
    boxShadow: 'none',
  },
}

const button: SystemStyleObject = {
  bgColor: '#edeff1',
  _hover: {
    bgColor: '#235cf1',
    color: '#fff',
  },
  _disabled: {
    bgColor: '#edeff1',
    opacity: '.4',
    cursor: 'not-allowed',
    color: 'inherit',
  },
}

export const CashOut: React.FC<CashOutProps> = ({
  value,
  minValue = 1.01,
  maxValue = 999,
  disabled,
  title,
  onChange,
  onBlur,
  precision,
}) => {
  const {
    box: boxStyle,
    input: inputStyle,
    button: buttonStyle,
    label: labelStyle,
  } = useCustomizedStyle(NAME, { box, input, button, label: {} }, PARTS)

  return (
    <HStack sx={boxStyle} position='relative' justifyContent='space-between'>
      <Box>
        <Text sx={labelStyle} textStyle='text3'>
          {title}
        </Text>
      </Box>
      <Box>
        <MemoNumberInput
          value={value}
          inputSX={inputStyle}
          buttonSX={buttonStyle}
          maxValue={maxValue}
          minValue={minValue}
          disabled={disabled}
          onChange={v => onChange && onChange(v)}
          onBlur={value => onBlur && onBlur(value)}
          precision={precision}
        />
      </Box>

      {disabled && <MaskLayer />}
    </HStack>
  )
}

const MemoCashout = memo(CashOut)
export default MemoCashout

interface NumberInputProps {
  value: number
  maxValue?: number
  minValue?: number
  precision?: number | undefined
  step?: number

  disabled?: boolean

  buttonSX?: SystemStyleObject
  inputSX?: SystemStyleObject

  onChange?: (value: number) => void
  onBlur?: (value: number) => void
}

const NumberInput: React.FC<NumberInputProps> = ({
  disabled,
  value,
  maxValue,
  minValue,
  precision,
  step = 1,

  buttonSX,
  inputSX,

  onBlur,
  onChange,
}) => {
  const [inputValue, setInputValue] = useState<string>(toString(value))

  const { getInputProps } = useNumberInput({
    step,
    isDisabled: disabled,
    onChange: setInputValue,
  })

  const input = getInputProps()

  const updateInputValue = (v: number) => {
    setInputValue(toString(v))
  }

  const handleBlur = () => {
    const nextNumbericValue = toNumber(inputValue)
    const isNaN = Number.isNaN(nextNumbericValue)

    if (isNaN) {
      updateInputValue(value)
      onBlur && onBlur(value)
    } else {
      if (onChange) {
        // 1 - 先取出经过边界判断的值
        let nextValue = getLimitedResult(nextNumbericValue, maxValue, minValue)

        // 2 - 精度修改
        nextValue = floor(nextValue, precision)

        if (nextValue !== value) {
          // 更新value， 由副作用再更新内部状态
          onChange(nextValue)
          // 如果相等不更新， 要手动更新已经编辑过后的状态
        } else {
          updateInputValue(nextValue)
        }

        onBlur && onBlur(nextValue)
      }
    }
  }

  const handleDec = () => {
    const nextValue = getLimitedResult(value - 1, maxValue, minValue)
    onChange && onChange(nextValue)
  }

  const handleInc = () => {
    const nextValue = floor(getLimitedResult(value + 1, maxValue, minValue))
    onChange && onChange(nextValue)
  }

  useEffect(() => {
    updateInputValue(value)
  }, [value])

  return (
    <InputGroup>
      <InputLeftElement h='8'>
        <Button
          size='xs'
          w='6'
          disabled={disabled}
          sx={buttonSX}
          onClick={handleDec}
          borderRadius='6px'
        >
          <Icon as={SharedIcon.Reduce} boxSize='4' />
        </Button>
      </InputLeftElement>
      <Input
        {...input}
        value={inputValue}
        sx={inputSX}
        h='8'
        p='0 5px'
        disabled={disabled}
        onBlur={() => handleBlur()}
      />
      <InputRightElement h='8'>
        <Button
          size='xs'
          disabled={disabled}
          sx={buttonSX}
          onClick={handleInc}
          borderRadius='6px'
          w='6'
        >
          <Icon as={SharedIcon.Add} boxSize='4' />
        </Button>
      </InputRightElement>
    </InputGroup>
  )
}

const MemoNumberInput = memo(NumberInput)
