import { Box, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { useAppSelector } from '@/modules/app/store'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import {
  selectUserId,
  selectUserToken,
  toggleBalanceFloat,
  updateBanlance,
} from '@/modules/user/userSlice'
import type { WithMessageObject } from '@/services/types'
import { useUserInfo } from '@/utils/hooks'

import {
  useClaimRewardsMutation,
  useMarkMessageAsReadMutation,
} from '../../api'
import type { Message } from '../../types'
import RewardItem from './RewardItem'

const ClaimedCover = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.6)',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='25'
        height='19'
        viewBox='0 0 25 19'
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M21.496 0.200195L9.0504 12.6458L2.828 6.4234L0 9.2514L9.0504 18.3026L9.616 17.7362L24.324 3.029L21.496 0.200195Z'
          fill='#2EF87A'
        />
      </svg>
    </Box>
  )
}
const Reward = ({
  reward,
}: {
  reward: Message['rewards'] & {
    is_claim_rewards?: number
    guid?: string
  }
}) => {
  const [claimRewards] = useClaimRewardsMutation()
  const [markMessageAsRead] = useMarkMessageAsReadMutation()
  const toast = useToast()
  const userId = useAppSelector(selectUserId)
  const token = useAppSelector(selectUserToken)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { userInfo } = useUserInfo()
  const claimReward = async (
    e: any,
    item: Message['rewards'] & {
      is_claim_rewards?: number
      guid?: string
    },
  ) => {
    e.preventDefault()
    e.stopPropagation()
    if (!item.guid || item.is_claim_rewards) {
      return
    }
    try {
      await claimRewards({
        user_id: userId,
        token,
        guid: item.guid,
      }).unwrap()
      await markMessageAsRead({
        user_id: userId,
        token,
        guid: item.guid,
      }).unwrap()

      if (item.num) {
        dispatch(
          toggleBalanceFloat({
            balance: item.num,
          }),
        )
        const blc: number = userInfo.banlance?.amount as number
        const newBlc = blc + item.num
        updateBanlance(newBlc)
        dispatch(
          openModal({
            key: GlobalModalKey.RewardSuccess,
            data: item.num,
          }),
        )
      }
    } catch (error) {
      toast({ title: t('NOTIFY_BONUS_GET_FAIL'), status: 'error' })
    }
  }
  return (
    <Box
      sx={{
        borderRadius: '10px',
        layerStyle: 'ls-accent',
        width: '80px',
        height: '80px',
        marginTop: '12px',
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
      }}
      onClick={e => claimReward(e, reward)}
    >
      {reward?.is_claim_rewards ? <ClaimedCover /> : null}
      <RewardItem item={reward} isClaim={Boolean(reward.is_claim_rewards)} />
    </Box>
  )
}

export default Reward
