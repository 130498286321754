import { Box, Button, Image, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useCallback, useMemo } from 'react'

import moneyRainEntryIcon from '@/assets/images/money-rain/money_rain.webp'
import { isFeatureEnabled } from '@/common/feature'
import AtomBadge from '@/components/atom-badge/AtomBadge'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { selectUserIsLogin } from '@/modules/user/userSlice'
import { formatLocalMoney } from '@/utils/tools'

import useMoneyRain from '../hooks/useMoneyRain'
import {
  selectIsShowEntryButtonRedDot,
  selectMoneyRainStatus,
  selectNextAct,
  selectStartingAct,
  selectUserDrawCount,
} from '../store/monyRainSlice'
import type { MoneyRainAct } from '../types/moneyRainTypes'
import PhiluckyMoneyRainEntryButton from './PhiluckyMoneyRainEntryButton'

const btnStyles = {
  borderRadius: { base: '12', lg: '16' },
  w: { base: '12', lg: '16' },
  h: { base: '12', lg: '16' },
  padding: '1',
  display: 'grid',
  placeItems: 'center',
  gridTemplateRows: 'auto auto',
  cursor: 'pointer',
  bg: 'linear-gradient(0deg, #108BB2 0%, #033440 100%)',
}
function MoneyRainEntryButton() {
  const { handleShowMoneyRainRulesModal, handleShowMoneyRainGameModal } =
    useMoneyRain()

  const isShowBadge = useAppSelector(selectIsShowEntryButtonRedDot)
  const startingAct = useAppSelector(selectStartingAct)
  const nextAct = useAppSelector(selectNextAct)
  const dispatch = useAppDispatch()

  const isUserLogin = useAppSelector(selectUserIsLogin)

  const userDrawCount = useAppSelector(selectUserDrawCount)

  const moneyRainStatus = useAppSelector(selectMoneyRainStatus)

  const actStatus = useMemo(() => {
    if (startingAct && userDrawCount === 0) {
      return 'starting'
    }
    if (nextAct) {
      return 'noStart'
    }
    return 'noAct'
  }, [nextAct, startingAct, userDrawCount])

  const renderData: MoneyRainAct | undefined = useMemo(() => {
    if (startingAct && userDrawCount === 0) {
      return startingAct
    }
    if (nextAct) {
      return nextAct
    }
    return undefined
  }, [nextAct, startingAct, userDrawCount])

  const getStatusRenderContent = useCallback(
    (data: MoneyRainAct | undefined) => {
      switch (actStatus) {
        case 'starting':
          return t('EVENT_CASHRAIN_BEGIN')
        case 'noStart':
          return data ? dayjs(data.start_time * 1000).format('HH:mm') : null
        case 'noAct':
          return <Text mt='-2px'>{t('EVENT_CASHRAIN_TITTLE1')}</Text>
      }
    },
    [actStatus],
  )

  const getMoneyRenderContent = useCallback(
    (data: MoneyRainAct | undefined) => {
      switch (actStatus) {
        case 'starting':
          return formatLocalMoney(data?.total_prize)
        case 'noStart':
          return formatLocalMoney(data?.total_prize)
        case 'noAct':
          return null
      }
    },
    [actStatus],
  )

  function NormalEntryButton() {
    return (
      <Box position='relative'>
        <AtomBadge variant='round' show={isShowBadge}>
          <Button
            colorScheme='fill-prim'
            sx={btnStyles}
            aria-label='download'
            onClick={() => {
              if (!isUserLogin) {
                dispatch(
                  openModal({
                    key: GlobalModalKey.Login,
                  }),
                )
                return
              }
              const now = dayjs().unix().valueOf()
              if (renderData) {
                if (now < renderData.start_time || userDrawCount !== 0) {
                  handleShowMoneyRainRulesModal()
                } else {
                  handleShowMoneyRainGameModal()
                }
              } else {
                handleShowMoneyRainRulesModal()
              }
            }}
            _hover={{
              bg: 'linear-gradient(0deg, #108BB2 0%, #033440 100%)',
            }}
            _checked={{
              bg: 'linear-gradient(0deg, #108BB2 0%, #033440 100%)',
            }}
          >
            <Image src={moneyRainEntryIcon} w='12' h='auto' mt='-1' />
            <Text
              sx={{
                fontSize: {
                  base: '8px',
                  lg: '10px',
                },
                fontWeight: 'bold',
                lineHeight: {
                  base: '8px',
                  lg: '12px',
                },
                color: '#FFF3B1',
              }}
              mt='-1px'
            >
              {getMoneyRenderContent(renderData)}
            </Text>
            <Box
              maxW='full'
              whiteSpace='pre-wrap'
              mt='2px'
              sx={{
                fontSize: {
                  base: '8px',
                  lg: '10px',
                },
                color: '#fff',
                lineHeight: {
                  base: '8px',
                  lg: '10px',
                },
              }}
            >
              {getStatusRenderContent(renderData)}
            </Box>
          </Button>
        </AtomBadge>
      </Box>
    )
  }

  return (
    <>
      {/* 如果全部活动结束了,或者未开启红包雨 */}
      {moneyRainStatus === 'allActEnd' ||
      !isFeatureEnabled('moneyRain') ? null : (
        <>
          {isFeatureEnabled('redMoneyRainStyle') ? (
            <PhiluckyMoneyRainEntryButton />
          ) : (
            <NormalEntryButton />
          )}
        </>
      )}
    </>
  )
}

export default MoneyRainEntryButton
