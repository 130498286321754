import styles from './loading.module.scss'
import LoadingProgress from './LoadingProgress'

export default function AtomPageLoading() {
  return (
    <div className={styles.new_loading_box}>
      <div className={styles.loading_container}>
        <p className={styles.loading_text} aria-label='Loading'>
          <span className={styles.letter} aria-hidden='true'>
            L
          </span>
          <span className={styles.letter} aria-hidden='true'>
            o
          </span>
          <span className={styles.letter} aria-hidden='true'>
            a
          </span>
          <span className={styles.letter} aria-hidden='true'>
            d
          </span>
          <span className={styles.letter} aria-hidden='true'>
            i
          </span>
          <span className={styles.letter} aria-hidden='true'>
            n
          </span>
          <span className={styles.letter} aria-hidden='true'>
            g
          </span>
        </p>
      </div>
      <LoadingProgress />
    </div>
  )
}
