import { Center, Spinner } from '@chakra-ui/react'
import { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { setLoginAfterRedirect } from '@/modules/app-layout/app-layout-slice'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { useGetUserInfoQuery } from '@/modules/user/userApi'
import { selectUser } from '@/modules/user/userSlice'
import { useUserAuth } from '@/utils/hooks/useUserAuth'

export function AuthRoute() {
  const [userId, token] = useUserAuth()
  const hasUserCredentials = token && userId
  const { isUninitialized, isLoading } = useGetUserInfoQuery(
    { token, user_id: userId },
    { skip: !hasUserCredentials },
  )
  const user = useAppSelector(state => selectUser(state).user)
  const dispatch = useAppDispatch()

  const isWaitingForUserInfo = isUninitialized || isLoading

  useEffect(() => {
    if (!hasUserCredentials || (!isWaitingForUserInfo && !user)) {
      setTimeout(() => {
        dispatch(setLoginAfterRedirect(location.pathname))
        dispatch(openModal({ key: GlobalModalKey.Login }))
      }, 0)
    }
  }, [hasUserCredentials, user, dispatch, isWaitingForUserInfo])

  if (!hasUserCredentials) {
    return <Navigate to='/' replace />
  } else if (isWaitingForUserInfo) {
    return (
      <Center w='full' h='72'>
        <Spinner
          size='xl'
          thickness='4px'
          emptyColor='gray.200'
          color='prim.400'
        />
      </Center>
    )
  } else if (!user) {
    return <Navigate to='/' replace />
  } else {
    return <Outlet />
  }
}
