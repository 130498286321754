import { api } from '@/services/api'
import type { Response } from '@/services/types'

import type { RankLuckConfig, RankWindConfig } from './type'

export const slotGameApi = api.injectEndpoints({
  endpoints: builder => ({
    // win today 榜单列表
    getWinInfo: builder.query<Response<RankWindConfig[]>, number>({
      query: limit => ({
        url: `ranking/bonus/${limit}`,
        method: 'GET',
      }),
    }),
    // luck 榜单列表
    getLuckInfo: builder.query<Response<RankLuckConfig[]>, number>({
      query: limit => ({
        url: `ranking/luck/${limit}`,
        method: 'GET',
      }),
    }),
    // 周榜 日榜
    getBonusInfo: builder.query<Response<RankLuckConfig[]>, string>({
      query: type => ({
        url: `ranking/bonus/10/${type}`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetWinInfoQuery, useGetLuckInfoQuery, useGetBonusInfoQuery } =
  slotGameApi
