import { useTranslation } from 'react-i18next'

import { useAppDispatch } from '@/modules/app/store'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'

import * as Images from '../image-imports'
import type { TaskItemProps } from '../types'
import TaskItem from './TaskItem'

function TaskItemRecharge(props: TaskItemProps<'deposit'>) {
  const { data } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const onClick = () => dispatch(openModal({ key: GlobalModalKey.Wallet }))

  if (!data) return null
  return (
    <TaskItem
      icon={Images.taskItemRechargeIcon}
      title={t('NAV_DEPOSIT')}
      drawTimes={data.drawTimes}
      progressProps={data.progress}
      buttonProps={{ onClick, text: t('NAV_DEPOSIT') }}
    />
  )
}

export default TaskItemRecharge
