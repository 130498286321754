import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import { reloadI18nResourceFromS3 } from '@/modules/app/initI18n'
import property from '@/websites/current/property.json'
import i18nCfg from '@/websites/current/public/config/i18n.json'

import { isNotLocal } from '../env'
import { isFeatureEnabled } from '../feature'

/**
 * @see https://www.i18next.com/
 * @see https://react.i18next.com/
 * @see https://github.com/i18next/i18next-http-backend
 * @see https://github.com/i18next/i18next-browser-languageDetector
 */

export const DEFAULT_NS = ['common', 'help']

const initCfg = {
  fallbackLng: property.locale.fallbackLocale,
  supportedLngs: property.locale.i18nLocales,
  // debug: true,
  interpolation: {
    escapeValue: false,
  },
  ns: DEFAULT_NS,
}

// 加载i18n文件版本
try {
  if (isNotLocal() && i18nCfg?.v) {
    Object.assign(initCfg, {
      backend: {
        queryStringParams: {
          v: i18nCfg?.v || '',
        },
      },
    })
  }
} catch (e) {
  //
}
i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(initCfg)

if (isFeatureEnabled('i18nSupportS3')) {
  i18n.on('initialized', () => {
    reloadI18nResourceFromS3(DEFAULT_NS)
  })
}

export default i18n
