import { useTheme } from '@chakra-ui/react'
import { merge } from 'lodash-es'

type DefaultProps = {
  size?: string
  variant?: string
  colorScheme?: string
}
type DefaultPropsDict = {
  [key: string]: DefaultProps | undefined
}
export function useInternalDefaultProps<T extends Partial<DefaultPropsDict>>(
  name: string,
  defaultValue: T,
): T {
  const theme = useTheme()
  const componentStyles = theme?.components[name]
  const result = {} as T
  merge(result, defaultValue, componentStyles?.internalDefaultProps ?? {})
  return result
}
