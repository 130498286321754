import { useCallback, useState } from 'react'

import { isFeatureEnabled } from '@/common/feature'
import { useAddGameHistoryMutation } from '@/modules/my-games/api'
import { useUserAuth } from '@/utils/hooks'
import useBalanceDecrease from '@/utils/hooks/useBalanceDecrease'

/***
 * Adds a game to the user's history
 * @param gameId - the game id from the url
 * @returns {string} gameUuid - the game uuid from the database
 */

export const useAddGameHistory = (gameId: string, gameUuid: string) => {
  const [addGameHistory] = useAddGameHistoryMutation()
  const [hasAdded, setHasAdded] = useState(false)
  const [userId, token] = useUserAuth()

  // Add game to history on balance decrease
  const decreaseCb = useCallback(async () => {
    if (!isFeatureEnabled('gameCollection')) {
      return
    }
    if (hasAdded) {
      return
    }
    if (gameUuid && userId && token) {
      await addGameHistory({
        user_id: userId,
        token,
        game_id: gameId,
        game_uuid: gameUuid,
      })
      setHasAdded(true)
    }
  }, [hasAdded, gameUuid, userId, token, addGameHistory, gameId])
  useBalanceDecrease(decreaseCb)
}
