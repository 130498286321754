import { Box } from '@chakra-ui/react'

const PROGRESS_CONTAINER_STYLE = {
  position: 'relative',
  width: '100%',
  height: '4px',
  background: 'personalCenter.progressBg',
  borderRadius: '12px',
  overflow: 'clip',
  overflowClipMargin: '20px',
}

const PROGRESS_STYLE = {
  position: 'absolute',
  top: '0',
  left: '0',
  height: '100%',
  borderRadius: '12px',
  background: "personalCenter.progressGradient",
  boxShadow: "0px 0px 6px 0px #008A61"

}

/**
 * 领奖中心的通用进度条
 * @param progress 长度 0-100
 * @returns
 */
function ProgressBar({ progress }: { progress: number }) {
  const trueProgress = progress < 0 ? 0 : progress > 100 ? 100 : progress
  return (
    <Box position='relative' sx={PROGRESS_CONTAINER_STYLE}>
      <Box sx={PROGRESS_STYLE} width={`${trueProgress}%`} />
    </Box>
  )
}

export default ProgressBar
