import { Button, Flex, Image, Text, useDisclosure } from '@chakra-ui/react'
import { t } from 'i18next'
import { useEffect } from 'react'

import EmptyImg from '@/assets/images/lucky-wheel/empty.png'
import TitledDialog from '@/components/modal/TitledDialog'

import luckyWheelEvents from '../events'

function NoResultModal() {
  const { isOpen, onClose, onOpen } = useDisclosure()

  useEffect(() => {
    luckyWheelEvents.on('showNoResultModal', () => {
      onOpen()
    })
    return () => {
      luckyWheelEvents.off('showNoResultModal')
    }
  }, [onOpen])

  const handleClose = () => {
    onClose()
  }

  return (
    <TitledDialog
      isOpen={isOpen}
      onClose={() => {
        handleClose()
      }}
      size='sm'
      title={t('EVENT_CASHRAIN_WINNING_WORD4')}
      isCentered
    >
      <Flex justifyContent='center'>
        <Image w='123px' h='auto' src={EmptyImg} objectFit='cover' />
      </Flex>
      <Button
        colorScheme='fill-prim'
        w='full'
        whiteSpace='nowrap'
        color='white'
        mt='4'
        py='4'
        h='46px'
        onClick={() => {
          handleClose()
        }}
      >
        <Text textStyle='h4'>{t('EVENT_CASHRAIN_WINNING_BUTTON1')}</Text>
      </Button>
    </TitledDialog>
  )
}

export default NoResultModal
