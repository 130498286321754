import type {
  PlatformStorage,
  PlatformThridPartAuth,
  PlatformTracker,
  ThirdPartSignResult,
} from './types'

export enum Native3rdPartyLoginChannel {
  Google = 'GG',
  Facebook = 'FB',
}

interface Android3rdPartyLoginResult {
  channel: string
  isSigned: boolean
  signResult: {
    serverAuthCode: string
  }
}

export class AndroidStorage implements PlatformStorage {
  setClientStorage(key: string, val: string) {
    window.AndroidWebView?.setClientStorage(key, val)
  }

  getClientStorage(key: string) {
    const clientStorage = this.getAllClientStorage()
    clientStorage ? clientStorage[key] : null
    return clientStorage ? clientStorage[key] : null
  }

  removeClientStorage(key: string) {
    window.AndroidWebView?.removeClientStorage(key)
  }

  clearClientStorage() {
    window.AndroidWebView?.clearClientStorage()
  }

  getAllClientStorage() {
    const clientStorage = window.AndroidWebView?.getAllClientStorage() || '{}'
    return JSON.parse(clientStorage)
  }
}

export class AndroidThirdPartAuth implements PlatformThridPartAuth {
  _googleClientId: string
  _fbAppId: string

  constructor(googleClientId: string, fbAppId: string) {
    this._googleClientId = googleClientId
    this._fbAppId = fbAppId
  }

  get googleClientId() {
    return this._googleClientId
  }

  get fbAppId() {
    return this._fbAppId
  }

  private thirdPartSignIn(
    serverClientId: string,
    channel: Native3rdPartyLoginChannel,
  ) {
    return new Promise<ThirdPartSignResult>((resove, reject) => {
      window.AndroidWebView?.do3rdSignIn(
        channel,
        JSON.stringify({ serverClientId }),
      )
      window.on3rdSignResult = res => {
        let data: null | Android3rdPartyLoginResult = null
        if (typeof res === 'string') {
          data = JSON.parse(res) as Android3rdPartyLoginResult
        } else if (typeof res === 'object') {
          data = res as unknown as Android3rdPartyLoginResult
        }
        if (!data || data.channel !== channel) {
          reject('invalid data')
        } else if (!data.isSigned) {
          reject('signin failed')
        } else {
          resove({
            channel: data.channel,
            isSigned: data.isSigned,
            authCode: data.signResult?.serverAuthCode,
          })
        }
      }
    })
  }

  googleSingIn() {
    return this.thirdPartSignIn(
      this.googleClientId,
      Native3rdPartyLoginChannel.Google,
    )
  }

  facebookSingIn() {
    return this.thirdPartSignIn(
      this.fbAppId,
      Native3rdPartyLoginChannel.Facebook,
    )
  }
}

export class AndroidTracker implements PlatformTracker {
  firebaseEvent(args: {
    key: string
    value: string | number
    currency?: string
    extras?: { [key: string]: string | number }
    [key: string]: any
  }) {
    try {
      window.AndroidWebView?.firebaseEvent(JSON.stringify(args))
    } catch (error) {
      window.AndroidWebView?.firebaseEvent(args.key, args.vallue)
    }
  }
}
