import { add, divide, multiply, toNumber } from 'lodash-es'
import { useCallback, useMemo } from 'react'

import { isFeatureEnabled } from '@/common/feature'
import { getCurrencyCode } from '@/common/locale/currency'
import { deviceMap } from '@/modules/activity/type'
import { useRechargeInfo } from '@/modules/activity/useRechargeInfo'
import { useAppSelector } from '@/modules/app/store'
import { getRechargeList, selectIsFromAct } from '@/modules/wallet/walletSlice'
import { useWalletConfig } from '@/utils/hooks'
import { getDeviceType } from '@/utils/tools'

import type {
  RechargeActListProps,
  RewardCfg,
  rewardFixedCfg,
  rewardGradCfg,
} from '../activity/ActInterface'
import {
  selectRechargeActId,
  selectRechargeActList,
} from '../activity/ActSlice'
import { selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { filterActivityByScope } from '../inhouse/logic/RechargeComfirmModal'
import type { RechargeActivityProps, RechargeBonusPorps } from './interface'
import { DEFALUT_RECHARGE_ACT, RECHARGE_ACTIVITY_SCOPE_TYPE } from './interface'

const rechargeActivityV2 = isFeatureEnabled('rechargeActivityV2')
export default function useRechargeActivity() {
  const { data: modalData } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.Wallet],
  )
  const { handleActStatus } = useRechargeInfo()

  const is_from_recharge_act = useAppSelector(selectIsFromAct)
  const actId = useAppSelector(selectRechargeActId)
  // 旧充值活动列表
  const originalRechargeList = useAppSelector(getRechargeList)
  // 新充值活动列表
  const newRechargeActList = useAppSelector(selectRechargeActList)

  const rechargeList = useMemo(() => {
    // 新的充值活动
    if (rechargeActivityV2) {
      return handleNewRechargeActListByDevice({
        actId,
        actList: newRechargeActList,
        is_from_recharge_act,
        handleActStatus,
      })
    }
    // 以下是之前旧的充值活动
    if (modalData?.from === 'prompte_app_activity') {
      return originalRechargeList.filter(
        item => item.scope === RECHARGE_ACTIVITY_SCOPE_TYPE.APP,
      )
    }
    return filterActivityByScope(originalRechargeList)
  }, [
    originalRechargeList,
    newRechargeActList,
    modalData,
    actId,
    is_from_recharge_act,
  ])
  const { AMOUNT_LIST } = useWalletConfig('', { is_from_recharge_act })

  //快捷金额对应的赠金
  const quickAmountsBouns = useMemo(() => {
    if (!rechargeList || !Array.isArray(rechargeList)) {
      return {}
    }
    return AMOUNT_LIST.reduce((acc, cur) => {
      const { value: amount } = cur
      const _rechargeList = sortRechargeAct(rechargeList, amount)
      if (_rechargeList.length === 0) {
        acc[amount] = ''
      } else {
        acc[amount] = getRewardAmount(
          { ..._rechargeList[0]?.reward, amount },
          _rechargeList[0] || {},
        )
      }
      return acc
    }, {})
  }, [rechargeList, AMOUNT_LIST])

  const _getActItemByAmount = useCallback(
    (amount: number) => {
      const sortedRechargeActList = sortRechargeAct(rechargeList, amount)
      return getActItemByAmount(sortedRechargeActList)
    },
    [rechargeList],
  )

  const _getBonusByAmount = useCallback(
    (amount: number) => {
      const sortedRechargeActList = sortRechargeAct(rechargeList, amount)
      return getBonusByAmount(sortedRechargeActList, amount)
    },
    [rechargeList],
  )

  return useMemo(
    () => ({
      actId: is_from_recharge_act ? actId : '',
      quickAmountsBouns,
      getActItemByAmount: _getActItemByAmount,
      getBonusByAmount: _getBonusByAmount,
      rechargeList: rechargeList,
    }),
    [
      _getActItemByAmount,
      _getBonusByAmount,
      quickAmountsBouns,
      actId,
      is_from_recharge_act,
      rechargeList,
    ],
  )
}

type RechargeActList = RechargeActivityProps[]

/**
 *
 * 排序 充值活动
 *
 * @param rechargeList 未排序的活动列表
 * @param amount
 * @returns 返回排过序的充值活动列表
 */
export function sortRechargeAct(rechargeList: RechargeActList, amount: number) {
  if (!rechargeList || !Array.isArray(rechargeList)) {
    return []
  }
  if (rechargeActivityV2) {
    // 新的充值活动
    return newSortRechargeAct(rechargeList, amount)
  }
  return rechargeList
    .filter(filterItem => amount >= Number(filterItem.pre_condition.min_charge))
    .sort((activity, bctivity) => {
      const rewardAmount = getRewardAmount(
        { amount, ...activity?.reward },
        activity,
      )
      const rewardBmount = getRewardAmount(
        { amount, ...bctivity?.reward },
        bctivity,
      )
      return rewardBmount - rewardAmount
    })
}

/**
 * 处理固定数额
 */
function handleFixedAmount(reward_fixed: rewardFixedCfg, amount: number) {
  if (!reward_fixed?.min_recharge_amount) return false
  return amount >= reward_fixed?.min_recharge_amount
}

/**
 * 处理梯度数额
 */
function handleGradAmount(reward_grad: rewardGradCfg[], amount: number) {
  if (!reward_grad || !Array.isArray(reward_grad)) return false
  let flag = false
  for (let i = 0; i < reward_grad.length; i++) {
    const cur = reward_grad[i]
    if (amount >= cur?.min_recharge_amount) {
      flag = true
      break
    }
  }
  return flag
}

/**
 * 新的充值活动排序
 * @param rechargeList
 * @param amount
 * @returns
 */
export function newSortRechargeAct(
  rechargeList: RechargeActList,
  amount: number,
) {
  const currency = getCurrencyCode()
  const _rechargeList = rechargeList
    .filter(item => {
      const { reward_config } = item as unknown as RechargeActListProps
      const { reward_grad, reward_fixed, reward_type }: RewardCfg =
        reward_config?.[currency] || {}
      switch (reward_type) {
        case 0:
        case 1:
          // 固定数额 | 固定比例
          return handleFixedAmount(reward_fixed, amount)
        case 2:
        case 3:
          // 梯度数额 ｜ 梯度比例
          return handleGradAmount(reward_grad, amount)
      }
    })
    .sort((a, b) => {
      const _amount = getRewardAmount({ amount }, a)
      const _bmount = getRewardAmount({ amount }, b)
      return _bmount - _amount
    })
  return _rechargeList
}

// 充值活动最小充值金额
export function getRechargeActMinAmount(
  rechargeList: RechargeActList,
  min: number,
) {
  if (!rechargeList || !Array.isArray(rechargeList)) {
    return min
  }
  return rechargeList.sort((activity, bctivity) => {
    const rewardAmount = Number(activity.pre_condition.min_charge)
    const rewardBmount = Number(bctivity.pre_condition.min_charge)
    return rewardAmount - rewardBmount
  })[0]?.pre_condition.min_charge
}

// 处理梯度数额最大奖金金额
function handleGradMaxRewardAmount(reward_grad: rewardGradCfg[]) {
  const amountMap = reward_grad?.map(item => item.reward_value) || []
  return Math.max(...amountMap)
}

/**
 * 通过新的充值活动获取奖励金额
 */
export function getNewRewardAmount(
  amount: number,
  actInfo: RechargeActListProps,
) {
  const currency = getCurrencyCode()
  const {
    reward_fixed,
    reward_grad,
    reward_type,
    max_reward_amount,
  }: RewardCfg = actInfo?.reward_config?.[currency] || {}

  switch (reward_type) {
    case 0:
      // 固定数额
      return Math.min(
        // Number(max_reward_amount),
        Number(reward_fixed.reward_value),
      )
      break
    case 1:
      // 固定比例
      return Math.min(
        // Number(max_reward_amount),
        Number(reward_fixed.reward_value) * amount,
      )
      break
    case 2:
      // 梯度数额
      return Math.min(
        Number(handleGradBonus(reward_grad, amount, false)),
        Number(handleGradMaxRewardAmount(reward_grad)),
      )
      break
    case 3:
      // 梯度比例
      return Math.min(
        Number(handleGradBonus(reward_grad, amount, true)),
        Number(max_reward_amount),
      )
      break
  }
}

/**
 * 计算梯度奖金
 */
function handleGradBonus(
  reward_grad: rewardGradCfg[],
  amount: number,
  is_grad: boolean,
) {
  let bonus = 0
  reward_grad?.forEach((item, index) => {
    const next = reward_grad[index + 1]
    if (
      amount >= item?.min_recharge_amount &&
      (!next || amount < next.min_recharge_amount)
    ) {
      bonus = is_grad
        ? Number(amount) * Number(item?.reward_value || 0)
        : Number(item?.reward_value || 0)
    }
  })
  return bonus
}

/**
 * 计算梯度奖金对应的比例
 */
export function handleGradRate(reward_grad: rewardGradCfg[], amount: number) {
  let rate = 0
  reward_grad?.forEach((item, index) => {
    const next = reward_grad[index + 1]
    if (
      amount >= item?.min_recharge_amount &&
      (!next || amount < next.min_recharge_amount)
    ) {
      rate = Number(item?.reward_value || 0)
    }
  })
  return rate || ''
}

/**
 * get reward amount
 * @param params 参数
 * @returns
 */
export function getRewardAmount(
  params: RechargeBonusPorps | { amount: number },
  actInfo: RechargeActListProps,
) {
  if (rechargeActivityV2) {
    // 新版的充值活动
    return getNewRewardAmount(params.amount, actInfo)
  }
  const { coefficient_amount, coefficient_amount_limit, fixed_amount, amount } =
    params
  const _bonust = divide(
    multiply(multiply(toNumber(coefficient_amount), 10000), amount),
    10000,
  )
  return add(
    Math.min(Number(coefficient_amount_limit), _bonust),
    Number(fixed_amount),
  )
}

/**
 * 找到赠金最大的活动
 * @param sortedRechargeActList  已排序的活动列表
 * @returns
 */
function getActItemByAmount(sortedRechargeActList: RechargeActList) {
  if (rechargeActivityV2) {
    // 新的充值活动
    return sortedRechargeActList[0] || {}
  }
  if (sortedRechargeActList.length === 0) {
    return DEFALUT_RECHARGE_ACT
  }
  return sortedRechargeActList[0]
}

/**
 *
 * @param sortedRechargeActList 已排序的活动列表
 * @param amount 金额
 * @returns
 */
export function getBonusByAmount(
  sortedRechargeActList: RechargeActList,
  amount: number,
) {
  if (sortedRechargeActList.length === 0) {
    return 0
  }
  return getRewardAmount(
    { ...sortedRechargeActList[0]?.reward, amount },
    sortedRechargeActList[0] || {},
  )
}

/**
 * 新充值活动过滤活动列表
 */
export function handleNewRechargeActListByDevice(props: {
  actList: RechargeActListProps[]
  is_from_recharge_act: boolean | undefined
  actId: string | number | undefined
  handleActStatus: (act: RechargeActListProps) => any
}) {
  const { actList, is_from_recharge_act, actId, handleActStatus } = props
  if (!actList || !actList?.length) {
    return []
  }

  const device = getDeviceType()
  // 过滤设备
  const filterList = actList?.filter(item => {
    const join_device = item?.join_device || []
    if (is_from_recharge_act) {
      // 从特定的充值活动页面来,查找某个特定的活动
      return (
        join_device.includes(deviceMap[device]) && item.id === Number(actId)
      )
    } else {
      // 常规充值界面/破产充值弹窗，需要过滤掉没有活动次数的
      return (
        join_device.includes(deviceMap[device]) &&
        item.page_state !== 1 &&
        handleActStatus(item)?.verify
      )
    }
    return
  })

  return filterList
}
