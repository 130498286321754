import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'

import { Icon as SharedIcon } from '@/utils/atom-shared'
import { getCompatibleColors } from '@/utils/tools'

import NewMessageEvent from './NewMessageEvent'
import NewMessageReward from './NewMessageReward'

function NewMessageReceiveModal() {
  const [rewardAmount, setRewardAmount] = useState<number[]>()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const messageMemo = useMemo(() => {
    return rewardAmount?.map(item => ({
      is_read: 1,
      is_claim_rewards: 1,
      rewards: {
        num: item,
      },
    }))
  }, [rewardAmount])

  useEffect(() => {
    NewMessageEvent.on('openMessageReceiveModal', data => {
      if (
        data?.length > 0 &&
        data?.every((item: any) => typeof item === 'number')
      ) {
        setRewardAmount(data)
        onOpen()
      }
    })

    return () => {
      NewMessageEvent.off('openMessageReceiveModal')
    }
  })

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size='md'>
      <ModalOverlay />
      <ModalContent
        // height='280px'
        bg={getCompatibleColors(
          'notification.receiveModal.bg',
          'linear-gradient(0deg, #105BBC 0%, #022054 100%)',
        )}
        position='relative'
        px='20px'
      >
        <Icon
          as={SharedIcon.Close}
          boxSize='6'
          right='4'
          top='4'
          position='absolute'
          color='white'
          onClick={onClose}
          cursor='pointer'
        />
        <Box mt='6' textAlign='center'>
          <Text
            textStyle='h4'
            color={getCompatibleColors(
              'notification.receiveModal.title',
              'white',
            )}
          >
            {t('MESSAGE_POPUP_TITTLE')}
          </Text>
          <Text
            textStyle='text6'
            mt='5'
            color={getCompatibleColors(
              'notification.receiveModal.content',
              'white',
            )}
          >
            {t('MESSAGE_TEXT4')}
          </Text>
          <Flex mt='5' w='full' justifyContent='center' flexWrap='wrap'>
            {messageMemo?.map((message, index) => (
              <NewMessageReward
                key={`reward_${index}`}
                scene='reward'
                message={message}
              />
            ))}
          </Flex>
          <Button
            w='full'
            mt='6'
            mb='5'
            h='46px'
            bg={getCompatibleColors(
              'notification.receiveModal.buttonBg',
              'rgba(252, 163, 39, 1)',
            )}
            _active={{
              bg: getCompatibleColors(
                'notification.receiveModal.buttonBg',
                'rgba(252, 163, 39, 1)',
              ),
            }}
            _hover={{
              bg: getCompatibleColors(
                'notification.receiveModal.buttonBg',
                'rgba(252, 163, 39, 1)',
              ),
            }}
            onClick={onClose}
          >
            <Text
              textStyle='h4'
              color={getCompatibleColors(
                'notification.receiveModal.buttonText',
                'white',
              )}
            >
              {t('MESSAGE_BUTTON5')}
            </Text>
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  )
}

export default NewMessageReceiveModal
