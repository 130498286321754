import type { EventConfigType, TrackFn } from '../const'
import { EventName } from '../const'
// import { postPoint } from '../kwaiPoint'

const EVENT_CONFIGS: EventConfigType = {
  [EventName.ContentView]: ['contentView'],
  [EventName.CompleteRegistration]: ['completeRegistration'],
  [EventName.PlaceAnOrder]: ['addToCart', 'placeOrder'],
  [EventName.CompletePurchase]: ['purchase'],
}

export const trackerFn: TrackFn = (event, data) => {
  const channel = sessionStorage.getItem('channel')
  if (channel !== 'kwaiq') {
    return
  }
  const kwaiId = sessionStorage.getItem('kwaiId')

  const events = EVENT_CONFIGS[event]
  if (!events || !kwaiId || !kwaiq) {
    return
  }

  for (const event of events) {
    kwaiq.instance(kwaiId).track(event, data)
    // postPoint()
  }
}
