import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import 'swiper/css/grid'
import 'swiper/css/navigation'

import {
  Box,
  Flex,
  Icon,
  Link,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { memo, useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// import { Grid } from 'swiper/modules'
import type { SwiperProps } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'

import LazyImage from '@/components/lazy-image/LazyImage'
import { useAppSelector } from '@/modules/app/store'
import { Icon as SharedIcon } from '@/utils/atom-shared'

import type { ManulacturerListItem } from '../../gameInterface'
import { selectManuObj } from '../../gameSlice'
import { GRID_COLUMNS } from '../constants'
import { BuiltInGameLabel } from '../home-games/types'

function chunkArray(array: any[], chunkSize: number) {
  const result = []
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }
  return result
}

interface Props {
  manulacturerList: ManulacturerListItem[]
  onSwiperInit?: (swiper: any) => void
}

export function ProviderItem({ provider }: { provider: ManulacturerListItem }) {
  const allManuObj = useAppSelector(selectManuObj)
  const gameLength = (allManuObj[provider.id]?.data ?? []).length
  return (
    <Link
      display='flex'
      flexDirection='column'
      as={RouterLink}
      to={`/game/all?cate=${BuiltInGameLabel.Manufacturer}&label=${provider.id}`}
      textDecoration='none'
      h='full'
      _hover={{
        textDecoration: 'none',
      }}
    >
      <LazyImage src={provider.icon} h='40px' m='auto' />
      <Flex
        bgColor='gray.500'
        flex={1}
        justifyContent='center'
        alignItems='center'
      >
        <Icon as={SharedIcon.Game} boxSize='14px' color='gray.600' mr='6px' />
        <Text textStyle='text5' color='gray.900' as='span' cursor='pointer'>
          {`${gameLength}`}
        </Text>
      </Flex>
    </Link>
  )
}

function ProviderListwiper({ onSwiperInit, manulacturerList }: Props) {
  const chunkSize = useBreakpointValue({
    base: 6,
    sm: 8,
    md: 10,
    lg: 12,
  })

  const swiperProps: SwiperProps = useMemo(() => {
    return {
      style: {
        width: '100%',
        height: '100%',
      },
      grabCursor: true,
      observer: true,
      observeParents: true,
      freeMode: true,
      onInit: (swiper: any) => {
        onSwiperInit && onSwiperInit(swiper)
      },
      simulateTouch: false,
    }
  }, [onSwiperInit])

  const providerList = useMemo(() => {
    return chunkArray(manulacturerList, chunkSize || 12)
  }, [manulacturerList, chunkSize])

  const handleTwoRowItemRender = (itemList: ManulacturerListItem[]) => {
    return (
      <SimpleGrid columns={GRID_COLUMNS} columnGap='3' rowGap='3'>
        {itemList?.map(providerItem => {
          return (
            <Box
              key={providerItem.id}
              bgColor='gray.400'
              borderRadius={8}
              h='64px'
              overflow='hidden'
            >
              <ProviderItem provider={providerItem} />
            </Box>
          )
        })}
      </SimpleGrid>
    )
  }

  return (
    <Swiper {...swiperProps}>
      {(providerList as ManulacturerListItem[][])?.map(
        (providerList, index) => {
          return (
            <SwiperSlide key={`provider_row_${index}`}>
              {handleTwoRowItemRender(providerList)}
            </SwiperSlide>
          )
        },
      )}
    </Swiper>
  )
}

const ProviderListwiperMemo = memo(ProviderListwiper)

export default ProviderListwiperMemo
