import type { AuthQueryArgs } from '@/modules/user/userApi'
import { api } from '@/services/api'
import type { Response } from '@/services/types'

export interface CustomerServiceListResponseData {
  /**
   * 元数据
   */
  list?: CustomerService[]
  /**
   * 当前页
   */
  page?: number
  /**
   * 当页请求数量
   */
  pageSize?: number
  /**
   * 总条数
   */
  total?: number
}

export interface CustomerService {
  configuration_content?: string
  /**
   * 创建时间戳
   */
  created_at?: number
  /**
   * id
   */
  id?: number
  image?: string
  name?: string
  principal?: string
  status?: number
  type?: string
  /**
   * 更新时间戳
   */
  updated_at?: number
  weights?: number
  [property: string]: any
}

export const serviceListApi = api.injectEndpoints({
  endpoints: builder => ({
    // 获取用户专属客服
    userCustomerService: builder.query<
      Response<CustomerService[]>,
      AuthQueryArgs
    >({
      query: data => {
        return {
          url: '/api/v1/user/user_tag/customer/customer_service',
          method: 'POST',
          data,
        }
      },
    }),
    serviceList: builder.query<Response<CustomerServiceListResponseData>, void>(
      {
        query: data => {
          return {
            url: '/api/v1/user/user_tag/customer/customer_service',
            method: 'GET',
            data,
          }
        },
      },
    ),
    serviceEntryConfig: builder.query<
      Response<{
        list: {
          recharge_name: string
          front_page_name: string
        }[]
      }>,
      void
    >({
      query: data => {
        return {
          url: '/api/v1/user/user_tag/customer/get_customer',
          method: 'GET',
          data,
        }
      },
    }),
    getIsSaleSmartlyEnable: builder.query<Response<{ enable: number }>, void>({
      query: () => {
        return {
          url: '/feat_switch/saleSmartly',
          method: 'GET',
        }
      },
    }),
  }),
})

export const {
  useUserCustomerServiceQuery,
  useServiceListQuery,
  useServiceEntryConfigQuery,
  useGetIsSaleSmartlyEnableQuery,
} = serviceListApi
