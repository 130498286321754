import {
  AspectRatio,
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Link,
  Text,
  useTheme,
  VStack,
} from '@chakra-ui/react'
import type { ComponentProps } from 'react'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import giftIcon from '@/assets/images/gift-icon.png'
import { isFeatureEnabled } from '@/common/feature'
import LazyImage from '@/components/lazy-image/LazyImage'
import type { Banner } from '@/modules/app/home-page-config-types'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { HomePageConfigContext } from '@/modules/app-layout/AppLayout'
import type { IImageLink } from '@/modules/app-layout/types'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { selectUserIsLogin } from '@/modules/user/userSlice'
import { useActionTrigger } from '@/utils/hooks/useActionTrigger'
import {
  MAX_MOBILE_WIDTH,
  useLargerThanMobile,
} from '@/utils/hooks/useLargerThanMobile'
import { handleS3SourceDomain } from '@/utils/tools'
import appConfig from '@/websites/current/pages/layout/app-config.json'

import Carousel from './Carousel'
import { HeroSectionForAAJogo } from './HeroSectionForAAJogo'
import H24HoursRank, {
  isH24HoursRankVariant,
} from './TwentyFourHoursRank/H24HoursRank'
import { V24HoursRank } from './TwentyFourHoursRank/V24HoursRank'

interface CarouselConfig {
  variant?: string
  slidesPerView?: number
  items?: IImageLink[]
}

export interface HeroSectionConfig {
  widgets?: string[]
  widgetsMobile?: string[]
  callToActions?: IImageLink[]
  heroImage?: IImageLink | IImageLink[]
  carousel?: CarouselConfig
  sideRanking: { variant: string }
  bottomRanking: { variant: string }
}

const heroSection = appConfig.heroSection as unknown as HeroSectionConfig

const {
  widgets = [],
  widgetsMobile = [],
  heroImage,
  carousel,
  callToActions,
  sideRanking,
  bottomRanking,
} = heroSection

function useBannersFromHomePageConfig() {
  const homePageConfig = useContext(HomePageConfigContext)

  const banners = useMemo(() => {
    return (
      homePageConfig?.heroSection || {
        fixedBanners: [],
        largeBanners: [],
        smallBanners: [],
      }
    )
  }, [homePageConfig])

  return banners
}

export function HeroImagePrimaryButton(props: ComponentProps<typeof Button>) {
  const { onClick: customClick, ...restProps } = props
  const { t } = useTranslation()
  const dispath = useAppDispatch()
  const isLogin = useAppSelector(selectUserIsLogin)

  const onClick = () => {
    if (isLogin) {
      dispath(openModal({ key: GlobalModalKey.Wallet }))
    } else {
      dispath(openModal({ key: GlobalModalKey.Login, data: { index: 1 } }))
    }
  }

  return (
    <Button
      colorScheme='seco'
      size='lg'
      flex='1'
      maxW='172px'
      {...restProps}
      onClick={customClick || onClick}
    >
      <LazyImage
        position='absolute'
        top='0'
        right='3'
        transform='translateY(-50%)'
        src={giftIcon}
        alt=''
      />
      {isLogin ? t('BZ_WELCOME_B2') : t('USER_SIGNUP')}
    </Button>
  )
}

function HeroSectionForBetFiery() {
  const { t } = useTranslation()
  const isLogin = !!useAppSelector(selectUserIsLogin)
  const singleHeroImage = Array.isArray(heroImage) ? heroImage[0] : heroImage
  const imageSrc = singleHeroImage?.imageSrc

  return (
    <Box borderRadius='lg' overflow='hidden' w='full' position='relative'>
      <Flex
        position='absolute'
        left='0'
        top={isLogin ? '16' : '12'}
        flexDirection='column'
        alignItems='flex-start'
        color='white'
      >
        <Text textStyle='h5' maxWidth='400px'>
          {isLogin
            ? 'Agora, ao depositar, você pode ganhar um bônus adicional de até'
            : 'Registre-se e deposite para receber benefícios'}
        </Text>
        <Text
          fontSize='72px'
          fontWeight='900'
          lineHeight='1.21'
          color='transparent'
          background='linear-gradient(180deg, #FBFADB 0%, #FFEE05 100%)'
          backgroundClip='text'
        >
          50%
        </Text>
        <Text
          fontSize='24px'
          fontWeight='900'
          lineHeight='1.25'
          color='transparent'
          background='linear-gradient(180deg, #FBFADB 0%, #FFEE05 100%)'
          backgroundClip='text'
          hidden={isLogin}
        >
          de bônus adicional
        </Text>
        <HStack spacing='2' mt={isLogin ? '3' : '10'} w='full'>
          <HeroImagePrimaryButton />
          <Button
            colorScheme='white'
            size='lg'
            as={RouterLink}
            to='/promotions'
          >
            {t('Saber mais')}
          </Button>
        </HStack>
      </Flex>
      <LazyImage src={imageSrc} alt='' />
    </Box>
  )
}

export function Widget(props: { name: string; children?: React.ReactNode }) {
  const { name, children } = props
  const isLargerThanMobile = useLargerThanMobile()

  const isEnable = useMemo(() => {
    if (isLargerThanMobile) {
      return widgets?.includes(name)
    } else {
      return widgetsMobile?.includes(name)
    }
  }, [isLargerThanMobile, name])

  return isEnable ? children : null
}

function HeroImage(props: {
  heroImage?: Banner | Banner[] | IImageLink | IImageLink[]
}) {
  const actionTrigger = useActionTrigger()
  const { heroImage } = props
  if (!heroImage) {
    return null
  }

  if (Array.isArray(heroImage)) {
    return (
      <Carousel
        loopNumberLimit={1}
        items={heroImage}
        breakpoints={{ 0: { slidesPerView: 1 } }}
        ratio={697 / 251}
      />
    )
  } else {
    return (
      <Box
        borderRadius='lg'
        overflow='hidden'
        as='button'
        w='full'
        position='relative'
        onClick={() => {
          actionTrigger(heroImage.action)
        }}
      >
        <LazyImage src={heroImage?.imageSrc} alt='' noLazy={false} />
      </Box>
    )
  }
}

function CallToActionButton(props: Banner | IImageLink) {
  const { action, imageSrc, mobileImageSrc } = props
  const theme = useTheme()
  const actionTrigger = useActionTrigger()
  const isLargeThanMobile = useLargerThanMobile()
  if (
    (isLargeThanMobile && !imageSrc) ||
    (!isLargeThanMobile && !mobileImageSrc)
  ) {
    return null
  }

  const contentElem = (
    <AspectRatio ratio={{ base: 1.60747, md: 3.39 }}>
      <picture>
        <source
          srcSet={imageSrc}
          media={`(min-width: ${theme.breakpoints.md})`}
        />
        <Image
          src={handleS3SourceDomain(mobileImageSrc ?? imageSrc)}
          alt='site logo'
          borderRadius='2xl'
          w='full'
          height='100%'
          objectPosition='center'
          objectFit='cover'
        />
      </picture>
    </AspectRatio>
  )

  if (action?.href) {
    return (
      <Link as={RouterLink} to={action.href} w='full'>
        {contentElem}
      </Link>
    )
  } else {
    return (
      <Button
        onClick={actionTrigger(action)}
        variant='unstyled'
        w='full'
        h='full'
      >
        {contentElem}
      </Button>
    )
  }
}

export default function HeroSection() {
  const { largeBanners, smallBanners, fixedBanners } =
    useBannersFromHomePageConfig()
  const isLargerThanMobile = useLargerThanMobile()

  const heroImageRender = () => {
    if (isFeatureEnabled('heroSectionForAAJogo')) {
      return <HeroSectionForAAJogo />
    }

    if (isFeatureEnabled('heroSectionForBetFiery')) {
      return isLargerThanMobile && <HeroSectionForBetFiery />
    }

    return (
      !!largeBanners && (
        <Widget name='heroImage'>
          <HeroImage
            heroImage={largeBanners?.length ? largeBanners : heroImage}
          />
        </Widget>
      )
    )
  }

  return (
    <>
      <VStack as='section' alignItems='stretch' w='full' spacing='2.5'>
        <HStack alignItems='stretch' w='full'>
          <VStack alignItems='stretch' spacing='2.5' minW='0' flex='1 1 68.06%'>
            {heroImageRender()}
            <Widget name='carousel'>
              {smallBanners ? (
                <Carousel
                  loopNumberLimit={isLargerThanMobile ? 3 : 1}
                  items={smallBanners.length ? smallBanners : carousel?.items}
                  ratio={606 / 377}
                  breakpoints={{
                    [0]: { slidesPerView: 1 },
                    [MAX_MOBILE_WIDTH]: {
                      slidesPerView: carousel?.slidesPerView,
                    },
                  }}
                />
              ) : null}
            </Widget>
          </VStack>
          <DesktopV24HoursRank />
        </HStack>

        {fixedBanners ? (
          <HStack spacing='2.5' w='full'>
            {(fixedBanners?.length ? fixedBanners : callToActions)?.map(
              item => (
                <Box flexBasis='50%' key={item.key}>
                  <CallToActionButton {...item} />
                </Box>
              ),
            )}
          </HStack>
        ) : null}
      </VStack>
    </>
  )
}

function DesktopV24HoursRank() {
  const homePageConfig = useContext(HomePageConfigContext)
  const dailyRanking = homePageConfig?.dailyRanking
  const isLargerThanMobile = useLargerThanMobile()

  const variant = sideRanking?.variant

  if (
    isLargerThanMobile &&
    widgets.includes('sideRanking') &&
    !isH24HoursRankVariant(variant) &&
    (!dailyRanking || dailyRanking?.pcShow)
  ) {
    return (
      <Box flex='31.15%' position='relative'>
        <VStack
          spacing='2.5'
          alignItems='stretch'
          flex='31.15%'
          position='absolute'
          top='0'
          left='0'
          w='full'
          h='full'
        >
          <V24HoursRank variant={variant} />
        </VStack>
      </Box>
    )
  }

  return null
}

export function MobileV24HoursRank() {
  const homePageConfig = useContext(HomePageConfigContext)
  const dailyRanking = homePageConfig?.dailyRanking
  const isLargerThanMobile = useLargerThanMobile()

  const variant = bottomRanking?.variant

  if (
    !isLargerThanMobile &&
    widgetsMobile.includes('bottomRanking') &&
    !isH24HoursRankVariant(variant) &&
    (!dailyRanking || dailyRanking?.mobileShow)
  ) {
    return (
      <VStack maxH='258px' spacing='2.5' alignItems='stretch'>
        <V24HoursRank variant={variant} />
      </VStack>
    )
  }

  return null
}

export function BottomH24HoursRank() {
  const homePageConfig = useContext(HomePageConfigContext)
  const dailyRanking = homePageConfig?.dailyRanking

  const isLargerThanMobile = useLargerThanMobile()

  const variant = bottomRanking?.variant

  const isShowInLargeScreen =
    isLargerThanMobile &&
    widgets.includes('bottomRanking') &&
    isH24HoursRankVariant(variant) &&
    (!dailyRanking || dailyRanking?.pcShow)

  const isShowInSmallScreen =
    !isLargerThanMobile &&
    widgetsMobile.includes('bottomRanking') &&
    isH24HoursRankVariant(variant) &&
    (!dailyRanking || dailyRanking?.mobileShow)

  if (isShowInLargeScreen || isShowInSmallScreen) {
    return <H24HoursRank variant={variant} />
  }

  return null
}
