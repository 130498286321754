import { Box } from '@chakra-ui/react'
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { getVariable, isNotOffice, isProduction } from '@/common/env'
import { getCurrencyCode } from '@/common/locale/currency'
import { useAppSelector } from '@/modules/app/store'
import {
  selectUserId,
  selectUserIsLogin,
  selectUserName,
  selectUserToken,
} from '@/modules/user/userSlice'
import { Maintenance } from '@/pages/slot-and-live'
import {
  useLoginMutation as useGmpDemoLoginMutatation,
  usePlayMutation as useGmpDemoPlayMutatation,
} from '@/services/gmp-demo-api'
import { isQueryError } from '@/services/types'
import property from '@/websites/current/property.json'

import { selectGameList } from '../../gameSlice'
import ThirdPartGameBox from '../ThirdPartGameBox'
import { FreeModeContext } from '../ThirdPartGameWrap'
import { usePlaySelfGameMutation } from './SelfGameApi'

/** 渲染组件 */
export default function SelfGame() {
  const { t } = useTranslation()
  const gameId = useGameIdFromParams()
  const { gameUrl, errorCode, isLoading } = useFetchGameUrl(gameId)

  if (errorCode) {
    return (
      <Maintenance error={t('GAME_WORRONG', { code: errorCode }) as string} />
    )
  }

  return (
    <Box pt={9}>
      <ThirdPartGameBox gameUrl={gameUrl} isLoading={isLoading} />
    </Box>
  )
}

function useGameIdFromParams() {
  const routeParam = useParams()
  const gameList = useAppSelector(selectGameList)
  const gameId = useMemo(
    () =>
      gameList.find(
        item => Number(item?.game_id || 0) === Number(routeParam?.gameId),
      )?.expand?.game_uuid || '',
    [gameList, routeParam?.gameId],
  )
  return gameId
}

const GMP_LANG = getVariable('GMP_LANG') || 'en'
const CURRENCY = getCurrencyCode()
const FREE_PLAY_AMOUNT = '10000'

function handleGameUrl(url: string, startTime: number) {
  if (!url) return ''
  let result = url
  const token = new URLSearchParams(new URL(url).search).get('token')
  const isNeedAttachParams = /^(fundist|wg|pgsoft)/.test(token || '')
  if (isNeedAttachParams) {
    const env = isProduction() ? 'online' : isNotOffice() ? 'pre' : 'dev'
    const theme = property.colorMode ?? 'dark'
    result = `${url}&env=${env}&theme=${theme}`
  }
  return `${result}&startTime=${startTime}`
}

export function useFetchGameUrl(gameId: string) {
  const isLogin = !!useAppSelector(selectUserIsLogin)
  const userId = useAppSelector(selectUserId)
  const userToken = useAppSelector(selectUserToken)
  const userName = useAppSelector(selectUserName)
  const isFreeMode = useContext(FreeModeContext)
  const [isLoading, setIsLoading] = useState(false)
  const [playSelfGame] = usePlaySelfGameMutation()
  const [gmpDemoLogin] = useGmpDemoLoginMutatation()
  const [gmpDemoPlay] = useGmpDemoPlayMutatation()

  const [gameUrl, setGameUrl] = useState('')
  const [errorCode, setErrorCode] = useState<number>()

  const componentStartRef = useRef<number>(0)

  useLayoutEffect(() => {
    componentStartRef.current = Date.now()
  }, [gameId])

  const realMoneyPlay = useCallback(async () => {
    const { data } = await playSelfGame({
      game_id: gameId,
      username: userName,
      currency: CURRENCY,
      lang: GMP_LANG,
    }).unwrap()
    return data
  }, [playSelfGame, gameId, userName])

  const freePlay = useCallback(async () => {
    const loginPayload = isLogin
      ? { user_id: userId, user_name: userName }
      : { user_id: 'guest', user_name: 'guest' }

    const { data: loginData } = await gmpDemoLogin({
      ...loginPayload,
      amount: FREE_PLAY_AMOUNT,
      project_name: property.projectName,
    }).unwrap()

    const { data: playData } = await gmpDemoPlay({
      game_id: gameId,
      user_id: loginData.user_id,
      username: loginData.user_name,
      user_token: isLogin ? userToken : loginData.user_id,
      currency: CURRENCY,
      lang: GMP_LANG,
    }).unwrap()
    return playData
  }, [isLogin, userId, userName, gmpDemoLogin, gmpDemoPlay, userToken, gameId])

  const fetchGameUrl = useCallback(
    async (isForFree?: boolean) => {
      setIsLoading(true)
      try {
        const { game_url } = await (isForFree ? freePlay : realMoneyPlay)()
        setGameUrl(handleGameUrl(game_url, componentStartRef.current))
      } catch (error: unknown) {
        if (isQueryError(error)) {
          setErrorCode(error.status)
        }
      } finally {
        setIsLoading(false)
      }
    },
    [freePlay, realMoneyPlay],
  )

  useEffect(() => {
    if (!gameId) return
    if (!isFreeMode && !isLogin) return
    fetchGameUrl(isFreeMode)
  }, [isLogin, isFreeMode, gameId, fetchGameUrl])

  const result = useMemo(
    () => ({
      gameUrl,
      isLoading,
      errorCode,
    }),
    [errorCode, gameUrl, isLoading],
  )
  return result
}
