import { Image } from '@chakra-ui/react'
import type { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

import LazyImage from '@/components/lazy-image/LazyImage'
import { FavoriteButtonInGameCover } from '@/modules/my-games/favorite-button/FavoriteButtonInGameCover'
import { getPgSoftActivitySwitch } from '@/pages/pgSoftActivity/pgSoftActivitySwitch'

import { GameIconMap } from '../../contants'
import type { GameListItem } from '../../gameInterface'
import GameCard from '../../units/gamecard/AtomGameCard'
import GameCardHover from '../../units/gamecard/AtomGameCardHover'
import MaintCover from '../../units/gamecard/MaintCover'
import { useNavigateToGame } from '../../useNavigateToGame'
import GameCardExtraText from './GameCardExtraText'
type Props = Pick<ComponentProps<typeof GameCard>, 'orientation'> & {
  onClick?: () => void
  info: GameListItem
  gameQuantity?: number
}

function GameCardRender(props: Props) {
  const { info, onClick, orientation = 'portrait' } = props
  const { t } = useTranslation()
  const pgSoftActivityState = getPgSoftActivitySwitch()
  const navigateToGame = useNavigateToGame()
  
  const handleClick = () => {
    // if (info.id === 'view_all') {
    //   navigate(info.path || `/game/all?cate=${type}&label=${label}`)
    // } else {
    //   navigateToGame(info)
    // }
    navigateToGame(info)
    onClick && onClick()
  }

  const hoverLayerRender = () => {
    if (info.is_under_maint === 1) {
      return <MaintCover />
    }
    return (
      <GameCardHover
        orientation={orientation === 'portrait' ? 'vertical' : 'horizontal'}
      >
        {t('GAME_PLAY')}
      </GameCardHover>
    )
  }

  /* 如果正在开启pg限时活动且游戏平台属于pgSoft，出现角标 */
  const pgSoftActivityFlagRender = () => {
    if (
      pgSoftActivityState !== 'online' ||
      !info.expand ||
      info.expand.game_platform !== 'pgsoft'
    ) {
      return null
    }

    return (
      <Image
        src='/assets/images/flag.png'
        position='absolute'
        top='0px'
        left='0px'
        width='51px'
        height='51px'
      />
    )
  }

  const gameLogoRender = () => {
    if (!info.game_logo) return null
    return (
      <LazyImage
        src={(GameIconMap as any)[info.game_logo] || ''}
        position='absolute'
        left='0px'
        bottom='0px'
        height='20px'
        borderBottomLeftRadius='8px'
      />
    )
  }

  return (
    <GameCard
      src={
        orientation === 'landscape'
          ? info.game_cover_cross
          : info.game_cover || ''
      }
      onClick={handleClick}
      orientation={orientation}
    >
      <FavoriteButtonInGameCover
        gameId={String(info?.game_id)}
        gameUuId={info.expand.game_uuid}
      />
      {pgSoftActivityFlagRender()}
      {hoverLayerRender()}
      {gameLogoRender()}
      {info.extraText && <GameCardExtraText text={info.extraText} />}
    </GameCard>
  )
}

export default GameCardRender
