import { useToast } from '@chakra-ui/react'
import { usePrevious } from 'ahooks'
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { isFeatureEnabled } from '@/common/feature'
import { getCurrencyCode } from '@/common/locale/currency'
import { shushuReport } from '@/common/tracker/shushu'
import { useGetBankruptTriggerQuery } from '@/modules/activity/activityApi'
import { selectBankruptTrigger } from '@/modules/activity/ActSlice'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import FundistGame from '@/modules/game/third-game/fundist/fundist'
import OtherGame from '@/modules/game/third-game/other-game/OtherGame'
import SelfGame from '@/modules/game/third-game/self-game/SelfGame'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import RechargeComfirmModal, {
  NAME,
  NAME as RechargeComfirmModalName,
} from '@/modules/inhouse/logic/RechargeComfirmModal'
import { selectUserBalance, selectUserIsLogin } from '@/modules/user/userSlice'
import SlotAndLiveGame from '@/pages/slot-and-live'
import { useAtomModal } from '@/utils/atom-modal/AtomModal'
import { useUserAuth } from '@/utils/hooks'
import property from '@/websites/current/property.json'

import type { GameListItem } from '../gameInterface'
import {
  selectAllGameIncludeSelf,
  selectFreeTryModalStatus,
  selectIsFreeMode,
  setIsFreeTryMode,
} from '../gameSlice'
import { MINIMAL_BET } from './ThirdGameMinimalBet'

const BankruptRechargeV3 = isFeatureEnabled('bankruptRechargeV3')
const NewRechargeComfirmModal = isFeatureEnabled('newRechargeComfirmModal')

// eslint-disable-next-line react-refresh/only-export-components
export function useBalanceInsufficientEffect(
  minimalBet: number,
  isFreeMode?: boolean,
) {
  const { show } = useAtomModal(RechargeComfirmModalName)
  const balance = useAppSelector(state => selectUserBalance(state)?.amount || 0)
  const balancePrev = usePrevious(balance)
  const toast = useToast()
  const { t } = useTranslation()
  const showToastRef = useRef<boolean>(false)

  useEffect(() => {
    if (!NewRechargeComfirmModal || BankruptRechargeV3) {
      return
    }
    if (
      balance !== undefined &&
      (balancePrev === undefined || balance < balancePrev) &&
      balance < minimalBet
    ) {
      show()
    }
  }, [show, balance, balancePrev, minimalBet])

  showToastRef.current =
    BankruptRechargeV3 &&
    balance !== undefined &&
    balance < minimalBet &&
    !isFreeMode

  useEffect(() => {
    return () => {
      if (showToastRef.current) {
        toast({
          title: t('ACCOUNT_INSUF_BALANCE'),
          status: 'error',
        })
      }
    }
  }, [t, toast])
}

export const FreeModeContext = React.createContext<boolean>(false)

function useIsFreeMode(game: GameListItem | undefined) {
  const dispatch = useAppDispatch()
  const isDemoSupported = Boolean(game?.try_play_switch)
  const isFreeModeFromStore = useAppSelector(selectIsFreeMode)
  const freeTryModalStatus = useAppSelector(selectFreeTryModalStatus)

  const initIsFreeModeRef = useRef<boolean | 'invalid'>(
    isDemoSupported && freeTryModalStatus !== 'normal',
  )

  useEffect(() => {
    if (initIsFreeModeRef.current !== 'invalid') {
      dispatch(setIsFreeTryMode(initIsFreeModeRef.current))
      initIsFreeModeRef.current = 'invalid'
    }
  }, [])

  const isFreeMode = useMemo(
    () =>
      initIsFreeModeRef.current !== 'invalid'
        ? initIsFreeModeRef.current
        : isFreeModeFromStore,
    [isFreeModeFromStore],
  )

  return isFreeMode
}

export const GameInfoContext = React.createContext<GameListItem | undefined>(
  undefined,
)

/** 渲染组件 */
export default function ThirdGame() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const routeParam = useParams()
  const { gameId, gameType = '' } = routeParam
  const [userId, token] = useUserAuth()
  const isLogin = !!useAppSelector(selectUserIsLogin)
  const allGames = useAppSelector(selectAllGameIncludeSelf)
  const game = useMemo(
    () =>
      gameId
        ? allGames.find(game => String(game.game_id) === gameId)
        : undefined,
    [allGames, gameId],
  )

  const isFreeMode = useIsFreeMode(game)

  const slotAndLive = ['slot', 'live']
  const { show } = useAtomModal(NAME)
  // 获取用户余额
  const balance = useAppSelector(selectUserBalance)

  // 获取当前货币类型
  const CURRENCY = getCurrencyCode()

  // 获取用户当前的钱

  const amount = balance?.amount || 0

  const projectName = property.projectName

  const bankruptTrigger = useAppSelector(selectBankruptTrigger)

  const minimalBet = useMemo(() => {
    const minimal = MINIMAL_BET
    if (bankruptTrigger) {
      return bankruptTrigger[gameType === 'self' ? 'gmp' : 'third'] || minimal
    }
    return minimal
  }, [CURRENCY, projectName, bankruptTrigger, gameType])

  /** 获取破产活动触发余额 */
  useGetBankruptTriggerQuery(undefined, {
    skip: !BankruptRechargeV3 || !token || !userId,
  })

  useEffect(() => {
    return () => {
      dispatch(setIsFreeTryMode(false))
    }
  }, [dispatch])

  useEffect(() => {
    if (!isLogin && !isFreeMode) {
      navigate('/')
      setTimeout(() => {
        dispatch(openModal({ key: GlobalModalKey.Login }))
      }, 1000)
      return
    }
    if (minimalBet && amount < minimalBet && !BankruptRechargeV3) {
      show()
    }
  }, [amount, minimalBet, show, isLogin, navigate, dispatch, isFreeMode])

  useBalanceInsufficientEffect(minimalBet, isFreeMode)

  useLayoutEffect(() => {
    const start = new Date().getDate()
    if (window.shushu !== undefined) {
      const args = window.shushu
      shushuReport({ ...args, type: 0 })
    }

    return () => {
      const end = new Date().getDate()
      if (window.shushu !== undefined) {
        const args = window.shushu
        shushuReport({ ...args, type: 1, visit_time: end - start })
      }

      window.shushu = undefined
    }
  }, [])

  const [isShowRechargeModal, setIsShowRechargeModal] = useState(() => {
    return (
      property.features.rechargeCountDown &&
      isLogin &&
      minimalBet &&
      amount < minimalBet &&
      !BankruptRechargeV3
    )
  })

  return (
    <GameInfoContext.Provider value={game}>
      <FreeModeContext.Provider value={isFreeMode}>
        {isShowRechargeModal ? (
          <RechargeComfirmModal
            closeCb={() => {
              setIsShowRechargeModal(false)
            }}
          />
        ) : (
          (() => {
            if (slotAndLive.includes(gameType)) {
              // slot游戏 & live游戏
              return <SlotAndLiveGame />
            } else if (gameType === 'fundist') {
              // fundist游戏
              return <FundistGame />
            } else if (['self', 'self_fundist', 'self_wg'].includes(gameType)) {
              // 我们自己的游戏
              return <SelfGame />
            } else {
              // 其他游戏
              return <OtherGame />
            }
          })()
        )}
      </FreeModeContext.Provider>
    </GameInfoContext.Provider>
  )
}
