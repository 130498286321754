import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  createDrawerOpenSelector,
  DrawerKey,
  toggleDrawerStart,
} from '@/modules/app-layout/app-layout-slice'

import { useLargerThanMobile } from './useLargerThanMobile'

export const HOME_GAME_ANCHOR_ELEMENT_ID = 'homeGame'

export function useNavToHomeGame() {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [navToGameId, setNavToGameId] = useState('')
  const dispatch = useAppDispatch()
  const isLeftDrawerOpen = useAppSelector(
    createDrawerOpenSelector(DrawerKey.Left),
  )
  const isLargerThanMobile = useLargerThanMobile()
  const needCloseLeftDrawer = useRef(false)

  needCloseLeftDrawer.current = !isLargerThanMobile && isLeftDrawerOpen

  useEffect(() => {
    if (pathname === '/' && navToGameId) {
      if (needCloseLeftDrawer.current) {
        dispatch(toggleDrawerStart(DrawerKey.Left))
      }
      setTimeout(() => {
        document
          .querySelector(`#${navToGameId}`)
          ?.scrollIntoView({ behavior: 'smooth' })
      }, 0)
      setNavToGameId('')
    }
  }, [dispatch, pathname, navToGameId])

  const navToHomeGame = useCallback(
    (gameId = HOME_GAME_ANCHOR_ELEMENT_ID) => {
      if (pathname !== '/') {
        navigate('/')
      }
      setNavToGameId(gameId)
    },
    [navigate, pathname],
  )

  return navToHomeGame
}
