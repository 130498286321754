import type { ChakraProps } from '@chakra-ui/react'
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Show,
  SimpleGrid,
  Text,
  useTheme,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { getAppConfig } from '@/common/app-config'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useLargerThanMobile } from '@/utils/hooks/useLargerThanMobile'

import type { Banner } from '../app/home-page-config-types'
import { useAppDispatch, useAppSelector } from '../app/store'
import type { IImageLink } from '../app-layout/types'
import { openModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { selectUserIsLogin } from '../user/userSlice'
import Carousel from './Carousel'
import type { HeroSectionConfig } from './HeroSection'
import { HeroImagePrimaryButton } from './HeroSection'

const { carousel } = getAppConfig('heroSection', {
  carousel: { slidesPerView: 3 },
  bottomRanking: { variant: 'landscape' },
}) as unknown as HeroSectionConfig

interface TwoCcolorHeadingProps extends ChakraProps {
  startText: string
  endText: string
}

function TwoCcolorHeading(props: TwoCcolorHeadingProps) {
  const { startText, endText, ...restProps } = props
  return (
    <HStack {...restProps} whiteSpace='nowrap'>
      <Text color='prim.500'>{startText}</Text>
      <Text color='white'>&</Text>
      <Text color='seco.500'>{endText}</Text>
    </HStack>
  )
}

const AAJogoWebContent = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isLogin = !!useAppSelector(selectUserIsLogin)

  const noLoginElem = (
    <HStack justifyContent='space-between' h='290px'>
      <Flex
        flexDirection='column'
        alignItems='flex-start'
        mt='63px'
        maxWidth='400px'
      >
        <TwoCcolorHeading
          startText={t('BZ_WELCOME_T1').toString()}
          endText={t('BZ_WELCOME_T1_1').toString()}
          textAlign='left'
          w='full'
          fontSize='30px'
          fontWeight='extrabold'
          lineHeight='1.33'
        />
        <Text
          fontSize='13px'
          fontWeight='extrabold'
          lineHeight='4'
          color='prim.400'
          wordBreak='break-word'
          mt='9px'
          mb='22px'
        >
          {t(`BZ_WELCOME_D1`, { percentage: '60%' })}
        </Text>
        <HStack w='full' textAlign='left'>
          <HeroImagePrimaryButton w='172px'>
            <Text fontSize='14px'>{t('USER_SIGNUP')}</Text>
          </HeroImagePrimaryButton>
        </HStack>
      </Flex>
      <Box flex='1 0 300px'>
        <LazyImage
          src='/assets/images/mobile-hero-image-fg.png'
          mt='20px'
          mx='auto'
          w='484px'
        />
      </Box>
    </HStack>
  )

  const loginElem = (
    <HStack justifyContent='space-between' h='290px'>
      <Flex
        mt='57px'
        maxW='385px'
        flexDirection='column'
        alignItems='flex-start'
      >
        <Text fontSize='26px' fontWeight='extrabold' mb='11px' color='tert.200'>
          {t('BZ_WELCOME_T2')}
        </Text>
        <Text textStyle='text3' mb='35px' color='prim.400'>
          {t('BZ_WELCOME_D2', { welcome_bonus: '60%' })}
        </Text>
        <SimpleGrid columns={2} gap={5}>
          <Button
            colorScheme='prim'
            size='lg'
            fontSize='14px'
            to='/promotions'
            as={RouterLink}
          >
            {t('BZ_WELCOME_B1')}
          </Button>
          <HeroImagePrimaryButton
            fontSize='14px'
            onClick={() => dispatch(openModal({ key: GlobalModalKey.Wallet }))}
          >
            {t('BZ_WELCOME_B2')}
          </HeroImagePrimaryButton>
        </SimpleGrid>
      </Flex>
      <Box flex='1 0 300px'>
        <LazyImage
          src='/assets/images/desktop-hero-image-fg-login.png'
          mt='20px'
          mx='auto'
          w='238px'
        />
      </Box>
    </HStack>
  )

  return isLogin ? loginElem : noLoginElem
}

const AAJogoMobileContent = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isLogin = !!useAppSelector(selectUserIsLogin)
  const mobileHeroImageFg = '/assets/images/mobile-hero-image-fg.png'
  const mobileHeroImageFgLogin = '/assets/images/mobile-hero-image-fg-login.png'

  const noLoginElem = (
    <Box
      bg='no-repeat center top -10px / 450px auto'
      bgImage={`url(${mobileHeroImageFg})`}
      maxW='400px'
      mx='auto'
    >
      <TwoCcolorHeading
        startText='Sign'
        endText='Play'
        mt='33px'
        fontSize='20px'
        fontWeight='extrabold'
        lineHeight='1.219'
        justifyContent='center'
      />
      <Box pt='211px'>
        <HeroImagePrimaryButton
          maxW='53.33%'
          w='full'
          display='block'
          mx='auto'
          fontSize='14px'
        >
          {t('USER_SIGNUP')}
        </HeroImagePrimaryButton>
      </Box>
    </Box>
  )

  const loginElem = (
    <Flex
      flexDirection='column'
      alignItems='center'
      bg='no-repeat center top -25px / 313.11px auto'
      bgImage={`url(${mobileHeroImageFgLogin})`}
      maxW='400px'
      mx='auto'
    >
      <Text
        fontSize='16px'
        fontWeight='extrabold'
        color='tert.200'
        maxWidth='124px'
        wordBreak='break-word'
        textAlign='center'
      >
        {t('BZ_WELCOME_T2')}
      </Text>
      <Text
        textStyle='text6'
        color='prim.400'
        textAlign='center'
        px='9'
        mb='23px'
        pt='121px'
      >
        {t('BZ_WELCOME_D2', { welcome_bonus: '60%' })}
      </Text>
      <SimpleGrid columns={2} gap={5}>
        <Button
          colorScheme='prim'
          size='lg'
          fontSize='14px'
          as={RouterLink}
          to='/promotions'
        >
          {t('BZ_WELCOME_B1')}
        </Button>
        <HeroImagePrimaryButton
          fontSize='14px'
          onClick={() => dispatch(openModal({ key: GlobalModalKey.Wallet }))}
        >
          {t('BZ_WELCOME_B2')}
        </HeroImagePrimaryButton>
      </SimpleGrid>
    </Flex>
  )

  return isLogin ? loginElem : noLoginElem
}

export function HeroSectionForAAJogo(props: {
  carouselItems?: Banner[] | IImageLink[]
}) {
  const { carouselItems = [] } = props
  const theme = useTheme()
  const imageSrc = '/assets/images/desktop-hero-image-bg.jpg'
  const mobileImageSrc = '/assets/images/mobile-hero-image-bg.jpg'

  const isLargerThanMobile = useLargerThanMobile()

  return (
    <>
      <Box
        mt={{ base: '-2.5', md: '-5' }}
        mx={{ base: '-2.5', md: '0' }}
        isolation='isolate'
        position='relative'
      >
        <picture>
          <source
            srcSet={imageSrc}
            media={`(min-width: ${theme.breakpoints.md})`}
          />
          <Image
            src={mobileImageSrc}
            alt=''
            position='absolute'
            left='0'
            top='0'
            zIndex='-1'
            w='full'
            h={{ base: 'auto', md: '310px' }}
            objectFit='cover'
            objectPosition={{ base: 'center', md: 'right' }}
          />
        </picture>

        <Show above='md'>
          <AAJogoWebContent />
        </Show>
        <Show below='md'>
          <AAJogoMobileContent />
        </Show>
      </Box>
      <Carousel
        loopNumberLimit={isLargerThanMobile ? 3 : 1}
        items={carouselItems}
        ratio={606 / 377}
        breakpoints={{
          [0]: { slidesPerView: 1 },
          [480]: { slidesPerView: carousel?.slidesPerView },
        }}
      />
    </>
  )
}
