import * as Sentry from '@sentry/react'

import { getVariable, isNotOffice, isProduction } from '@/common/env'

// 获取谷歌验证码
export const getGrecaptchaToken = async (action: string) => {
  if (!isNotOffice()) {
    return 'FAKE_TOKEN'
  }

  const recaptchaSiteKey = getVariable('NEXT_PUBLIC_RECAPTCHA_SITE_KEY')
  if (typeof window !== 'undefined' && recaptchaSiteKey) {
    const { grecaptcha } = window as unknown as any
    let token = 'FAKE_TOKEN'
    try {
      token = await grecaptcha?.execute(recaptchaSiteKey, {
        action,
      })
    } catch (e) {
      if (isProduction()) {
        Sentry.captureMessage('grecaptcha_error', {
          extra: {
            err_msg: (e as any)?.message || e,
          },
        })
      }
    }
    return token
  }
  return 'FAKE_TOKEN'
}

/** 新版google recaptcha 接口白名单 */
export const newGoogleRecaptchaWhiteList = [
  '/gw/login/register', // 注册接口
]
