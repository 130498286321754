import type { GameListItem } from '../gameInterface'

/**
 * 筛选出未下线的游戏 && 根据是否维护进行排序
 * @param _game_list GameListItem[]
 * @returns GameListItem[]
 */
export default function sortGame(_game_list: GameListItem[]) {
  const maintGameList = _game_list
    .filter(item => item.is_under_maint === 1)
    .sort((a, b) => a.sort_weight - b.sort_weight)
  const unMaintGameList = _game_list
    .filter(item => item.is_under_maint !== 1)
    .sort((a, b) => a.sort_weight - b.sort_weight)
  const returnList = [...unMaintGameList, ...maintGameList]

  return returnList
}

export function sortGame2(_game_list: GameListItem[]) {
  const maintGameList = _game_list.filter(item => item.is_under_maint === 1)
  const unMaintGameList = _game_list.filter(item => item.is_under_maint !== 1)
  const returnList = [...unMaintGameList, ...maintGameList]

  return returnList
}
