import { Box, Icon, SlideFade, useDisclosure } from '@chakra-ui/react'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useMobile, useUserAuth } from '@/utils/hooks'

import { useAppDispatch, useAppSelector } from '../app/store'
import { selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { useMarkMessageAsReadMutation } from '../notification/api'
import Message from '../notification/components/Message'
import { parseMessageParams } from '../notification/Notification'
import type { Message as MessageType } from '../notification/types'
import {
  DrawerKey,
  notificationList,
  toggleDrawerStart,
} from './app-layout-slice'
import { appLayoutEvents } from './appLayoutEvents'

export type NotificationType = MessageType & {
  show: boolean
}

function SlideBox({ index, item }: { index: number; item: MessageType }) {
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure()
  const dispatch = useAppDispatch()
  const [userId, token] = useUserAuth()
  const [markMessageAsRead] = useMarkMessageAsReadMutation()

  useEffect(() => {
    setTimeout(() => {
      onOpen()
    }, 1000 * index)
  }, [index, onOpen])

  const handleClose = (item: MessageType) => {
    const storageMessageListIds =
      localStorage.getItem('notificationListIds') || ''
    const storageMessageListIdsArray = storageMessageListIds.split(',')
    storageMessageListIdsArray.push(item.guid!)
    localStorage.setItem(
      'notificationListIds',
      storageMessageListIdsArray.join(','),
    )
    onClose()
  }

  /**
   * 该部分用于 抽奖活动中,显示通知的时候,不显示链接,因为链接会跳转其他页面
   */
  const NORMAL_MESSAGE_WITH_NO_LINK = 3
  const { open: isDrawActivityModalOpen } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.DrawActivityModal],
  )
  const isCanShowRedirectLink = useMemo(() => {
    return !isDrawActivityModalOpen
  }, [isDrawActivityModalOpen])

  return (
    <SlideFade
      offsetX='100%'
      in={isOpen}
      unmountOnExit
      style={{
        position: 'relative',
        marginTop: `4px`,
      }}
      onClick={async () => {
        onClose()
        dispatch(toggleDrawerStart(DrawerKey.Right))
        await markMessageAsRead({
          user_id: userId,
          token,
          guid: item.guid!,
        }).unwrap()
      }}
    >
      <Box
        p='3'
        pb='4'
        bg='notificationList.bg'
        mb='1'
        mt='1'
        borderRadius='10px'
        position='relative'
        boxShadow={' 0px 8px 16px 0px rgba(0, 0, 0, 0.10)'}
      >
        <Icon
          as={SharedIcon.Close}
          boxSize={4}
          position='absolute'
          right='2'
          top='2'
          cursor={'pointer'}
          onClick={() => {
            handleClose(item)
          }}
        />
        <Message
          item={{
            ...item,
            show_type: isCanShowRedirectLink
              ? item.show_type
              : NORMAL_MESSAGE_WITH_NO_LINK,
          }}
          scene='index'
          onClose={() => {
            handleClose(item)
          }}
        />
      </Box>
    </SlideFade>
  )
}

// rebuild

const NotificationList = memo(() => {
  const messageList = useAppSelector(notificationList)

  const { pathname } = useLocation()

  const excludeRoutes = ['/game/']

  const isAllowedToShow = useMemo(() => {
    return !excludeRoutes.some(route => pathname.startsWith(route))
  }, [excludeRoutes, pathname])

  const isMobile = useMobile()
  const [user_id, token] = useUserAuth()

  const [isAllPopup, setIsAllPopup] = useState(false)

  const [isModalAllClose, setIsModalAllClose] = useState(false)

  const storageMessageListIds =
    localStorage.getItem('notificationListIds') || ''

  // 未弹出的消息
  const newMessageList = useMemo(() => {
    // 获取已经弹出的消息Ids
    const storageMessageListIdsArray = storageMessageListIds?.split(',') || []
    // 找一下未读的消息
    const _newList = messageList.filter(item => {
      return !storageMessageListIdsArray.includes(item.guid!)
    })

    return _newList
  }, [messageList, storageMessageListIds])

  useEffect(() => {
    if (newMessageList?.length) {
      setIsAllPopup(false)
    }
  }, [newMessageList])

  useEffect(() => {
    appLayoutEvents.on(
      'setNotificationListIsModalAllClose',
      (isModalAllClose: boolean) => {
        setIsModalAllClose(isModalAllClose)
      },
    )
    return () => {
      appLayoutEvents.off('setNotificationListIsModalAllClose')
    }
  }, [])

  const isShowNotification = useMemo(() => {
    return (
      // 有新消息
      newMessageList?.length &&
      isModalAllClose &&
      // 且不是所有的消息都弹出来了
      !isAllPopup &&
      // 且登录了
      user_id &&
      token
    )
  }, [isAllPopup, isModalAllClose, newMessageList?.length, token, user_id])

  const storageCb = () => {
    const allNewIds = newMessageList.map(item => item.guid)
    const storageMessageListIdsArray = storageMessageListIds.split(',')
    const newStorageMessageListIdsArray = new Set([
      ...storageMessageListIdsArray,
      ...allNewIds,
    ])
    localStorage.setItem(
      'notificationListIds',
      Array.from(newStorageMessageListIdsArray).join(','),
    )
  }

  const MobileNotificationList = () => {
    const boxRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      const observer = new MutationObserver((mutationsList, observer) => {
        if (!boxRef.current?.childNodes.length) {
          setIsAllPopup(true)
          storageCb()
          observer.disconnect()
        }
      })
      if (boxRef.current) {
        observer.observe(boxRef.current, { childList: true })
      }

      return () => {
        observer.disconnect()
      }
    }, [])
    return (
      <Box
        ref={boxRef}
        position='absolute'
        top='64px'
        w='full'
        padding='0 10px'
        height='100px'
        margin='0 auto'
        zIndex='9998'
      >
        {newMessageList?.map((item, index) => {
          return (
            <SlideBox
              index={index}
              item={{
                ...item,
                params: parseMessageParams(item.params),
              }}
              key={item.guid}
            />
          )
        })}
      </Box>
    )
  }

  const DesktopNotificationList = () => {
    const boxRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      const observer = new MutationObserver((mutationsList, observer) => {
        if (!boxRef.current?.childNodes.length) {
          setIsAllPopup(true)
          storageCb()
          observer.disconnect()
        }
      })
      if (boxRef.current) {
        observer.observe(boxRef.current, { childList: true })
      }

      return () => {
        observer.disconnect()
      }
    }, [])
    return (
      <Box
        ref={boxRef}
        position='absolute'
        top='64px'
        right='0'
        w='355px'
        padding='0 10px'
        height='100px'
        margin='0 auto'
        zIndex='9999'
      >
        {newMessageList?.map((item, index) => {
          return (
            <SlideBox
              index={index}
              item={{
                ...item,
                params: parseMessageParams(item.params),
              }}
              key={item.guid}
            />
          )
        })}
      </Box>
    )
  }

  return (
    <>
      {isShowNotification && isAllowedToShow ? (
        <>
          {isMobile ? <MobileNotificationList /> : <DesktopNotificationList />}
        </>
      ) : null}
    </>
  )
})

NotificationList.displayName = 'NotificationList'

export default NotificationList
