import SnowSence from './SnowSence'

export function setupSnow(canvas: HTMLCanvasElement) {
  canvas.width = window.innerWidth
  canvas.height = window.innerHeight
  canvas.style.position = 'fixed'
  canvas.style.top = '0'
  canvas.style.left = '0'
  const ctx = canvas.getContext('2d')
  if (!ctx) return

  const offscreenCanvas = document.createElement('canvas')
  offscreenCanvas.width = canvas.width
  offscreenCanvas.height = canvas.height
  const offscreenCtx = offscreenCanvas.getContext('2d')
  if (!offscreenCtx) return

  const snowSence = new SnowSence(offscreenCtx, offscreenCanvas)

  // 监听窗口的resize事件
  window.addEventListener('resize', () => {
    // 在窗口大小改变时，重新设置canvas的大小
    const { innerWidth, innerHeight } = window
    canvas.width = innerWidth
    canvas.height = innerHeight
    offscreenCanvas.width = innerWidth
    offscreenCanvas.height = innerHeight
  })

  function animate() {
    if (!ctx || !offscreenCtx) return
    snowSence.nextFrame()
    ctx.clearRect(0, 0, canvas.width, canvas.height) // 清除主Canvas上一帧的内容
    ctx.drawImage(offscreenCanvas, 0, 0) // 将离屏Canvas绘制到主Canvas上
    requestAnimationFrame(animate) // 请求下一帧
  }

  animate() // 开始动画
}
