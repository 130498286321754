import '@/modules/app/prelude'

import ReactDOM from 'react-dom/client'

import App from '@/modules/app/App'

async function deferRenderer() {
  console.log(process.env.NODE_ENV)
  return
  if (process.env.NODE_ENV !== 'develoment') {
    return
  }

  const { worker } = await import('@/mocks/browsers')

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start()
}

deferRenderer().then(() => {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <App />,
  )
})
