import { api } from '@/services/api'
import type { Response } from '@/services/types'

import type {
  IRaffleConfig,
  ITotalBonus,
  IWheelResult,
  IWinnerItem,
  RecordList,
} from './types'

export const wheelApi = api.injectEndpoints({
  endpoints: builder => ({
    recordList: builder.query<Response<RecordList>, { pageSize: number }>({
      query: ({ pageSize }) => ({
        url: `/raffle/turntable/record/latest/home/${pageSize}`,
        method: 'GET',
      }),
    }),
    winnerList: builder.query<Response<{ list: IWinnerItem[] }>, void>({
      query: () => ({
        url: `/raffle/turntable/winner_list/home`,
        method: 'GET',
      }),
    }),
    raffleConfig: builder.query<Response<IRaffleConfig>, void>({
      query: () => ({
        url: `/raffle/turntable/conf/home`,
        method: 'GET',
      }),
      providesTags: ['Auth', 'wheel'],
    }),
    beginRaffle: builder.mutation<
      Response<IWheelResult>,
      { group: string; temp_id: number; updated_at: number }
    >({
      query: data => ({
        url: `/raffle/turntable`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['wheel'],
    }),
    getTotalBonus: builder.query<Response<ITotalBonus[]>, undefined>({
      query: () => ({
        url: `/raffle/turntable/total_bonus/home`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useRecordListQuery,
  useWinnerListQuery,
  useRaffleConfigQuery,
  useBeginRaffleMutation,
  useGetTotalBonusQuery,
} = wheelApi
