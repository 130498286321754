/* eslint-disable react-refresh/only-export-components */
import type { PropsWithChildren } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { useUserAuth } from '@/utils/hooks'

import { useAppSelector } from '../app/store'
import type { GameListItem } from '../game/gameInterface'
import { selectAllGameIncludeSelf } from '../game/gameSlice'
import { useCollectionHistoryQuery } from './api'
import type { GameCollectionInfo } from './types'

const PAGE_SIZE = 12

const useCollectionGamesData = (collectionGames: GameCollectionInfo[]) => {
  const gameList = useAppSelector(selectAllGameIncludeSelf)

  const collectionGamesHashMap = useMemo(
    () =>
      collectionGames.reduce((acc, cur) => {
        if (cur.game_id) {
          acc[cur.game_id] = cur.game_id
        }
        return acc
      }, {} as Record<string, string | GameListItem>),
    [collectionGames],
  )

  const isGameFavorited = useCallback(
    (gameId: string) => {
      return !!collectionGamesHashMap[gameId]
    },
    [collectionGamesHashMap],
  )

  const showGameList = useMemo(() => {
    if (!collectionGames || !gameList || gameList.length === 0) {
      return []
    } else {
      const entiesMap = {} as Record<string, GameListItem>
      gameList.forEach(item => {
        const gameUuid = item?.expand?.game_uuid
        const gameId = item?.game_id
        if (item?.is_under_maint === 1) {
          return
        }
        // 如果 gameUuid 存在，优先使用 gameUuid, 否则使用 gameId
        if (gameUuid && collectionGamesHashMap[gameUuid]) {
          entiesMap[gameUuid] = item
        } else if (gameId && isGameFavorited(String(gameId))) {
          entiesMap[gameId] = item
        }
      })

      const result: GameListItem[] = []
      collectionGames.forEach(item => {
        const info = item.game_id ? entiesMap[item.game_id] : null
        if (info) {
          result.push(info)
        }
      })
      return result
    }
  }, [collectionGames, collectionGamesHashMap, gameList, isGameFavorited])

  const getRemoveId = useCallback(
    (gameId: string, gameUuid: string) => {
      if (collectionGamesHashMap[gameId]) {
        return gameId
      } else if (collectionGamesHashMap[gameUuid]) {
        return gameUuid
      } else {
        return gameId
      }
    },
    [collectionGamesHashMap],
  )

  return useMemo(
    () => ({
      gameList: showGameList,
      isFavorited: isGameFavorited,
      getRemoveId,
    }),
    [isGameFavorited, showGameList, getRemoveId],
  )
}

export const CollectionGamesContext = createContext<{
  gameList: GameListItem[]
  isFavorited(id: string): boolean
  getRemoveId(gameId: string, gameUuid: string): string
}>({ gameList: [], isFavorited: () => false, getRemoveId: () => '' })

export function CollectionGamesProvider(props: PropsWithChildren) {
  const { children } = props
  const [userId, token] = useUserAuth()
  const { data } = useCollectionHistoryQuery(
    { user_id: userId, token },
    {
      skip: !userId || !token,
      refetchOnMountOrArgChange: true,
    },
  )
  const collectionGames = useMemo(() => data?.data?.game_list ?? [], [data])

  const collectionGamesData = useCollectionGamesData(collectionGames)

  return (
    <CollectionGamesContext.Provider value={collectionGamesData}>
      {children}
    </CollectionGamesContext.Provider>
  )
}

export const useCollectionGames = () => {
  const { gameList } = useContext(CollectionGamesContext)
  return gameList
}

export function useGameGrid(
  collectionGames: GameCollectionInfo[],
  pageSize = PAGE_SIZE,
) {
  const [endIndex, setEndIndex] = useState(pageSize)
  const { gameList: list } = useCollectionGamesData(collectionGames)
  const total = list?.length
  const canLoadMore = total > endIndex
  const showGameList = useMemo(() => {
    return list.slice(0, endIndex)
  }, [list, endIndex])

  const onLoadMore = useCallback(() => {
    setEndIndex(prevState => prevState + pageSize)
  }, [pageSize])

  return useMemo(
    () => ({ showGameList, onLoadMore, total, canLoadMore }),
    [canLoadMore, onLoadMore, showGameList, total],
  )
}
