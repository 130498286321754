import type * as React from 'react'

export const LIST_MAP: {
  [key: string]: string
} = {
  CLP: '/assets/images/clp-icon.png',
  COP: '/assets/images/cop-icon.png',
  MXN: '/assets/images/mxn-icon.png',
  PEN: '/assets/images/pen-icon.png',
  USD: '/assets/images/usd-icon.png',
}

// interface dataProps {
//   guid: string
//   title: string
// }
export interface itemProps {
  value: string
  label: string
}
export interface currencyListProps extends itemProps {
  img: string
}
export interface currencyListItem extends itemProps {
  icon: React.ReactNode
}

export interface currencyListItemSelect extends itemProps {
  icon: JSX.Element
}

// export const formatList = (data: dataProps[]): currencyListProps[] => {
//   const list: currencyListProps[] = data
//     .filter(item => LIST.indexOf(item.title) !== -1)
//     .map(item => {
//       return {
//         value: item.guid,
//         label: item.title,
//         img: LIST_MAP[item.title],
//       }
//     })
//   return list
// }
