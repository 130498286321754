import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import {
  getConfigBaseUrl,
  getPageConfigBaseUrl,
  getVariable,
} from '@/common/env'
import type { HomePageConfig } from '@/modules/app/home-page-config-types'
import type { ModalProps } from '@/modules/auto-modal/ModalInterface'
import type { GameFile } from '@/modules/game/gameInterface'
import type { Promotions } from '@/modules/promotions/types'
import getHashUrl from '@/utils/get-hash-url'
import { projectName } from '@/websites/current/property.json'

const MODAL_RESOURCE_SIGN = getVariable('MODAL_RESOURCE_SIGN')
const HOME_PAGE_CONFIG = getVariable('HOME_PAGE_CONFIG')

const jsonQuery = fetchBaseQuery({
  baseUrl: getConfigBaseUrl(),
})

export const jsonApi = createApi({
  reducerPath: 'json',
  baseQuery: jsonQuery,
  endpoints: builder => ({
    getBounsAndPerson: builder.query<
      { base_person: number; base_bonus: number },
      void
    >({
      query: () => {
        return {
          url: getHashUrl('/public/number.json'),
          method: 'GET',
        }
      },
    }),
    getGameList: builder.query<GameFile, void>({
      query: () => {
        if (['P3N'].includes(projectName)) {
          return {
            url: '/public/game_n.json',
            method: 'GET',
          }
        } else {
          return {
            url: '/public/game2.json',
            method: 'GET',
          }
        }
      },
    }),
  }),
})

export const { useGetBounsAndPersonQuery, useGetGameListQuery } = jsonApi

const pageConfigJsonQuery = fetchBaseQuery({
  baseUrl: getPageConfigBaseUrl(),
})

export const pageConfigApi = createApi({
  reducerPath: 'pageConfigJson',
  baseQuery: pageConfigJsonQuery,
  endpoints: builder => ({
    getModalInfo: builder.query<ModalProps, any>({
      query: () => {
        return {
          url: `/pageconfig/billboardConfig_${MODAL_RESOURCE_SIGN}.json`,
          method: 'GET',
        }
      },
    }),
    getHomePageConfig: builder.query<HomePageConfig, void>({
      query: () => {
        return {
          url: `/pageconfig/homePageConfig_${HOME_PAGE_CONFIG}.json`,
          method: 'GET',
        }
      },
    }),
    getPromotions: builder.query<Promotions, void>({
      query: () => {
        return {
          url: getHashUrl(`/pageconfig/promotions_${projectName}.json`),
          method: 'GET',
        }
      },
    }),
  }),
})

export const {
  useGetModalInfoQuery,
  useGetHomePageConfigQuery,
  useLazyGetPromotionsQuery,
} = pageConfigApi
