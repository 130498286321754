import { Box } from '@chakra-ui/react'
import { type FC, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getPgSoftPageUrl, getVariable } from '@/common/env'
import { getCurrencyCode } from '@/common/locale/currency'
import { getSocket } from '@/common/ws'
import NotFound from '@/components/not-found/NotFound'
import { useAppSelector } from '@/modules/app/store'
import { selectUser } from '@/modules/user/userSlice'
import { getFeatures } from '@/utils/tools'

import { selectGameList } from '../../gameSlice'
import ThirdPartGameBox from '../ThirdPartGameBox'
import { useGetSocketUrlQuery } from './OtherGameApi'

interface GameType2GameIdProps {
  GAME_ID: string
  gameName: string
}

enum GameType {
  PGSOFT = 'pgsoft',
  JILI = 'jili',
  FACAI = 'facai',
}

const gameType2GameParams: Record<GameType, GameType2GameIdProps> = {
  pgsoft: {
    GAME_ID: 'PGSOFT_GAME_ID',
    gameName: 'pgsoft',
  },
  jili: {
    GAME_ID: 'JILI_GAME_ID',
    gameName: 'jili',
  },
  facai: {
    GAME_ID: 'FACAI_GAME_ID',
    gameName: 'facai',
  },
}

const newPgsoft = getFeatures('newPgsoft')

/** 渲染组件 */
export default function JiliGame() {
  const isLogin = !!useAppSelector(selectUser)?.user
  const client_ip = useAppSelector(selectUser)?.user?.ip
  const routeParam = useParams()
  const gameType = (routeParam?.gameType || '') as GameType
  const GAME_ID = getVariable(gameType2GameParams[gameType]?.GAME_ID || '')
  const GAME_NAME = gameType2GameParams[gameType]?.gameName || ''
  const gameList = useAppSelector(selectGameList)

  const gameId = useMemo(() => {
    const game_id = routeParam?.gameId || ''
    return (
      gameList.find(item => item?.game_id === Number(game_id))?.expand
        ?.game_uuid || ''
    )
  }, [gameList, routeParam?.gameId])
  const [gameUrl, setGameUrl] = useState('')
  const [gameDom, setGameDom] = useState('')
  const { data: gameData } = useGetSocketUrlQuery()
  const CURRENCY = getCurrencyCode()
  useEffect(() => {
    if (gameData && isLogin) {
      if (gameData?.data?.url && gameData?.data?.token) {
        const url = String(gameData?.data?.url).replace('/socket.io/', '')
        const { socket } = getSocket(url, gameData?.data?.token)
        timer().then(() => {
          const params = {
            game_uuid: gameId,
            currency: CURRENCY,
          }

          // 如果是facai游戏，需要带上language参数
          gameType === 'facai' &&
            Object.assign(params, {
              language: getVariable('FACAI_LANGUAGE') || '1',
            })

          gameType === 'pgsoft' &&
            newPgsoft &&
            Object.assign(params, {
              client_ip: client_ip || '',
              return_html: true,
            })

          const data = {
            gameName: GAME_NAME,
            gameID: GAME_ID,
            room: '',
            param: params,
          }
          const clash_body = JSON.stringify(data)
          socket.emit('play', clash_body, (data: string) => {
            const _data: {
              code: number
              data: string
            } = JSON.parse(data)

            if (_data.code === 0) {
              try {
                setGameUrl(decodeURIComponent(_data.data))
              } catch (error) {
                setGameUrl(getPgSoftPageUrl())
                setGameDom(_data.data)
              }
            }
          })
        })
      }
    }
  }, [gameData, isLogin, gameId])

  function getSocketStatus() {
    return (window as any).new_gSocket && (window as any).new_gSocket?.connected
  }
  function timer() {
    return new Promise(resolve => {
      const _timer = setInterval(() => {
        if (getSocketStatus()) {
          clearTimeout(_timer)
          resolve(true)
        }
      }, 1000)
    })
  }

  if (!Object.keys(gameType2GameParams).includes(gameType)) {
    return <NotFound />
  }

  return (
    <Box pt={9}>
      <ThirdPartGameBox gameUrl={gameUrl} gameDom={gameDom} />
    </Box>
  )
}
