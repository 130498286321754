import type { AuthQueryArgs } from '@/modules/user/userApi'
import { api } from '@/services/api'
import type { Response } from '@/services/types'

import type { DrawResult, MoneyRainActRsp } from '../types/moneyRainTypes'

export const moneyRainApi = api.injectEndpoints({
  endpoints: build => ({
    // 获取正在进行中的活动
    getStartingMoneyRainAct: build.query<Response<MoneyRainActRsp>, void>({
      query: data => {
        return {
          url: 'api/v1/red_envelope_rain/staring',
          data,
          method: 'GET',
        }
      },
      providesTags: ['MoneyRainAct'],
    }),
    // 获取最近的下一场活动
    getNextMoneyRainAct: build.query<Response<MoneyRainActRsp>, void>({
      query: data => {
        return {
          url: 'api/v1/red_envelope_rain/recently_act',
          data,
          method: 'GET',
        }
      },
      providesTags: ['MoneyRainAct'],
    }),
    //  抽红包
    drawMoneyRain: build.mutation<
      DrawResult,
      AuthQueryArgs & {
        guid: string
      }
    >({
      query: data => {
        return {
          url: 'api/v1/red_envelope_rain/open',
          data,
          method: 'POST',
        }
      },
      invalidatesTags: ['MoneyRainDrawCount'],
    }),

    // 用户奖品
    getUserReward: build.query<
      any,
      AuthQueryArgs & {
        page?: number
        pageSize?: number
      }
    >({
      query: data => {
        return {
          url: 'api/v1/red_envelope_rain/user_rewards',
          data: {
            ...data,
            pageSize: 400,
          },
          method: 'POST',
        }
      },
    }),

    // 领取奖品
    claimReward: build.mutation<Response, AuthQueryArgs>({
      query: guid => {
        return {
          url: 'api/v1/red_envelope_rain/claim_rewards',
          data: { guid },
          method: 'POST',
        }
      },
    }),

    // 获取已抽取的次数
    getDrawCount: build.query<
      Response,
      AuthQueryArgs & {
        guid?: string
      }
    >({
      query: data => {
        return {
          url: 'api/v1/red_envelope_rain/open_frequency',
          data,
          method: 'POST',
        }
      },
      providesTags: ['MoneyRainDrawCount'],
    }),

    // 获取展示的活动内容
    getShowMoneyRainAct: build.query<
      Response<{
        list: []
      }>,
      void
    >({
      query: data => {
        return {
          url: '/api/v1/red_envelope_rain/list_act_info',
          data,
          method: 'GET',
        }
      },
    }),
  }),
})

export const {
  useGetNextMoneyRainActQuery,
  useGetStartingMoneyRainActQuery,
  useLazyGetStartingMoneyRainActQuery,
  useLazyGetNextMoneyRainActQuery,
  useDrawMoneyRainMutation,
  useGetUserRewardQuery,
  useLazyGetUserRewardQuery,
  useLazyGetShowMoneyRainActQuery,
  useClaimRewardMutation,
  useGetDrawCountQuery,
} = moneyRainApi
