export const numtofiexed = (num: number | string, dit = 2) => {
  num = Number(num)
  return num.toFixed(dit)
}

export const toFixed2 = (num: number | string, dit = 2) => {
  num = Number(num)
  const fixedStr = num.toFixed(dit) // 保留两个小数点
  const floorNum = Math.floor(parseFloat(fixedStr) * 100) / 100 // 向下取整，返回 number 类型
  return floorNum
}

export const getScrollTop = () =>
  window.pageYOffset ||
  document.documentElement.scrollTop ||
  document.body.scrollTop
