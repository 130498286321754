import { getUserId, getUserToken } from '@/modules/user/storage'
import { api } from '@/services/api'

interface SelfGameListProps {
  category: string
  cover_url: string
  id: number
  name: string
  provider: string
  status: number
  [key: string]: any
}

interface SelfGamePlayProps {
  game_config: string
  game_url: string
  user_token: string
}

export interface Response<T = unknown> {
  code: number
  data: T
}

export const selfGameApi = api.injectEndpoints({
  endpoints: builder => ({
    // 自营游戏列表
    getSelfList: builder.query<Response<SelfGameListProps[]>, void>({
      query: () => ({
        url: '/gmp/list',
        method: 'GET',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
    }),
    // 自营游戏play
    playSelfGame: builder.mutation<
      Response<SelfGamePlayProps>,
      {
        game_id: number | string
        currency: string
        lang: string
        username: string
        user_token?: string
        user_id?: string
      }
    >({
      query: data => ({
        url: '/gmp/play',
        method: 'POST',
        data: {
          user_token: getUserToken(),
          user_id: getUserId(),
          ...data,
        },
      }),
    }),
  }),
})

export const { useGetSelfListQuery, usePlaySelfGameMutation } = selfGameApi
