import { isFeatureEnabled } from '@/common/feature'

type PMSdk = {
  init: (config: any) => void
  log: (eventName: string, payload: Record<string, any>, target: Target) => void
}

export default function getIsSupportPmsdk(
  sdk: typeof window.pmSdk,
): sdk is PMSdk {
  return isFeatureEnabled('pmSdk') === true && sdk !== undefined
}
