import type { PlacementWithLogical } from '@chakra-ui/react'
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import QRCode, { QRCodeSVG } from 'qrcode.react'
import type { PropsWithChildren } from 'react'

import { useAppSelector } from '@/modules/app/store'
import { selectActionSystemConfig } from '@/modules/app-layout/app-layout-slice'
import type { EventName } from '@/modules/auto-modal/ModalInterface'
import { getDeviceType } from '@/utils/tools'

import LazyImage from '../lazy-image/LazyImage'

type DownloadAppBtnProps = {
  placement?: PlacementWithLogical
  eventName?: EventName
} & PropsWithChildren

const DEVICE = getDeviceType()

export function DownloadAppBtn({
  children,
  placement,
  eventName,
}: DownloadAppBtnProps) {
  const actionSystemConfig = useAppSelector(selectActionSystemConfig)

  if (eventName !== 'download-app') {
    return <>{children}</>
  }

  return (
    <Popover
      placement={placement}
      trigger='hover'
      arrowShadowColor='transparent'
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      {DEVICE === 'pc' && (
        <Portal>
          <PopoverContent w='100%' h='100%' bg='blackAlpha.800' borderWidth={0}>
            <PopoverArrow bg='blackAlpha.800' />
            {actionSystemConfig?.WEB?.code_img_url ? (
              <LazyImage
                src={actionSystemConfig.WEB.code_img_url}
                w='144px'
                h='144px'
              />
            ) : (
              <Box
                position='relative'
                h='144px'
                w='144px'
                display='flex'
                alignItems='center'
                justifyContent='center'
                mx='auto'
              >
                {/* <LazyImage
                  position='absolute'
                  top='50%'
                  left='50%'
                  transform='translate(-50%, -50%)'
                  src='/icon.png'
                  w='40px'
                  h='40px'
                /> */}
                <QRCodeSVG
                  level={'Q'}
                  includeMargin={false}
                  value={location.origin}
                  imageSettings={{
                    src: '/icon.png',
                    x: undefined,
                    y: undefined,
                    height: 30,
                    width: 30,
                    excavate: true,
                  }}
                />
              </Box>
            )}
          </PopoverContent>
        </Portal>
      )}
    </Popover>
  )
}
