import ballPNG from '@/assets/images/snow-backdrop/ball.png'
import caneCandyPNG from '@/assets/images/snow-backdrop/cane-candy.png'
import giftPNG from '@/assets/images/snow-backdrop/gift.png'
import goldBallPNG from '@/assets/images/snow-backdrop/gold-ball.png'
import snowflakesPNG from '@/assets/images/snow-backdrop/snowflakes.png'

function generateX(width: number): number {
  const rand = Math.random()
  const sign = Math.random() < 0.5 ? -1 : 1
  return width / 2 + ((sign * width) / 2) * Math.sqrt(rand)
}

function generateSize(minSize = 32): number {
  return (Math.random() + 1) * minSize
}

function generateSpeed(size = 0, minSpeed = 0.1): number {
  return minSpeed + size * 0.05
}

interface Options {
  minSize: number
  minSpeed: number
}

export class Drops {
  ctx: CanvasRenderingContext2D
  x: number
  y: number
  alpha = 0
  minSize: number
  size: number
  minSpeed: number
  speed: number
  rotation: number
  canvasElement: HTMLCanvasElement
  image: HTMLImageElement

  constructor(
    ctx: CanvasRenderingContext2D,
    canvasElement: HTMLCanvasElement,
    imageUrl: string,
    options: Options = { minSize: 16, minSpeed: 0.1 },
  ) {
    this.ctx = ctx
    this.canvasElement = canvasElement
    this.x = generateX(canvasElement.width)
    this.y = Math.random() * canvasElement.height
    this.minSize = options.minSize
    this.size = generateSize(this.minSize)
    this.minSpeed = options.minSpeed
    this.speed = generateSpeed(this.size, this.minSpeed)
    this.rotation = 0 // 初始化旋转角度为0
    this.image = new Image()
    this.image.src = imageUrl
  }

  get canvasHeight() {
    return this.canvasElement.height
  }

  get canvasWidth() {
    return this.canvasElement.width
  }

  reset() {
    const newSize = generateSize(this.minSize)
    this.y = 0 // 如果雪花已经落出画布，那么让它从顶部开始
    this.rotation = 0 // 重置旋转角度
    this.x = generateX(this.canvasWidth) // 重置x坐标
    this.size = newSize // 重置x坐标
    this.speed = generateSpeed(this.size, this.minSpeed) // 重置x坐标
  }

  update() {
    this.y += this.speed // 更新雪花的位置
    this.rotation += this.speed / 100 // 更新旋转角度
    if (this.y > this.canvasHeight) {
      this.reset()
    }
    this.alpha = 1 - this.y / this.canvasHeight // 计算透明度
  }

  draw() {
    this.ctx.save() // 保存当前状态
    this.ctx.translate(this.x, this.y) // 将坐标原点移动到(x, y)
    this.ctx.rotate(this.rotation)
    this.ctx.globalAlpha = this.alpha // 设置透明度
    this.ctx.drawImage(
      this.image,
      -this.size / 2,
      -this.size / 2,
      this.size,
      this.size,
    )
    this.ctx.globalAlpha = 1 // 重置透明度
    this.ctx.restore() // 恢复原来的状态
  }
}

export class Snowflakes extends Drops {
  constructor(ctx: CanvasRenderingContext2D, canvasElement: HTMLCanvasElement) {
    super(ctx, canvasElement, snowflakesPNG, { minSize: 32, minSpeed: 0.05 })
  }
}

export class CaneCandy extends Drops {
  constructor(ctx: CanvasRenderingContext2D, canvasElement: HTMLCanvasElement) {
    super(ctx, canvasElement, caneCandyPNG, { minSize: 36, minSpeed: 0.2 })
  }
}

export class GiftBox extends Drops {
  constructor(ctx: CanvasRenderingContext2D, canvasElement: HTMLCanvasElement) {
    super(ctx, canvasElement, giftPNG, { minSize: 24, minSpeed: 0.2 })
  }
}

export class Ball extends Drops {
  constructor(ctx: CanvasRenderingContext2D, canvasElement: HTMLCanvasElement) {
    super(ctx, canvasElement, ballPNG, { minSize: 24, minSpeed: 0.2 })
  }
}

export class GoldBall extends Drops {
  constructor(ctx: CanvasRenderingContext2D, canvasElement: HTMLCanvasElement) {
    super(ctx, canvasElement, goldBallPNG, { minSize: 24, minSpeed: 0.2 })
  }
}
