import { Box, Button, Flex, Image } from '@chakra-ui/react'
import React from 'react'

import { decimalFormatByCode } from '@/common/locale/currency-const'
import LazyImage from '@/components/lazy-image/LazyImage'
import type { WalletAmountItemProps } from '@/modules/wallet/interface'
import { formatLocalMoney } from '@/utils/tools'

import { useAppSelector } from '../app/store'
import { selectUserOriginalCurrencySign } from '../user/userSlice'
import { amountAnimation } from './animate'
import useRechargeActivity from './useRechargeActivity'
import { getJoinActFlag, selectIsFromAct } from './walletSlice'

export default function WalletAmountItem({
  value,
  btn_num,
  index,
  isHot,
  active,
  onClick,
}: WalletAmountItemProps) {
  const CURRENCY_SYMBOL = useAppSelector(selectUserOriginalCurrencySign)
  const isFromAct = useAppSelector(selectIsFromAct)
  const joinActFlag = useAppSelector(getJoinActFlag) || isFromAct
  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick()
    }
  }
  const { quickAmountsBouns } = useRechargeActivity()

  const bonus = quickAmountsBouns?.[value] || ''

  const defaultStyles: React.ComponentProps<typeof Box> = {
    display: 'block',
    position: 'absolute',
    width: '100%',
    left: '0px',
    right: '0px',
    margin: 'auto',
    bottom: '2px',
    height: '16px',
    textAlign: 'center',
  }
  return (
    <Box
      as={Button}
      key={value}
      h='60px'
      role='group'
      cursor='pointer'
      position='relative'
      borderRadius='lg'
      overflow='hidden'
      padding={'0px'}
      colorScheme={
        active === index
          ? 'fill-seco-active-amount'
          : 'fill-seco-unactive-amount'
      }
      onClick={handleClick}
    >
      <LazyImage
        src='/assets/images/login-reward-modal/light.png'
        alt='amount-bg'
        position={'absolute'}
        top='50%'
        left='50%'
        transform='translate(-50%,-50%)'
        animation={active === index ? amountAnimation : ''}
        _groupHover={{
          animation: amountAnimation,
        }}
      />
      <Flex
        height={'100%'}
        align={'center'}
        justifyContent='center'
        color={'#fff'}
        position='relative'
        width={'100%'}
        zIndex={2}
        flexDirection='column'
      >
        <Box textStyle={(btn_num || 3) > 3 ? 'h5' : 'h4'}>
          {CURRENCY_SYMBOL} {decimalFormatByCode(CURRENCY_SYMBOL || '', value)}
        </Box>
        <Box textStyle='text6' color='four.400' {...defaultStyles}>
          {joinActFlag &&
            bonus &&
            `+${formatLocalMoney(bonus, CURRENCY_SYMBOL)}`}
        </Box>
      </Flex>
      {isHot && (
        <Box
          position={'absolute'}
          bg='recharge.hot'
          fontSize={'12px'}
          color='#fff'
          right={'0'}
          top='0'
          w='41px'
          zIndex='999'
          height='16px'
          lineHeight={'16px'}
          textAlign={'center'}
          borderRadius={'0px 0px 0px 4px'}
        >
          Hot
        </Box>
      )}
    </Box>
  )
}
