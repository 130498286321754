import type {
  Platform as IPlatform,
  PlatformStorage,
  PlatformThridPartAuth,
  PlatformTracker,
} from './types'

export default class PlatformContainer implements IPlatform {
  _storage?: PlatformStorage
  _thridPartAuth?: PlatformThridPartAuth
  _tracker?: PlatformTracker

  addStorage(storage: PlatformStorage) {
    this._storage = storage
  }

  private get storage() {
    return this._storage
  }

  addThridPartAuth(thridPartAuth: PlatformThridPartAuth) {
    this._thridPartAuth = thridPartAuth
  }

  private get thridPartAuth() {
    return this._thridPartAuth
  }

  addTracker(tracker: PlatformTracker) {
    this._tracker = tracker
  }

  private get tracker() {
    return this._tracker
  }

  firebaseEvent(args: {
    key: string
    value: string | number
    currency?: string | number
    extras?: { [key: string]: string | number }
    [key: string]: any
  }) {
    if (!this.tracker) return null
    this.tracker.firebaseEvent(args)
  }

  googleSingIn() {
    if (!this.thridPartAuth) return null
    return this.thridPartAuth.googleSingIn()
  }

  facebookSingIn() {
    if (!this.thridPartAuth) return null
    return this.thridPartAuth.facebookSingIn()
  }

  setClientStorage(key: string, val: string) {
    if (!this.storage) return null
    this.storage.setClientStorage(key, val)
  }

  getClientStorage(key: string) {
    if (!this.storage) return null
    return this.storage.getClientStorage(key)
  }

  removeClientStorage(key: string) {
    if (!this.storage) return null
    this.storage.removeClientStorage(key)
  }

  clearClientStorage() {
    if (!this.storage) return null
    this.storage.clearClientStorage()
  }

  getAllClientStorage() {
    if (!this.storage) return null
    return this.storage.getAllClientStorage()
  }
}
