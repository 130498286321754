import { Box, Button, Text } from '@chakra-ui/react'
import saveAs from 'file-saver'
import QRCode from 'qrcode.react'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { getConfigBaseUrl } from '@/common/env'
import LazyImage from '@/components/lazy-image/LazyImage'
import TitledDialog from '@/components/modal/TitledDialog'
import {
  closeModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { downloadApp as downloadAppTool } from '@/utils/download-app'
import { getConfigLng, getDeviceType } from '@/utils/tools'
import property from '@/websites/current/property.json'

import type { DiversionItemProps } from '../app/home-page-config-types'
import { useAppDispatch, useAppSelector } from '../app/store'
import { GlobalModalKey } from '../global-modal/types'

const device = getDeviceType()
const lang = getConfigLng()
const APK_LINK = `${getConfigBaseUrl()}${property.apk.LINK}`

// 包名
const APK_NAME = property.apk.NAME

export default function AppDownloadDiversionModal() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { open, data } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.AppDownloadDiversion],
  )
  const modalCfg = useMemo(() => {
    return data?.modalCfg as DiversionItemProps
  }, [data?.modalCfg])

  const handleClose = () => {
    dispatch(closeModal({ key: GlobalModalKey.AppDownloadDiversion }))
  }

  const btnRender = useCallback(() => {
    const downloadApp = () => {
      downloadAppTool({})
      return
    }

    if (device === 'pc') {
      return null
    }

    return (
      <Button w='full' colorScheme='fill-prim' size='lg' onClick={downloadApp}>
        {t(
          device === 'ios' && !modalCfg?.ios_url
            ? 'MOBILE_IOS_COLLECT_TEXT'
            : 'MOBILE_AND_COLLECT_NEWTEXT',
        )}
      </Button>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalCfg])

  return (
    <TitledDialog
      title={t('SAFETY_TIPS')}
      isOpen={open}
      onClose={handleClose}
      size='sm'
    >
      <Box>
        <LazyImage
          src={modalCfg?.language_content?.[lang]?.image}
          alt=''
          height='135px'
          display='block'
          mx='auto'
        />
        <Text
          pt={3}
          pb={6}
          fontSize={14}
          color='gray.700'
          lineHeight={'18px'}
          fontWeight='600'
        >
          {modalCfg?.language_content?.[lang]?.content}
        </Text>
        {btnRender()}

        {device === 'pc' && (
          <>
            {modalCfg?.web_url ? (
              <LazyImage
                src={modalCfg?.web_url}
                alt=''
                height='144px'
                width='144px'
                display='block'
                mx='auto'
              />
            ) : (
              <Box
                height='144px'
                width='144px'
                display='flex'
                alignItems='center'
                justifyContent='center'
                mx='auto'
              >
                <QRCode value={location.origin} />
              </Box>
            )}

            <Text
              m='auto'
              pt={3}
              fontSize={12}
              maxW='240px'
              lineHeight={'16px'}
              fontWeight='600'
              color='gray.700'
              textAlign='center'
            >
              {t('SCAN_WITH_BROWSER')}
            </Text>
          </>
        )}
      </Box>
    </TitledDialog>
  )
}
