import { Box, Text } from '@chakra-ui/react'
import QRCode, { QRCodeSVG } from 'qrcode.react'
import { useTranslation } from 'react-i18next'

import LazyImage from '@/components/lazy-image/LazyImage'
import TitledDialog from '@/components/modal/TitledDialog'

import { useAppDispatch, useAppSelector } from '../app/store'
import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'

export default function QrCodeModal() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { open, data } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.QrCode],
  )

  function handleCloseModal() {
    dispatch(closeModal({ key: GlobalModalKey.QrCode }))
  }

  return (
    <TitledDialog
      title={t(data?.title || '')}
      isOpen={open}
      onClose={handleCloseModal}
      size='sm'
    >
      {data?.imageUrl ? (
        <LazyImage
          src={data.imageUrl}
          alt=''
          height='144px'
          width='144px'
          display='block'
          mx='auto'
        />
      ) : (
        <Box
          position='relative'
          h='144px'
          w='144px'
          display='flex'
          alignItems='center'
          justifyContent='center'
          mx='auto'
        >
          {/* <LazyImage
            position='absolute'
            top='50%'
            left='50%'
            transform='translate(-50%, -50%)'
            src='/icon.png'
            w='40px'
            h='40px'
          />
          <QRCode value={location.origin} /> */}
          <QRCodeSVG
            level={'Q'}
            includeMargin={false}
            value={location.origin}
            imageSettings={{
              src: '/icon.png',
              x: undefined,
              y: undefined,
              height: 30,
              width: 30,
              excavate: true,
            }}
          />
        </Box>
      )}
      <Text
        m='auto'
        pt={3}
        fontSize={12}
        maxW='240px'
        lineHeight={'16px'}
        fontWeight='600'
        color='gray.700'
        textAlign='center'
      >
        {t(data?.desc || '')}
      </Text>
    </TitledDialog>
  )
}
