import type {
  Action,
  AnyAction,
  Middleware,
  PayloadAction,
  ThunkDispatch,
} from '@reduxjs/toolkit'
import {
  createDraftSafeSelector,
  createSelector,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit'
import type { MutationThunkArg } from '@reduxjs/toolkit/dist/query/core/buildThunks'
import { isEmpty, toNumber } from 'lodash-es'
import type { ParsedQuery } from 'query-string'
import queryString from 'query-string'

import { isFeatureEnabledV2 } from '@/common/featureSwiitch'
import {
  getCurrencyCode,
  getSign,
  setCurrencyCode,
} from '@/common/locale/currency'
import { setSupportedCurrencies } from '@/common/locale/currency-const'
import { isFromApp } from '@/common/platform/utils'
import { trackFirstRecharge, trackRegister } from '@/common/tracker'
import { RegType } from '@/common/tracker/const'
import type { RootState } from '@/modules/app/store'
import { STORAGE_KEY } from '@/modules/app-layout/TopBanner'
import {
  getClientStorageForWebView,
  getUserId,
  getUserToken,
  removeUserId,
  removeUserToken,
} from '@/modules/user/storage'
import { localStorage } from '@/utils/localStorage'
import { isConditionError } from '@/utils/rtk-query/handleError'
import property from '@/websites/current/property.json'

import { generateAutoActions, updateAt } from '../app/generateAutoActions'
import { startAppListening } from '../app/listenerMiddleware'
import { clearFavoriteTipsStorage } from '../my-games/favorite-button/useIsFavoriteTipsShow'
import type { LoginArgs, LoginResponse } from '../new-login/loginApi'
import { AccountType, loginApi } from '../new-login/loginApi'
import {
  getHasSetLevelProtection,
  getLevelProtectionStatus,
} from '../new-vip/features/vip-level-protection/utils'
import { newVipApi } from '../new-vip/newVipApi'
import type {
  Ext as UserVipInfoExt,
  LevelProtectionStatus,
  UserVipInfo,
} from '../new-vip/types'
import { bigAgentApi } from '../referral/bigAgentApi'
import { vipEndpoints } from '../vip/apis/endpoints'
import { persistUserCredentials } from './storage'
import type { Balance, CurrencyType, User } from './types'
import { DEVICE_TYPE, userApi } from './userApi'
import { setChannelPurchaseRatio } from '@/utils/gtag/atom-gtag'

// localStorage.removeItem('openTgModal')
// localStorage.removeItem('userId')
// // 是否不显示添加到主屏幕
// localStorage.removeItem('NOT_SHOW_ADD_TO_HOME_SCREEN')
// // 埋点保活上报时间戳
// localStorage.removeItem('nextLoginLogTimeStamp')
// //退出后清除
// sessionStorage.removeItem('code_time')
// sessionStorage.removeItem('last_phone')
// // 充值引导弹窗的倒计时
// localStorage.removeItem('rechargeCountDownFinishedTimeStamp')
// // 下次充值时间戳
// localStorage.removeItem('nextRechargeCountDownTimeStamp')
// // 是否有限时间内充值成功
// localStorage.removeItem('isRechargeSuccessInLimitTime')
// // 移除缓存的消息ids
// localStorage.removeItem('notificationListIds')

const removeItems = [
  'openTgModal',
  'userId',
  'NOT_SHOW_ADD_TO_HOME_SCREEN',
  'nextLoginLogTimeStamp',
  'code_time',
  'last_phone',
  'rechargeCountDownFinishedTimeStamp',
  'nextRechargeCountDownTimeStamp',
  'isRechargeSuccessInLimitTime',
  'notificationListIds',
  'luckyWheelStorage',
  'wheelActivityCountDown',
  'rechargeCountDown',
]
interface BalanceChanged {
  id: string
  amount: number
}

export type UserState = {
  isBigAgent?: boolean
  isLoggedIn: boolean
  token: string
  userId: string
  user?: User
  vipInfo?: UserVipInfo
  vipInfoExt?: UserVipInfoExt & {
    /** 是否开启了VIP保级 */
    hasSetLevelProtection: boolean
    levelProtectionStatus: LevelProtectionStatus
  }
  vipLevelExpireNotifyDays?: number
  balanceFloatNumber?: number[]
  balanceChanged: BalanceChanged | undefined
  clickLogin?: boolean
  clickLogout?: boolean
  // 是否已经充值过
  recharged?: boolean
  // 货币标识 （用于推广页）
  currencySign?: string
  // 原来的货币标识 （用于充值弹窗）
  originalCurrencySign?: string
  /**
   * 新login服务
   * 账号绑定信息
   */
  accountsInfo?: {
    account_type: AccountType
    account_value: string
    verified: boolean
  }[]
  /** 请求用户信息接口状态 */
  loadedUserInfo: boolean
}

if (!getUserToken() && isFromApp()) {
  getClientStorageForWebView()
}

const query = queryString.parse(window.location.search)

function getFirstElementIfArray(
  value: ParsedQuery<string>[keyof ParsedQuery<string>],
): string | null {
  if (Array.isArray(value)) {
    return value[0]
  }
  return value
}

const userId = getFirstElementIfArray(query.userId)
const token = getFirstElementIfArray(query.token)
if (token && userId) {
  localStorage.clear()
  persistUserCredentials(userId, token)
  window.location.href = window.location.pathname
}

const initialState: UserState = {
  isLoggedIn: false,
  token: getUserToken(),
  userId: getUserId(),
  balanceFloatNumber: [],
  isBigAgent: undefined,
  balanceChanged: undefined,
  clickLogin: false,
  clickLogout: false,
  recharged: false,
  currencySign: getSign(),
  originalCurrencySign: getSign(),
  loadedUserInfo: true,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateAt,
    updateBanlance(state, { payload }: PayloadAction<Balance | number>) {
      const CURRENCY = getCurrencyCode() as CurrencyType
      if (state.user) {
        if (typeof payload === 'number') {
          state.user.balance_map[CURRENCY].amount = payload
        } else {
          state.user.balance_map[CURRENCY] = payload
        }
      }
    },
    logout: () => {
      // localStorage.clear()
      removeItems.forEach(item => {
        localStorage.removeItem(item)
      })

      if (window && window?.AndroidWebView?.clearClientStorage) {
        window?.AndroidWebView?.clearClientStorage()
      }
      return { ...initialState, token: '', userId: '', clickLogout: true }
    },
    setClickLogin: (state, action) => {
      state.clickLogin = !!action.payload
    },
    toggleBalanceFloat: (state, { payload }) => {
      const curFloatArr = state.balanceFloatNumber?.slice() || []
      if (payload.clear) {
        curFloatArr.shift()
      } else {
        curFloatArr.push(payload.balance)
        state.balanceChanged = {
          id: Date.now().toString(),
          amount: payload.balance,
        }
      }
      state.balanceFloatNumber = curFloatArr
    },
    changeCurrencySign: (state, { payload }) => {
      state.currencySign = payload
    },
    changeOriginalCurrencySign: (state, { payload }) => {
      state.originalCurrencySign = payload
    },
    setUserInfoLoaded: (state, { payload }: { payload: boolean }) => {
      state.loadedUserInfo = payload
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        isAnyOf(
          userApi.endpoints.login.matchFulfilled,
          userApi.endpoints.loginByFacebookAccout.matchFulfilled,
          userApi.endpoints.loginByGoogleAccout.matchFulfilled,
          userApi.endpoints.loginByGoogleAuthCode.matchFulfilled,
          userApi.endpoints.loginByFacebookAuthCode.matchFulfilled,
          userApi.endpoints.loginByPhoneVerifyCode.matchFulfilled,
        ),
        (state, action) => {
          const { user, token } = action.payload.data
          state.user = user
          state.token = token
          state.userId = user._id
          state.isLoggedIn = true
          state.clickLogin = true
        },
      )
      .addMatcher(
        isAnyOf(
          loginApi.endpoints.newLogin.matchFulfilled,
          loginApi.endpoints.register.matchFulfilled,
          loginApi.endpoints.loginByTelegramAccount.matchFulfilled,
        ),
        (state, action) => {
          const { user_id, token } = action.payload.data
          state.token = token
          state.userId = user_id
          state.isLoggedIn = true
          state.clickLogin = true
        },
      )
      .addMatcher(
        userApi.endpoints.getUserInfo.matchRejected,
        (state, action) => {
          const { error } = action
          if (!isConditionError(error.name)) {
            return { ...initialState, token: '', userId: '' }
          }
        },
      )
      .addMatcher(
        userApi.endpoints.getUserInfo.matchFulfilled,
        (state, action) => {
          state.user = action.payload.data
          state.isLoggedIn = true
          const { bind_currency_info, recharge_amount } = action.payload
            .data as any
          // 用户是否充值过
          if (recharge_amount && Object.keys(recharge_amount).length > 0) {
            state.recharged = true
            state.currencySign = state.originalCurrencySign
            // 重置货币符号 很多地方都是直接用的getSign()，所以这里要重置一下,
            // getSign直接走的getConfig会比state前,所以无法直接用store中的货币符号
            setSupportedCurrencies({
              currency: getCurrencyCode(),
              sign: state.originalCurrencySign,
            })
          }
          setCurrencyCode(
            bind_currency_info?.bind_currency ||
              property.locale.currencies[0] ||
              '',
          )

          state.loadedUserInfo = true
        },
      )
      .addMatcher(
        newVipApi.endpoints.getUserVipInfo.matchFulfilled,
        (state, { payload }) => {
          const hasSetLevelProtection = getHasSetLevelProtection(
            payload.data.ext.level_expire_remain_sec,
            toNumber(payload.data.vip.config.bet_relegation),
            toNumber(payload.data.vip.config.recharge_relegation),
            payload.data.vip.config.vip_expire_effected_at,
          )

          const levelProtectionStatus = getLevelProtectionStatus({
            vipExpireEffectedAt: payload.data.vip.config.vip_expire_effected_at,
            ext: payload.data.ext,
          })

          state.vipInfo = payload.data.user
          state.vipInfoExt = {
            ...payload.data.ext,
            hasSetLevelProtection,
            levelProtectionStatus,
          }
        },
      )
      .addMatcher(
        isFeatureEnabledV2('vip_v3_opt')
          ? vipEndpoints.endpoints.getCommonSettings.matchFulfilled
          : newVipApi.endpoints.getVipCommonConfig.matchFulfilled,
        (state, { payload }) => {
          const key = 'vip_level_expire_notify_days'
          const vipLevelExpireNotifyDays = payload.data.find(
            ({ c_key }) => c_key === key,
          )
          state.vipLevelExpireNotifyDays = toNumber(
            vipLevelExpireNotifyDays?.value,
          )
        },
      )
      .addMatcher(
        bigAgentApi.endpoints.isBigAgent.matchFulfilled,
        (state, { payload }) => {
          state.isBigAgent = payload.data
        },
      )
      .addMatcher(bigAgentApi.endpoints.isBigAgent.matchRejected, state => {
        state.isBigAgent = false
      })
      .addMatcher(
        loginApi.endpoints.getAccountsInfo.matchFulfilled,
        (state, action) => {
          const { account_tokens } = action.payload.data
          state.accountsInfo = account_tokens?.map(item => {
            return item.account_type === AccountType.AT_PHONE
              ? {
                  ...item,
                  account_value: item.account_value.split('|')?.[1],
                }
              : item
          })
        },
      )
  },
})

const selectSelf = (state: RootState) => state
export const selectUser = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => state.user,
)

export const selectUserRecharged = createSelector(
  selectUser,
  user => user.recharged,
)

export const selectUserCurrencySign = createSelector(
  selectUser,
  user => user.currencySign,
)

export const selectUserOriginalCurrencySign = createSelector(
  selectUser,
  user => user.originalCurrencySign,
)

export const selectUserIsLogin = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => selectUser(state).userId && selectUser(state).token,
)

export const selectUserVipInfo = createSelector(
  selectUser,
  userInfo => userInfo.vipInfo,
)

export const selectVipLevel = createSelector(
  selectUserVipInfo,
  userVipInfo => userVipInfo?.level ?? 0,
)
export const selectClickLogin = (state: RootState) =>
  selectUser(state).clickLogin

export const selectClickLogout = (state: RootState) =>
  selectUser(state).clickLogout

export const selectUserName = (state: RootState) =>
  selectUser(state).user?.nick_name ?? ''

export const selectAccount = (state: RootState) =>
  selectUser(state).user?.account ?? ''

export const selectUserToken = (state: RootState) => selectUser(state).token

export const selectUserId = createSelector(selectUser, user => user.userId)

export const selectUserRechargeAmount = createSelector(
  selectUserVipInfo,
  vipInfo => (vipInfo?.recharge ? Number(vipInfo?.recharge) : 0),
)
export const selectUserBetAmount = createSelector(selectUserVipInfo, vipInfo =>
  vipInfo?.bet ? Number(vipInfo?.bet) : 0,
)

// 用户注册时间
export const selectUserRegisterTime = (state: RootState) =>
  selectUser(state).user?.create_at ?? ''

export const selectAvatarId = (state: RootState) =>
  selectUser(state).user?.avatar ?? '0'

export const selectUserBalance = (state: RootState) => {
  const CURRENCY = getCurrencyCode() as CurrencyType
  return selectUser(state).user?.balance_map[CURRENCY]
}

export const selectUserInfo = createSelector(selectUser, user => user.user)

export const selectUserSpreadParent = createSelector(
  selectUserInfo,
  userInfo => userInfo?.spread_info?.spread_parent,
)

export const selectIsBigAgent = (state: RootState) =>
  selectUser(state).isBigAgent

export const selectBalanceChanged = (state: RootState) =>
  selectUser(state).balanceChanged

/**
 * 新登录注册账号绑定信息
 */

export const selectAccountsInfo = createSelector(
  selectUser,
  user => user.accountsInfo,
)

/**
 * 获取用户信息接口请求状态
 */
export const selectUserInfoLoaded = createSelector(
  selectUser,
  user => user.loadedUserInfo,
)

type ActionsType = (typeof userSlice)['actions']
type ResponseType = {
  [k in keyof ActionsType]: ActionsType[k]
}
export const { updateBanlance } = generateAutoActions<ResponseType>(
  userSlice.actions,
)
export const {
  logout,
  toggleBalanceFloat,
  setClickLogin,
  changeCurrencySign,
  changeOriginalCurrencySign,
  setUserInfoLoaded,
} = userSlice.actions
export const userReducer = userSlice.reducer

export type AuthMiddleware<
  State = unknown,
  BasicAction extends Action = AnyAction,
  ExtraThunkArg = undefined,
> = Middleware<
  ThunkDispatch<State, ExtraThunkArg, BasicAction>,
  State,
  ThunkDispatch<State, ExtraThunkArg, BasicAction>
>

const clearUserCredentials = () => {
  if (window && window?.AndroidWebView?.clearClientStorage) {
    window?.AndroidWebView?.clearClientStorage()
  }
  removeUserId()
  removeUserToken()
  clearFavoriteTipsStorage()
}

// 权限中间件
export const authMiddleware: AuthMiddleware = () => next => action => {
  if (
    isAnyOf(
      loginApi.endpoints.verifyCodeLogin.matchFulfilled,
      loginApi.endpoints.setAccount.matchFulfilled,
    )(action)
  ) {
    const { re_login } = action.payload.data

    if (re_login) {
      clearUserCredentials()
    }
  }
  if (userSlice.actions.logout.match(action)) {
    clearUserCredentials()
  } else if (
    isAnyOf(
      userApi.endpoints.login.matchFulfilled,
      userApi.endpoints.loginByFacebookAccout.matchFulfilled,
      userApi.endpoints.loginByGoogleAccout.matchFulfilled,
      userApi.endpoints.loginByFacebookAuthCode.matchFulfilled,
      userApi.endpoints.loginByGoogleAuthCode.matchFulfilled,
      userApi.endpoints.loginByPhoneVerifyCode.matchFulfilled,
    )(action)
  ) {
    sessionStorage.removeItem(STORAGE_KEY)
    const { token, user } = action.payload.data
    persistUserCredentials(user._id, token)
  } else if (
    isAnyOf(
      loginApi.endpoints.newLogin.matchFulfilled,
      loginApi.endpoints.register.matchFulfilled,
      loginApi.endpoints.loginByTelegramAccount.matchFulfilled,
    )(action)
  ) {
    const { token, user_id } = action.payload.data
    persistUserCredentials(user_id, token)
  } else if (userApi.endpoints.getBindInfo.matchRejected(action)) {
    const { error } = action
    if (!isConditionError(error.name)) {
      // return clearUserCredentials()
    }
  }
  return next(action)
}

startAppListening({
  matcher: isAnyOf(
    // userApi.endpoints.getBindInfo.matchRejected,
    userApi.endpoints.getUserInfo.matchRejected,
  ),
  effect: (action, api) => {
    if (!isConditionError(action.error?.name)) {
      api.dispatch(userSlice.actions.logout())
    }
  },
})

/* ======================== 注册埋点 start ======================== */
export enum AccountTypeForTrack {
  None = 'none',
  Email = 'email',
  Phone = 'phone',
  Google = 'google',
  Facebook = 'facebook',
  Telegram = 'telegram',
}

type LoginAction = PayloadAction<
  LoginResponse,
  string,
  { arg: MutationThunkArg & { originalArgs: LoginArgs } }
>

const getTrackDataFromLoginAction = (action: LoginAction) => {
  const accountType = action.meta.arg.originalArgs.account_type as AccountType
  const accountTypeMap = {
    [AccountType.AT_EMAIL]: AccountTypeForTrack.Email,
    [AccountType.AT_PHONE]: AccountTypeForTrack.Phone,
    [AccountType.AT_GOOGLE]: AccountTypeForTrack.Google,
    [AccountType.AT_FACEBOOK]: AccountTypeForTrack.Facebook,
    [AccountType.AT_TELEGRAM]: AccountTypeForTrack.Telegram,
  } as Record<AccountType, AccountTypeForTrack>

  return {
    user_id: action.payload.data.user_id,
    account_type: accountTypeMap[accountType] ?? AccountTypeForTrack.None,
    device_type: DEVICE_TYPE,
    from: action.meta.arg.originalArgs.extra?.from,
    hig_one: action.meta.arg.originalArgs.invite_code,
  }
}

startAppListening({
  matcher: isAnyOf(
    loginApi.endpoints.newLogin.matchFulfilled,
    loginApi.endpoints.register.matchFulfilled,
    loginApi.endpoints.loginByTelegramAccount.matchFulfilled,
  ),
  effect: action => {
    const { user_id } = action.payload.data ?? {}
    if (user_id) {
      window.ta?.login(user_id)
      bindUserIdToAndroidShell(user_id)
    }
  },
})

startAppListening({
  matcher: loginApi.endpoints.register.matchFulfilled,
  effect: action => {
    trackRegister(RegType.Register, getTrackDataFromLoginAction(action))
  },
})

startAppListening({
  matcher: loginApi.endpoints.newLogin.matchFulfilled,
  effect: action => {
    const isRegister = action.payload.data?.is_register
    if (!isRegister) return
    trackRegister(RegType.Register, getTrackDataFromLoginAction(action))
  },
})

function bindUserIdToAndroidShell(userId: string) {
  if (
    window.AndroidWebView &&
    typeof window.AndroidWebView.bindPush === 'function'
  ) {
    window.AndroidWebView.bindPush(JSON.stringify({ user_id: userId }))
  }
}

/* ======================== 注册埋点 end ======================== */

/* ======================== 首充埋点 start ======================== */
startAppListening({
  matcher: userApi.endpoints.rechargeTracker.matchFulfilled,
  effect: (action, api) => {
    const events = action.payload.data?.events
    const eventName = 'event_user_firstpay_success'
    if (
      !isEmpty(events) &&
      Object.prototype.hasOwnProperty.call(events, eventName)
    ) {
      const orderId = events[eventName]
      const userId = selectUserId(api.getState())
      const higOne = selectUserSpreadParent(api.getState()) ?? ''
      const currency = getCurrencyCode()
      const amount = events?.REVENUE
      trackFirstRecharge({
        value: Number(setChannelPurchaseRatio(amount)),
        currency,
        predicted_ltv: amount,
        user_id: userId,
        hig_one: higOne,
        order_id: orderId,
      })
    }
  },
})
/* ======================== 首充埋点 end ======================== */
