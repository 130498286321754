import { Box } from '@chakra-ui/react'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getFundistPageUrl } from '@/common/env'
import { getCurrencyCode } from '@/common/locale/currency'
import { useAppSelector } from '@/modules/app/store'
import { getUserId, getUserToken } from '@/modules/user/storage'

import type { GameListItem } from '../../gameInterface'
import { selectGameList } from '../../gameSlice'
import ThirdPartGameBox from '../ThirdPartGameBox'

/** 渲染组件 */
export default function FundistGame() {
  const PAGE_URL = getFundistPageUrl()
  const { gameId } = useParams()
  const gameList = useAppSelector(selectGameList)

  const currentGame = useMemo(() => {
    return (gameList.find(item => item?.game_id === Number(gameId)) ||
      {}) as GameListItem
  }, [gameList, gameId])

  const getIfreamUlr = useCallback(() => {
    const user_id = getUserId()
    const token = getUserToken()
    const CURRENCY = getCurrencyCode()
    const expand = currentGame?.expand || {}
    const search = `Page=${expand?.game_type || ''}&System=${
      expand?.game_uuid || ''
    }`
    return `${PAGE_URL}&${search}&token=${token}&user_id=${user_id}&Country=${CURRENCY}&gameId=${gameId}`
  }, [PAGE_URL, currentGame])

  return (
    <Box pt={9}>
      <ThirdPartGameBox gameUrl={getIfreamUlr()} />
    </Box>
  )
}
