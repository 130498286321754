import { api } from '@/services/api'
import type { Response } from '@/services/types'

import type { AuthQueryArgs } from '../user/userApi'
import type { RechargeActivityProps } from '../wallet/interface'
import type { ICfgResult, IInfoResult, ILogintaskClaimResult } from './types'

export const loginRewardApi = api.injectEndpoints({
  endpoints: builder => ({
    loginTaskCfq: builder.query<Response<ICfgResult>, AuthQueryArgs>({
      query: data => {
        return {
          url: `/api/v1/login-task/cfg`,
          method: 'POST',
          data,
        }
      },
      providesTags: ['Auth'],
    }),
    loginTaskInfo: builder.query<Response<IInfoResult>, AuthQueryArgs>({
      query: data => ({
        url: `/api/v1/login-task/info`,
        method: 'POST',
        data,
      }),
      providesTags: ['Auth', 'LoginReward'],
    }),
    logintaskClaim: builder.mutation<
      Response<ILogintaskClaimResult>,
      AuthQueryArgs
    >({
      query: data => ({
        url: `/api/v1/login-task/claim`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Auth', 'LoginReward'],
    }),
    rechargeTask: builder.query<
      Response<RechargeActivityProps[]>,
      AuthQueryArgs
    >({
      query: data => ({
        url: '/api/v1/recharge-task/list',
        method: 'POST',
        data,
      }),
    }),
  }),
})

export const {
  useLoginTaskCfqQuery,
  useLoginTaskInfoQuery,
  useLogintaskClaimMutation,
  useRechargeTaskQuery,
} = loginRewardApi
