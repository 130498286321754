import type { ChakraProps } from '@chakra-ui/react'
import { Box, Text } from '@chakra-ui/react'
import { useCountDown } from 'ahooks'
import type { TDate } from 'ahooks/lib/useCountDown'
import React, { useMemo } from 'react'

import bg from './bg.png'

interface CountDownProps extends ChakraProps {
  targetDate?: TDate
  leftTime?: number
  cellRender?(n: string): React.ReactNode
  onEnd?: () => void
  // 倒计时显示是否加上天数 ，用于跨天倒计时
  isHoursAddDay?: boolean
  separator?: React.ReactNode
}

const COUNT_DOWN_ITEM = {
  position: 'relative',
  width: '27px',
  height: '41px',
  borderRadius: '4px',
  background: `url(${bg}) no-repeat `,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '30px',
  color: 'white',
}

// eslint-disable-next-line react-refresh/only-export-components
export function useAtomCountDown(
  props: Pick<CountDownProps, 'leftTime' | 'targetDate'>,
) {
  const { leftTime, targetDate } = props
  const [countDown, formattedRes] = useCountDown({
    ...(targetDate && { targetDate }),
    ...(leftTime && { leftTime }),
  })

  return {
    days: formattedRes.days.toString(),
    hours: formattedRes.hours.toString().padStart(2, '0'),
    minutes: formattedRes.minutes.toString().padStart(2, '0'),
    seconds: formattedRes.seconds.toString().padStart(2, '0'),
    countDown: countDown,
    isFinished: !countDown,
  }
}
/***
 * 倒计时组件
 * @param props.targetDate  目标时间 格式为：YYYY-MM-DD HH:mm:ss 例如： 2021-09-09 00:00:00
 * @param props.leftTime  剩余时间 单位为毫秒
 * @param props.cellRender 单元格渲染函数
 * @param props.onEnd 倒计时结束回调
 */
const AtomCountDown = (props: CountDownProps) => {
  const defaultCellRender = (n: string) => (
    <Box sx={COUNT_DOWN_ITEM}>
      <Text>{n}</Text>
    </Box>
  )

  const {
    targetDate,
    leftTime,
    cellRender = defaultCellRender,
    separator = ':',
    onEnd,
    isHoursAddDay,
    ...restProps
  } = props
  const [_, formattedRes] = useCountDown({
    ...(targetDate && { targetDate }),
    ...(leftTime && { leftTime }),
    onEnd,
  })

  // console.log(formattedRes, 'formattedRes')

  const HMS = useMemo(() => {
    const hours =
      isHoursAddDay?.toString() === 'true'
        ? (formattedRes.days * 24 + formattedRes.hours)
            .toString()
            .padStart(2, '0')
        : formattedRes.hours.toString().padStart(2, '0')
    const minutes = formattedRes.minutes.toString().padStart(2, '0')
    const seconds = formattedRes.seconds.toString().padStart(2, '0')
    return [hours, minutes, seconds]
  }, [
    formattedRes.days,
    formattedRes.hours,
    formattedRes.minutes,
    formattedRes.seconds,
    isHoursAddDay,
  ])

  return (
    <Box
      width='100%'
      padding='0 76.5px'
      marginTop='10px'
      display='flex'
      alignItems='center'
      {...restProps}
    >
      {HMS.map((item, index) => {
        const isLast = index === HMS.length - 1
        return (
          <React.Fragment key={index}>
            {cellRender(item[0])}
            {cellRender(item[1])}
            {!isLast && <Text sx={{ mx: '5px' }}>{separator}</Text>}
          </React.Fragment>
        )
      })}
    </Box>
  )
}

export default AtomCountDown
