import type {
  NumberValidate,
  TextValidate,
  Validate,
  ValidateOption,
} from '@/components/forms/types'

function isValidate(value: unknown): value is Validate {
  return (
    typeof value === 'object' &&
    value !== null &&
    'required' in value &&
    typeof value.required === 'boolean' &&
    (!('label' in value) || typeof value.label === 'string') &&
    (!('error_msg' in value) || typeof value.error_msg === 'string')
  )
}

function isTextVaildate(value: unknown): value is TextValidate {
  return (
    isValidate(value) &&
    (!('len' in value) || typeof value.len === 'number') &&
    (!('min_length' in value) || typeof value.min_length === 'number') &&
    (!('max_length' in value) || typeof value.max_length === 'number') &&
    (!('regex' in value) || typeof value.regex === 'string')
  )
}

function isNumberVaildate(value: unknown): value is NumberValidate {
  return (
    isValidate(value) &&
    (!('min_value' in value) || typeof value.min_value === 'number') &&
    (!('max_value' in value) || typeof value.max_value === 'number') &&
    (!('is_integer' in value) || typeof value.is_integer === 'boolean')
  )
}

function textValidator(
  val: string,
  option: ValidateOption | undefined,
): string | null {
  if (isTextVaildate(option)) {
    const { max_length, min_length, regex, error_msg } = option
    if (val.length < (min_length || 0)) {
      return 'LONG_TOO_SHORT'
    }
    if (val.length > (max_length || Infinity)) {
      return 'LONG_TOO_LONG'
    }
    if (regex && !new RegExp(regex).test(val)) {
      return error_msg || 'FORMAT_ERROR'
    }
  }
  return null
}

function numberValidtor(val: string, option: ValidateOption | undefined) {
  if (isNumberVaildate(option)) {
    const { min_value, max_value } = option
    if (Number(val) < (min_value || 0)) {
      return 'LESSTHEN'
    }
    if (Number(val) > (max_value || Infinity)) {
      return 'MORETHEN'
    }
  }
  return null
}

const validator = {
  text: textValidator,
  number: numberValidtor,
}

export default validator
