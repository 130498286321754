const defaultGameIds = [
  'crash',
  'dice',
  'double',
  'doble',
  'mines',
  'ring',
  'coinflip',
  'limbo',
  'stairs',
  'keno',
  'fierybot',
  'plinko',
  'roulette',
] as const

export type GameIds = typeof defaultGameIds
export type GameId = GameIds[number]
export default defaultGameIds
export type InhouseGameName = GameId

export const displayGameName = (game: string) => game
