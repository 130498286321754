import { useToast } from '@chakra-ui/react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { setLiveChatWigetVisibility } from '@/modules/app-layout/app-layout-slice'
import { selectWithdrawToast } from '@/modules/wallet/walletSlice'

import { useAppDispatch, useAppSelector } from '../app/store'

export function useWithdrawToast() {
  const isToast = useAppSelector(selectWithdrawToast)
  const toast = useToast()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const withdrawToast = useCallback(
    (cb: () => void) => {
      if (isToast) {
        toast.closeAll()
        toast({
          status: 'error',
          title: t('WITHDRAWAL_TOAST'),
          duration: 5000,
        })
        dispatch(setLiveChatWigetVisibility('maximized'))
        return
      }

      cb && cb()
    },
    [isToast],
  )

  return {
    withdrawToast,
  }
}
