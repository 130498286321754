import React, { useState } from 'react'

import { useAppSelector } from '@/modules/app/store'

import type { GameListItem } from '../../gameInterface'
import { selectManufTabItems, selectManuObj } from '../../gameSlice'
import type { Orientation } from '../../units/gamecard/AtomGameCard'
import { GRID_MAX_SIZE } from '../constants'
import ManufacturerTabs from '../game-list/ManufacturerTabs'
import { trackHomeToGameEvt } from '../trackHomeToGameEvt'
import { GameGridWithLoadMoreMemo } from './GameGridWithLoadMoreMemo'
import { BuiltInGameLabel } from './types'

function ManufGameGrid(props: { coverOrientation?: Orientation, gameQuantity?: number }) {
  const [activedTabIndex, setActivedTabIndex] = useState(0)
  const manufTabItems = useAppSelector(selectManufTabItems)
  const allManuObj = useAppSelector(selectManuObj)
  const activedTabId = manufTabItems[activedTabIndex]?.id
  const list = (allManuObj[activedTabId]?.data ?? []).slice(0, GRID_MAX_SIZE)

  const onItemClick = (item: GameListItem) =>
    trackHomeToGameEvt(item, activedTabId)

  return (
    <>
      <ManufacturerTabs
        index={activedTabIndex}
        items={manufTabItems}
        onChange={setActivedTabIndex}
      />
      <GameGridWithLoadMoreMemo
        coverOrientation={props?.coverOrientation || 'landscape'}
        gameQuantity={props?.gameQuantity}
        list={list}
        onItemClick={onItemClick}
        viewAllHref={`/game/all?cate=${BuiltInGameLabel.Manufacturer}&label=${activedTabId}`}
      />
    </>
  )
}

export const ManufGameGridMemo = React.memo(ManufGameGrid)
