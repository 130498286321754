import { api } from '@/services/api'
import type { Response } from '@/services/types'

import type {
  GetCommonSettingsRes,
  GetIsSetVipServiceRes,
  GetTaskListRes,
} from './types'

export const vipEndpoints = api.injectEndpoints({
  endpoints: builder => ({
    getCommonSettings: builder.query<Response<GetCommonSettingsRes>, void>({
      query: () => ({
        url: '/api/v1/vip-config/setting_list',
        method: 'GET',
      }),
    }),

    /** cash back detail */
    getCashbackTotalCount: builder.query<
      Response<{ total: string }>,
      { token: string; user_id: string }
    >({
      query: params => ({
        url: '/api/v1/vip-reward/mycashback',
        method: 'POST',
        data: params,
      }),
    }),

    /** 获取VIP后台任务配置 */
    getTaskList: builder.query<Response<GetTaskListRes>, void>({
      query: () => ({
        url: '/api/v1/vip-config/task_list',
        method: 'GET',
      }),
    }),

    /** 获取VIP后台任务配置 */
    getIsSetVipService: builder.query<
      Response<GetIsSetVipServiceRes>,
      { token: string; user_id: string }
    >({
      query: params => ({
        url: '/api/v1/user/user_tag/customer/vip_customer_info',
        method: 'POST', // GET would be better
        data: params,
      }),
    }),
  }),
})

export const {
  useGetCommonSettingsQuery,
  useGetCashbackTotalCountQuery,
  useGetTaskListQuery,
  useGetIsSetVipServiceQuery,
} = vipEndpoints
