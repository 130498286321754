function ArrowIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.29289 12.2929C4.90237 12.6834 4.90237 13.3166 5.29289 13.7071L11.2929 19.7071C11.6834 20.0976 12.3166 20.0976 12.7071 19.7071L18.7071 13.7071C19.0976 13.3166 19.0976 12.6834 18.7071 12.2929C18.3166 11.9024 17.6834 11.9024 17.2929 12.2929L12 17.5858L6.70711 12.2929C6.31658 11.9024 5.68342 11.9024 5.29289 12.2929Z'
        fill='#3F4F70'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.29289 4.29289C4.90237 4.68342 4.90237 5.31658 5.29289 5.70711L11.2929 11.7071C11.6834 12.0976 12.3166 12.0976 12.7071 11.7071L18.7071 5.70711C19.0976 5.31658 19.0976 4.68342 18.7071 4.29289C18.3166 3.90237 17.6834 3.90237 17.2929 4.29289L12 9.58579L6.70711 4.29289C6.31658 3.90237 5.68342 3.90237 5.29289 4.29289Z'
        fill='#3F4F70'
      />
    </svg>
  )
}

export default ArrowIcon
