import {
  Box,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { t } from 'i18next'

import {
  closeModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { projectName } from '@/websites/current/property.json'
import AddHomeScreenTipsImg from '@/websites/current/public/assets/images/add-home-screen-tips-2.png'

import { useAppDispatch, useAppSelector } from '../store'
import AddIcon from './AddIcon'
import CloseIcon from './CloseIcon'

import ShareIcon from './ShareIcon'

const ColorMap: Record<string, string> = {
  B1: 'white',
  B2: 'white',
  P3N: 'white',
}
const MODAL_CONTENT_CENTER_STYLE = {
  height: 'auto',
  borderRadius: '16px',
  paddingBottom: '1',
  maxWidth: '100%',
  width: 355,
}
const HEADER_STYLE = {
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '18px',
}

const IMAGE_CONTAINER_STYLE = {
  position: 'relative',
  width: '100%',
  minHeight: '315px',
  padding: '10px',
}

const IMAGE_STYLE = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  objectPosition: 'center',
  borderRadius: '8px',
}

function AddToHomeScreenModalGuide() {
  const { open, data } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.AddToHomeScreenGuide],
  )
  const dispatch = useAppDispatch()

  // 关闭弹窗的回调
  const handleClose = () => {
    dispatch(closeModal({ key: GlobalModalKey.AddToHomeScreenGuide }))
  }

  return (
    <Modal isOpen={open} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent sx={MODAL_CONTENT_CENTER_STYLE}>
        <ModalCloseButton
          sx={{
            right: 3,
            top: 4,
          }}
        >
          <CloseIcon />
        </ModalCloseButton>
        <ModalHeader sx={HEADER_STYLE}>
          <Text
            fontSize={16}
            textAlign={'center'}
            fontWeight={800}
            color={'#ffffff'}
          >
            {t('MOBILE_IOS_TITLE')}
          </Text>

          <Box px='5px' pt='20px'>
            <Box display='flex' alignItems='center' mt='3'>
              <Text
                sx={{
                  whiteSpace: 'nowrap',
                }}
                color={'#ADB6C4'}
                mr='2'
              >
                1. {t('MOBILE_IOS_TEXT')}
              </Text>
              <ShareIcon color={ColorMap[projectName] ?? undefined} />
              <Text
                ml='2'
                color={'#ADB6C4'}
                sx={{
                  whiteSpace: 'wrap',
                }}
              >
                {t('MOBILE_IOS_TEXT_2')}
              </Text>
            </Box>
            <Text
              ml='2'
              textAlign={'left'}
              color={'#ADB6C4'}
              sx={{
                whiteSpace: 'wrap',
              }}
            >
              {t('MOBILE_IOS_TEXT_4')}
            </Text>
            <Box display='flex' alignItems='center' mt='3'>
              <Text mr='2' color={'#ADB6C4'}>
                2. {t('MOBILE_IOS_COLLECT_WINDOW_TEXT02')}
              </Text>
              <AddIcon color={ColorMap[projectName] ?? undefined} />
              <Text
                ml='2'
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {t('MOBILE_IOS_COLLECT_TEXT')}
              </Text>
            </Box>
          </Box>
        </ModalHeader>
        <Box sx={IMAGE_CONTAINER_STYLE}>
          <Image
            src={AddHomeScreenTipsImg}
            mt='1'
            loading='lazy'
            sx={IMAGE_STYLE}
          />
        </Box>
        <Text p={5} fontSize={12}>
          {t('MOBILE_IOS_TEXT_3')}
        </Text>
      </ModalContent>
    </Modal>
  )
}

export default AddToHomeScreenModalGuide
