import { chakra, shouldForwardProp } from '@chakra-ui/react'
import type { MotionProps } from 'framer-motion'
import { isValidMotionProp, motion } from 'framer-motion'

/** framer-motion组件 */
const Motion = chakra<typeof motion.div, MotionProps>(motion.div, {
  shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
})

export default Motion
