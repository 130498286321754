import {
  Box,
  Checkbox,
  Flex,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Show,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { Button } from './Button'
import { FREE_TRY_MODAL_SHOW_AGAGIN_KEY as STORAGE_KEY } from './constants'
import type { FreeTryModalProps } from './FreeTryModal'
import * as Images from './image-imports'
import { dontShowItAgainToday } from './utils'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useNavigate } from 'react-router-dom'

export function FreeTryModalPresentational(props: FreeTryModalProps) {
  const {
    isOpen,
    onClose,
    containerRef,
    gameCover,
    desc,
    buttons,
    showCheckbox,
  } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onCloseBtnClick = () => {
    navigate(-1)
    onClose()
  }
  return (
    <Modal
      autoFocus={false}
      trapFocus={false}
      isOpen={isOpen}
      onClose={onClose}
      portalProps={{ containerRef }}
      size='contain'
    >
      <ModalOverlay
        bgColor='rgba(0,0,0,0.8)'
        backdropFilter='none'
        position='absolute'
        w='100%'
        h='100%'
      />

      <ModalContent bg='transparent' boxShadow='none' margin='auto'>
        <Image
          src={Images.gameFreeTryModalBgPng}
          alt='bg'
          width='427px'
          height='407px'
          maxWidth='none'
          top='0'
          left='50%'
          transform='translateX(-50%)'
          position='absolute'
          zIndex={-1}
        />

        <Box
          position='relative'
          mx='auto'
          mt='93px'
          hidden={!gameCover}
          _before={{
            content: "''",
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundImage: `url(${Images.glassMaskPng})`,
            bgSize: '100% 100%',
          }}
        >
          <Image
            src={gameCover ?? ''}
            alt='game cover'
            width='168px'
            height='221px'
            borderRadius='14px'
            backgroundColor='rgb(163, 148, 206)'
          />
        </Box>
        <Text
          textStyle='h7'
          maxWidth='320px'
          mt='22px'
          mx='auto'
          mb='24px'
          textAlign='center'
          color='white'
        >
          {t(desc)}
        </Text>
        <SimpleGrid columns={2} gap='4' mx='auto'>
          <Button {...buttons[0]} colorScheme='primary' />
          <Button {...buttons[1]} colorScheme='secondary' />
        </SimpleGrid>
        <Flex justify='center' mt='6' hidden={!showCheckbox}>
          <Checkbox
            defaultChecked={false}
            onChange={({ target: { checked } }) =>
              dontShowItAgainToday(STORAGE_KEY).onChange(checked)
            }
            borderColor='white'
          />
          <Text ml='2' color='white' textStyle='text6'>
            {t('NOT_REMIND')}
          </Text>
        </Flex>
      </ModalContent>
      <Show below='md'>
        <ModalCloseButton
          color='white'
          position='absolute'
          zIndex='modal'
          w='5'
          h='5'
          top='5'
          right='5'
          onClick={onCloseBtnClick}
          as={SharedIcon.Close}
        />
      </Show>
    </Modal>
  )
}
