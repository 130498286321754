//bind绑定 rebind换绑

import type { CommonVerifyProps, ModalTypeProps } from './type'
// https://tringame.feishu.cn/wiki/TUohwMk3piYuWFkydjzcqXkEngd

export const VERIFY_PHONE_COMMON: CommonVerifyProps = {
  verify_btn_text: 'PH_SEND_BTN', //按钮-获取验证码
  verify_btn_error1: 'VERIFY_CODE_ERR2', //获取验证码超10次提示文案
  verify_phone_error1: 'PHONE_FORMAT_ERR', //输入限制错误提示
  verify_phone_error2: 'PHONE_IS_BOUND', //手机号已被绑定
  verify_code_label: 'PHONE_CODELABEL', //验证码
  verify_code_error1: 'VERIFY_CODE_ERR', //验证码错误
  verify_code_error2: 'GET_VERIfY_CODE_TOO_FAST', // 1分钟内重复获取验证码
}
export const MODAL_TYPE: ModalTypeProps = {
  bind: {
    title: 'PH_BIND_CODE', //
    desc: 'PH_BIND_CODE3', //
    btn_text: 'TO_VERIFY_BUTTON',
    verify_label: 'HISTORY_PHONE', //
    verify_placeholder: 'PH_ENTER', //input占位符
    disabled: false,
    // ...VERIFY_PHONE_COMMON,
  },
  bind_disabled: {
    title: 'PH_BIND_CODE', //
    desc: 'PH_BIND_CODE3', //
    btn_text: 'TO_VERIFY_BUTTON',
    verify_label: 'HISTORY_PHONE', //
    verify_placeholder: 'PH_ENTER', //input占位符
    disabled: true,
    // ...VERIFY_PHONE_COMMON,
  },
  rebind: {
    title: 'CHANGE_PHONE_VERIFY', //更换手机
    desc: '', //
    btn_text: 'NEXT_STEP_BUTTON',

    verify_label: 'BIND_PHONE_NOW', //
    verify_placeholder: 'PH_ENTER', //input占位符

    disabled: true,
    // ...VERIFY_PHONE_COMMON,
  },
  //
  changesuccess: {
    title: 'PHONE_VERIFY', //
    desc: 'CHANGE_PHONE_VERIFY', //
    btn_text: 'PHONE_VERIFY',
    verify_label: 'HISTORY_PHONE', //
    verify_placeholder: 'PH_ENTER', //input占位符
    disabled: false,
    // ...VERIFY_PHONE_COMMON,
  },
  bindsuccess: {
    title: 'PH_BIND_CODE', //
    desc: 'TO_BIND_SUCCESS', //
    btn_text: 'PH_CONFIRM_BTN',
    verify_label: 'HISTORY_PHONE', //
    verify_placeholder: 'PH_ENTER', //input占位符
    disabled: false,
    // ...VERIFY_PHONE_COMMON,
  },

  rebindsuccess: {
    title: 'TO_BIND_UPDATED', //
    desc: 'TO_BIND_CHANGE', //
    btn_text: 'PH_CONFIRM_BTN',
    verify_label: 'HISTORY_PHONE', //
    verify_placeholder: 'PH_ENTER', //input占位符
    disabled: false,
    type: 'rebindsuccess',
    // ...VERIFY_PHONE_COMMON,
  },
}

//
export const VERIFY_CODE_LENGTH = 6

export const VERIFY_CODE_MESSAGE: {
  [key: number]: string
} = {
  1003: 'VERIFY_CODE_ERR',
  1030: 'GET_VERIfY_CODE_TOO_FAST',
  1031: 'VERIFY_CODE_ERR2',
  1032: 'PHONE_IS_BOUND',
}
