import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import { localStorage } from '@/utils/localStorage'

import { startAppListening } from '../app/listenerMiddleware'
import type { RootState } from '../app/store'
import { getUserId } from '../user/storage'
import { bindPhoneApi } from './bindPhoneApi'
import type { ModalTypeE } from './type'
import { ModalType } from './type'

interface BindPhoneSlice {
  showBindPhoneModal: boolean //显示隐藏
  isBindPhone: boolean //是否绑定手机号
  phone: string //  用户输入的手机号
  oldPhone: string //已绑定手机号
  modalType: ModalTypeE // bind | rebind 对应 constants中的文案
  countDownTime: number //倒计时？是否需要
  showCountDown: boolean
  code: string //验证码
  email: string //邮箱
  withdraw_bind_phone_switch: boolean // feat开关
  showBindPhoneModalFromPersonal: boolean
}

const initialState: BindPhoneSlice = {
  showBindPhoneModal: false,
  isBindPhone: false,
  phone: '',
  oldPhone: '',
  modalType: ModalType.bind,
  countDownTime: -1,
  code: '',
  showCountDown: false,
  email: '',
  withdraw_bind_phone_switch: false,
  showBindPhoneModalFromPersonal: false,
}

function handleIsAuthenticatedPhone(phone: string) {
  const authenticatedPhone = JSON.parse(
    localStorage.getItem('authenticatedPhone') ?? '{}',
  )
  const userId = getUserId()

  if (userId && authenticatedPhone?.[userId]?.[phone]) {
    if (
      dayjs().valueOf() - authenticatedPhone[userId][phone] <=
      2 * 60 * 60 * 1000
    ) {
      return true
    }
  }

  return false
}

export const bindPhoneSlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    switchBindPhoneModal: state => {
      if (state.isBindPhone) {
        if (handleIsAuthenticatedPhone(state.phone)) {
          state.modalType = ModalType.changesuccess
          state.phone = ''
        } else {
          state.modalType = ModalType.rebind
        }
      } else {
        state.modalType = state?.phone
          ? ModalType.bind_disabled
          : ModalType.bind
      }
      if (state.showBindPhoneModal && state.isBindPhone) {
        sessionStorage.removeItem('last_phone')
        state.phone = state.oldPhone
      }
      state.showBindPhoneModal = !state.showBindPhoneModal
      state.showBindPhoneModalFromPersonal = false
    },
    openBindPhoneModal: (state, action: PayloadAction<boolean>) => {
      if (handleIsAuthenticatedPhone(state.phone) && state.isBindPhone) {
        state.modalType = ModalType.changesuccess
        state.phone = ''
      }
      state.showBindPhoneModal = true
      state.showBindPhoneModalFromPersonal = !!action.payload
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload
    },
    setOldPhone: (state, action: PayloadAction<string>) => {
      state.oldPhone = action.payload
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    setIsBindPhone: (state, action: PayloadAction<boolean>) => {
      state.isBindPhone = action.payload
    },
    setCountDownTime: (state, action: PayloadAction<number>) => {
      state.countDownTime = Number(action.payload)
    },
    setCode: (state, action: PayloadAction<string>) => {
      state.code = String(action.payload)
    },
    setModalType: (state, action: PayloadAction<ModalTypeE>) => {
      state.modalType = action.payload
    },
    setShowCountDown: (state, action: PayloadAction<boolean>) => {
      state.showCountDown = action.payload
    },
  },
  extraReducers: builder => {
    builder
      // .addMatcher(
      //   bindPhoneApi.endpoints.bindUserPhoneInfo.matchFulfilled,
      //   (state, action) => {
      //     const {
      //       data: { is_bind = false, phone = '', email = '' },
      //     } = action.payload
      //     state.isBindPhone = is_bind
      //     state.phone = phone
      //     state.oldPhone = phone
      //     state.email = email

      //     if (is_bind && state.modalType === ModalType.bind) {
      //       state.modalType = ModalType.rebind
      //     }
      //   },
      // )
      .addMatcher(
        bindPhoneApi.endpoints.getWithdrawBindPhoneSwitch.matchFulfilled,
        (state, action) => {
          const { data } = action.payload
          state.withdraw_bind_phone_switch = data?.enable === 1
        },
      )
      .addMatcher(
        bindPhoneApi.endpoints.offBindUserPhone.matchFulfilled,
        state => {
          const authenticatedPhone = JSON.parse(
            localStorage.getItem('authenticatedPhone') ?? '{}',
          )
          const userId = getUserId()
          localStorage.setItem(
            'authenticatedPhone',
            JSON.stringify({
              ...authenticatedPhone,
              [userId]: { [state.phone]: dayjs().valueOf() },
            }),
          )
        },
      )
  },
})

export const {
  switchBindPhoneModal,
  openBindPhoneModal,
  setPhone,
  setCountDownTime,
  setCode,
  setModalType,
  setShowCountDown,
  setEmail,
  setIsBindPhone,
  setOldPhone,
} = bindPhoneSlice.actions

export const bindPhoneModalReducer = bindPhoneSlice.reducer

export const selectPhone = (state: RootState) => state.bindPhoneModal

export const selectEmail = (state: RootState) => selectPhone(state).email
//
export const getModalType = (state: RootState): ModalTypeE =>
  selectPhone(state).modalType

// 获取绑定的手机号
export const getPhone = (state: RootState) => selectPhone(state).phone

export const getCountDownTime = (state: RootState) =>
  selectPhone(state).countDownTime

export const getCode = (state: RootState) => selectPhone(state).code

export const getIsBindPhone = (state: RootState) =>
  selectPhone(state).isBindPhone

export const getOldPhone = (state: RootState) => selectPhone(state).oldPhone

export const getShowCountDown = (state: RootState) =>
  selectPhone(state).showCountDown
//
export const getOpen = (state: RootState) =>
  selectPhone(state).showBindPhoneModal

// 获取提现绑定手机号开关
export const getBindPhoneSwitch = (state: RootState) =>
  selectPhone(state).withdraw_bind_phone_switch

// 绑定手机号是否来自个人中心
export const getBindPhoneModalFromPersonal = (state: RootState) =>
  selectPhone(state).showBindPhoneModalFromPersonal

startAppListening({
  matcher: bindPhoneApi.endpoints.bindUserPhoneInfo.matchFulfilled,
  effect: (action, store) => {
    const bindPhoneModalState = store.getState().bindPhoneModal
    const userState = store.getState().user.user

    const {
      data: { is_bind = false, phone = '', email = '' },
    } = action.payload

    const isBindPhone = is_bind
    let currentPhone = phone
    const oldPhone = phone
    const currentEmail = email
    const account = userState?.account || ''
    let modalType = bindPhoneModalState.modalType

    if (is_bind && modalType === ModalType.bind) {
      modalType = ModalType.rebind
    }

    if (!is_bind && /^\d+$/.test(account)) {
      if (
        account &&
        [ModalType.bind, ModalType.bind_disabled].includes(
          bindPhoneModalState.modalType as ModalType,
        )
      ) {
        currentPhone = account
        modalType = ModalType.bind_disabled
      }
    }

    store.dispatch(setModalType(modalType))
    store.dispatch(setPhone(currentPhone))
    store.dispatch(setOldPhone(oldPhone))
    store.dispatch(setEmail(currentEmail))
    store.dispatch(setIsBindPhone(isBindPhone))
  },
})
