// 参与该活动的按钮
import { Button } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../app/store'
import { getRechargeAmount, getRechargeTyp } from './rechargeActSlice'
import type { RechargeActBtnProps } from './type'
import { useRechargeButtonClick } from './useRechargeButtonClick'

export default function RechargeActBtn(props: RechargeActBtnProps) {
  const { isRechargeOptionEmpty = false, taskId } = props
  const rechargeAmount = useAppSelector(getRechargeAmount)
  const rechargeType = useAppSelector(getRechargeTyp)
  const {
    trigger: onRechargeButtonClick,
    result: { isLoading },
  } = useRechargeButtonClick(
    Number(rechargeAmount),
    true,
    isRechargeOptionEmpty,
    rechargeType,
    taskId,
  )

  const { t } = useTranslation()

  return (
    <Button
      colorScheme='four'
      size='lg'
      w='full'
      onClick={() => onRechargeButtonClick()}
      isLoading={isLoading}
      loadingText='Loading'
    >
      {t('DEPOSIT_RECHARGE')}
    </Button>
  )
}
