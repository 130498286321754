import { Box, Flex, Highlight, Image, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useMemo } from 'react'

import jb from '@/assets/images/login-reward-modal/jb2.png'
import rewardExpiredPng from '@/assets/images/notification/reward-expired.png'
import rewardReceivedPng from '@/assets/images/notification/reward-received.png'
import { formatLocalMoney, getCompatibleColors } from '@/utils/tools'
import { projectName } from '@/websites/current/property.json'

import type { Message } from '../../types'

// const getBgColor = () => {
//   const bgColorsMap = {
//     M1: 'linear-gradient(0deg, #7E9BC0 0%, #425D7A 100%)',
//   } as Record<string, string>

//   return bgColorsMap[projectName] ?? bgColorsMap['M1']
// }

const getTextColor = (
  isClaimOrExpired: boolean,
  scene: 'list' | 'detail' | 'reward',
) => {
  if (scene === 'reward') {
    return getCompatibleColors(
      'notification.receiveModal.num',
      'rgba(255, 255, 255, 1)',
    )
  }
  return isClaimOrExpired
    ? getCompatibleColors(
        'notification.detail.rewardNumReceived',
        'rgba(255, 255, 255, 0.5)',
      )
    : getCompatibleColors(
        'notification.detail.rewardNum',
        'rgba(255, 255, 255, 1)',
      )
}

function NewMessageReward({
  message,
  scene = 'list',
}: {
  message: Message
  scene?: 'list' | 'detail' | 'reward'
}) {
  const { is_claim_rewards = 0 } = message

  const expireDate = useMemo(() => {
    const expireTimestamp = dayjs(
      message?.created_at ? message.created_at * 1000 : '',
    ).add(message?.rewards?.expire_day || 0, 'day')

    const nowTimestamp = dayjs().valueOf()
    if (expireTimestamp.valueOf() <= nowTimestamp) {
      return 'expire'
    }
    return expireTimestamp.format('MM/DD')
  }, [message])

  const styleMemo = useMemo(() => {
    if (scene === 'list') {
      return {
        boxStyle: {
          w: '40px',
          maxW: '40px',
          h: '40px',
          maxH: '40px',
          borderRadius: '4px',
        },
        wrapperStyle: {},
        prizeStyle: {
          fontSize: '7px',
        },
        coinStyle: {
          h: '38px',
        },
      }
    }

    return {
      wrapperStyle: {
        mx: scene === 'reward' ? '7px' : 0,
        mb: scene === 'reward' ? '7px' : 0,
        w: '64px',
        maxW: '64px',
      },
      boxStyle: {
        w: '64px',
        maxW: '64px',
        h: '64px',
        maxH: '64px',
        borderRadius: '10px',
      },
      prizeStyle: {
        fontSize: '14px',
      },
      coinStyle: {
        h: '64px',
      },
    }
  }, [scene])

  const formatAnount = useMemo(() => {
    const res = formatLocalMoney(message.rewards?.num)

    return res.slice(-3) === '.00' ? res.slice(0, -3) : res
  }, [message.rewards?.num])

  return (
    <Box {...styleMemo.wrapperStyle}>
      <Flex
        {...styleMemo.boxStyle}
        bg='linear-gradient(0deg, #7E9BC0 0%, #425D7A 100%)'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        position='relative'
        flexShrink='0'

        // cursor='pointer'
      >
        <Image
          src={jb}
          width='full'
          {...styleMemo.coinStyle}
          objectFit='cover'
        />
        {/* 只有在详情弹窗的时候，才会出现这个,而且和红点互斥 */}
        {(is_claim_rewards || expireDate === 'expire') && scene === 'detail' ? (
          <Box
            {...styleMemo.boxStyle}
            bg='rgba(0, 0, 0, 0.5)'
            position='absolute'
            top='0'
            left='0'
            display='flex'
            alignItems='center'
            justifyContent='center'
            borderRadius={styleMemo.boxStyle.borderRadius}
          >
            <Image
              src={
                expireDate === 'expire' ? rewardExpiredPng : rewardReceivedPng
              }
              w='28px'
              h='28px'
            />
          </Box>
        ) : null}
        {!is_claim_rewards && expireDate !== 'expire' ? (
          <Box
            position='absolute'
            w='12px'
            height='12px'
            borderRadius='50%'
            bgColor='#F43434'
            top='-6px'
            right='-6px'
          />
        ) : null}
      </Flex>
      {/* 
        详情弹窗或领奖弹窗需要展示金额
      */}
      {['detail', 'reward'].includes(scene) && (
        <Text
          sx={{
            ...styleMemo.prizeStyle,
            color: getTextColor(
              (is_claim_rewards === 1 || expireDate === 'expire') &&
                scene === 'detail',
              scene,
            ),
            fontWeight: '800',
            whiteSpace: 'nowrap',
            marginTop: '8px',
            textAlign: 'center',
          }}
        >
          {formatAnount}
        </Text>
      )}
      {/**
       * 详情弹窗在奖励未领取状态下需要展示奖励过期时间
       */}
      {scene === 'detail' && !is_claim_rewards && (
        <Text
          textStyle='text6'
          mt='4px'
          textAlign='center'
          color={getCompatibleColors('notification.title', 'white')}
        >
          {expireDate === 'expire' ? (
            t('MESSAGE_TEXT1')
          ) : (
            <Highlight
              query={expireDate}
              styles={{
                color: 'tert.500',
                fontWeight: 800,
                fontSize: '12px',
                lineHeight: '16px',
              }}
            >
              {t('MESSAGE_COUNT_DOWN', { date: expireDate }) || ''}
            </Highlight>
          )}
        </Text>
      )}
    </Box>
  )
}

export default NewMessageReward
