import { useToast } from '@chakra-ui/react'
import { t } from 'i18next'

import { useAppDispatch } from '@/modules/app/store'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { toggleBalanceFloat, updateBanlance } from '@/modules/user/userSlice'
import { useUserAuth, useUserInfo } from '@/utils/hooks'

import {
  useClaimAllRewardsMutation,
  useClaimRewardsMutation,
  // useMarkMessageAsReadMutation,
} from '../api'
import NewMessageEvent from '../components/NewMessage/NewMessageEvent'
import type { Message } from '../types'
import { MessageShowType } from '../types'

function useClaimReward() {
  const [claimRewards, { isLoading: isClaiming }] = useClaimRewardsMutation()
  const [claimAllRewards, { isLoading: isClaimingAll }] =
    useClaimAllRewardsMutation()

  const [userId, token] = useUserAuth()
  // const [markMessageAsRead] = useMarkMessageAsReadMutation()
  const { userInfo } = useUserInfo()
  const toast = useToast()

  const dispatch = useAppDispatch()

  const claimAllReward = async () => {
    if (isClaimingAll) {
      return
    }
    try {
      const { data } = await claimAllRewards({
        user_id: userId,
        token,
      }).unwrap()
      const { amount = [] } = data
      if (!amount || amount?.every((everyItem: number) => everyItem === 0)) {
        toast({ title: t('MESSAGE_TOAST1'), status: 'error' })
      } else {
        const currentAmount = amount?.filter(
          item => typeof item === 'number' && item > 0,
        )
        const totalAmount = currentAmount?.reduce((a, b) => a + b, 0)
        dispatch(
          toggleBalanceFloat({
            balance: totalAmount,
          }),
        )
        const blc: number = userInfo.banlance?.amount as number
        const newBlc = blc + totalAmount
        updateBanlance(newBlc)

        NewMessageEvent.emit('openMessageReceiveModal', currentAmount)
      }
    } catch (error) {
      /**
       * todo
       * 没有可领取的奖励需要特殊的报错信息
       **/
      toast({ title: t('NOTIFY_BONUS_GET_FAIL'), status: 'error' })
    }
  }

  const claimReward = async (item?: Message | null) => {
    // 如果消息为空,或者不是奖励消息,或者消息已经领取,则不执行领取操作
    if (
      !item ||
      !item.guid ||
      item.show_type !== MessageShowType.Reward ||
      item.is_claim_rewards ||
      !item.rewards ||
      isClaiming
    ) {
      return
    }
    const rewards = item?.rewards
    try {
      await claimRewards({
        user_id: userId,
        token,
        guid: item.guid,
      }).unwrap()

      if (rewards.num) {
        dispatch(
          toggleBalanceFloat({
            balance: rewards.num,
          }),
        )
        const blc: number = userInfo.banlance?.amount as number
        const newBlc = blc + rewards.num

        updateBanlance(newBlc)

        NewMessageEvent.emit('openMessageReceiveModal', [rewards.num])
      }
    } catch (error) {
      toast({ title: t('NOTIFY_BONUS_GET_FAIL'), status: 'error' })
    }
  }

  return {
    claimReward,
    isClaiming,
    claimAllReward,
    isClaimingAll,
  }
}

export default useClaimReward
