import type { RankGameItem } from '@/modules/home/TwentyFourHoursRank/types'
import { api } from '@/services/api'
import type { Response } from '@/services/types'

// test
export const gameInfoApi = api.injectEndpoints({
  endpoints: builder => ({
    games: builder.query({
      query: () => ({
        url: '/api/v2/game/list',
        method: 'GET',
      }),
    }),
    rankGame: builder.query<
      Response<RankGameItem[]>,
      { type?: number; size?: number }
    >({
      query: (data = { type: 1, size: 10 }) => ({
        url: '/ranking/game',
        method: 'POST',
        data: data,
      }),
    }),
  }),
})

export const { useGamesQuery, useRankGameQuery } = gameInfoApi
