import {
  Button,
  CloseButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { getSign } from '@/common/locale/currency'
import { debug } from '@/common/logger'
import LazyImage from '@/components/lazy-image/LazyImage'
import { toggleBalanceFloat, updateBanlance } from '@/modules/user/userSlice'
import type { WithMessageObject } from '@/services/types'
import { useUserAuth } from '@/utils/hooks'
import useUserInfo from '@/utils/hooks/useUserInfo'

import { useAppDispatch, useAppSelector } from '../app/store'
import {
  closeModal,
  openModal,
  selectModal,
} from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { useExchangeCouponMutation } from '../promotional-event/CouponModal'

export const ERROR_MAP = {
  3000: 'CODE_USED',
  3001: 'CODE_EXPIRED',
  3002: 'CODE_NOT_FOUND',
  3004: 'REGISTER_TIME_OUT',
  3005: 'RECHARGE_TIME_OUT',
  3006: 'RECHARGE_FOR_NEW',
  3007: 'BONUS_CODE_IDERROR',
  3008: 'VIP_LEVEL_ERROR',
}

function RedeemResultModal() {
  const CURRENCY_SYMBOL = getSign()
  const dispatch = useAppDispatch()
  const [searchParam] = useSearchParams()
  const redeem: string = searchParam.get('rdm') || ''
  const { t } = useTranslation()
  const [rewardAmount, setRewardAmount] = useState(0)
  const { open } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.RedeemResult],
  )
  const [userId, token] = useUserAuth()

  const [exchangeCoupon] = useExchangeCouponMutation()
  const toast = useToast()
  const { userInfo } = useUserInfo()

  useEffect(() => {
    if (redeem) {
      if (token) {
        submitRedeem(redeem)
      } else {
        dispatch(openModal({ key: GlobalModalKey.RedeemResult }))
      }
    }
  }, [dispatch, token])

  const handleClose = () => {
    dispatch(closeModal({ key: GlobalModalKey.RedeemResult }))
  }

  const submitRedeem = async (couponCode: string) => {
    try {
      const { data } = await exchangeCoupon({
        number: couponCode,
        token,
        user_id: userId,
      }).unwrap()
      debug(data)
      const reward = JSON.parse(data || '{}')
      const blc: number = userInfo.banlance?.amount as number
      const addAmount = reward.bonus.bet_amount
      debug(reward)
      debug(reward.bonus.bet_amount)
      dispatch(
        toggleBalanceFloat({
          balance: reward.bonus.bet_amount,
        }),
      )
      const res: number = Number(blc) + Number(addAmount)
      setRewardAmount(Number(addAmount))
      dispatch(openModal({ key: GlobalModalKey.RedeemResult }))
      debug(res)
      updateBanlance(Number(res))
    } catch (error: unknown) {
      toast({
        title:
          t((ERROR_MAP as any)[(error as WithMessageObject)?.status]) ||
          (error as WithMessageObject).message,
        status: 'error',
      })
    }
    //window.history.replaceState(null, '', pathname)
    // navigate(pathname)
  }

  return (
    <Modal
      autoFocus={false}
      trapFocus={false}
      isOpen={open}
      onClose={handleClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        bg='bg.rdmModal'
        textAlign='center'
        width='355px'
        minHeight='282px'
        padding='0 20px'
        color='text.rdmModalTitle'
      >
        <CloseButton
          position='absolute'
          right='3'
          top='3'
          onClick={handleClose}
        />

        <LazyImage
          src='/assets/images/redeem-result-modal/box.png'
          alt=''
          height='165px'
          display='block'
          margin='-30px auto 0'
        />
        <Text textStyle='h6' marginBottom='8px'>
          {t('REWARD_SUCCESS_TITLE')}
        </Text>

        {token ? (
          <>
            <Text
              textStyle='h3'
              color='text.rdmModal'
              dangerouslySetInnerHTML={{
                __html:
                  t('CODE_BONUS', {
                    currencySign: CURRENCY_SYMBOL,
                    amount: rewardAmount,
                  }) ?? '',
              }}
            />{' '}
            <Button
              w='full'
              colorScheme='fill-gold'
              size='lg'
              height='46px'
              marginTop='24px'
              onClick={() => {
                handleClose()
              }}
            >
              {t('COMMON_CONFIRM')}
            </Button>
            <Text textStyle='text6' mt='16px' mb='16px'>
              {t('CODE_BONUS_TIPS')}
            </Text>
          </>
        ) : (
          <>
            <Text textStyle='h3' color='text.rdmModal'>
              {t('CODE_BONUS_UNLOGIN')}
            </Text>
            <Button
              w='full'
              colorScheme='fill-gold'
              size='lg'
              height='46px'
              marginTop='24px'
              onClick={() => {
                handleClose()
                dispatch(openModal({ key: GlobalModalKey.Login }))
              }}
            >
              {t('GET_NOW')}
            </Button>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}

const HomeRedeemResultModal: FC = () => {
  const [searchParam] = useSearchParams()
  const redeem: string = searchParam.get('rdm') || ''
  if (redeem) {
    return <RedeemResultModal />
  } else {
    return null
  }
}

export default HomeRedeemResultModal
