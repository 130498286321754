import { debug } from '../logger'
import type {
  PlatformStorage,
  PlatformThridPartAuth,
  PlatformTracker,
  ThirdPartSignResult,
} from './types'

export enum IOS3rdPartyLoginChannel {
  Google = 'google',
  Facebook = 'facebook',
}

interface IOS3rdPartyLoginResult {
  channel: string
  isSigned: boolean
  serverAuthCode: string
}

export class IosStorage implements PlatformStorage {
  setClientStorage(key: string, val: string) {
    window.webkit?.messageHandlers.setClientStorage?.postMessage({ key, val })
  }

  getClientStorage(key: string) {
    return JSON.parse(window.prompt('getClientStorage', key) || '{}')
  }

  removeClientStorage(key: string) {
    window.webkit?.messageHandlers.removeClientStorage?.postMessage({ key })
  }

  clearClientStorage() {
    window.webkit?.messageHandlers.clearClientStorage?.postMessage({})
  }

  getAllClientStorage() {
    const clientStorage = window.prompt('getAllClientStorage') || '{}'
    return JSON.parse(clientStorage)
  }
}

export class IosThirdPartAuth implements PlatformThridPartAuth {
  googleClientId: string
  fbAppId: string

  constructor(serverClientId: string, fbAppId: string) {
    this.googleClientId = serverClientId
    this.fbAppId = fbAppId
  }

  private thirdPartSignIn(channel: IOS3rdPartyLoginChannel) {
    return new Promise<ThirdPartSignResult>((resove, reject) => {
      window.webkit?.messageHandlers.do3rdSignIn?.postMessage({
        channel,
        serverClientId: this.googleClientId,
      })
      window.on3rdSignResult = res => {
        let data: null | IOS3rdPartyLoginResult = null
        if (typeof res === 'string') {
          data = JSON.parse(res) as IOS3rdPartyLoginResult
        } else if (typeof res === 'object') {
          data = res as unknown as IOS3rdPartyLoginResult
        }
        if (!data || data.channel !== channel) {
          reject('invalid data')
        } else if (!data.isSigned) {
          reject('signin failed')
        } else {
          resove({
            channel: data.channel,
            isSigned: data.isSigned,
            authCode: data.serverAuthCode,
          })
        }
      }
    })
  }

  googleSingIn() {
    return this.thirdPartSignIn(IOS3rdPartyLoginChannel.Google)
  }

  facebookSingIn() {
    return this.thirdPartSignIn(IOS3rdPartyLoginChannel.Facebook)
  }
}

export class IosTracker implements PlatformTracker {
  firebaseEvent(args: {
    key: string
    value: string | number
    [key: string]: string | number
  }) {
    window.webkit?.messageHandlers.firebaseEvent?.postMessage({
      key: args.key,
      val: args.value,
    })
  }
}

export class IosVestTracker implements PlatformTracker {
  firebaseEvent(args: {
    key: string
    value: string | number
    currency?: string | number
    extras?: { [key: string]: string | number }
    [key: string]: any
  }) {
    if (
      !window.webkit ||
      !window.webkit.messageHandlers ||
      !window.webkit.messageHandlers.BrushTrack ||
      typeof window.webkit.messageHandlers.BrushTrack.postMessage !== 'function'
    ) {
      return null
    }

    try {
      const { key, value, currency } = args
      const dataVal: Record<string, string | number> = { key }
      if (currency) {
        dataVal.revenue = value
        dataVal.currency = currency
      }
      const dataStr = JSON.stringify(dataVal)
      window.webkit.messageHandlers.BrushTrack.postMessage({ data: dataStr })
    } catch (error) {
      debug(error)
    }
  }
}
