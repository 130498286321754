//根据不同的时间段显示不同的人数
// 上午 11点到晚上11点
// 其他时间
const BASE_USER = [
  {
    number: 6000,
    max: 0.6,
    min: 0.4,
  },
  {
    number: 5000,
    max: 0.6,
    min: 0.4,
  },
  {
    number: 4000,
    max: 0.6,
    min: 0.4,
  },
  {
    number: 3000,
    max: 0.5,
    min: 0.3,
  },
  {
    number: 2000,
    max: 0.5,
    min: 0.3,
  },
  {
    number: 1000,
    max: 0.5,
    min: 0.3,
  },
  {
    number: 800,
    max: 0.5,
    min: 0.3,
  },
  {
    number: 700,
    max: 0.5,
    min: 0.3,
  },
  {
    number: 600,
    max: 0.5,
    min: 0.3,
  },
  {
    number: 1000,
    max: 0.6,
    min: 0.4,
  },
  {
    number: 1200,
    max: 0.6,
    min: 0.4,
  },
  {
    number: 2000,
    max: 0.6,
    min: 0.4,
  },
  {
    number: 3000,
    max: 0.7,
    min: 0.3,
  },
  {
    number: 5000,
    max: 0.6,
    min: 0.3,
  },
  {
    number: 6000,
    max: 0.6,
    min: 0.3,
  },
  {
    number: 7000,
    max: 0.6,
    min: 0.4,
  },
  {
    number: 8000,
    max: 0.6,
    min: 0.4,
  },
  {
    number: 9000,
    max: 0.7,
    min: 0.5,
  },
  {
    number: 10000,
    max: 0.8,
    min: 0.5,
  },
  {
    number: 12000,
    max: 0.6,
    min: 0.4,
  },
  {
    number: 14000,
    max: 0.5,
    min: 0.3,
  },
  {
    number: 16000,
    max: 0.4,
    min: 0.2,
  },
  {
    number: 14000,
    max: 0.3,
    min: 0.2,
  },
  {
    number: 10000,
    max: 0.4,
    min: 0.2,
  },
]

export const generateOnlineUser = () => {
  const hours: any = getLocalTime(-3).getHours()
  const { max, min, number } = BASE_USER[hours]
  const rate = Math.round((Math.random() * (max - min) + min) * 100000) / 100000
  return Math.ceil(Number(number * 10 * (1 + rate)))
}

function getLocalTime(i: number) {
  //参数i为时区值数字，比如北京为东八区则输进8,西5输入-5
  const d = new Date()
  //得到1970年一月一日到现在的秒数
  const len = d.getTime()
  //本地时间与GMT时间的时间偏移差
  const offset = d.getTimezoneOffset() * 60000
  //得到现在的格林尼治时间
  const utcTime = len + offset
  return new Date(utcTime + 3600000 * i)
}

export default generateOnlineUser
