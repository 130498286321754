import { useToast } from '@chakra-ui/react'
import { t } from 'i18next'

import { useUserAuth } from '@/utils/hooks'

import {
  useMarkMessageDeletedMutation,
  useReaderMessageDeletedMutation,
} from '../api'
import type { Message } from '../types'

function useDeleteNotification() {
  const [userId, token] = useUserAuth()
  const [markMessageDeleted, { isLoading: isMarkMsgDeling }] =
    useMarkMessageDeletedMutation()
  const [readedMessageDeleted, { isLoading: isReadedMsgDeling }] =
    useReaderMessageDeletedMutation()
  const toast = useToast()

  const handleDeleteNotificationForReaded = async (
    successCb?: () => void,
    failCb?: () => void,
  ) => {
    if (isReadedMsgDeling) {
      return
    }
    try {
      const { data } = await readedMessageDeleted({
        user_id: userId,
        token,
      }).unwrap()
      if (data?.delete_times === 0) {
        toast({ title: t('MESSAGE_TOAST2'), status: 'error' })
        return
      }
      toast({ title: t('NOTIFY_MSG_DELETE_SUCCESS'), status: 'success' })
      successCb && successCb()
    } catch (error) {
      toast({ title: t('NOTIFY_MSG_DELETE_FAIL'), status: 'error' })
      failCb && failCb()
    }
  }

  const handleDeleteNotification = async (
    item: Message | null,
    successCb?: () => void,
    failCb?: () => void,
  ) => {
    if (!item || !item.guid || isMarkMsgDeling) {
      return
    }
    try {
      await markMessageDeleted({
        user_id: userId,
        token,
        guid: item.guid,
      }).unwrap()
      toast({ title: t('NOTIFY_MSG_DELETE_SUCCESS'), status: 'success' })
      successCb && successCb()
    } catch (error) {
      toast({ title: t('NOTIFY_MSG_DELETE_FAIL'), status: 'error' })
      failCb && failCb()
    }
  }

  return {
    isMarkMsgDeling,
    handleDeleteNotification,
    isReadedMsgDeling,
    handleDeleteNotificationForReaded,
  }
}

export default useDeleteNotification
