import {
  Button,
  Flex,
  Icon,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { useEffect } from 'react'

import ticketIcon from '@/assets/images/lucky-wheel/ticket.png'
import { useAppDispatch } from '@/modules/app/store'
import { useModalConfig } from '@/modules/auto-modal/useModal'
import { closeModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { projectName } from '@/websites/current/property.json'

import luckyWheelEvents from './events'

function PromptModal() {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { onCloseModal } = useModalConfig()

  const dispatch = useAppDispatch()

  useEffect(() => {
    luckyWheelEvents.on('openPromptModal', () => {
      onOpen()
    })
  }, [onOpen])

  return (
    <Modal
      autoFocus={false}
      trapFocus={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={{
        base: 'sm',
        lg: 'md',
      }}
    >
      <ModalOverlay />
      <ModalContent p='7' bg='luckyWheel.promptModalBg'>
        <Icon
          as={SharedIcon.Close}
          w='5'
          h='5'
          fontWeight='bold'
          position='absolute'
          top='4'
          cursor='pointer'
          right='4'
          onClick={() => {
            onClose()
          }}
        />
        <Text textAlign='center' fontWeight='bold' fontSize='18px'>
          {t('MAINTENANCE_TIP_TITLE')}
        </Text>
        <Image src={ticketIcon} w='32' h='32' m='0 auto' mt='5' />
        <Text textStyle='text6' color='gray.700' mt='1'>
          {t('SPIN_REMAIN')}
        </Text>
        <Flex justifyContent='space-between' gap='2' mt='6'>
          <Button
            borderRadius='10px'
            flexBasis='50%'
            colorScheme='fill-tert'
            onClick={() => {
              onClose()
            }}
          >
            {t('USER_HEAD_PROTRAIT_CANCEL')}
          </Button>
          <Button
            borderRadius='10px'
            colorScheme='fill-prim'
            flexBasis='50%'
            onClick={() => {
              dispatch(
                closeModal({
                  key: GlobalModalKey.LuckyWheelActivity,
                }),
              )
              onCloseModal(GlobalModalKey.LuckyWheelActivity)
            }}
          >
            {t('LEAVE')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  )
}

export default PromptModal
