import { useCallback } from 'react'

import { getVariable } from '@/common/env'

import { useAppDispatch, useAppSelector } from '../app/store'
import { selectIsOpenActive } from '../bind-currency/currencySlice'
import { openModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import type { PageChangeEvent } from './usePageChangeNotification'
import { usePageChangeSubscription } from './usePageChangeNotification'

const REGION_LIMIT_LIST = getVariable('REGION_LIMIT_LIST')

export function useRegionLimitGuard() {
  const isOpenActive = useAppSelector(selectIsOpenActive)
  const dispatch = useAppDispatch()
  const handler = useCallback(
    ({ pathname }: PageChangeEvent) => {
      if (!isOpenActive && REGION_LIMIT_LIST.includes(pathname)) {
        dispatch(
          openModal({
            key: GlobalModalKey.RegionLimit,
            data: true,
          }),
        )
      }
    },
    [dispatch, isOpenActive],
  )

  usePageChangeSubscription(handler)
}
