import { isEmpty, trim } from 'lodash-es'

import { getVariable } from '@/common/env'
import { isFeatureEnabled } from '@/common/feature'
import { projectName } from '@/websites/current/property.json'

import { BANK_CODE_MAP } from './backListCode'
import { verifySimpleNumber } from './verifyAllPhone'
import { id_type_2_id_number } from './walletContansts'
//
// 基础邮箱校验
interface VerifyInterface {
  params: {
    [key: string]: string
  }
  value: string
  necessary?: boolean
  removeBlank?: boolean
  pay_method?: string
}
export function verifyEmail(params: VerifyInterface): {
  verify: boolean
  text?: string
} {
  const regVerify =
    /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_-]+([.][a-zA-Z]+){1,2}$/.test(params.value)

  if (!regVerify) {
    return {
      verify: regVerify,
      text: 'WITHDRAW_EMAIL_RIGHT',
    }
  } else {
    const lengthVerify = params.value?.length <= 200
    return {
      verify: lengthVerify,
      text: 'EMAIL_LONG',
    }
  }
}

export function verifyPhone(params: VerifyInterface): {
  verify: boolean
  text?: string
} {
  if (params.value === '') {
    return {
      verify: false,
      text: 'DEPOSIT_INPUT_PHONE',
    }
  } else {
    return verifySimpleNumber(params.value)
  }
}

// PIX cpf 校验
export function verifyPix(params: VerifyInterface): {
  verify: boolean
  text?: string
} {
  const value = params.value.replace(/[^\d]+/g, '')
  if (!/^\d{11}$/.test(value)) {
    return {
      verify: false,
      text: 'WITHDRAW_BR_TIPS_CPF',
    }
  }

  // const value = params.value.replace(/[^\d]+/g, '')

  let sum = 0
  for (let i = 0; i < 9; i++) {
    sum += parseInt(value.charAt(i)) * (10 - i)
  }
  let rest = sum % 11
  const digit1 = rest < 2 ? 0 : 11 - rest
  sum = 0
  for (let j = 0; j < 10; j++) {
    sum += parseInt(value.charAt(j)) * (11 - j)
  }
  rest = sum % 11
  const digit2 = rest < 2 ? 0 : 11 - rest
  const verify =
    digit1 === parseInt(value.charAt(9)) &&
    digit2 === parseInt(value.charAt(10))
  return {
    verify,
    text: 'AMOUNT_PIX_WRONG',
  }
}
//
export function verifyAccountNo(params: VerifyInterface): {
  verify: boolean
  text?: string
} {
  const verify = /^\d{11}$/.test(params.value)
  return {
    verify,
    text: '',
  }
}
//
export function verifyCommon(params: VerifyInterface): {
  verify: boolean
  text?: string
} {
  const verify = /\S/.test(params.value)
  return {
    verify,
  }
}
// 墨西哥提现
export function verifyMxnWithdrawParams(data: VerifyInterface): {
  verify: boolean
  text?: string
} {
  const reg_clabe = /^\d{18}$/
  let {
    params: { bank_code = 'acbc' },
  } = data
  const {
    params: { account_type = '' },
    value,
  } = data

  if (isFeatureEnabled('isCOLseriesWallet')) {
    return verifyAccount(data)
  }

  bank_code = bank_code.replace(/[^\w^\s^\u4e00-\u9fa5]/gi, '').toUpperCase()
  bank_code = bank_code?.replace(/\s/g, '') || ''

  const BANK_CODE = String(BANK_CODE_MAP[bank_code]) || ''

  if (account_type && account_type === 'clabe' && BANK_CODE) {
    const _bank_code = BANK_CODE.substring(BANK_CODE.length - 3)
    const _value_code = value.substring(0, 3)
    const verify = _bank_code === _value_code

    if (!reg_clabe.test(value)) {
      return {
        verify: false,
        text: 'WITHDRAW_ACCOUNT_TYPE_CLABE',
      }
    }

    if (!verify) {
      return {
        verify: false,
        text: 'WITHDRAW_CLABE_ACCOUNT_ERROR',
      }
    }
  }

  return verifyCommon(data)
}
//
export function verifyMxnWithdrawDCType(data: VerifyInterface): {
  verify: boolean
  text?: string
} {
  const REG: {
    [key: string]: RegExp
  } = {
    RFC: /^[a-zA-Z0-9]{13}$/,
    CURP: /^[a-zA-Z0-9]{18}$/,
  }
  const {
    params: { document_type = 'RFC' },
    value,
  } = data

  if (!document_type) {
    return {
      verify: false,
      text: 'WITHDRAW_ACCOUNT_ID_INPUT_TIPS',
    }
  }
  const verify = REG[document_type].test(value)

  const text =
    document_type === 'RFC'
      ? 'WITHDRAW_ACCOUNT_ID_INPUT_TIPS'
      : 'WITHDRAW_ACCOUNT_ID_INPUT_TIPS2'
  return {
    verify,
    text,
  }
}

/** 验证col1--account_no */
export function verifyCol1AccountNo(value: string) {
  const _reg = new RegExp(`^[0-9]{9,18}$`)
  return {
    verify: _reg.test(value.replaceAll(/\s/g, '')),
    text: 'COL_ACCOUNT_LIMIT_TIP',
  }
}

//
export function verifyGcashAccountNo(data: VerifyInterface): {
  verify: boolean
  text?: string
} {
  /** M1/M2的annout_no取手机号 */
  if (isFeatureEnabled('isMseriesWallet')) {
    return verifyEmail(data)
  }
  const REG = /^09\d{9}$/g
  const isNumber = /^[0-9]*$/
  const {
    params: { payment_method = 'RFC' },
    value,
    pay_method,
  } = data
  /** COL1的account_no
   *
   * electronic_wallet方式：取手机号
   * transfiya提现方式：9--18位
   *
   * */
  if (isFeatureEnabled('isCOLseriesWallet')) {
    if (pay_method === 'transfiya') {
      return verifyCol1AccountNo(value)
    } else if (pay_method === 'electronic_wallet') {
      return verifyPhone(data)
    }
  }

  if (['GCASH', 'PAYMAYA'].includes(payment_method)) {
    if (isNumber.test(value)) {
      if (!String(value).startsWith('09')) {
        return {
          verify: false,
          text:
            payment_method === 'GCASH'
              ? 'WITHDRAW_GCASH_RIGHT_NUMBER'
              : 'WITHDRAW_PAYMAYA_RIGHT_NUMBER',
        }
      }
      if (!REG.test(value)) {
        return {
          verify: false,
          text:
            payment_method === 'GCASH'
              ? 'WITHDRAW_GCASH_LENGTH_NUMBER'
              : 'WITHDRAW_PAYMAYA_LENGTH_NUMBER',
        }
      }
      return {
        verify: true,
      }
    }
    //非数字
    return {
      verify: false,
      text: 'WITHDRAW_PAYPAL_ACCOUNT_CORRECT',
    }
  }
  return verifyCommon(data)
}
// B系列姓名 https://tringame.feishu.cn/wiki/WY8OwARVxiP5LdktdmCcCSUWnRh

export function verifyBseriesName(data: VerifyInterface): {
  verify: boolean
  text?: string
} {
  if (!data?.value) {
    return {
      verify: false,
      text: 'WITHDRAW_AA_REAL_NAME',
    }
  }
  let value = data?.value.trim()
  if (value?.length > 100) {
    return { verify: false, text: 'WITHDRAW_BANK_NAME_LONG' }
  }

  /** K1平台暂时先不做限制 */
  if (isFeatureEnabled('isKseriesWallet')) {
    return {
      verify: true,
    }
  }
  if (data.removeBlank) {
    value = value.replace(/\s+/g, '') // 删除字符串所有空格
  }
  const necessary = data?.necessary || false
  if (
    (isFeatureEnabled('isBseriesWallet') ||
      isFeatureEnabled('isNseriesWallet')) &&
    !necessary
  ) {
    //输入为空，提示key：WITHDRAW_AA_REAL_NAME（por favor digite seu nome verdadeiro）
    if (isEmpty(value.replace(/\s+/g, ''))) {
      return { verify: false, text: 'WITHDRAW_AA_REAL_NAME' }
    }
    //特殊字符
    // /[^a-zA-Z]/g
    if (!isAllLetter(value.replace(/\s+/g, ''))) {
      return {
        verify: false,
        text: 'WITHDRAW_FULL_NAME3',
      }
    }
    //输入格式不符，判断未输入空格，提示key：WITHDRAW_FULL_NAME
    if (!/\s+/g.test(value)) {
      return {
        verify: false,
        text: 'WITHDRAW_FULL_NAME',
      }
    }
    // 4. 输入格式不符，判断是输入字符＜2，提示key：WITHDRAW_FULL_NAME2（O nome e o sobrenome devem ter pelo menos 2 caracteres cada）
    const _list = value.split(' ')
    // .every(item => item.length > 2)
    //
    if (!_list.every(item => item.length > 0)) {
      return {
        verify: false,
        text: 'WITHDRAW_FULL_NAME',
      }
    }
    // 对于B系列提现name字段需要限制5--50字符，而且空格算字符数
    if (
      (value.length > 50 || value.length < 5) &&
      getVariable('RECHARGE_ERROR_TIPS')
    ) {
      return {
        verify: false,
        text: 'WITHDRAW_BR_TIPS_ACCOUNT',
      }
    }
    return {
      verify: _list.length >= 2 && _list.every(item => item.length >= 2),
      text: 'WITHDRAW_FULL_NAME2',
    }
  }

  /** 要先校验长度 */
  if (value.length > 100 || value.length < 5) {
    return {
      verify: false,
      text: 'WITHDRAW_BR_TIPS_ACCOUNT',
    }
  }
  /** M系列/COL系列独有：因为都使用西班牙语 */
  if (
    isFeatureEnabled('isMseriesWallet') ||
    isFeatureEnabled('isCOLseriesWallet')
  ) {
    return {
      verify: isMSeriesPeosonName(trim(value)),
      text: 'WITHDRAW_FULL_NAME3',
    }
  }
  /** 全平台校验字母 */
  return {
    verify: isAllLetter(value.replace(/\s+/g, '')),
    text: 'WITHDRAW_FULL_NAME3',
  }
}

/**
 * 纯字母
 * @param value
 * @returns
 */
export function isAllLetter(value: string): boolean {
  return /^[a-zA-Z]+$/.test(value)
}

/**
 *
 * @param value
 */
export function isMSeriesPeosonName(value: string): boolean {
  return /^[A-Za-z\sñÑáéíóúÁÉÍÓÚüÜ]*$/.test(value)
}

/**
 * 验证银行账号信息
 * @param data
 * @returns
 */
export function verifyAccount(data: VerifyInterface) {
  if (isFeatureEnabled('isKseriesWallet')) {
    // 因为K1的银行账号即为手机号
    return verifyPhone(data)
  }
  if (data?.value?.replace(/\s+/g, '')?.length === 0) {
    return {
      verify: false,
      text: 'PH_MODAL_NEWBANK',
    }
  }
  const accountReg = {
    default: {
      verify: /^\d{10,19}$/.test(data.value),
      text: 'WITHDRAW_BANK_ACCOUNT_LONG2',
    },
  }

  const COLseriesAccountReg = {
    verify: /^\d{9,18}$/.test(data.value),
    text: 'COL_ACCOUNT_LIMIT_TIP',
  }

  const NseriesAccountReg = {
    verify: /^\d{10,19}$/.test(data.value),
    text: 'WITHDRAW_BANK_ACCOUNT_LONG2',
  }

  let reg = accountReg.default
  if (isFeatureEnabled('isCOLseriesWallet')) {
    reg = COLseriesAccountReg
  }

  if (isFeatureEnabled('isNseriesWallet')) {
    reg = NseriesAccountReg
  }

  return {
    verify: reg.verify,
    text: reg.text,
  }
}

/**´
 *  验证id_number
 */
export function verifyIdNumber(data: VerifyInterface) {
  const id_type = data?.params?.id_type?.toUpperCase() || ''
  const value = data?.value || ''
  if (id_type) {
    if (id_type === 'CE') {
      // 需要12位字符，中间空格算字符，首尾空格不算
      const _val = value.trim()
      if (!_val) {
        return {
          verify: false,
          text: 'COMMON_ENTER_ID_NUMBER',
        }
      }
      /** CE下的id_number只能输入数字和字母 */
      const reg = /^[a-zA-Z0-9]{1,12}$/
      return {
        verify: reg.test(_val),
        text: 'COL_IDCE_LIMIT_TIP',
      }
    } else {
      const id_number_obj = id_type_2_id_number?.[id_type] as any
      const min_length = id_number_obj?.min_length || 1
      const max_length = id_number_obj?.max_length || 1
      const _reg = new RegExp(`^[0-9]{${min_length},${max_length}}$`)
      const value = data?.value?.replaceAll(/\s/g, '') || ''
      const text = id_number_obj?.error_tips_text || ''
      return {
        verify: _reg.test(value),
        text,
      }
    }
  } else {
    return {
      verify: true,
      text: '',
    }
  }
}
