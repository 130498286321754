import { getUserId, getUserToken } from '@/modules/user/storage'
import { api } from '@/services/api'
import type { Response } from '@/services/types'

import type { AuthQueryArgs } from '../user/userApi'

export const bindPhoneApi = api.injectEndpoints({
  endpoints: builder => ({
    // 绑定手机号
    bindUserPhone: builder.mutation<
      Response<any>,
      AuthQueryArgs & {
        area_code?: string
        code: string
        phone: string
      }
    >({
      query: data => ({
        url: `/api/v1/platform/asset_order/verify_code/bind_user_phone`,
        method: 'POST',
        data: {
          ...data,
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
    }),
    // 绑定手机信息
    bindUserPhoneInfo: builder.query<Response<any>, any>({
      query: data => ({
        url: `/api/v1/platform/asset_order/verify_code/user_is_bind_phone`,
        method: 'POST',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
      providesTags: ['Auth'],
    }),
    // 验证码
    userPhoneCode: builder.mutation<
      Response<any>,
      AuthQueryArgs & {
        area_code?: string
        phone: string
      }
    >({
      query: data => ({
        url: `/api/v1/platform/asset_order/verify_code/get_bind_user_phone_code`,
        method: 'POST',
        data: {
          ...data,
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
    }),
    // 注销绑定
    offBindUserPhone: builder.mutation<
      Response<any>,
      AuthQueryArgs & {
        code: string
        phone: string
      }
    >({
      query: data => ({
        url: `/api/v1/platform/asset_order/verify_code/write_off_code`,
        method: 'POST',
        data: {
          ...data,
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
    }),
    // 更换绑定
    updateBindUserPhone: builder.mutation<
      Response<any>,
      AuthQueryArgs & {
        area_code?: string
        code: string
        phone: string
        old_phone: string
      }
    >({
      query: data => ({
        url: `/api/v1/platform/asset_order/verify_code/update_user_phone`,
        method: 'POST',
        data: {
          ...data,
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
    }),
    // 获取提现是否需要绑定手机号开关
    getWithdrawBindPhoneSwitch: builder.mutation<Response<any>, any>({
      query: () => ({
        url: `/feat_switch/withdraw_bind_phone`,
        method: 'GET',
        data: {},
      }),
    }),
  }),
})

export const {
  useBindUserPhoneMutation,
  useBindUserPhoneInfoQuery,
  useUserPhoneCodeMutation,
  useOffBindUserPhoneMutation,
  useUpdateBindUserPhoneMutation,
  useGetWithdrawBindPhoneSwitchMutation,
} = bindPhoneApi
