import { Box } from '@chakra-ui/react'

import RechargeActFloatIcon from '@/assets/images/recharge-package/recharge-act-float-icon.gif'
import LazyImage from '@/components/lazy-image/LazyImage'
import { setRechargeActId } from '@/modules/activity/ActSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { useActionTrigger, useUserAuth } from '@/utils/hooks'

import { useAppDispatch } from '../app/store'
import { openModal } from '../global-modal/globalModalSlice'
import { setIsFromRechargeAct } from '../wallet/walletSlice'
import type { RechargeActListProps } from './ActInterface'

export default function RechargeActEntry(props: {
  actInfo: RechargeActListProps | undefined | null
}) {
  const { actInfo } = props
  const actionTrigger = useActionTrigger()
  const dispatch = useAppDispatch()
  const [user_id, token] = useUserAuth()

  if (!actInfo) return null
  function onclick() {
    if (!user_id || !token) {
      dispatch(
        openModal({
          key: GlobalModalKey.Login,
        }),
      )
      return
    }
    const { meta } = actInfo as RechargeActListProps
    const { action } = meta || {}
    dispatch(setRechargeActId(actInfo?.id || ''))
    actionTrigger(action)(undefined as any)
    // dispatch(setIsFromRechargeAct(true))
  }
  return (
    <Box
      position='relative'
      mb='12px'
      cursor='pointer'
      mr='-9px'
      onClick={() => {
        onclick()
      }}
    >
      <LazyImage
        boxSize={{
          lg: '20',
          base: '16',
        }}
        src={RechargeActFloatIcon}
      />
    </Box>
  )
}
