function CloseIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
    >
      <path
        d='M16.6101 6.25282C17.1568 5.70609 18.0432 5.70609 18.5899 6.25282C19.1367 6.79956 19.1367 7.68599 18.5899 8.23272L13.9799 12.8428L18.5899 17.4528C19.1367 17.9996 19.1367 18.886 18.5899 19.4327C18.0432 19.9795 17.1568 19.9795 16.6101 19.4327L12 14.8227L7.38995 19.4327C6.84321 19.9795 5.95678 19.9795 5.41005 19.4327C4.86332 18.886 4.86332 17.9996 5.41005 17.4528L10.0201 12.8428L5.41005 8.23272C4.86332 7.68599 4.86332 6.79956 5.41005 6.25282C5.95679 5.70609 6.84322 5.70609 7.38995 6.25282L12 10.8629L16.6101 6.25282Z'
        fill='#758097'
      />
    </svg>
  )
}

export default CloseIcon
