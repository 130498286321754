import {
  Accordion,
  AspectRatio,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Link,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react'
import type { CSSProperties } from 'react'
import { memo, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'

import AtomActiveLink from '@/components/atom-active-link/AtomActiveLink'
import AtomBadge from '@/components/atom-badge/AtomBadge'
import AtomNavAccordionItem from '@/components/atom-nav-accordion-item/AtomNavAccordionItem'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useActionTrigger } from '@/utils/hooks/useActionTrigger'
import useIsRedDotShow from '@/utils/hooks/useIsRedDotShow'
import appConfig from '@/websites/current/pages/layout/app-config.json'

import type { Banner } from '../app/home-page-config-types'
import { useAppSelector } from '../app/store'
import { HomePageConfigContext } from '../app-layout/AppLayout'
import INHOUSE_GAME from '../game/logic/game-list/data/inhouseGames'
import SportTab from '../sport/SportTab'
import {
  createDrawerOpenSelector,
  DrawerKey,
  toggleDrawerStart,
} from './app-layout-slice'
import type { IImageLink } from './types'

export const {
  primaryImageNavigations: primaryImageNavigators = [],
  secondaryImageNavigations: secondaryImageNavigators = [],
  promotions = [],
  navigations = [],
  gamesEntries = [] as IImageLink[],
  promotionDisplayType = 'grid',
  promotionButtonColorScheme = 'fill-seco',
} = appConfig.sideNav

// 0831 从后台读取侧边栏配置

export const useSideNavConfig = () => {
  const homePageConfig = useContext(HomePageConfigContext)
  return homePageConfig?.sideBar
}

const gamesEntriesFiltered = gamesEntries.filter(item =>
  INHOUSE_GAME.some(game => item.key === game.id),
)

function SideNavAccordion() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const { isConfigDropDownMenuItems } = useSideNavConfig() || {}

  const sideNavConfig = useSideNavConfig()
  const { dropdown } = sideNavConfig || {}

  const isGamesShow = gamesEntriesFiltered && gamesEntriesFiltered.length > 0

  const isPromotionsShow =
    (promotionDisplayType !== 'grid' &&
      (dropdown || promotions) &&
      dropdown?.items?.length) ||
    promotions.length > 0

  const scrollToInhouseGame = useCallback(() => {
    if (pathname === '/') {
      setTimeout(
        () =>
          window.document.getElementById('inhouse')?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          }),
        0,
      )
    } else {
      navigate('/')
    }
  }, [navigate, pathname])

  if (!isGamesShow && !isPromotionsShow) {
    return null
  }

  const gamesAccordionItemElem = (
    <AtomNavAccordionItem
      variant='image'
      headingProps={{
        heading: t('MUNE_CENTER_TEXT').toString(),
        subheading: t('MUNE_GAME_TEXT').toString(),
        bg: 'url("/assets/images/side-nav/accordion-heading-bg-1.png") no-repeat top / 100% 100%',
        icon: '/assets/images/side-nav/accordion-heading-icon-1.png',
      }}
    >
      <Grid templateColumns='repeat(2, 1fr)' gap='1.5' w='full' mb='3'>
        {gamesEntriesFiltered?.map((item, index) => (
          <GridItem key={item.key} colSpan={index === 0 ? 2 : 1}>
            <ImageButton {...item} />
          </GridItem>
        ))}
      </Grid>
      <Button
        colorScheme='fill-seco'
        w='full'
        size='sm'
        onClick={scrollToInhouseGame}
      >
        <Text textStyle='text5'>{t('MUNE_GAME_ALL')}</Text>
      </Button>
    </AtomNavAccordionItem>
  )

  const promotionsAccordionItemRender = () => {
    /**
     * 0831 从后台读取侧边栏配置 如果没有配置则使用默认配置
     */
    const icon = dropdown?.imageSrc
      ? dropdown.imageSrc
      : promotionDisplayType === 'accordion-image'
      ? '/assets/images/side-nav/accordion-heading-icon-2.png'
      : 'Gift'

    const headingProps = {
      heading: dropdown?.title ?? t('MENU_ACTIVITY_CENTER').toString(),
      subheading: t('MUNE_ACTIVITY_TITLE').toString(),
      bg: `url(/assets/images/side-nav/accordion-heading-bg-2.png) no-repeat top / 100% 100%`,
      icon,
    }

    let content: React.ReactNode = null

    // 合并后台配置和前端配置
    const showPromotions = isConfigDropDownMenuItems
      ? dropdown?.items
      : promotions

    if (promotionDisplayType === 'accordion-image') {
      content = (
        <VStack alignItems='stretch' spacing='2' mb='3'>
          {showPromotions?.map(item => {
            return <ImageButton {...item} ratio={106 / 64} key={item.key} />
          })}
        </VStack>
      )
    } else if (promotionDisplayType === 'accordion-simple') {
      content = (
        <VStack alignItems='stretch' spacing='0' mb='1.5'>
          {showPromotions?.map(item => {
            return (
              <AtomActiveLink
                variant='secondary'
                icon={item.imageSrc}
                {...item}
                key={item.key}
              />
            )
          })}
        </VStack>
      )
    }

    const variant =
      promotionDisplayType === 'accordion-image' ? 'image' : 'simple'

    return (
      <AtomNavAccordionItem headingProps={headingProps} variant={variant}>
        {content}
        <Box px='4'>
          <Link
            as={RouterLink}
            to={dropdown?.action?.href ?? '/promotions'}
            target={dropdown?.action?.data?.target ?? '_self'}
          >
            <Button colorScheme={promotionButtonColorScheme} w='full' size='sm'>
              <Text textStyle='text5'>
                {dropdown?.btnText ?? t('MENU_ACTIVITY_LEARN_MORE')}
              </Text>
            </Button>
          </Link>
        </Box>
      </AtomNavAccordionItem>
    )
  }

  return (
    <Accordion allowToggle w='full' defaultIndex={0}>
      <VStack alignItems='stretch' w='full'>
        {isGamesShow && gamesAccordionItemElem}
        {isPromotionsShow && promotionsAccordionItemRender()}
      </VStack>
    </Accordion>
  )
}

const MemoSideNavAccordion = memo(SideNavAccordion)

function _SideNav() {
  const sideNavConfig = useSideNavConfig()

  /**
   * 参数说明
   * largeBanners 后台配置的且启用状态的banner
   */
  const {
    largeBanners,
    smallBanners,
    menuItems,
    isConfigLargeBanner,
    isConfigSmallBanner,
    isConfigBottomMenu,
  } = sideNavConfig || {}

  const primaryBanner = isConfigLargeBanner
    ? largeBanners
    : primaryImageNavigators

  const secondaryBanner = isConfigSmallBanner
    ? smallBanners
    : secondaryImageNavigators

  const showNavis = isConfigBottomMenu ? menuItems : navigations

  const { t } = useTranslation()

  return (
    <VStack
      h='full'
      w='full'
      bg='bg.appLayout.sideNav'
      p='2.5'
      spacing='2'
      overflow='auto'
      sx={{
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <SportTab type='sideNav' />
      <VStack alignItems='stretch' spacing='1.5' w='full'>
        {primaryBanner?.map(item => {
          const banner = item as Banner & IImageLink
          return (
            <ImageButtonWithBadge
              key={banner.key}
              height='64px'
              objectFit='cover'
              text={banner?.text}
              action={banner.action}
              imageSrc={banner.imageSrc}
              ratio={220 / 64}
            />
          )
        })}
      </VStack>

      <SimpleGrid columns={2} spacing='1.5' w='full'>
        {secondaryBanner?.map(item => {
          const banner = item as Banner & IImageLink
          return (
            <ImageButtonWithBadge
              key={banner.key}
              text={banner.text}
              action={banner.action}
              height='64px'
              imageSrc={banner.imageSrc}
              ratio={106 / 64}
              objectFit='cover'
            />
          )
        })}
      </SimpleGrid>

      <MemoSideNavAccordion />

      {promotionDisplayType === 'grid' ? (
        <Flex
          direction='column'
          borderRadius='md'
          bg='gray.50'
          w='full'
          alignItems='stretch'
        >
          <Grid templateColumns='repeat(2, 1fr)' gap='2' m='1.5' mb='0'>
            {promotions.map(item => (
              <GridItem key={item.key}>
                <ImageButton {...item} />
              </GridItem>
            ))}
          </Grid>
          <Button
            as={RouterLink}
            colorScheme='fill-prim'
            display='block'
            size='sm'
            m='3'
            to='/promotions'
          >
            <Text>{t('MUNE_ACTIVITY_CENTER_TEXT')}</Text>
          </Button>
        </Flex>
      ) : null}

      <VStack w='full' alignItems='stretch' spacing='1.5'>
        {showNavis?.map(item => (
          <AtomActiveLink {...item} key={item.key} />
        ))}
      </VStack>

      <Box flex='0 0 100px' />
    </VStack>
  )
}

interface ImageButtonProps extends IImageLink {
  ratio?: number
  height?: string
  objectFit?: CSSProperties['objectFit']
  objectPosition?: CSSProperties['objectPosition']
}

function ImageButton(props: ImageButtonProps) {
  const {
    action,
    imageSrc,
    text,
    ratio,
    height,
    objectFit = 'unset',
    objectPosition = 'center',
  } = props
  const isLeftDrawerOpen = useAppSelector(
    createDrawerOpenSelector(DrawerKey.Left),
  )
  const dispatch = useDispatch()
  const actionTrigger = useActionTrigger()
  const imageElem = (
    <LazyImage
      borderRadius='md'
      src={imageSrc}
      alt=''
      noLazy={false}
      height={height}
      objectFit={objectFit}
      objectPosition={objectPosition}
      w='100%'
    />
  )

  const contentElem = ratio ? (
    <AspectRatio ratio={ratio}>{imageElem}</AspectRatio>
  ) : (
    imageElem
  )

  if (action?.href) {
    return (
      <Link
        as={RouterLink}
        to={action.href}
        target={action.data?.target ?? '_self'}
        w='full'
        title={text}
        display='block'
        onClick={() => {
          if (action.href === window.location.pathname) {
            if (isLeftDrawerOpen) {
              dispatch(toggleDrawerStart(DrawerKey.Left))
            }
          }
        }}
      >
        {contentElem}
      </Link>
    )
  } else {
    return (
      <Link
        as='button'
        onClick={actionTrigger(action)}
        title={text}
        w='full'
        display='block'
      >
        {contentElem}
      </Link>
    )
  }
}

function ImageButtonWithBadge(props: ImageButtonProps) {
  const isRedDotShow = useIsRedDotShow()(props)
  return (
    <AtomBadge size='md' show={isRedDotShow}>
      <ImageButton {...props} />
    </AtomBadge>
  )
}

const SideNav = memo(_SideNav)

export default SideNav
