import { Button, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import LazyImage from '@/components/lazy-image/LazyImage'
import TitledDialog from '@/components/modal/TitledDialog'

import { useAppDispatch, useAppSelector } from '../app/store'
import { selectActionSystemConfig } from '../app-layout/app-layout-slice'
import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'

export default function IOSSecurityAwarenessModal() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { open } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.IOSSecurityAwareness],
  )
  const actionSystemConfig = useAppSelector(selectActionSystemConfig)

  /**
   * IOS配置
   */
  const iosConfig = useMemo(() => {
    return actionSystemConfig?.IOS || undefined
  }, [actionSystemConfig])

  function handleCloseModal() {
    dispatch(closeModal({ key: GlobalModalKey.IOSSecurityAwareness }))
  }

  function handleClick() {
    window.open(iosConfig?.app_url)
  }

  return (
    <TitledDialog
      isOpen={open}
      title={t('APP_STORE_CERTIFIFY_TITLE')}
      onClose={handleCloseModal}
      size='sm'
    >
      <Text
        fontSize='14px'
        fontWeight='600'
        lineHeight='18px'
        color='gray.700'
        textAlign='center'
      >
        {t('APP_STORE_CERTIFIFY_DSC')}
      </Text>
      <LazyImage
        w='88px'
        m={'24px auto'}
        src={
          iosConfig?.app_img_url ||
          '/assets/images/ios-security-awareness-modal-image.png'
        }
        alt=''
      />
      <Button onClick={handleClick} colorScheme='fill-prim' w='100%' h='46px'>
        {t('APP_SAFE_DOWNLOAD')}
      </Button>
    </TitledDialog>
  )
}
