import { saveAs } from 'file-saver'
import isMobile from 'ismobilejs'

import { getConfigBaseUrl } from '@/common/env'
import { trackClickDownload } from '@/common/tracker'
import store from '@/modules/app/store'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import property, { projectName, sdk } from '@/websites/current/property.json'

import type { PlatformName } from './platform'
import { getDeviceType, isFromApk } from './tools'

const REFERRALCODE_KEY = 'referralCode'
const CHANNEL_KEY = 'channel'
const CHANNELID_KEY = 'channel_id'

/**
 *
 * @param key string
 * @returns string Return an empty string if the key does not exist
 *
 */
function getUrlParam(key: string) {
  const reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)', 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r !== null) {
    return decodeURIComponent(r[2])
  }
  return ''
}

// 包下载地址
const APK_LINK = `${getConfigBaseUrl()}${property.apk.LINK}`

// 包名
const APK_NAME = property.apk.NAME

// 设备
const DEVICE = getDeviceType()

export const canDownloadApp = () =>
  !isFromApk() && !isMobile(window.navigator).apple.device

type DownloadAppArgs =
  | {
      apklink?: string
      addToHomeScreenPosition?: 'float-center-button' | 'other'
      platform?: PlatformName
    }
  | undefined

/**
 *
 * @param param0
 * @returns
 */
export function downloadApp({
  addToHomeScreenPosition,
  apklink,
}: DownloadAppArgs = {}) {
  const actionSystemConfig = store.getState().appLayout.actionSystemConfig
  if (DEVICE === 'apk') {
    return
  }

  /** 点击下载app */
  trackClickDownload()

  if (['ios', 'ios-desktop-icon'].includes(DEVICE)) {
    if (actionSystemConfig?.IOS) {
      store.dispatch(
        openModal({
          key: GlobalModalKey.IOSSecurityAwareness,
        }),
      )
      return
    }

    if (DEVICE === 'ios') {
      store.dispatch(
        openModal({
          key: GlobalModalKey.AddToHomeScreen,
          data: { position: addToHomeScreenPosition || 'other' },
        }),
      )
    }
    return
  }

  const realApklink =
    apklink || actionSystemConfig?.ANDROID?.app_url || APK_LINK

  if (['android', 'android-desktop-icon'].includes(DEVICE)) {
    if (projectName === 'N1') {
      downloadApk(realApklink)
      return
    } else {
      const token = sdk.ADJUST_TOKEN
      if (token !== undefined && token !== '') {
        /** adjust 下载代替以前普通下载 */
        const referralCode = getUrlParam(REFERRALCODE_KEY)
        const channel = getUrlParam(CHANNEL_KEY)
        const channel_id = getUrlParam(CHANNELID_KEY)
        const apkLink = encodeURIComponent(realApklink)

        const clickLabel = `rc_${referralCode},ch_${channel},ci_${channel_id}`
        const url = `https://app.adjust.com/${token}?label=${encodeURIComponent(
          clickLabel,
        )}&redirect=${apkLink}`
        window.open(url)
        return
      } else {
        /** 兜底 */
        saveAs(realApklink, APK_NAME)
        return
      }
    }
  }

  store.dispatch(
    openModal({
      key: GlobalModalKey.QrCode,
      data: {
        title: 'INSTALL_APP',
        desc: 'QR_DESC',
        imageUrl: actionSystemConfig?.WEB?.code_img_url,
      },
    }),
  )
  return actionSystemConfig?.WEB?.code_img_url || location.origin
}

// 下载apk
function downloadApk(apklink?: string) {
  const fbc = sessionStorage.getItem('fbc') || ''
  const fbp = sessionStorage.getItem('fbp') || ''
  const external_id = sessionStorage.getItem('external_id') || ''
  // 透传到apk的参数
  // 在app内由app开发提供接口给web调用获取
  // const label = JSON.stringify({
  //   fbc,
  //   fbp,
  //   external_id,
  // })
  const label = `__eid${external_id}__fbc${fbc}__fbp${fbp}`

  // url上获取
  const campaign = getUrlParam('campaign') || ''
  const adgroup = getUrlParam('adgroup') || ''
  const creative = getUrlParam('creative') || '' // token和apkLink换成自己的

  const referralCode = getUrlParam(REFERRALCODE_KEY)
  const channel = getUrlParam(CHANNEL_KEY)
  const channel_id = getUrlParam(CHANNELID_KEY)

  const token = sdk.ADJUST_TOKEN
  const apkLink = encodeURIComponent(apklink || APK_LINK)
  const downLink = `https://app.adjust.com/${token}?fbclid=${fbc}&fbpid=${fbp}&label=${label}&campaign=${campaign}&adgroup=${adgroup}&creative=${creative}&engagement_type=fallback_click&redirect=${apkLink}`
  window.open(downLink)
}
