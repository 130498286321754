import { AspectRatio, Box, Button, Flex, Icon, Text } from '@chakra-ui/react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { isFeatureEnabled } from '@/common/feature'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useAppDispatch } from '@/modules/app/store'
import { openWallet } from '@/modules/global-modal/globalModalSlice'
import { useRechargeTaskQuery } from '@/modules/login-reward-modal/loginRewardModalApi'
import type { RechargeActivityProps } from '@/modules/wallet/interface'
import { RECHARGE_ACTIVITY_SCOPE_TYPE } from '@/modules/wallet/interface'
import { Loading } from '@/pages/slot-and-live'
import AtomModal, {
  createModal,
  useAtomModal,
} from '@/utils/atom-modal/AtomModal'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useUserAuth } from '@/utils/hooks'
import { getDeviceType } from '@/utils/tools'
import property from '@/websites/current/property.json'

import type { RechargerCountDownRef } from './components/rechargeCountDown'
import RechargerCountDown from './components/rechargeCountDown'
import RechargeComfirmModalNew from './RechargeComfirmModalNew'

export type { ItemData } from './RechargeComfirmModalNew'

export const NAME = 'RechargeComfirmModal'

const countDownRef = React.createRef<RechargerCountDownRef>()

const { features } = property

export function filterActivityByScope(
  rechargeList: RechargeActivityProps[] | [],
): RechargeActivityProps[] | [] {
  const device = getDeviceType()

  if (device === 'apk' || device === 'ios-desktop-icon') {
    return rechargeList.filter(
      item => !item.scope || item.scope === RECHARGE_ACTIVITY_SCOPE_TYPE.APP,
    )
  }

  return rechargeList.filter(
    item => !item.scope || item.scope === RECHARGE_ACTIVITY_SCOPE_TYPE.PC,
  )
}

const RechargeComfirmModal = createModal(
  NAME,
  ({ closeCb }: { closeCb?: () => void }) => {
    const [userId, token] = useUserAuth()
    const { t } = useTranslation()
    const { hide } = useAtomModal(NAME)
    const dispatch = useAppDispatch()

    const showRecharge = useCallback(() => {
      dispatch(openWallet())
    }, [dispatch])
    const { taskinfo, isLoading } = useRechargeTaskQuery(
      { token, user_id: userId },
      {
        selectFromResult: result => ({
          ...result,
          taskinfo: filterActivityByScope(
            (result?.data?.data || [])?.slice(),
          ).sort((a, b) => b.sort - a.sort)[0],
        }),
      },
    )

    return (
      <AtomModal
        id={NAME}
        type='title'
        title={t('ACCOUNT_INSUF_BALANCE')}
        onClose={() => {
          hide()
          closeCb?.()
        }}
      >
        {isLoading ? (
          <Box
            sx={{
              width: '100%',
              minHeight: '240px',
            }}
          >
            <Loading />
          </Box>
        ) : (
          <>
            {taskinfo ? (
              <Flex
                flexDirection='column'
                justifyContent='space-between'
                alignItems='center'
              >
                {features.rechargeCountDown ? (
                  <RechargerCountDown
                    ref={countDownRef}
                    style={{
                      marginBottom: '12px',
                    }}
                  />
                ) : null}
                <Flex>
                  <Icon
                    as={SharedIcon.Question}
                    boxSize='20px'
                    marginRight='10px'
                  />
                  <Text color='gray.900' textStyle='text4'>
                    {taskinfo.desc}
                  </Text>
                </Flex>
                <AspectRatio ratio={335 / 176} w='full' marginTop='15px'>
                  <LazyImage
                    w='full'
                    src={taskinfo.image_url}
                    alt=''
                    borderRadius='lg'
                    // marginTop='15px'
                    onClick={() => {
                      showRecharge()
                    }}
                  />
                </AspectRatio>

                <Button
                  width='100%'
                  height='46px'
                  colorScheme={'fill-prim'}
                  fontSize='lg'
                  fontWeight='extrabold'
                  marginTop='15px'
                  onClick={() => {
                    showRecharge()
                  }}
                >
                  {t('RECHARGE_TO_BONUS')}
                </Button>
              </Flex>
            ) : (
              <Flex
                flexDirection='column'
                justifyContent='space-between'
                alignItems='center'
              >
                <Text color='gray.900' margin='56px 28px' textStyle='text4'>
                  {t('ACCOUNT_INSUF_BALANCE_RECHARGE')}
                </Text>
                <Button
                  width='100%'
                  height='46px'
                  colorScheme={'fill-prim'}
                  fontSize='lg'
                  fontWeight='extrabold'
                  onClick={() => {
                    showRecharge()
                  }}
                >
                  {t('DEPOSIT_RECHARGE')}
                </Button>
              </Flex>
            )}
          </>
        )}
      </AtomModal>
    )
  },
)

const RechargeComfirmModalExported = isFeatureEnabled('newRechargeComfirmModal')
  ? RechargeComfirmModalNew
  : RechargeComfirmModal

export default RechargeComfirmModalExported
