import {
  Box,
  Button,
  Flex,
  Image,
  keyframes,
  Modal,
  ModalCloseButton,
  ModalContent,
  ScaleFade,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { t } from 'i18next'
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import bonusCoin from '@/assets/images/lucky-wheel/bonus-coin.png'
import lightCircle from '@/assets/images/lucky-wheel/light-circle.png'
import spinLight from '@/assets/images/lucky-wheel/spin-light.webp'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { selectUserCurrencySign } from '@/modules/user/userSlice'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { formatLocalMoney } from '@/utils/tools'

import luckyWheelEvents from './events'
import { luckyWheelApi } from './luckyWheelApi'
import {
  getCurrentUserRewardInfo,
  getLuckyWheelConfigReward,
  setRemainReward,
} from './luckyWheelSlice'

export type BonusModalRef = {
  openBonusModal: () => void
}

// 绕中心点旋转
const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

// 滚动动画

let BonusModal = forwardRef((props, ref) => {
  const { isOpen, onOpen } = useDisclosure()
  const dispatch = useAppDispatch()
  const [countDown, setCountDown] = useState(3)

  const timerRef = useRef<NodeJS.Timer>()

  const { firstDrawTime } = useAppSelector(getCurrentUserRewardInfo)

  const isFirstDraw = useMemo(() => {
    return firstDrawTime === 0
  }, [firstDrawTime])

  const [reward, setReward] = useState('0')
  const sign = useAppSelector(selectUserCurrencySign)

  const { reward: totalReward = 0 } = useAppSelector(getLuckyWheelConfigReward)

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure()

  useImperativeHandle(ref, () => ({
    openBonusModal: () => {
      onOpenModal()
    },
  }))

  const closeModal = useCallback(() => {
    if (timerRef.current) clearInterval(timerRef.current)
    onCloseModal()
    if (isFirstDraw) {
      luckyWheelEvents.emit('showCover')
    }
    dispatch(luckyWheelApi.util.invalidateTags(['luckyWheelInfo']))
    luckyWheelEvents.emit('resumeSpin')
  }, [dispatch, isFirstDraw, onCloseModal])

  const onStartCountDown = useCallback(() => {
    if (timerRef.current) clearInterval(timerRef.current)
    timerRef.current = setInterval(() => {
      setCountDown(prev => {
        if (prev === 1) {
          clearInterval(timerRef.current!)
          closeModal()
          return 3
        }
        return prev - 1
      })
    }, 1000)
  }, [closeModal])

  useEffect(() => {
    luckyWheelEvents.on('openBonusModal', data => {
      const { reward, remainReward } = data
      onOpenModal()
      onOpen()
      onStartCountDown()
      setReward(reward)
      dispatch(setRemainReward(remainReward))
    })
    return () => {
      luckyWheelEvents.off('openBonusModal')
    }
  }, [dispatch, onOpen, onOpenModal, onStartCountDown])

  const getRewardContent = useCallback(
    (reward: string) => {
      const tempNumber = Number(reward)
      if (tempNumber === 0) return t('NO_SPIN_BONUS')

      // const trueNumber = handleENumber(tempNumber.toString())
      const decimalPlaces = reward.split('.')[1]?.length || 0
      if (decimalPlaces) {
        if (tempNumber > 0.1) {
          return `${sign}${reward
            .split('.')
            .map((part, index) =>
              index === 1 ? part.slice(0, Math.min(decimalPlaces, 2)) : part,
            )
            .join('.')}`
        } else if (tempNumber >= 0.00000001) {
          return `${sign}${reward
            .split('.')
            .map((part, index) =>
              index === 1 ? part.slice(0, Math.min(decimalPlaces, 8)) : part,
            )
            .join('.')}`
        } else {
          // 变成xx.00...加最后的三位小数
          const lastThree = reward.slice(-3)
          return `${sign}${reward.split('.')[0]}.${'00...'}${lastThree}`
        }
      }
      return formatLocalMoney(Number(reward))
    },
    [sign],
  )

  return (
    <Modal
      autoFocus={false}
      trapFocus={false}
      isOpen={isOpenModal}
      onClose={closeModal}
      // size={{ base: 'full', sm: 'lg' }}
      size='full'
      variant={{
        base: 'fill-square',
        sm: 'round',
      }}
    >
      <ModalContent
        layerStyle='ls-modal-mask-blur-100'
        w='full'
        h='100vh'
        my='0'
      >
        <ModalCloseButton
          color='white'
          w='5'
          h='5'
          mt='5'
          onClick={() => {
            closeModal()
          }}
          as={SharedIcon.Close}
        />
        <Box
          w='full'
          h='full'
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
        >
          <Text textAlign='center' textStyle='h3' color='white'>
            {t('GAME_TEXT_ROULETTERESULT')}
          </Text>
          <ScaleFade initialScale={0.1} in={isOpen}>
            <Image
              src={lightCircle}
              w='320px'
              h='320px'
              objectFit='cover'
              position='absolute'
              left='50%'
              top='50%'
              transform='translate(-50%, -60%)'
            />
          </ScaleFade>
          <ScaleFade initialScale={0.1} in={isOpen}>
            <Box>
              <Flex
                mt='50px'
                justifyContent='center'
                position='relative'
                w='full'
                p='40px'
              >
                <Image
                  src={bonusCoin}
                  w='300px'
                  h='165px'
                  objectFit='cover'
                  position='relative'
                  zIndex='2'
                />
                <Image
                  src={spinLight}
                  w='100%'
                  h='auto'
                  objectFit='cover'
                  position='absolute'
                  top='-32%'
                  //   left='-2%'
                  transform='translate(-50%, -50%)'
                  zIndex='1'
                  animation={`${rotateAnimation} 24s linear infinite`}
                  transformOrigin='center center'
                />
              </Flex>
            </Box>
          </ScaleFade>
          <Text
            textAlign='center'
            textStyle='h1'
            color='white'
            textShadow='0px 2px 8px rgba(202, 194, 15, 0.50)'
            bg='linear-gradient(180deg, #FFF 0%, #F2F199 74.48%, #E8D195 100%)'
            bgClip='text'
            textColor='transparent'
          >
            {getRewardContent(reward)}
          </Text>
          <Flex mt='6' alignItems='center' justifyContent='center'>
            <Text textStyle='h8' color='white' ml='1' textAlign='center'>
              {t('TO_BE_WITHDRAWN', {
                amount: formatLocalMoney(Number(totalReward)),
              })}
            </Text>
          </Flex>
          <Button
            colorScheme='fill-prim'
            w='300px'
            whiteSpace='nowrap'
            color='white'
            mt='4'
            h='46px'
            py='4'
            onClick={() => {
              closeModal()
            }}
            position='relative'
            zIndex='4'
          >
            <Text textStyle='h4'>
              {t('HAPPY_ACCEPT')}
              <Text as='span' ml='1'>
                {`(${countDown}s)`}
              </Text>
            </Text>
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  )
})

BonusModal.displayName = 'BonusModal'
BonusModal = memo(BonusModal)

export default BonusModal
