import { BuiltInGameLabel } from './types'

export const LabelChecker = {
  isAllGames(id: number) {
    return id === BuiltInGameLabel.All
  },
  isRecommandGroups(id: number) {
    return id === BuiltInGameLabel.Recommand
  },
  isProviderGroups(id: number) {
    return id === BuiltInGameLabel.Manufacturer
  },
  isFavorite(id: number) {
    return id === BuiltInGameLabel.Favorite
  },
}
