//

import { useToast } from '@chakra-ui/react'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { isQueryError } from '@/services/types'

import { useBindUserPhoneMutation } from './bindPhoneApi'
import {
  getModalType,
  getOpen,
  getPhone,
  setCode,
  setCountDownTime,
  setModalType,
} from './bindPhoneSlice'
import type { ModalTypeE, PhoneType } from './type'
import { ModalType } from './type'

export const usePhone = () => {
  const modalType: ModalTypeE = useAppSelector(getModalType) as ModalTypeE
  const phone = useAppSelector(getPhone)
  const isBindPhoneModalOpen = useAppSelector(getOpen)
  const toast = useToast()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  //绑定手机号
  const [bindPhone, bindPhoneResult] = useBindUserPhoneMutation()
  const {
    error: phoneError,
    isSuccess: bindPhoneSuccess,
    isLoading: bindLoading,
  } = bindPhoneResult
  //绑定手机号
  const userBindPhone = useCallback(
    async (params: PhoneType) => {
      try {
        await bindPhone(params).unwrap()
      } catch (error) {
        throw new Error('network error')
      }
    },
    [bindPhone],
  )
  //监听绑定手机号
  useEffect(() => {
    if (phoneError && isQueryError(phoneError)) {
      const text = t(phoneError?.message) || t('NETWORK_EXCEPTION')
      toast({ status: 'error', title: t(text), duration: 5000 })
      return
    }
    if (bindPhoneSuccess) {
      sessionStorage.removeItem('code_time')
      sessionStorage.removeItem('last_phone')
      dispatch(setCountDownTime(0))
      dispatch(setCode(''))

      if (isBindPhoneModalOpen) {
        dispatch(setModalType(ModalType.bindsuccess))
      }
    }
  }, [
    phoneError,
    t,
    toast,
    bindPhoneSuccess,
    modalType,
    dispatch,
    isBindPhoneModalOpen,
  ])

  return {
    _userBindPhone: userBindPhone,
    bindLoading,
  }
}
