import { Box, Flex, Icon, Text, useToast } from '@chakra-ui/react'
import { type PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getVariable } from '@/common/env'
import { trackLogin } from '@/common/tracker'
import { LoginType } from '@/common/tracker/const'
import { getSocket } from '@/common/ws'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  closeModal,
  openModal,
  selectLoginModalData,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { useLoginMutation } from '@/modules/user/userApi'
import { isQueryError } from '@/services/types'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import atomGtag from '@/utils/gtag/atom-gtag'
import GAEvent from '@/utils/gtag/GAEvent'
import { useInputValidState } from '@/utils/hooks'
import type { PickPartial } from '@/utils/typecast'
import {
  getWrongPhoneFormatMsg,
  isAllNumber,
  isValidOnlyPhoneNumber,
  isValidPassword,
  isValidPhoneNumber,
} from '@/utils/validform'
import projectConfig from '@/websites/current/dataCollection/projectConfig'

import { getUserId, getUserToken } from '../user/storage'
import { ERROR_CODE_MAP } from './constants'
import type { LoginFormInputProps } from './FormInput'
import { AutoCompleteInput, NormalInput } from './FormInput'
import LoginCommon from './New/components/LoginCommon'
import { useGetLoginAndSignUpConfigQuery } from './New/newLoginAndSignUpApi'
import {
  selectIsEnableEmailPwdVerifyLogin,
  selectIsEnablePhonePwdVerifyLogin,
} from './New/newLoginAndSignUpConfigSlice'

const { emailSuffixs } = projectConfig

const areaCode = getVariable('PHONE_AREA_CODE')

type customerFormErrorProp = {
  isInvalid: boolean
} & PropsWithChildren

export function CustomizedFormErrorMessage(props: customerFormErrorProp) {
  return (
    <Text
      visibility={!props.isInvalid ? 'hidden' : 'visible'}
      color='red.500'
      textAlign={'right'}
      my='px'
      paddingRight='6px'
      textStyle='text5'
    >
      {props.children}
    </Text>
  )
}

export function LoginFormInput(
  props: PickPartial<LoginFormInputProps, 'autoComplete'>,
) {
  // return <NormalInput {...props} />
  if (props.autoComplete) {
    return <AutoCompleteInput {...(props as LoginFormInputProps)} />
  } else {
    return <NormalInput {...props} />
  }
}

export default function LoginForm() {
  const { t } = useTranslation()
  const toast = useToast()
  const dispatch = useAppDispatch()
  const [isInputEmail, setIsInputEmail] = useState(false)

  const isEnableEmailPwdVerifyLogin = useAppSelector(
    selectIsEnableEmailPwdVerifyLogin,
  )

  const isEnablePhonePwdVerifyLogin = useAppSelector(
    selectIsEnablePhonePwdVerifyLogin,
  )

  const isComboLoginForm = isEnablePhonePwdVerifyLogin

  const { defaultInput } = useAppSelector(selectLoginModalData) as {
    tab?: string
    defaultInput?: string
  }

  const {
    value: account,
    valid: accountValid,
    handleChange: handleAccountChange,
    isValid: handleAccoutValid,
  } = useInputValidState(
    defaultInput ?? '',
    isInputEmail
      ? () => true
      : isEnableEmailPwdVerifyLogin
      ? isValidPhoneNumber
      : isValidOnlyPhoneNumber,
  )

  useEffect(() => {
    if (
      defaultInput &&
      defaultInput.includes('@') &&
      isEnableEmailPwdVerifyLogin
    ) {
      setIsInputEmail(true)
    }
  }, [defaultInput, isEnableEmailPwdVerifyLogin])

  const {
    value: email,
    valid: emailValid,
    handleChange: handleEmailChange,
    isValid: handleEmailValid,
  } = useInputValidState(defaultInput ?? '', val => !!val)

  const {
    value: password,
    valid: passwordValid,
    handleChange: handlePasswordChange,
    isValid: handlePasswordValid,
  } = useInputValidState('', isValidPassword)

  /** 请求配置接口 */

  const { refetch: refetchLoginAndSignUpConfig, isLoading: isConfigLoading } =
    useGetLoginAndSignUpConfigQuery()

  /** 登录接口 */
  const [login, { isLoading }] = useLoginMutation()

  /** 登录 */
  const handleLogin: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault()
    if (
      [
        handlePasswordValid(),
        [...(isComboLoginForm ? [handleAccoutValid()] : [handleEmailValid()])],
      ].every(item => !!item)
    ) {
      try {
        const { data } = await refetchLoginAndSignUpConfig().unwrap()

        const { config } = data ?? '{}'

        const _config = JSON.parse(config)
        let isEquals = true
        if (isComboLoginForm) {
          if (
            isAllNumber(account) &&
            _config?.login_config?.phone_and_password === false
          ) {
            isEquals = false
          }

          if (
            !isAllNumber(account) &&
            _config?.login_config?.email_and_password === false
          ) {
            isEquals = false
          }
        } else {
          if (
            isAllNumber(email) &&
            _config?.login_config?.phone_and_password === false
          ) {
            isEquals = false
          }

          if (
            !isAllNumber(email) &&
            _config?.login_config?.email_and_password === false
          ) {
            isEquals = false
          }
        }

        if (!isEquals) {
          dispatch(
            openModal({
              key: GlobalModalKey.LoginAndSignUpConfigError,
            }),
          )
          return
        }

        /** GA 上报 */
        atomGtag('event', GAEvent.LOGIN_BTN_CLICK)
        /** 登录 api */
        await login({
          account: isComboLoginForm ? account : email,
          password: password,
        }).unwrap()

        /** to resolve game page bet failed */
        const { socket } = getSocket()
        socket.emit(
          'login',
          { token: getUserToken(), userId: getUserId() },
          (success: any) => {
            socket.isLogin = success
            socket.isLogging = false
          },
        )
        /**
         * 邮箱或手机号登录埋点
         */
        trackLogin(LoginType[email.includes('@') ? 'EmailLogin' : 'PhoneLogin'])

        dispatch(closeModal({ key: GlobalModalKey.Login }))
        toast({ title: t('USER_LOGIN_SUCCESS').toString(), status: 'success' })

        // openTgModal(dispatch)
      } catch (error: any) {
        const MultipleWrongPasswordCode = 1032
        // 如果多次登录失败
        if (error.status === MultipleWrongPasswordCode) {
          dispatch(
            openModal({
              key: GlobalModalKey.WrongPassword,
              data: {
                account: isComboLoginForm ? account : email,
              },
            }),
          )
          return
        }

        let messageKey = 'USER_LOGIN_ERROR'
        if (isQueryError(error)) {
          messageKey = ERROR_CODE_MAP[error.status] ?? messageKey
        }
        toast({ title: t(messageKey).toString(), status: 'error' })
      }
    }
  }

  const getErrorMessage = (value: string) => {
    if (!value) {
      if (isComboLoginForm) {
        return t('NULL_ALL')
      }

      if (isEnablePhonePwdVerifyLogin) {
        return t('DEPOSIT_INPUT_PHONE')
      }

      return t('PH_EMAIL_PLACE')
    }

    // 如果没开新的登陆注册开关
    if (!isComboLoginForm) {
      if (value.length > 200) {
        return t('WITHDRAW_EMAIL_RIGHT')
      }
    } else {
      // 如果我在输出邮箱  或者邮箱长度超过200
      if (isInputEmail && value.length > 200) {
        return t('WITHDRAW_EMAIL_RIGHT')
      }

      // 如果不是在输出邮箱 且不是合法的手机号
      if (!isInputEmail && !isValidPhoneNumber(value)) {
        // 如果不是全数字
        return isAllNumber(value)
          ? t(getWrongPhoneFormatMsg(value), {
              phone_digit: getVariable('PHONE_AREA_LENGTH'),
            })
          : t('WITHDRAW_EMAIL_RIGHT')
      }
    }
    return t('WITHDRAW_EMAIL_RIGHT')
  }

  const invalidPasswordTips = useMemo(() => {
    if (!passwordValid) {
      if (!password.length) return t('USER_INPUT_PWD2')
      if (password.length < 8) {
        return t('USER_PWD_OVERLENGTH')
      }
      if (password.length > 64) {
        return t('USER_PWD_TOOLONG')
      }
    }

    return ''
  }, [password.length, passwordValid, t])

  return (
    <Flex direction='column'>
      <Box as='form' onSubmit={handleLogin}>
        {/* @see https://www.chromium.org/developers/design-documents/create-amazing-password-forms/ */}
        <LoginFormInput
          value={isComboLoginForm ? account : email}
          handleValueChange={e => {
            if (e.target.value.includes('@') && isEnableEmailPwdVerifyLogin) {
              setIsInputEmail(true)
            } else {
              setIsInputEmail(false)
            }
            isComboLoginForm ? handleAccountChange(e) : handleEmailChange(e)
          }}
          addonBeforeWidth={
            !isEnableEmailPwdVerifyLogin
              ? `${areaCode.length * 9 + 32}px`
              : undefined
          }
          addonBefore={
            !isEnableEmailPwdVerifyLogin ? (
              <Text fontSize='14px' mr='8' ml='8'>
                {areaCode}
              </Text>
            ) : (
              <Icon
                boxSize='5'
                as={isComboLoginForm ? SharedIcon.User1 : SharedIcon.Email1}
              />
            )
          }
          validator={{
            valueValid: isComboLoginForm ? accountValid : emailValid,
            errorMsg: !(isComboLoginForm ? accountValid : emailValid)
              ? getErrorMessage(isComboLoginForm ? account : email)
              : '',
          }}
          fieldProps={{
            name: 'login-email',
            type: isComboLoginForm
              ? !isEnableEmailPwdVerifyLogin
                ? 'number'
                : 'text'
              : 'email',
            placeholder: t(
              isComboLoginForm
                ? isEnableEmailPwdVerifyLogin
                  ? 'DEPOSIT_INPUT_ACCOUNT'
                  : 'DEPOSIT_INPUT_PHONE'
                : 'USER_EMAIL' ?? '',
            ).toString(),
          }}
          autoComplete={emailSuffixs}
          openJudge={() => isInputEmail}
        />
        <Box>
          <LoginFormInput
            value={password}
            handleValueChange={handlePasswordChange}
            addonBefore={<Icon boxSize='5' as={SharedIcon.Lock} />}
            validator={{
              valueValid: passwordValid,
              errorMsg: invalidPasswordTips,
            }}
            fieldProps={{
              name: 'password',
              type: 'password',
              placeholder: t('USER_ENTER_PWD') || '',
            }}
          />
        </Box>
        <LoginCommon
          isLoading={isLoading || isConfigLoading}
          showForgetPassword
          scene='account'
        />
      </Box>
    </Flex>
  )
}
