import { Box, Button, FormLabel, Input, useToast } from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import LazyImage from '@/components/lazy-image/LazyImage'
import TitledDialog from '@/components/modal/TitledDialog'
import {
  closeModal,
  openModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { toggleBalanceFloat, updateBanlance } from '@/modules/user/userSlice'
import { api } from '@/services/api'
import type { Response, WithMessageObject } from '@/services/types'
import useUserInfo from '@/utils/hooks/useUserInfo'

import { useAppDispatch, useAppSelector } from '../app/store'
import { ERROR_MAP } from '../redeem-result-modal/RedeemResultModal'
import type { AuthQueryArgs } from '../user/userApi'
import { selectUser } from '../user/userSlice'
import { selectADDCoupon } from '../guide-event/guideEventSlice'
import useADDModalControl from '../guide-event/useADDModalControl'

export const couponApi = api.injectEndpoints({
  endpoints: builder => ({
    exchangeCoupon: builder.mutation<
      Response<string>,
      { number: string } & AuthQueryArgs
    >({
      query: data => ({
        url: '/api/v1/coupon/number/use',
        method: 'POST',
        data,
      }),
    }),
  }),
})

export const { useExchangeCouponMutation } = couponApi

export default function CouponModal() {
  const [couponCode, setCouponCode] = useState('')
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { open } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.Coupon],
  )
  const token = useAppSelector(state => selectUser(state).token)
  const userId = useAppSelector(state => selectUser(state).userId)

  const [exchangeCoupon, { isLoading }] = useExchangeCouponMutation()
  const toast = useToast()
  const { userInfo } = useUserInfo()

  const canSubmit = useMemo(() => {
    return couponCode.length >= 6 && !isLoading
  }, [couponCode, isLoading])

  /** 引流下载App弹窗 */
  const diversionModalCfg = useAppSelector(selectADDCoupon)
  const { handleAddModalShow } = useADDModalControl(diversionModalCfg)

  const handleClose = () => {
    dispatch(closeModal({ key: GlobalModalKey.Coupon }))
    setCouponCode('')
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault()

    if (handleAddModalShow()) {
      return
    }

    if (!couponCode) {
      return
    }

    if (couponCode.length < 6) {
      return
    }

    try {
      const { data } = await exchangeCoupon({
        number: couponCode,
        token,
        user_id: userId,
      }).unwrap()
      setCouponCode('')
      handleClose()
      toast({ title: t('REDEEM_SUCCESS'), status: 'success' })
      const reward = JSON.parse(data || '{}')
      const blc: number = userInfo.banlance?.amount as number
      const addAmount = reward.bonus.bet_amount
      dispatch(
        toggleBalanceFloat({
          balance: reward.bonus.bet_amount,
        }),
      )
      dispatch(
        openModal({
          key: GlobalModalKey.RewardSuccess,
          data: {
            amount: addAmount,
            tips: t('CODE_BONUS_TIPS'),
          },
        }),
      )
      const res: number = Number(blc) + Number(addAmount)
      updateBanlance(Number(res))
    } catch (error: unknown) {
      toast({
        title:
          t((ERROR_MAP as any)[(error as WithMessageObject)?.status]) ||
          (error as WithMessageObject).message,
        status: 'error',
      })
    }
  }

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setCouponCode(e.target.value)
  }

  return (
    <TitledDialog
      title={t('BONUS_CODE_TITLE')}
      isOpen={open}
      onClose={handleClose}
      size='sm'
    >
      <Box as='form' onSubmit={handleSubmit} mb='-3'>
        <FormLabel color='text.base' mx='1.5'>
          {t('BONUS_CODE')}
        </FormLabel>
        <Input
          placeholder={t('COPY_CODE').toString()}
          value={couponCode}
          onChange={handleInputChange}
        />
        <Box my='1'>
          <LazyImage
            src='/assets/images/coupon-gift.png'
            alt=''
            height='180px'
            display='block'
            mx='auto'
          />
        </Box>
        <Button
          type='submit'
          w='full'
          colorScheme='fill-prim'
          size='lg'
          isDisabled={!canSubmit}
        >
          {t('REDEEM')}
        </Button>
      </Box>
    </TitledDialog>
  )
}
