import { Center } from '@chakra-ui/react'
import React from 'react'

import AtomPageLoading from '@/components/atom-page-loading/AtomPageLoading'

function Loading() {
  return (
    <Center w='full' h='full' position='absolute'>
      <AtomPageLoading />
    </Center>
  )
}

const MemoziedLoading = React.memo(Loading)

export default MemoziedLoading
