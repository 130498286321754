import type { GameListItem } from '../game/gameInterface'

function normalizeString(str: string) {
  return str.toLowerCase().replace(/[^a-z0-9]/g, '')
}

export function includesKeyword(testString: string, keyword: string): boolean {
  return normalizeString(testString).includes(normalizeString(keyword))
}

export function filterByGameName(keyword: string, gameList: GameListItem[]) {
  return gameList.filter(item => includesKeyword(item.game_name, keyword))
}
