import { createSelector } from '@reduxjs/toolkit'

import { selectUserIsLogin } from '../user/userSlice'
import { FAVORITE_LABEL_ID, selectAllCateByLableType } from './gameSlice'
import { LabelChecker } from './logic/home-games/utils'
import type { Orientation } from './units/gamecard/AtomGameCard'

export const selectGameTabItems = createSelector(
  selectAllCateByLableType,
  selectUserIsLogin,
  (allCateByLabelType, isLogin) => {
    const categoryLabels =
      allCateByLabelType[2]?.filter(item => {
        if (
          item.label_id === 6 ||
          item.label_id === 8 ||
          (item.label_id === FAVORITE_LABEL_ID && isLogin)
        ) {
          return true
        }
        return item?.data?.length > 0
      }) || []
    return categoryLabels.map(item => {
      let coverOrientation: Orientation = 'portrait'
      try {
        coverOrientation =
          JSON.parse(item?.game_show_detail ?? '{}')?.game_show_type === 2
            ? 'landscape'
            : 'portrait'
      } catch (error) {
        /* empty */
      }

      return {
        id: item.label_id,
        icon: item.icon,
        name: item.label_name,
        game_quantity: item?.game_quantity,
        coverOrientation,
      }
    })
  },
)

export const selectAllGameTabItems = createSelector(
  selectGameTabItems,
  gameTabItems => {
    if (!gameTabItems || gameTabItems.length === 0) return []
    const allGameCate = {
      id: -1,
      name: 'GAMELIST_ALLGAME',
      icon: '/assets/images/games/all-game.png',
      coverOrientation: 'landscape',
    }
    return [
      allGameCate as {
        id: number
        icon: string
        name: string
        coverOrientation: Orientation
        game_quantity?: number
      },
      ...gameTabItems,
    ]?.filter(item => !LabelChecker.isRecommandGroups(item.id))
  },
)

export const selectRecommendGameGroups = createSelector(
  selectAllCateByLableType,
  allCateByLabelType => {
    return (
      allCateByLabelType[1]?.filter(
        item => item?.data?.length > 0 || item?.providerSort?.length > 0,
      ) || []
    )
  },
)
