// 绑定币种
import { Button, Image, Text, useToast } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getVariable } from '@/common/env'
import { getCurrencyCodeList, setCurrencyCode } from '@/common/locale/currency'
import AtomSelect from '@/components/atom-select/AtomSelect'
import LazyImage from '@/components/lazy-image/LazyImage'
import TitledDialog from '@/components/modal/TitledDialog'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  useBindCurrencyInfoQuery,
  useBindCurrencyMutation,
  useCurrencyListQuery,
} from '@/modules/bind-currency/currencyApi'
import {
  getBindCurrency,
  getShowBindCurrency,
  setCallBackType,
  switchBindModal,
} from '@/modules/bind-currency/currencySlice'
import { DEFAULT_ACT_INFO } from '@/modules/wallet/interface'
import { setJoinAct } from '@/modules/wallet/walletSlice'
import { useUserAuth } from '@/utils/hooks'

import { selectUserIsLogin } from '../user/userSlice'
import { LIST_MAP } from './contansts'

export function BindCurrency() {
  const { t } = useTranslation()
  const isOpen = useAppSelector(getShowBindCurrency)
  const dispatch = useAppDispatch()
  const SHOW_BIND_SELECT = getVariable('SHOW_BIND_SELECT')
  const [selectCurrency, setSelectCurrency] = useState<string>('')

  const toast = useToast()
  const [userId, token] = useUserAuth()
  const isLogin = useAppSelector(selectUserIsLogin)

  const [bindingCurrency, { isLoading: isBinding }] = useBindCurrencyMutation()
  const currencyListQuery = useCurrencyListQuery(
    { user_id: userId, token },
    { skip: Boolean(!isLogin || !SHOW_BIND_SELECT) },
  )

  const boundCurrency = useAppSelector(getBindCurrency)

  const currencyOptions = useMemo(() => {
    const { data: currencies } = currencyListQuery.data || { data: [] }
    const codeList = getCurrencyCodeList()
    return currencies
      .filter(item => codeList.indexOf(item.title) !== -1)
      .map(item => ({
        label: item.title,
        value: item.guid,
        icon: (
          <LazyImage
            src={LIST_MAP[item.title]}
            w='8'
            h='auto'
            objectFit='contain'
            mr='3'
          />
        ),
      }))
  }, [currencyListQuery.data])

  useEffect(() => {
    if (boundCurrency) {
      setCurrencyCode(boundCurrency)
    }
  }, [boundCurrency])
  useEffect(() => {
    if (!isBinding && isLogin) {
      // dispatch(switchBindModal())
    }
  }, [isBinding, isLogin])
  const userBindCurrency = async () => {
    try {
      await bindingCurrency({
        currency_guid: selectCurrency,
        user_id: userId,
        token,
      }).unwrap()
      toast({ title: 'Enlazar con éxito', status: 'success' })
      setTimeout(() => {
        // window.location.reload()
      }, 100)
    } catch (error) {
      throw new Error('net work error')
    }
  }

  const handleDialogClose = () => {
    dispatch(setCallBackType({ callBackType: '' }))
    dispatch(setJoinAct({ actInfo: DEFAULT_ACT_INFO }))
    dispatch(switchBindModal())
  }

  const handleCurrencySelectChange = (
    newValue?: {
      value: string
      label: string
      icon: JSX.Element
    } | null,
  ) => {
    if (newValue) {
      setSelectCurrency(newValue.value)
    }
  }
  return (
    <TitledDialog title='MexLucky' isOpen={isOpen} onClose={handleDialogClose}>
      <Text textStyle='text3' color='text.secondary' mb='2'>
        Elige tu moneda
      </Text>
      <AtomSelect
        size='lg'
        options={currencyOptions}
        onChange={handleCurrencySelectChange}
      />
      <Text textStyle='text1' mt='5' mb='3.5' color='text.accent'>
        Por qué elegir moneda
      </Text>
      <Text textStyle='text4' color='text.base'>
        {/* todo====== */}
        Mexswin es una plataforma de juegos multipaís. Debe elegir monedas
        fiduciarias de uso común para recargar, retirar, consumir y liquidar
        recompensas en la plataforma. Elija con cuidado, esta elección es
        irreversible. Después de elegir, puede experimentar todo el contenido de
        la plataforma. ¡Te deseo un feliz juego!
      </Text>

      <Button
        mt='8'
        w='full'
        size='lg'
        colorScheme='fill-prim'
        isDisabled={!selectCurrency || isBinding}
        onClick={userBindCurrency}
      >
        {t('COMMON_CONFIRM')}
      </Button>
    </TitledDialog>
  )
}

/**
 * 不要直接渲染，应该可见的时候才渲染
 * TitledDialog isOpen 虽然不为false，但里面的函数计算开销仍然走了一遍！
 *
 * @returns 优化渲染
 */
export default function NiceBindCurrency() {
  const isOpen = useAppSelector(getShowBindCurrency)
  const isLogin = useAppSelector(selectUserIsLogin)
  const SHOW_BIND_SELECT = getVariable('SHOW_BIND_SELECT')
  const [userId, token] = useUserAuth()

  useBindCurrencyInfoQuery(
    { user_id: userId, token },
    { skip: Boolean(!isLogin || !SHOW_BIND_SELECT) },
  )

  if (!isOpen) return null

  return <BindCurrency />
}
