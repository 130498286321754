import { Center, HStack } from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'
import React, { Children, isValidElement, useMemo } from 'react'

import { useCustomizedStyle } from '@/utils/hooks'

const ActionKeys = ['rules', 'fairness', 'sound']
type ActionKey = (typeof ActionKeys)[number]

export interface Action {
  key: ActionKey
  label: string
  icon: React.ReactNode
  onClick?: (k: ActionKey) => void
}
export type Actions = Action[]

export const NAME = 'ActionsBar'
export const PARTS = ['hstack', 'box'] as const

export const ActionsBar: React.FC<PropsWithChildren> = ({ children }) => {
  const { hstack: hstackStyle, box: boxStyle } = useCustomizedStyle(
    NAME,
    {
      hstack: {
        spacing: '5px',
      },
      box: {
        borderRadius: '8px',
        bgColor: '#fff',
        w: '26px',
        h: '26px',
      },
    },
    PARTS,
  )

  const _children = useMemo(() => {
    return Children.map(children, child =>
      isValidElement(child) ? <Center sx={boxStyle}>{child}</Center> : null,
    )
  }, [boxStyle, children])

  return <HStack sx={hstackStyle}>{_children}</HStack>
}

export default ActionsBar
