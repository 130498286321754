import type { SystemStyleObject } from '@chakra-ui/react'
import { Box, Flex, Spinner, Text } from '@chakra-ui/react'
import { isEmpty } from 'lodash-es'
import React from 'react'

import { useCustomizedStyle } from '@/utils/hooks'

export const NAME = 'BetButton'
export const PARTS = ['box', 'maintext', 'subtext'] as const

export interface BetButtonProps {
  loading?: boolean
  maintext: string | React.ReactNode
  subtext?: Exclude<string | React.ReactNode, number>
  disabled?: boolean
  onClick?: () => void
  variant?: string
  _boxStyle?: Record<string, any>
}

const box: SystemStyleObject = {
  h: '100%',
  w: '100%',
  borderRadius: '10px',
  overflow: 'hidden',
  bgImage: 'linear-gradient(180deg,#f43434,#ff7171)',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  px: '3',
  _hover: {
    opacity: 0.9,
  },
  _disabled: {
    bg: 'gray.600',
    cursor: 'not-allowed',
    _hover: {
      opacity: 1,
    },
  },
}
export const BetButton: React.FC<BetButtonProps> = ({
  loading,
  maintext,
  subtext,
  onClick,
  disabled,
  variant,
  _boxStyle,
}) => {
  const {
    box: boxStyle,
    maintext: maintextStyle,
    subtext: subtextStyle,
  } = useCustomizedStyle(
    NAME,
    {
      box: {
        ...box,
        ...(_boxStyle || {}),
      },
      maintext: {
        color: '#fff',
        fontSize: '18px',
        fontWeight: '700',
        lineHeight: '22px',
        textAlign: 'center',
        _disabled: {
          color: 'gray.300',
          cursor: 'not-allowed',
        },
      },
      subtext: {
        color: '#fff',
        textAlign: 'center',
        textStyle: 'h7',
        _disabled: {
          color: 'gray.300',
          cursor: 'not-allowed',
        },
      },
    },
    PARTS,
    { variant },
  )

  const realDisabled = loading || disabled
  const showSubText = !isEmpty(subtext) && !loading

  return (
    <Flex
      onClick={disabled ? undefined : onClick}
      aria-disabled={realDisabled}
      sx={boxStyle}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Text
          flex='1'
          sx={maintextStyle}
          cursor='inherit'
          aria-disabled={realDisabled}
        >
          {loading ? <Spinner size='sm' as='span' /> : maintext}
        </Text>
        {showSubText && (
          <Text sx={subtextStyle} cursor='inherit' aria-disabled={realDisabled}>
            {subtext}
          </Text>
        )}
      </Box>
    </Flex>
  )
}
