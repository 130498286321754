import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  SimpleGrid,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormItem } from '@/components/forms/DynamicForms'
import { FormType } from '@/components/forms/types'
import TitledDialog from '@/components/modal/TitledDialog'

import { useAppDispatch, useAppSelector } from '../app/store'
import { useRecharge } from './useRecharge'
import { verifyEmail } from './verifyFunction'
import { FORM_INPUT_REG, FORM_INPUT_TIPS } from './walletContansts'
import {
  getMxnRecahrgeEmail,
  getShowMxnRecahrgeEmail,
  selectWallet,
  setMxnRecahrgeEmail,
  setShowMxnRecahrgeEmail,
} from './walletSlice'

const { label, tips } = FORM_INPUT_TIPS['email']

export default function MxnEmailModal() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const MxnRecahrgeEmail = useAppSelector(getMxnRecahrgeEmail)
  const isOpen = useAppSelector(getShowMxnRecahrgeEmail)
  const rechargeParams = useAppSelector(
    state => selectWallet(state).rechargeParams,
  )
  const {
    trigger: recharge,
    result: { isLoading: isRecharging },
  } = useRecharge()

  const isInvalid = useMemo(() => {
    return verifyEmail({ params: {}, value: MxnRecahrgeEmail })
  }, [MxnRecahrgeEmail])

  const rechargeClick = useCallback(() => {
    if (!isInvalid.verify) return

    recharge({
      ...rechargeParams,
      email: MxnRecahrgeEmail,
    })
  }, [recharge, MxnRecahrgeEmail, rechargeParams, isInvalid.verify])

  return (
    <TitledDialog
      title={''}
      isOpen={isOpen}
      onClose={() => {
        dispatch(setShowMxnRecahrgeEmail(false))
      }}
      size='sm'
    >
      <FormItem
        type={FormType.text}
        label={t(label)}
        isInvalid={!isInvalid.verify}
        value={MxnRecahrgeEmail}
        key={'mxn_eamil'}
        placeholder={t(tips)}
        onChange={value => {
          dispatch(setMxnRecahrgeEmail(value))
        }}
        error={!isInvalid.verify ? t(tips) : null}
      />
      <Button
        colorScheme='fill-seco'
        size='lg'
        isLoading={isRecharging}
        loadingText='Loading'
        width='100%'
        marginTop='26px'
        onClick={rechargeClick}
      >
        {t('DEPOSIT_RECHARGE')}
      </Button>
    </TitledDialog>
  )
}
