import { useTranslation } from 'react-i18next'

import useLuckyWheelShares from '../../lucky-wheel/hooks/useLuckyWheelSharesInfo'
import * as Images from '../image-imports'
import type { TaskItemProps } from '../types'
import TaskItem from './TaskItem'

function TaskItemInvite(props: TaskItemProps<'invite'>) {
  const { data } = props
  const { t } = useTranslation()
  // const { handleOpenSharesModal } = useShares([], sharesJson.draw, 'draw')
  const { handleOpenSharesModal } = useLuckyWheelShares(
    'drawActivity',
    data?.activityId,
  )
  // 如果data中除了activityId外沒有其他值
  if (
    data &&
    Object.keys(data).length === 1 &&
    Object.keys(data)[0] === 'activityId'
  ) {
    return null
  }

  if (!data) return null
  return (
    <TaskItem
      icon={Images.taskItemInviteIcon}
      title={t('INVITE_NAME')}
      drawTimes={data.drawTimes}
      progressProps={{
        ...data.progress,
        type: 'text',
        label: t('INVITE_SPIN_AGAIN'),
      }}
      buttonProps={{
        onClick: () => handleOpenSharesModal('drawActivity'),
        text: t('COMMON_SHARE'),
      }}
    />
  )
}

export default TaskItemInvite
