import type { SystemStyleObject } from '@chakra-ui/react'
import {
  Box,
  Flex,
  Input,
  Text,
  useNumberInput,
  useRadioGroup,
} from '@chakra-ui/react'
import { floor, toNumber, toString } from 'lodash-es'
import {
  type FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useCustomizedStyle } from '@/utils/hooks'
import { useAdaptableFontSize } from '@/utils/hooks/useAdaptableFontSize'

import getLimitedResult from '../../utils/getLimitedResult'
import MaskLayer from '../mask-layer/MaskLayer'
import { RadioBox } from './RadioBox'

export const PARTS = ['box', 'radio', 'betAmount', 'balance'] as const
export const NAME = 'AmountControl'

export type RadioValues = 'min' | 'max' | 'half' | 'double'

const box: SystemStyleObject = {
  padding: '14px',
  borderRadius: '10px',
  bgColor: '#fff',
}

const radio: SystemStyleObject = {
  cursor: 'pointer',
  padding: 0,
  w: '40px',
  h: '23px',
  lineHeight: '23px',
  color: '#3f4f70',
  bgColor: '#edeff1',
  borderRadius: '7px',
  overflow: 'hidden',
  _checked: {
    bgColor: '#235cf1',
    color: '#fff',
  },
  textAlign: 'center',
  fontSize: '12px',
}

export type Option<T extends string = string> = {
  key: T
  label: string
  onClick?: (key: T) => void
}

export interface AmountControlProps {
  value: number
  maxValue?: number
  minValue?: number
  subText: string
  disabled?: boolean
  integer?: boolean

  /**
   * [leftTop, leftDown, rightTop, rightDown]
   */
  options: [Option, Option, Option, Option]

  onChange?: (value: number) => void
  onFocus?: () => void

  onBlur?: (value: number) => void

  /**
   * 用于前缀转换
   * @param num 实际input输入的值
   * @returns 返回格式化后的值
   *
   * 例如 valueFormat = (value) => `$ ${number}` // 10 => $ 10
   */
  valueFormat?: (num: number | string) => string

  /**
   * 配合valueForman反向获得实际输入的值
   *
   * @param value 经过format后的值
   * @returns 返回实际的输入值
   *
   * value.replace(`$ `, '') // $ 10 => 10
   */
  parse?: (value: string) => string
}

export const AmountControl: FC<AmountControlProps> = ({
  maxValue,
  minValue,
  value,
  valueFormat,
  subText,
  disabled,
  onChange,
  onBlur,
  onFocus,
  integer,
  parse,

  options = [],
}) => {
  const [inputValue, setInputValue] = useState<string>(toString(value))
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleNumberInputChange = (
    valueAsString: string,
    valueAsNumber: number,
  ) => {
    const checkedValue = valueAsString === '.' ? '' : valueAsString
    setInputValue(checkedValue)
  }

  const { getInputProps } = useNumberInput({
    step: 1,
    onChange: handleNumberInputChange,
    onFocus,
    parse,
  })
  const inp = getInputProps()

  const {
    box: boxStyle,
    radio: radioStyle,
    betAmount: stakeStyle,
    balance: banlanceStyle,
  } = useCustomizedStyle(
    NAME,
    {
      box,
      radio,
      betAmount: {
        h: '24px',
        lineHeight: '24px',
        textAlign: 'center',
        mb: '5px',
        color: '#f43434',
        fontWeight: '700',
        fontSize: '18px',
      },
      balance: {
        h: '23px',
        lineHeight: '23px',
        textAlign: 'center',
        fontWeight: '700',
        fontSize: '14px',
      },
    },
    ['box', 'radio', 'betAmount', 'balance'],
  )

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'amount',
  })
  const groupProps = getRootProps()

  const radioBoxs = useMemo(
    () =>
      options.map(opt => (
        <RadioBox
          key={opt.key}
          {...getRadioProps({ value: opt.key })}
          sx={radioStyle}
          onclick={opt.onClick}
        >
          {opt.label}
        </RadioBox>
      )),
    [getRadioProps, options],
  )

  const updateInputValue = useCallback((v: number) => {
    setInputValue(toString(v))
  }, [])

  const handleBlur = useCallback(() => {
    const nextNumbericValue = toNumber(inputValue)
    const isNaN = Number.isNaN(nextNumbericValue)

    if (isNaN) {
      updateInputValue(value)
      onBlur && onBlur(value)
    } else {
      if (onChange) {
        let nextValue = getLimitedResult(nextNumbericValue, maxValue, minValue)

        nextValue = integer ? floor(nextValue) : nextValue

        if (nextValue !== value) {
          // 更新value， 由副作用再更新内部状态
          onChange(nextValue)
        } else {
          // 如果相等不更新， 要手动更新已经编辑过后的状态
          updateInputValue(value)
        }
        onBlur && onBlur(value)
      }
    }
  }, [
    inputValue,
    integer,
    maxValue,
    minValue,
    onBlur,
    onChange,
    updateInputValue,
    value,
  ])

  useEffect(() => {
    updateInputValue(value)
  }, [value])

  const inputValueFontmatted = useMemo(
    () => (valueFormat ? valueFormat(inputValue) : inputValue),
    [inputValue, valueFormat],
  )

  const inputFontSize = useAdaptableFontSize(inputValueFontmatted, {
    containerRef: inputRef,
    minStrLen: 9,
    maxFontSize: 18,
  })

  return (
    <Box overflow='hidden'>
      <Flex
        w='f'
        {...groupProps}
        pos='relative'
        overflow='hidden'
        sx={boxStyle}
      >
        {/* {disabled && <MaskLayer />} */}
        <Flex direction='column' justifyContent='space-between'>
          {[radioBoxs[0], radioBoxs[1]]}
        </Flex>
        <Flex
          flex={1}
          overflow='hidden'
          direction='column'
          justifyContent='space-between'
        >
          <Input
            {...inp}
            ref={inputRef}
            value={inputValueFontmatted}
            onBlur={handleBlur}
            sx={stakeStyle}
            style={{ fontSize: `${inputFontSize}px` }}
            variant='unstyled'
          />
          <Text
            sx={banlanceStyle}
            noOfLines={1}
            maxW='full'
            whiteSpace='nowrap'
          >
            {subText}
          </Text>
        </Flex>
        <Flex direction='column' justifyContent='space-between'>
          {[radioBoxs[2], radioBoxs[3]]}
        </Flex>
      </Flex>
    </Box>
  )
}
