import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'

import type { DrawTask } from '../types'
import ProgressBar from './ProgressBar'

type ProgressProps = {
  type?: 'text' | 'bar'
  label?: string | null
  target: number
  current: number
}

type ButtonProps = {
  onClick(): void
  text: string
  isDisabled?: boolean
}

interface Props extends DrawTask {
  icon: string
  title: string
  progressProps?: ProgressProps
  buttonProps?: ButtonProps
  drawTimes: number
}

function TaskItem(props: Props) {
  const { icon, title, progressProps, buttonProps, drawTimes } = props
  const progressRender = () => {
    if (!progressProps) return null
    const { type = 'bar', current, target, label } = progressProps
    if (type === 'text') {
      return (
        <Text textStyle='text6' color='white' textAlign='left'>
          {`${label} (${current}/${target})`}
        </Text>
      )
    }
    if (type === 'bar') {
      return <ProgressBar current={current} total={target} />
    }
    return null
  }
  return (
    <Flex
      bg='rgba(255,255,255,0.3)'
      w='full'
      borderRadius='12px'
      height='70px'
      p='12px'
      alignItems='center'
    >
      <Image src={icon} boxSize='48px' />
      <Box mx='3' flexGrow={1}>
        <Text textStyle='h5' color='white' textAlign='left' w='full' mb='2'>
          {title}
          <Text textStyle='h6' color='four.500' as='span' ml='2'>
            +{drawTimes} draw
          </Text>
        </Text>
        {progressRender()}
      </Box>
      <Button
        type='button'
        onClick={buttonProps?.onClick}
        isDisabled={buttonProps?.isDisabled}
        flexShrink={0}
        width='86px'
        borderRadius='8px'
        backgroundColor='prim.500'
        color='white'
        _hover={{ backgroundColor: 'prim.500' }}
      >
        <Text cursor='inherit' textStyle='h7'>
          {buttonProps?.text}
        </Text>
      </Button>
    </Flex>
  )
}

export default TaskItem
