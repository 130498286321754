import { lazy } from 'react'
import type { RouteObject } from 'react-router-dom'

import { isProduction } from '@/common/env'

const Design = lazy(() => import('@/modules/dev/design/Design'))
export const devRouter: RouteObject[] = []

/** only available in non-production environment */
if (!isProduction()) {
  devRouter.push({
    path: '/__dev__/design',
    element: <Design />,
  })
}
