import FAIL_ICON from '@/assets/images/wallet/walletAct/fail.png'
import SUCCESS_ICON from '@/assets/images/wallet/walletAct/success.png'
import TOMORROW_ICON from '@/assets/images/wallet/walletAct/tomorrow.png'

import type { tipsTypeMapProps } from './type'

export const RECHARGE_STATUS_MAP: tipsTypeMapProps = {
  // 参与成功
  success: {
    title: 'PARTICIPATE_SUCCESSFULLY',
    desc: 'CONGRATULATION_SUCCESSFULLY',
    btn_text: 'OK_BUTTON',
    status: 'success_status',
    icon: SUCCESS_ICON,
    style: 'success_status',
  },
  // 参与失败
  fail: {
    title: 'PARTICIPATE_FAILURE',
    desc: 'CLICK_GAGIN_BUTTON',
    btn_text: 'RE_ENGAGEMENT',
    status: 'fail_status',
    icon: FAIL_ICON,
    style: 'fail_status',
  },
  // 参与失败，无法再参与
  nochance: {
    title: 'PARTICIPATE_FAILURE',
    desc: 'PARTICIPATE_ONE',
    btn_text: 'EXPERIENCE_GAME',
    status: 'nochance_status',
    icon: TOMORROW_ICON,
    style: 'fail_status',
  },
  //  参与失败，今日无法再参与
  tomorrow: {
    title: 'PARTICIPATE_FAILURE',
    desc: 'PARTICIPATE_TOMORROW',
    btn_text: 'OK_BUTTON',
    status: 'tomorrow_status',
    icon: TOMORROW_ICON,
    style: 'fail_status',
  },
}
