import { api } from '@/services/api'
import type { Response } from '@/services/types'

import type {
  LiveGameParam,
  SlotGameParam,
  SlotOrLiveReponseData,
} from './types'

export const slotGameApi = api.injectEndpoints({
  endpoints: builder => ({
    // slot游戏获取视频信息
    slotGameInfo: builder.mutation<
      Response<SlotOrLiveReponseData>,
      { token: string; user_id: string; data: SlotGameParam }
    >({
      query: data => ({
        url: `/game/evoplay/play`,
        method: 'POST',
        data,
      }),
    }),
    // live游戏获取视频信息
    liveGameInfo: builder.mutation<
      Response<SlotOrLiveReponseData>,
      LiveGameParam
    >({
      query: data => ({
        url: `/game/livecasino/play`,
        method: 'POST',
        data,
      }),
    }),
  }),
})

export const { useSlotGameInfoMutation, useLiveGameInfoMutation } = slotGameApi
