import type { ComponentStyleConfig } from '@chakra-ui/react'

import { NAME } from '@/modules/game/ranking/RankingHeadBox'

const componentStyle: ComponentStyleConfig = {
  baseStyle: {
    '--bg-gradient': 'linear-gradient(to bottom, #022054, #105BBC)',
  },
}

export const name = NAME
export default componentStyle
