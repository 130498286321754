import { Flex, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { Component } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { isProduction } from '@/common/env'
import { error } from '@/common/logger'
import LazyImage from '@/components/lazy-image/LazyImage'
import generateRandomHash from '@/utils/generateRandomHash'

import errimg from './error.png'

class ErrorBoundary extends Component<any, any> {
  state = { hasError: false, errmsg: undefined }

  /**
   * 更新 state 使下一次渲染能够显示降级 UI
   * @param err
   * @returns
   */
  static getDerivedStateFromError(err: Error) {
    return { hasError: true, errmsg: err.message }
  }

  /**
   * 这里可以上报
   * @param err
   * @param errorInfo
   */
  componentDidCatch(err: any, errorInfo: any) {
    // 因为版本更新导致的动态加载脚本错误
    const importedError = err?.message?.includes(
      'Failed to fetch dynamically imported module',
    )
    if (!importedError) {
      error('Error Boundary caught an error:', err, errorInfo)
    }
  }

  /**
   *
   * @param prevProps
   * @param prevState
   */
  componentDidUpdate(prevProps: any, prevState: any) {
    // 如果组件状态变为错误状态，刷新页面
    if (this.state.hasError && !prevState.hasError) {
      const hash = generateRandomHash()
      // if (isProduction()) {
      setTimeout(() => {
        // 获取当前路径上所有的参数
        const searchParams = new URLSearchParams(window.location.search)
        // 将错误信息添加到参数中
        let target = `/?${hash}`
        // 把所有的参数都添加到新的路径中
        for (const [key, value] of searchParams.entries()) {
          target += `&${key}=${value}`
        }

        // test
        window.location.href = target
      }, 1000)
      // }
    }
  }

  render() {
    if (this.state.hasError) {
      const msg = isProduction() ? '' : this.state.errmsg

      // 可以自定义降级的 UI 并渲染
      return <DymicImportError />
    }

    return this.props.children
  }
}

export default ErrorBoundary

export const DymicImportError: FC<{ msg?: string }> = ({ msg }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Flex
      direction='column'
      justifyContent='center'
      alignItems='center'
      textAlign='center'
      py='40'
      px='5'
    >
      <LazyImage src={errimg} alt='' width='190px' h='160px' mb='6' />
      <Text textStyle='text4' color='gray.900' mb='8' w='full' maxW='xs'>
        {t('COMMON_REFRESH_TIPS')}
      </Text>
    </Flex>
  )
}
