import { useCallback, useMemo } from 'react'

import { useAppSelector } from '../app/store'
import { FORM_INPUT_REG, getSpecialKey } from './walletContansts'
import { getType, WalletModalTab } from './walletSlice'

const list = getSpecialKey()

export const useSpecialTips = () => {
  const tabType = useAppSelector(getType) as WalletModalTab

  const necessary = useMemo(() => {
    return tabType === WalletModalTab.recharge
  }, [tabType])

  //是否特殊值
  const isUseErrorText = useCallback((key: string) => {
    return list?.includes(key)
  }, [])
  //当前验证的值
  const errorText = useCallback(
    (
      key: string,
      value: string,
      params: { [key: string]: string },
      removeBlank?: boolean,
      pay_method?: string,
    ) => {
      return FORM_INPUT_REG[key]({
        params,
        value,
        necessary,
        removeBlank,
        pay_method,
      })?.text
    },
    [necessary],
  )
  return {
    errorText,
    isUseErrorText,
  }
}
