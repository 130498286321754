enum PerformanceMarkTag {
  RechargeStart = 'RECHARGE_START',
  RechargeEnd = 'RECHARGE_END',

  PageStart = 'page-start',
  PageEnd = 'page-end',

  WalletPageStart = 'WALLET_PAGE_START',
  WalletPageEnd = 'WALLET_PAGE_END',
}

export default PerformanceMarkTag
