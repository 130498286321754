import { useToast } from '@chakra-ui/react'
import { t } from 'i18next'

import { useUserAuth } from '@/utils/hooks'

import { useMarkMessageAsReadMutation } from '../api'
import type { Message } from '../types'

function useReadNotification() {
  const [markMessageAsRead] = useMarkMessageAsReadMutation()
  const [userId, token] = useUserAuth()
  const toast = useToast()
  const handleMarkMessageAsRead = async (item: Message) => {
    if (!item.guid || item.is_read) {
      return
    }
    try {
      await markMessageAsRead({
        user_id: userId,
        token,
        guid: item.guid,
      }).unwrap()
      toast({ title: t('USER_READ_SIGNED'), status: 'success' })
    } catch (error) {
      toast({ title: t('USER_READ_SIGNED_FAIL'), status: 'error' })
    }
  }

  return {
    handleMarkMessageAsRead,
  }
}

export default useReadNotification
