import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Image,
  Mark,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Progress,
  Text,
  useClipboard,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { toString } from 'lodash-es'
import type { FC } from 'react'
import React, { memo, useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import BuiltInAvatar from '@/components/built-in-avatar/BuiltInAvatar'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { STORAGE_KEY } from '@/modules/app-layout/TopBanner'
import type { IIconLink } from '@/modules/app-layout/types'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { getIcon } from '@/utils/get-icon'
import {
  useCustomizedStyle,
  useMobile,
  useUserAuth,
  useUserProgress,
} from '@/utils/hooks'
import {
  formatLocalMoney,
  getFeatures,
  isSupportedImageUrl,
} from '@/utils/tools'
import appConfig from '@/websites/current/pages/layout/app-config.json'

import { usePageChangeSubscription } from '../../app-layout/usePageChangeNotification'
import { getIsBindCurrency, selectIsOpenActive, switchBindModal } from '../../bind-currency/currencySlice'
import useAccountsInfo from '../../new-login/hooks/useAccountsInfo'
import { useGetUserVipInfoQuery } from '../../new-vip/newVipApi'
import { setIsUserVipInfoInvalid } from '../../new-vip/newVipSlice'
import { TripartitelBind } from '../TripartitelBind'
import UserProfilePopoverLevelIcon from '../UserProfilePopoverLevelIcon'
import {
  logout,
  selectAvatarId,
  selectUserName,
  selectVipLevel,
} from '../userSlice'
import { setWithdrawCurrency } from '@/modules/wallet/walletSlice'
import { trackClickWallet } from '@/common/tracker'
import { openWallet } from '@/modules/global-modal/globalModalSlice'
import { getVariable } from '@/common/env'

const loginAndSignUpV3 = getFeatures('loginAndSignUpV3')

interface UserProfilePopoverProps {
  children: React.ReactNode
}

const userNavigations: IIconLink[] =
  appConfig?.userProfilePopover?.navigations ?? []

const UserProfilePopoverContext = React.createContext<{
  isOpen: boolean
  onClose: () => void
}>({
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
})

function UserProfilePopover({ children }: UserProfilePopoverProps) {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const isMobile = useMobile()
  const styles = useCustomizedStyle(
    'UserProfilePopover',
    {
      root: {},
    },
    ['root'],
  )

  return (
    <Popover
      trigger={isMobile ? 'click' : 'hover'}
      onClose={onClose}
      isOpen={isOpen}
    >
      <UserProfilePopoverContext.Provider value={{ isOpen, onClose }}>
        <HidePopoeverOnScroll onClose={onClose} />
        <PopoverTrigger>
          <Box position='relative'>
            {/** trigger box 覆盖头像，防止头像渲染 */}
            <Box
              key={toString(isOpen)}
              onClick={onToggle}
              position='absolute'
              left='0'
              top='0'
              w='full'
              h='full'
              zIndex='9'
            />
            <Box>{children}</Box>
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            width={{ base: '100vw', sm: 'sm' }}
            border='none'
            boxShadow='none'
            overflow='hidden'
            borderRadius='2xl'
            layerStyle='ls-blur'
            _focusVisible={{ boxShadow: 'none', outline: 'none' }}
            sx={styles.root}
          >
            <Content />
          </PopoverContent>
        </Portal>
      </UserProfilePopoverContext.Provider>
    </Popover>
  )
}

const lineStyles: React.ComponentProps<typeof Box> = {
  width: 'full',
  height: '1px',
  background: 'gray.50',
  opacity: 0.1,
}

const Content: FC = () => {
  const { isOpen } = useContext(UserProfilePopoverContext)
  const isOpenActive = useAppSelector(selectIsOpenActive)
  const [userId, token] = useUserAuth()

  /**
   * 获取账号绑定信息
   */
  const { bindInfoValue } = useAccountsInfo()

  const isUserVipInfoInvalid = useAppSelector(setIsUserVipInfoInvalid)

  const { refetch } = useGetUserVipInfoQuery(
    { token, user_id: userId },
    { skip: !token || !userId },
  )

  useEffect(() => {
    if (isUserVipInfoInvalid && isOpen) {
      refetch()
    }
  }, [refetch, isUserVipInfoInvalid, isOpen])

  const [isBindFacebook, isBindGoogle] = useMemo(() => {
    if (loginAndSignUpV3) {
      return [
        !bindInfoValue?.facebook?.verified,
        !bindInfoValue?.google?.verified,
      ]
    }

    return [true, true]
  }, [bindInfoValue])

  return (
    <>
      <UserProfilePopoverHeader />
      <Box p='0' mx='1.5'>
        <Box {...lineStyles} />
        {isOpenActive && <UserProfilePopoverVipInfo />}
        <UserProfilePopoverNavigations />
        <TripartitelBind
          isBindFacebook={isBindFacebook}
          isBindGoolge={isBindGoogle}
        />
      </Box>
      <UserProfilePopoverFooter />
    </>
  )
}

export const MemoUserProfilePopover = memo(UserProfilePopover)

function UserProfilePopoverHeader() {
  const { t } = useTranslation()
  const userName = useAppSelector(selectUserName)
  const avatarId = useAppSelector(selectAvatarId)
  const { onClose } = useContext(UserProfilePopoverContext)
  const { onCopy } = useClipboard(userName)
  const navigate = useNavigate()
  const toast = useToast()
  const handleCopy = useCallback(() => {
    onCopy()
    toast({
      title: t('COMMON_CONTENT_CANCEL'),
      status: 'success',
    })
  }, [toast, onCopy, t])

  const onAvatarClick = useCallback(() => {
    onClose()
    navigate('/user/profile')
  }, [navigate, onClose])

  return (
    <Box p='4' borderBottom='none'>
      <HStack justifyContent='space-between'>
        <Button variant='unstyled' onClick={onAvatarClick}>
          <BuiltInAvatar avatarId={avatarId} boxSize='10' />
        </Button>
        <Text flex='1' textAlign='center' textStyle='h5' color='white'>
          {userName}
        </Text>
        <IconButton
          onClick={handleCopy}
          colorScheme='fill-prim'
          size='sm'
          icon={<Icon boxSize={4} as={SharedIcon.Copy} />}
          aria-label='copy'
          borderRadius='6px'
          height='28px'
          minW='28px'
        />
      </HStack>
    </Box>
  )
}

function UserProfilePopoverVipInfo() {
  const vipLevel = useAppSelector(selectVipLevel)

  return (
    <HStack ml='0.5' mr='3.5' my='4' spacing='2.5'>
      <VStack spacing='2'>
        <UserProfilePopoverLevelIcon vipLevel={vipLevel} />
      </VStack>
      <VStack alignItems='stretch' flex='1' spacing='16px'>
        <UserRechargeProgress />
        <UserBetProgress />
      </VStack>
    </HStack>
  )
}

function UserProfilePopoverFooter() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  return (
    <Box borderTop='none' py='5px' px='0' mx='1.5'>
      <Button
        leftIcon={<Icon boxSize={4} as={SharedIcon.Exit} />}
        variant='ghost'
        color='white'
        w='full'
        onClick={() => {
          const appLaunchSearchString =
            localStorage.getItem('app_launch_search_string') || ''
          dispatch(logout())
          sessionStorage.removeItem(STORAGE_KEY)
          setTimeout(() => {
            window.location.href = `${
              appLaunchSearchString ? '/' + appLaunchSearchString : '/'
            }`
          }, 300)
        }}
      >
        <Text textStyle='text3' cursor='pointer'>
          {t('NAV_LOGOUT')}
        </Text>
      </Button>
    </Box>
  )
}

const SHOW_BIND_SELECT = getVariable('SHOW_BIND_SELECT')

function UserProfilePopoverNavigations() {
  const { t } = useTranslation()
  const { onClose } = useContext(UserProfilePopoverContext)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isBindCurrency = useAppSelector(getIsBindCurrency)

  const navigationLeftIconRender = (icon: string) =>
    isSupportedImageUrl(icon) ? (
      <LazyImage src={icon} boxSize={7} />
    ) : (
      <Icon as={getIcon(icon)} boxSize={6} color='gray.600' />
    )

    const walletClick = () => {
      // 点击wallet埋点
      trackClickWallet()
      if (isBindCurrency || !SHOW_BIND_SELECT) {
        dispatch(openWallet())
        return
      }
      dispatch(switchBindModal())
    }

  const onItemClick = useCallback(
    (item: IIconLink) => {
      onClose()
      if (item.action?.href) {
        navigate(item.action?.href)
      } else if (item.action?.data?.actionType === 'openWallet') {
        dispatch(setWithdrawCurrency(''))
        walletClick()
      }
    },
    [onClose, navigate],
  )

  return (
    <Grid templateColumns='repeat(2, 1fr)' rowGap='1.5' columnGap='2' mb='3'>
      {userNavigations.map(item => (
        <GridItem key={item.key} overflow='hidden'>
          <Button
            leftIcon={navigationLeftIconRender(item.icon)}
            colorScheme='white'
            width='full'
            borderRadius='lg'
            px='3'
            size='lg'
            justifyContent='flex-start'
            onClick={onItemClick.bind(null, item)}
          >
            <Text textStyle='text5' cursor='pointer'>
              {t(item.text)}
            </Text>
          </Button>
        </GridItem>
      ))}
    </Grid>
  )
}

function UserRechargeProgress() {
  const { t } = useTranslation()
  const { percentage, current, target } = useUserProgress('recharge')
  if (target === 0) return
  return (
    <UserProgress
      title={t('VIP_DEPOSIT').toString()}
      percentage={percentage}
      current={current}
      target={target}
    />
  )
}

function UserBetProgress() {
  const { t } = useTranslation()
  const { percentage, current, target } = useUserProgress('bet')
  if (target === 0) return
  return (
    <UserProgress
      title={t('VIP_BET').toString()}
      percentage={percentage}
      current={current}
      target={target}
    />
  )
}

interface UserProgressProps {
  title?: string
  startLabel?: string
  endLabel?: string
  percentage: number
  current: number
  target: number
}

function UserProgress({
  title = '',
  startLabel = '',
  endLabel = '',
  percentage = 0,
  current = 0,
  target,
}: UserProgressProps) {
  return (
    <Grid
      alignItems='stretch'
      justifyContent='space-between'
      flex='1'
      templateColumns='1fr auto'
      gap='1'
      color='white'
      textStyle='text5'
    >
      <GridItem colSpan={2}>
        <HStack justifyContent='space-between'>
          <Text>{title}</Text>
          <Box
            whiteSpace='nowrap'
            overflow='hidden'
            textOverflow='ellipsis'
            color='var(--chakra-colors-prim-500, #F9EB2D)'
          >
            <Text as='span' color='white'>{`${formatLocalMoney(
              current,
            )} / `}</Text>
            <Mark cursor='default' color='inherit'>
              {formatLocalMoney(target)}
            </Mark>
          </Box>
        </HStack>
      </GridItem>
      <GridItem alignSelf='center'>
        <Progress value={percentage} w='full' colorScheme='gray' />
      </GridItem>
      <GridItem alignSelf='center'>
        <Text w='5ch' textAlign='end' whiteSpace='nowrap'>
          {`${percentage}%`}
        </Text>
      </GridItem>
      <GridItem hidden={!startLabel && !endLabel}>
        <HStack justifyContent='space-between'>
          <Text>{startLabel}</Text>
          <Text>{endLabel}</Text>
        </HStack>
      </GridItem>
    </Grid>
  )
}

function HidePopoeverOnScroll(props: { onClose(): void }) {
  const { onClose } = props
  useEffect(() => {
    window?.addEventListener('scroll', () => {
      onClose()
    })
  }, [onClose])

  usePageChangeSubscription(onClose)
  return null
}
