import { usePrevious } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Event, useEvent } from '@/utils/atom-shared'

export interface PageChangeEvent {
  pathname: string
  prevPathname: string
}

export const onPageChange = new Event<PageChangeEvent>()

export function usePageChangeNotification() {
  const { pathname } = useLocation()
  const prevPathname = usePrevious(pathname)

  useEffect(() => {
    if (prevPathname !== pathname) {
      onPageChange.emit({
        pathname,
        prevPathname,
      })
    }
  }, [prevPathname, pathname])
}

export function usePageChangeSubscription(
  handler: (e: PageChangeEvent) => void,
) {
  useEvent<PageChangeEvent>(onPageChange, handler)
}
