import { Icon, IconButton, Spinner } from '@chakra-ui/react'
import { TiStarFullOutline, TiStarOutline } from 'react-icons/ti'

import { isFeatureEnabled } from '@/common/feature'

import { useFavoriteButton } from './useFavoriteButton'

interface Props {
  gameId: string
  gameUuId: string
  onlyShowWhenHover?: boolean
}

function NewFeatureFavoriteButtonInGameCover(props: Props) {
  const { gameId, gameUuId, onlyShowWhenHover = true } = props
  const { onClick, isFavorited, isLoading } = useFavoriteButton(
    gameId,
    gameUuId,
    gameUuId,
  )
  const icon = isLoading ? (
    <Spinner size='xs' />
  ) : (
    <Icon as={isFavorited ? TiStarFullOutline : TiStarOutline} boxSize='4' />
  )
  return (
    <IconButton
      isDisabled={isLoading}
      onClick={onClick}
      position='absolute'
      right='0'
      top='0'
      zIndex='docked'
      color={isFavorited ? 'tert.500' : 'white'}
      icon={icon}
      aria-label='favorite'
      variant='unstyledIcon'
      bgColor='blackAlpha.400'
      boxSize='26px'
      borderBottomStartRadius='md'
      visibility={
        onlyShowWhenHover ? { base: 'visible', lg: 'hidden' } : undefined
      }
      _groupHover={{ visibility: 'visible' }}
      _hover={{
        bgColor: 'blackAlpha.300',
      }}
      _active={{
        bgColor: 'blackAlpha.500',
      }}
    />
  )
}

export function FavoriteButtonInGameCover(props: Props) {
  if (isFeatureEnabled('gameCollection')) {
    return <NewFeatureFavoriteButtonInGameCover {...props} />
  } else {
    return null
  }
}
