import { Icon } from '@chakra-ui/react'
import React, { memo, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon as SharedIcon } from '@/utils/atom-shared'
import projectConfig from '@/websites/current/dataCollection/projectConfig'

import type { FormItemProps } from './Form'
import type { ExtraFormItemProps } from './FormItem'
import { LoginFormInput } from './LoginFormInput'

const { emailSuffixs } = projectConfig

const FormItem: React.FC<FormItemProps & ExtraFormItemProps> = ({
  label,
  name = '',
  value,
  onChange,
  registerFormItem,
  unregisterFormItem,
  updateFormItemError,
  error,
  rules,
  disabled,
  addonBefore,
  addonAfter,
  fieldProps = {},
}) => {
  const { t } = useTranslation()
  const isInit = useRef(true)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(name, e.target.value)
  }

  const validate = useCallback(async () => {
    let errorMsg = ''

    if (rules?.length) {
      for (const rule of rules) {
        try {
          if (rule?.validator) {
            await rule.validator(value)
          } else if (rule?.required) {
            if (!value) {
              throw new Error(rule?.message || 'is empty'.toString())
            }
          }
        } catch (error: any) {
          errorMsg = error.message
          // 处理抛出的错误，可以在此加入您的逻辑
          break // 终止循环
        }
      }
    }

    if (errorMsg) {
      updateFormItemError?.(name, t(errorMsg)) // 更新错误信息
      return false
    }

    updateFormItemError?.(name, '')
    return true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    registerFormItem?.(name, validate)
    return () => {
      unregisterFormItem?.(name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate])

  useEffect(() => {
    !isInit.current && validate()
    isInit.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <LoginFormInput
      label={label}
      value={value}
      handleValueChange={handleChange}
      errorMsg={error}
      addonBefore={addonBefore || <Icon boxSize='5' as={SharedIcon.Email1} />}
      addonAfter={addonAfter}
      disabled={disabled}
      fieldProps={{
        name: 'email',
        type: 'text',
        placeholder: t('USER_INPUT_EMAIL') || '',
        ...fieldProps,
        // autoComplete: 'new-password',
      }}
      autoComplete={emailSuffixs}
    />
  )
}

const EmailFormItem = memo(FormItem)

EmailFormItem.displayName = 'FormItem'

export default EmailFormItem
