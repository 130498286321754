import { keyframes } from '@chakra-ui/react'

import mWheelbg from '@/assets/images/wheel-modal/m_wheelbg.png'
import mWheeltop from '@/assets/images/wheel-modal/m_wheeltop.png'
import wheelbg from '@/assets/images/wheel-modal/wheelbg.png'
import wheeltop from '@/assets/images/wheel-modal/wheeltop.png'

const rotateAnimationKeyframes = keyframes`
    0% { transform: rotate(0deg) }
    100% { transform: rotate(-360deg)}
  `
export const rotateAnimation = `${rotateAnimationKeyframes} 24s linear infinite`

const opacityAnimationKeyframes = keyframes`
    0% { opacity: 0 }
    100% { opacity: 1}
  `
export const opacityAnimation = `${opacityAnimationKeyframes} 2s linear forwards`

export const overviewStyle = {
  modal: {
    width: '950px',
    height: '611px',
    backgroundImage: wheelbg,
    backgroundSize: '890px auto',
  },
}

export const getWheelStyle = (isMobile: boolean) => {
  let wheelStyle
  if (!isMobile) {
    wheelStyle = {
      modal: {
        width: '950px',
        height: '611px',
        backgroundImage: wheelbg,
        backgroundSize: '890px auto',
      },
      modalTopBtn: {
        right: '40px',
        top: '48px',
      },
      modalTopBtnImg: {
        width: '64px',
        marginRight: '-8px',
        cursor: 'pointer',
      },
      wheeltop: {
        right: '0',
        top: '66px',
        width: '476px',
        height: '117px',
        backgroundImage: wheeltop,
      },
      wheeltopTitle: {
        left: '68px',
        top: '15px',
      },
      wheeltopAmout: {
        fontSize: '36px',
        marginTop: '46px',
      },
      labelItem: {
        width: '140px',
        height: '38px',
      },
      wheelPerson: {
        left: '490',
        top: '194',
        width: '315px',
      },
      logo: {
        left: '593px',
        top: '309px',
      },
      btnBox: {
        left: '490px',
        top: '438px',
        height: '74px',
        lineHeight: '74px',
        width: '283px',
        fontSize: '26px',
      },
      time: {
        width: '67px',
        height: '67px',
        lineHeight: '67px',
        left: '739px',
        top: '405px',
        fontSize: '32px',
      },
    }
  } else {
    wheelStyle = {
      modal: {
        width: '95vw',
        height: '520px',
        backgroundImage: mWheelbg,
        backgroundSize: '100% auto',
      },
      modalTopBtn: {
        right: '22px',
        top: '22px',
      },
      modalTopBtnImg: {
        width: '37px',
        marginRight: '-4px',
      },
      wheeltop: {
        right: '0',
        top: '30px',
        width: '100%',
        height: '80px',
        backgroundImage: mWheeltop,
      },
      wheeltopTitle: {
        left: '45px',
        top: '2px',
      },
      wheeltopAmout: {
        fontSize: '24px',
        marginTop: '26px',
      },
      labelItem: {
        width: '80px',
        height: '30px',
      },
      wheelPerson: {
        left: '-10%',
        top: '130px',
        width: '267px',
      },
      logo: {
        left: '50%',
        top: '288px',
        transform: 'translateX(-50%)',
      },
      btnBox: {
        left: '50%',
        top: '400px',
        height: '49px',
        lineHeight: '49px',
        width: '183px',
        transform: 'translateX(-50%)',
        fontSize: '18px',
      },
      time: {
        width: '44px',
        height: '44px',
        lineHeight: '44px',
        left: 'calc(50% + 68px)',
        top: '378px',
        fontSize: '18px',
      },
    }
  }
  return wheelStyle
}
export const getLotteryStyle = (isMobile: boolean) => {
  let lotteryStyle
  if (!isMobile) {
    lotteryStyle = {
      modal: {
        width: '892px',
        height: '622px',
      },
      rollOutcleBox: {
        width: '940px',
        height: '936px',
        backgroundSize: '1298px 1298px',
      },
      rollOutcle3: {
        width: '850px',
        height: '850px',
      },
      rollOutcle2: {
        width: '719px',
        height: '655px',
      },
      rolloutbg2: {
        width: '521px',
        height: '521px',
      },
      rollBouns: {
        width: '549px',
        height: '554px',
      },
      rollingCenter: {
        width: '100px',
        height: '100px',
      },
    }
  } else {
    lotteryStyle = {
      modal: {
        width: '95vw',
        height: '520px',
      },
      rollOutcleBox: {
        width: '600px',
        height: '600px',
        backgroundSize: '600px 600px',
      },
      rollOutcle3: {
        width: '550px',
        height: '550px',
      },
      rollOutcle2: {
        width: '450px',
        height: '412px',
      },
      rolloutbg2: {
        width: '90vw',
        height: '90vw',
      },
      rollBouns: {
        width: '90vw',
        height: '90vw',
      },
      rollingCenter: {
        width: '80px',
        height: '80px',
      },
    }
  }
  return lotteryStyle
}
