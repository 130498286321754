import {
  createDraftSafeSelector,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { keyBy, random, sample } from 'lodash-es'

import { isFeatureEnabled } from '@/common/feature'
import { pageConfigApi } from '@/services/jsonApi'
import { formatLocalMoney } from '@/utils/tools'

import type { RootState } from '../app/store'
import {
  HostTag,
  multipleDomainsApi,
} from '../multiple-domains/multiple-domains-api'
import type { Task } from '../task/types'
import { referralApi } from './api'
import type {
  InviteConfig,
  ReferralTaskItem,
  ReferralUseTaskItem,
} from './types'

interface AchievementItem {
  head_count: number
  bonus: number
  icon: string
  [key: string]: any
}

export interface AgentInfoResponseData {
  accomplishment_bet_target: number
  accomplishment_refresh_period: number
  bet_award_flow: number
  bet_award_rate_level_1: number
  bet_award_rate_level_2: number
  bet_award_rate_level_3: number
  currency: string
  head_refund: number
  head_refund_flow: number
  recharge_award_flow: number
  recharge_award_rate_level_1: number
  recharge_award_rate_level_2: number
  recharge_award_rate_level_3: number
  status: number
  valid_subordinate_recharge_limit: number
  version: number
  [key: string]: any
}

interface AgentInfo {
  // 人头费
  rechargeRebate?: number
  minInviteNum?: number // 最低邀请人数
  maxRebateInMinInviteNum?: number // 最低邀请人数对应的最大人头费
  desc?: string
  stepCfg?: { [key: string]: { [key: string]: number } }
}

export type ReferralStateNew = {
  achieveList: AchievementItem[]
  userInviteList: Task[]
  referralTaskList: ReferralTaskItem[]
  referralUseTaskList: ReferralUseTaskItem[]
  agentCfgInfo: AgentInfo
  referralBettingRebate: boolean
  shareUrl?: string
  rewardConfig: {
    bet_reward_rate_level_1: string
    bet_reward_rate_level_2: string
    bet_reward_rate_level_3: string
  }
  shareDomain?: string
  allShareDomain?: string[]
  allShareUrl?: string[]
}

const initialState: ReferralStateNew = {
  achieveList: [],
  userInviteList: [],
  referralTaskList: [],
  referralUseTaskList: [],
  agentCfgInfo: {
    rechargeRebate: 0,
    minInviteNum: 0,
    maxRebateInMinInviteNum: 0,
    desc: '',
    stepCfg: {},
  },
  shareUrl: '',
  referralBettingRebate: false,
  rewardConfig: {
    bet_reward_rate_level_1: '0',
    bet_reward_rate_level_2: '0',
    bet_reward_rate_level_3: '0',
  },
}

export const referralSlice = createSlice({
  name: 'referralNew',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(
        referralApi.endpoints.agentAchieve.matchFulfilled,
        (state, action) => {
          const { payload } = action
          const data = payload?.data || []
          const sortData = data?.sort(
            (a: AchievementItem, b: AchievementItem) =>
              a.head_count - b.head_count,
          )
          state.achieveList = sortData
        },
      )
      .addMatcher(
        referralApi.endpoints.getReferralInviteConfig.matchFulfilled,
        (state, action) => {
          const { payload } = action
          let max = 0
          const _data = payload.data || []
          let config: InviteConfig | null = null
          if (Array.isArray(_data)) {
            _data.sort((a: any, b: any) => b.invite_type - a.invite_type)
            config = _data[0]
          } else {
            config = _data
          }

          const step_setting = config?.setting?.step_setting || {}
          const inviteList =
            Object.keys(step_setting)?.map(item => Number(item)) || []
          const minInviteNum = Math.min(...inviteList)

          const minConfig = step_setting?.[minInviteNum] || {}
          const minConfigList =
            Object.values(minConfig)?.map(item => Number(item)) || []

          const maxRebateInMinInviteNum = Math.max(...minConfigList)

          const stepSetting = config?.setting?.step_setting
          if (stepSetting) {
            Object.keys(stepSetting).forEach(key => {
              const values = stepSetting[key]
              Object.keys(values).forEach(valueKey => {
                const rawValue = values[valueKey]
                const value =
                  typeof rawValue === 'string' ? parseInt(rawValue) : rawValue
                if (value > max) {
                  max = value
                }
              })
            })
          }
          state.agentCfgInfo.stepCfg = step_setting || {}
          state.agentCfgInfo.rechargeRebate = max
          state.agentCfgInfo.maxRebateInMinInviteNum = maxRebateInMinInviteNum
          state.agentCfgInfo.minInviteNum = minInviteNum
          state.agentCfgInfo.desc = config?.desc || ''
        },
      )
      .addMatcher(
        referralApi.endpoints.getReferralTask.matchFulfilled,
        (state, action) => {
          const { payload } = action
          state.referralTaskList =
            payload?.data
              ?.slice()
              ?.sort((a, b) => a.invite_num - b.invite_num) ?? []
        },
      )
      .addMatcher(
        referralApi.endpoints.getUserReferralTask.matchFulfilled,
        (state, action) => {
          const { payload } = action
          state.referralUseTaskList =
            payload?.data
              ?.slice()
              ?.sort((a, b) => a.invite_num - b.invite_num) ?? []
        },
      )
      .addMatcher(
        pageConfigApi.endpoints.getHomePageConfig.matchFulfilled,
        (state, action) => {
          const { payload } = action

          const _url = payload?.share_url?.domain || ''
          const urls = _url.split(';')?.filter((item:string) => !!item)
          const lower = 0
          const upper = urls.length - 1
          const randomIndex = random(lower, upper)
          const randomUrl = urls[randomIndex]

          state.allShareUrl = urls
          state.shareUrl =
            payload?.share_url?.domain === undefined ? '' : randomUrl
        },
      )
      .addMatcher(
        referralApi.endpoints.getReferralConfig.matchFulfilled,
        (state, action) => {
          const { payload } = action
          const rewardConfig = payload?.data?.reward_config || {}

          try {
            state.referralBettingRebate = !!(
              Number(rewardConfig?.bet_reward_rate_level_1) ||
              Number(rewardConfig?.bet_reward_rate_level_2) ||
              Number(rewardConfig?.bet_reward_rate_level_3)
            )
          } catch (error) {}

          state.rewardConfig = rewardConfig || {}
        },
      )
      .addMatcher(
        multipleDomainsApi.endpoints.getHostByTag.matchFulfilled,
        (state, action) => {
          if (HostTag.ShareEntry in action.meta.arg.originalArgs.tag_host_map) {
            const tagMapResult = action.payload.data.tag_host_map
            const hosts = tagMapResult[HostTag.ShareEntry]?.list ?? []
            state.allShareDomain = hosts
            state.shareDomain = sample(hosts)
          }
        },
      )
  },
})

const selectSelf = (state: RootState) => state
export const selectReferral = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => state.referral as ReferralStateNew,
)

export const selectUserInviteUrl = createSelector(
  selectReferral,
  referral =>
    (referral.shareDomain ?? referral.shareUrl) || window.location.origin,
)

export const selecAlltUserInviteUrl = createSelector(
  selectReferral,
  referral =>
    (referral.allShareUrl) ||[ window.location.origin],
)

// This list contains complete information about each agent task
export const selectReferralTaskList = createSelector(
  selectReferral,
  referral => referral.referralTaskList,
)

// This list contains the progress of a user's agent tasks and some simple task information
export const selectReferralUseTaskList = createSelector(
  selectReferral,
  referral => referral.referralUseTaskList,
)

export const selectAchieveList = createSelector(
  selectReferralTaskList,
  selectReferralUseTaskList,
  (taskList, userTaskList) => {
    if (!isFeatureEnabled('referralGroup')) return userTaskList
    const userTaskMap = keyBy(userTaskList, 'task_id')
    return taskList.map(task => ({ ...task, ...userTaskMap[task.id] }))
  },
)

export const selectAgentRechargeRebate = (state: RootState) =>
  state.referral.agentCfgInfo?.rechargeRebate ?? 0

export const selectAgentHeadFeedStepCfg = createSelector(
  selectReferral,
  referral => referral.agentCfgInfo.stepCfg || {},
)

export const selectReferralBettingRebate = createSelector(
  selectReferral,
  referral => referral.referralBettingRebate,
)

export const selectRewardConfig = createSelector(
  selectReferral,
  referral => referral.rewardConfig,
)

export const selectAgentMinInviteNumber = (state: RootState) =>
  state.referral.agentCfgInfo?.minInviteNum ?? 0

export const selectAgentMaxRebateInMinInviteNum = (state: RootState) =>
  state.referral.agentCfgInfo?.maxRebateInMinInviteNum ?? 0

export const selectAgentCfgDesc = (state: RootState) =>
  state.referral.agentCfgInfo?.desc ?? ''

export const selectAchievementBonus = createSelector(
  selectReferralUseTaskList,
  selectReferralTaskList,
  (referralUseTaskList, referralTaskList) => {
    const taskList = isFeatureEnabled('referralGroup')
      ? referralTaskList
      : referralUseTaskList
    const achievementBonus = taskList
      ?.slice()
      ?.sort((a, b) => a.invite_num - b.invite_num)
      .map(item => {
        return {
          invite: item.invite_num || 0,
          price: Number(item.reward) || 0,
        }
      })
    return achievementBonus.map(item => {
      return {
        invite: item.invite,
        price: formatLocalMoney(item.price),
        priceWithoutUnit: item.price,
      }
    })
  },
)

export const referralNewReducer = referralSlice.reducer
