// 该组件是登录界面的通用部分

import { Box, Button, Link, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { Link as RouterLink } from 'react-router-dom'

import { isFeatureEnabled } from '@/common/feature'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { closeModal, openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { appName, features } from '@/websites/current/property.json'

import { LoginModalTab } from '../../LoginModalTab'
import { selectBonusShowPosition } from '../newLoginAndSignUpConfigSlice'
import { Bonus } from './SignUpCommon'

function LoginCommon({
  isLoading,
  showForgetPassword,
  scene,
}: {
  isLoading: boolean
  showForgetPassword?: boolean
  scene?: 'account' | 'phone'
}) {
  const dispatch = useAppDispatch()

  const handleOpenResetPwdDialog = () => {
    dispatch(openModal({ key: GlobalModalKey.ResetPassword }))
  }

  /**
   * 点击服务条款
   */
  const handleService = () => {
    dispatch(closeModal({ key: GlobalModalKey.Login }))
  }
  const bonusShowPosition = useAppSelector(selectBonusShowPosition) ?? []

  const isShowBonus = bonusShowPosition.includes('loginBtn')

  return (
    <>
      {showForgetPassword ? (
        <Box textAlign='right'>
          <Button variant='link' onClick={handleOpenResetPwdDialog} mr='3'>
            <Text textStyle='text6' cursor={'pointer'}>
              {t('USER_FROGETPWD')}
            </Text>
          </Button>
        </Box>
      ) : null}
      <Box
        mt={isShowBonus ? (scene === 'account' ? '40px' : '60px') : '6'}
        mb='4'
        position={'relative'}
      >
        {isShowBonus && <Bonus />}
        <Box
          position={'absolute'}
          left={'100px'}
          top={'-6px'}
          width={0}
          height={0}
        />
        <Button
          size='lg'
          width='100%'
          colorScheme='fill-prim'
          type='submit'
          isLoading={isLoading}
        >
          {t('USER_LOGIN')}
        </Button>
      </Box>
      <Box px='1.5'>
        <Text textStyle='text4' color='text.base'>
          {t('USER_CONSENT_LOGIN')}
          <Link
            as={RouterLink}
            to='/help/terms_and_condition'
            onClick={handleService}
          >
            {t('USER_TERMS')}
          </Link>
        </Text>
      </Box>

      <Box w='full' textAlign='center' mt='5'>
        <Text textStyle='text4' color='gray.700'>
          {t('USER_NO_ACCOUNT', {
            website: appName,
          })}
          &nbsp;
          <Link
            onClick={() => {
              dispatch(
                openModal({
                  key: GlobalModalKey.Login,
                  data: {
                    index: LoginModalTab.SignUp,
                  },
                }),
              )
            }}
          >
            {t('USER_CREATE_ACCOUNT')}
          </Link>
        </Text>
      </Box>
    </>
  )
}

export default LoginCommon
