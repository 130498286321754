import { VStack } from '@chakra-ui/react'
import { memo, useEffect, useMemo, useRef, useState } from 'react'

import { getVariable } from '@/common/env'
import { FeatureToggle, isFeatureEnabled } from '@/common/feature'
import { getCurrencyCode } from '@/common/locale/currency'
import Advantage from '@/modules/advantage'
import FloatButtons from '@/modules/app-layout/FloatButtons'
import HomeGames from '@/modules/game/logic/home-games/HomeGames'
import HomeApiGames from '@/modules/game/logic/HomeApiGames'
import Ranking from '@/modules/game/ranking'
import property from '@/websites/current/property.json'

import FloatCenterButton from '../app-layout/FloatCenterButton'
import ErrorBoundary from '../error/ErrorBoundary'
import { CollectionGamesProvider } from '../my-games/useGameGrid'
import SearchTigger from '../search-game/SearchTigger'
import HeroSection, {
  BottomH24HoursRank,
  MobileV24HoursRank,
} from './HeroSection'
import {
  openModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import GameRecordsAndCollection from './GameRecordsAndCollection'
import withIsUserLogin from './withIsUserLogin'
import { isFeatureEnabledV2 } from '@/common/featureSwiitch'
import { GlobalModalKey } from '../global-modal/types'
import { useAppDispatch, useAppSelector } from '../app/store'
import { selecAlltUserInviteUrl } from '../new-referral/referralSlice'
import { getDeviceType } from '@/utils/tools'
import { useMobile, useUserInfo } from '@/utils/hooks'
import { isSameOrigin } from '../download-app-guide-modal/downloadAppGuide'
import { useClaimFirstLoginRewardMutation } from '../guide-event/guideEventApi'
import { toggleBalanceFloat, updateBanlance } from '../user/userSlice'
const EnhancedGameRecordsAndCollection = withIsUserLogin(
  GameRecordsAndCollection,
)

const CURRENCY = getCurrencyCode()

const { locale, features } = property

const HIDE_HOME_RANK = getVariable('HIDE_HOME_RANK')

function _Home() {
  const dispatch = useAppDispatch()
  const shareAllUrl = useAppSelector(selecAlltUserInviteUrl)
  const currentUrl = window.location.origin
  const isShareLink = shareAllUrl.some(url => isSameOrigin(url, currentUrl))
  const deviceType = getDeviceType()
  const hasClaimedRef = useRef(false);
  const { userInfo } = useUserInfo()
  const [isLoading, setIsLoading] = useState(false);
  const hasFetchedRef = useRef(false);
  const isAppOrDesktop = ['ios_app', 'ios-desktop-icon', 'apk'].includes(deviceType)

  const banlance: number = userInfo?.banlance?.amount as number
  const [claimFirstLoginReward] = useClaimFirstLoginRewardMutation()

  const isMobile = useMobile()
  const { data } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.IntroductionToDownloadModal],
  )
  const firstLoginRewardInfo = useMemo(() => {
    if (data?.firstLoginRewardInfo) {
      return {
        ...data.firstLoginRewardInfo,
      }
    }
    return undefined
  }, [data])
  useEffect(() => {
    const fetchData = async () => {
      
      try {
        setIsLoading(true);
        hasFetchedRef.current = true;
      } catch (error) {
        console.error('Refetch error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (isAppOrDesktop) {
      fetchData();
    }

    return () => {
      hasFetchedRef.current = false;
    };
  }, [isAppOrDesktop]);
  const handleClaimFirstLoginReward = async (biz_id: string) => {
    try {
      await claimFirstLoginReward({ biz_id }).unwrap()
      dispatch(
        toggleBalanceFloat({
          balance: firstLoginRewardInfo?.reward || 0,
        }),
      )
      updateBanlance(Number((firstLoginRewardInfo?.reward || 0) + banlance))
      dispatch(
        openModal({
          key: GlobalModalKey.RewardSuccess,
          data: firstLoginRewardInfo?.reward || 0,
        }),
      )
    } catch (error) {
    }
  }
  const conformDevice = () => {
    return (
      (deviceType === 'apk' &&
        firstLoginRewardInfo?.open_platform?.includes('android_app')) ||
      (deviceType === 'ios-desktop-icon' &&
        firstLoginRewardInfo?.open_platform?.includes('ios_desktop')) ||
      (deviceType === 'ios_app' &&
        firstLoginRewardInfo?.open_platform?.includes('ios_app'))
    )
  }
  const showRank =
    !HIDE_HOME_RANK || (HIDE_HOME_RANK && locale.currencies.includes(CURRENCY))

  function openAddHomeScreenModal() {
    dispatch(
      openModal({
        key: GlobalModalKey.AddToHomeScreenGuide,
      }),
    )
  }
  useEffect(() => {
    if (
      !isFeatureEnabledV2('new_app_login_reward_progress') ||
      !isAppOrDesktop ||
      !userInfo?.userId ||
      !data?.firstLoginRewardInfo?.biz_id ||
      hasClaimedRef.current
    ) return;
    const canReceive =
      data?.firstLoginRewardInfo?.biz_id && 
      conformDevice() &&
      ((data?.firstLoginRewardInfo?.is_require_recharge &&
        data?.firstLoginRewardInfo?.user_is_recharge) ||
        !data?.firstLoginRewardInfo?.is_require_recharge);
  
    if (canReceive) {
      hasClaimedRef.current = true;
      handleClaimFirstLoginReward(data?.firstLoginRewardInfo?.biz_id);
    }
  }, [data?.firstLoginRewardInfo?.biz_id, 
    data?.firstLoginRewardInfo?.is_require_recharge, 
    data?.firstLoginRewardInfo?.user_is_recharge, 
   isAppOrDesktop, userInfo?.userId]);
  useEffect(() => {
    if (
      isFeatureEnabledV2('enable_goto_mobile') &&
      isShareLink &&
      deviceType === 'ios' &&
      isMobile
    ) {
      openAddHomeScreenModal()
    }
  }, [isShareLink, deviceType])
  return (
    <VStack alignItems='stretch' spacing='5' mt={{ base: '2.5', md: '5' }}>
      <FeatureToggle feature='gameSearch'>
        <SearchTigger colorScheme='gray' />
      </FeatureToggle>

      <ErrorBoundary>
        <CollectionGamesProvider>
          <HeroSection />

          {isFeatureEnabledV2('game_records_collections') && (
            <EnhancedGameRecordsAndCollection />
          )}

          <BottomH24HoursRank />

          {isFeatureEnabled('gameApiReplaceJson') ? (
            <HomeApiGames />
          ) : (
            <HomeGames />
          )}
        </CollectionGamesProvider>
      </ErrorBoundary>

      {isFeatureEnabled('advantagePage') ? (
        <VStack>
          <Advantage />
        </VStack>
      ) : null}

      <MobileV24HoursRank />

      {showRank && <Ranking />}
      {features.addToHomeScreenBtn ? <FloatCenterButton /> : null}
      <FloatButtons />
    </VStack>
  )
}

const Home = memo(_Home)

export default Home
