import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from '@/modules/app/store'
import { closeModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'

import * as Images from '../image-imports'
import type { TaskItemProps } from '../types'
import TaskItem from './TaskItem'

function TaskItemBet(props: TaskItemProps<'bet'>) {
  const { data } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onClick = () => {
    dispatch(closeModal({ key: GlobalModalKey.DrawActivityModal }))
    navigate('/game/all')
  }

  if (!data) return null
  return (
    <TaskItem
      icon={Images.taskItemGameIcon}
      title={t('GAME_BET')}
      drawTimes={data.drawTimes}
      progressProps={{
        type: 'bar',
        current: data.progress.current,
        target: data.progress.target,
      }}
      buttonProps={{ onClick, text: t('GAME_PLAY') }}
    />
  )
}

export default TaskItemBet
