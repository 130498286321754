import { Icon, IconButton, useToast } from '@chakra-ui/react'
import { useGoogleLogin } from '@react-oauth/google'
import { forwardRef, useEffect, useImperativeHandle } from 'react'
import { FcGoogle } from 'react-icons/fc'

import Platform from '@/common/platform'
import { isFromApp } from '@/common/platform/utils'
import {
  hideGlobalLoading,
  showGlobalLoading,
} from '@/components/global-loading/global-loading-slice'
import { isQueryError } from '@/services/types'
import { getFeatures } from '@/utils/tools'

import { useAppDispatch } from '../app/store'
import { openModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { AccountType, useNewLoginMutation } from '../new-login/loginApi'
import { useLoginByGoogleAuthCodeMutation } from '../user/userApi'
import { setUserInfoLoaded } from '../user/userSlice'
import { useReferralCode } from './useReferralCode'

const loginAndSignUpV3 = getFeatures('loginAndSignUpV3')

interface GoogleLoginButtonProps {
  onSuccess?: (isRegister?: boolean) => void
}

interface Native3rdPartyLoginResult<T = unknown> {
  channel: string
  isSigned: boolean
  signResult: T | null
}

export enum Native3rdPartyLoginChannel {
  Google = 'GG',
  Facebook = 'FB',
}

// eslint-disable-next-line react-refresh/only-export-components
export const parseNative3rdSignResult = (result: string) => {
  try {
    return JSON.parse(result) as Native3rdPartyLoginResult<{
      serverAuthCode: string
    }>
  } catch (error) {
    return null
  }
}

const GoogleLoginButton = forwardRef((props: GoogleLoginButtonProps, ref) => {
  const dispatch = useAppDispatch()
  const [referralCode, removeReferralCode, getFormItemReferralCode] =
    useReferralCode()
  const [loginByGoogleAuthCode, { error }] = useLoginByGoogleAuthCodeMutation()
  const [newLoginByGoogleAuthCode, { error: newLoginError }] =
    useNewLoginMutation()
  const toast = useToast()

  useImperativeHandle(ref, () => ({
    onClick,
  }))

  const googleLoginByWeb = useGoogleLogin({
    flow: 'auth-code',
    async onSuccess(codeResponse) {
      try {
        const { onSuccess } = props
        if (loginAndSignUpV3) {
          dispatch(setUserInfoLoaded(false))
          const loginRes = await newLoginByGoogleAuthCode({
            account_type: AccountType.AT_GOOGLE,
            account_value: codeResponse.code,
            invite_code: referralCode || getFormItemReferralCode() || '',
          }).unwrap()
          removeReferralCode()
          onSuccess && onSuccess(loginRes.data.is_register)
        } else {
          await loginByGoogleAuthCode({
            authorization_code: codeResponse.code,
            redirect_uri: window.location.origin || '',
          }).unwrap()
          onSuccess && onSuccess()
        }
      } catch (error) {
        dispatch(setUserInfoLoaded(true))
      } finally {
        dispatch(hideGlobalLoading())
      }
    },
    onError() {
      dispatch(hideGlobalLoading())
    },
    onNonOAuthError() {
      dispatch(hideGlobalLoading())
    },
  })

  const onClick = async () => {
    dispatch(showGlobalLoading())
    if (isFromApp() && Platform) {
      try {
        const authRes = await Platform.googleSingIn()
        if (!authRes) throw new Error('Google login failed')
        const { authCode } = authRes
        const { onSuccess } = props
        if (loginAndSignUpV3) {
          dispatch(setUserInfoLoaded(false))
          const loginRes = await newLoginByGoogleAuthCode({
            account_type: AccountType.AT_GOOGLE,
            account_value: authCode,
            invite_code: referralCode || getFormItemReferralCode() || '',
          }).unwrap()
          removeReferralCode()
          onSuccess && onSuccess(loginRes.data.is_register)
        } else {
          await loginByGoogleAuthCode({
            authorization_code: authCode,
          }).unwrap()
          onSuccess && onSuccess()
        }
      } catch (error) {
        dispatch(setUserInfoLoaded(true))
      } finally {
        dispatch(hideGlobalLoading())
      }
    } else {
      googleLoginByWeb()
    }
  }

  useEffect(() => {
    if (error) {
      dispatch(hideGlobalLoading())
    }

    if (newLoginError) {
      if (isQueryError(newLoginError) && newLoginError?.status === 100009) {
        dispatch(
          openModal({
            key: GlobalModalKey.ActTips,
            data: {
              title: 'EXCHANGE_TITLE',
              content: 'LOGIN_FAIL_OTHER',
              icon: '',
              okButtonText: 'OK_BUTTON',
              // btnRender: ActTipsbtnRenderType | null,
              needCustomer: true,
              style: 'success_status',
            },
          }),
        )

        dispatch(hideGlobalLoading())
        return
      }

      dispatch(hideGlobalLoading())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, newLoginError])

  return (
    <IconButton
      icon={<Icon as={FcGoogle} boxSize='8' />}
      aria-label='google login'
      variant='unstyledIcon'
      onClick={onClick}
    />
  )
})

GoogleLoginButton.displayName = 'GoogleLoginButton'

export default GoogleLoginButton
