import { useEffect, useRef, useState } from 'react'

import { isFeatureEnabled } from '@/common/feature'
import { useAppSelector } from '@/modules/app/store'

import { selectMoneyRainStatus } from '../store/monyRainSlice'

const allMoneyRainImages = import.meta.glob(
  '@/assets/images/money-rain/*.{png,webp}',
)

function useMoneyRainImageLoad() {
  const [loadedImages, setLoadedImages] = useState(false)

  const isFirstLoad = useRef(true)

  const moneyRainStatus = useAppSelector(selectMoneyRainStatus)
  useEffect(() => {
    if (!isFeatureEnabled('moneyRain')) return
    if (moneyRainStatus === 'allActEnd') return
    if (!isFirstLoad.current) return
    const imageObjects = {} as Record<string, any>
    let loadedCount = 0

    Object.entries({
      ...allMoneyRainImages,
    }).forEach(([path, importFunction]) => {
      // 使用动态导入加载图片
      importFunction().then((module: any) => {
        const img = new Image()
        img.src = module.default
        img.onload = () => {
          loadedCount++
          imageObjects[path] = module.default
          if (loadedCount === Object.keys(allMoneyRainImages).length) {
            // 当所有图片加载完成时，更新状态
            setLoadedImages(true)
            isFirstLoad.current = false
          }
        }
        img.onerror = () => {
          setLoadedImages(true)
        }
      })
    })
  }, [moneyRainStatus])

  return {
    loadedImages,
  }
}

export default useMoneyRainImageLoad
