import type { Analytics } from 'firebase/analytics'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'

import type { EventConfigType, TrackFn } from '../const'
import { EventName } from '../const'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

export let analytics: Analytics | undefined

const platformList = ['M1', 'B3']

const firebaseConfigMap = {
  B3: {
    apiKey: 'AIzaSyDhRiff-eF7ei7ib7K2UiSGH8uSjKgddhY',
    authDomain: 'b3-2023122001.firebaseapp.com',
    projectId: 'b3-2023122001',
    storageBucket: 'b3-2023122001.appspot.com',
    messagingSenderId: '219839075490',
    appId: '1:219839075490:web:74ce4d0364a0a276ed33fb',
    measurementId: 'G-ZXNWHYBFF6',
  },
  M1: {
    apiKey: 'AIzaSyDRPCScdpHMMwjlJIFK1gTwfL4BpI81QDM',
    authDomain: 'm1-2023121301.firebaseapp.com',
    projectId: 'm1-2023121301',
    storageBucket: 'm1-2023121301.appspot.com',
    messagingSenderId: '895656741119',
    appId: '1:895656741119:web:98aac76437e3dd870c64ca',
    measurementId: 'G-CEBW42FLFP',
  },
} as Record<string, Record<string, string>>

const EVENT_CONFIGS: EventConfigType = {
  [EventName.ContentView]: ['page_view'],
  [EventName.Login]: ['login'],
  [EventName.PhoneLogin]: ['login'],
  [EventName.EmailLogin]: ['login'],
  [EventName.CompleteRegistration]: ['sign_up'],
  // [EventName.EmailRegister]: ['sign_up'],
  // [EventName.PhoneRegister]: ['sign_up'],
  [EventName.CompletePurchase]: ['purchase'],
  [EventName.SecondRecharge]: ['purchase'],
  [EventName.MoreRecharge]: ['purchase'],
  [EventName.ShowRechargePanel]: ['add_cash_view'],
  [EventName.ClickWallet]: ['add_cash'],
  [EventName.ClickRecharge]: ['recharge'],
  [EventName.FirstOpen]: ['firstOpen'],
  [EventName.viewPromotion]: ['view_promotion'],
  [EventName.addToWishList]: ['add_to_wishlist'],
}

export const initFirebase = (platform: 'M1' | 'B3') => {
  // Initialize Firebase
  if (!platform) return
  if (!platformList.includes(platform)) return
  const config = firebaseConfigMap[platform]
  const app = initializeApp(config)
  analytics = getAnalytics(app)
}

export const trackFn: TrackFn = (event, data) => {
  const events = EVENT_CONFIGS[event]
  if (!events || !analytics) {
    return
  }
  for (const event of events) {
    logEvent(analytics, event, data)
  }
}
