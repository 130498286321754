import { useCallback, useMemo } from 'react'

import { useAppDispatch } from '@/modules/app/store'
import type { ModalAction } from '@/modules/global-modal/globalModalSlice'
import { closeModal, openModal } from '@/modules/global-modal/globalModalSlice'

export function useGlobalModal() {
  const dispatch = useAppDispatch()

  const open = useCallback(
    (payload: ModalAction) => {
      dispatch(openModal(payload))
    },
    [dispatch],
  )

  const close = useCallback(
    (payload: ModalAction) => {
      dispatch(closeModal(payload))
    },
    [dispatch],
  )

  return useMemo(() => {
    return { open, close }
  }, [open, close])
}

export default useGlobalModal
