import { isProduction } from '@/common/env'
import Platform from '@/common/platform'
import { projectName } from '@/websites//current/property.json'

import { warn } from '../../logger'
import type { EventConfigType, TrackFn } from '../const'
import { EventName } from '../const'
import { initAdjustWeb } from './adjustWeb'

let PAY_EVENT_CONFIGS_FROM_PARAMS: EventConfigType = {}

const EVENT_CONFIGS: EventConfigType = {
  [EventName.CompletePurchase]: ['FIRST_RECHARGE'],
  [EventName.SecondRecharge]: ['SECOND_RECHARGE'],
  [EventName.MoreRecharge]: ['PAY_RECHARGE'],
}

const initAdjustWebFromParams = () => {
  // 详细参数说明见 文档 https://project.feishu.cn/tringame/story/detail/19049808?parentUrl=%2Ftringame%2FstoryView%2Fn5UlZMRIR%3Fscope%3Dworkspaces%26node%3D16992846&scope=workspaces&node=16992846
  const searchParams = new URLSearchParams(window.location.search)
  const atk = searchParams.get('atk')
  const params = atk?.split(',')
  const appToken = params
    ?.find(item => item.startsWith('tk'))
    ?.replace('tk', '')

  if (!appToken) return

  // 先判断一下当前是否初始化了 adjustWeb
  const Adjust = window.Adjust
  if (!Adjust || !Adjust?.getWebUUID()) {
    initAdjustWeb(appToken, isProduction() ? 'production' : 'sandbox')
  }

  const [rechargeFirst, rechargeSecond, rechargePay] = ['rf', 'rs', 'rp'].map(
    prefix =>
      params?.find(item => item.startsWith(prefix))?.replace(prefix, ''),
  )

  PAY_EVENT_CONFIGS_FROM_PARAMS = {
    [EventName.CompletePurchase]: rechargeFirst ? [rechargeFirst] : [],
    [EventName.SecondRecharge]: rechargeSecond ? [rechargeSecond] : [],
    [EventName.MoreRecharge]: rechargePay ? [rechargePay] : [],
  }
}

export const trackerFn: TrackFn = (event, data = {}) => {
  const isEnableParamsProjects = ['B3', 'M1', 'M5']
  const isEnableParams = isEnableParamsProjects.includes(projectName)
  if (isEnableParams) {
    initAdjustWebFromParams()
    const Adjust = window.Adjust
    const _events = PAY_EVENT_CONFIGS_FROM_PARAMS[event]
    if (_events) {
      const { value: amount, currency } = data
      const amountJson = {
        revenue: amount,
        currency: currency,
      }

      for (const event of _events) {
        Adjust?.trackEvent({
          eventToken: event,
          ...amountJson,
        })
      }
      return
    }
  }

  try {
    const events = EVENT_CONFIGS[event]
    if (!events) {
      throw new Error('Unknown event type')
    }

    for (const event of events) {
      const { value: amount, currency } = data
      Platform?.firebaseEvent({
        key: event,
        value: amount,
        currency: currency,
      })
    }
  } catch (error) {
    warn(`[Tracker]: send ADJUST event failed. event: ${event} error: ${error}`)
  }
}
