import { getCurrencyCode } from '@/common/locale/currency'
import { getSignByCode } from '@/common/locale/currency-const'

import type {
  RechargeActListProps,
  rewardFixedCfg,
  rewardGradCfg,
} from './ActInterface'
import type { RechargeTableProps } from './type'

export const handleRechargeActData = (data: RechargeActListProps[]) => {
  if (!data) return []
  const list = [] as RechargeActListProps[]
  for (let i = 0; i < data.length; i++) {
    const obj = data[i]
    const item = Object.assign({}, obj) as RechargeActListProps
    try {
      const meta = obj.meta as unknown as string
      item.meta = JSON.parse(meta || '{}')
    } catch (e) {
      item.meta = {}
    }
    list.push(item)
  }
  return (list || [])?.sort((a, b) => b.sort - a.sort) || []
}

const handleBonus = (context: {
  reward_type: 0 | 1 | 2 | 3
  reward_value: number | string
  max_reward_amount: number | string
}) => {
  const { reward_type, reward_value, max_reward_amount } = context
  if ([1, 3].includes(reward_type)) {
    // 比例奖励
    return Number(reward_value) * 100 + '%'
  } else {
    // 数额奖励
    return Math.min(Number(reward_value))
  }
}

// 处固定奖金
const handleFixedReward = (
  reward_fixed: rewardFixedCfg,
  max_reward_amount: number,
  reward_type: 0 | 1 | 2 | 3,
) => {
  return [
    {
      min_recharge: reward_fixed.min_recharge_amount,
      max_recharge: '',
      bonus: handleBonus({
        reward_type,
        max_reward_amount,
        reward_value: reward_fixed.reward_value,
      }),
      recharge_key: 'REQUIREMENTS_NH',
      bonus_key: 'BONUS_ST',
    },
  ]
}

// 处理梯度奖金
const handleGradReward = (
  reward_grad: rewardGradCfg[],
  max_reward_amount: number,
  reward_type: 0 | 1 | 2 | 3,
  max_recharge_amount?: number,
) => {
  const result = []

  for (let i = 0; i < reward_grad.length; i++) {
    const next = reward_grad[i + 1]
    const cur = reward_grad[i]
    result.push({
      min_recharge: cur.min_recharge_amount,
      max_recharge: next?.min_recharge_amount || max_recharge_amount || '',
      bonus: handleBonus({
        reward_type,
        max_reward_amount,
        reward_value: cur.reward_value,
      }),
      recharge_key:
        !next?.min_recharge_amount && max_recharge_amount
          ? 'REQUIREMENTS_TH'
          : next?.min_recharge_amount || max_recharge_amount
          ? 'REQUIREMENTS_ST'
          : 'REQUIREMENTS_NH',
      bonus_key: 'BONUS_ST',
    })
  }
  return result
}

export const getActTable = (context: any) => {
  const CURRENCY = getCurrencyCode()
  const sign = getSignByCode(CURRENCY)
  const { curAct } = context
  const { reward_config } = curAct || {}
  const { recharge_config } = curAct || {}
  const rechargeCfgContent = recharge_config?.[CURRENCY] || {}
  const cfgContent = reward_config?.[CURRENCY] || {}
  const { t } = context
  const result = [] as RechargeTableProps[]
  let _config_arr = []

  if ([0, 1].includes(cfgContent.reward_type)) {
    // 固定奖金
    _config_arr = handleFixedReward(
      cfgContent.reward_fixed,
      cfgContent.max_reward_amount,
      cfgContent.reward_type,
    )
  } else {
    // 梯度奖金
    _config_arr = handleGradReward(
      cfgContent.reward_grad || [],
      cfgContent.max_reward_amount,
      cfgContent.reward_type,
      rechargeCfgContent?.max_recharge_amount,
    )
  }
  _config_arr.forEach(item => {
    const isAmount = [0, 2].includes(cfgContent.reward_type)
    result.push({
      requirements: t(item.recharge_key, {
        interval_stlow: item.min_recharge,
        interval_sthigh: item.max_recharge,
      }),
      bonus: isAmount
        ? sign + ' ' + item.bonus
        : t(item.bonus_key, {
            first_re_1b: item.bonus,
          }),
    })
  })
  return result
}
