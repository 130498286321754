import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  PinInput,
  PinInputField,
  SimpleGrid,
} from '@chakra-ui/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getVariable } from '@/common/env'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import BindPhone from '@/websites/current/styles/components/BindPhone.json'

import { CustomizedFormErrorMessage } from '../login/LoginForm'
import CountDown from '../wallet/countDonw'
import { verifyPhone } from '../wallet/verifyFunction'
import { shakeAnimation } from './animate'
import {
  getCode,
  getCountDownTime,
  getOpen,
  getPhone,
  getShowCountDown,
  setCode,
  setCountDownTime,
  setPhone,
  setShowCountDown,
} from './bindPhoneSlice'
import { VERIFY_CODE_LENGTH, VERIFY_PHONE_COMMON } from './constants'
import { useBindPhone } from './useBindPhone'

const PHONE_AREA_CODE = getVariable('PHONE_AREA_CODE') || '+55'

export default function PhoneVerify() {
  const { t } = useTranslation()
  const {
    lang,
    showPhoneShake,
    codeBtnClick,
    codeSuccess,
    bindPhoneClick,
    showCodeShake,
    isSuccess,
    isLoading,
    _verifyPhone,
  } = useBindPhone()
  // const [showCountDown, setShowCountDown] = useState(codeSuccess)
  const showCountDown = useAppSelector(getShowCountDown)
  const startTime = useAppSelector(getCountDownTime)
  const phone = useAppSelector(getPhone)
  const phoneCode = useAppSelector(getCode)
  const dispatch = useAppDispatch()
  const [isPhoneError, setIsPhoneError] = useState<{
    verify: boolean
    text?: string
  }>({
    verify: false,
    text: '',
  })

  const handleInputChange = e => {
    const value = e.target.value
    handleChecValue(value)
  }
  const valueChange = useCallback((value: string) => {
    return verifyPhone({ params: {}, value })
  }, [])
  const handleChecValue = useCallback(
    (value: string) => {
      dispatch(setPhone(value))
      setIsPhoneError(valueChange(value))
    },
    [dispatch, valueChange],
  )

  const onFinish = () => {
    sessionStorage.removeItem('code_time')
    // sessionStorage.removeItem('last_phone')
    dispatch(setShowCountDown(false))
  }
  useEffect(() => {
    const codeTime = Number(sessionStorage.getItem('code_time'))
    if (codeTime > 0) {
      dispatch(setCountDownTime(codeTime))
      dispatch(setShowCountDown(true))
      return
    }
  }, [codeSuccess, dispatch])

  const handleCodeChange = (value: string) => dispatch(setCode(value))
  useEffect(() => {
    if (showPhoneShake && !isPhoneError?.text) {
      setIsPhoneError({
        verify: false,
        text: _verifyPhone?.text,
      })
    }
  }, [showPhoneShake, _verifyPhone, isPhoneError?.text])
  return (
    <>
      {/* 未成功绑定 */}
      {!isSuccess && (
        <>
          <FormControl marginBottom='20px'>
            <FormLabel color='gray.900' htmlFor=''>
              {t(lang.verify_label)}
            </FormLabel>
            <HStack alignItems='flex-start' marginBottom='10px'>
              <InputGroup>
                <InputLeftElement
                  pointerEvents='none'
                  color='text.accent'
                  fontSize='14px'
                  fontWeight='600'
                  top='0px'
                  lineHeight='46px'
                  height='46px'
                  left='4px'
                >
                  {PHONE_AREA_CODE}
                </InputLeftElement>
                <Box>
                  <Input
                    value={phone}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    autoComplete='off'
                    paddingLeft='46px'
                    paddingRight='46px'
                    placeholder={t(lang.verify_placeholder)}
                    animation={showPhoneShake ? shakeAnimation : ''}
                    isDisabled={lang.disabled || showCountDown}
                  />
                </Box>
              </InputGroup>
              {/*  获取验证码 */}
              <Box
                {...BindPhone.codeNormal}
                onClick={() => {
                  if (showCountDown) return
                  codeBtnClick()
                }}
                background={
                  showCountDown
                    ? BindPhone.codeDisabledBg
                    : BindPhone.codeNormalBg
                }
              >
                {!showCountDown && t(VERIFY_PHONE_COMMON.verify_btn_text)}
                {showCountDown && (
                  <CountDown
                    start_time={startTime}
                    on_finish={onFinish}
                    format='ss'
                  />
                )}
              </Box>
            </HStack>

            {!isPhoneError.verify && (
              <CustomizedFormErrorMessage isInvalid={!isPhoneError.verify}>
                {t(isPhoneError?.text)}
              </CustomizedFormErrorMessage>
            )}
          </FormControl>

          <FormControl mb='4'>
            <FormLabel color='gray.900'>
              {t(VERIFY_PHONE_COMMON.verify_code_label)}
            </FormLabel>
            <SimpleGrid
              columns={VERIFY_CODE_LENGTH}
              animation={showCodeShake ? shakeAnimation : ''}
            >
              <PinInput
                placeholder=''
                onChange={handleCodeChange}
                value={phoneCode}
              >
                {Array.from({ length: VERIFY_CODE_LENGTH }).map((_, index) => {
                  return (
                    <PinInputField
                      key={`verication_code_${index}`}
                      width='46px'
                      height='46px'
                    />
                  )
                })}
              </PinInput>
            </SimpleGrid>
          </FormControl>
        </>
      )}
      <Button
        colorScheme={'fill-prim'}
        size='lg'
        w='full'
        isLoading={isLoading}
        loadingText='Loading'
        onClick={bindPhoneClick}
        _hover={{
          colorScheme: 'fill-prim',
        }}
      >
        {t(lang.btn_text)}
      </Button>
    </>
  )
}
