import { useTranslation } from 'react-i18next'

import { useCheckInMutation } from '../api/drawActivityApi'
import * as Images from '../image-imports'
import type { TaskItemProps } from '../types'
import TaskItem from './TaskItem'

function TaskItemDaily(props: TaskItemProps<'checkIn'> & { taskId?: number }) {
  const { taskId, data } = props
  const { t } = useTranslation()
  const [checkIn, { isLoading }] = useCheckInMutation()
  const onClick = () => taskId && checkIn({ task_id: taskId })

  if (!data || !taskId) return null
  return (
    <TaskItem
      icon={Images.taskItemDailyIcon}
      title={t('COMMON_CHECK_IN')}
      drawTimes={data.drawTimes}
      progressProps={{
        type: 'text',
        label: t('CLAIM_ONCE_PER_DAY'),
        current: Number(data.isDone),
        target: 1,
      }}
      buttonProps={{
        onClick,
        text: t('COMMON_DONE'),
        isDisabled: data.isDone || isLoading,
      }}
    />
  )
}

export default TaskItemDaily
