import type { MouseEventHandler, ReactNode } from 'react'

import type { AtomOption } from '@/components/atom-select/AtomSelect'
import type { DynamicFromType, ValidateOption } from '@/components/forms/types'

import type { RechargeActListProps } from '../activity/ActInterface'
import type { AmountRechargeProp } from './walletContansts'
import type {
  RechargeBtnRuleConfigProps,
  RechargeConfigProps,
  RechargeQuickerBtnConfigProps,
} from './walletInterface'
import type { WalletModalTab } from './walletSlice'

type CallbackFunction = (data: any) => void
//基础校验函数 返回的数据格式
type VerifyFunction = (data: any) => { verify: boolean; text?: string }

export interface ArgsType {
  data?: AtomSelectItemProps[] | string[]
  name: string
  title: string
  type: string
}
export interface ApiArgsType {
  data: string[]
  name: string
  title: string
  type: string
}
//  充值 提现接口返回数据
export interface WalletParamsArgs {
  recharge: {
    args: ArgsType[]
    pay_method: string
  }
  withdraw: {
    [key: string]: {
      args: ArgsType[]
      pay_method: string
    }
  }
}

/** 充值表单? */
export interface RechargeParamsArgs {
  recharge: {
    args: ArgsType[]
    pay_method: string
  }
}

// 常用对象
export interface CommonProps {
  [key: string]: {
    [key: string]: string
  }
}

// 活动
export interface ActInfoProps {
  join: boolean
  tid: number
  name: string
  r: number
  limit: number
  completion_condition: string
}
export const DEFAULT_ACT_INFO: ActInfoProps = {
  join: false,
  tid: -1,
  name: '',
  r: 0,
  limit: 0,
  completion_condition: '',
}
// 提现保存的用户提款信息
export interface UseSaveInfoProps {
  use: boolean
  id: string | number
  ways: string
  item: {
    [key: string]: string
  }
}
export const DEFAULT_USER_SAVE_INFO: UseSaveInfoProps = {
  use: false,
  id: '',
  ways: '',
  item: {},
}
// 提现信息
export interface UserSaveListProps {
  [key: string]: string | number
}

//快捷充值金额组间
export interface WalletAmountItemProps {
  value: number
  btn_num?: number
  actInfo?: RechargeActListProps
  index?: number
  active?: number
  isHot?: boolean
  onClick?(): void
}

// WalletButtonProps
export interface WalletButtonProps {
  title?: string
  loading?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  children?: ReactNode
  colorScheme?: string
  disabled?: boolean
}

// WalletJoinActProps
export interface WalletJoinActProps {
  amount: number
  type?: 'pic' | 'text'
  isFromRechargeAct?: boolean
}

export interface ParamsModalProps {
  open: boolean
  onClose: CallbackFunction
  children?: ReactNode
}

export interface WalletConfigProps {
  recharge_btn_rule_config: RechargeBtnRuleConfigProps
  recharge_quicker_btn_config: AmountRechargeProp[]
  all_recharge_quicker_btn_config: RechargeQuickerBtnConfigProps[]
}

export interface WalletState {
  withdrawToast: boolean
  walletDurationLoaded: boolean
  activeTab: WalletModalTab
  isParamsDialogOpen: boolean
  paramsDialogRechargeParams?: {
    rechargeAmount?: number
    taskId: string
    taskGift?: number
  }
  is_from_recharge_act: boolean
  activedQuickPayMethodId: string
  activedQuickPayChannel: string
  actInfo: ActInfoProps
  withdrawAmount: number
  rechargeAmount: number
  activedQuickRechargeItemIndex: number
  useSaveInfo: UseSaveInfoProps
  showSelectWays: boolean
  saveList: WithdrawalMethodDetail[]
  //虚拟支付
  showVirtualModal: boolean
  showVirtualTipsModal: boolean
  virtualOrderList: FormatVirtualOrder[]
  virtualItemIndex: number
  currentVirtualItem: FormatVirtualOrder
  withdrawExchangeRates: number
  withdrawWays: string
  withdrawWaysList: AtomOption[]
  withdrawFeilds: Record<string, string[]>
  withdrawParams: Record<string, Record<string, string>>
  defalutWithdrawParams: Record<string, Record<string, string>>
  rechargeParams: Record<string, string>
  recharge_amount_list: AmountRechargeProp[]
  withdrawCurrency: string
  showAmountDetail: boolean //打码量
  amountList: ArrayData[]
  userAssets: {
    [key: string]: {
      amount: number
      withdrawable_amount: number
    }
  }
  rechargeList: RechargeActivityProps[] //充值任务
  handleType: string
  joinActFlag: boolean
  //pix 二维码充值
  showPixQrCode: boolean
  pixQrcodeInfo: {
    pix: string
    name: string
    email: string
    phone: string
    qrcode: string
  }
  showAmountDetails: boolean
  questType: 'request' | 'retry'
  showFullPageLoading: boolean
  fullPageText: string
  walletConfig: WalletConfigProps
  walletDurationConfig: RechargeConfigProps

  //B3 二维码充值信息
  rechargeInfoList: B3RechargeInfoProps
  mxnRecahrgeEmail: string
  showMxnRecahrgeEmail: boolean

  // 充值方式options
  rechargePaymentOptions: RechargSelectProps[]

  /**
   * 实际发起充值金额
   */
  rechargedAmount?: number | string
  /**
   * 充值信息
   */
  rechargeInfo?: Record<string, any>
  /**
   * 充值信息表单
   */
  rechargeForm?: Record<string, any>
}
export const DEFAULT_VIRTUAL_ITEM: FormatVirtualOrder = {
  order_id: '',
  start_time: 0,
  status: '',
  user_id: '',
  provider: ``,
  account_no: ``,
  name: ``,
}
export interface FormRegProps {
  [key: string]: VerifyFunction
}

// 充值提现参数
export interface RechargeWithdrawParams {
  recharge: {
    [key: string]: string
  }
  withdraw: {
    [key: string]: {
      [key: string]: string
    }
  }
}
export interface WithdrawParams {
  [key: string]: {
    args: ArgsType[]
    pay_method: string
  }
}
export interface ApiWithdrawParams {
  args: ApiArgsType[]
  pay_method: string
}
interface ArrayData {
  [key: string]: string
}
export interface WithdrawPayRes {
  data: {
    data: ArrayData[]
    code: number
  }
  error?: {
    status: string
  }
}

export type WithdrawalMethodDetail =
  | {
      // 电子钱包
      pay_method: 'electronic_wallet'
      // 电子钱包类型
      payment_method: string
      // 电子钱包目标账户号
      account_no: string
      // 电子钱包目标账户名
      account_name: string
      // 邮箱
      email: string
      // 电话
      phone: string
      // 钱包id
      id: string
      //是否是默认方式
      is_default: number
    }
  | {
      // 银行卡
      pay_method: 'bank'
      // 钱包id
      id: string
      // 银行账号
      account: string
      // 银行代码
      code: string
      // 名字
      name: string
      // 邮箱
      email: string
      // 电话
      phone: string
      //是否是默认方式
      is_default: number
    }
  | {
      // 电子钱包
      pay_method: 'pix'
      // 钱包id
      id: string
      // CPF值
      pix: string
      // 名字
      name: string
      // 邮箱
      email: string
      // 电话
      phone: string
      //是否是默认方式
      is_default: number
    }
  | {
      // 电子钱包
      pay_method: 'mex'
      // 钱包id
      id: string
      // 账号id
      account_id: string
      // 账号类型
      account_type: string
      // 银行账户
      bank_account: string
      // 银行代码
      bank_code: string
      // 文档类型
      document_type: string
      // 邮箱
      email: string
      // 名字
      name: string
      // 电话
      phone: string
      //是否是默认方式
      is_default: number
    }
  | {
      // 电子钱包
      pay_method: 'transfiya'
      // 钱包id
      id: string
      // 账号id
      account_no: string
      // 证件类型
      id_type: string
      // 证件号码
      id_number: string
      // 邮箱
      email: string
      // 名字
      name: string
      // 电话
      phone: string
      //是否是默认方式
      is_default: number
    }
  | {
      // 电子钱包
      pay_method: 'pse'
      // 钱包id
      id: string
      // 账号id
      bank_account: string
      // 证件类型
      id_type: string
      // 证件号码
      id_number: string
      // 邮箱
      email: string
      // 名字
      name: string
      // 电话
      phone: string
      bank_code: string
      account_type: string
      //是否是默认方式
      is_default: number
    }

/**
 * 电子钱包类型
 */
export enum PaymentMethod {
  BANk = 'BANk',
  CODi = 'CODi',
  Gcash = 'GCASH',
  Grabpay = 'GRABPAY',
  Oxxo = 'OXXO',
  Paymaya = 'PAYMAYA',
  Paypal = 'PAYPAL',
  Pix = 'PIX',
  Spei = 'SPEI',
  Vangqr = 'VANGQR',
  Vietcombank = 'VIETCOMBANK',
}

export interface RechargeRes {
  data?: {
    data: {
      url: string
    }
    code?: number
  }
  error: {
    status: string
  }
}

//虚拟充值订单

export interface VirtualList {
  data: FormatVirtualOrder[]
}

export interface InformationListProps {
  title: string
  key: string
}

export interface RuleListItemProps {
  text: string
}
export interface RuleListProps {
  title: string
  rules: RuleListItemProps[]
}

export interface VirtualModalTipsProps {
  title: string
  cancel_text: string
  cancel_cb: CallbackFunction
  confirm_text: string
  confirm_cb: CallbackFunction
  content: ReactNode
  close_cb: CallbackFunction
}

// select type
export interface AtomSelectItemProps {
  value: string
  label: string
}
// recharge
export interface NetWorkProps {
  data: {
    code: number
    data: {
      pay_method: {
        cashier: string
      }
    }
  }
  error?: {
    status: string
  }
}

export interface RechargeNetWorkProps {
  data: {
    code: number
    data: {
      order_id: string
      pay_method: {
        cashier: string
        virtual_account: ApiVirtualAccount
      }
    }
  }
  error: {
    status: string
  }
}
// 接口返回的虚拟订单格式
export interface ApiVirtualAccount {
  provider: string
  account_no: string
  name: string
}
// 前端格式化后需要的虚拟订单格式
export interface FormatVirtualOrder extends ApiVirtualAccount {
  order_id: string
  start_time: number
  status: string
  user_id: string
  typ?: string
  amount?: string
}
//格式化虚拟订单的格式
export interface FormatVirtualOrderParams extends ApiVirtualAccount {
  order_id: string
}
export interface WithdrawNetWorkProps {
  data: {
    code: number
  }
  error: {
    status: string
  }
}

export interface WithdrawKeyProps {
  [key: string]: {
    [key: string]: string
  }
}

export interface WithdrawSelectListProps {
  data: {
    code: number
    data: {
      [key: string]: string
    }[]
  }
}

// 倒计时
export interface CountDownProps {
  start_time: number
  on_finish: CallbackFunction
}

export interface RechargeOrderStatusProps {
  data: {
    code: number
    data: {
      order_id: string
      currency: string
      amount: number
      state: string
      create_at: number
    }
  }
}

export interface TimerProps {
  [key: string]: string
}

export interface ApiCurrencyProps {
  data: {
    code: number
    data: {
      From: string
      mid: number
      to: string
    }
  }
}

interface RechargeWithdrawCommon {
  amount: number | string
  currency: string
  data: {
    [key: string]: string
  }
}
//发起充值参数
export interface RechargeApiParamsProps extends RechargeWithdrawCommon {
  task_id: string
}
export type WithdrawApiParamsProps = RechargeWithdrawCommon

export interface CurrencyExchangeRates {
  From: string
  mid: number
  to: string
}

export type WithdrawArgs =
  | {
      type: 'input'
      name: string
      title: string
      data: null
    }
  | {
      type: 'select'
      name: string
      title: string
      data: (string | Record<string, any>)[]
    }

export interface WithdrawArgsData {
  pay_method: string
  args: WithdrawArgs[]
}

export interface RechargeFormItemOption {
  label: string
  title: string // i18n key
  value: string
  is_default?: boolean
  use_group?: string
}

export interface RechargeFormItem {
  type: DynamicFromType
  key: string
  value?: string
  form_id?: string
  label?: string
  options?: RechargeFormItemOption[]
  validate?: ValidateOption
}

export interface VirtualAccount {
  account_no: string
  name: string
  provider: string
}

export interface PayMethod {
  cashier: string
  virtual_account: VirtualAccount
  code_img?: string
}

export interface RechargeResponseData {
  order_id: string
  pay_method: PayMethod
  pay_order_id: string
}

/** 支付方式实体? */
export type RechargSelectProps = AtomOption

// 提款信息 手续费 币种 可提款金额等
export interface WithdrawInfoProps {
  text: string
  label?: string
  value: number | string
  isPrimary?: boolean
  [key: string]: any
}
//

export interface WalletAmountItem {
  amount: string
  bet_progress: string
  bet_target: string
  update_at: number
  id: number
  state: 'pending' | 'success' | 'failed'
}
//充值活动
export enum STATE_TYPE {
  ON = 1,
  OFF = 2,
}

export enum RECHARGE_TYPE {
  ON = 1,
  OFF = 2,
}

export enum CODING_TYPE {
  GOLD = 1,
  PRINCIPAL = 2,
}

/**
 * 奖励类型
 */
export enum PROPS_TYPE {
  FIXED = 'is_fixed_amount',
  COEFFICIENT = 'is_coefficient_amount',
}

export enum RECHARGE_ACTIVITY_SCOPE_TYPE {
  ALL,
  PC,
  APP,
}

/**
 * 充值任务实体
 */
export interface RechargeActivityProps {
  id: number

  biz_id: string

  begin_at: number

  coding: CODING_TYPE

  cycle_interval_at: number

  cycle_number: number

  desc: string

  detail: string

  end_at: number

  image_url: string

  is_first_charge: RECHARGE_TYPE

  join_number: number

  name: string

  pre_condition: RechargeActivityPreConditionProps

  reward: RechargeActivityRewardProps

  sort: number

  // 支持设备
  scope?: RECHARGE_ACTIVITY_SCOPE_TYPE
}

export const DEFALUT_RECHARGE_ACT: RechargeActivityProps = {
  id: -1,
  biz_id: '',
  begin_at: 0,
  coding: 1,
  cycle_interval_at: 1,
  cycle_number: 0,
  desc: '',
  detail: '',
  end_at: 0,
  image_url: '',
  is_first_charge: 1,
  join_number: 1,
  name: '',
  pre_condition: {
    min_charge: '200',
  },
  reward: {
    coefficient_amount: '',
    coefficient_amount_limit: '',
    fixed_amount: '',
    is_coefficient_amount: true,
    is_fixed_amount: true,
  },
  sort: 1,
}
/**
 * 打码量实体
 */
export interface RechargeActivityCodingProps {
  /**
   * 打码量类型：1，打码量：彩金，2，打码量：彩金+本金
   */
  code_type: CODING_TYPE
  /**
   * 打码量的值
   */
  code_value: string
}

/**
 * 前置条件实体
 */
export interface RechargeActivityPreConditionProps {
  /**
   * 最低充值金额，小数点后2位
   */
  min_charge: string
}

export interface RechargeActivityRewardProps {
  /**
   * 系数金额奖励，百分比
   */
  coefficient_amount: string | number
  /**
   * 系数金额奖励上限
   */
  coefficient_amount_limit: string
  /**
   * 固定金额奖励
   */
  fixed_amount: string
  /**
   * 是否选中系数金额奖励
   */
  is_coefficient_amount: boolean
  /**
   * 是否选中固定金额奖励
   */
  is_fixed_amount: boolean
}

export interface RechargeBonusPorps extends RechargeActivityRewardProps {
  amount: number
}

export enum RechargeErrorFromType {
  Normal = 'normal',
  RecharageAct = 'recharageAct',
}
export enum RechargeQuestType {
  retry = 'retry',
  request = 'request',
}

export interface B3RechargeInfoProps {
  [key: string]: string
}

export interface BankruptRechargeCfg {
  /**
   * 任务状态 1. 开启 2. 关闭
   */
  button_state?: number
  /**
   * 创建时间戳
   */
  created_at?: number
  /**
   * 固定金额
   */
  fixed_amount?: number
  /**
   * id
   */
  id: number
  /**
   * 图片地址
   */
  image_url: string
  /**
   * 充值按钮
   */
  recharge_buttons?: number[]
  /**
   * 充值活动Id
   */
  recharge_task_id: number
  /**
   * 权重
   */
  sort: number
  /**
   * 任务状态 1. 开启 2. 关闭
   */
  state?: number
  /**
   * 最近修改人
   */
  update_user_name?: string
  /**
   * 更新时间戳
   */
  updated_at?: number
}
