import { HStack, Input, Text } from '@chakra-ui/react'
import { add, toNumber } from 'lodash-es'
import { type ChangeEventHandler, type FocusEventHandler, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { isFeatureEnabled } from '@/common/feature'
import { getCurrencyCode } from '@/common/locale/currency'
import { useWalletConfig } from '@/utils/hooks'

import type { RechargeActListProps, RewardCfg } from '../activity/ActInterface'
import { selectRechargeActList } from '../activity/ActSlice'
import { useAppDispatch, useAppSelector } from '../app/store'
import { selectUserCurrencySign } from '../user/userSlice'
import { getRewardAmount } from '../wallet/useRechargeActivity'
import { getRechargeList } from '../wallet/walletSlice'
import {
  getRechargeAmount,
  getRechargeTyp,
  selectTaskId,
  setRechargeAmount,
} from './rechargeActSlice'

const rechargeActivityV2 = isFeatureEnabled('rechargeActivityV2')

/** 获取最小充值金额 */
function getMinRechargeAmount(actInfo: RechargeActListProps) {
  const currency = getCurrencyCode()

  const { reward_config } = (actInfo || {}) as RechargeActListProps
  const { reward_type, reward_grad, reward_fixed } = (reward_config?.[
    currency
  ] || {}) as RewardCfg
  if ([0, 1].includes(reward_type)) {
    // 固定奖金
    return reward_fixed?.min_recharge_amount || 0
  } else {
    // 梯度奖金
    let min = reward_grad[0]?.min_recharge_amount
    reward_grad.forEach(item => {
      if (item.min_recharge_amount < min) {
        min = item.min_recharge_amount
      }
    })
    return min || 0
  }
}

export default function RechargeInput() {
  const dispatch = useAppDispatch()
  const CURRENCY = useAppSelector(selectUserCurrencySign) ?? getCurrencyCode()
  const taskId = useAppSelector(selectTaskId)
  const rechargeList = useAppSelector(getRechargeList)
  const newRechargeList = useAppSelector(selectRechargeActList)
  const rechargeAmount = useAppSelector(getRechargeAmount)
  const typ = useAppSelector(getRechargeTyp)

  const { MAX_RECHARGE_AMOUNT, MIN_RECHARGE_AMOUNT } = useWalletConfig()

  const amountConfig = useMemo(() => {
    return {
      min: MIN_RECHARGE_AMOUNT[typ] || MIN_RECHARGE_AMOUNT['DEFAULT'],
      max: MAX_RECHARGE_AMOUNT[typ] || MAX_RECHARGE_AMOUNT['DEFAULT'],
    }
  }, [typ, MIN_RECHARGE_AMOUNT, MAX_RECHARGE_AMOUNT])

  const task = useMemo(() => {
    if (rechargeActivityV2) {
      return newRechargeList.find(item => item.id === toNumber(taskId))
    } else {
      return rechargeList.find(item => item.id === toNumber(taskId))
    }
  }, [taskId, rechargeList, newRechargeList])

  const minRechargeAmount = useMemo(() => {
    if (rechargeActivityV2) {
      return task
        ? getMinRechargeAmount(task as RechargeActListProps)
        : amountConfig.min
    } else {
      return task
        ? toNumber(task?.pre_condition?.min_charge || 0)
        : amountConfig.min
    }
  }, [task, amountConfig])

  const maxRechargeAmount = toNumber(amountConfig.max)

  const bonus = useMemo(() => {
    // 充值金额 <  活动配置的最小金额， 奖金为0
    if (task && toNumber(rechargeAmount) >= minRechargeAmount) {
      return getRewardAmount(
        { ...(task.reward || {}), amount: toNumber(rechargeAmount) },
        task,
      )
    } else {
      return 0
    }
  }, [rechargeAmount, task, minRechargeAmount])

  const onChange = (v: string | number) => dispatch(setRechargeAmount(v))

  return (
    <DumbRechargeAmountInput
      value={rechargeAmount}
      onChange={onChange}
      min={minRechargeAmount}
      max={maxRechargeAmount}
      bonus={bonus}
      currency={CURRENCY}
    />
  )
}

function DumbRechargeAmountInput(props: {
  value: string
  currency: string
  min: number
  max: number
  bonus: number
  onChange(v: number | string): void
}) {
  const { value, onChange, min, max, currency, bonus } = props
  const { t } = useTranslation()

  // 充值金额
  const amountChange: ChangeEventHandler<HTMLInputElement> = e => {
    let value: Parameters<typeof onChange>[0] = ''
    if (e.target.value !== '') {
      const _value = Number(e.target.value)
      value = isNaN(_value) ? min : _value
    }
    onChange(value)
  }

  const handleAmountInputBlur: FocusEventHandler<HTMLInputElement> = e => {
    let value = Number(e.target.value)
    if (isNaN(value)) {
      value = min
    }
    value = Math.min(Math.max(Number(value), min), max)
    onChange(value)
  }

  return (
    <>
      <Input
        value={value}
        readOnly
        // onChange={amountChange}
        // onBlur={handleAmountInputBlur}
        placeholder={t('BENEFITS_PLACEHOLDER', {
          min: `${currency}${min}`,
          max: `${currency}${max}`,
        }).toString()}
        bg='white'
        color='text.base'
        borderColor='white'
        _readOnly={{
          color: 'text.base',
          opacity: 0.8,
          borderColor: 'white',
        }}
      />
      <HStack justifyContent='space-between' mx='1.5'>
        <Text color='white' textStyle='text3'>
          {t('RECHARGE_AMOUNT')}
        </Text>
        <Text color='four.500' textStyle='h5'>
          {add(Math.min(toNumber(value), max), bonus)}
          {currency}
        </Text>
      </HStack>
    </>
  )
}
