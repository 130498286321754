import { Center, HStack, Text } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import LazyImage from '@/components/lazy-image/LazyImage'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  selectAllGMPClassicGames,
  selectAllGMPFacaiGames,
  selectAllGMPFundistGames,
  selectAllGMPGames,
  selectAllGMPJiliGames,
  selectAllGMPPgSoftGames,
  selectAllSelfGames,
  selectGmpInhouseGame,
  switchShowTips,
} from '@/modules/game/gameSlice'
import useShushu from '@/utils/hooks/useShushu'
import fallbackIcon from '@/websites/current/public/icon.png'
import { isFeatureEnabledV2 } from '@/common/featureSwiitch'
import { GameListItem } from '../gameInterface'

export const GameIconText: FC<
  PropsWithChildren & {
    game: string
    gameId?: string | undefined | null
    asLink?: boolean
    center?: boolean
    gameObject?: GameListItem
  }
> = ({ game, gameId, asLink, center, children, gameObject }) => {
  const gameType = !!gameId && gameId !== game ? 'gmp' : 'self'
  const selfGameList = useAppSelector(selectAllSelfGames)
  const gmpGameList = useAppSelector(selectAllGMPGames)
  const gmpInhouseGame = useAppSelector(selectGmpInhouseGame)

  const allGMPClassicGames = useAppSelector(selectAllGMPClassicGames)
  const allGMPFacaiGames = useAppSelector(selectAllGMPFacaiGames)
  const allGMPFundistGames = useAppSelector(selectAllGMPFundistGames)
  const allGMPJiliGames = useAppSelector(selectAllGMPJiliGames)
  const allGMPPgSoftGames = useAppSelector(selectAllGMPPgSoftGames)

const isImproveRankingFeatureEnable = isFeatureEnabledV2("enable_improve_ranking_module")
const isFeatureValid = gameObject && isImproveRankingFeatureEnable

  const { setShushuGameReportData } = useShushu()

  const currentGame = useMemo(() => {
    if(isFeatureValid) {
      return gameObject;
    }
    if (gameType === 'gmp') {
      // gmp游戏
      return (
        gmpGameList.find(item => item?.expand?.game_uuid === gameId) ||
        gmpInhouseGame.find(item => item?.expand?.game_uuid === gameId) ||
        allGMPClassicGames.find(item => item?.expand?.game_uuid === gameId) ||
        allGMPFacaiGames.find(item => item?.expand?.game_uuid === gameId) ||
        allGMPFundistGames.find(item => item?.expand?.game_uuid === gameId) ||
        allGMPJiliGames.find(item => item?.expand?.game_uuid === gameId) ||
        allGMPPgSoftGames.find(item => item?.expand?.game_uuid === gameId) 
      )
    } else {
      // 自营游戏
      return selfGameList.find(
        item => item.game_name?.toUpperCase() === game?.toUpperCase(),
      )
    }
  }, [gameType, gmpGameList, selfGameList])

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const onClickGame = useCallback(() => {
    if (
      currentGame?.is_under_maint === 1 ||
      currentGame?.game_status !== 1 ||
      !currentGame?.game_id
    ) {
      dispatch(switchShowTips(currentGame?.maintenance_text || ''))
    } else {
      if (gameType === 'gmp') {
        const gameId = currentGame?.game_id || ''
        setShushuGameReportData({
          from: 'home-rank',
          game_id: currentGame?.game_id || '',
          game_name: currentGame?.game_name || '',
          manufacturer: 'gmp',
        })

        const isPopularGame = gmpInhouseGame.find(item => {
          return (
            item?.expand?.game_uuid === String(currentGame?.expand?.game_uuid)
          )
        })

        if (isPopularGame?.game_id) {
          const gameName = isPopularGame?.game_name?.toLowerCase() || ''
          navigate(`/game/inhouse/${gameName}`)
        } else {
          // GMP游戏
          navigate(`/game/self/${gameId}`)
        }
      } else {
        const gameId = game?.toLocaleLowerCase() || ''
        setShushuGameReportData({
          from: 'home-rank',
          game_id: currentGame?.game_id || '',
          game_name: currentGame?.game_name || '',
          manufacturer: 'inhouse',
        })

        navigate(`/game/inhouse/${gameId}`)
      }
    }
  }, [currentGame, dispatch, game, navigate, gameType, gmpInhouseGame])

  if (center) {
    return (
      <Center
        position='sticky'
        left='0'
        as={asLink ? 'button' : undefined}
        onClick={onClickGame}
        w='40'
        mx='auto'
      >
        <LazyImage
          borderRadius='sm'
          src={currentGame?.record_icon || fallbackIcon}
          objectFit='cover'
          objectPosition='bottom'
          boxSize='8'
        />
        <Text marginLeft='5px'>{children}</Text>
      </Center>
    )
  } else {
    return (
      <HStack
        position='sticky'
        left='0'
        as={asLink ? 'button' : undefined}
        spacing='3'
        onClick={onClickGame}
        w='40'
        mx='auto'
      >
        <LazyImage
          borderRadius='sm'
          src={currentGame?.record_icon || fallbackIcon}
          objectFit='cover'
          objectPosition='bottom'
          boxSize='8'
        />
        <Text textTransform='capitalize'>{children}</Text>
      </HStack>
    )
  }
}
