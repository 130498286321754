import { EventName } from '../const'
import type { Channel, ITrackerBase } from './types'

export class TrackerGoogleAnalytics implements ITrackerBase {
  channel: Channel = { name: 'google', id: '' }

  eventMap = {
    [EventName.CompleteRegistration]: ['sign_up'], // 注册
    [EventName.CompletePurchase]: ['purchase'], // 充值
  }

  constructor(channelId: string) {
    this.channel.id = channelId
  }

  trackSingleEvent(
    event: string,
    _originEventName?: EventName,
    data?: any,
  ): void {
    if (typeof gtag === 'function') {
      gtag('event', event, { ...data, send_to: 'ads_group' })
    }
  }
}
