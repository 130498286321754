import { Tab, TabList, Tabs, Text, Tooltip } from '@chakra-ui/react'
import type { FC } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'

import { handleSliceText } from '../auto-modal/utils'

export interface dataProps {
  id: string
  title: string
}

interface GameCatagoryTabsProps {
  datasource: dataProps[]
  onChange?: (key: number) => void
}

export const GameCatagoryTabs: FC<GameCatagoryTabsProps> = ({
  datasource,
  onChange,
}) => {
  const { t } = useTranslation()
  const tabs = useMemo(() => {
    return datasource.map(({ id, title }, index) => (
      <SwiperSlide key={id + '_' + index} style={{ width: 'max-content' }}>
        <Tab
          onClick={onChange ? () => onChange(index) : undefined}
          cursor='pointer'
          style={{ marginBottom: '0px' }}
        >
          <Tooltip label={title} fontSize='sm' shouldWrapChildren>
            <Text textStyle='text3' cursor='pointer'>
              {title}
              {/* {handleSliceText(title, 8)} */}
            </Text>
          </Tooltip>
        </Tab>
      </SwiperSlide>
    ))
  }, [datasource, onChange, t])

  return (
    <>
      <Tabs
        variant='announce-tab'
        colorScheme='announce-tab'
        defaultIndex={0}
        marginBottom={'40px'}
      >
        <TabList
          as={Swiper}
          slidesPerView='auto'
          // centeredSlides={true}
          slideToClickedSlide={true}
          mx='0'
          mb={'12px'}
        >
          {tabs}
        </TabList>
      </Tabs>
    </>
  )
}

export default GameCatagoryTabs
