//游戏维护弹窗提示
import { Button, HStack, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import TitledDialog from '@/components/modal/TitledDialog'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'

import {
  getShowTips,
  getTipsText,
  selectGameList,
  switchShowTips,
} from './gameSlice'
import GameCard from './units/gamecard/AtomGameCard'
import GameCardHover from './units/gamecard/AtomGameCardHover'
import { useNavigateToGame } from './useNavigateToGame'

export function GameUpdateTips() {
  const navigateToGame = useNavigateToGame()

  const isOpen = useAppSelector(getShowTips)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const gameList = useAppSelector(selectGameList)
  const tipsText = useAppSelector(getTipsText)
  const { t } = useTranslation()

  const showGameList = useMemo(() => {
    return gameList
      .filter(item => item?.is_under_maint !== 1 && !!item?.game_cover)
      .slice(0, 6)
  }, [gameList])

  return (
    <TitledDialog
      title={t('MAINTENANCE_TIP_TITLE')}
      isOpen={isOpen}
      onClose={() => {
        dispatch(switchShowTips(''))
        navigate('/')
      }}
    >
      <Text color='gray.600' fontSize='12px' textAlign='center'>
        {tipsText || t('MAINTENANCE_TIP_CONTENT_BUILTIN')}
      </Text>
      {showGameList?.length > 0 ? (
        <>
          <Text
            fontSize='18px'
            textAlign='center'
            fontWeight='800'
            color='#3F4F70'
            margin='18px 0'
          >
            {t('MAINTENANCE_RECOMMEND_TITLE')}
          </Text>
          <HStack flexWrap='wrap' justifyContent='space-between' spacing='0'>
            {showGameList.map(item => {
              const { game_cover } = item
              return (
                <GameCard
                  key={item.game_id}
                  src={game_cover || ''}
                  style={{
                    marginTop: '10px',
                    width: '32%',
                  }}
                  onClick={() => {
                    navigate('/')
                    dispatch(switchShowTips(''))
                    navigateToGame(item)
                  }}
                >
                  <GameCardHover>Play</GameCardHover>
                </GameCard>
              )
            })}
          </HStack>
        </>
      ) : null}
      <Button
        w='100%'
        marginTop='20px'
        colorScheme='fill-prim'
        onClick={() => {
          navigate('/')
          dispatch(switchShowTips(''))
        }}
      >
        {t('MAINTENANCE_RECOMMEND_MOREBTN')}
      </Button>
    </TitledDialog>
  )
}

export default function NiceGameUpdateTips() {
  const isOpen = useAppSelector(getShowTips)

  if (!isOpen) return null

  return <GameUpdateTips />
}
