import { Box } from '@chakra-ui/react'
import { useMemo } from 'react'

import RechargePackageFloatBtnGif from '@/assets/images/recharge-package/recharge-package-float-btn.gif'
import { isFeatureEnabled } from '@/common/feature'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useUserAuth } from '@/utils/hooks'

import {
  type FloatButton,
  HOME_HOVER_BTN_TYPE,
} from '../app/home-page-config-types'
import RechargeActEntry from './RechargeActEntry'
import useRechargeActEntry from './useRechargeActEntry'
import useRechargePackageEntry, {
  RECHARGE_ACTIVITY_TYPE,
} from './useRechargePackageEntry'

const rechargeActivityV2 = isFeatureEnabled('rechargeActivityV2')
const rechargePackageV3 = isFeatureEnabled('rechargePackageV3')

type RechargePackageEntryProps = {
  floatBtnQueue?: FloatButton[]
}

export default function RechargePackageEntry({
  floatBtnQueue,
}: RechargePackageEntryProps) {
  const {
    type,
    handleModal,
    countDown,
    rechargePackage,
    hours,
    seconds,
    minutes,
  } = useRechargePackageEntry({ floatBtnQueue })
  const [user_id, token] = useUserAuth()

  const { actInfo } = useRechargeActEntry()

  const barWidth = useMemo(() => {
    return (
      countDown /
      ((rechargePackage?.end_at && rechargePackage?.begin_at
        ? rechargePackage.end_at - rechargePackage.begin_at
        : 30 * 60) *
        1000)
    )
  }, [countDown, rechargePackage])

  const rechargePackageFloatBtnGif = useMemo(() => {
    let res = RechargePackageFloatBtnGif

    if (rechargePackageV3) {
      res =
        floatBtnQueue?.find(item => {
          if (type === RECHARGE_ACTIVITY_TYPE.RECHARGE_ACTIVITY) {
            return item.type === HOME_HOVER_BTN_TYPE.RECHARGE_ACTIVITY
          }

          if (type === RECHARGE_ACTIVITY_TYPE.RECHARGE_PACKAGE) {
            return item.type === HOME_HOVER_BTN_TYPE.RECHARGE_PACKAGE
          }
        })?.imageSrc || RechargePackageFloatBtnGif
    }

    return res
  }, [floatBtnQueue, type])

  if (type === RECHARGE_ACTIVITY_TYPE.NONE) {
    if (
      rechargeActivityV2 &&
      (actInfo?.id || !token || !user_id) &&
      !rechargePackageV3
    ) {
      return <RechargeActEntry actInfo={actInfo} />
    }
    return null
  }

  return (
    <Box
      position='relative'
      mb='12px'
      cursor='pointer'
      onClick={() => {
        handleModal()
      }}
      mr='-7px'
    >
      <LazyImage
        boxSize={{
          lg: '20',
          base: '16',
        }}
        src={rechargePackageFloatBtnGif}
      />

      {type === RECHARGE_ACTIVITY_TYPE.RECHARGE_PACKAGE && (
        <Box
          position='absolute'
          bottom='-5px'
          left='50%'
          borderRadius='full'
          h={{
            lg: '16px',
            base: '12px',
          }}
          w={{
            lg: '67px',
            base: '51px',
          }}
          bg='linear-gradient(180deg, #F6E2A3 0%, #C25C24 100%)'
          transform='translateX(-50%)'
        >
          <Box
            overflow='hidden'
            position='absolute'
            top='50%'
            left='50%'
            borderRadius='full'
            w='calc(100% - 2px)'
            h='calc(100% - 2px)'
            bg='linear-gradient(180deg, #9E1090 0%, #9E1090 100%)'
            transform='translate(-50%, -50%)'
          >
            {isFeatureEnabled('rechargePackageV2') ? (
              <Box
                fontSize={{
                  lg: '10px',
                  base: '8px',
                }}
                lineHeight={{
                  lg: '16px',
                  base: '10px',
                }}
                textAlign='center'
                color='#fff'
              >{`${hours}:${minutes}:${seconds}`}</Box>
            ) : (
              <Box
                position='absolute'
                top='50%'
                left='1px'
                borderRadius='full'
                h='calc(100% - 2px)'
                w={`calc((100% - 2px) * ${barWidth})`}
                bg='linear-gradient(180deg, #FFF9C5 0%, #FFC023 51.9%, #FFAC05 100%)'
                transform='translateY(-50%)'
                transition={'width 0.3s'}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}
