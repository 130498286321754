import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import React, { Suspense, useCallback, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import Loading from '@/routes/Loading'

import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { EShowType } from './types'

const Wheel = React.lazy(() => import('./Wheel'))

export default function WheelModal() {
  const dispatch = useAppDispatch()
  const { open } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.LuckyWheel],
  )
  const [showType, setShowType] = useState<EShowType>(EShowType.Overview)

  const handleClose = useCallback(() => {
    dispatch(closeModal({ key: GlobalModalKey.LuckyWheel }))
    setShowType(EShowType.Overview)
  }, [dispatch])

  return (
    <Modal
      autoFocus={false}
      trapFocus={false}
      isOpen={open}
      onClose={handleClose}
      size={{
        base: '5xl',
        lg: '5xl',
      }}
      isCentered
      motionPreset='none'
    >
      <ModalOverlay />
      <Suspense
        fallback={
          <ModalContent
            bgColor='transparent'
            overflow='hidden'
            backgroundRepeat='no-repeat'
            backgroundPosition={['right top', 'right bottom']}
            boxShadow='none'
            width={['95vw', '950px']}
            height={['520px', '611px']}
            backgroundSize={['100% auto', '890px auto']}
          >
            <Loading />
          </ModalContent>
        }
      >
        <Wheel showType={showType} setShowType={setShowType} open={open} />
      </Suspense>
    </Modal>
  )
}
