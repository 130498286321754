import type { ComponentStyleConfig } from '@chakra-ui/react'

import { NAME, PARTS } from '@/components/atom-nav-accordion-item/constants'

const componentStyle: ComponentStyleConfig = {
  parts: PARTS as unknown as string[],
  baseStyle: {
    accordionItem: {
      '--bg-color': 'var(--chakra-colors-gray-400)',
    },
  },
}

export const name = NAME
export default componentStyle
