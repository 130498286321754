import type { SystemStyleObject } from '@chakra-ui/react'
import { Box, Text } from '@chakra-ui/react'

import { useAppSelector } from '@/modules/app/store'

import type { LevelProtectionStatus } from '../types'
import passImg from './pass-level.webp'
import unpassImg from './unpass-level.webp'

interface VipLevelIconProps {
  level: number
  type?: LevelProtectionStatus
}

export default function VipLevelNumIcon({ level }: VipLevelIconProps) {
  const { vipInfoExt } = useAppSelector(state => state.user)

  const hasSetLevelProtection = vipInfoExt?.hasSetLevelProtection

  const hasType = hasSetLevelProtection
  const boxStyle: SystemStyleObject = hasType
    ? {
        background: `url(${
          vipInfoExt?.pass_level_expire_check ? passImg : unpassImg
        }) no-repeat center / 100% auto`,
        paddingLeft: '20px',
      }
    : {}
  const textStyle: SystemStyleObject = hasType
    ? {
        width: '40px',
        color: vipInfoExt?.pass_level_expire_check ? '#fff' : 'gray.700',
      }
    : {
        width: '100%',
      }
  return (
    <Box
      w='64px'
      h='20px'
      lineHeight='20px'
      fontSize='11px'
      fontStyle='italic'
      fontWeight='800'
      backgroundSize='64px 20px'
      sx={boxStyle}
    >
      <Text textAlign='center' sx={textStyle}>
        VIP {level}
      </Text>
    </Box>
  )
}
