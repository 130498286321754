import type { AuthQueryArgs } from '@/modules/user/userApi'
import { api } from '@/services/api'
import type { Response } from '@/services/types'

import type {
  luckyWheelDrawRes,
  luckyWheelOpportunityRes,
  luckyWheelResponse,
} from './types'

export const luckyWheelApi = api.injectEndpoints({
  endpoints: builder => ({
    getLuckyWheelConfig: builder.query<
      Response<luckyWheelResponse>,
      AuthQueryArgs
    >({
      query: data => {
        return {
          url: '/api/v1/act-raffle/info',
          method: 'POST',
          data,
        }
      },
      providesTags: ['luckyWheelInfo'],
    }),
    draw: builder.mutation<Response<luckyWheelDrawRes>, AuthQueryArgs>({
      query: data => {
        return {
          url: '/api/v1/act-raffle/draw',
          method: 'POST',
          data,
        }
      },
      // invalidatesTags: ['luckyWheelInfo'],
    }),
    claimReward: builder.mutation<
      Response,
      AuthQueryArgs & {
        biz_id: string
      }
    >({
      query: data => {
        return {
          url: '/api/v1/act-raffle/claim',
          method: 'POST',
          data,
        }
      },
    }),
    getOpportunityList: builder.query<
      Response<{
        list: luckyWheelOpportunityRes[]
        total: number
      }>,
      AuthQueryArgs & {
        page: number
        pageSize: number
        page_off: boolean
      }
    >({
      query: data => {
        return {
          url: '/api/v1/act-raffle/opp_list',
          method: 'POST',
          data,
        }
      },
    }),
  }),
})

export const {
  useGetLuckyWheelConfigQuery,
  useDrawMutation,
  useClaimRewardMutation,
  useGetOpportunityListQuery,
} = luckyWheelApi
