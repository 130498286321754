import {
  Box,
  Center,
  CloseButton,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Service1 from '@/assets/images/service-list/service-1.png'
import Service2 from '@/assets/images/service-list/service-2.png'
import { getFeatureFlag } from '@/common/feature'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useModalConfig } from '@/modules/auto-modal/useModal'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useUserAuth } from '@/utils/hooks'
import type { Action } from '@/utils/hooks/useActionTrigger'
import { useActionTrigger } from '@/utils/hooks/useActionTrigger'

import { useAppDispatch, useAppSelector } from '../app/store'
import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { useServiceListQuery, useUserCustomerServiceQuery } from './api'

const serviceListTgLink = getFeatureFlag('serviceListTgLink')

const newServiceList = getFeatureFlag('newServiceList')

interface ServiceItem {
  title: string
  desc: string
  avatar: string
  action: Action
}

function ServiceModal() {
  const dispatch = useAppDispatch()
  const { onCloseModal } = useModalConfig()
  const { t } = useTranslation()
  const [userId, token] = useUserAuth()
  const {
    data: userCustomerServiceData,
    isLoading: isUserCustomerServiceQueryLoading,
  } = useUserCustomerServiceQuery(
    { user_id: userId, token },
    { skip: !userId || !token || !newServiceList },
  )
  const { data: serviceListData, isLoading: isServiceListQueryLoading } =
    useServiceListQuery(undefined, {
      skip: !newServiceList,
    })

  const userCustomerService = useMemo(
    () => userCustomerServiceData?.data,
    [userCustomerServiceData],
  )

  const serviceList = useMemo(() => {
    if (
      userCustomerService &&
      Array.isArray(userCustomerService) &&
      userCustomerService.length > 0
    ) {
      return userCustomerService
    } else {
      return serviceListData?.data?.list || []
    }
  }, [userCustomerService, serviceListData])

  const { open } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.ServiceList],
  )

  const service_list = useMemo((): ServiceItem[] => {
    if (newServiceList) {
      return serviceList
        .filter(item => Boolean(item.status))
        .map(item => {
          const result: Partial<Pick<ServiceItem, 'action'>> &
            Omit<ServiceItem, 'action'> = {
            title: item.name ?? '',
            avatar: item.image ?? '',
            desc: String(t('ONLINE_SERVICE')),
          }
          if (item.type === 'Livechat') {
            result.action = {
              eventName: 'toggle-live-chat',
              data: {
                license: item.configuration_content || undefined,
              },
            }
          } else {
            result.action = {
              eventName: 'navigation',
              href: item.configuration_content || '',
              data: {
                target: '_blank',
                navigation_type: 1,
              },
            }
          }
          return result as ServiceItem
        })
    } else {
      return [
        {
          title: t('ONLINE_CUS_LT'),
          desc: t('ONLINE_CUS_LTE'),
          avatar: Service2,
          action: {
            eventName: 'toggle-live-chat',
          },
        },
        {
          title: t('ONLINE_CUS_TT'),
          desc: t('ONLINE_CUS_TTE'),
          avatar: Service1,
          action: {
            eventName: 'navigation',
            href: serviceListTgLink || '',
            data: {
              target: '_blank',
              navigation_type: 1,
            },
          },
        },
      ]
    }
  }, [serviceList, t])

  const actionTrigger = useActionTrigger()

  /**
   * 关闭tg-modal
   */
  const handleClose = useCallback(() => {
    dispatch(closeModal({ key: GlobalModalKey.ServiceList }))
  }, [dispatch, onCloseModal])

  return (
    <Modal
      autoFocus={false}
      trapFocus={false}
      isOpen={open}
      onClose={handleClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        bg='serviceList.bg'
        color='serviceList.titleColor'
        textAlign='center'
        padding='0px 12px 20px'
        width='95%'
        position={'relative'}
        sx={{ boxShadow: 'none' }}
        minHeight={'268px'}
      >
        <CloseButton
          position='absolute'
          right='3'
          top='3'
          onClick={handleClose}
          color='gray.700'
          fontWeight={900}
        />
        <ModalHeader cursor='default' lineHeight='24px'>
          {t('ONLINE_CUS_ST')}
        </ModalHeader>
        {isServiceListQueryLoading || isUserCustomerServiceQueryLoading ? (
          <Center py='6'>
            <Spinner />
          </Center>
        ) : (
          service_list?.slice(0, 5).map((item, index) => {
            return (
              <Box
                mt='10px'
                borderRadius={'10px'}
                bg='serviceList.itemBg'
                minHeight={'80px'}
                padding={'16px'}
                key={index}
                display={'flex'}
                alignItems={'center'}
                cursor={'pointer'}
                onClick={actionTrigger(item?.action || {})}
              >
                <LazyImage
                  src={item.avatar}
                  width={'48px'}
                  height={'48px'}
                  mr='16px'
                />
                <Box as='button' flex={1} textAlign={'left'} fontWeight={800}>
                  <Text
                    fontSize={'16px'}
                    color='serviceList.itemTitle'
                    cursor={'pointer'}
                  >
                    {t(item.title)}
                  </Text>
                  <Text
                    fontSize={'12px'}
                    color='serviceList.itemDesc'
                    cursor={'pointer'}
                  >
                    {t(item.desc)}
                  </Text>
                </Box>
                <IconButton
                  colorScheme='white'
                  aria-label='pagination-next'
                  icon={<Icon as={SharedIcon.ArrowRight1} />}
                  w='8'
                  h='8'
                  minW='6'
                  borderRadius='base'
                />
              </Box>
            )
          })
        )}
      </ModalContent>
    </Modal>
  )
}

const ServiceListModal: FC = () => {
  const { open } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.ServiceList],
  )
  if (open) {
    return <ServiceModal />
  } else {
    return null
  }
}

export default ServiceListModal
