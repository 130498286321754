import type { ComponentStyleConfig } from '@chakra-ui/react'

import {
  NAME,
  PARTS,
} from '@/modules/inhouse/common/components/auto-bet/AutoBet'

import theme from './inhouse'

const { switchCheckedColor, switchNormalColor } = theme

const parts = [...PARTS]
const componentStyle: ComponentStyleConfig = {
  parts,
  baseStyle: {
    box: {
      bgColor: 'gray.400',
    },
    input: {
      bgColor: 'gray.50',
    },
    switchbox: {},
    switchtext: {
      color: 'gray.600',
      _even: {
        _checked: {
          color: switchNormalColor,
        },
      },
      _odd: {
        _checked: {
          color: switchCheckedColor,
        },
      },
    },
  },
}

export const name = NAME
export default componentStyle
