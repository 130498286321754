import { getVariable } from '@/common/env'
import { useAppSelector } from '@/modules/app/store'
import { selectUserInviteUrl } from '@/modules/new-referral/referralSlice'

import { selectUserId } from '../../userSlice'
import {
  Box,
  Icon,
  IconButton,
  Text,
  useClipboard,
  useToast,
} from '@chakra-ui/react'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useTranslation } from 'react-i18next'

const RefferalCode = () => {
  const INVITE_TINYURL = getVariable('INVITE_TINYURL') || window.location.origin
  const toast = useToast()
  const { t } = useTranslation()

  const userId = useAppSelector(selectUserId)

  const shareUrl = useAppSelector(selectUserInviteUrl)

  const INVITE_URL = `${shareUrl || INVITE_TINYURL}?referralcode=${userId}`
  const { onCopy } = useClipboard(INVITE_URL)
  return (
    <Box display='flex' flexDirection='column' mb="12px">
      <Text
        color='personalCenter.inputText'
        fontSize='12px'
        fontWeight='600'
        lineHeight='16px'
        mb='4px'
      >
        {t('INVITE_SITE')}
      </Text>

      <Box
        borderRadius='8px'
        borderWidth="1px"
        borderStyle="solid"
        borderColor="personalCenter.border"
        pl='12px'
        h='36px'
        display='flex'
        alignItems='center'
        overflow='hidden'
      >
        <Text
          color='personalCenter.inputText'
          fontWeight='600'
          fontSize='14px'
          lineHeight='18px'
          overflow='hidden'
          textOverflow='ellipsis'
          flexGrow='1'
          style={{
            textWrap: 'nowrap',
          }}
        >
          {INVITE_URL}
        </Text>

        <IconButton
          borderRadius='0px'
          icon={<Icon boxSize={4} as={SharedIcon.Copy} color="personalCenter.iconColor" />}
          aria-label='copy'
          width={'36px'}
          height={'100%'}
          minW={'36px'}
          onClick={() => {
            onCopy()
            toast({
              title: t('COMMON_CONTENT_CANCEL'),
              status: 'success',
            })
          }}
        />
      </Box>
    </Box>
  )
}

export default RefferalCode
