// pix 二维码弹出框
import {
  Button,
  HStack,
  SimpleGrid,
  Text,
  useClipboard,
  useToast,
} from '@chakra-ui/react'
import { QRCodeSVG } from 'qrcode.react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import CopyInput from '@/components/input/CopyInput'
import TitledDialog from '@/components/modal/TitledDialog'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { formatLocalMoney } from '@/utils/tools'
import { projectName } from '@/websites/current/property.json'

import TitleWithRechargeWayIcon from './TitleWithRechargeWayIcon'
import { useRechargeWays } from './useRechargeWays'
import {
  closeQrModal,
  getPixQrCodeInfo,
  getShowPixQrCode,
  selectRechargeAmountCompatibleWithActivity,
  selectWallet,
} from './walletSlice'

export function PixQrcodeModal() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(getShowPixQrCode)
  const info = useAppSelector(getPixQrCodeInfo)
  const rechargeAmount = useAppSelector(
    selectRechargeAmountCompatibleWithActivity,
  )

  const { onCopy } = useClipboard(info.qrcode)
  const toast = useToast()
  const { paramasErrorHandal } = useRechargeWays()
  const rechargeParams = useAppSelector(
    state => selectWallet(state).rechargeParams,
  )
  const qrcodeBtcClick = (type: string) => {
    switch (type) {
      case 'close':
        dispatch(closeQrModal())
        paramasErrorHandal()
        break
      case 'copy':
        handleCopy()
        break
      default:
    }
  }
  const handleCopy = useCallback(() => {
    onCopy()
    toast({
      title: t('COMMON_COPIED'),
      status: 'success',
    })
  }, [toast, onCopy, t])

  const modalTitleRender = () => {
    if (
      projectName === 'B3' &&
      ['PIXQR', 'PIXQRNEW'].includes(rechargeParams?.typ?.toLocaleUpperCase())
    ) {
      return <TitleWithRechargeWayIcon />
    }
    return t('DEPOSIT_PAYMENT_QRCODE')
  }

  return (
    <TitledDialog
      title={modalTitleRender()}
      isOpen={isOpen}
      onClose={() => {
        dispatch(closeQrModal())
      }}
      size={{
        base: 'full',
        sm: 'lg',
      }}
    >
      <HStack justifyContent='center'>
        <Text>{t('DEPOSIT_RECHARGEAMOUNT')}</Text>
        <Text color='prim.500'>{formatLocalMoney(rechargeAmount)}</Text>
      </HStack>
      <HStack justifyContent='center' m='4'>
        <QRCodeSVG value={info.qrcode} size='200' />
      </HStack>
      <CopyInput text={info.qrcode} />
      <Text fontSize='sm' mt='4'>
        {t('DEPOSIT_QRCODE_TIPS')}
      </Text>
      <SimpleGrid columns={2} spacing='0.5' w='full' mt='4'>
        <Button
          colorScheme='fill-tert'
          size='lg'
          loadingText='Loading'
          onClick={() => {
            qrcodeBtcClick('close')
          }}
          fontWeight='600'
        >
          {t('DEPOSIT_EDIT_INFO_BTN')}
        </Button>
        <Button
          colorScheme='fill-prim'
          size='lg'
          loadingText='Loading'
          onClick={() => {
            qrcodeBtcClick('copy')
          }}
          fontWeight='600'
        >
          {t('DEPOSIT_COPY_CODE_BTN')}
        </Button>
      </SimpleGrid>
    </TitledDialog>
  )
}
