import {
  Box,
  Checkbox,
  CloseButton,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import { t } from 'i18next'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { getVariable } from '@/common/env'
import LazyImage from '@/components/lazy-image/LazyImage'
import type {
  ModalConfigProps,
  PopularizeContentProps,
} from '@/modules/auto-modal/ModalInterface'
import { setModalStorage, useModalConfig } from '@/modules/auto-modal/useModal'
import { useUserAuth } from '@/utils/hooks'
import { useActionTrigger } from '@/utils/hooks/useActionTrigger'
import { getConfigLng } from '@/utils/tools'

import { useAppDispatch, useAppSelector } from '../app/store'
import {
  useGetTgModalRemindQuery,
  useSetTgModalRemindMutation,
} from '../auto-modal/ModalApi'
import {
  selectModalConfig,
  selectPopularizeContent,
} from '../auto-modal/ModalSlice'
import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'

const lng = getConfigLng()

function TgModal() {
  const MODAL_RESOURCE_SIGN = getVariable('MODAL_RESOURCE_SIGN')
  const dispatch = useAppDispatch()
  const [userId, token] = useUserAuth()
  const { onCloseModal } = useModalConfig()
  const modalConfig = useAppSelector(selectModalConfig)
  const [noRemind, setNoRemind] = useState(false)
  const [foreverNoRemind, setForeverNoRemind] = useState(true)
  const [foreverNoRemind2, setForeverNoRemind2] = useState(true)
  const [setTgModalRemind] = useSetTgModalRemindMutation()
  const { data } = useGetTgModalRemindQuery(
    { user_id: userId, token },
    { skip: !MODAL_RESOURCE_SIGN },
  )

  const actionTrigger = useActionTrigger()

  const {
    open,
    data: openData,
    extra,
  } = useAppSelector(state => selectModal(state)[GlobalModalKey.TgModal])

  const popularizeContentList = useAppSelector(selectPopularizeContent)

  const curModalCfg: ModalConfigProps = useMemo(() => {
    if (extra?.isManu) {
      // 用户手动触发
      const content = []
      popularizeContentList?.forEach(item => {
        if (item?.status === 1) {
          content.push({
            language_content: item.language_content,
            action: item.action || {},
          })
        }
      })
      return {
        content,
      }
    }
    return modalConfig.find(item => item.type === 4) as ModalConfigProps
  }, [modalConfig, openData, popularizeContentList])

  const showContent = useMemo(() => {
    return (curModalCfg?.content?.[0] || {}) as PopularizeContentProps
  }, [curModalCfg])

  const onClose = () => {
    onCloseModal(GlobalModalKey.TgModal, true)
  }

  /**
   * 关闭tg-modal
   */
  const handleClose = useCallback(() => {
    dispatch(closeModal({ key: GlobalModalKey.TgModal }))
    onCloseModal(GlobalModalKey.TgModal)
    if (MODAL_RESOURCE_SIGN && foreverNoRemind) {
      setTgModalRemind({ user_id: userId, token })
    }
  }, [dispatch, onCloseModal])

  /**
   * 点击今日不再提示
   * @param checked
   */
  const onChangeCheckbox = (checked: boolean) => {
    setModalStorage(userId, curModalCfg?.id || '', checked ? 1 : 0)
    setNoRemind(checked)
    if (checked && foreverNoRemind) {
      setForeverNoRemind(false)
      // localStorage.removeItem(foreverKey)
    }
  }

  /**
   * 点击永久不再提示
   */
  const onChangeForever = (checked: boolean) => {
    // 调接口
    setForeverNoRemind(checked)
    if (checked) {
      // localStorage.setItem(foreverKey, '1')
      if (noRemind) {
        setNoRemind(false)
        setModalStorage(userId, curModalCfg?.id || '', 0)
      }
    } else {
      // localStorage.removeItem(foreverKey)
    }
  }

  useEffect(() => {
    if (extra?.isManu) {
      setForeverNoRemind2(false)
      return
    }
    if (data) {
      if (data?.data === true) {
        // 说明设置了永久不再提示
        setForeverNoRemind(true)
        setForeverNoRemind2(true)
        handleClose()
      } else {
        // 没设置永久不再提示
        setForeverNoRemind(false)
        setForeverNoRemind2(false)
      }
    }
  }, [data, extra])

  const banner_url = showContent?.language_content?.[lng]?.banner_url || ''

  const btn_img_url = showContent?.language_content?.[lng]?.btn_img_url
  return (
    <Modal
      autoFocus={false}
      trapFocus={false}
      isOpen={open && !foreverNoRemind2}
      onClose={handleClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        bg='bg.tgModalBg'
        color='text.tgModalColor'
        textAlign='center'
        padding='0'
        width='375px'
        position={'relative'}
        sx={{ boxShadow: 'none' }}
      >
        <CloseButton
          position='absolute'
          right='3'
          top='10'
          onClick={handleClose}
          color='bg.tgModalClose'
          zIndex={10}
        />
        <Box
          as='button'
          onClick={actionTrigger(showContent?.action, onClose)}
          position='relative'
        >
          <LazyImage
            src={banner_url}
            alt=''
            borderRadius='2xl'
            loading='lazy'
            sx={{
              margin: 'auto',
              width: '100%',
            }}
          />
          {btn_img_url && (
            <LazyImage
              position='absolute'
              width='100%'
              src={btn_img_url}
              bottom='32px'
            />
          )}
        </Box>
        <Flex
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
          width={'100%'}
          marginTop={'-90px'}
        >
          <Box>
            {curModalCfg?.can_hide === 1 && (
              <Box height={'50px'} display={'flex'} width={'100%'}>
                <Checkbox
                  isChecked={noRemind}
                  variant='tgModal'
                  onChange={e => onChangeCheckbox(e.target.checked)}
                >
                  {t('NOT_REMIND')}
                </Checkbox>
              </Box>
            )}
            {curModalCfg?.can_hide_forever === 1 && (
              <Box
                height={'50px'}
                display={'flex'}
                alignItems={'start'}
                width={'100%'}
                marginTop='-5px'
              >
                <Checkbox
                  isChecked={foreverNoRemind}
                  variant='tgModal'
                  onChange={e => onChangeForever(e.target.checked)}
                >
                  {t('NOT_REMIND2')}
                </Checkbox>
              </Box>
            )}
          </Box>
        </Flex>
      </ModalContent>
    </Modal>
  )
}

const HomeTgModal: FC = () => {
  const { pathname } = useLocation()
  const { extra } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.TgModal],
  )
  if (pathname === '/' || extra?.isManu) {
    return <TgModal />
  } else {
    return null
  }
}

export default HomeTgModal
