import { api } from '@/services/api'
import type { Response } from '@/services/types'

export const gameApi = api.injectEndpoints({
  endpoints: build => ({
    // 获取正在进行中的活动
    getHomeGameList: build.query<Response<any>, void>({
      query: data => {
        return {
          url: 'api/v1/internal/game/aggregate_list',
          data,
          method: 'GET',
        }
      },
    }),
  }),
})

export const { useGetHomeGameListQuery } = gameApi
