import { Box } from '@chakra-ui/react'

import icons from './level-icon-v3.webp'

export default function VipLevelIcon({
  level,
  size = 56,
}: {
  level: number
  size?: number
}) {
  const height = size
  const width = (100 * size) / 56

  const x = level * width * -1

  return (
    <Box
      w={`${width}px`}
      h={`${height}px`}
      background={`url(${icons}) no-repeat`}
      backgroundSize={`auto ${height}px`}
      backgroundPosition={`${x}px 0`}
    />
  )
}
