import type SemanticTheme from '@/styles/SemanticTheme'

import ButtonStyles from './Button.json'

const theme: SemanticTheme = {
  layoutGap: '15px',
  mainTextColor: 'gray.900',
  tabMaxWidth: 'auto',

  buttonNormalBg: ButtonStyles.baseStyle.colorScheme['fill-prim'].bgGradient,
  buttonNormalColor:
    ButtonStyles.baseStyle.colorScheme['fill-prim']['--btn-text'],

  switchNormalColor: 'prim.500',
  switchCheckedColor: 'seco.500',
  switchTextColor: 'gray.600',

  plinkoBG: [13, 18, 27],
  plinkoTagBg: [27, 34, 51],

  // mines
  minesStepBox: {
    border: '1px solid #fff',
    borderColor: 'gray.500',
  },

  minesStepBoxActive: {
    border: '1px solid #fff',
    borderColor: 'prim.500',
  },

  minesStepTop: {
    bgColor: 'gray.500',
    color: '#fff',
    border: 'none',
  },

  minesStepTopActive: {
    bgColor: 'prim.500',
    color: '#fff',
  },

  minesStepBottom: {
    bgColor: 'gray.400',
  },
  minesStepBottomActive: {
    bgColor: 'gray.400',
    color: 'prim.500',
  },

  starsStepBox: {
    border: '1px solid #fff',
    borderColor: 'gray.300',
  },
  starsStepBoxActive: {
    border: '1px solid #fff',
    borderColor: 'prim.500',
  },
  starsStepTop: {
    bgColor: 'gray.500',
    color: 'gray.900',
    border: 'none',
  },
  starsStepTopActive: {
    bgColor: 'prim.500',
    color: '#fff',
  },
  starsStepBottom: {
    color: 'gray.700',
    bgColor: 'gray.300',
  },
  starsStepBottomActive: {
    color: 'prim.500',
  },

  autoPick: {
    flex: '1',
    h: '48px',
    borderRadius: '10px',
    overflow: 'hidden',
    textAlign: 'center',
    cursor: 'pointer',
    bgColor: 'seco.500',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '48%',
  },
  autoPickText: {
    // h: '24px',
    fontWeight: '800',
    cursor: 'pointer',
    maxW: {
      base: '85%',
      lg: '85%',
      md: '85%',
    },
    wordBreak: 'break-word',
    textAlign: 'center',
    lineHeight: '16px',
    // marginTop: '-4px',
  },
  pickslot: {
    bgColor: 'gray.500',
    color: '#fff',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '48%',
  },
  pickSlotText: {
    //  h: '24px',
    fontWeight: '800',
    cursor: 'pointer',
    maxW: '60%',
    wordBreak: 'break-word',
    textAlign: 'center',
    lineHeight: '16px',
    // marginTop: '-4px',
  },

  amountColor: 'prim.500',

  diceBg: 'gray.400',
  diceControlBg: 'gray.400',
  diceLuckyNumberBg: 'gray.400',
}

export default theme
