import { useBreakpoint } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

/** 判断是否是移动端 */
export function _useMobile(): boolean {
  const bp = useBreakpoint()

  return ['base', 'sm'].includes(bp)
}

export const isMobileRegExp = () => {
  const regExp = /Android|Windows Phone|webOS|iPhone|iPod|BlackBerry/i
  return regExp.test(window.navigator.userAgent)
}
const getScreenState = () => {
  const isMobileScreen = document.body.clientWidth <= 768
  return (isMobileRegExp() || isMobileScreen) ?? false
}

export function useMobile(): boolean {
  const [isMobile, setIsMobile] = useState<boolean>(getScreenState())
  let didCancel = false
  const resize = () => {
    setCancelState(getScreenState())
  }
  const setCancelState = (state: any) => {
    if (!didCancel) setIsMobile(state)
  }
  useEffect(() => {
    window.addEventListener('resize', resize, false)
    return () => {
      didCancel = true
      window.removeEventListener('resize', resize, false)
    }
  }, [])

  return isMobile
}
