import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { features } from '@/websites/current/property.json'

import type { RootState } from '../app/store'
import { referralApi } from './api'
import type { SpreadType } from './types'

interface PageIndex {
  lastRecordId: string
  lastRecordCreatedAt: string
}

export interface ReferralDetailsState {
  total: number
  page: number
  pageSize: number
  pos: number
  spreadType: SpreadType
  pageIndexSets: Record<number, PageIndex>
}

const initPageIndex = {
  lastRecordId: '',
  lastRecordCreatedAt: '',
}

const initialState: ReferralDetailsState = {
  total: 0,
  page: 1,
  pageSize: 10,
  pos: 0,
  spreadType: features?.ignore_invite_reward ? 'bet_daily' : 'recharge',
  pageIndexSets: {
    1: initPageIndex,
  },
}

export const referralDetailsSlice = createSlice({
  name: 'referralDetailsSlice',
  initialState,
  reducers: {
    nextPage: (state, action: PayloadAction<PageIndex>) => {
      const { total, pageSize, page, pageIndexSets } = state
      const lastPage = Math.ceil(total / pageSize)
      if (page < lastPage) {
        const next = state.page + 1
        if (!(next in pageIndexSets)) {
          state.pageIndexSets[next] = action.payload
        }
        state.page = next
      }
    },
    prevPage: state => {
      const { page } = state
      if (page > 1) {
        state.page = state.page - 1
      }
    },
    changeSpreadType: (state, action: PayloadAction<SpreadType>) => {
      state.spreadType = action.payload
      state.page = 1
      state.pos = 0
    },
    changePageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
      state.page = 1
      state.pos = 0
    },
    reset: () => {
      return initialState
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      referralApi.endpoints.getInvitationRecords.matchFulfilled,
      (state, action) => {
        state.total = action.payload.data.count
      },
    )
  },
})

export const selectReferralDetails = (state: RootState) => state.referralDetails

export const selectPageIndex = (state: RootState) => {
  const { page, pageIndexSets } = selectReferralDetails(state)
  return pageIndexSets[page] ?? initPageIndex
}
export const selectHasNext = (state: RootState) => {
  const { total, pageSize, page } = selectReferralDetails(state)
  const lastPage = Math.ceil(total / pageSize)
  return page < lastPage
}

export const { nextPage, prevPage, changeSpreadType, changePageSize, reset } =
  referralDetailsSlice.actions

export const referralDetailsReducer = referralDetailsSlice.reducer
