import { Flex, useTheme } from '@chakra-ui/react'
import { useMemo } from 'react'

import LoadingProgress from '@/components/atom-page-loading/LoadingProgress'
import LazyImage from '@/components/lazy-image/LazyImage'
import Motion from '@/components/Motion'

import { useAppSelector } from '../app/store'
import { selectIsChristmasDressUpEnable } from '../christmas-dress-up/api'

export default function Splash() {
  const isChristmasDressUpEnable = useAppSelector(
    selectIsChristmasDressUpEnable,
  )

  const [desktopImg, mobileImg] = useMemo(() => {
    if (isChristmasDressUpEnable) {
      return [
        '/assets/images/about-icon/christmas-logos/web-loading.png',
        '/assets/images/about-icon/christmas-logos/h5-loading.png',
      ]
    } else {
      return [
        '/assets/images/about-icon/web-loading.png',
        '/assets/images/about-icon/h5-loading.png',
      ]
    }
  }, [isChristmasDressUpEnable])

  const theme = useTheme()
  return (
    <Flex
      w='100%'
      h='100%'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
      bg='gray.50'
    >
      <Motion
        h='fit-content'
        initial={{ opacity: 0, scale: 0.6 }}
        animate={{ opacity: 1, scale: 0.8 }}
        transition={{
          opacity: {
            duration: 1,
          },
          scale: {
            duration: 0.7,
            repeat: Infinity,
            repeatType: 'mirror',
          },
        }}
      >
        <picture>
          <source
            srcSet={desktopImg}
            media={`(min-width: ${theme.breakpoints.md})`}
          />
          <LazyImage fit='contain' src={mobileImg} maxW='100%' />
        </picture>
      </Motion>
      <LoadingProgress />
    </Flex>
  )
}
