import type { ComponentStyleConfig } from '@chakra-ui/react'

import { NAME, PARTS } from '@/components/atom-active-link/constants'

const componentStyle: ComponentStyleConfig = {
  parts: PARTS as unknown as string[],
  baseStyle: {
    container: {
      bg: 'gray.400',
      '--icon-color': 'var(--chakra-colors-icon-base)',
      _activeLink: {
        bg: 'prim.500',
        color: 'white',
        '--icon-color': 'var(--chakra-colors-white)',
      },
    },
  },
  variants: {
    primary: {
      container: {
        color: 'text.base',
      },
    },
    secondary: {
      container: {
        color: 'text.secondary',
      },
    },
  },
}

export const name = NAME
export default componentStyle
