// m1 虚拟支付
// Image
import {
  AspectRatio,
  Box,
  Flex,
  Icon,
  Link,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { useMemo } from 'react'

import { isFeatureEnabled } from '@/common/feature'
import CopyInput from '@/components/input/CopyInput'
import LazyImage from '@/components/lazy-image/LazyImage'
import TitledDialog from '@/components/modal/TitledDialog'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import VirtualModalTips from '@/modules/wallet/virtualModalTips'
import {
  getCurrentVirtualItem,
  getShowVirtualModal,
  getVirtualItemIndex,
  getVirtualOrderList,
  setVirtualOrderList,
  switchVirtualModalTips,
} from '@/modules/wallet/walletSlice'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { localStorage } from '@/utils/localStorage'
import { formatLocalMoney } from '@/utils/tools'

import CountDown from './countDonw'
import type { InformationListProps, RuleListItemProps } from './interface'
import { DEFAULT_VIRTUAL_ITEM } from './interface'
import {
  informationList,
  informationListSpei2,
  ruleList,
  ruleListSpei2,
} from './virtualModalContansts'
// import { RECHARGE_STATUE_MAP } from './walletContansts'
const textStyle = {
  marginBottom: '8px',
  marginTop: '12px',
  px: '6px',
  fontSize: '14px',
  color: 'gray.700',
}
const rulesStyle = {
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '16px',
  color: 'gray.700',
}

export function VirtualModal() {
  const {
    isOpen: isGuideModalOpen,
    onClose: onGuideModalClose,
    onOpen: onGuideModalOpen,
  } = useDisclosure()
  const dispatch = useAppDispatch()
  const showVirtualModal = useAppSelector(getShowVirtualModal)
  const index = useAppSelector(getVirtualItemIndex)
  const list = useAppSelector(getVirtualOrderList)
  const currentItem =
    useAppSelector(getCurrentVirtualItem) || DEFAULT_VIRTUAL_ITEM
  const toast = useToast()
  const cancelContent = () => {
    return (
      <>
        <Box
          fontSize='14px'
          fontWeight='bold'
          color='#ADB6C4'
          textAlign='center'
        >
          Las altas rebajas de recarga están a la vuelta de la esquina, ¿estás
          seguro de que quieres darte por vencido?
        </Box>
        <Flex alignItems='center' justifyContent='center' padding='16px 0 '>
          <LazyImage
            src='/assets/images/spei-icon.png'
            width='102px'
            height='auto'
          />
        </Flex>
        <Box
          fontSize='12px'
          fontWeight='600'
          color='#55657E'
          textAlign='center'
        >
          Continúe para completar la recarga, aún puede disfrutar de descuentos
          de recarga
        </Box>
      </>
    )
  }
  //
  const closeVirtualModal = (params: {
    showVirtualTipsModal: boolean
    showVirtualModal: boolean
  }) => {
    dispatch(switchVirtualModalTips(params))
  }
  //
  const btnClick = () => {
    toast({
      title: `Si la transferencia es correcta, el saldo de tu plataforma aumentará en 5 - 60 min. ten un buen juego`,
      status: 'success',
    })
    dispatch(
      switchVirtualModalTips({
        showVirtualTipsModal: false,
        showVirtualModal: false,
      }),
    )
  }
  //这个订单超时或者其他原因
  const onFinish = () => {
    //
    const tempCurrentItem = {
      ...currentItem,
      status: 'timeout',
    }
    const tempList = [...list]
    tempList[index] = { ...tempCurrentItem }
    dispatch(
      setVirtualOrderList({
        list: [...tempList],
        index: index,
      }),
    )
    localStorage.setItem('virtualOrderList', JSON.stringify(tempList))
  }

  const currentInformationList = useMemo(() => {
    return currentItem?.typ === 'SPEI2' ? informationListSpei2 : informationList
  }, [currentItem?.typ])

  const currentRuleList = useMemo(() => {
    return currentItem?.typ === 'SPEI2' ? ruleListSpei2 : ruleList
  }, [currentItem?.typ])

  const titleRender = () => {
    return (
      <Flex justifyContent='center' alignItems='center'>
        <LazyImage h='28px' src='/assets/images/about-icon/site-logo.png' />
        <Text as='span' ml='7.5px' h='32px' w='1px' bg='gray.600' />
        <LazyImage
          h='48px'
          src={
            currentItem?.typ === 'SPEI2'
              ? '/assets/images/wallet/spei/spei-2.png'
              : '/assets/images/wallet/spei/spei-1.png'
          }
        />
      </Flex>
    )
  }

  const oldInfoRender = () => {
    return (
      <>
        <Box>Aposta maxima</Box>
        <Box>
          {currentItem?.start_time && (
            <CountDown
              key={currentItem.start_time}
              start_time={currentItem.start_time}
              on_finish={() => {
                onFinish()
              }}
            />
          )}
        </Box>
      </>
    )
  }

  const infoRender = () => {
    return (
      <>
        <Text textStyle='text3' color='gray.800'>
          Importe del pedido
        </Text>
        <Text
          fontSize='30px'
          fontWeight='800'
          color='four.400'
          my='12px'
          lineHeight='30px'
        >
          {formatLocalMoney(Number(currentItem?.amount || 0))}
        </Text>
        <Flex
          fontSize='10px'
          fontWeight='600'
          lineHeight='16px'
          color='seco.500'
        >
          <Text as='span' mr='4px'>
            Plazo de pago
          </Text>
          {currentItem?.start_time && (
            <CountDown
              key={currentItem.start_time}
              start_time={currentItem.start_time}
              on_finish={() => {
                onFinish()
              }}
            />
          )}
        </Flex>
      </>
    )
  }

  const footerRender = () => {
    return (
      <>
        <Box
          mx='20px'
          mb='24px'
          w='full'
          height='46px'
          textAlign='center'
          lineHeight='46px'
          bg='linear-gradient(180deg, #FE4E73 0%, #ED1E4A 100%)'
          color='#fff'
          borderRadius='10px'
          cursor='pointer'
          onClick={btnClick}
        >
          Transferencia completada
        </Box>
        <Flex
          w='full'
          alignItems='center'
          justifyContent='space-between'
          h='40px'
          bg='gray.300'
          p='0 0 0 15px'
        >
          <Text fontSize='10px' fontWeight='800' color='gray.700'>
            Garantía de seguridad
          </Text>
          <LazyImage
            h='16px'
            src='/assets/images/wallet/spei/spei-provider.png'
          />
        </Flex>
      </>
    )
  }

  return (
    <TitledDialog
      title={titleRender()}
      isOpen={showVirtualModal}
      onClose={() => {
        closeVirtualModal({
          showVirtualTipsModal: true,
          showVirtualModal: true,
        })
      }}
      size='sm'
      // padding={false}
      action={footerRender()}
      footerStyle={{
        position: 'relative',
        mt: '-20px',
        zIndex: '1',
        flexWrap: 'wrap',
        p: 0,
      }}
    >
      <Flex
        alignItems='center'
        justifyContent='center'
        flexDirection='column'
        borderRadius='10px'
        py='12px'
        bg='gray.500'
      >
        {!currentItem.amount ? oldInfoRender() : infoRender()}
      </Flex>
      {/* 订单信息 */}
      {currentItem.account_no &&
        currentInformationList.map((item: InformationListProps) => {
          return (
            <Box key={item.title}>
              <Text {...textStyle}>{item.title}</Text>
              <CopyInput text={currentItem?.[item.key]} />
            </Box>
          )
        })}
      {/* 引导弹窗 */}
      {isFeatureEnabled('SPEIGuideVideo') && (
        <Flex
          alignItems='center'
          width='100%'
          mt='12px'
          fontSize='12px'
          color='link'
        >
          <Icon as={SharedIcon.Youtube} fontSize='24px' />

          <Link marginLeft='8px' onClick={onGuideModalOpen}>
            Tutorial de depósito SPEI
          </Link>
        </Flex>
      )}
      {/* spei规则说明 */}
      <Text
        fontSize='12px'
        fontWeight='800'
        marginTop='12px'
        marginBottom='8px'
        color='gray.800'
      >
        {ruleList.title}
      </Text>
      {currentRuleList.rules.map((item: RuleListItemProps) => {
        return (
          <Text key={item.text} {...rulesStyle}>
            {t(item.text)}
          </Text>
        )
      })}

      {/* 关闭虚拟弹出层的提示 */}
      <VirtualModalTips
        title='Pista'
        cancel_text='Abandonar'
        confirm_text='Continuar'
        cancel_cb={() => {
          closeVirtualModal({
            showVirtualTipsModal: false,
            showVirtualModal: true,
          })
        }}
        confirm_cb={() => {
          closeVirtualModal({
            showVirtualTipsModal: false,
            showVirtualModal: false,
          })
        }}
        content={cancelContent()}
        close_cb={() => {
          closeVirtualModal({
            showVirtualTipsModal: false,
            showVirtualModal: true,
          })
        }}
      />

      <TitledDialog
        isOpen={isGuideModalOpen}
        onClose={onGuideModalClose}
        title='Proceso'
        size='sm'
      >
        <video
          autoPlay
          controls
          style={{ maxHeight: 'calc(100vh - 120px - 7.5rem)', margin: 'auto' }}
        >
          <source
            src={
              currentItem?.typ === 'SPEI2'
                ? '/assets/images/wallet/spei/help-recharge-spei-guide.mp4'
                : '/assets/images/wallet/spei/help-recharge-spei-1-guide.mp4'
            }
            type='video/mp4'
          />
        </video>
      </TitledDialog>
    </TitledDialog>
  )
}

export default function NiceVirtualModal() {
  const showVirtualModal = useAppSelector(getShowVirtualModal)
  if (!showVirtualModal) return null

  return <VirtualModal />
}
