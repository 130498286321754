import { createSelector } from '@reduxjs/toolkit'

import { api } from '@/services/api'
import type { Response } from '@/services/types'

import { STATUS_TYPE } from '../app/home-page-config-types'
import type { RootState } from '../app/store'
import type { FeatSwitchProps } from './app-layout-slice'

export const featSwitchApi = api.injectEndpoints({
  endpoints: builder => ({
    getFeatSwitchList: builder.query<
      Response<FeatSwitchProps<string>[] | undefined | null>,
      void
    >({
      query: () => {
        return {
          url: '/feat_switch/list',
          method: 'GET',
        }
      },
    }),
    getFeatSwitchListV2: builder.query<
      Response<FeatSwitchProps<string>[] | undefined | null>,
      void
    >({
      query: () => {
        return {
          url: '/feat_switch/config/list',
          method: 'POST',
        }
      },
    }),
    websiteLogoFeatSwtch: builder.query<
      Response<{ enable: number; param: string }>,
      void
    >({
      query: () => ({
        url: '/feat_switch/website_logo',
        method: 'GET',
      }),
    }),
    getAlldomainList: builder.query({
      query: () => {
        return {
          url: '/gw/user-biz/get_all_host_list',
          method: 'POST',
        }
      },
    }),
  }),
})

export const { useGetFeatSwitchListQuery, useGetAlldomainListQuery } =
  featSwitchApi

export const selectState = (state: RootState) => state

export const selectWebsiteLogo = createSelector(selectState, state => {
  try {
    const website_logo = state.appLayout.featSwitch['website_logo'] || {}
    return (
      website_logo?.enable === STATUS_TYPE.ON &&
      (website_logo?.param as unknown as string)
    )
  } catch (e) {
    return false
  }
})
