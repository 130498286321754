import { api } from '@/services/api'
import type { Response } from '@/services/types'

import type { Task } from './types'

interface PaginationQueryReturnType<T> {
  list: T[]
  page: number
  pageSize: number
  total: number
}

interface PaginationQueryArgs {
  page?: number
  pageSize?: number
}

export const dailyTaskApi = api.injectEndpoints({
  endpoints: builder => ({
    tasks: builder.query<
      Response<PaginationQueryReturnType<Task>>,
      PaginationQueryArgs & { type: 'user' | 'recharge' | 'activity' | 'agent' }
    >({
      query: ({ type = 'user', page = 1, pageSize = 100 }) => ({
        url: `/api/v1/platform/task/search/${type}`,
        method: 'POST',
        data: { page, pageSize },
      }),
      providesTags: ['Auth', 'Tasks'],
    }),
    receiveAward: builder.mutation<Response<{ quantity: number }>, number>({
      query: taskId => ({
        url: `/api/v1/platform/task/claim/rewards`,
        method: 'PUT',
        data: { tid: taskId },
      }),
      invalidatesTags: ['Tasks'],
    }),
  }),
})

export const { useTasksQuery, useReceiveAwardMutation } = dailyTaskApi
