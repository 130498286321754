import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

import AtomDrawer from '@/components/atom-drawer/AtomDrawer'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  closeModal,
  openModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import WalletButton from '@/modules/wallet/walletButton'

export default function VipUpgradeGuide() {
  const dispatch = useAppDispatch()
  const navigator = useNavigate()
  const { open } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.UpgradeVipGuide],
  )

  const handleClose = () => {
    dispatch(closeModal({ key: GlobalModalKey.UpgradeVipGuide }))
  }

  const handleWallet = (val: 'recharge' | 'withdraw') => {
    dispatch(
      openModal({
        key: GlobalModalKey.Wallet,
        data: {
          defaultActivedTabKey: val,
        },
      }),
    )
    handleClose()
  }

  return (
    <AtomDrawer
      placement='right'
      colorScheme='normal'
      title={
        <Text textStyle='h4' color='gray.900'>
          {t('HOW_TO_PROMOTE_VIP')}
        </Text>
      }
      closeBtnHidden
      isOpen={open}
      action={
        <Grid templateColumns='repeat(2, 1fr)' gap='12px'>
          <WalletButton
            onClick={() => {
              handleWallet('recharge')
            }}
            colorScheme='fill-seco'
          >
            {t('DEPOSIT_RECHARGE')}
          </WalletButton>
          <WalletButton
            onClick={() => {
              navigator('/game/all')
              handleClose()
            }}
            colorScheme='fill-prim'
          >
            {t('NAV_APOSTA')}
          </WalletButton>
        </Grid>
      }
      onClose={handleClose}
      drawerContentStyle={{
        bgColor: 'var(--chakra-colors-bg-modal)',
        // bgColor: 'gray.300',
      }}
    >
      <Flex color={'white'} mb='30px'>
        <Box
          borderRadius='8px 2px 2px 2px'
          bgColor='prim.500'
          w='32px'
          h='32px'
          textStyle='h3'
          textAlign='center'
          lineHeight='32px'
          flexShrink={0}
        >
          01
        </Box>
        <Box ml='16px'>
          <Text textStyle='text5' color='gray.900'>
            {t('HOW_TO_PROMOTE_STEP1')}
          </Text>
          <Image
            mt='24px'
            height='52px'
            src='/assets/images/vip/guide/upgrade-vip-guide-1.webp'
          />
        </Box>
      </Flex>
      <Flex color={'white'} mb='30px'>
        <Box
          borderRadius='8px 2px 2px 2px'
          bgColor='prim.500'
          w='32px'
          h='32px'
          textStyle='h3'
          textAlign='center'
          lineHeight='32px'
          flexShrink={0}
        >
          02
        </Box>
        <Box ml='16px'>
          <Text textStyle='text5' color='gray.900'>
            {t('HOW_TO_PROMOTE_STEP2')}
          </Text>
          <Image
            mt='24px'
            height='59px'
            src='/assets/images/vip/guide/upgrade-vip-guide-2.webp'
          />
        </Box>
      </Flex>
      <Flex color={'white'} mb='30px'>
        <Box
          borderRadius='8px 2px 2px 2px'
          bgColor='prim.500'
          w='32px'
          h='32px'
          textStyle='h3'
          textAlign='center'
          lineHeight='32px'
          flexShrink={0}
        >
          03
        </Box>
        <Box ml='16px' color='gray.900'>
          <Text textStyle='text5'>{t('HOW_TO_PROMOTE_STEP3')}</Text>
          <Image
            mt='7px'
            height='72px'
            src='/assets/images/vip/guide/upgrade-vip-guide-3.webp'
          />
          <Box p='8px' borderRadius='8px' mb='4px' bgColor='gray.100'>
            <Text textStyle='text6' mb='4px' color='gray.900'>
              {t('DEPOSIT_100')}
            </Text>
            <Box w='full' h='4px' borderRadius='4px' bgColor='#00AC41' />
          </Box>
          <Box p='8px' borderRadius='8px' mb='4px' bgColor='gray.100'>
            <Text textStyle='text6' mb='4px' color='gray.900'>
              {t('BET_100')}
            </Text>
            <Box w='full' h='4px' borderRadius='4px' bgColor='#00AC41' />
          </Box>
        </Box>
      </Flex>
    </AtomDrawer>
  )
}
