import { createSelector, createSlice } from '@reduxjs/toolkit'

import { getVariable } from '@/common/env'
import { pageConfigApi } from '@/services/jsonApi'
import { formatLocalMoney } from '@/utils/tools'
import referralConfig from '@/websites/current/pages/referral/referral-config.json'

import type { RootState } from '../app/store'
import { dailyTaskApi } from '../task/api'
import type { Task } from '../task/types'
import { referralApi } from './api'

interface AchievementItem {
  head_count: number
  bonus: number
  icon: string
  [key: string]: any
}

export interface AgentInfoResponseData {
  accomplishment_bet_target: number
  accomplishment_refresh_period: number
  bet_award_flow: number
  bet_award_rate_level_1: number
  bet_award_rate_level_2: number
  bet_award_rate_level_3: number
  currency: string
  head_refund: number
  head_refund_flow: number
  recharge_award_flow: number
  recharge_award_rate_level_1: number
  recharge_award_rate_level_2: number
  recharge_award_rate_level_3: number
  status: number
  valid_subordinate_recharge_limit: number
  version: number
  [key: string]: any
}

interface AgentInfo {
  // 人头费
  rechargeRebate?: number
}

export type ReferralState = {
  achieveList: AchievementItem[]
  userInviteList: Task[]
  agentCfgInfo: AgentInfo
  shareUrl?: string
}

const initialState: ReferralState = {
  achieveList: [],
  userInviteList: [],
  shareUrl: '',
  agentCfgInfo: {
    rechargeRebate: Number(getVariable('RECHARGE_BONUS')),
  },
}

export const referralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addMatcher(
        referralApi.endpoints.agentAchieve.matchFulfilled,
        (state, action) => {
          const { payload } = action
          const data = payload?.data || []
          const sortData = data?.sort(
            (a: AchievementItem, b: AchievementItem) =>
              a.head_count - b.head_count,
          )
          state.achieveList = sortData
        },
      )
      .addMatcher(
        dailyTaskApi.endpoints.tasks.matchFulfilled,
        (state, { payload, meta }) => {
          if (meta.arg.originalArgs.type === 'agent') {
            let list = payload?.data?.list || []
            list = list.sort(
              (a, b) => a.completion_number - b.completion_number,
            )
            state.userInviteList = list
          }
        },
      )
      .addMatcher(
        referralApi.endpoints.getAgentCfg.matchFulfilled,
        (state, action) => {
          const { payload } = action
          state.agentCfgInfo.rechargeRebate = payload.data.head_refund
        },
      )
      .addMatcher(
        pageConfigApi.endpoints.getHomePageConfig.matchFulfilled,
        (state, action) => {
          const { payload } = action
          state.shareUrl = payload?.share_url?.domain || ''
        },
      )
  },
})

export const selectAchieveList = (state: RootState) =>
  state.referral.achieveList

export const selectUserInviteList = (state: RootState) =>
  state.referral.userInviteList

export const selectUserInviteUrl = (state: RootState) => state.referral.shareUrl

export const selectUserAchieveTaskProgress = createSelector(
  selectUserInviteList,
  (userInviteList: Task[]) =>
    userInviteList[userInviteList.length - 1]?.schedule || 0,
)

export const selectAgentRechargeRebate = (state: RootState) =>
  state.referral.agentCfgInfo?.rechargeRebate ?? 0

export const selectAchievementBonus = createSelector(
  (state: RootState) => selectUserInviteList(state),
  (userInviteList: Task[]) => {
    const ARCHIEVE_FROM_API = getVariable('ARCHIEVE_FROM_API')
    const localAchievementBonus = referralConfig?.achievementBonus ?? []
    const achievementBonus = !ARCHIEVE_FROM_API
      ? localAchievementBonus
      : userInviteList.map(item => {
          return {
            invite: item.completion_number || 0,
            price: Number(item.n) || 0,
          }
        })
    return achievementBonus.map(item => {
      return {
        invite: item.invite,
        price: formatLocalMoney(item.price),
        priceWithoutUnit: item.price,
      }
    })
  },
)

export const referralReducer = referralSlice.reducer
