import {
  Button,
  CloseButton,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../app/store'
import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'

export default function RegionLimitModal() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { open, data } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.RegionLimit],
  )
  const handleClose = useCallback(() => {
    if (data) {
      navigate('/')
    }
    dispatch(closeModal({ key: GlobalModalKey.RegionLimit }))
  }, [dispatch, data])
  return (
    <Modal
      autoFocus={false}
      trapFocus={false}
      isOpen={open}
      onClose={handleClose}
      isCentered
      motionPreset='none'
    >
      <ModalOverlay />
      <ModalContent width='356px' padding='24px'>
        <CloseButton
          position='absolute'
          right='3'
          top='3'
          onClick={handleClose}
        />
        <Flex
          flexDirection='column'
          justifyContent='space-between'
          alignItems='center'
        >
          <Text textStyle='h3'>{t('FUNCTION_DEVELOP_TITLE')}</Text>
          <Text color='gray.700' margin='56px 28px' textStyle='text4'>
            {t('FUNCTION_DEVELOP_TEXT')}
          </Text>
          <Button
            width='100%'
            height='46px'
            colorScheme={'fill-prim'}
            fontSize='lg'
            fontWeight='extrabold'
            onClick={handleClose}
          >
            {t('HOME_LOBBY')}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  )
}
