function ShareIcon({ color }: { color?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
    >
      <g clipPath='url(#clip0_4203_110393)'>
        <path
          d='M19.1469 6.95996H16.1966V8.29645H19.1469C19.6048 8.29733 20.0437 8.47962 20.3674 8.80338C20.6912 9.12715 20.8735 9.56601 20.8744 10.0239V20.9363C20.8735 21.3941 20.6912 21.833 20.3674 22.1568C20.0437 22.4805 19.6048 22.6628 19.1469 22.6637H6.56389C6.10603 22.6628 5.66716 22.4805 5.3434 22.1568C5.01964 21.833 4.83737 21.3941 4.83649 20.9363V10.0239C4.83737 9.56601 5.01964 9.12715 5.3434 8.80338C5.66716 8.47962 6.10603 8.29733 6.56389 8.29645H9.79153V6.95996H6.56389C5.75157 6.96085 4.97278 7.28392 4.39838 7.85832C3.82398 8.43272 3.50088 9.21155 3.5 10.0239V20.9363C3.50088 21.7486 3.82398 22.5274 4.39838 23.1018C4.97278 23.6762 5.75157 23.9993 6.56389 24.0002H19.1469C19.9593 23.9993 20.7381 23.6762 21.3125 23.1018C21.8869 22.5274 22.21 21.7486 22.2108 20.9363V10.0239C22.21 9.21155 21.8869 8.43272 21.3125 7.85832C20.7381 7.28392 19.9593 6.96085 19.1469 6.95996Z'
          fill={color ?? '#3F4F70'}
        />
        <path
          d='M9.31336 5.09551L12.1868 2.28219V15.1626C12.1868 15.3398 12.2572 15.5098 12.3825 15.6351C12.5079 15.7604 12.6778 15.8309 12.855 15.8309C13.0323 15.8309 13.2022 15.7604 13.3276 15.6351C13.4529 15.5098 13.5233 15.3398 13.5233 15.1626V2.28219L16.3967 5.1122C16.5219 5.23666 16.6913 5.30654 16.8679 5.30654C17.0444 5.30654 17.2138 5.23666 17.339 5.1122C17.4634 4.98699 17.5333 4.81765 17.5333 4.64111C17.5333 4.46457 17.4634 4.29519 17.339 4.16999L13.3295 0.213993C13.2046 0.0915524 13.0367 0.0229492 12.8617 0.0229492C12.6868 0.0229492 12.5189 0.0915524 12.394 0.213993L8.38449 4.16999C8.31415 4.22941 8.25685 4.30273 8.21619 4.38534C8.17552 4.46795 8.15235 4.55806 8.14815 4.65004C8.14396 4.74203 8.15883 4.83388 8.19182 4.91985C8.2248 5.00582 8.2752 5.08407 8.33985 5.14964C8.4045 5.21521 8.48201 5.26671 8.5675 5.30091C8.65299 5.33512 8.74463 5.35128 8.83667 5.34839C8.9287 5.3455 9.01913 5.32364 9.10231 5.28415C9.18549 5.24466 9.25962 5.18838 9.32003 5.11888L9.31336 5.09551Z'
          fill={color ?? '#3F4F70'}
        />
      </g>
      <defs>
        <clipPath id='clip0_4203_110393'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export default ShareIcon
