import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import { AspectRatio, Box, Link } from '@chakra-ui/react'
import type { FC } from 'react'
import { memo, useEffect, useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Autoplay, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { SwiperOptions } from 'swiper/types'

import LazyImage from '@/components/lazy-image/LazyImage'
import Motion from '@/components/Motion'
import { useActionTrigger } from '@/utils/hooks/useActionTrigger'
import { useLargerThanMobile } from '@/utils/hooks/useLargerThanMobile'

import type { Banner } from '../app/home-page-config-types'
import type { IImageLink } from '../app-layout/types'

interface breakpointsProps {
  [width: number]: SwiperOptions
  [ratio: string]: SwiperOptions
}

type CarouselProps = {
  loopNumberLimit?: number
  ratio: number
  breakpoints: breakpointsProps
  items?: Banner[] | IImageLink[]
  paginationPlacement?: 'insideBottom' | 'outsideBottom'
}

const ItemRender: FC<{ item: IImageLink | Banner; ratio: number }> = ({
  item,
  ratio,
}) => {
  const actionTrigger = useActionTrigger()
  const { action, imageSrc } = item
  const contentElem = (
    <Box overflow='hidden' borderRadius='2xl'>
      <Motion
        h='fit-content'
        whileHover={{ scale: [1, 1.1] }}
        transition={{ duration: 0.3 }}
      >
        <AspectRatio ratio={ratio}>
          <LazyImage src={imageSrc} alt='' borderRadius='2xl' noLazy />
        </AspectRatio>
      </Motion>
    </Box>
  )
  if (action?.href) {
    return (
      <Link as={RouterLink} to={action.href} target={action?.data?.target}>
        {contentElem}
      </Link>
    )
  } else {
    return <Box onClick={actionTrigger(action)}>{contentElem}</Box>
  }
}

function Carousel(props: CarouselProps) {
  const [init, setInit] = useState(false)

  const {
    items = [],
    ratio,
    breakpoints,
    paginationPlacement = 'insideBottom',
    loopNumberLimit = 0,
  } = props

  const paginationElemRef = useRef<HTMLDivElement | null>(null)
  const isLargerThanMobile = useLargerThanMobile()

  let style = {}

  const isNoShowSwiper =
    !init && props.items && props.items[0] && !isLargerThanMobile

  useEffect(() => {
    setTimeout(() => {
      setInit(true)
    }, 500)
  }, [])

  if (!isLargerThanMobile) {
    style = {
      bgImage: isNoShowSwiper ? items[0].imageSrc : undefined,
      bgSize: '100% auto',
      overflow: 'hidden',
      borderRadius: '2xl',
    }
  }
  return (
    <Box flexShrink='0' position='relative' sx={style}>
      {isNoShowSwiper ? (
        <AspectRatio ratio={props.ratio}>
          <Box />
        </AspectRatio>
      ) : (
        <Swiper
          breakpoints={breakpoints}
          pagination={{ el: paginationElemRef.current }}
          spaceBetween={10}
          loop={items.length > Number(loopNumberLimit || 0)}
          modules={[Autoplay, Pagination]}
          autoplay={{ delay: 3000 }}
          observer
          // loop
          grabCursor
        >
          {items.map(item => (
            <SwiperSlide key={item.key}>
              <ItemRender key={item.key} item={item} ratio={ratio} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <Box
        hidden={isLargerThanMobile}
        ref={paginationElemRef}
        textAlign='center'
        position={
          paginationPlacement === 'insideBottom' ? 'absolute' : 'static'
        }
        lineHeight='0'
        mt='2.5'
        bottom='2.5'
        zIndex='dropdown'
        sx={{
          '--bullet-active-color':
            paginationPlacement === 'insideBottom'
              ? 'white'
              : 'colors.prim.500',
        }}
      />
    </Box>
  )
}

const MemoCarousel = memo(Carousel)

export default MemoCarousel
