import { useToast } from '@chakra-ui/react'
import { isEmpty } from 'lodash-es'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { getVariable } from '@/common/env'
import { isFeatureEnabled } from '@/common/feature'
import { getDeviceType } from '@/utils/tools'

import { useAppDispatch, useAppSelector } from '../app/store'
import { openModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { selectUserIsLogin } from '../user/userSlice'
import { RechargeErrorFromType } from '../wallet/interface'
import { useRecharge } from '../wallet/useRecharge'
import useRechargeActivity from '../wallet/useRechargeActivity'
import { useRechargeWays } from '../wallet/useRechargeWays'
import { verifyPix } from '../wallet/verifyFunction'
import { useGetPlayerRechargeInfoQuery } from '../wallet/walletApi'
import {
  openParamsModal,
  selectRechargeInfo,
  selectWallet,
  setHandleType,
  setParamsDialogRechargeParams,
  setQuestType,
  setShowMxnRecahrgeEmail,
} from '../wallet/walletSlice'

const IS_MXN_RECAHRGE = isFeatureEnabled('IS_MXN_RECAHRGE') ?? false

const FORMAT_STR = /[^\w^\s^\u4e00-\u9fa5]/gi

const rechargeInfoNeed = isFeatureEnabled('rechargeInfoNeed')

export function useRechargeButtonClick(
  /** 充值金额 */
  rechargeAmount: number,
  /** 参与活动 */
  joinActFlag: boolean,
  /** 可能是充值参数？ */
  isRechargeOptionEmpty: boolean,
  /** 充值渠道 */
  rechargeType: string,
  /** 充值活动Id */
  taskId: string,
  /** 充值礼包Id */
  taskGift?: number,
  /** 破产充值活动Id */
  giftId?: number,
) {
  const isLogin = useAppSelector(selectUserIsLogin)
  useGetPlayerRechargeInfoQuery({}, { skip: !rechargeInfoNeed || !isLogin })
  const rechargeInfo = useAppSelector(selectRechargeInfo)
  const { t } = useTranslation()
  const toast = useToast()
  const dispatch = useAppDispatch()
  const { data: modalData } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.Wallet],
  )
  const rechargeParams = useAppSelector(state => ({
    ...selectWallet(state).rechargeParams,
    typ: rechargeType,
  }))

  //if M-PESA
  const IS_M_PESA_RECHARGE = useMemo(() => {
    if (!rechargeParams?.typ) return false
    return rechargeParams?.typ.toLocaleUpperCase() === 'M-PESA'
  }, [rechargeParams])
  //pix
  const IS_PIX_RECHARGE = useMemo(() => {
    if (!rechargeType) return false
    return rechargeType.toLocaleUpperCase() === 'PIX'
  }, [rechargeType])

  // pix qr code
  const IS_PIX_QRCODE_RECHARGE = useMemo(() => {
    if (!rechargeType) return false
    return (
      rechargeType.toLocaleUpperCase() === 'PIXQR' ||
      rechargeType.toLocaleUpperCase() === 'PIXQRNEW'
    )
  }, [rechargeType])

  //
  const IS_MXN_SPEI_RECHARGE = useMemo(() => {
    if (!rechargeType) return false
    return (
      IS_MXN_RECAHRGE &&
      rechargeType.replace(FORMAT_STR, '').toLocaleLowerCase() ===
        'speipersonnal'
    )
  }, [rechargeType])

  const { getActItemByAmount } = useRechargeActivity()

  const activedItem = useMemo(() => {
    return getActItemByAmount(Number(rechargeAmount))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rechargeAmount])
  const { getRechargInfoList, rechargeSaveInfo, paramasErrorHandal } =
    useRechargeWays()

  useEffect(() => {
    getRechargInfoList()
  }, [getRechargInfoList])

  const PIX_RECHARGE = getVariable('IS_PIX_RECHARGE') || false

  const {
    trigger: recharge,
    result: { isLoading: isRecharging },
  } = useRecharge({ rechargeAmount, taskId, taskGift, giftId })

  const trigger = useCallback(async () => {
    if (isRecharging) return
    if (!rechargeAmount) {
      toast({ status: 'error', title: t('AMOUNT_IS_LOW') })
      return
    }

    if (modalData?.from === 'prompte_app_activity' && joinActFlag) {
      const device = getDeviceType()
      if (['apk', 'ios-desktop-icon'].indexOf(device) === -1) {
        dispatch(
          openModal({
            key: GlobalModalKey.IntroductionToDownloadModal,
            data: {
              modalCfg: {
                title: 'APPUSER_ONLY_TLTLE',
                reward: Number(activedItem?.reward?.coefficient_amount)
                  ? (
                      Number(activedItem?.reward?.coefficient_amount) * 100
                    ).toFixed(0) + '%'
                  : Number(activedItem?.reward?.fixed_amount) > 0
                  ? `+${activedItem?.reward?.fixed_amount}`
                  : '',
                content:
                  ['android', 'pc'].indexOf(device) > -1
                    ? 'APPUSER_ONLY'
                    : 'SCREENUSER_ONLY',
              },
            },
          }),
        )
        return
      }
    }

    /** col新增了充值信息保存 */
    if (
      Object.values(rechargeInfo || {})?.some(value => !value) &&
      rechargeInfoNeed &&
      Object.keys(rechargeParams).some(
        key => !['pay_method', 'typ'].includes(key),
      )
    ) {
      dispatch(
        openModal({
          key: GlobalModalKey.WalletConfirmForm,
          data: {
            type: 'recharge',
            initialValues: {
              ...rechargeInfo,
              ...(Object.values(rechargeInfo || {})?.some(value => value)
                ? {}
                : rechargeParams),
            },
            rechargeParams: {
              rechargeAmount,
              taskGift,
              taskId,
              giftId,
            },
          },
        }),
      )
      return
    }

    /** 2024/2/21 k1注释该充值方式在站内填写手机号码 */
    /** 2024/3/18 k1该充值方式在站内填写手机号码 */
    if (IS_M_PESA_RECHARGE) {
      dispatch(
        openModal({
          key: GlobalModalKey.MPesaModal,
          data: {
            rechargeParams: {
              rechargeAmount,
              taskGift,
              taskId,
              giftId,
            },
          },
        }),
      )
      return
    }

    if (isRechargeOptionEmpty || PIX_RECHARGE) {
      // 填写数据充值
      // B系列新版充值 由于把充值参数返回在一个接口 需人工智能处理 兼容渠道选择&二维码
      //b系列充值默认使用提款信息的第一条数据且不弹出填写框 !IS_PIX_QRCODE_RECHARGE &&
      //
      if (
        !isEmpty(rechargeSaveInfo) &&
        rechargeSaveInfo?.pix &&
        IS_PIX_QRCODE_RECHARGE
      ) {
        if (
          !verifyPix({
            params: {},
            value: rechargeSaveInfo?.pix,
          }).verify
        ) {
          paramasErrorHandal()
          return
        }
        const params: any = {
          ...rechargeParams,
          ...rechargeSaveInfo,
          name: rechargeSaveInfo?.name.replace(/\s+/g, ''),
        }
        delete params?.id
        delete params?.is_default
        recharge(params, RechargeErrorFromType.RecharageAct)
        return
      }
      if (IS_PIX_RECHARGE) {
        recharge({ ...rechargeParams }, RechargeErrorFromType.RecharageAct)
        return
      }
      //  //b系列充值默认使用提款信息的第一条数据
      dispatch(setHandleType('recharge'))
      if (taskGift || giftId) {
        dispatch(
          setParamsDialogRechargeParams({
            rechargeAmount,
            taskId,
            taskGift,
            giftId,
          }),
        )
      }

      dispatch(openParamsModal())
      return
    }
    dispatch(setQuestType('request'))
    if (IS_MXN_SPEI_RECHARGE) {
      dispatch(setShowMxnRecahrgeEmail(true))
      return
    }
    recharge(
      {
        ...rechargeParams,
        ...(rechargeInfoNeed &&
        Object.keys(rechargeParams).some(
          key => !['pay_method', 'typ'].includes(key),
        )
          ? rechargeInfo
          : {}),
      },
      RechargeErrorFromType.RecharageAct,
    )
  }, [
    IS_MXN_SPEI_RECHARGE,
    IS_M_PESA_RECHARGE,
    IS_PIX_QRCODE_RECHARGE,
    IS_PIX_RECHARGE,
    PIX_RECHARGE,
    activedItem?.reward?.coefficient_amount,
    activedItem?.reward?.fixed_amount,
    dispatch,
    isRechargeOptionEmpty,
    isRecharging,
    joinActFlag,
    modalData?.from,
    paramasErrorHandal,
    recharge,
    rechargeAmount,
    rechargeParams,
    rechargeSaveInfo,
    t,
    taskGift,
    taskId,
    toast,
    giftId,
    rechargeInfo,
  ])

  const result = useMemo(
    () => ({
      trigger,
      result: { isLoading: isRecharging },
    }),
    [trigger, isRecharging],
  )

  return result
}
