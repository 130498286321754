import { Box, Text } from '@chakra-ui/react'
import type { TDate } from 'ahooks/lib/useCountDown'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'

import rechargePackageIcon from '@/assets/images/recharge-package/recharge-package-reward-center-icon.png'
import TOMORROW_ICON from '@/assets/images/wallet/walletAct/tomorrow.png'
import { isFeatureEnabled } from '@/common/feature'
import { useAtomCountDown } from '@/components/atom-count-down'
import { useUserAuth } from '@/utils/hooks'

import {
  type FloatButton,
  HOME_HOVER_BTN_TYPE,
} from '../app/home-page-config-types'
import { useAppDispatch, useAppSelector } from '../app/store'
import {
  closeModal,
  openModal,
  selectModal,
} from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import {
  BOX_STYLE,
  NUMBER_STYLE,
  TITLE_STYLE,
} from '../rewardCenter/hooks/useCashBack'
import type { RewardItem } from '../rewardCenter/types'
import useRechargeActivity from '../wallet/useRechargeActivity'
import { useRechargeTaskListQuery } from '../wallet/walletApi'
import { useRechargePackageGiftListQuery } from './activityApi'
import { setRechargeActId, setRechargePackageLoaded } from './ActSlice'
import useRechargeActEntry from './useRechargeActEntry'

const DEFAULT_TARGET_DATEE = dayjs().add(30, 'minutes').format()

export enum RECHARGE_ACTIVITY_TYPE {
  DEFAULT,
  NONE,
  RECHARGE_PACKAGE,
  RECHARGE_ACTIVITY,
}

type RechargePackageEntryProps = {
  floatBtnQueue?: FloatButton[]
}

const rechargeActivityV2 = isFeatureEnabled('rechargeActivityV2')
const rechargePackage = isFeatureEnabled('rechargePackage')
const rechargePackageV2 = isFeatureEnabled('rechargePackageV2')
const rechargePackageV3 = isFeatureEnabled('rechargePackageV3')

export default function useRechargePackageEntry({
  floatBtnQueue,
}: RechargePackageEntryProps) {
  const [user_id, token] = useUserAuth()
  const { rechargeList } = useRechargeActivity()
  const { actInfo } = useRechargeActEntry()

  const { open } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.RechargePackage],
  )
  const dispatch = useAppDispatch()

  const { data: rechargePackageList, refetch: refetchRechargePackageGiftList } =
    useRechargePackageGiftListQuery(undefined, {
      skip:
        !token ||
        !user_id ||
        (!rechargePackage && !rechargePackageV2 && !rechargePackageV3),
    })

  const { refetch: refetchRechargeTaskList } = useRechargeTaskListQuery({
    skip: !token || !user_id,
  })

  const rechargePackageInfo = rechargePackageList?.data?.[0]

  /** 充值礼包状态 */
  const isRechargePackage = useMemo(() => {
    return (
      rechargePackageInfo &&
      (!rechargePackageInfo.end_at ||
        (rechargePackageInfo.end_at &&
          rechargePackageInfo.end_at * 1000 > dayjs().valueOf()))
    )
  }, [rechargePackageInfo])

  /** 充值礼包3.0 判断return类型 */
  const judgeFloatBtnType = (curFloatBtn: FloatButton) => {
    if (
      curFloatBtn?.type === HOME_HOVER_BTN_TYPE.RECHARGE_ACTIVITY &&
      (rechargeList.length || !token || !user_id)
    ) {
      return RECHARGE_ACTIVITY_TYPE.RECHARGE_ACTIVITY
    }

    if (
      curFloatBtn?.type === HOME_HOVER_BTN_TYPE.RECHARGE_PACKAGE &&
      (isRechargePackage || !token || !user_id)
    ) {
      return RECHARGE_ACTIVITY_TYPE.RECHARGE_PACKAGE
    }
  }

  const type = useMemo(() => {
    if (
      (!rechargePackage && !rechargePackageV2) ||
      (rechargePackageV3 && !floatBtnQueue)
    ) {
      return RECHARGE_ACTIVITY_TYPE.NONE
    }

    /** 充值礼包3.0 */
    if (rechargePackageV3) {
      if (!floatBtnQueue) {
        return RECHARGE_ACTIVITY_TYPE.NONE
      }

      let step = floatBtnQueue?.length === 2 ? 1 : 2
      const curFloatBtn = floatBtnQueue?.[0]
      if (step === 1) {
        const res = judgeFloatBtnType(curFloatBtn)
        if (res) {
          return res
        }
        step = 2
      }

      if (step === 2) {
        return (
          judgeFloatBtnType(floatBtnQueue?.[1] || floatBtnQueue?.[0]) ||
          RECHARGE_ACTIVITY_TYPE.NONE
        )
      }
    }

    if (!token || !user_id) {
      if (rechargeActivityV2) {
        return RECHARGE_ACTIVITY_TYPE.NONE
      } else {
        return RECHARGE_ACTIVITY_TYPE.DEFAULT
      }
    }

    /** 在用户没有可参与的礼包活动时，看是不是有参与的充值活动 */
    if (rechargeActivityV2 && !rechargePackageInfo) {
      return RECHARGE_ACTIVITY_TYPE.NONE
    }

    if (
      rechargePackageInfo &&
      (!rechargePackageInfo.end_at ||
        (rechargePackageInfo.end_at &&
          rechargePackageInfo.end_at * 1000 > dayjs().valueOf()))
    ) {
      return RECHARGE_ACTIVITY_TYPE.RECHARGE_PACKAGE
    }
    if (rechargeList?.length) {
      return RECHARGE_ACTIVITY_TYPE.RECHARGE_ACTIVITY
    }
    return RECHARGE_ACTIVITY_TYPE.NONE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floatBtnQueue, rechargeList, rechargePackageInfo, token, user_id])

  const { days, hours, minutes, seconds, countDown } =
    useRechargePackageRewardCountdown(
      !isRechargePackage && (user_id || token)
        ? { stop: true }
        : {
            ...(rechargePackageList?.data?.[0]?.end_at
              ? { endTime: rechargePackageList?.data?.[0]?.end_at * 1000 }
              : {}),
          },
    )

  const handleModal = useCallback(
    (isRechargePackage?: boolean) => {
      if (!user_id || !token) {
        dispatch(
          openModal({
            key: GlobalModalKey.Login,
          }),
        )
        return
      }
      if (
        type === RECHARGE_ACTIVITY_TYPE.RECHARGE_PACKAGE ||
        isRechargePackage
      ) {
        dispatch(
          openModal({
            key: GlobalModalKey.RechargePackage,
            data: {
              rechargePackageInfo: rechargePackageList?.data?.[0],
            },
          }),
        )
        return
      }
      if (actInfo?.id) {
        dispatch(setRechargeActId(actInfo?.id || ''))
        dispatch(openModal({ key: GlobalModalKey.newRechargeActModal }))
      } else {
        dispatch(openModal({ key: GlobalModalKey.Wallet }))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type, rechargePackageList],
  )

  const totalHours = useMemo(() => {
    if (!Number(days)) {
      return hours
    }
    return Number(days || 0) * 24 + Number(hours)
  }, [hours, days])

  const rechargePackageRewardCenterInfo: RewardItem[] = useMemo(() => {
    return isRechargePackage
      ? [
          {
            icon: rechargePackageIcon,
            title: t('DEPOSIT_GIFT'),
            onJumpBtnClick: () => {
              handleModal(isRechargePackage)
            },
            onReceiveBtnClick: () => {
              handleModal(isRechargePackage)
            },
            canReceive: true,
            subArea: (
              <Box sx={BOX_STYLE}>
                <Text as='p' sx={TITLE_STYLE}>
                  {t('AVAILABLE_IN')}:
                </Text>
                <Text as='p' sx={NUMBER_STYLE} color='tert.500'>
                  {`${totalHours}:${minutes}:${seconds}`}
                </Text>
              </Box>
            ),

            rewardType: 'RECHARGE_PACKAGE',
          },
        ]
      : []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, minutes, hours, seconds, days, isRechargePackage])

  useEffect(() => {
    if (countDown <= 0 && rechargePackageList?.data?.[0]?.end_at) {
      if (open) {
        /** 如果弹窗时打开的需要打开提示弹窗 */
        dispatch(
          openModal({
            key: GlobalModalKey.ActTips,
            data: {
              title: 'HALLOWEENCOMOBOXDESCBOX1TITLE',
              content: 'EVENT_ENDED',
              okButtonText: 'OK_BUTTON',
              icon: TOMORROW_ICON,
              style: 'fail_status',
            },
          }),
        )
      }

      dispatch(closeModal({ key: GlobalModalKey.RechargePackage }))
      dispatch(setRechargePackageLoaded(false))

      refetchRechargePackageGiftList()
      refetchRechargeTaskList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDown, rechargePackageList?.data?.[0]?.end_at])

  useEffect(() => {
    if (
      rechargePackageInfo?.end_at &&
      rechargePackageInfo.end_at * 1000 <= new Date().getTime()
    ) {
      refetchRechargePackageGiftList()
      refetchRechargeTaskList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rechargePackageInfo])

  return {
    type,
    handleModal,
    rechargePackageRewardCenterInfo,
    countDown,
    rechargePackage: rechargePackageList?.data?.[0],
    hours,
    minutes,
    seconds,
  }
}

export function useRechargePackageRewardCountdown({
  endTime,
  stop,
}: {
  stop?: boolean
  endTime?: TDate
}) {
  const [rechargePackageRewardTargetDate, setRechargePackageRewardTargetDate] =
    useState(
      endTime ||
        localStorage.getItem('rechargePackageRewardTargetDate') ||
        DEFAULT_TARGET_DATEE,
    )

  const { days, hours, minutes, seconds, isFinished, countDown } =
    useAtomCountDown({
      targetDate: stop ? 0 : rechargePackageRewardTargetDate,
    })

  const onCountDownEnd = () => {
    if (
      !endTime &&
      !stop &&
      dayjs(rechargePackageRewardTargetDate) <= dayjs()
    ) {
      setRechargePackageRewardTargetDate(dayjs().add(30, 'minutes').format())
      localStorage.setItem(
        'rechargePackageRewardTargetDate',
        `${DEFAULT_TARGET_DATEE}`,
      )
    }
  }

  useEffect(() => {
    if (endTime) setRechargePackageRewardTargetDate(endTime)
  }, [endTime])

  useEffect(() => {
    localStorage.setItem(
      'rechargePackageRewardTargetDate',
      `${rechargePackageRewardTargetDate}`,
    )
  }, [rechargePackageRewardTargetDate])

  useEffect(() => {
    if (isFinished) {
      onCountDownEnd()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished, stop])

  return {
    days,
    hours,
    minutes,
    seconds,
    countDown,
    rechargePackageRewardTargetDate,
    isFinished,
  }
}
