import { Button } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const BaseNavigateButton = ({
  path,
  children,
}: {
  path?: string
  children: any
}) => {
  const navigator = useNavigate()

  return (
    <Button
      fontSize='14px'
      lineHeight='20px'
      fontWeight='700'
      border='1px'
      borderColor='prim.500'
      color='prim.500'
      size='md'
      bg={'transparent'}
      _active={{
        bg: 'prim.500',
      }}
      _hover={{
        borderColor: 'prim.500',
        color: 'prim.500',
      }}
      onClick={() => {
        if (path) {
          navigator(path)
        }
      }}
    >
      {children}
    </Button>
  )
}

export default BaseNavigateButton
