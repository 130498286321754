import type { ComponentDefaultProps, SystemStyleObject } from '@chakra-ui/react'
import { chakra, Flex, Image, Text } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import LazyImage from '@/components/lazy-image/LazyImage'

const CharkraGameMaintCover = chakra(Flex)

type GameCardHoverProps = ComponentDefaultProps & PropsWithChildren

export const MaintCover: FC<GameCardHoverProps> = ({ children }) => {
  const { t } = useTranslation()

  const innerStyles: SystemStyleObject = {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.10)',
    backdropFilter: 'blur(8.607725143432617px)',
    position: 'absolute',
    left: '0',
    top: '0',
    w: '100%',
    h: '100%',
    transitionProperty: 'all',
    transitionDuration: '0.1s',
    transitionTimingFunction: 'ease-in-out',
    zIndex: '1',
    textAlign: 'center',
    rowGap: '15px',
    cursor: 'pointer',
  }

  return (
    <CharkraGameMaintCover sx={innerStyles}>
      <LazyImage
        src={'/assets/images/games/maint-logo.png'}
        alt=''
        boxSize={'9'}
      />
      <Text textAlign={'center'} mt='-5px' cursor={'pointer'}>
        {t('MAINTENANCE_PIC_STATUS')}
      </Text>
    </CharkraGameMaintCover>
  )
}

export default MaintCover
