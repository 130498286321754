/**
 * 充值教程弹窗
 */
import { Box } from '@chakra-ui/react'
import { type FC, useCallback, useEffect, useState } from 'react'

import TitledDialog from '@/components/modal/TitledDialog'
import Loading from '@/routes/Loading'

import { useAppDispatch, useAppSelector } from '../app/store'
import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'

export const WalletTutorialModal: FC = () => {
  const { open, data } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.WalletTutorial],
  )
  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useAppDispatch()

  const onCloseModal = useCallback(() => {
    dispatch(
      closeModal({
        key: GlobalModalKey.WalletTutorial,
      }),
    )
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
  }, [])

  const handleIframeOnload = () => {
    setIsLoading(false)
  }
  return (
    <TitledDialog
      title={''}
      isOpen={open && !!data?.url}
      onClose={onCloseModal}
      motionPreset='none'
      size='6xl'
    >
      {isLoading && (
        <Box
          position='absolute'
          top={0}
          left={0}
          h='100%'
          w='100%'
          bgColor='var(--modal-bg)'
        >
          <Loading />
        </Box>
      )}
      <iframe
        onLoad={handleIframeOnload}
        style={{
          minHeight: '90vh',
          width: '100%',
        }}
        src={data?.url || ''}
      />
    </TitledDialog>
  )
}
