import { Box, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import cashBackIcon from '@/assets/images/rewardCenter/cashback.png'
import { useAppSelector } from '@/modules/app/store'
import { selectTypeMapRewardList } from '@/modules/new-vip/newVipSlice'
import { formatLocalMoney } from '@/utils/tools'

import type { RewardItem } from '../types'

export const BOX_STYLE = {
  display: 'flex',
  flexDirection: 'row',
  '@media screen and (max-width: 374px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  alignItems: 'flex-end',
}

export const TITLE_STYLE = {
  fontSize: '12px',
  fontWeight: '700',
  marginTop: '5px',
  flexShrink: 0,
  // flexGrow: 1,
  display: 'flex',
  alignItems: 'flex-start',
  color: 'rewardCenter.item.canReceiveSubTextColor',
}

export const NUMBER_STYLE = {
  fontSize: '14px',
  fontWeight: '800',
  lineHeight: '18px',
  flexShrink: '0',
  flexGrow: 1,
  marginLeft: '4px',
  '@media screen and (max-width: 374px)': {
    marginLeft: '0',
    marginTop: '5px',
  },
}
function useCashBack() {
  const navigate = useNavigate()
  const typeMapRewardList = useAppSelector(selectTypeMapRewardList)

  const cashBackRewardTotalAmount = useMemo(() => {
    const amount = (typeMapRewardList[4] || []).reduce((acc, cur) => {
      if (!cur.is_claim) {
        return acc + Number(cur.reward.amount)
      }
      return acc
    }, 0)
    return amount
  }, [typeMapRewardList])
  return {
    icon: cashBackIcon,
    title: t('CASHTITLE'),
    onJumpBtnClick: () => {
      navigate('/cashback?tab=myCashback')
    },
    onReceiveBtnClick: () => {
      navigate('/cashback?tab=myCashback')
    },
    canReceive: cashBackRewardTotalAmount ? true : false,
    subArea: (
      <Box sx={BOX_STYLE}>
        <Text as='p' sx={TITLE_STYLE}>
          {t('UNCLAIMED_BONUSES')}:
        </Text>
        <Text as='p' sx={NUMBER_STYLE} color={'tert.500'}>
          {formatLocalMoney(cashBackRewardTotalAmount || 0)}
        </Text>
      </Box>
    ),
    rewardType: 'CASH_BACK',
  } as RewardItem
}

export default useCashBack
