import { api } from '@/services/api'
import type { Response } from '@/services/types'

export enum HostTag {
  General = 't_general',
  Recaptcha = 't_recaptcha',
  Main = 't_main',
  Leader = 't_leader',
  LoginGoogle = 't_login_google',
  LoginFb = 't_login_fb',
  Share = 't_share',
  ShareEntry = 't_share_entry',
}

type GetHostsByTagResponse = Response<{
  tag_host_map: Partial<Record<HostTag, { list: string[] }>>
}>

type GetHostsByTagRequest = { tag_host_map: Partial<Record<HostTag, number>> }

export const multipleDomainsApi = api.injectEndpoints({
  endpoints: builder => ({
    getHostByTag: builder.query<GetHostsByTagResponse, GetHostsByTagRequest>({
      query: data => {
        return {
          url: '/gw/user-biz/get_host_by_tag',
          method: 'POST',
          data,
        }
      },
    }),
  }),
})

const { useGetHostByTagQuery } = multipleDomainsApi

export { useGetHostByTagQuery }
