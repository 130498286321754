import type { RechargeActivityProps } from '../wallet/interface'

export interface RechargeBtnProps {
  type: string
}
// 充值活动提示框
export interface tipsTypeMapProps {
  [key: string]: tipsTypeMapItem
}

export interface tipsTypeMapItem {
  title: string
  desc: string
  btn_text: string
  status:
    | 'success_status'
    | 'fail_status'
    | 'nochance_status'
    | 'tomorrow_status'
  icon: string
  style: 'success_status' | 'fail_status'
}
//充值活动弹框详情
export interface RechargeActDetail {
  list: RechargeActivityProps[]
  maxAmount: number
  minAmount: number
}
// 充值活动规则弹出层

export interface RechargeRule {
  isopen: boolean
  close: () => void
}
export interface RechargeActSlice {
  open: boolean
  tipsType: string
  openTips: boolean
  rechargeTyp: string
  detailRechargeAct: RechargeActDetail //充值活动弹框详情
  showRechargeTips: boolean
  rechargeAmount: string
  taskId: string | null
}
//选择渠道
export interface SelectWaysProps {
  icon?: string
  value?: string
  label?: string
}
// 充值按钮
export interface RechargeActBtnProps {
  type?: string //page ||modal
  isRechargeOptionEmpty?: boolean
  taskId: string
}
export enum TipsModalStatus {
  success = 'success',
  fail = 'fail',
  nochance = 'nochance',
  tomorrow = 'tomorrow',
  // success  fail nochance tomorrow
}
// | 'success_status'
//   | 'fail_status'
//   | 'nochance_status'
//   | 'tomorrow_status'
