//充值活动提示弹框
import { Button, Image, Text, VStack } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import TitledDialog from '@/components/modal/TitledDialog'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import RechargeActStyle from '@/websites/current/styles/components/RechargeAct.json'

import { RECHARGE_STATUS_MAP } from './constants'
import { getTipsOpen, getTipsType, setTipsInfo } from './rechargeActSlice'
import { TipsModalStatus } from './type'

export default function RechargeActTips() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const type = useAppSelector(getTipsType)
  const isopen = useAppSelector(getTipsOpen)
  const info = useMemo(() => {
    return RECHARGE_STATUS_MAP[type]
  }, [type])
  const onClick = () => {
    onClose()
    type === 'nochance' && navigate('/game/all?cate=-1&label=-1')
  }
  const onClose = () => {
    sessionStorage.removeItem('recahrge_act_time')
    dispatch(
      dispatch(
        setTipsInfo({
          open: false,
          type: TipsModalStatus.success,
        }),
      ),
    )
  }

  const rechargeTime = useMemo(() => {
    if (isopen) {
      return sessionStorage.getItem('recahrge_act_time') as unknown as number
    }
    return 0
  }, [isopen])
  // 1693814377065
  return (
    <TitledDialog
      isOpen={isopen}
      onClose={onClose}
      colorScheme='multilayer'
      size={{ base: 'md', md: 'md' }}
      title={
        <Text
          {...RechargeActStyle.rechargTipsText}
          {...RechargeActStyle?.[info?.style]}
        >
          {t(info.title)}
        </Text>
      }
    >
      <VStack>
        <Image src={info.icon} w='110px' height='auto' />
        <Text {...RechargeActStyle.rechargTipsText}>
          {t(info.desc, {
            time: dayjs(Number(rechargeTime)).format('YYYY-MM-DD HH:mm:ss'),
          }).toString()}
        </Text>
        <Button
          colorScheme='fill-seco'
          size='lg'
          w='full'
          onClick={onClick}
          _hover={{
            colorScheme: 'fill-seco',
          }}
        >
          {t(info.btn_text)}
        </Button>
      </VStack>
    </TitledDialog>
  )
}
