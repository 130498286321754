import {
  Box,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverTrigger,
  useStyleConfig,
} from '@chakra-ui/react'
import type { SystemStyleObject } from '@chakra-ui/styled-system'
import { isEmpty } from 'lodash-es'
import type {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  PropsWithChildren,
  ReactNode,
} from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { NAME } from '@/components/atom-select/AtomSelect'
import ResizeDetector from '@/components/resize-detector/ResizeDetector'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useToggleState } from '@/utils/hooks'

import { CustomizedFormErrorMessage } from './CustomizedFormErrorMessage'
import EmailTipsPopoverContent from './EmailTips'

export type LoginFormInputProps = {
  hidden?: boolean
  errorMsg?: string
  disabled?: boolean
  value: string
  handleValueChange: ChangeEventHandler<HTMLInputElement>
  label?: ReactNode | string
  addonBefore?: ReactNode | string
  addonAfter?: ReactNode | string
  fieldProps: {
    type?: 'number' | 'text' | 'password'
    name: string
    placeholder?: string
    autoComplete?: string
  }
  autoComplete?: Array<`@${string}`>
  openJudge?: () => boolean
  addonBeforeWidth?: number
}

interface PopItemProps {
  value: string
  onClick?: (value: string) => void
}

interface Styles {
  option: {
    _hover: SystemStyleObject
    _selected: SystemStyleObject
  }
}

const PopItem: FC<PropsWithChildren<PopItemProps>> = ({
  children,
  value,
  onClick,
}) => {
  const { option } = useStyleConfig(NAME) as Styles

  const handleClick = useCallback(() => {
    onClick && onClick(value)
  }, [onClick, value])

  return (
    <Box
      h='30px'
      lineHeight='30px'
      fontSize='14px'
      _hover={option._hover}
      _selected={option._selected}
      cursor='pointer'
      padding='0 10px'
      layerStyle='text-omit'
      flexShrink={0}
      onMouseDown={handleClick}
    >
      {children}
    </Box>
  )
}

export function AutoCompleteInput({
  hidden = false,
  errorMsg,
  disabled,
  value,
  handleValueChange,
  label,
  addonBefore,
  addonAfter,
  fieldProps,
  autoComplete,
  openJudge,
  addonBeforeWidth,
}: LoginFormInputProps) {
  const [open, setOpen] = useState(false)
  const inputRef = useRef<HTMLInputElement>()
  const [displayPwd, _setDisplayPwd, togglePwdDisplay] = useToggleState(false)

  const autoItems = useMemo(() => {
    if (!autoComplete) {
      return []
    }

    const splitArr = value?.split('@')
    const doReg = splitArr?.length > 1
    const valueWithoutSuffix = splitArr?.[0]
    const emailSuffix = doReg ? splitArr?.[1] : ''

    const items = doReg
      ? autoComplete.filter(suffix => suffix.startsWith('@' + emailSuffix))
      : autoComplete

    return items.map(suffix => {
      const uniqueValue = doReg
        ? `${valueWithoutSuffix}${suffix}`
        : `${value}${suffix}`
      return (
        <PopItem
          key={suffix}
          value={uniqueValue}
          onClick={v => {
            handleValueChange({
              target: {
                value: v,
              },
            } as ChangeEvent<HTMLInputElement>)
          }}
        >
          {uniqueValue}
        </PopItem>
      )
    })
  }, [value, autoComplete, handleValueChange])

  const isOpen = useMemo(() => {
    if (!autoComplete) return false

    if (!openJudge) return open && !isEmpty(value) && autoItems.length > 0
    return open && !isEmpty(value) && autoItems.length > 0 && openJudge()
  }, [openJudge, open, value, autoItems.length, autoComplete])

  const isInvalid = useMemo(() => !!errorMsg, [errorMsg])

  const handleBlur = () => {
    setOpen(false)
  }

  return (
    <Popover isOpen={isOpen} initialFocusRef={inputRef as any} strategy='fixed'>
      <FormControl
        hidden={hidden}
        isInvalid={isInvalid}
        isDisabled={!!disabled}
        minH={label ? '92px' : '64px'}
      >
        {label && (
          <FormLabel color='gray.700' htmlFor=''>
            {label}
          </FormLabel>
        )}
        <PopoverTrigger>
          <InputGroup>
            {addonBefore && (
              <InputLeftElement
                pointerEvents='none'
                {...(addonBeforeWidth ? { w: `${addonBeforeWidth}px` } : {})}
              >
                {addonBefore}
              </InputLeftElement>
            )}

            <Input
              {...fieldProps}
              value={value}
              onChange={handleValueChange}
              {...(fieldProps?.type === 'password'
                ? {
                    type: displayPwd ? 'text' : 'password',
                  }
                : {})}
              ref={inputRef as any}
              onFocus={() => {
                setOpen(true)
              }}
              onBlur={handleBlur}
              autoComplete='off'
              {...(addonBeforeWidth ? { pl: `${addonBeforeWidth}px` } : {})}
            />

            {addonAfter ||
              (fieldProps?.type === 'password' && (
                <InputRightElement>
                  {fieldProps?.type === 'password' ? (
                    <Icon
                      boxSize='5'
                      cursor='pointer'
                      as={displayPwd ? SharedIcon.Display : SharedIcon.Hide}
                      color={displayPwd ? 'prim.500' : 'gray.600'}
                      onClick={togglePwdDisplay}
                    />
                  ) : (
                    addonAfter
                  )}
                </InputRightElement>
              ))}
          </InputGroup>
        </PopoverTrigger>
        {errorMsg && (
          <CustomizedFormErrorMessage>{errorMsg}</CustomizedFormErrorMessage>
        )}
      </FormControl>
      {autoComplete && (
        <ResizeDetector>
          {({ width }: { width: number }) => {
            return (
              <EmailTipsPopoverContent width={width}>
                {autoItems}
              </EmailTipsPopoverContent>
            )
          }}
        </ResizeDetector>
      )}
    </Popover>
  )
}

export function LoginFormInput(props: LoginFormInputProps) {
  return <AutoCompleteInput {...(props as LoginFormInputProps)} />
}
