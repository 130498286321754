import { Flex, Image } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMemo } from 'react'

import warningPng from '@/assets/images/notification/warning.png'
import { getCompatibleColors } from '@/utils/tools'
import { projectName } from '@/websites/current/property.json'

import useReadNotification from '../../hooks/useReadNotification'
import type { Message } from '../../types'
import NewMessageContent from './NewMessageContent'
import NewMessageContext from './NewMessageContext'
import NewMessageEvent from './NewMessageEvent'
import NewMessageIcon from './NewMessageIcon'
import NewMessageStatusIcon from './NewMessageStatusIcon'

// const getNotificationColor = () => {
//   const bgColorsMap = {
//     M1: 'rgba(34, 44, 61, 1)',
//   } as Record<string, string>

//   return bgColorsMap[projectName] ?? bgColorsMap['M1']
// }

function NewMessage({ message }: { message: Message }) {
  const isRead = message.is_read

  const isExpire = useMemo(() => {
    const expireTimestamp = dayjs(
      message?.created_at ? message.created_at * 1000 : '',
    ).add(message?.rewards?.expire_day || 0, 'day')

    const nowTimestamp = dayjs().valueOf()
    if (expireTimestamp.valueOf() <= nowTimestamp) {
      return true
    }
    return false
  }, [message])

  const { handleMarkMessageAsRead } = useReadNotification()
  return (
    <NewMessageContext.Provider
      value={{
        message,
      }}
    >
      <Flex
        gap='3'
        borderRadius='8px'
        w='full'
        bg={getCompatibleColors('notification.bg', '#222c3d')}
        p='12px'
        alignItems='flex-start'
        position='relative'
        cursor='pointer'
        onClick={() => {
          NewMessageEvent.emit('openMessageDetail', message)
          handleMarkMessageAsRead(message)
        }}
      >
        <NewMessageIcon />
        <NewMessageContent />
        {!isRead ? <NewMessageStatusIcon /> : null}
        {!!(
          isRead &&
          message?.rewards?.num &&
          !isExpire &&
          !message.is_claim_rewards
        ) && (
          <Image
            src={warningPng}
            w='16px'
            h='16px'
            position='absolute'
            top='12px'
            right='12px'
          />
        )}
      </Flex>
    </NewMessageContext.Provider>
  )
}

export default NewMessage
