import type { SystemStyleObject } from '@chakra-ui/react'
import {
  Box,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useStyleConfig,
} from '@chakra-ui/react'
import { isEmpty } from 'lodash-es'
import type {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  PropsWithChildren,
  ReactNode,
} from 'react'
import { useCallback, useMemo, useRef, useState } from 'react'

import { NAME } from '@/components/atom-select/AtomSelect'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useToggleState } from '@/utils/hooks'

import EmailTipsPopoverContent from './components/EmailTips'
import { CustomizedFormErrorMessage } from './LoginForm'

export type LoginFormInputProps = {
  valueValid?: boolean
  errorMsg?: string
  disabled?: boolean
  value: string
  handleValueChange: ChangeEventHandler<HTMLInputElement>
  label?: string
  addonBefore?: ReactNode | string
  addonAfter?: ReactNode | string
  validator?: {
    valueValid: boolean
    errorMsg: string | JSX.Element | null
  }
  fieldProps: {
    type: string | 'password'
    name: string
    placeholder?: string
    autoComplete?: string
  }

  autoComplete: Array<`@${string}`>
  openJudge?: () => boolean
  onBlur?: () => void

  addonBeforeWidth?: string
}

export function AutoCompleteInput({
  validator,
  disabled,
  value,
  handleValueChange,
  label,
  addonBefore,
  addonAfter,
  fieldProps,
  autoComplete,
  onBlur,
  openJudge,
  addonBeforeWidth = 'var(--input-height)',
}: LoginFormInputProps) {
  const [open, setOpen] = useState(false)
  const inputRef = useRef<HTMLInputElement>()
  const [displayPwd, _setDisplayPwd, togglePwdDisplay] = useToggleState(false)

  const autoItems = useMemo(() => {
    const splitArr = value.split('@')
    const doReg = splitArr.length > 1
    const valueWithoutSuffix = splitArr[0]
    const emailSuffix = doReg ? splitArr[1] : ''

    const items = doReg
      ? autoComplete.filter(suffix => suffix.startsWith('@' + emailSuffix))
      : autoComplete

    return items.map(suffix => {
      const uniqueValue = doReg
        ? `${valueWithoutSuffix}${suffix}`
        : `${value}${suffix}`
      return (
        <PopItem
          key={suffix}
          value={uniqueValue}
          onClick={v => {
            handleValueChange({
              target: {
                value: v,
              },
            } as ChangeEvent<HTMLInputElement>)
          }}
        >
          {uniqueValue}
        </PopItem>
      )
    })
  }, [value, autoComplete, handleValueChange])

  const isOpen = useMemo(() => {
    if (!openJudge) return open && !isEmpty(value) && autoItems.length > 0
    return open && !isEmpty(value) && autoItems.length > 0 && openJudge()
  }, [openJudge, open, value, autoItems.length])

  const isInvalid = useMemo(
    () => validator && !validator.valueValid,
    [validator],
  )

  const handleBlur = () => {
    setOpen(false)
    if (!isInvalid) onBlur && onBlur()
  }

  return (
    <Popover isOpen={isOpen} initialFocusRef={inputRef as any}>
      <FormControl isInvalid={isInvalid} isDisabled={!!disabled} minH='64px'>
        {label && (
          <FormLabel color='gray.700' htmlFor=''>
            {label}
          </FormLabel>
        )}
        <PopoverTrigger>
          <InputGroup>
            {addonBefore && (
              <InputLeftElement pointerEvents='none' w={addonBeforeWidth}>
                {addonBefore}
              </InputLeftElement>
            )}

            <Input
              {...fieldProps}
              value={value}
              onChange={handleValueChange}
              {...(fieldProps?.type === 'password'
                ? {
                    type: displayPwd ? 'text' : 'password',
                  }
                : {})}
              ref={inputRef as any}
              onFocus={() => {
                setOpen(true)
              }}
              onBlur={handleBlur}
              autoComplete='off'
              pl={addonBefore ? addonBeforeWidth : 'auto'}
            />

            {addonAfter ||
              (fieldProps?.type === 'password' && (
                <InputRightElement>
                  {fieldProps?.type === 'password' ? (
                    <Icon
                      w='5'
                      cursor='pointer'
                      as={displayPwd ? SharedIcon.Display : SharedIcon.Hide}
                      color={displayPwd ? 'prim.500' : 'gray.600'}
                      onClick={togglePwdDisplay}
                    />
                  ) : (
                    addonAfter
                  )}
                </InputRightElement>
              ))}
          </InputGroup>
        </PopoverTrigger>
        {validator?.errorMsg && typeof validator.errorMsg === 'string' ? (
          <CustomizedFormErrorMessage isInvalid={!validator.valueValid}>
            {validator.errorMsg}
          </CustomizedFormErrorMessage>
        ) : (
          validator?.errorMsg
        )}
      </FormControl>
      <EmailTipsPopoverContent>{autoItems}</EmailTipsPopoverContent>
    </Popover>
  )
}

export function NormalInput({
  validator,
  disabled,
  value,
  handleValueChange,
  label,
  addonBefore,
  addonAfter,
  fieldProps,
  addonBeforeWidth = 'var(--input-height)',
  ...props
}: Omit<LoginFormInputProps, 'autoComplete'>) {
  const [displayPwd, _setDisplayPwd, togglePwdDisplay] = useToggleState(false)

  return (
    <FormControl
      isInvalid={validator && !validator.valueValid}
      isDisabled={!!disabled}
      minH='64px'
    >
      {label && (
        <FormLabel ml='6px' color='gray.700' htmlFor=''>
          {label}
        </FormLabel>
      )}
      <InputGroup>
        {addonBefore && (
          <InputLeftElement w={addonBeforeWidth} pointerEvents='none'>
            {addonBefore}
          </InputLeftElement>
        )}

        <Input
          {...props}
          {...fieldProps}
          value={value}
          onChange={handleValueChange}
          {...(fieldProps?.type === 'password'
            ? {
                type: displayPwd ? 'text' : 'password',
              }
            : {})}
          autoComplete='new-password'
          pl={addonBefore ? addonBeforeWidth : 'auto'}
        />
        {addonAfter ||
          (fieldProps?.type === 'password' && (
            <InputRightElement>
              {fieldProps?.type === 'password' ? (
                <Icon
                  boxSize='5'
                  cursor='pointer'
                  as={displayPwd ? SharedIcon.Display : SharedIcon.Hide}
                  color={displayPwd ? 'prim.500' : 'gray.600'}
                  onClick={togglePwdDisplay}
                />
              ) : (
                addonAfter
              )}
            </InputRightElement>
          ))}
      </InputGroup>
      {validator?.errorMsg && typeof validator.errorMsg === 'string' ? (
        <CustomizedFormErrorMessage isInvalid={!validator.valueValid}>
          {validator.errorMsg}
        </CustomizedFormErrorMessage>
      ) : (
        validator?.errorMsg
      )}
    </FormControl>
  )
}

interface PopItemProps {
  value: string
  onClick?: (value: string) => void
}

interface Styles {
  option: {
    _hover: SystemStyleObject
    _selected: SystemStyleObject
  }
}

const PopItem: FC<PropsWithChildren<PopItemProps>> = ({
  children,
  value,
  onClick,
}) => {
  const { option } = useStyleConfig(NAME) as Styles

  const handleClick = useCallback(() => {
    onClick && onClick(value)
  }, [onClick, value])

  return (
    <Box
      h='30px'
      lineHeight='30px'
      fontSize='14px'
      _hover={option._hover}
      _selected={option._selected}
      cursor='pointer'
      padding='0 10px'
      layerStyle='text-omit'
      flexShrink={0}
      onMouseDown={handleClick}
    >
      {children}
    </Box>
  )
}

export default PopItem
