import { useCallback } from 'react'

import { getVariable } from '@/common/env'

import { useAppSelector } from '../app/store'
import type { WithdrawalMethodDetail } from './interface'
import { handlePIX } from './utils'
import { getSelectLabelByItem } from './walletContansts'
import {
  getUseSaveInfoList,
  selectActivedQuickPayChannel,
  selectActivedQuickPayMethodId,
} from './walletSlice'

const RECHARGE_ERROR_TIPS = getVariable('RECHARGE_ERROR_TIPS')

export const useSaveList = () => {
  //快捷提款id
  const activedQuickPayMethodId = useAppSelector(selectActivedQuickPayMethodId)

  const activedQuickPayChannel = useAppSelector(selectActivedQuickPayChannel)

  //用户提款信息
  const saveList = useAppSelector(getUseSaveInfoList).map(
    (item: WithdrawalMethodDetail) => {
      return {
        ...item,
        value: item?.id,
        label: RECHARGE_ERROR_TIPS
          ? handlePIX(getSelectLabelByItem(item).title)
          : getSelectLabelByItem(item).name,
      }
    },
  )
  //快捷充值提现参数
  function getWithdrawParamsById() {
    if (activedQuickPayMethodId) {
      const item = saveList.find((item: { [key: string]: string }) => {
        return (
          item.id === activedQuickPayMethodId &&
          item?.pay_method === activedQuickPayChannel
        )
      })
      return {
        method: item?.pay_method,
        pay_method: 'id',
        pay_method_id: `${activedQuickPayMethodId}`,
      }
    }
    return null
  }
  const getInfoById = useCallback(
    (id?: string) => {
      const info_id = String(id || activedQuickPayMethodId)
      return saveList.find(
        (item: { [key: string]: string }) => String(item.id) === info_id,
      )
    },
    [saveList, activedQuickPayMethodId],
  )
  return {
    saveList,
    hasSaveList: saveList.length > 0,
    getWithdrawParamsById,
    getInfoById,
  }
}
