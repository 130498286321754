import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Text,
  useClipboard,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import BuiltInAvatar from '@/components/built-in-avatar/BuiltInAvatar'
import { useAppSelector } from '@/modules/app/store'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { selectAvatarId, selectUserName } from '../../userSlice'
import { UserProfilePopoverContext } from './NewUserProfilePopover'

function PopoverHeader() {
  const { t } = useTranslation()
  const userName = useAppSelector(selectUserName)
  const avatarId = useAppSelector(selectAvatarId)
  const { onClose } = useContext(UserProfilePopoverContext)
  const { onCopy } = useClipboard(userName)
  const navigate = useNavigate()
  const toast = useToast()

  const onAvatarClick = useCallback(() => {
    onClose()
    navigate('/user/profile')
  }, [navigate, onClose])

  return (
    <Box
      p='8px'
      borderBottom='none'
      display='flex'
      alignItems='center'
      gap='16px'
      mb="16px"
    >
      <Button variant='unstyled' onClick={onAvatarClick}>
        <BuiltInAvatar avatarId={avatarId} boxSize='56px' />
      </Button>

      <Box
        h='24px'
        display='flex'
        alignItems='center'
        overflow='hidden'
        flexGrow='1'
      >
        <Text
          color='personalCenter.primText'
          fontWeight='700'
          fontSize='16px'
          lineHeight='24px'
          overflow='hidden'
          textOverflow='ellipsis'
          flexGrow='1'
          style={{
            textWrap: 'nowrap',
          }}
        >
          {userName}
        </Text>

        <IconButton
          variant='ghost'
          icon={<Icon boxSize={4} as={SharedIcon.Copy} color="personalCenter.iconColor" />}
          aria-label='copy'
          width={'24px'}
          height={'24px'}
          minW={'24px'}
          onClick={() => {
            onCopy()
            toast({
              title: t('COMMON_CONTENT_CANCEL'),
              status: 'success',
            })
          }}
        />
      </Box>
    </Box>
  )
}
export default PopoverHeader
