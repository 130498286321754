import React from 'react'
import { useAppSelector } from '../app/store'
import { selectUserIsLogin } from '../user/userSlice'

function withIsUserLogin<P extends object>(
    WrappedComponent: React.ComponentType<P>
  ): React.FC<P> {
    const WithLoading: React.FC<P> = (props) => {
        const isUserLogin = useAppSelector(selectUserIsLogin)
      if (!isUserLogin) {
        return <></>;
      }
      
      return <WrappedComponent {...props as P} />;
    };
    
    return WithLoading;
  }
export default withIsUserLogin
