import type { Drops } from './Drops'
import { Ball, CaneCandy, GiftBox, GoldBall, Snowflakes } from './Drops'

export default class SnowSence {
  ctx: CanvasRenderingContext2D
  canvasElement: HTMLCanvasElement
  snows: Drops[] = []

  constructor(ctx: CanvasRenderingContext2D, canvasElement: HTMLCanvasElement) {
    this.ctx = ctx
    this.canvasElement = canvasElement
    this.snows = [
      new CaneCandy(ctx, canvasElement),
      new CaneCandy(ctx, canvasElement),
      new Ball(ctx, canvasElement),
      new Snowflakes(ctx, canvasElement),
      new Snowflakes(ctx, canvasElement),
      new GiftBox(ctx, canvasElement),
      new GiftBox(ctx, canvasElement),
      new Ball(ctx, canvasElement),
      new GoldBall(ctx, canvasElement),
      new Snowflakes(ctx, canvasElement),
      new Snowflakes(ctx, canvasElement),
      new Snowflakes(ctx, canvasElement),
    ]
  }

  nextFrame() {
    const { width, height } = this.canvasElement
    this.ctx.clearRect(0, 0, width, height)
    this.snows.forEach(snow => snow.update())
    this.snows.forEach(snow => snow.draw())
  }
}
