import { useDimensions } from '@chakra-ui/react'
import { useMemo } from 'react'

interface AdaptableFontSizeOptions {
  minStrLen?: number
  maxFontSize?: number
  minFontSize?: number
  containerRef: React.RefObject<HTMLElement>
}

export function useAdaptableFontSize(
  text: string,
  {
    minStrLen = 9,
    maxFontSize = 18,
    minFontSize = 12,
    containerRef,
  }: AdaptableFontSizeOptions,
) {
  const dimensions = useDimensions(containerRef, true)
  const inputContentBoxWidth = dimensions?.contentBox.width ?? 0
  const inputFontSize = useMemo(() => {
    const gap = maxFontSize - minFontSize
    if (minStrLen < 0 || inputContentBoxWidth / minStrLen > maxFontSize) {
      return maxFontSize
    } else {
      const factor = 0.2
      const diffStrLen = Math.max(0, text.length - minStrLen)
      return maxFontSize - Math.round(Math.min(diffStrLen * factor, 1) * gap)
    }
  }, [text, inputContentBoxWidth, minStrLen, maxFontSize, minFontSize])
  return inputFontSize
}
