import { useMemo } from 'react'

import { getVariable } from '@/common/env'
import { isFeatureEnabled } from '@/common/feature'
import { getCurrencyCode } from '@/common/locale/currency'
import type {
  RechargeCfg,
  RechargeGrid,
  RewardCfg,
} from '@/modules/activity/ActInterface'
import {
  selectRechargeActId,
  selectRechargeActList,
} from '@/modules/activity/ActSlice'
import { useAppSelector } from '@/modules/app/store'
import type {
  RechargSelectProps,
  WalletConfigProps,
} from '@/modules/wallet/interface'
import type { AmountRechargeProp } from '@/modules/wallet/walletContansts'
import type { RechargeQuickerBtnConfigProps } from '@/modules/wallet/walletInterface'
import {
  getRechargePaymentOptions,
  selectWallet,
  selectWalletConfig,
  selectWalletDurationConfig,
} from '@/modules/wallet/walletSlice'
import walletConfig from '@/websites/current/public/config/wallet.json'

/**
 * 本地默认的按钮展示规则
 */
const DEFAULT_BT_CONFIG = {
  btn_non_participation: 1,
  btn_num: 3,
}

const _localWalletConfig = walletConfig as Record<string, any>

/**
 * 默认提现时间
 * @param currency
 * @returns
 */

const DEFAULT_WITHDRAW_TIME = {
  start_withdraw_time: '00:00',
  end_withdraw_time: '23:59',
}

const isBottomRecommend = isFeatureEnabled('bottomRecommend')

export function getLocalWalletConfig(currency?: string) {
  // 传递currency主要解决大代理提现切换货币
  const CURRENCY = currency || getCurrencyCode()
  const currenctWalletConfig =
    _localWalletConfig?.[CURRENCY] || _localWalletConfig?.['DEFAULT']

  const MIN_RECHARGE_AMOUNT = currenctWalletConfig.MIN_RECHARGE_AMOUNT
  const MAX_RECHARGE_AMOUNT = currenctWalletConfig.MAX_RECHARGE_AMOUNT
  const MIN_WITHDRAW_AMOUNT = currenctWalletConfig.MIN_WITHDRAW_AMOUNT
  const MAX_WITHDRAW_AMOUNT = currenctWalletConfig.MAX_WITHDRAW_AMOUNT
  const DEFAULT_AMOUNT = currenctWalletConfig.DEFAULT_RECHARGE_AMOUNT
  const DEFAULT_INDEX = currenctWalletConfig.DEFAULT_RECHARGE_INDEX
  const RECHARGE_REBATE = currenctWalletConfig.RECHARGE_REBATE
  const AMOUNT_LIST = currenctWalletConfig.AMOUNT_LIST as AmountRechargeProp[]
  return {
    MIN_RECHARGE_AMOUNT,
    MAX_RECHARGE_AMOUNT,
    MIN_WITHDRAW_AMOUNT,
    MAX_WITHDRAW_AMOUNT,
    DEFAULT_AMOUNT,
    DEFAULT_INDEX,
    AMOUNT_LIST,
    RECHARGE_REBATE,
    ...DEFAULT_WITHDRAW_TIME,
    BTN_CONFIG: DEFAULT_BT_CONFIG,
  }
}

/** 获取快捷充值金额 */
export const useAmountList = (
  remoteWalletConfig: WalletConfigProps,
  rechargeType: string,
  min_recharge: number,
  rechargeActInfo?: {
    is_from_recharge_act: boolean
    fixedAmountList: RechargeGrid[]
  },
) => {
  const { all_recharge_quicker_btn_config, recharge_btn_rule_config } =
    remoteWalletConfig
  let amount_list = [] as RechargeQuickerBtnConfigProps[]
  all_recharge_quicker_btn_config.forEach(item => {
    if (item?.status === 1) {
      amount_list.push({
        ...item,
        value: item?.currency_content?.[getCurrencyCode()]?.btn_amount || 0,
        isHot: item?.is_hot === 1,
      })
    }
  })

  if (isBottomRecommend) {
    // 增加了渠道选择
    amount_list = amount_list?.filter(item => {
      return item?.recharge_channel?.includes(rechargeType)
    })
  }

  let hotList = []
  hotList = amount_list
    .filter(item => !!item.isHot)
    .map(item => {
      return Number(item.value)
    })
    .filter(item => item > 0)

  hotList.length === 0 && hotList.push(min_recharge)

  // 先取hot中最小的，再去最低充值金额中最大的
  let DEFAULT_AMOUNT = Math.max(min_recharge, Math.min(...hotList))

  if (isBottomRecommend) {
    const { is_channel, channel } = recharge_btn_rule_config
    if (is_channel === 1) {
      DEFAULT_AMOUNT =
        Number(channel?.all_channel || 0) || amount_list?.[0]?.value || 0
    } else {
      DEFAULT_AMOUNT =
        Number(channel?.[rechargeType] || 0) || amount_list?.[0]?.value || 0
    }
  }

  /** ================== 新的充值活动配置 =================== */
  if (rechargeActInfo?.is_from_recharge_act) {
    hotList = []
    const { fixedAmountList } = rechargeActInfo || {}
    amount_list = fixedAmountList?.map((item, index) => {
      if (item.is_default) {
        DEFAULT_AMOUNT = item.recharge_amount
      }
      if (item.icon) {
        hotList.push(item.recharge_amount)
      }
      return {
        ...item,
        isHot: item.icon,
        index,
        value: item.recharge_amount,
      }
    })
  }

  const DEFAULT_INDEX = amount_list.findIndex(
    item => Number(item.value) === Number(DEFAULT_AMOUNT),
  )

  return {
    amount_list,
    DEFAULT_AMOUNT,
    DEFAULT_INDEX,
  }
}

/** 计算最大最小充值金额 */
export const useRechargeChannelAmount = (
  rechargeOptions: RechargSelectProps[],
  min_recharge: number,
  max_recharge: number,
  // 来自新的充值活动
  rechargeActInfo?: {
    is_from_recharge_act: boolean
    curAct: any
    CURRENCY: string
  },
) => {
  const MIN_RECHARGE = {} as Record<string, number | string>
  const MAX_RECHARGE = {} as Record<string, number | string>
  if (isBottomRecommend) {
    // 后台设置的渠道充值范围
    rechargeOptions?.forEach(item => {
      const value = item?.value || 'DEFAULT'
      MIN_RECHARGE[value as string] = Number(item.min_recharge) || min_recharge
      MAX_RECHARGE[value as string] = Number(item.max_recharge) || max_recharge
    })
  }

  /** 来自新的充值活动 */
  if (rechargeActInfo?.is_from_recharge_act) {
    const { curAct, CURRENCY } = rechargeActInfo || {}
    const { reward_type, reward_fixed } = (curAct?.reward_config?.[CURRENCY] ||
      {}) as RewardCfg
    if ([0, 1].includes(reward_type)) {
      // 固定奖励: 最小值取奖励配置的最小值，最大值取平台设置的最大值
      const { min_recharge_amount } = reward_fixed || {}
      MIN_RECHARGE.DEFAULT = min_recharge_amount
    } else {
      // 梯度奖励：最大值取奖励设置的最大值，最下值取平台设置的最小值
      const { max_recharge_amount } = (curAct?.recharge_config?.[CURRENCY] ||
        {}) as RechargeCfg
      MAX_RECHARGE.DEFAULT = max_recharge_amount
    }
  }

  return {
    MIN_RECHARGE,
    MAX_RECHARGE,
  }
}

export const useWalletConfig = (
  currency?: string,
  actCfg?: {
    is_from_recharge_act: boolean
  },
): { [key: string]: any } => {
  const rechargeType = useAppSelector(
    state => selectWallet(state).rechargeParams?.typ,
  )

  const rechargeOptions = useAppSelector(getRechargePaymentOptions)
  const CURRENCY = currency || getCurrencyCode()

  const localWalletConfig = useMemo(
    () => getLocalWalletConfig(currency),
    [currency],
  )
  const remoteWalletConfig = useAppSelector(selectWalletConfig)
  const remoteWalletDurationConfig = useAppSelector(selectWalletDurationConfig)
  /** ********* 关于新充值活动 ************ */
  const actId = useAppSelector(selectRechargeActId)
  const rechargeActList = useAppSelector(selectRechargeActList)
  const { fixedAmountList, curAct } = useMemo(() => {
    const info = rechargeActList.find(item => Number(item.id) === Number(actId))
    let amountList = (info?.recharge_config?.[CURRENCY]?.recharge_elem ||
      []) as RechargeGrid[]
    amountList = amountList
      .filter(item => item.recharge_amount > 0)
      .sort((a, b) => a.recharge_amount - b.recharge_amount)

    return { fixedAmountList: amountList, curAct: info }
  }, [actId, rechargeActList, CURRENCY])

  // 平台设置的最小充值金额
  const min_recharge = remoteWalletDurationConfig?.min_recharge || 0
  const max_recharge = remoteWalletDurationConfig?.max_recharge || 0
  const { MIN_RECHARGE, MAX_RECHARGE } = useRechargeChannelAmount(
    rechargeOptions,
    min_recharge,
    max_recharge,
    {
      is_from_recharge_act: !!actCfg?.is_from_recharge_act,
      curAct,
      CURRENCY,
    },
  )
  const { amount_list, DEFAULT_AMOUNT, DEFAULT_INDEX } = useAmountList(
    remoteWalletConfig,
    rechargeType,
    min_recharge,
    {
      is_from_recharge_act: !!actCfg?.is_from_recharge_act,
      fixedAmountList,
    },
  )

  const WalletConfigMemo = useMemo(() => {
    return {
      ...localWalletConfig,
      AMOUNT_LIST: amount_list,
      BTN_CONFIG: remoteWalletConfig?.recharge_btn_rule_config || {},
      MIN_RECHARGE_AMOUNT: {
        DEFAULT: min_recharge,
        ...MIN_RECHARGE,
      },
      DEFAULT_AMOUNT,
      DEFAULT_INDEX,
      // 以下不需要修改
      MIN_WITHDRAW_AMOUNT: {
        DEFAULT: remoteWalletDurationConfig?.min_withdraw || 0,
      },
      MAX_RECHARGE_AMOUNT: {
        DEFAULT: max_recharge,
        ...MAX_RECHARGE,
      },
      MAX_WITHDRAW_AMOUNT: {
        DEFAULT: remoteWalletDurationConfig?.max_withdraw || 0,
      },
      start_withdraw_time:
        remoteWalletDurationConfig?.start_withdraw_time || '',
      end_withdraw_time: remoteWalletDurationConfig?.end_withdraw_time || '',
    }
  }, [rechargeType, remoteWalletDurationConfig, remoteWalletConfig])

  return WalletConfigMemo
}

export default useWalletConfig
