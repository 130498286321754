import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { useLayoutEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { getCostByMark } from '@/common/pmsdk/get-cost-by-mark'
import PerformanceMarkTag from '@/common/pmsdk/performance-mark-tag'
import { pmLog } from '@/common/pmsdk/pm-log'
import { useAppDispatch } from '@/modules/app/store'
import type { RechargSelectProps } from '@/modules/wallet/interface'
import { setParamValue } from '@/modules/wallet/walletSlice'
import { getIcon } from '@/utils/get-icon'

import LazyImage from '../lazy-image/LazyImage'

const swiperProps = {
  freeMode: true,
  spaceBetween: 8,
}

export function PaymentSelect({
  options,
  rechargeParams,
  withoutMark,
  styles,
  onSelect,
}: {
  options: RechargSelectProps[]
  rechargeParams: Record<string, string>
  withoutMark?: boolean
  styles?: {
    boundaryShadow?: {
      right?: Record<string, string>
      left?: Record<string, string>
    }
    contentPadding?: string
  }
  onSelect?: () => any
}) {
  const dispatch = useAppDispatch()

  useLayoutEffect(() => {
    /** 耗时统计 */
    const cost = getCostByMark(
      PerformanceMarkTag.WalletPageStart,
      PerformanceMarkTag.WalletPageEnd,
    )

    if (cost > 0) {
      const logArgs = {
        eventName: 'clientmetrics_page_load_time_spent',
        payload: {
          page_url: 'recharge-modal',
          time_spent: cost,
        },
        target: {
          labels: ['page_url'],
          value: 'time_spent',
        },
      }
      pmLog(logArgs)
    }
  }, [])

  return (
    <Swiper
      direction='horizontal'
      slidesPerView='auto'
      style={{
        position: 'relative',
        height: '48px',
        width: '100%',
        padding: styles?.contentPadding || '0 20px 0 0',
      }}
      {...swiperProps}
    >
      <Box
        position='absolute'
        width='100%'
        height='100%'
        top={0}
        left={0}
        pointerEvents='none'
        _after={{
          content: "''",
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 1,
          width: '27px',
          height: '48px',
          background:
            'linear-gradient(270deg, var(--chakra-colors-bg-appLayout-content) 0%, transparent 100%)',
          ...(styles?.boundaryShadow?.right || {}),
        }}
        _before={{
          position: 'absolute',
          left: 0,
          top: 0,
          zIndex: 1,
          width: '27px',
          height: '48px',
          background:
            'linear-gradient(90deg, var(--chakra-colors-bg-appLayout-content) 0%, transparent 100%)',
          ...(styles?.boundaryShadow?.left
            ? { ...styles?.boundaryShadow?.left, content: '""' }
            : {}),
        }}
      />
      {options?.map((item, index) => {
        return (
          <SwiperSlide key={`payment_${index}`} style={{ width: '100px' }}>
            <Flex
              position='relative'
              overflow='hidden'
              borderRadius='8px'
              w='100px'
              h='48px'
              justifyContent='center'
              alignItems='center'
              backgroundColor={'var(--chakra-colors-bg-control)'}
              cursor='pointer'
              _before={
                (rechargeParams.typ || options[0]) === item.value
                  ? {
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: 'calc(100% - 4px)',
                      height: 'calc(100% - 4px)',
                      borderRadius: '8px',
                      borderColor: 'prim.500',
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      content: '""',
                    }
                  : {}
              }
              onClick={() => {
                onSelect && onSelect()
                dispatch(
                  setParamValue({
                    key: 'typ',
                    value: item.value as string,
                  }),
                )
              }}
            >
              {item.icon ? (
                <LazyImage
                  cursor='pointer'
                  src={item.icon as any}
                  width='72px'
                />
              ) : (
                <Text cursor='pointer'>{item.value as string}</Text>
              )}

              {(item?.markImg as string) && !withoutMark && (
                <LazyImage
                  position='absolute'
                  top='0'
                  right='0'
                  height='16px'
                  src={item.markImg as string}
                />
              )}
              {(rechargeParams.typ || options[0]) === item.value && (
                <>
                  <Box
                    position='absolute'
                    borderColor='prim.500'
                    borderTop='24px solid transparent'
                    borderRightWidth='24px'
                    right='0'
                    bottom='0'
                    width='0'
                    height='0'
                  />
                  <Icon
                    position='absolute'
                    right='0'
                    bottom='0'
                    color='#fff'
                    boxSize='16px'
                    // {...RechargeActStyle.selectIcon}
                    as={getIcon('Reach')}
                  />
                </>
              )}
            </Flex>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
