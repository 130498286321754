import type { AuthQueryArgs } from '@/modules/user/userApi'
import { api } from '@/services/api'
import type { Response } from '@/services/types'

import type {
  IVipConfigList,
  ResponseVipInfoRsp,
  RewardTypes,
  VipRewardItem,
  VipTaskModelVipSetting,
} from './types'

export const newVipApi = api.injectEndpoints({
  endpoints: builder => ({
    getUserVipInfo: builder.query<Response<ResponseVipInfoRsp>, AuthQueryArgs>({
      query: data => {
        return {
          url: '/api/v1/vip-user/infov2',
          method: 'POST',
          data,
        }
      },
      providesTags: ['UserVipInfo'],
    }),
    getVipConfigList: builder.query<Response<IVipConfigList | null>, string>({
      query: user_id => {
        return {
          url: `/api/v1/vip-config/list`,
          method: 'get',
          params: { user_id },
        }
      },
      providesTags: ['newVip'],
    }),
    getVipRewardList: builder.query<Response<VipRewardItem[]>, AuthQueryArgs>({
      query: data => {
        return {
          url: '/api/v1/vip-reward/list',
          method: 'POST',
          data,
        }
      },
      providesTags: ['newVip'],
    }),
    getVipCommonConfig: builder.query<
      Response<VipTaskModelVipSetting[]>,
      undefined
    >({
      query: () => {
        return {
          url: '/api/v1/vip-config/setting_list',
          method: 'GET',
        }
      },
    }),
    onClaimVipReward: builder.mutation<
      Response,
      AuthQueryArgs & {
        biz_id: string
      }
    >({
      query: data => ({
        url: '/api/v1/vip-reward/claim',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['newVip'],
    }),
    onClaimVipRewardAll: builder.mutation<
      Response,
      AuthQueryArgs & {
        biz_id: string[]
      }
    >({
      query: data => ({
        url: '/api/v1/vip-reward/claim_all',
        method: 'POST',
        data,
      }),
    }),
    /**
     * 获取后台是否启用了vip每日奖励显示的开关
     */
    getVipDailyRewardSwitch: builder.query<Response<{ enable: number }>, void>({
      query: () => ({
        url: '/feat_switch/vip_daily_reward',
        method: 'GET',
      }),
    }),
    /**
     * 领取当前可领取返水奖励
     */
    onClaimVipRewardCanReceive: builder.mutation<Response, AuthQueryArgs>({
      query: data => ({
        url: '/api/v1/vip-reward/claim_all_cashback',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['newVip'],
    }),
    /**
     * 获取当前可领取返水奖励
     */
    getVipRewardCanReceive: builder.query<
      Response<{ total: number }>,
      AuthQueryArgs
    >({
      query: data => ({
        url: '/api/v1/vip-reward/mycashback',
        method: 'POST',
        data,
      }),
      providesTags: ['newVip'],
    }),
    /**
     * 获取vip奖励list
     */
    getVipRewardListV2: builder.query<
      Response<{
        list?: VipRewardItem[]
        page: number
        total: number
        pageSize: number
      }>,
      AuthQueryArgs & {
        page: number
        pageSize: number
        page_off: boolean
        reward_type: RewardTypes
      }
    >({
      query: data => {
        return {
          url: '/api/v1/vip-reward/listv2',
          method: 'POST',
          data,
        }
      },
      providesTags: ['newVip'],
    }),
  }),
})

export const {
  useGetVipConfigListQuery,
  useGetUserVipInfoQuery,
  useGetVipRewardListQuery,
  useOnClaimVipRewardMutation,
  useOnClaimVipRewardAllMutation,
  useGetVipCommonConfigQuery,
  useGetVipDailyRewardSwitchQuery,
  useOnClaimVipRewardCanReceiveMutation,
  useLazyGetVipRewardListV2Query,
  useGetVipRewardCanReceiveQuery,
  useGetVipRewardListV2Query,
} = newVipApi
