import { useEffect, useMemo, useRef } from 'react'

import { isFeatureEnabled } from '@/common/feature'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { closeModal, openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'

import {
  moneyRainApi,
  useLazyGetNextMoneyRainActQuery,
  useLazyGetStartingMoneyRainActQuery,
} from '../api/moneyRainApi'
import {
  selectIsShowEntryButton,
  selectNextAct,
  selectStartingAct,
} from '../store/monyRainSlice'

function useMoneyRain() {
  const dispatch = useAppDispatch()

  const staringAct = useAppSelector(selectStartingAct)
  const nextAct = useAppSelector(selectNextAct)

  const [fetchStarting] = useLazyGetStartingMoneyRainActQuery()
  const [fetchNext] = useLazyGetNextMoneyRainActQuery()

  const timerRef = useRef<number | undefined>()

  const lessTime = useMemo(() => {
    if (staringAct) {
      return staringAct.end_time * 1000 - Date.now()
    }
    if (nextAct) {
      return nextAct.start_time * 1000 - Date.now()
    }
  }, [nextAct, staringAct])

  useEffect(() => {
    if (!isFeatureEnabled('moneyRain')) return
    if (!lessTime) return
    // 用timerRef来保存timer，防止在组件卸载后，timer还在执行
    timerRef.current = window.setTimeout(() => {
      dispatch(moneyRainApi.util.invalidateTags(['MoneyRainAct']))
    }, lessTime + 10 * 1000)
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [])

  // 打开红包雨的规则弹窗
  const handleShowMoneyRainRulesModal = () => {
    dispatch(
      openModal({
        key: GlobalModalKey.MoneyRain,
        data: {
          modalType: 'rules',
          isOpen: true,
        },
      }),
    )
  }

  // 打开红包雨的游戏弹窗
  const handleShowMoneyRainGameModal = () => {
    dispatch(
      openModal({
        key: GlobalModalKey.MoneyRain,
        data: {
          modalType: 'game',
          isOpen: true,
        },
      }),
    )
  }

  // 是否显示红包雨的入口按钮
  const isShowMoneyRainEntryButton = useAppSelector(selectIsShowEntryButton)
  return {
    handleShowMoneyRainRulesModal,
    handleShowMoneyRainGameModal,
    isShowMoneyRainEntryButton,
  }
}

export default useMoneyRain
