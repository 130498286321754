import { useCallback } from 'react'

import { useLazyGetUserInfoQuery } from '@/modules/user/userApi'

import { useUserInfo } from '.'

function useUpdateUserInfo() {
  const { userInfo } = useUserInfo()
  const [getUserInfo] = useLazyGetUserInfoQuery()

  const updateUserInfo = useCallback(() => {
    if (userInfo?.userToken && userInfo.userId) {
      getUserInfo({
        token: userInfo.userToken,
        user_id: userInfo.userId,
      })
    }
  }, [])

  return updateUserInfo
}

export default useUpdateUserInfo
