import { useCallback, useMemo } from 'react'

import { useAppSelector } from '@/modules/app/store'
import type { IBaseLink } from '@/modules/app-layout/types'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { selectLoginRewardStatus } from '@/modules/login-reward-modal/loginRewardSlice'
import {
  selectTypeMapRewardList,
  selectVipRewardsNum,
} from '@/modules/new-vip/newVipSlice'

function useCashbackRedDotShow() {
  const typeMapRewardList = useAppSelector(selectTypeMapRewardList)
  return useMemo(() => {
    return (
      (typeMapRewardList[4] || [])?.filter(item => !item.is_claim).length > 0
    )
  }, [typeMapRewardList])
}

export function useIsRedDotShow() {
  const rewardStatus = useAppSelector(selectLoginRewardStatus)

  const isCashbackRedDotShow = useCashbackRedDotShow()

  const vipRewardsNum = useAppSelector(selectVipRewardsNum)

  return useCallback(
    (item: IBaseLink) => {
      const eventName = item.action?.eventName
      const href = item.action?.href
      if ('/vip' === href) {
        return vipRewardsNum > 0 || rewardStatus === false
      } else if ('/cashback' === href) {
        return isCashbackRedDotShow
      } else if (
        'open-global-modal' === eventName &&
        item?.action?.data?.key === GlobalModalKey.LoginReward
      ) {
        return rewardStatus === false
      }
    },
    [rewardStatus, isCashbackRedDotShow, vipRewardsNum],
  )
}

export default useIsRedDotShow
