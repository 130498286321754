import { createSelector, createSlice } from '@reduxjs/toolkit'

import i18n from '@/common/locale/i18n'
import { pageConfigApi } from '@/services/jsonApi'
import type { Action } from '@/utils/hooks/useActionTrigger'
import { getConfigLng } from '@/utils/tools'

import { NewLoginAndSignUpApi } from './newLoginAndSignUpApi'

export type NewLoginAndSignUpConfigState = {
  logo: string | undefined
  banners: {
    image: string
    action: Action
    scene?: string[]
  }[]
  bonusShowPosition: string[]
  bonusText: string
  // 登录配置
  login_config: {
    phone_and_verify_code: boolean
    phone_and_password: boolean
    email_and_password: boolean
  }
  // 注册配置
  register_config: {
    phone_and_password: boolean
    email_and_password: boolean
    account_and_verify_code: boolean
    phone_and_verify_code: boolean
    email_and_phone_code: boolean
  }
  // 三方登录设置
  other_config: {
    can_use_google: boolean
    can_use_facebook: boolean
  }
}

const initialState: NewLoginAndSignUpConfigState = {
  logo: undefined,
  banners: [],
  bonusShowPosition: [],
  login_config: {
    phone_and_verify_code: false,
    phone_and_password: false,
    email_and_password: false,
  },
  register_config: {
    phone_and_password: false,
    email_and_password: false,
    account_and_verify_code: false,
    phone_and_verify_code: false,
    email_and_phone_code: false,
  },
  other_config: {
    can_use_google: false,
    can_use_facebook: false,
  },
  bonusText: i18n.t('REGISTER_BONUS_TIPS'),
}

const lng = getConfigLng()

export const newLoginAndSignUpConfigSlice = createSlice({
  name: 'newLoginAndSignUpConfig',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      pageConfigApi.endpoints.getHomePageConfig.matchFulfilled,
      (state, { payload }) => {
        const ENABLE = 1
        state.logo = payload.login_register_content?.image || ''
        state.bonusShowPosition = payload.login_register_content?.showBtn ?? []
        state.bonusText =
          payload.login_register_content?.language_content?.[lng]?.icon_text ??
          ''
        state.banners =
          payload.login_register_banner
            ?.filter(
              item =>
                item.status === ENABLE &&
                Object.keys(item.language_content).includes(lng),
            )
            .map(item => {
              const content = item.language_content[lng] || {
                image: '',
                action: {},
                scene: [],
              }
              return {
                image: content.image,
                action: item.action,
                scene: item.scene,
              }
            }) || []

        return state
      },
    ),
      builder.addMatcher(
        NewLoginAndSignUpApi.endpoints.getLoginAndSignUpConfig.matchFulfilled,
        (state, { payload }) => {
          const config = payload.data.config ?? ''
          const trueConfig = JSON.parse(config)
          state.login_config = trueConfig.login_config ?? {}
          state.register_config = trueConfig.register_config ?? {}
          // state.register_config = {
          //   phone_and_password: true,
          //   email_and_password: true,
          //   account_and_verify_code: false,
          //   phone_and_verify_code: false,
          //   email_and_phone_code: true,
          // }
          state.other_config = trueConfig.other_config ?? {}
          return state
        },
      )
  },
})

export const selectEffectLoginConfig = (state: {
  newLoginAndSignUpConfig: NewLoginAndSignUpConfigState
}) => state.newLoginAndSignUpConfig.login_config

export const selectEffectRegisterConfig = (state: {
  newLoginAndSignUpConfig: NewLoginAndSignUpConfigState
}) => state.newLoginAndSignUpConfig.register_config

export const selectEffectOtherConfig = (state: {
  newLoginAndSignUpConfig: NewLoginAndSignUpConfigState
}) => Object.values(state.newLoginAndSignUpConfig.other_config).some(Boolean)

export const selectNewLoginAndSignUpConfig = (state: {
  newLoginAndSignUpConfig: NewLoginAndSignUpConfigState
}) => state.newLoginAndSignUpConfig

export const selectIsEnablePhoneVerifyLogin = (state: {
  newLoginAndSignUpConfig: NewLoginAndSignUpConfigState
}) => state.newLoginAndSignUpConfig.login_config.phone_and_verify_code

export const selectIsEnablePhonePwdVerifyLogin = (state: {
  newLoginAndSignUpConfig: NewLoginAndSignUpConfigState
}) => state.newLoginAndSignUpConfig.login_config.phone_and_password

export const selectIsEnableEmailPwdVerifyLogin = createSelector(
  selectEffectLoginConfig,
  selectIsEnablePhoneVerifyLogin,
  selectIsEnablePhonePwdVerifyLogin,
  (loginConfig, isEnablePhoneVerifyLogin, isEnablePhonePwdVerifyLogin) => {
    if (!isEnablePhonePwdVerifyLogin && !isEnablePhoneVerifyLogin) {
      return true
    }
    return loginConfig.email_and_password
  },
)

export const selectIsEnablePhoneVerifyRegister = (state: {
  newLoginAndSignUpConfig: NewLoginAndSignUpConfigState
}) => state.newLoginAndSignUpConfig.register_config.phone_and_verify_code

export const selectIsEnableEmailAndPhoneCodeRegister = (state: {
  newLoginAndSignUpConfig: NewLoginAndSignUpConfigState
}) => state.newLoginAndSignUpConfig.register_config.email_and_phone_code

export const selectIsEnablePhonePwdVerifyRegister = (state: {
  newLoginAndSignUpConfig: NewLoginAndSignUpConfigState
}) => state.newLoginAndSignUpConfig.register_config.phone_and_password

export const selectIsEnableEmailVerifyRegister = (state: {
  newLoginAndSignUpConfig: NewLoginAndSignUpConfigState
}) => state.newLoginAndSignUpConfig.register_config.account_and_verify_code

export const selectIsEnableEmailPwdVerifyRegister = createSelector(
  selectEffectRegisterConfig,
  selectIsEnablePhoneVerifyRegister,
  selectIsEnablePhonePwdVerifyRegister,
  selectIsEnableEmailVerifyRegister,
  (
    registerConfig,
    isEnablePhoneVerifyRegister,
    isEnablePhonePwdVerifyRegister,
    isEnableEmailVerifyRegister,
  ) => {
    if (
      !isEnablePhoneVerifyRegister &&
      !isEnablePhonePwdVerifyRegister &&
      isEnableEmailVerifyRegister
    ) {
      return true
    }
    return registerConfig.email_and_password
  },
)
export const selectBonusShowPosition = (state: {
  newLoginAndSignUpConfig: NewLoginAndSignUpConfigState
}) => state.newLoginAndSignUpConfig.bonusShowPosition

export const newLoginAndSignUpConfigReducer =
  newLoginAndSignUpConfigSlice.reducer

export const selectBonusText = (state: {
  newLoginAndSignUpConfig: NewLoginAndSignUpConfigState
}) => state.newLoginAndSignUpConfig.bonusText
