import { api } from '@/services/api'
import type { Response } from '@/services/types'

import { startAppListening } from '../app/listenerMiddleware'
import { openModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import type { AuthQueryArgs } from '../user/userApi'
import type {
  AddGameHistoryArgs,
  CollectionMutationArgs,
  GameCollection,
  RecentlyPlayedGames,
} from './types'
import { getUserId, getUserToken } from '../user/storage'

export const collectionHistoryApi = api.injectEndpoints({
  endpoints: builder => ({
    getRecordsAndCollectionsData: builder.query<Response, void>({
      query: data => {
        return {
          url: 'api/v1/platform/collection/records_and_collections',
          data: {
            token: getUserToken(),
            user_id: getUserId(),
          },
          method: 'POST',
        }
      },
      providesTags: ['GameRecordsAndCollections'],
    }),
    addToCollection: builder.mutation<
      Response<boolean>,
      CollectionMutationArgs
    >({
      query: data => {
        return {
          url: '/api/v1/platform/collection/collection_game',
          method: 'POST',
          data,
        }
      },
      async onQueryStarted(
        { game_id, game_uuid, game_name, user_id, token },
        { dispatch, queryFulfilled },
      ) {
        const { data } = await queryFulfilled
        if (data?.data) {
          dispatch(
            api.util.updateQueryData(
              'collectionHistory',
              { user_id, token },
              (draft: Response<GameCollection>) => {
                const newItem = {
                  game_id,
                  game_name,
                  game_uuid,
                }
                if (Array.isArray(draft?.data?.game_list)) {
                  draft.data?.game_list.unshift(newItem)
                } else if (draft) {
                  draft.data = {
                    game_list: [newItem],
                  }
                }
              },
            ),
          )
        }
      },
      invalidatesTags:['GameRecordsAndCollections']
    }),
    removeFromCollection: builder.mutation<
      Response<boolean>,
      CollectionMutationArgs
    >({
      query: data => {
        return {
          url: '/api/v1/platform/collection/cancel_collection_game',
          method: 'POST',
          data,
        }
      },
      async onQueryStarted(
        { game_id, game_uuid, user_id, token },
        { dispatch, queryFulfilled },
      ) {
        const { data } = await queryFulfilled
        if (data?.data) {
          dispatch(
            api.util.updateQueryData(
              'collectionHistory',
              { user_id, token },
              (draft: Response<GameCollection>) => {
                const indexByGameId = draft?.data?.game_list?.findIndex(
                  item => item.game_id === game_id,
                )
                const indexByGameUuid = draft?.data?.game_list?.findIndex(
                  item => item.game_id === game_uuid,
                )
                const idx =
                  typeof indexByGameUuid !== 'undefined' && indexByGameUuid > -1
                    ? indexByGameUuid
                    : typeof indexByGameId !== 'undefined' && indexByGameId > -1
                    ? indexByGameId
                    : -1
                if (idx > -1 && Array.isArray(draft?.data?.game_list)) {
                  draft.data.game_list?.splice(idx, 1)
                }
              },
            ),
          )
        }
      },
      invalidatesTags:['GameRecordsAndCollections']
    }),
    collectionHistory: builder.query<Response<GameCollection>, AuthQueryArgs>({
      query: data => {
        return {
          url: '/api/v1/platform/collection/collection_history',
          method: 'POST',
          data,
        }
      },
    }),
    addGameHistory: builder.mutation<Response<boolean>, AddGameHistoryArgs>({
      query: data => {
        return {
          url: '/api/v1/platform/collection/set_game_history',
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['GameHistory'],
    }),
    recentlyPlayedGames: builder.query<
      Response<RecentlyPlayedGames>,
      AuthQueryArgs
    >({
      query: data => {
        return {
          url: '/api/v1/platform/collection/game_history',
          method: 'POST',
          data,
        }
      },
      providesTags: ['GameHistory'],
    }),
  }),
})

export const {
  useAddToCollectionMutation,
  useRemoveFromCollectionMutation,
  useCollectionHistoryQuery,
  useRecentlyPlayedGamesQuery,
  useAddGameHistoryMutation,
  useGetRecordsAndCollectionsDataQuery
} = collectionHistoryApi

startAppListening({
  matcher: collectionHistoryApi.endpoints.addToCollection.matchRejected,
  effect: (action, api) => {
    if (action.payload?.message === 'collection limit exceeded') {
      api.dispatch(openModal({ key: GlobalModalKey.FavoriteLimit }))
    }
  },
})
