import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  VStack,
} from '@chakra-ui/react'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from '@/modules/app/store'
import { HEADER_HEIGHT } from '@/modules/app-layout/constants'
import { GameGridEmpty } from '@/modules/my-games/GamesGrid'
import { useCollectionGames } from '@/modules/my-games/useGameGrid'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useUserAuth } from '@/utils/hooks'
import useShushu from '@/utils/hooks/useShushu'

import {
  selectHomeAllGameList,
  selectHomeAllManuList,
  selectHomeAllRecomList,
} from '../gameApiSlice'
import type { CategoryLabelListItem, GameListItem } from '../gameInterface'
import { FAVORITE_LABEL_ID, selectAllCateByLableId } from '../gameSlice'
import GameCatagoryTabs from '../units/GameCatagoryTabs'
import GameCardRender from './game-list/GameCardRender'
import Manufacturer from './game-list/ManufacturerTabs'
import { GroupGames } from './game-list/recommend-game-groups/GroupGames'

const HomeGames = () => {
  const homeAllGameList = useAppSelector(selectHomeAllGameList)
  const homeAllManuList = useAppSelector(selectHomeAllManuList)
  const homeAllRecomLabelList = useAppSelector(selectHomeAllRecomList)
  // ************** 以上是用API方式获取的游戏列表 ********

  const _allCateByLabelId = useAppSelector(selectAllCateByLableId)
  const [activedKey, setActivedKey] = useState<number>(6)
  const [currentManu, setCurrentManu] = useState<number>(
    null as unknown as number,
  )
  const [userId, token] = useUserAuth()
  const collectionGames = useCollectionGames()

  const onTabChange = useCallback(
    (key: number) => {
      setActivedKey(key)
      if (key === 8) {
        const firstLabelId = homeAllManuList?.[0]?.id || null
        setCurrentManu(firstLabelId as unknown as number)
      }
    },
    [homeAllManuList],
  )

  const firstCateLabelId = useMemo(
    () => homeAllGameList[0]?.label_id,
    [homeAllGameList],
  )

  useEffect(() => {
    if (firstCateLabelId) {
      setActivedKey(firstCateLabelId || 6)
    }
  }, [firstCateLabelId])

  const resetActivedKey = useCallback(
    () => setActivedKey(firstCateLabelId || 6),
    [firstCateLabelId],
  )

  const showCateList = useMemo(() => {
    const cateList = homeAllGameList.slice()
    if (token && userId && !!_allCateByLabelId?.[FAVORITE_LABEL_ID]?.label_id) {
      // 如果登陆的情况下
      const cateList1 = cateList.slice(0, 1)
      const cateList2 = cateList.slice(1)
      const collectCate = {
        ...(_allCateByLabelId[FAVORITE_LABEL_ID] || {}),
        data: collectionGames || [],
      } as unknown as CategoryLabelListItem
      return cateList1.concat([collectCate]).concat(cateList2)
    }
    return cateList
  }, [token, userId, homeAllGameList, collectionGames, _allCateByLabelId])

  if (homeAllGameList?.length === 0) return null

  return (
    <>
      <GameCatagoryTabs
        items={showCateList}
        onChange={onTabChange}
        activedKey={activedKey}
      />
      <VStack id='game-list' alignItems='stretch' spacing='5'>
        {(() => {
          if (activedKey === 8) {
            // provider: 厂商列表
            return (
              <>
                <Manufacturer
                  index={homeAllManuList.findIndex(
                    item => item?.id === currentManu,
                  )}
                  datasource={homeAllManuList || []}
                  onChange={item => {
                    setCurrentManu(item)
                  }}
                />
                <GridRender
                  activedKey={activedKey}
                  currentManu={currentManu}
                  list={
                    (homeAllManuList.find(item => item?.id === currentManu)
                      ?.data || []) as unknown as GameListItem[]
                  }
                />
              </>
            )
          } else if (activedKey === 6) {
            // 推荐列表
            return (
              <GameList data={homeAllRecomLabelList} activedKey={activedKey} />
            )
          } else {
            return (
              // 剩余的厂商
              <GridRender
                activedKey={activedKey}
                currentManu={currentManu}
                resetActivedKey={resetActivedKey}
                list={
                  (showCateList.find(item => item?.label_id === activedKey)
                    ?.data || []) as unknown as CategoryLabelListItem[]
                }
              />
            )
          }
        })()}
      </VStack>
    </>
  )
}

export default HomeGames

const GRID_MAX_SIZE = 18
const GridRender: FC<{
  activedKey: number
  currentManu: number
  list: GameListItem[]
  resetActivedKey?: () => void
}> = ({ activedKey, currentManu, list, resetActivedKey }) => {
  const { setShushuGameReportData } = useShushu()
  const navigate = useNavigate()
  const { t } = useTranslation()
  if (activedKey === FAVORITE_LABEL_ID && list?.length === 0) {
    return (
      <GameGridEmpty
        emptyText={t('NO_FAVORITE_GAME').toString()}
        p='0'
        onClick={resetActivedKey}
      />
    )
  }
  const onCardClick = (game: GameListItem) => {
    setShushuGameReportData({
      game_id: game.game_id,
      game_name: game.game_name,
      manufacturer: game.game_manufacturer,
      from: 'home-list',
      label_id: currentManu,
    })
  }

  return (
    <>
      <Grid
        templateColumns={{
          base: 'repeat(3, 1fr)',
          sm: 'repeat(4, 1fr)',
          md: 'repeat(5, 1fr)',
          lg: 'repeat(6, 1fr)',
        }}
        columnGap='3'
        rowGap='3'
      >
        {(list?.slice(0, GRID_MAX_SIZE) || []).map(item => {
          if (!item) return null
          return (
            <GridItem key={item.game_id + item.game_name}>
              <GameCardRender
                info={item}
                onClick={onCardClick.bind(null, item)}
              />
            </GridItem>
          )
        })}
      </Grid>
      <Flex justifyContent={'center'}>
        <Button
          size='mdsm'
          mb={5}
          hidden={list.length < GRID_MAX_SIZE}
          onClick={() => {
            if (activedKey === 8) {
              navigate(`/game/all?cate=${activedKey}&label=${currentManu}`)
            } else {
              navigate(`/game/all?cate=${activedKey}`)
            }
          }}
          colorScheme='white'
        >
          {t('HOME_LOAD_MORE')}
          <Icon as={SharedIcon.More} boxSize='5' ml='1' />
        </Button>
      </Flex>
    </>
  )
}

const GameList: FC<{
  data: CategoryLabelListItem[]
  activedKey: number
}> = ({ data, activedKey }) => {
  return data.map((d: any) => {
    return (
      <Box key={d.label_id} position='relative'>
        <Box
          position='absolute'
          id={d.label_id}
          top={`-${HEADER_HEIGHT}`}
          arir-label='group-anchor'
        />
        <GroupGames
          datasource={d}
          label={d.label_id}
          type={activedKey === 6 ? -1 : activedKey}
        />
      </Box>
    )
  })
}
