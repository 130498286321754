import { Text } from '@chakra-ui/react'
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { getVariable } from '@/common/env'

import type { FormItemProps } from './Form'
import { FormInput } from './FormInput'
import type { ExtraFormItemProps } from './FormItem'

const areaCode = getVariable('PHONE_AREA_CODE')
const areaCodeWidth = (getVariable('PHONE_AREA_CODE')?.length - 1) * 10

const FormItem: React.FC<FormItemProps & ExtraFormItemProps> = ({
  label,
  name = '',
  value,
  onChange,
  registerFormItem,
  unregisterFormItem,
  updateFormItemError,
  error,
  rules,
  disabled,
  addonBefore,
  addonAfter,
  isRequired,
  fieldProps = {},
}) => {
  const { t } = useTranslation()
  const isInit = useRef(true)
  const [addonBeforeWidth] = useState(!addonBefore ? areaCodeWidth + 38 : 0)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(name, e.target.value)
  }

  const validate = useCallback(async () => {
    let errorMsg = ''

    if (rules?.length) {
      for (const rule of rules) {
        try {
          if (rule?.validator) {
            await rule.validator(value)
          } else if (rule?.required) {
            if (!value) {
              throw new Error(rule?.message || 'is empty'.toString())
            }
          }
        } catch (error: any) {
          errorMsg = error.message
          // 处理抛出的错误，可以在此加入您的逻辑
          break // 终止循环
        }
      }
    }

    if (errorMsg) {
      updateFormItemError?.(name, t(errorMsg)) // 更新错误信息
      return false
    }

    updateFormItemError?.(name, '')
    return true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    registerFormItem?.(name, validate)
    return () => {
      unregisterFormItem?.(name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate])

  useEffect(() => {
    !isInit.current && validate()
    isInit.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const addonBeforeRender = useMemo(() => {
    return (
      addonBefore || (
        <Text fontSize='14px' mr='4' ml='4' color='text.accent'>
          {areaCode}
        </Text>
      )
    )
  }, [addonBefore])

  return (
    <FormInput
      label={label}
      value={value}
      handleValueChange={handleChange}
      errorMsg={error}
      addonBefore={addonBeforeRender}
      addonBeforeWidth={addonBeforeWidth}
      addonAfter={addonAfter}
      fieldProps={{
        name: 'phone',
        type: 'number',
        placeholder: t('DEPOSIT_INPUT_PHONE') || '',
        ...fieldProps,
        // autoComplete: 'new-password',
      }}
      disabled={disabled}
      isRequired={isRequired}
    />
  )
}

const PhoneFormItem = memo(FormItem)

PhoneFormItem.displayName = 'FormItem'

export default PhoneFormItem
