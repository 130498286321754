//

import { useCallback, useEffect, useMemo, useState } from 'react'

import { getVariable } from '@/common/env'
import { isFeatureEnabled } from '@/common/feature'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { getFeatures } from '@/utils/tools'

import { openModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import useAccountsInfo from '../new-login/hooks/useAccountsInfo'
import { LoginVerifyCodeType } from '../new-login/loginApi'
import { verifyPhone } from '../wallet/verifyFunction'
import { getType, WalletModalTab } from '../wallet/walletSlice'
import {
  getBindPhoneModalFromPersonal,
  getBindPhoneSwitch,
  getCode,
  getIsBindPhone,
  getModalType,
  getOldPhone,
  getPhone,
  openBindPhoneModal,
  setPhone,
  switchBindPhoneModal,
} from './bindPhoneSlice'
import { MODAL_TYPE, VERIFY_CODE_LENGTH } from './constants'
import type { ModalTypeE } from './type'
import { ModalType } from './type'
import { useGetcode } from './useGetcode'
import { useOffBindPhone } from './useOffBindPhone'
import { usePhone } from './usePhone'
import { useUpdateBindPhone } from './useUpdateBindPhone'

const PHONE_AREA_CODE = getVariable('PHONE_AREA_CODE') || '+55'
const SHOW_BIND_PHONE = getVariable('SHOW_BIND_PHONE')
const loginAndSignUpV3 = getFeatures('loginAndSignUpV3')
const code_type_switch = isFeatureEnabled('code_type_switch')

export const useBindPhone = () => {
  const { bindInfoValue } = useAccountsInfo()
  const modalType: ModalTypeE = useAppSelector(getModalType) as ModalTypeE
  const isBindPhone = useAppSelector(getIsBindPhone)
  const oldPhone = useAppSelector(getOldPhone) //旧手机号
  const phone = useAppSelector(getPhone) //新手机号
  const code = useAppSelector(getCode)
  const activeTab = useAppSelector(getType)
  const { userOffBindPhone, offLoading } = useOffBindPhone()
  const { userUpdateBindPhone, updateLoading } = useUpdateBindPhone()
  const { _userBindPhone, bindLoading } = usePhone()
  const bindPhoneSwitch = useAppSelector(getBindPhoneSwitch)
  const bindPhoneModalFromPersonal = useAppSelector(
    getBindPhoneModalFromPersonal,
  )

  const {
    getPhoneCode,
    showPhoneShake,
    codeSuccess,
    phoneShakeFn,
    // codeLoading,
  } = useGetcode()

  const dispatch = useAppDispatch()
  const [showCodeShake, setShowCodeShake] = useState(false)
  //手机号
  const _verifyPhone = useMemo(
    () => verifyPhone({ params: {}, value: phone }),
    [phone],
  )
  // 获取手机验证码
  const codeBtnClick = useCallback(async () => {
    const params = {
      phone,
      area_code: PHONE_AREA_CODE,
    }

    !!code_type_switch &&
      Object.assign(params, {
        code_type: bindPhoneModalFromPersonal ? 'personal' : 'withdraw',
      })

    getPhoneCode(params)
  }, [getPhoneCode, phone, bindPhoneModalFromPersonal])

  const isLoading = useMemo(() => {
    return offLoading || updateLoading || bindLoading
  }, [offLoading, updateLoading, bindLoading])
  //modal 按钮点击
  const bindPhoneClick = useCallback(async () => {
    if (isLoading) return
    if (!codeSuccess && !_verifyPhone.verify) {
      phoneShakeFn()
      return
    }
    if (
      !(
        modalType === ModalType.bindsuccess ||
        modalType === ModalType.rebindsuccess
      )
    ) {
      if (code.length < VERIFY_CODE_LENGTH) {
        codeShakeFn()
        return
      }
    }

    switch (modalType) {
      case ModalType.bind:
        _userBindPhone({
          code,
          phone,
          area_code: PHONE_AREA_CODE,
        })
        break
      case ModalType.bind_disabled:
        _userBindPhone({
          code,
          phone,
          area_code: PHONE_AREA_CODE,
        })
        break
      case ModalType.rebind:
        userOffBindPhone({
          code,
          phone,
          area_code: PHONE_AREA_CODE,
        })
        break
      case ModalType.changesuccess:
        userUpdateBindPhone({
          code,
          phone,
          area_code: PHONE_AREA_CODE,
          old_phone: oldPhone,
        })
        break
      case ModalType.bindsuccess:
      case ModalType.rebindsuccess:
        dispatch(switchBindPhoneModal())
        break
    }
    return
  }, [
    code,
    codeSuccess,
    _verifyPhone,
    phone,
    modalType,
    userOffBindPhone,
    oldPhone,
    _userBindPhone,
    userUpdateBindPhone,
    phoneShakeFn,
    dispatch,
    isLoading,
  ])

  //code摇
  const codeShakeFn = () => {
    setShowCodeShake(true)
    setTimeout(() => {
      setShowCodeShake(false)
    }, 800)
  }
  //获取验证码刷新后做个临时保存
  useEffect(() => {
    const _phone = sessionStorage.getItem('last_phone') || '' //上一次发送验证码的手机号
    if (_phone) {
      dispatch(setPhone(_phone))
    }
  }, [dispatch])
  //是否是成功状态
  const isSuccess = useMemo(() => {
    return (
      modalType === ModalType.bindsuccess ||
      modalType === ModalType.rebindsuccess
    )
  }, [modalType])

  //监听打开提现页面
  useEffect(() => {
    if (
      activeTab === WalletModalTab.withdraw &&
      (!isBindPhone || (loginAndSignUpV3 && !bindInfoValue?.phone?.verified)) &&
      !!bindPhoneSwitch
    ) {
      if (loginAndSignUpV3) {
        let verifyType = undefined

        if (!bindInfoValue?.phone?.value) {
          verifyType = LoginVerifyCodeType.uvctBindingPhone
        }
        dispatch(
          openModal({
            key: GlobalModalKey.BindInfoModal,
            data: {
              type: 'phone',
              verifyType,
              source: 'withdraw',
            },
          }),
        )
        return
      }
      // dispatch(changeTabs(WalletModalTab.recharge))
      dispatch(openBindPhoneModal(false))
    }
  }, [activeTab, isBindPhone, dispatch, bindPhoneSwitch, bindInfoValue])
  //
  /**
   *lang           语言配置
   *showPhoneShake 手机号抖动
   *codeBtnClick   获取验证码点击
   *codeSuccess    成功获取验证码
   *bindPhoneClick 绑定手机号点击
   *showCodeShake  code抖动
   *modalType      成功
   *isSuccess      是否成功
   *isBindPhone   是否绑定
   *isLoading     按钮状态
   */
  const lang = useMemo(() => {
    return MODAL_TYPE[modalType]
    // return MODAL_TYPE['rebindsuccess']
  }, [modalType])
  return {
    lang,
    _verifyPhone,
    showPhoneShake,
    codeBtnClick,
    codeSuccess,
    bindPhoneClick,
    showCodeShake,
    modalType,
    isSuccess,
    isBindPhone,
    isLoading,
    oldPhone,
  }
}
