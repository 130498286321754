/**
 * models.Response
 */
export interface DrawActivityInfoRes {
  /**
   * 返回码
   */
  code?: number
  /**
   * 返回数据
   */
  data?: DrawpbDrawInfoRsp
  /**
   * 返回描述
   */
  msg?: string
  /**
   * 追踪id
   */
  trace_id?: string
}

/**
 * drawpb.DrawInfoRsp
 */
export interface DrawpbDrawInfoRsp {
  /**
   * 抽奖信息
   */
  info: Info
  /**
   * 抽奖活动
   */
  task: Task
}

/**
 * 抽奖信息
 *
 * drawpb.DrawInfo
 */
export interface Info {
  /**
   * 投注进度
   */
  bet_progress?: number
  /**
   * 充值进度
   */
  deposit_progress?: number
  /**
   * 抽奖次数
   */
  draw_ticket_nums: number
  /**
   * 邀请进度
   */
  invite_progress?: number
  /**
   * 今天是否已经签到
   */
  is_claim_today?: boolean
  /**
   * 用户相对于当前抽奖的状态,参考userfilter.NotPassCode
   */
  play_status?: number
}

/**
 * 抽奖活动
 *
 * drawpb.DrawTask
 */
export interface Task {
  /**
   * 具体抽奖配置
   */
  config: Config
  /**
   * 抽奖样式
   */
  draw_style: number
  /**
   * 图片地址
   */
  image_url?: string
  /**
   * 奖励列表
   */
  prize_list?: DrawpbDrawPrize[]
  /**
   * 状态。0为关闭，1为开启
   */
  state?: number
  /**
   * 流水id
   */
  task_id: number
  /**
   * 更新时间
   */
  updated_at?: number
  /**
   * 权重，从大到小
   */
  weight?: number
}

/**
 * 具体抽奖配置
 *
 * drawpb.DrawConfig
 */
export interface Config {
  /**
   * 开始时间
   */
  begin?: number
  /**
   * 投注条件配置
   */
  bet_condition?: BetCondition
  /**
   * 多语言按钮文案
   */
  button_text?: { [key: string]: string }
  /**
   * 充值条件配置
   */
  deposit_condition?: DepositCondition
  /**
   * 多语言文案
   */
  desc?: { [key: string]: string }
  /**
   * 结束时间
   */
  end?: number
  invite_condition?: DrawpbInviteCondition
  /**
   * 抽奖条件是否允许投注
   */
  is_bet?: boolean
  /**
   * 抽奖条件是否允许充值
   */
  is_deposit?: boolean
  /**
   * 抽奖条件是否允许分享
   */
  is_invite?: boolean
  /**
   * 抽奖条件是否允许签到
   */
  is_sign_in?: boolean
  /**
   * 多语言名称
   */
  name?: { [key: string]: string }
  /**
   * 签到条件配置
   */
  sign_in_condition?: SignInCondition
  /**
   * user filter id
   */
  user_filter_id?: number
}

/**
 * 投注条件配置
 *
 * drawpb.BetCondition
 */
export interface BetCondition {
  /**
   * 所需投注金额
   */
  bet_amount?: number
  /**
   * 是否投注后提示
   */
  bet_hint?: boolean
  /**
   * 投注次数奖励
   */
  reward_nums?: number
}

/**
 * 充值条件配置
 *
 * drawpb.DepositCondition
 */
export interface DepositCondition {
  /**
   * 所需充值金额
   */
  deposit_amount?: number
  /**
   * 是否充值后提示
   */
  deposit_hint?: boolean
  /**
   * 充值次数奖励
   */
  reward_nums?: number
}

/**
 * drawpb.InviteCondition
 */
export interface DrawpbInviteCondition {
  /**
   * 是否邀请后弹出
   */
  after_invite_pop?: boolean
  /**
   * 邀请人数限制
   */
  invite_limit?: number
  /**
   * 邀请次数奖励
   */
  reward_nums?: number
}

/**
 * 签到条件配置
 *
 * drawpb.SignInCondition
 */
export interface SignInCondition {
  /**
   * 是否访问后弹出
   */
  after_sign_in_pop?: boolean
  /**
   * 签到次数奖励
   */
  reward_nums?: number
}

/**
 * 抽奖接口响应
 */
export interface DrawRes {
  /**
   * 返回码
   */
  code?: number
  /**
   * 返回数据
   */
  data?: DrawpbDrawRsp
  /**
   * 返回描述
   */
  msg?: string
  /**
   * 追踪id
   */
  trace_id?: string
}

/**
 * drawpb.DrawRsp
 */
export interface DrawpbDrawRsp {
  /**
   * 中奖信息
   */
  prize?: DrawpbDrawPrize
}

/**
 * 中奖信息
 *
 * drawpb.DrawPrize
 */
export type Prize = DrawpbDrawPrize

/**
 * models.Response
 */
export interface DrawActivityListRes {
  /**
   * 返回码
   */
  code?: number
  /**
   * 返回数据
   */
  data?: DrawpbDrawListRsp
  /**
   * 返回描述
   */
  msg?: string
  /**
   * 追踪id
   */
  trace_id?: string
}

/**
 * drawpb.DrawListRsp
 */
export interface DrawpbDrawListRsp {
  /**
   * 可用的抽奖列表
   */
  list?: DrawpbDrawTask[]
  /**
   * 用户相对于当前抽奖的状态map,key是task_id，value参考userfilter.NotPassCode
   */
  play_status_map?: { [key: string]: number }
  /**
   * // 抽奖次数map,key是task_id，value是次数
   */
  ticket_map?: { [key: string]: number }
}

export enum PrizeType {
  Currency = 'CURRENCY',
  VirtualCoin = 'VIRTUAL_COIN',
  Custom = 'CUSTOM',
}

/**
 * drawpb.DrawTask
 */
export interface DrawpbDrawTask {
  /**
   * 具体抽奖配置
   */
  config?: Config
  /**
   * 抽奖样式
   */
  draw_style?: number
  /**
   * 图片地址
   */
  image_url?: string
  /**
   * 奖励列表
   */
  prize_list?: DrawpbDrawPrize[]
  /**
   * 状态。0为关闭，1为开启
   */
  state?: number
  /**
   * 流水id
   */
  task_id: number
  /**
   * 更新时间
   */
  updated_at?: number
  /**
   * 权重，从大到小
   */
  weight?: number
}

/**
 * 具体抽奖配置
 *
 * drawpb.DrawConfig
 */
export interface Config {
  /**
   * 开始时间
   */
  begin?: number
  /**
   * 投注条件配置
   */
  bet_condition?: BetCondition
  /**
   * 多语言按钮文案
   */
  button_text?: { [key: string]: string }
  /**
   * 充值条件配置
   */
  deposit_condition?: DepositCondition
  /**
   * 多语言文案
   */
  desc?: { [key: string]: string }
  /**
   * 结束时间
   */
  end?: number
  invite_condition?: DrawpbInviteCondition
  /**
   * 抽奖条件是否允许投注
   */
  is_bet?: boolean
  /**
   * 抽奖条件是否允许充值
   */
  is_deposit?: boolean
  /**
   * 抽奖条件是否允许分享
   */
  is_invite?: boolean
  /**
   * 抽奖条件是否允许签到
   */
  is_sign_in?: boolean
  /**
   * 多语言名称
   */
  name?: { [key: string]: string }
  /**
   * 签到条件配置
   */
  sign_in_condition?: SignInCondition
  /**
   * user filter id
   */
  user_filter_id?: number
}

/**
 * 投注条件配置
 *
 * drawpb.BetCondition
 */
export interface BetCondition {
  /**
   * 所需投注金额
   */
  bet_amount?: number
  /**
   * 是否投注后提示
   */
  bet_hint?: boolean
  /**
   * 投注次数奖励
   */
  reward_nums?: number
}

/**
 * 充值条件配置
 *
 * drawpb.DepositCondition
 */
export interface DepositCondition {
  /**
   * 所需充值金额
   */
  deposit_amount?: number
  /**
   * 是否充值后提示
   */
  deposit_hint?: boolean
  /**
   * 充值次数奖励
   */
  reward_nums?: number
}

/**
 * drawpb.InviteCondition
 */
export interface DrawpbInviteCondition {
  /**
   * 是否邀请后弹出
   */
  after_invite_pop?: boolean
  /**
   * 邀请人数限制
   */
  invite_limit?: number
  /**
   * 邀请次数奖励
   */
  reward_nums?: number
}

/**
 * 签到条件配置
 *
 * drawpb.SignInCondition
 */
export interface SignInCondition {
  /**
   * 是否访问后弹出
   */
  after_sign_in_pop?: boolean
  /**
   * 签到次数奖励
   */
  reward_nums?: number
}

/**
 * drawpb.DrawPrize
 */
export interface DrawpbDrawPrize {
  prize_id: number
  rate?: number
  /**
   * 奖励图片
   */
  reward_image?: string
  /**
   * 奖励数量
   */
  reward_num?: number
  /**
   * 奖励类型描述,例如CURRENCY,VIRTUAL_COIN,CUSTOM
   */
  reward_type: string
  /**
   * 奖励单位,例如BRL,BTC
   */
  reward_unit?: string
}

/**
 * drawpb.DrawRecordListRsp
 */
export interface DrawpbDrawRecordListRsp {
  list?: DrawpbDrawRecord[]
  paginate?: Paginate
  [property: string]: any
}

/**
 * drawpb.DrawRecord
 */
export interface DrawpbDrawRecord {
  /**
   * 创建时间
   */
  created_at?: number
  /**
   * 流水id
   */
  id?: number
  /**
   * 奖励数量
   */
  reward_num?: number
  /**
   * 奖励类型描述,例如CURRENCY,VIRTUAL_COIN,CUSTOM
   */
  reward_type?: string
  /**
   * 奖励单位,例如BRL,BTC
   */
  reward_unit?: string
  /**
   * 抽奖记录状态
   */
  state?: number
  /**
   * 用户id
   */
  user_id?: string
  [property: string]: any
}

/**
 * ubasepb.Paginate
 */
export interface Paginate {
  /**
   * 每页结果数
   */
  limit?: number
  /**
   * 当前获取到的第一个的索引位置
   */
  offset?: number
  /**
   * 总页数
   */
  total?: number
  [property: string]: any
}

/**
 * drawpb.DrawRecordListReq
 */
export interface GetDrawDrawRecordListReq {
  /**
   * @v: required
   */
  list_option: ListOption
  /**
   * @v: required
   */
  task_id: number
  [property: string]: any
}

/**
 * @v: required
 *
 * ubasepb.ListOption
 */
export interface ListOption {
  /**
   * 分页的每页字段长度
   */
  limit?: number
  /**
   * 需要获取的索引开始位置
   */
  offset?: number
  /**
   * 需要获取的索引开始位置标记
   */
  offset_token?: string
  /**
   * 搜索过滤逻辑，不支持逻辑或
   * options内部是逻辑与关系，暂不支持逻辑或；尽量避免复杂的过滤逻辑，后台不好分页
   */
  options?: UbasepbListOptionOption[]
  /**
   * = true 时, 就算 offset = 0 也不要 count
   */
  skip_count?: boolean
  [property: string]: any
}

/**
 * ubasepb.ListOption_Option
 */
export interface UbasepbListOptionOption {
  /**
   * 类型，各业务定义的type
   */
  type?: number
  /**
   * 参数，只支持1个参数，参数实际类型和意义由type决定
   */
  value?: string
  [property: string]: any
}
export type PrizeProps = {
  id: number
  image: string
  num: number
  unit: string
  text: string
  type: string
}
type Progress = {
  current: number
  target: number
}

export interface DrawTask {
  drawTimes: number
}

export interface DrawTaskWithProgress extends DrawTask {
  progress: Progress
  activityId?: number
}

export interface DrawTaskOneTime extends DrawTask {
  isDone: boolean
}

export type DrawActivity = {
  title?: string
  rule?: string
  taskId: number
  drawStyle: number
  image?: string
  prizes: PrizeProps[]
  canPlay: boolean
  ticketNum: number
}

export interface ActivityTask {
  bet: DrawTaskWithProgress | null
  invite: DrawTaskWithProgress | null
  deposit: DrawTaskWithProgress | null
  checkIn: DrawTaskOneTime | null
}

export interface AcitivtyProps {
  taskId: number
  drawStyle: number
  name: string
  rule: string
  buttonDisabledText: string
  image?: string
  canPlay: boolean
  drawTimes: number
  prizes: PrizeProps[]
  task: ActivityTask
  updatedAt: number
  begin: number
  end: number
}

export interface TaskItemProps<T extends keyof ActivityTask> {
  data: ActivityTask[T]
}

export type ScenceProps = { isShow: boolean }
