import { Box, Image, Text } from '@chakra-ui/react'

import EmptyIcon from '@/assets/images/notification/empty.png'

const CONTAINER = {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}

const ICON = {
  width: '86px',
  height: '86px',
  objectFit: 'cover',
}

const TIPS = {
  color: 'gray.700',
  fontSize: 'text4',
  fontWeight: '600',
  lineHeight: '18px',
  marginTop: '24px',
  marginInline: '41px',
  textAlign: 'center',
}
function NotificationEmpty() {
  return (
    <Box sx={CONTAINER}>
      <Image src={EmptyIcon} sx={ICON} />
      <Text sx={TIPS}>There is no system message at the moment</Text>
    </Box>
  )
}

export default NotificationEmpty
