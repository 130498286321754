import { Box, Button, HStack, Icon, IconButton, Text } from '@chakra-ui/react'
import { clamp } from 'lodash-es'

import { Icon as SharedIcon } from '@/utils/atom-shared'

interface AtomPaginationProps {
  onNext(): void
  hasNext: boolean
  onPrev(): void
  hasPrev: boolean
  current: number
  total?: number
  pageSize?: number
  onChange?(page: number): void
  disabled?: boolean
  size?: 'sm' | 'md' | 'lg'
  style?: React.CSSProperties
  mode?: 'simple'
}

export default function AtomPagination(props: AtomPaginationProps) {
  const {
    onNext,
    hasNext,
    onPrev,
    hasPrev,
    current,
    onChange,
    disabled,
    size,
    style,
    mode,
  } = props

  const paginationItemRender = (page: number, size?: 'sm' | 'md' | 'lg') => {
    const onClick = () => {
      if (onChange && page !== current) {
        onChange(page)
      }
    }

    const getSizeValue = (size: 'sm' | 'md' | 'lg') => {
      switch (size) {
        case 'sm':
          return '30px'
        case 'md':
          return '38px'
        case 'lg':
          return '46px'
      }
    }

    return (
      <Button
        colorScheme='white'
        key={page}
        _hover={{ color: 'prim.500' }}
        color={current === page ? 'prim.500' : undefined}
        h={size ? getSizeValue(size) : '10'}
        minW='10'
        size={size}
        lineHeight='10'
        onClick={onClick}
      >
        <Text textStyle='text5'>{page}</Text>
      </Button>
    )
  }

  const simplePaginationItemRender = (
    page: number,
    totlePage: number,
    size?: 'sm' | 'md' | 'lg',
  ) => {
    const getSizeValue = (size: 'sm' | 'md' | 'lg') => {
      switch (size) {
        case 'sm':
          return '30px'
        case 'md':
          return '38px'
        case 'lg':
          return '46px'
      }
    }

    return (
      <Box
        bgColor='gray.500'
        key={page}
        _hover={{ color: 'prim.500' }}
        h={size ? getSizeValue(size) : '10'}
        minW='10'
        px='24px'
        borderRadius='6px'
      >
        <Text
          textStyle='text5'
          color='prim.500'
          lineHeight={size ? getSizeValue(size) : '10'}
        >
          {page}
          <Text as='span' color='gray.900'>
            /{totlePage}
          </Text>
        </Text>
      </Box>
    )
  }

  const paginationListRender = (size?: 'sm' | 'md' | 'lg') => {
    const { total, pageSize } = props
    if (typeof pageSize !== 'undefined' && typeof total !== 'undefined') {
      const WINDOW_SIZE = 5
      const itemCount = Math.ceil(total / pageSize)
      const start = clamp(
        current - 1 - Math.floor(WINDOW_SIZE / 2),
        0,
        itemCount - WINDOW_SIZE,
      )

      if (mode === 'simple') {
        return simplePaginationItemRender(
          current,
          Math.ceil(total / pageSize),
          size,
        )
      }

      return Array.from({ length: Math.min(WINDOW_SIZE, itemCount) }).map(
        (_, index) => paginationItemRender(start + index + 1, size),
      )
    } else {
      return paginationItemRender(current, size)
    }
  }

  return (
    <HStack justifyContent='center' mt='4' spacing='1.5' style={style}>
      <IconButton
        colorScheme='white'
        onClick={onPrev}
        _hover={{
          layerStyle: 'ls-prim',
        }}
        isDisabled={disabled || !hasPrev}
        icon={<Icon as={SharedIcon.ArrowLeft} />}
        aria-label='prev'
        size={size}
      />
      {paginationListRender(size)}
      <IconButton
        colorScheme='white'
        onClick={onNext}
        _hover={{
          layerStyle: 'ls-prim',
        }}
        isDisabled={disabled || !hasNext}
        icon={<Icon as={SharedIcon.ArrowRight1} />}
        aria-label='next'
        size={size}
      />
    </HStack>
  )
}
