import { Icon } from '@chakra-ui/react'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getVariable } from '@/common/env'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import projectConfig from '@/websites/current/dataCollection/projectConfig'

import type { FormItemProps } from './Form'
import type { ExtraFormItemProps } from './FormItem'
import { LoginFormInput } from './LoginFormInput'

const { emailSuffixs } = projectConfig

const FormItem: React.FC<
  FormItemProps & {
    isNeedAddonBefore?: boolean
  } & ExtraFormItemProps
> = ({
  label,
  name = '',
  value,
  onChange,
  registerFormItem,
  unregisterFormItem,
  updateFormItemError,
  error,
  rules,
  isNeedAddonBefore = true,
  disabled,
  addonBefore,
  addonAfter,
  fieldProps = {},
}) => {
  const { t } = useTranslation()
  const isInit = useRef(true)
  const [isInputEmail, setIsInputEmail] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.includes('@')) {
      setIsInputEmail(true)
    } else {
      setIsInputEmail(false)
    }

    onChange?.(name, e.target.value)
  }

  const validate = useCallback(async () => {
    let errorMsg = ''

    if (rules?.length) {
      for (const rule of rules) {
        try {
          if (rule?.validator) {
            await rule.validator(value)
          } else if (rule?.required) {
            if (!value) {
              throw new Error(rule?.message || 'is empty'.toString())
            }
          }
        } catch (error: any) {
          errorMsg = error.message
          // 处理抛出的错误，可以在此加入您的逻辑
          break // 终止循环
        }
      }
    }

    if (errorMsg) {
      updateFormItemError?.(
        name,
        t(errorMsg, {
          phone_digit: getVariable('PHONE_AREA_LENGTH'),
        }),
      ) // 更新错误信息
      return false
    }

    updateFormItemError?.(name, '')
    return true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    registerFormItem?.(name, validate)
    return () => {
      unregisterFormItem?.(name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate])

  useEffect(() => {
    !isInit.current && validate()
    isInit.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <LoginFormInput
      label={label}
      value={value}
      handleValueChange={handleChange}
      errorMsg={error}
      addonBefore={
        isNeedAddonBefore && addonBefore ? (
          addonBefore
        ) : (
          <Icon boxSize='5' as={SharedIcon.User1} />
        )
      }
      addonAfter={addonAfter}
      disabled={disabled}
      fieldProps={{
        name: 'email',
        type: 'text',
        placeholder: t('DEPOSIT_INPUT_ACCOUNT') || '',
        ...fieldProps,
        // autoComplete: 'new-password',
      }}
      autoComplete={emailSuffixs}
      openJudge={() => isInputEmail}
    />
  )
}

const ComboAccountFormItem = memo(FormItem)

ComboAccountFormItem.displayName = 'FormItem'

export default ComboAccountFormItem
