import { Box, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { getCurrencyCode } from '@/common/locale/currency'
import { decimalFormatByCode } from '@/common/locale/currency-const'
import LazyImage from '@/components/lazy-image/LazyImage'
import type { RechargeActListProps } from '@/modules/activity/ActInterface'
import type { WalletJoinActProps } from '@/modules/wallet/interface'
import { formatLocalMoney, getConfigLng } from '@/utils/tools'

import useRechargeActivity, { handleGradRate } from './useRechargeActivity'

const lng = getConfigLng()

export default function WalletJoinAct({
  amount,
  type = 'text',
  isFromRechargeAct = false,
}: WalletJoinActProps) {
  const CURRENCY = getCurrencyCode()
  const { t } = useTranslation()
  const { getActItemByAmount, getBonusByAmount } = useRechargeActivity()
  const activedItem = getActItemByAmount(
    Number(amount),
  ) as unknown as RechargeActListProps

  const bonus = getBonusByAmount(Number(amount)) || 0

  if ((!activedItem.id || activedItem.id === -1) && !isFromRechargeAct) {
    return null
  }

  const { reward_type, reward_grad, reward_fixed } =
    activedItem?.reward_config?.[CURRENCY] || {}

  const rate = [1, 3].includes(reward_type)
    ? reward_type === 1
      ? Number(reward_fixed?.reward_value || 0)
      : handleGradRate(reward_grad, Number(amount))
    : ''

  /** 渲染之前的奖励展示 */
  const renderOldBonusCard = () => {
    return (
      <Flex
        width='100%'
        height='82px'
        bgImg={'url("/assets/images/recharge-act-bg.png")'}
        bgPosition='top'
        bgSize='cover'
      >
        <Text
          px={4}
          py={3}
          fontSize='12px'
          lineHeight='16px'
          w='188px'
          color={'#fff'}
          noOfLines={4}
          height='76px'
        >
          <Box
            as='div'
            wordBreak='break-word'
            className='ck-content'
            dangerouslySetInnerHTML={{
              __html:
                activedItem?.meta?.language_content?.[lng]?.active_name || '',
            }}
          />
        </Text>
        <Text
          flex='1'
          display='flex'
          alignItems='center'
          justifyContent='center'
          fontFamily='Montserrat-Black, Montserrat'
          fontStyle='italic'
          fontWeight='900'
          fontSize='44px'
          background='rechargeActBlock.numBg'
          backgroundClip='text'
        >
          {bonus > 0
            ? Number(rate) > 0
              ? Number(rate) * 100 + '%'
              : '+' + decimalFormatByCode(CURRENCY, bonus)
            : ''}
        </Text>
      </Flex>
    )
  }

  /** 渲染新的奖励展示 */
  const renderNewBonusCard = () => {
    return (
      <>
        <Box
          position='relative'
          borderRadius='10px'
          overflow='hidden'
          minH='96px'
          px={4}
          pb='4px'
          mt='14px'
          bg='linear-gradient(94.17deg, #6B4922 0.25%, #D2A860 50.93%, #F2E2B0 99.78%)'
        >
          <Flex
            position='absolute'
            top={0}
            left={0}
            px={4}
            w='full'
            height='30px'
            bgColor='rgba(0, 0, 0, 0.1)'
            alignItems='center'
            justifyContent='space-between'
          >
            <Text
              fontSize='14px'
              fontStyle='italic'
              fontWeight={600}
              color='#fff'
            >
              {t('Prize')}：
            </Text>
            <Text
              color='four.500'
              fontWeight={800}
              fontStyle='italic'
              fontSize='16px'
            >
              {'+ ' + formatLocalMoney(Number(bonus))}
            </Text>
          </Flex>
          <Text
            pt='36px'
            fontSize='14px'
            lineHeight='18px'
            fontStyle='italic'
            fontWeight={600}
            color='#fff'
          >
            {t('RECHARGE_CK_TT')}
          </Text>
          <Text
            fontWeight={800}
            fontStyle='italic'
            fontSize='24px'
            lineHeight='40px'
            background='linear-gradient(90deg, #FFFAAA 0%, #FFFFFF 56.41%, #FFFAAC 100%)'
            backgroundClip='text'
          >
            {formatLocalMoney(Number(amount + bonus))}
          </Text>
        </Box>
      </>
    )
  }

  return (
    <Box
      position='relative'
      borderRadius={'10px'}
      overflow='hidden'
      fontSize={'12px'}
      marginBottom='8px'
      height={!isFromRechargeAct && type === 'text' ? '110px' : 'auto'}
    >
      {type === 'pic' ? (
        <LazyImage
          width='100%'
          height='auto'
          src={activedItem && activedItem?.image_url}
        />
      ) : !isFromRechargeAct ? (
        renderOldBonusCard()
      ) : (
        renderNewBonusCard()
      )}
      {!isFromRechargeAct && (
        <Flex
          position='absolute'
          bottom={'0'}
          left='0'
          right='0'
          h='30px'
          alignItems={'center'}
          zIndex='1'
          bg={'gold.500'}
          px='16px'
          justifyContent='space-between'
          bgColor='rechargeActBlock.bottomBg'
        >
          <Text textAlign='center' color={'#fff'}>
            {t('REACHRGA_TASK_BONUS')}
          </Text>
          <Text textAlign='center' color={'#fff'}>
            {Number(rate) > 0 ? Number(rate) * 100 + '%' : ''}
          </Text>
          <Text textAlign='center' color={'#fff'}>
            {activedItem && decimalFormatByCode(CURRENCY, bonus)}
            {' ' + CURRENCY}
          </Text>
        </Flex>
      )}
    </Box>
  )
}
