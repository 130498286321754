import { Flex } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'

import RankingTitle from '@/assets/images/ranking/ranking-title-img.png'
import { useCustomizedStyle } from '@/utils/hooks'

export const NAME = 'RankingHeadBox'
export const RankingHeadBox: FC<PropsWithChildren> = ({ children }) => {
  const styles = useCustomizedStyle(NAME, {
    borderRadius: 'lg',
    pt: '14',
    pb: '3',
    px: '3',
    mb: '5',
    justifyContent: 'center',
    bg: `no-repeat center 12px / 226px 92px url(${RankingTitle}), var(--bg-gradient)`,
  })

  return <Flex sx={styles}>{children}</Flex>
}

export default RankingHeadBox
