import type { SystemStyleObject } from '@chakra-ui/styled-system'

export const NAME = 'AtomNavAccordionItem'
export const PARTS = [
  'accordionItem',
  'panel',
  'buttonRoot',
  'buttonContent',
  'buttonHeading',
  'buttonBeforeIcon',
  'buttonSubheading',
] as const
export const VARIANTS = ['simple', 'image'] as const
export type Part = (typeof PARTS)[number]
export type Variant = (typeof VARIANTS)[number]
export type VariantsStyles = Record<
  Variant,
  Partial<Record<Part, SystemStyleObject>>
>
