import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useState } from 'react'

export function useToggleState(
  initVal: boolean,
): [boolean, Dispatch<SetStateAction<boolean>>, () => void] {
  const [value, setValue] = useState(initVal)
  const handleToggle = useCallback(() => setValue(val => !val), [])
  return [value, setValue, handleToggle]
}
