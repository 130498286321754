import type { MultiStyleConfig, StyleConfig } from '@chakra-ui/react'
import {
  createMultiStyleConfigHelpers,
  defineStyleConfig,
} from '@chakra-ui/react'

import { assignExcept } from '@/utils/object'
import componentCfg from '@/websites/current/styles/components'

// 官方组件的样式key，见
// @see https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/index.ts

/**  */
const components: { [key: string]: any } = {}

function setComponentStyle(name: string, style: StyleConfig) {
  components[name] = defineStyleConfig(style)
}

function setMultiComponentStyle(name: string, style: MultiStyleConfig) {
  const helper = createMultiStyleConfigHelpers(style.parts)
  const config = helper.defineMultiStyleConfig(style)
  components[name] = config
}

function composeBaseStyle(baseStyle: any) {
  if (baseStyle['colorScheme']) {
    const schemeCache: { [key: string]: any } = {}
    return ({ colorScheme }: any) => {
      if (schemeCache[colorScheme]) return schemeCache[colorScheme]
      const base = assignExcept({}, baseStyle, 'colorScheme')
      const colorSchemeStyle = Object.assign(
        base,
        baseStyle['colorScheme'][colorScheme],
      )
      schemeCache[colorScheme] = colorSchemeStyle
      return colorSchemeStyle
    }
  } else {
    return baseStyle
  }
}

function parseConfig(config: any): StyleConfig | MultiStyleConfig {
  const ret = { ...config }
  if (config.baseStyle) {
    ret.baseStyle = composeBaseStyle(config.baseStyle)
  }
  return ret
}

// 生成组件样式
for (const name in componentCfg) {
  const config: StyleConfig | MultiStyleConfig = parseConfig(
    (componentCfg as any)[name],
  )
  if ((config as MultiStyleConfig)['parts']) {
    setMultiComponentStyle(name, config as MultiStyleConfig)
  } else {
    setComponentStyle(name, config)
  }
}

export default components
