import dayjs from 'dayjs'

import type { Ext, LevelProtectionStatus } from '../../types'

/**
 *
 * @param levelExpireRemainSec
 * @param betRelegation
 * @param rechargeRelegation
 * @returns
 */
export function getHasSetLevelProtection(
  levelExpireRemainSec: unknown,
  betRelegation: unknown,
  rechargeRelegation: unknown,
  VipExpireEffectedAt: number,
) {
  /** 最少设置一个条件 */
  const atLeastOneSet =
    (typeof betRelegation === 'number' && betRelegation > 0) ||
    (typeof rechargeRelegation === 'number' && rechargeRelegation > 0)

  /** 是否设置了保级时间 */
  const hasSetLevelExpireTs =
    typeof levelExpireRemainSec === 'number' &&
    levelExpireRemainSec > 0 &&
    VipExpireEffectedAt > 0

  const now = dayjs().unix()
  /** 是否到达开启时间 */
  const hasInTime = hasSetLevelExpireTs && now >= VipExpireEffectedAt

  return hasInTime && atLeastOneSet
}

/**
 * 获取当前vip等级保护状态
 * @param
 * @returns return LevelProtectionStatus
 */
export function getLevelProtectionStatus({
  vipExpireEffectedAt,
  ext,
}: {
  vipExpireEffectedAt: number
  ext: Ext
}): LevelProtectionStatus {
  if (ext.pass_level_expire_check) return 'safe'

  if (typeof vipExpireEffectedAt === 'number' && vipExpireEffectedAt > 0) {
    if (ext.level_expire_remain_sec <= vipExpireEffectedAt * 24 * 60 * 60)
      return 'danger'

    return 'unsafe'
  } else {
    return 'none'
  }
}
