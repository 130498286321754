import { getVariable } from '@/common/env'
import { isFeatureEnabled } from '@/common/feature'
import { getCurrencyCode } from '@/common/locale/currency'
import { projectName } from '@/websites/current/property.json'

import type { AmountRechargeProp } from './walletContansts'
import type { RechargeQuickerBtnConfigProps } from './walletInterface'

const withdrawWithPhoneAreaCode = isFeatureEnabled('withdrawWithPhoneAreaCode')
const PHONE_AREA_CODE = getVariable('PHONE_AREA_CODE')
/**
 * 处理钱包配置
 */
export const handleWalletConfig = (config: RechargeQuickerBtnConfigProps[]) => {
  const list = [] as AmountRechargeProp[]
  const CURRENCY = getCurrencyCode()
  const _list = config.slice()?.sort((a, b) => a.sort_weight - b.sort_weight)
  _list?.forEach(item => {
    const { currency_content, is_hot } = item
    if (item?.status === 1 && currency_content?.[CURRENCY]?.btn_amount > 0) {
      list.push({
        value: currency_content?.[CURRENCY]?.btn_amount,
        index: list.length,
        isHot: is_hot === 1,
      })
    }
  })

  return list
}

export const handlePIX = (value: string) => {
  const newValue = value?.replace(/[^\d]+/g, '') || ''
  let first = newValue.slice(0, 3)
  first = first.length === 3 ? first + '.' : first
  let second = newValue.slice(3, 6)
  second = second.length === 3 ? second + '.' : second
  let third = newValue.slice(6, 9)
  third = third.length === 3 ? third + '-' : third
  const forth = newValue.slice(9)
  const end = first + second + third + forth
  if (end.endsWith('.') || end.endsWith('-')) {
    return end.slice(0, end.length - 1)
  } else {
    return end
  }
}

// 去除带有区号的手机号
export const handleRemovePhoneWithAreaCode = (value: string) => {
  if (!withdrawWithPhoneAreaCode) return value
  const areaCode = PHONE_AREA_CODE?.slice(1)
  if (value?.startsWith(areaCode)) {
    return value?.slice(areaCode?.length || 0)
  } else {
    return value
  }
}

export const handlePIXSubmitInfo = (obj: Record<string, any>) => {
  const _obj = Object.assign({}, obj)
  if (_obj.pix) {
    _obj.pix = _obj.pix.replace(/[^\d]+/g, '')
  }
  if (_obj?.name) {
    _obj.name = _obj.name?.trim() || ''
  }

  // 提现充值手机号需要带上区号
  if (_obj?.account && withdrawWithPhoneAreaCode) {
    _obj.account = PHONE_AREA_CODE?.slice(1) + _obj.account
  }

  if (
    isFeatureEnabled('newRechargeParamsStyle') &&
    ['PIXQR', 'PIXQRNEW'].includes(_obj?.typ?.toLocaleUpperCase())
  ) {
    delete _obj.phone
    delete _obj.email
  }
  try {
    for (let key in _obj) {
      if (typeof _obj[key] === 'string') {
        _obj[key] = _obj[key]?.trim()
      }
    }
  } catch (e) {
    //
  }
  return _obj
}
