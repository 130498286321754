import { Box, Flex, Highlight, Image, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useMemo } from 'react'

import rechargeIcon from '@/assets/images/lucky-wheel/recharge.png'
import { useAtomCountDown } from '@/components/atom-count-down'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { openWallet } from '@/modules/global-modal/globalModalSlice'
import { formatLocalMoney } from '@/utils/tools'

import {
  getCurrentUserRewardInfo,
  getRewardMinDeposit,
} from '../luckyWheelSlice'

const landingPageStyle = {
  bg: 'rgba(255, 255, 255, .1)',
  btnColor: 'prim.500',
}

function RechargeProgressCard({ origin }: { origin?: 'landingPage' }) {
  const rewardMinDeposit = useAppSelector(getRewardMinDeposit)
  const { currentDeposit, rewardMinDeposit: rewardMinDepositRes } =
    rewardMinDeposit as { currentDeposit: number; rewardMinDeposit: number }
  const { firstDrawTime } = useAppSelector(getCurrentUserRewardInfo)
  const { days, hours, minutes, seconds } = useAtomCountDown({
    targetDate: dayjs(firstDrawTime * 1000)
      .add(3, 'day')
      .format('YYYY-MM-DD HH:mm:ss'),
  })
  const inviteFriendCountDown = useMemo(() => {
    return `${Number(days) * 24 + Number(hours)}:${minutes}:${seconds}`
  }, [days, hours, minutes, seconds])

  const dispatch = useAppDispatch()

  return (
    <>
      <Box
        w='full'
        bg={
          origin === 'landingPage'
            ? landingPageStyle.bg
            : 'linear-gradient(180deg, #21A400 0%, #4DD42B 100%)'
        }
        borderRadius='16px'
        mt='3'
        px='3'
        h='88px'
      >
        <Flex alignItems='flex-start' justifyContent='space-between' h='100%'>
          <Image
            src={rechargeIcon}
            w='14'
            h='14'
            mt='3'
            objectFit='cover'
            flexShrink={0}
          />
          <Flex
            mx='8px'
            h='100%'
            flexWrap='wrap'
            flexDirection='column'
            justifyContent='center'
            w='100%'
          >
            {currentDeposit < rewardMinDepositRes && (
              <Text
                textStyle='h7'
                color='white'
                fontWeight='600'
                noOfLines={3}
                w='100%'
              >
                <Highlight
                  query={[
                    `${formatLocalMoney(
                      currentDeposit || 0,
                    )}/${formatLocalMoney(rewardMinDepositRes || 0)}`,
                  ]}
                  styles={{ color: 'four.500', mx: '1' }}
                >
                  {t('DEPOSIT_NOT_REACH_REDUCE', {
                    num: `${formatLocalMoney(
                      currentDeposit || 0,
                    )}/${formatLocalMoney(rewardMinDepositRes || 0)}`,
                  }) ?? ''}
                </Highlight>
              </Text>
            )}
            <Text textStyle='h3' color='four.500' mt='4px' w='100%'>
              {inviteFriendCountDown}
            </Text>
          </Flex>

          <Box
            mt='18px'
            bg={
              origin === 'landingPage' ? landingPageStyle.btnColor : '#1E7C06'
            }
            color='white'
            borderRadius='10px'
            textStyle='h6'
            py='2'
            w='82px'
            whiteSpace='normal'
            onClick={() => {
              dispatch(openWallet())
            }}
            cursor='pointer'
            flexShrink={0}
            textAlign='center'
          >
            {t('NAV_DEPOSIT')}
          </Box>
        </Flex>
      </Box>
    </>
  )
}

export default RechargeProgressCard
