import { Button } from '@chakra-ui/react'
import { useRef, useState } from 'react'

import outImage from './out.png'

const boxSize = 38
function CloseBtn({ closeCb }: { closeCb: () => void }) {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const touchStartPos = useRef({ x: 0, y: 0 })
  const viewportWidth = window.innerWidth
  const viewportHeight = window.innerHeight

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartPos.current.x = e.touches[0].clientX - position.x
    touchStartPos.current.y = e.touches[0].clientY - position.y
  }

  const handleTouchMove = (e: React.TouchEvent) => {
    const x = e.touches[0].clientX - touchStartPos.current.x
    const y = e.touches[0].clientY - touchStartPos.current.y

    setPosition({
      x: getLimitedNumber({
        num: x,
        min: -20 + 10,
        max: viewportWidth - boxSize - 20 - 10,
      }),
      y: getLimitedNumber({
        num: y,
        min: -20 + 10,
        max: viewportHeight - boxSize - 20 - 10,
      }),
    })
  }

  return (
    <Button
      onClick={() => {
        closeCb()
      }}
      mr='2'
      w={`${boxSize}px`}
      h={`${boxSize}px`}
      bgColor='rgba(0,0,0,0.2)'
      p='1'
      color={'white'}
      borderRadius='full'
      position='fixed'
      zIndex={3}
      top='20px'
      left='20px'
      transition='none'
      style={{
        transform: `translate(${position.x}px, ${position.y}px)`,
        background: `url(${outImage}) no-repeat center / 38px`,
      }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    />
  )
}

export default CloseBtn

function getLimitedNumber({
  num,
  min,
  max,
}: {
  num: number
  min: number
  max: number
}): number {
  if (num <= min) return min
  if (num >= max) return max

  return num
}
