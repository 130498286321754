import { useCallback, useReducer, useRef } from 'react'
import {
  ContextModalProps,
  ModalsContext,
  ModalsContextProps,
  ModalSettings,
  OpenConfirmModal,
  OpenContextModal,
} from './context'
import { useModalsEvents } from './events'
import { modalsReducer } from './reducer'
import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'

const randomId = () => Date.now() + Math.random() + ''

export interface ModalsProviderProps {
  /** Your app */
  children?: React.ReactNode

  /** Predefined modals */
  modals?: Record<string, React.FC<ContextModalProps<any>>>

  /** Shared Modal component props, applied for every modal */
  modalProps?: ModalSettings
}


export function ModalsProvider({
  children,
  modalProps,
  modals,
}: ModalsProviderProps) {
  const [state, dispatch] = useReducer(modalsReducer, {
    modals: [],
    current: null,
  })
  const stateRef = useRef(state)
  stateRef.current = state

  const closeAll = useCallback(
    (canceled?: boolean) => {
      dispatch({ type: 'CLOSE_ALL', canceled })
    },
    [stateRef, dispatch],
  )

  const openModal = useCallback(
    ({ modalId, ...props }: ModalSettings) => {
      console.log(modalId, props)
      const id = modalId || randomId()

      dispatch({
        type: 'OPEN',
        modal: {
          id,
          type: 'content',
          props,
        },
      })
      return id
    },
    [dispatch],
  )

  const openConfirmModal = useCallback(
    ({ modalId, ...props }: OpenConfirmModal) => {
      const id = modalId || randomId()
      dispatch({
        type: 'OPEN',
        modal: {
          id,
          type: 'confirm',
          props,
        },
      })
      return id
    },
    [dispatch],
  )

  const openContextModal = useCallback(
    (modal: string, { modalId, ...props }: OpenContextModal) => {
      const id = modalId || randomId()
      dispatch({
        type: 'OPEN',
        modal: {
          id,
          type: 'context',
          props,
          ctx: modal,
        },
      })
      return id
    },
    [dispatch],
  )

  const closeModal = useCallback(
    (id: string, canceled?: boolean) => {
      dispatch({ type: 'CLOSE', modalId: id, canceled })
    },
    [stateRef, dispatch],
  )

  useModalsEvents({
    openModal,
    openConfirmModal,
    openContextModal: ({ modal, ...payload }: any) =>
      openContextModal(modal, payload),
    closeModal,
    closeContextModal: closeModal,
    closeAllModals: closeAll,
  })

  const ctx: ModalsContextProps = {
    modals: state.modals,
    openModal,
    openConfirmModal,
    openContextModal,
    closeModal,
    closeContextModal: closeModal,
    closeAll,
  }

  const getCurrentModal = () => {
    const currentModal = stateRef.current.current
    switch (currentModal?.type) {
      case 'context': {
        const { innerProps, ...rest } = currentModal.props
        const ContextModal = modals![currentModal.ctx]

        return {
          modalProps: rest,
          content: (
            <ContextModal
              innerProps={innerProps}
              context={ctx}
              id={currentModal.id}
            />
          ),
        }
      }
      case 'content': {
        const { children: currentModalChildren, ...rest } = currentModal.props

        return {
          modalProps: rest,
          content: currentModalChildren,
        }
      }
      default: {
        return {
          modalProps: {},
          content: null,
        }
      }
    }
  }

  const { modalProps: currentModalProps, content } = getCurrentModal()

  const isOpen = state.modals.length > 0

  return (
    <ModalsContext.Provider value={ctx}>
      {isOpen && <Modal
        {...modalProps}
        {...currentModalProps}
        isOpen={isOpen}
        onClose={() => closeModal(state.current?.id as any)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
          zIndex={10}
            sx={{
              right: 1,
              top: 1,
            }}
          >
            <CloseIcon />
          </ModalCloseButton>

          {content}
        </ModalContent>
      </Modal>}

      {children}
    </ModalsContext.Provider>
  )
}
