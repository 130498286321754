import {
  createDraftSafeSelector,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'

import { getCurrencyCode } from '@/common/locale/currency'
import { startAppListening } from '@/modules/app/listenerMiddleware'
import type { RootState } from '@/modules/app/store'

import { calcNumber } from './components/utils'
import { luckyWheelApi } from './luckyWheelApi'

interface StateProps {
  // 奖品列表
  reward: {
    // 奖品的打码量
    betTarget: number
    // 是否已经领取
    isClaim: boolean
    // 奖品的金额
    reward: number
    // 奖品的id
    bizId?: string
  }
  // 配置的奖励金额
  configReward: string
  // 分享平台
  sharePlatform?: string[]
  // 当前周期已获得的奖励
  currentReward: string
  // 当前周期首抽时间
  firstDrawTime: number
  // 当前周期是否在首抽后首充
  isFirstRecharge: boolean
  // 当前周期用户可抽奖次数
  opportunity: number
  // 剩余多少可以领取奖励
  remainReward: number
  // 活动时间开始
  startTime: number
  // 活动结束时间
  endTime: number
  // 状态   1开启 2关闭
  state: number
  // 当前已经获得多少金额的奖金
  totalReward?: number
  /** 累计充值要求 */
  rewardMinDeposit?: number
  /** 当前周期充值金额 */
  currentDeposit?: number
  /** 获取转盘接口请求状态 */
  loadedWheelInfo?: boolean
}

const initialState: StateProps = {
  sharePlatform: [],
  // 奖品列表
  reward: {
    // 奖品的打码量
    betTarget: 0,
    // 是否已经领取
    isClaim: false,
    // 奖品的金额
    reward: 0,
    // 奖品的id
    bizId: undefined,
  },
  configReward: '0',
  currentReward: '0',
  firstDrawTime: 0,
  isFirstRecharge: false,
  opportunity: 0,
  remainReward: 0,
  startTime: 0,
  endTime: 0,
  // 状态   1开启 2关闭
  state: 2,
  totalReward: 0,
  rewardMinDeposit: 0,
  currentDeposit: 0,
  /** 获取转盘接口请求状态 */
  loadedWheelInfo: false,
}

export const luckyWheelSlice = createSlice({
  name: 'luckyWheelSlice',
  initialState,
  reducers: {
    setCurrentReward(state, action) {
      state.currentReward = action.payload
    },
    setRemainReward(state, action) {
      state.remainReward = action.payload
    },
    setWheelInfoLoaded: (state, { payload }: { payload: boolean }) => {
      state.loadedWheelInfo = payload
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        luckyWheelApi.endpoints.getLuckyWheelConfig.matchFulfilled,
        (state, action) => {
          // state.sharePlatform = action.payload.share_platform
          const { data } = action.payload
          const { reward } = data
          const currencyCode = getCurrencyCode()
          state.reward = {
            betTarget: reward?.bet_target || 0,
            isClaim: Boolean(reward?.is_claim) || false,
            reward: reward?.reward || 0,
            bizId: reward?.biz_id || undefined,
          }

          state.sharePlatform = data?.conf?.config?.share_platform || []
          state.currentReward = data?.info?.current_reward || '0'
          state.firstDrawTime = data?.info?.first_draw_time || 0
          state.isFirstRecharge = data?.info?.is_first_recharge || false
          state.opportunity = data?.info ? data?.info?.opportunity || 0 : 1
          state.configReward = data?.conf?.config?.reward?.[currencyCode] || '0'
          state.remainReward =
            Number(data?.conf?.config?.reward?.[currencyCode] || 0) -
            Number(data?.info?.current_reward || 0)
          state.startTime = data?.conf?.config?.begin || 0
          state.endTime = data?.conf?.config?.end || 0
          state.state = data?.conf?.state || 2
          state.totalReward = data?.info?.total || 0
          state.rewardMinDeposit = data?.conf?.config?.reward_min_deposit
          state.currentDeposit = data?.info?.current_deposit
          state.loadedWheelInfo = true
        },
      )
      .addMatcher(
        luckyWheelApi.endpoints.getLuckyWheelConfig.matchRejected,
        (state, action) => {
          state.loadedWheelInfo = false
        },
      )
  },
})

export const luckyWheelReducer = luckyWheelSlice.reducer

const selectSelf = (state: RootState) => state
export const selectLuckyWheel = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => state.luckyWheel,
)

export const getLuckyWheelSharePlatform = createSelector(
  selectLuckyWheel,
  luckyWheel => luckyWheel.sharePlatform,
)

export const getLuckyWheelActivityIsOpen = createSelector(
  selectLuckyWheel,
  luckyWheel => luckyWheel.state === 1,
)

export const getLuckyWheelConfigReward = createSelector(
  selectLuckyWheel,
  luckyWheel => {
    return {
      reward: luckyWheel.configReward,
    }
  },
)

export const getLuckyWheelRewardInfo = createSelector(
  selectLuckyWheel,
  luckyWheel => luckyWheel.reward,
)

export const getCurrentUserRewardInfo = createSelector(
  selectLuckyWheel,
  luckyWheel => ({
    currentReward: luckyWheel.currentReward,
    firstDrawTime: luckyWheel.firstDrawTime,
    isFirstRecharge: luckyWheel.isFirstRecharge,
    opportunity: luckyWheel.opportunity,
  }),
)

export const getLuckyWheelCurrentRewardDiff = createSelector(
  selectLuckyWheel,
  luckyWheel => {
    return luckyWheel.remainReward < 0 ? 0 : luckyWheel.remainReward
  },
)

export const getLuckyWheelActivityTimeRange = createSelector(
  selectLuckyWheel,
  luckyWheel => {
    return {
      startTime: luckyWheel.startTime,
      endTime: luckyWheel.endTime,
    }
  },
)

export const getLuckyWheelCurrentProgress = createSelector(
  selectLuckyWheel,
  luckyWheel => {
    const { currentReward, configReward } = luckyWheel
    // const temp = Math.floor(
    //   (Number(currentReward) / Number(configReward)) * 100,
    // )
    // return temp < 0 ? 0 : temp > 100 ? 100 : temp
    const fixed2ConfigReward = calcNumber(configReward)
    const fixed2CurrentReward = calcNumber(currentReward)
    return Math.floor(
      (Number(fixed2CurrentReward) / Number(fixed2ConfigReward)) * 100,
    )
  },
)

export const getLuckyWheelCurrentUserTotalReward = createSelector(
  selectLuckyWheel,
  luckyWheel => {
    return luckyWheel.totalReward
  },
)

export const getRewardMinDeposit = createSelector(
  selectLuckyWheel,
  luckyWheel =>
    !luckyWheel.rewardMinDeposit
      ? false
      : {
          rewardMinDeposit: luckyWheel.rewardMinDeposit,
          currentDeposit: luckyWheel.currentDeposit,
        },
)

/**
 * 获取转盘信息接口请求状态
 */
export const selectWheelInfoLoaded = createSelector(
  selectLuckyWheel,
  luckyWheel => luckyWheel.loadedWheelInfo,
)

export const { setRemainReward, setWheelInfoLoaded } = luckyWheelSlice.actions

// 监听绑定货币成功的 action，动态更新 i18n
startAppListening({
  matcher: luckyWheelApi.endpoints.getLuckyWheelConfig.matchPending,
  effect: (_, store) => {
    if (!store.getState().luckyWheel.firstDrawTime) {
      store.dispatch(setWheelInfoLoaded(false))
    }
  },
})
