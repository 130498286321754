import { Modal, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '@/modules/app/store'

import { Loading } from '../../pages/slot-and-live/index'
import { getFullPageText, getShowFullPageLoading } from './walletSlice'

export default function FullPageLoading() {
  const { t } = useTranslation()
  const isOpen = useAppSelector(getShowFullPageLoading)
  const text = useAppSelector(getFullPageText)
  // const
  return (
    <Modal autoFocus={false} trapFocus={false} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <Loading />
        <Text
          position='absolute'
          w='100%'
          h='100%'
          left='0'
          top='50px'
          display='flex'
          justifyContent='center'
          alignItems='center'
          color='prim.500'
          textAlign='center'
        >
          {t(text)}
        </Text>
      </ModalContent>
    </Modal>
  )
}
