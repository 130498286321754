import { EventName } from '../const'
import type { Channel, ITrackerBase } from './types'

export class TrackerTiktok implements ITrackerBase {
  channel: Channel

  eventMap = {
    // [EventName.ContentView]: ['ViewContent'], // 浏览
    [EventName.CompleteRegistration]: ['CompleteRegistration'], // 注册
    [EventName.CompletePurchase]: ['CompletePayment'], // 充值
  }

  constructor(private channelId: string) {
    this.channel = { name: 'tiktlk', id: channelId }
  }

  trackSingleEvent(
    event: string,
    _originEventName: EventName,
    data?: any,
  ): void {
    ttq.instance(this.channelId).track(event, data)
  }
}
