import { useMemo } from 'react'

import { useAppSelector } from '@/modules/app/store'
import { selectAccountsInfo } from '@/modules/user/userSlice'

import { AccountType } from '../loginApi'

const ACCOUNT_TYPE_TEXT = {
  [AccountType.AT_EMAIL]: 'email',
  [AccountType.AT_FACEBOOK]: 'facebook',
  [AccountType.AT_GOOGLE]: 'google',
  [AccountType.AT_PHONE]: 'phone',
  [AccountType.AT_TELEGRAM]: 'telegram',
}

function useAccountsInfo() {
  const accountsInfo = useAppSelector(selectAccountsInfo)

  /**
   * 当前账号验证状态
   */
  const bindInfoValue:
    | Record<
        'email' | 'phone' | 'google' | 'facebook' | 'telegram',
        {
          value: string
          verified: boolean
        }
      >
    | undefined = useMemo(() => {
    return accountsInfo?.reduce((accv: any, curv) => {
      accv[ACCOUNT_TYPE_TEXT[curv.account_type]] = {
        value: curv.account_value,
        verified: curv.verified,
      }

      return accv
    }, {})
  }, [accountsInfo])

  return {
    bindInfoValue,
  }
}

export default useAccountsInfo
