import { api } from '@/services/api'

export const remindModalApi = api.injectEndpoints({
  endpoints: builder => ({
    getTgModalRemind: builder.query<any, { user_id: string; token: string }>({
      query: data => ({
        url: '/api/v1/platform/asset_order/behavior/get_show_msg_cache',
        method: 'POST',
        data: {
          user_id: data.user_id,
          token: data.token,
        },
      }),
    }),
    setTgModalRemind: builder.mutation<any, { user_id: string; token: string }>(
      {
        query: data => ({
          url: '/api/v1/platform/asset_order/behavior/set_show_msg_cache',
          method: 'POST',
          data: {
            user_id: data.user_id,
            token: data.token,
          },
        }),
      },
    ),
  }),
})

export const { useGetTgModalRemindQuery, useSetTgModalRemindMutation } =
  remindModalApi
