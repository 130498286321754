import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiFillCaretRight } from 'react-icons/ai'
import { Link as RouterLink } from 'react-router-dom'

import { AtomSectionHeading } from '@/components/atom-section-heading/AtomSectionHeading'
import type { Orientation } from '@/modules/game/units/gamecard/AtomGameCard'
import AtomGameCard from '@/modules/game/units/gamecard/AtomGameCard'
import generateOnlineUser from '@/modules/game/units/utils/generate-online-user'
import { Icon as SharedIcon } from '@/utils/atom-shared'

import { GameGrid } from '../../all-games/GameGrid'
import { DEFAULT_GAME_VIEW_OPTION, GRID_COLUMNS } from '../../constants'
import { BuiltInGameLabel } from '../../home-games/types'
import GameCardRender from '../GameCardRender'
import SwiperGames from '../GamesListwiper'
import ProviderListwiperMemo from '../ProviderListwiper'
import { FadingBox } from './FadingBox'
import type { GroupGameProps } from './GroupGameProps'
import { isFeatureEnabledV2 } from '@/common/featureSwiitch'

const VIEW_ALL_GAMES_IMG = '/assets/images/about-icon/games/view_all_img.png'
const VIEW_ALL_GAMES_HORIZONTAL_IMG =
  '/assets/images/about-icon/games/view_all_horizontal_img.png'

export type GameViewOption = {
  rowsPerView: number // 每页显示几行
  loadMoreType: number // 加载更多类型
  coverOrientation: Orientation // 封面方向
}

export function DesktopGroupGame({
  datasource,
  type,
  label,
  viewOption = DEFAULT_GAME_VIEW_OPTION,
  onItemClick,
}: GroupGameProps) {
  const chunkSize = useBreakpointValue({
    base: 'base',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
  })
  const { t } = useTranslation()
  const { name, data, icon, game_number, providerSort } = datasource
  const [swiperIns, setSwiperIns] = useState<any>(null)
  const [onlineUserCount] = useState(generateOnlineUser())
  const games = useMemo(() => {
    if (
      datasource?.data?.length > 12 &&
      viewOption?.loadMoreType === 1 &&
      chunkSize !== 'md'
    ) {
      return datasource?.data.slice(0, 11)
    }

    return datasource?.data.slice(0, 12)
  }, [datasource, viewOption, chunkSize])
  const itemNumberPerRow = useBreakpointValue(GRID_COLUMNS) ?? GRID_COLUMNS.lg

  const onSwiperInit = (swiper: any) => {
    setSwiperIns(swiper)
  }

  const showAllRender = () => {
    return (
      <Link
        as={RouterLink}
        to={`/game/all?cate=${type}&label=${label}`}
        display='block'
        // {...cardContainerStyles}
      >
        <AtomGameCard
          src={
            viewOption.coverOrientation === 'portrait'
              ? VIEW_ALL_GAMES_IMG
              : VIEW_ALL_GAMES_HORIZONTAL_IMG
          }
          orientation={viewOption.coverOrientation}
        />
      </Link>
    )
  }

  const contentRender = (option: GameViewOption) => {
    const { rowsPerView, loadMoreType, coverOrientation } = option
    if (loadMoreType === 1 || type === 2) {
      return (
        <FadingBox>
          {type === 2 ? (
            <ProviderListwiperMemo
              onSwiperInit={onSwiperInit}
              manulacturerList={providerSort}
            />
          ) : (
            <SwiperGames
              rowsPerView={rowsPerView}
              games={games}
              showAllRender={
                datasource.data.length > 12 ? showAllRender : undefined
              }
              onSwiperInit={onSwiperInit}
              itemRender={game => (
                <GameCardRender
                  info={game}
                  onClick={() => onItemClick?.(game)}
                  orientation={coverOrientation}
                />
              )}
            />
          )}
        </FadingBox>
      )
    } else {
      const isOptimizeDisplay = isFeatureEnabledV2('optimise_display_game_quantity') ? rowsPerView * 2 : rowsPerView
      return (
        <GameGrid
          data={datasource.data}
          onCardClick={onItemClick}
          pageSize={itemNumberPerRow * isOptimizeDisplay}
          coverOrientation={coverOrientation}
          my='0'
        />
      )
    }
  }

  return (
    <VStack alignItems='stretch' spacing={['3', '3', '5']}>
      <HStack justifyContent='space-between' spacing='4'>
        <AtomSectionHeading icon={icon} useImage={true}>
          {t(name)}
        </AtomSectionHeading>

        {type !== 2 && (
          <Flex flex='1' alignItems='center' textStyle='text3'>
            <Flex
              bgColor='bg.control'
              color='text.accent'
              alignItems='center'
              borderRadius='base'
              h='7'
              pl='3'
              pr='2'
            >
              <Box
                as='i'
                display='inline-block'
                w='1.5'
                h='1.5'
                mr='2'
                borderRadius='full'
                bg='prim.500'
              />
              <Text color='prim.500'>{onlineUserCount}+</Text>
              <Text ml='1'>Online</Text>
            </Flex>
          </Flex>
        )}

        {viewOption.loadMoreType === 1 && (
          <HeaderRight
            allGameHref={`/game/all?cate=${
              type === 2 ? BuiltInGameLabel.Manufacturer : type
            }&label=${label}`}
            gameNumber={game_number || data?.length || providerSort?.length}
            onPrev={() => swiperIns?.slidePrev()}
            onNext={() => swiperIns?.slideNext()}
          />
        )}
      </HStack>

      {contentRender(viewOption)}
    </VStack>
  )
}

export const PagniationBtns = ({
  onPrev,
  onNext,
  isPrevDisabled,
  isNextDisabled,
}: {
  onPrev: () => void
  onNext: () => void
  isPrevDisabled?: boolean
  isNextDisabled?: boolean
}) => {
  return (
    <HStack spacing='2'>
      <IconButton
        isDisabled={isPrevDisabled}
        colorScheme='white'
        aria-label='pagination-prev'
        icon={<Icon as={SharedIcon.ArrowLeft} />}
        onClick={onPrev}
        w='9'
        h='9'
        minW='9'
        borderRadius='base'
      />
      <IconButton
        isDisabled={isNextDisabled}
        colorScheme='white'
        aria-label='pagination-next'
        icon={<Icon as={SharedIcon.ArrowRight1} />}
        onClick={onNext}
        w='9'
        h='9'
        minW='9'
        borderRadius='base'
      />
    </HStack>
  )
}

export function HeaderRight(props: {
  onPrev?: () => void
  onNext?: () => void
  gameNumber: number
  allGameHref: string
}) {
  const { allGameHref, gameNumber } = props
  const [page, setPage] = useState(1)
  const { t } = useTranslation()

  const onPrev = () => {
    setPage(prev => Math.max(prev - 1, 1))
    props?.onPrev && props?.onPrev()
  }

  const onNext = () => {
    setPage(prev => prev + 1)
    props?.onNext && props?.onNext()
  }

  return (
    <HStack spacing='2.5'>
      {props?.onNext && props.onPrev && (
        <PagniationBtns
          isPrevDisabled={page === 1}
          onNext={onNext}
          onPrev={onPrev}
        />
      )}
      <Flex
        h='9'
        ml='auto'
        mr='0'
        pl='3'
        pr='3'
        bgColor='bg.control'
        color='text.accent'
        borderRadius='base'
        alignItems='center'
        justifyContent='center'
        cursor='pointer'
      >
        <Link
          as={RouterLink}
          to={allGameHref}
          textDecoration='none'
          color='inherit'
        >
          <Text textStyle='text3' as='span' cursor='pointer'>
            {t('NEW_HOME_PLAY_NAV_06')}
          </Text>
          <Text textStyle='text3' color='prim.500' as='span' cursor='pointer'>
            {` ${gameNumber}`}
          </Text>
        </Link>
        <Icon as={AiFillCaretRight} boxSize='3' ml='1' />
      </Flex>
    </HStack>
  )
}
