import dayjs from 'dayjs'
import type { TFunction } from 'i18next'
import { inRange } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from '@/assets/images/act/modal-unactive-1.png'
import Icon2 from '@/assets/images/act/modal-unactive-2.png'
import { getCurrencyCode } from '@/common/locale/currency'
import {
  selectRechargeActId,
  selectRechargeActList,
} from '@/modules/activity/ActSlice'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import {
  selectUserRecharged,
  selectUserRegisterTime,
  selectVipLevel,
} from '@/modules/user/userSlice'
import { useUserAuth } from '@/utils/hooks'
import { getDeviceType } from '@/utils/tools'

import { GlobalModalKey } from '../global-modal/types'
import type {
  ACT_TIPS_CODE,
  DeviceType,
  PreCondition,
  RechargeActListProps,
  RechargeGrid,
} from './ActInterface'
import { ActTipsbtnRenderType, deviceMap } from './type'

const device = getDeviceType()

export function getSupportDeviceI18nText(
  t: TFunction,
  join_device?: DeviceType[],
) {
  const deviceMapI18nKey = {
    android_desktop: 'AD_DT_KEY',
    android_web: 'AD_WEB_KEY',
    ios_desktop: 'IOS_DT_KEY',
    ios_web: 'IOS_WEB_KEY',
    pc: 'PC_WEB_KEY',
    android_app: 'AD_APP_KEY',
    ios_app: 'IOS_APP_KEY',
    other: '',
  }
  const supportDevice = [] as string[]
  join_device?.forEach(item => {
    if (deviceMapI18nKey?.[item]) {
      supportDevice.push(t(deviceMapI18nKey?.[item]) || '')
    }
  })
  return t('DEPOSIT_ACTICITY_TT8', {
    device_platform: supportDevice.join('、'),
  })
}

/** 处理活动有效期 */
const handleActTimeValid = (begin_at: number, end_at: number) => {
  const curTime = +new Date()
  const _begin_at = begin_at * 1000
  const _end_at = end_at * 1000
  if (curTime < _begin_at) {
    // 未开始
    return -1
  } else if (curTime > _end_at) {
    // 已结束
    return 1
  } else {
    // 有效期内
    return 0
  }
}

export const useRechargeInfo = () => {
  const dispatch = useAppDispatch()
  const rechargeActList = useAppSelector(selectRechargeActList)
  const actId = useAppSelector(selectRechargeActId)
  const CURRENCY = getCurrencyCode()
  const { t } = useTranslation()

  const [curAct, fixedAmountList] = useMemo(() => {
    const info = rechargeActList.find(item => Number(item.id) === Number(actId))
    const amountList = (info?.recharge_config?.[CURRENCY]?.recharge_elem ||
      []) as RechargeGrid[]
    return [info, amountList]
  }, [actId, rechargeActList, CURRENCY])

  const [user_id] = useUserAuth()

  // 用户VIP有参与资格
  const vipLevel = useAppSelector(selectVipLevel)
  // 用户注册时间
  const regiaterTime = useAppSelector(selectUserRegisterTime)
  const isFirstCharge = !!useAppSelector(selectUserRecharged)

  const handleBtnRender = useCallback(
    (tipsCode: ACT_TIPS_CODE | null) => {
      // 是否还有可参与的活动
      const flag = rechargeActList.some(item => {
        const { verify } = handleActStatus(item)
        return verify
      })

      if (tipsCode === 4) {
        // 设备不符合
        let btnKey =
          ActTipsbtnRenderType.ACTIVITY_COPY_URL_BTN as ActTipsbtnRenderType | null
        const curDevice = (deviceMap[device] || '') as DeviceType
        const { join_device } = curAct || {}
        // 是否需要存在于APP
        const hasAndroidApp = join_device?.includes('android_app')
        const hasIosApp = join_device?.includes('ios_app')
        const hasAndroidDesktop = join_device?.includes('android_desktop')
        const hasIosDesktop = join_device?.includes('ios_desktop')
        // 活动设备包含桌面 && 用户设备不在桌面
        if (hasIosDesktop && curDevice !== 'ios_desktop') {
          btnKey = ActTipsbtnRenderType.ACTIVITY_INTRODUTION_BTN
        }

        if (hasAndroidDesktop && curDevice !== 'android_desktop') {
          btnKey = null
        }
        // 活动设备包含APP && 用户设备不在APP
        if (
          (hasAndroidApp && curDevice !== 'android_app') ||
          (hasIosApp && curDevice !== 'ios_app')
        ) {
          btnKey = ActTipsbtnRenderType.ACTIVITY_INTRODUTION_BTN
        }
        return {
          btnKey,
          can_join_other: flag,
        }
      }
      if (flag) {
        //  去活动中心
        return {
          btnKey: ActTipsbtnRenderType.ACTIVITY_CENTER_BTN,
          can_join_other: flag,
        }
      } else {
        // 去全部游戏
        return {
          btnKey: ActTipsbtnRenderType.ACTIVITY_ALL_GAME_BTN,
          can_join_other: flag,
        }
      }
    },
    [curAct],
  )

  function handleTime(time: number) {
    return dayjs(Number(time) * 1000).format('YYYY-MM-DD HH:mm:ss')
  }

  /** 处理提示弹窗活动时间 */
  function handleActTimeContent(
    tipsCode: ACT_TIPS_CODE | null,
    can_join_other: boolean,
  ) {
    const { begin_at, end_at } = curAct || {}
    if (!begin_at || !end_at) return ''
    const time = handleTime(begin_at) + ' -- ' + handleTime(end_at)
    switch (tipsCode) {
      case 5:
        return t(
          can_join_other ? 'DEPOSIT_ACTICITY_TT1' : 'DEPOSIT_ACTICITY_TT2',
          { time },
        )
      case 6:
        return t(
          can_join_other ? 'DEPOSIT_ACTICITY_TT5' : 'DEPOSIT_ACTICITY_TT6',
          { time },
        )
      case 7:
        return t(
          can_join_other ? 'DEPOSIT_ACTICITY_TT3' : 'DEPOSIT_ACTICITY_TT4',
          { time },
        )
    }
  }

  /** 处理活动提示语内容 */
  function handleTipsContent(
    tipsCode: ACT_TIPS_CODE | null,
    can_join_other: boolean,
  ) {
    switch (tipsCode) {
      // 无参与资格
      case 1:
        if (can_join_other) {
          return t('DEPOSIT_ACTICITY_TT9')
        } else {
          return t('DEPOSIT_ACTICITY_TT10')
        }
      // 么有参与次数了; 已参与，不可点击
      case 2:
        if (can_join_other) {
          return t('DEPOSIT_ACTICITY_TT11')
        } else {
          return t('DEPOSIT_ACTICITY_TT12')
        }
      // 明日再来
      case 3:
        return t('DEPOSIT_ACTICITY_TT7')
      // 不符合设备
      case 4:
        return getSupportDeviceI18nText(t, curAct?.join_device)
      // 时间未开始/ 有效期内但是暂停/时间结束
      case 5:
      case 6:
      case 7:
        return handleActTimeContent(tipsCode, can_join_other)
    }
  }

  /**
   * 充值活动弹窗处理
   */
  const handleOpenTips = useCallback(
    (tipsCode: ACT_TIPS_CODE | null) => {
      const { btnKey, can_join_other } = handleBtnRender(tipsCode)
      const content = handleTipsContent(tipsCode, can_join_other)
      // 弹窗信息
      const modalInfo = {
        title: 'DEPOSIT_ACTICITY_TL',
        content,
        icon: tipsCode === 5 ? Icon2 : Icon,
        btnRender: btnKey,
        needCustomer: tipsCode === 6,
      }
      tipsCode &&
        dispatch(
          openModal({
            key: GlobalModalKey.ActTips,
            data: modalInfo,
          }),
        )
    },
    [handleBtnRender],
  )

  /**
   * 处理当活动的状态
   * act 活动信息
   * forbiddenModal 【boolean】 是否禁止弹窗
   */
  const handleActStatus = useCallback(
    (act: RechargeActListProps) => {
      // 验证是否通过
      let verify = false
      let tipsCode = null as ACT_TIPS_CODE | null
      const isValidTime = handleActTimeValid(act?.begin_at, act?.end_at)
      const isFirstChargeCheck =
        (act.is_first_charge === 1 && !isFirstCharge) ||
        act.is_first_charge !== 1
      if (act?.state === 1 && isValidTime === 0) {
        // 活动开启 & 在有效期
        if (
          !(
            isEligibleForActivity(
              act.pre_condition,
              {
                vipLevel,
                regiaterTime,
                user_id,
              },
              {
                vip: act.pre_condition?.vip_level_limit === 1,
                registerDay: act.pre_condition?.register_day_limit === 1,
              },
            ) && isFirstChargeCheck
          )
        ) {
          // 没有参与资格
          tipsCode = 1
        } else {
          // 有参与资格
          const { join_type } = act
          const join_device = act.join_device || []
          if (join_device.includes(deviceMap[device] as DeviceType)) {
            // 符合设备
            const { current_join, join_number } = act
            // 剩余的活动参与次数
            const remain_count = Number(join_number) - Number(current_join)
            if (remain_count <= 0 && join_type !== 0) {
              if (join_type === 4 || join_type === 3 || join_type === 2) {
                // 没有参与次数了
                tipsCode = 2
              } else {
                //  明日再来
                tipsCode = 3
              }
            } else {
              verify = true
            }
          } else {
            // 不符合设备
            tipsCode = 4
          }
        }
      } else {
        // 活动未开启
        switch (isValidTime) {
          case -1:
            // 活动未开始
            tipsCode = 5
            break
          case 0:
            // 活动暂停
            tipsCode = 6
            break
          case 1:
            // 活动已结束
            tipsCode = 7
            break
        }
      }
      return { verify, tipsCode }
    },
    [vipLevel, regiaterTime, user_id],
  )

  return { handleActStatus, actInfo: curAct, fixedAmountList, handleOpenTips }
}

/** 验证注册时间 */
const checkRegisterDays = (
  registerTime: string | number,
  start: number,
  end: number,
) => inRange(dayjs().diff(dayjs(registerTime), 'day'), start, end + 1)

/** 验证VIP等级是否符合 */
const checkVipLevel = (vipLevel: number, start: number, end: number) =>
  inRange(vipLevel, start, end + 1)

// 应为不同活动的限制开关的定义不同，所以这里的参数是不确定的
export function isEligibleForActivity(
  pre_condition: Omit<PreCondition, 'vip_level_limit' | 'register_day_limit'>,
  userInfo: {
    vipLevel: number
    regiaterTime: string | number
    user_id: string
  },
  limitEnable?: {
    vip?: boolean
    registerDay?: boolean
  },
) {
  const {
    min_vip_level,
    max_vip_level,
    min_register_day,
    max_register_day,
    limit_user_id_list = [],
  } = pre_condition
  const { vipLevel, regiaterTime, user_id } = userInfo
  let flag = true
  if (limitEnable?.vip) {
    flag = flag && checkVipLevel(vipLevel, min_vip_level, max_vip_level)
  } else if (limitEnable?.registerDay) {
    flag =
      flag &&
      checkRegisterDays(regiaterTime, min_register_day, max_register_day)
  } else if (limit_user_id_list && limit_user_id_list.length > 0) {
    flag = flag && !limit_user_id_list.includes(user_id)
  }
  return flag
}
