import { Box, HStack, Text } from '@chakra-ui/react'

import btnBg from '@/assets/images/recharge-comfirm-modal/btn-bg.png'
import hotStickers from '@/assets/images/recharge-comfirm-modal/hot-stickers.png'
import itemBoxBg1 from '@/assets/images/recharge-comfirm-modal/item-box-bg-1.png'
import itemBoxBg2 from '@/assets/images/recharge-comfirm-modal/item-box-bg-2.png'
import itemBoxBg3 from '@/assets/images/recharge-comfirm-modal/item-box-bg-3.png'
import spotlights1 from '@/assets/images/recharge-comfirm-modal/spotlights-1.png'
import spotlights2 from '@/assets/images/recharge-comfirm-modal/spotlights-2.png'
import spotlights3 from '@/assets/images/recharge-comfirm-modal/spotlights-3.png'
import widebtnBg from '@/assets/images/recharge-comfirm-modal/wide-btn-bg.png'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useAppDispatch } from '@/modules/app/store'
import {
  setRechargeAmount,
  switchRecahrgeActModal,
} from '@/modules/recharge-activity/rechargeActSlice'
import { formatLocalMoney } from '@/utils/tools'

import type { ItemData } from './RechargeComfirmModal'

interface ListItemProps
  extends Pick<ItemData, 'image' | 'title' | 'rechargeAmounts' | 'taskId'> {
  index: number
}

// eslint-disable-next-line react-refresh/only-export-components
export const itemAssets = [
  {
    bgImage: itemBoxBg1,
    spotlightsImage: spotlights1,
    detailImageBg: 'linear-gradient(180deg, #7233BC 0%, #924DDF 100%)',
  },
  {
    bgImage: itemBoxBg2,
    spotlightsImage: spotlights2,
    detailImageBg: 'linear-gradient(180deg, #153CA6 0%, #4E71FF 100%)',
  },
  {
    bgImage: itemBoxBg3,
    spotlightsImage: spotlights3,
    detailImageBg: 'linear-gradient(180deg, #187B6B 0%, #33947A 100%)',
  },
]

export const ListItem = (props: ListItemProps) => {
  const { index, taskId, title, image = '', rechargeAmounts } = props
  const dispatch = useAppDispatch()

  const onClickAmount = (amount: number) => {
    dispatch(switchRecahrgeActModal({ taskId }))
    dispatch(setRechargeAmount(amount))
  }

  const assets = itemAssets[index % itemAssets.length]

  const isOnlyOneAmount = rechargeAmounts?.length === 1

  return (
    <Box position='relative'>
      {index === 0 && (
        <LazyImage
          src={hotStickers}
          alt='hot'
          boxSize='50px'
          objectFit='contain'
          position='absolute'
          top='16px'
          right='22px'
        />
      )}
      <Box
        position='absolute'
        w='full'
        h='full'
        pt={index === 0 ? '10px' : '12px'}
        pb='2.5'
        px='5'
      >
        <HStack
          spacing='4'
          justifyContent='center'
          onClick={
            isOnlyOneAmount
              ? () => onClickAmount(rechargeAmounts[0])
              : undefined
          }
        >
          <LazyImage
            src={assets.spotlightsImage}
            alt=''
            w='119px'
            h='83px'
            position='absolute'
            top={index === 0 ? '8px' : '11px'}
            left='5px'
          />
          <LazyImage src={image} alt='' w='88px' h='88px' mb='0.5' />
          <Box w='24' ml='-12px'>
            <Text
              fontSize='18px'
              fontStyle='italic'
              fontWeight='900'
              lineHeight='29px'
              color='four.500'
              noOfLines={2}
            >
              {title}
            </Text>
          </Box>
        </HStack>
        <HStack justifyContent='center'>
          {rechargeAmounts?.map(amount => (
            <Box
              as='button'
              onClick={() => onClickAmount(amount)}
              display='flex'
              justifyContent='center'
              alignItems='center'
              textAlign='center'
              w={isOnlyOneAmount ? '182px' : '88px'}
              h='36px'
              color='#651F00'
              fontSize='14px'
              fontStyle='italic'
              fontWeight='900'
              lineHeight='1'
              key={amount}
              bgImage={`url(${isOnlyOneAmount ? widebtnBg : btnBg})`}
              bgSize='100% 100%'
              bgRepeat='no-repeat'
            >
              {formatLocalMoney(amount)}
            </Box>
          ))}
        </HStack>
      </Box>
      <LazyImage src={assets.bgImage} w='full' />
    </Box>
  )
}
