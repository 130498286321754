import { isFeatureEnabled } from '@/common/feature'

// GMT+8 2023-11-06 18:00 - 2023-12-01 00:59
const START_TIME_STAMP = 1699264800000
const END_TIME_STAMP = 1701363599000

export const getPgSoftActivitySwitch = () => {
  if (!isFeatureEnabled('activityActiveInProject')) return false
  const currentTimeStamp = new Date().getTime()
  return currentTimeStamp >= START_TIME_STAMP &&
    currentTimeStamp <= END_TIME_STAMP
    ? 'online'
    : currentTimeStamp > END_TIME_STAMP
    ? 'offline'
    : 'coming'
}
