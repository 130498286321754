import { useCallback } from 'react'

import { trackEnterHome, trackPageView } from '@/common/tracker'

import type { PageChangeEvent } from './usePageChangeNotification'
import { usePageChangeSubscription } from './usePageChangeNotification'

export function usePageViewTracker() {
  const handler = useCallback(({ pathname, prevPathname }: PageChangeEvent) => {
    if (prevPathname !== pathname && pathname === '/') {
      trackEnterHome()
    }
    trackPageView({ pathname })
  }, [])
  usePageChangeSubscription(handler)
}
