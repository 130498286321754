export function dontShowItAgainToday(key: string) {
  const getToday = () => new Date().toISOString().split('T')[0] // 获取今天的日期
  const canShow = () => localStorage.getItem(key) !== getToday() // 获取本地存储的日期
  const onChange = (val: boolean) => {
    if (!val) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, getToday()) // 设置本地存储的key
    }
  }
  return { canShow, onChange }
}
