import {
  Box,
  Flex,
  Highlight,
  HStack,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'

import shareArrow from '@/assets/images/lucky-wheel/share_arrow.png'
import shareLeft from '@/assets/images/lucky-wheel/share_left.png'
import shareRight from '@/assets/images/lucky-wheel/share_right.png'
import ticket2Icon from '@/assets/images/lucky-wheel/ticke2.png'
import { useAppSelector } from '@/modules/app/store'
import { selectModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'

import Progress from './Progress'

function ApanaloWithdrawSuccessHeader() {
  const { data: shareArr } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.SharesModal],
  )
  const { t } = useTranslation()
  return (
    <Flex justifyContent='space-between' alignItems='center' p='0 4px'>
      <Image src={ticket2Icon} w='48px' h='48px' />
      <Text textStyle='h6' color='gray.900' ml='4'>
        {t('SHARE_INVITE_FREE_BONUS', {
          amount: shareArr?.amount ?? 0,
        })}
      </Text>
    </Flex>
  )
}

const NormalShareHeader = () => {
  return (
    <VStack>
      <Text textStyle='text6' color='#fff' textAlign='left' w='full'>
        <Highlight
          query={'1'}
          styles={{
            color: 'four.500',
          }}
        >
          {t('REFER_ONE_FRIEND')!}
        </Highlight>
      </Text>
      <Progress />
      <Box
        bg='rgba(255,255,255,0.1)'
        mx='20px'
        w='100%'
        h='70px'
        mt='12px'
        borderRadius='10px'
        // px='32px'
        py='8px'
      >
        <HStack alignItems='center' justifyContent='center' w='full' h='full'>
          <VStack alignItems='center' justifyContent='center'>
            <Image src={shareLeft} w='72px' h='40px' />
            <Text textStyle='text6' color='#fff'>
              {t('A_FRIEND_JOIN')}
            </Text>
          </VStack>
          <Image src={shareArrow} w='28px' h='20px' ml='38px' />
          <VStack alignItems='center' justifyContent='center' ml='38px'>
            <Image src={shareRight} w='72px' h='40px' />
            <Text textStyle='text6' color='#fff'>
              <Highlight
                query='100%'
                styles={{
                  color: 'four.500',
                }}
              >
                {t('UP_TO_100')!}
              </Highlight>
            </Text>
          </VStack>
        </HStack>
      </Box>
    </VStack>
  )
}

function ApanaloSharedHeader({ scene }: { scene: string }) {
  return (
    <>
      {scene === 'landingPage' ? (
        <NormalShareHeader />
      ) : (
        <ApanaloWithdrawSuccessHeader />
      )}
    </>
  )
}

export default ApanaloSharedHeader
