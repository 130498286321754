//

import { useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { isQueryError } from '@/services/types'

import { verifyPhone } from '../wallet/verifyFunction'
import { useUserPhoneCodeMutation } from './bindPhoneApi'
import {
  getPhone,
  setCode,
  setCountDownTime,
  setPhone,
  setShowCountDown,
} from './bindPhoneSlice'
import { VERIFY_CODE_MESSAGE } from './constants'
import type { PhoneCode } from './type'

export const useGetcode = () => {
  const phone = useAppSelector(getPhone)
  const toast = useToast()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  //绑定手机号
  //获取验证码
  const [userPhoneCode, codeResult] = useUserPhoneCodeMutation()
  const {
    error: codeError,
    isSuccess: codeSuccess,
    isLoading: codeLoading,
  } = codeResult
  const [showPhoneShake, setShowPhoneShake] = useState(false)
  //基础校验手机号
  const _verifyPhone = useMemo(
    () => verifyPhone({ params: {}, value: phone }),
    [phone],
  )
  // 获取手机验证码
  const getPhoneCode = useCallback(
    async (params: PhoneCode) => {
      if (!_verifyPhone.verify) {
        phoneShakeFn()
        return
      }
      sessionStorage.setItem('last_phone', String(params.phone))
      userPhoneCode(params).unwrap()
    },
    [userPhoneCode, _verifyPhone],
  )

  //监听验证码获取
  useEffect(() => {
    //获取失败提示
    if (codeError && isQueryError(codeError)) {
      const text =
        t(VERIFY_CODE_MESSAGE[codeError?.status]) || t('NETWORK_EXCEPTION')
      toast({ status: 'error', title: t(text), duration: 5000 })
      return
    }
    //获取成功
    if (codeSuccess) {
      const _time = new Date().valueOf() + 1000 * 60
      sessionStorage.setItem('code_time', String(_time))
      dispatch(setShowCountDown(true))
      dispatch(setCountDownTime(_time))
      dispatch(setCode(''))
      toast({
        status: 'success',
        duration: 5000,
        title: t('SENDED_CODE_BY_PH', {
          phonenumber: sessionStorage.getItem('last_phone') || '',
        }),
      })
    }
  }, [codeError, t, toast, codeSuccess, dispatch])

  //手机号摇
  const phoneShakeFn = () => {
    setShowPhoneShake(true)
    setTimeout(() => {
      setShowPhoneShake(false)
    }, 800)
  }

  useEffect(() => {
    const _phone = sessionStorage.getItem('last_phone') || '' //上一次发送验证码的手机号
    if (_phone) {
      dispatch(setPhone(_phone))
    }
  }, [dispatch])

  return {
    getPhoneCode,
    showPhoneShake,
    codeSuccess,
    phoneShakeFn,
    codeLoading,
  }
}
