import { Flex, Image, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { useMemo } from 'react'
import { Autoplay } from 'swiper/modules'
import type { SwiperProps } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'

import pixIcon from '@/assets/images/lucky-wheel/pix-payment.png'
import { getCurrencyCode } from '@/common/locale/currency'
import { useAppSelector } from '@/modules/app/store'
import {
  options as optionsList,
  WITHDRAW_WAY_ICON_MAP,
  WITHDRAW_WAYS_TEXT,
} from '@/modules/wallet/walletContansts'
import { getWithdrawalMethods } from '@/modules/wallet/walletSlice'
import { formatLocalMoney } from '@/utils/tools'

import { getLuckyWheelConfigReward } from '../luckyWheelSlice'

const swiperProps: SwiperProps = {
  allowTouchMove: false,
  modules: [Autoplay],
  grabCursor: true,
  autoplay: true,
  observer: true,
  observeParents: true,
  freeMode: true,
  loop: true,
}

function DrawRecord() {
  const CURRENCY = getCurrencyCode()
  const { reward } = useAppSelector(getLuckyWheelConfigReward)
  const withdrawalMethods = useAppSelector(getWithdrawalMethods).map(
    (item: any) => {
      const option = optionsList[item.value]
      return {
        icon: WITHDRAW_WAY_ICON_MAP[CURRENCY][item.value] || option?.icon,
        label: t(WITHDRAW_WAYS_TEXT[item.value]).toString(),
      }
    },
  )
  const fakeData = useMemo(() => {
    const generateUsername = () => {
      const prefix = Math.floor(Math.random() * 90) + 10 // Generate a random number between 10 and 99
      const suffix = Math.floor(Math.random() * 900) + 100 // Generate a random number between 100 and 999
      return `${prefix}****${suffix}`
    }

    // 生成3个二维数组，每个数组中有3个元素，每个元素是一个对象
    return [...Array(3)].map(() => {
      return [...Array(3)].map(() => {
        const username = generateUsername()

        // 在withdrawalMethods中随机取一个
        const method =
          withdrawalMethods[
            Math.floor(Math.random() * withdrawalMethods.length)
          ]
        return {
          username,
          method,
          amount: reward,
        }
      })
    })
  }, [reward, withdrawalMethods])

  return (
    <>
      <Text textAlign='center' textStyle='h6' color='gray.900'>
        {t('REWARD_RECORDS')}
      </Text>
      <Swiper
        {...swiperProps}
        direction='vertical'
        slidesPerView='auto'
        style={{
          width: '100%',
          height: '88px',
        }}
      >
        {fakeData.map((item, index) => (
          <SwiperSlide key={index}>
            {item.map((record, index) => {
              return (
                <Flex justifyContent='space-around' mt='3' mb='2' key={index}>
                  <Text textStyle='text6'>{record.username}</Text>
                  <Text textStyle='text6'>{t('USER_HAD_WITHDRAW_DSC')}</Text>
                  <Flex flexWrap='nowrap'>
                    <Text
                      color='luckyWheel.drawRecordColor'
                      textStyle='h6'
                      whiteSpace='nowrap'
                    >
                      + {formatLocalMoney(record.amount)}
                    </Text>
                    <Image
                      src={record.method.icon ?? pixIcon}
                      // flexBasis='20px'
                      h='4'
                      ml='1'
                    />
                  </Flex>
                </Flex>
              )
            })}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

export default DrawRecord
