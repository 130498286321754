import { Box, Flex } from '@chakra-ui/react'
import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'

import type { AtomFullScreenRef } from '@/components/atom-full-screen/AtomFullScreen'
import AtomFullScreen from '@/components/atom-full-screen/AtomFullScreen'
import FreeTryModal from '@/modules/game/third-game/free-try-modal/FreeTryModal'
import { useAddGameHistory } from '@/modules/my-games/useAddGameHistory'
import ButtonAction from '@/pages/slot-and-live/button-action'
import Loading from '@/routes/Loading'
import { useMobile } from '@/utils/hooks'

import CloseBtn from './CloseBtn'
import Game from './Game'
import { GameInfoContext } from './ThirdPartGameWrap'
import useHistoryListen from './useHistoryListen'

interface ThirdPartGameBoxProps {
  gameUrl: string
  gameDom?: string
  isLoading?: boolean
  customGameContent?: React.ReactNode
}

export interface ThirdPartGameBoxRef {
  enterFullScreen: () => void
}

const ThirdPartGameBox = forwardRef<ThirdPartGameBoxRef, ThirdPartGameBoxProps>(
  ({ gameUrl, customGameContent = null, gameDom, isLoading }, ref) => {
    const isMobile = useMobile()
    const { goBack } = useHistoryListen()
    const fullScreenRef = useRef<AtomFullScreenRef>(null)
    const [borderRadius, setBorderRadius] = useState('0')
    const containerRef = useRef<HTMLDivElement | null>(null)
    const { gameId } = useParams()
    const game = useContext(GameInfoContext)
    const isDemoSupported = Boolean(game?.try_play_switch)
    const gameUuid = game?.expand?.game_uuid || ''
    useAddGameHistory(String(gameId), gameUuid)
    useImperativeHandle(ref, () => ({
      enterFullScreen: () => {
        setBorderRadius('0')
        fullScreenRef.current?.enterFullScreen()
      },
    }))

    const GameContent = useMemo(() => {
      if (customGameContent) {
        return customGameContent
      }
      if (!gameUrl || isLoading) {
        return <Loading />
      }
      return <Game gameUrl={gameUrl} isMobile={isMobile} gameDom={gameDom} />
    }, [customGameContent, gameDom, gameUrl, isLoading, isMobile])

    return (
      <AtomFullScreen fullScreenInEnter={isMobile} ref={fullScreenRef}>
        {/** 破产充值V3 */}
        <FreeTryModal
          containerRef={containerRef}
          gameCover={game?.game_cover || ''}
          isDemoSupported={isDemoSupported}
        />
        <Flex
          alignItems='center'
          justifyContent='center'
          bgColor='bg.control'
          borderRadius={borderRadius}
          h={'100%'}
          overflow={'hidden'}
          position={'relative'}
          zIndex={2}
          ref={containerRef}
        >
          <Box
            height={isMobile ? '100%' : '67vh'}
            alignItems='center'
            display='flex'
            width='100%'
            justifyContent='center'
          >
            {GameContent}
          </Box>

          <CloseBtn
            closeCb={() => {
              // const timer = -history.length
              goBack()
            }}
          />
        </Flex>
        <Box>
          <ButtonAction
            onFullScreen={() => {
              fullScreenRef.current?.enterFullScreen()
              setBorderRadius('0')
            }}
            gameId={gameId}
            gameUuid={gameUuid}
            isDemoSupported={isDemoSupported}
          />
        </Box>
      </AtomFullScreen>
    )
  },
)

ThirdPartGameBox.displayName = 'ThirdPartGameBox'

export default ThirdPartGameBox
