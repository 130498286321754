import {
  Box,
  Checkbox,
  Flex,
  Icon,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { type ChangeEvent, useMemo } from 'react'

import {
  closeModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { localStorage } from '@/utils/localStorage'
import { projectName } from '@/websites/current/property.json'
import AddHomeScreenTipsImg from '@/websites/current/public/assets/images/add-home-screen-tips.png'

import { useAppDispatch, useAppSelector } from '../store'
import AddIcon from './AddIcon'
import ArrowIcon from './ArrowIcon'
import CloseIcon from './CloseIcon'
import ShareIcon from './ShareIcon'

const ColorMap: Record<string, string> = {
  B1: 'white',
  B2: 'white',
  P3N: 'white',
}

const MODAL_CONTENT_STYLE = {
  height: 'auto',
  position: 'fixed',
  bottom: 0,
  margin: 0,
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  paddingBottom: '1',
  maxWidth: '100%',
  width: '100%',
}
const HEADER_STYLE = {
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '18px',
  padding: '30px 10px 12px',
}

const IMAGE_CONTAINER_STYLE = {
  position: 'relative',
  width: '100%',
  minHeight: '315px',
  padding: '10px',
  background:
    'var(--chakra-colors-addToHomeScreen-bg,var(--chakra-colors-gray-200))',
}

const IMAGE_STYLE = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  objectPosition: 'center',
  borderRadius: '8px',
}

const isChrome =
  navigator.userAgent.indexOf('Chrome') > -1 ||
  navigator.userAgent.indexOf('CriOS') > -1

function AddToHomeScreenModal() {
  const { open, data } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.AddToHomeScreen],
  )
  const dispatch = useAppDispatch()

  // 关闭弹窗的回调
  const handleClose = () => {
    dispatch(closeModal({ key: GlobalModalKey.AddToHomeScreen }))
  }

  const isShowNotShowAgain = useMemo(() => {
    if (data?.position === 'other') {
      return false
    }
    return true
  }, [data?.position])

  const handleNotShowAgainChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).checked
    if (value) {
      localStorage.setItem('NOT_SHOW_ADD_TO_HOME_SCREEN', 'true')
      handleClose()
    }
    return
  }

  return (
    <Modal isOpen={open} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent sx={MODAL_CONTENT_STYLE}>
        <ModalCloseButton
          sx={{
            right: 3,
            top: 4,
          }}
        >
          <CloseIcon />
        </ModalCloseButton>
        <ModalHeader sx={HEADER_STYLE}>
          {isChrome && (
            <Flex m='16px 0 12px' p='10px' borderRadius='8px' bg='gray.500'>
              <Icon as={SharedIcon.Info} boxSize='4' mr='2' color='gray.700' />
              <Text
                color='gray.900'
                textAlign='left'
                lineHeight='16px'
                fontSize='12px'
              >
                {t('SAFARI_INTR')}
              </Text>
            </Flex>
          )}
          <Box px='40px'>
            <Box display='flex' alignItems='end' justifyContent='center'>
              <Text mr='1'>{t('MOBILE_IOS_COLLECT_WINDOW_TEXT01')}</Text>
              <ShareIcon color={ColorMap[projectName] ?? undefined} />
            </Box>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              mt='3'
            >
              <Text mr='2'>{t('MOBILE_IOS_COLLECT_WINDOW_TEXT02')}</Text>
              <AddIcon color={ColorMap[projectName] ?? undefined} />
              <Text
                ml='2'
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                {t('MOBILE_IOS_COLLECT_TEXT')}
              </Text>
            </Box>
          </Box>
        </ModalHeader>
        <Box sx={IMAGE_CONTAINER_STYLE}>
          <Image
            src={AddHomeScreenTipsImg}
            mt='1'
            loading='lazy'
            sx={IMAGE_STYLE}
          />
        </Box>

        {isShowNotShowAgain ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            mt='3'
          >
            <Checkbox
              defaultChecked={false}
              onChange={handleNotShowAgainChange}
              borderColor='gray.700'
            />
            <Text ml='2'>{t('NOT_REMIND_THIS_LOGIN')}</Text>
          </Box>
        ) : null}
        <Box display='flex' justifyContent='center' alignItems='center' mt='2'>
          <ArrowIcon />
        </Box>
      </ModalContent>
    </Modal>
  )
}

export default AddToHomeScreenModal
