import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { keyBy } from 'lodash-es'

import {
  generateAutoActions,
  updateAt,
} from '@/modules/app/generateAutoActions'
import type { RootState } from '@/modules/app/store'
import { newVipApi } from '@/modules/new-vip/newVipApi'

import { vipEndpoints } from '../apis/endpoints'
import type { CommonConfig, VipState } from './selectors/types'

const vipInitialState: VipState = {
  commonConfig: [],
  swiperActiveLevel: 0,
}

const vipSlice = createSlice({
  name: 'vip',
  initialState: vipInitialState,
  reducers: {
    updateAt,
    updateSwiperActiveLevel(state, { payload }: PayloadAction<number>) {
      state.swiperActiveLevel = payload
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(
        vipEndpoints.endpoints.getCommonSettings.matchFulfilled,
        (state, { payload }) => {
          if (payload.code === 200) {
            const _ = keyBy(payload.data, 'c_key')
            state.commonConfig = _ as CommonConfig
          }
        },
      )
      .addMatcher(
        newVipApi.endpoints.getUserVipInfo.matchFulfilled,
        (state, { payload }) => {
          state.swiperActiveLevel = payload.data.vip.level
        },
      )
  },
})

type Actions = (typeof vipSlice)['actions']
type Action = {
  [k in keyof Actions]: Actions[k]
}
export const { updateSwiperActiveLevel } = generateAutoActions<Action>(
  vipSlice.actions,
)
export const { reducer } = vipSlice

const selectVip = (state: RootState) => state.vip

export const selectVipCommonConfig = createSelector(
  selectVip,
  vipState => vipState.commonConfig,
)

export default reducer
