import { Box, Flex, Image, Text, useToast } from '@chakra-ui/react'
import { createSelector } from '@reduxjs/toolkit'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import IconAlert from '@/assets/images/icon-alert.png'
import IconShield from '@/assets/images/icon-shield.png'
import service from '@/assets/images/service.png'
import { getVariable } from '@/common/env'
import { InfoTooltip } from '@/components/info-tooltip/InfoTooltip'
import LazyImage from '@/components/lazy-image/LazyImage'
import TitledDialog from '@/components/modal/TitledDialog'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { useBindUserPhoneInfoQuery } from '@/modules/bind-phone/bindPhoneApi'
import {
  closeModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { selectUserIsLogin } from '@/modules/user/userSlice'
import { useWalletService } from '@/modules/wallet/useWalletService'
import { localStorage } from '@/utils/localStorage'

import useAccountsInfo from '../hooks/useAccountsInfo'
import useVerifyCode from '../hooks/useVerifyCode'
import {
  LoginVerifyCodeType,
  useSetAccountMutation,
  useVerifyCodeLoginMutation,
} from '../loginApi'
import { getLoginCodeErrorMessage } from '../tools'
import { handlePhoneValidator, handleRealEmailValidator } from '../utils'
import EmailFormItem from './EmailFormItem'
import type { FormRef } from './Form'
import Form from './Form'
import PhoneFormItem from './PhoneFormItem'
import VerifyCodeFormItem from './VerifyCodeFormItem'

const SHOW_BIND_PHONE = getVariable('SHOW_BIND_PHONE')

const MODAL_STEP_CONFIG_RE = {
  first: {
    title: {
      reEmail: 'EMAIL_VERIFY_2',
      rePhone: 'BIND_PHONE_NOW',
    },
    btnText: 'NEXT_STEP_BUTTON',
    verifyType: {
      reEmail: LoginVerifyCodeType.uvctEmailOld,
      rePhone: LoginVerifyCodeType.uvctPhoneOld,
    },
  },
  sec: {
    title: {
      reEmail: 'EDIT_CHANGE_EMAIL',
      rePhone: 'CHANGE_PHONE_VERIFY',
    },
    btnText: 'NEXT_STEP_BUTTON',
    verifyType: {
      reEmail: LoginVerifyCodeType.uvctEmailNew,
      rePhone: LoginVerifyCodeType.uvctPhoneNew,
    },
  },
  finally: {
    title: {
      reEmail: 'BINDING_SUCCEEDED',
      rePhone: 'BINDING_SUCCEEDED',
    },
    btnText: 'OK_BUTTON',
  },
}

const MODAL_STEP_CONFIG = {
  first: {
    title: {
      email: 'EMAIL_VERIFY_2',
      phone: 'BIND_PHONE_NOW',
    },
    btnText: 'NEXT_STEP_BUTTON',
    verifyType: {
      email: LoginVerifyCodeType.uvctVerifyEmail,
      phone: LoginVerifyCodeType.uvctVerifyPhone,
    },
  },
  finally: {
    title: {
      email: 'BINDING_SUCCEEDED',
      phone: 'BINDING_SUCCEEDED',
    },
    btnText: 'OK_BUTTON',
  },
}

const INIT_VERIFYTYPE_MAP = {
  email: MODAL_STEP_CONFIG.first.verifyType.email,
  phone: MODAL_STEP_CONFIG.first.verifyType.phone,
  reEmail: MODAL_STEP_CONFIG_RE.first.verifyType.reEmail,
  rePhone: MODAL_STEP_CONFIG_RE.first.verifyType.rePhone,
}

/**
 * todo 登录注册
 * 账号disable 通过当前type和step组合判断
 * first step 账号label信息提示 未验证绑定手机时是不安全状态
 * 接口调用
 */

function BindInfoModal() {
  const toast = useToast()
  const openWalletService = useWalletService()
  const { open, data } = useAppSelector(
    createSelector(selectModal, globalModal => globalModal.BindInfoModal),
  )
  const disaptch = useAppDispatch()
  const { t } = useTranslation()

  const formRef = useRef<FormRef>(null)
  const finalAccountValue = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const type = data?.type || 'email'
  const source = data?.source
  const [verifyType, setVerifyType] = useState<LoginVerifyCodeType>(
    data?.verifyType || INIT_VERIFYTYPE_MAP[type],
  )
  const [modalStep, setModalStep] = useState<'first' | 'sec' | 'finally'>(
    'first',
  )
  const [finalType, setFinalType] = useState<'reload' | undefined>()

  const { bindInfoValue } = useAccountsInfo()
  const isLogin = useAppSelector(selectUserIsLogin)

  const { refetch: bindPhoneQuery } = useBindUserPhoneInfoQuery(
    {},
    { skip: !isLogin || !SHOW_BIND_PHONE },
  )

  /** 校验验证码 */
  const [verifyCode, { isLoading: isVerifying }] = useVerifyCodeLoginMutation()

  /** 换绑接口 */
  const [setAccount, { isLoading: isSetting }] = useSetAccountMutation()

  const modalStepCfg: Record<
    'first' | 'sec' | 'finally',
    {
      title: Record<'email' | 'phone' | 'reEmail' | 'rePhone', string>
      btnText: string
      verifyType?: Record<
        'email' | 'phone' | 'reEmail' | 'rePhone',
        LoginVerifyCodeType
      >
    }
  > = useMemo(() => {
    if (['reEmail', 'rePhone'].includes(type)) {
      return MODAL_STEP_CONFIG_RE as any
    }
    return MODAL_STEP_CONFIG
  }, [type])

  const bigType = useMemo(() => {
    if (['email', 'reEmail'].includes(type)) {
      return 'email'
    }
    return 'phone'
  }, [type])

  const handlePrecalibration = async () => {
    return (await formRef.current?.validateFormItem('account_value')) || false
  }

  const account = () => {
    return formRef.current?.getFieldValue('account_value')
  }

  const { BtnRender, flowId } = useVerifyCode({
    handlePrecalibration,
    loginType: verifyType || LoginVerifyCodeType.uvctBindingEmail,
    account,
    from: source === 'withdraw' ? 'withdraw' : 'profile',
  })

  const handleSubmit = async (values: any) => {
    setIsLoading(true)
    if (modalStep === 'first') {
      if ('sec' in modalStepCfg) {
        /**
         * todo 登录注册
         * 验证旧账号验证码 verifyCodeLogin
         * 入参 verifyType flowId value.verify_code
         */
        try {
          await verifyCode({
            ...values,
            flow_id: flowId || '',
            verify_type: verifyType,
          }).unwrap()
          localStorage.removeItem(`login_verify_code_${verifyType}`)
        } catch (e: any) {
          setIsLoading(false)
          const title = t(
            getLoginCodeErrorMessage(e.status) || 'ABNORMAL_OPERATION',
          )
          toast({
            title,
            status: 'error',
          })
          return
        }
        formRef.current?.setFieldsValue({
          account_value: undefined,
          verify_code: undefined,
        })
        setVerifyType(
          modalStepCfg?.sec?.verifyType?.[type] as LoginVerifyCodeType,
        )
        setModalStep('sec')
        setIsLoading(false)
      } else {
        /**
         * todo 登录注册
         * 验证旧账号验证码 verifyCodeLogin
         * 入参 verifyType flowId value.verify_code
         */
        try {
          const { data } = await ([
            LoginVerifyCodeType.uvctVerifyPhone,
            LoginVerifyCodeType.uvctVerifyEmail,
          ].includes(verifyType)
            ? verifyCode
            : setAccount)({
            ...values,
            flow_id: flowId || '',
            verify_type: verifyType,
          }).unwrap()
          finalAccountValue.current = values.account_value
          localStorage.removeItem(`login_verify_code_${verifyType}`)
          setFinalType(data.re_login ? 'reload' : undefined)
          bindPhoneQuery()
        } catch (e: any) {
          setIsLoading(false)
          let title = t(
            getLoginCodeErrorMessage(e.status) || 'ABNORMAL_OPERATION',
          )
          if (e.status === 6) {
            title = t(bigType === 'email' ? 'EMAIL_IS_BOUND' : 'PHONE_IS_BOUND')
          }
          toast({
            title,
            status: 'error',
          })
          return
        }
        setModalStep('finally')
        setIsLoading(false)
      }

      return
    }

    if (modalStep === 'sec') {
      /**
       * todo 登录注册
       * 绑定新账号 setAccount
       * 入参 verifyType flowId value.verify_code
       */
      try {
        const { data } = await setAccount({
          ...values,
          flow_id: flowId || '',
          verify_type: verifyType,
        }).unwrap()
        finalAccountValue.current = values.account_value
        localStorage.removeItem(`login_verify_code_${values.verify_type}`)
        setFinalType(data.re_login ? 'reload' : undefined)
        bindPhoneQuery()
      } catch (e: any) {
        setIsLoading(false)
        const title = t(
          getLoginCodeErrorMessage(e.status) || 'ABNORMAL_OPERATION',
        )
        toast({
          title,
          status: 'error',
        })
        return
      }
      setModalStep('finally')
      setIsLoading(false)
      return
    }

    if (modalStep === 'finally') {
      if (finalType === 'reload') {
        window.location.reload()
      }
      handleClose()
      setIsLoading(false)
      return
    }
    setModalStep('finally')
    setIsLoading(false)
  }

  const handleClose = () => {
    if (modalStep === 'finally') {
      if (finalType === 'reload') {
        window.location.reload()
      }
    }
    disaptch(closeModal({ key: GlobalModalKey.BindInfoModal }))
  }

  const accountLabelRender = () => {
    return (
      <Flex alignItems='center'>
        <Text mr='4px'>
          {t(
            ['reEmail', 'email'].includes(type)
              ? 'USER_EMAIL'
              : 'HISTORY_PHONE',
          )}
        </Text>
        {modalStep === 'first' && (
          <InfoTooltip
            tooltipProps={{
              p: '8px',
              maxW: '188px',
              borderRadius: '10px',
              placement: 'top',
              label: bindInfoValue?.[bigType]?.verified ? null : (
                <Text textStyle='text6' textAlign='center'>
                  {t(
                    type.includes('email')
                      ? 'BIND_EMAIL_MUST'
                      : 'BIND_PHONE_MUST',
                  ).toString()}
                </Text>
              ),
            }}
            iconNode={
              <Image
                src={
                  bindInfoValue?.[bigType]?.verified ? IconShield : IconAlert
                }
                cursor='pointer'
                w='16px'
                h='16px'
              />
            }
          />
        )}
      </Flex>
    )
  }

  const formRender = () => {
    return (
      <>
        {['reEmail', 'email'].includes(type) ? (
          <EmailFormItem
            key={`${modalStep}_${type}`}
            name='account_value'
            label={accountLabelRender()}
            disabled={!!(modalStep === 'first' && bindInfoValue?.email?.value)}
            rules={[
              {
                validator: handleRealEmailValidator,
              },
            ]}
          />
        ) : (
          <PhoneFormItem
            key={`${modalStep}_${type}`}
            name='account_value'
            disabled={!!(modalStep === 'first' && bindInfoValue?.phone?.value)}
            label={accountLabelRender()}
            rules={[{ validator: handlePhoneValidator }]}
          />
        )}

        <Flex>
          <VerifyCodeFormItem
            key={`${modalStep}_${type}_code`}
            label={t('PHONE_CODELABEL')}
            name='verify_code'
            rules={[{ required: true, message: 'USER_RESET_PW_CODE_INPUT' }]}
          />
          <Box mt='26px'>
            <BtnRender />
          </Box>
        </Flex>
      </>
    )
  }

  const finallyStepRender = () => {
    let content = ''

    if ('sec' in modalStepCfg) {
      content = t(
        type.toLocaleLowerCase().includes('email')
          ? 'EMAIL_CHANGE_LOG'
          : 'PHONE_CHANGE_LOG',
        { number: finalAccountValue.current },
      )
    }

    if (!source) {
      return (
        <Text textStyle='text5' px='55px' py='30px' textAlign='center'>
          {content ||
            t(finalType === 'reload' ? 'BIND_EMAIL_DOWN3' : 'BIND_EMAIL_DOWN2')}
        </Text>
      )
    }
    return (
      <Text textStyle='text5' px='55px' py='30px' textAlign='center'>
        {content || t('BIND_EMAIL_DOWN4')}
      </Text>
    )
  }

  const serviceClick = () => {
    openWalletService()
  }

  return (
    <TitledDialog
      isOpen={open}
      onClose={handleClose}
      title={t(
        modalStep === 'first' && source === 'withdraw'
          ? 'PH_BIND_CODE'
          : modalStepCfg?.[modalStep]?.title[type],
      )}
      size='sm'
    >
      {modalStep === 'first' && source === 'withdraw' && (
        <Text
          fontSize='12px'
          color='gray.700'
          lineHeight='20px'
          marginBottom='20px'
        >
          {t('PH_BIND_CODE3')}
        </Text>
      )}
      <Form
        initialValues={{
          account_value:
            modalStep === 'first' && bindInfoValue?.[bigType]?.value,
        }}
        key='ForgetPwdModal'
        ref={formRef}
        isLoading={isLoading || isSetting || isVerifying}
        onSubmit={handleSubmit}
        submitBtnText={t(modalStepCfg[modalStep].btnText).toString()}
      >
        {['first', 'sec'].includes(modalStep) && formRender()}

        {modalStep === 'finally' && finallyStepRender()}
      </Form>

      {modalStep !== 'finally' && (
        <Box w='full' textAlign='center' mb='-16px'>
          <Box
            m='16px auto 0'
            display={'inline-flex'}
            alignItems={'center'}
            as='button'
            onClick={serviceClick}
          >
            <LazyImage
              src={service}
              mr='5px'
              w='24px'
              h='24px'
              cursor='pointer'
              alt=''
            />
            <Box
              color={'#1376FA'}
              fontSize={'12px'}
              textDecoration={'underline'}
            >
              {t('MENU_ONLINE_SERVICE')}
            </Box>
          </Box>
        </Box>
      )}
    </TitledDialog>
  )
}

export default BindInfoModal
