import { Button, Highlight, Text } from '@chakra-ui/react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import TitledDialog from '@/components/modal/TitledDialog'

import { useAppDispatch, useAppSelector } from '../app/store'
import { usePageChangeSubscription } from '../app-layout/usePageChangeNotification'
import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'

export default function FavoriteLimitModal() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { open } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.FavoriteLimit],
  )
  const handleClose = useCallback(() => {
    dispatch(closeModal({ key: GlobalModalKey.FavoriteLimit }))
  }, [dispatch])

  usePageChangeSubscription(handleClose)

  return (
    <TitledDialog
      isOpen={open}
      title={t('EXCEED_LIMIT')}
      onClose={handleClose}
      action={
        <Button
          as={RouterLink}
          colorScheme='fill-prim'
          w='full'
          size='lg'
          to='/user/my-games/favorite'
        >
          {t('CHECK_NOW')}
        </Button>
      }
    >
      <Text w='full' textAlign='center' textStyle='text3'>
        <Highlight query='100' styles={{ color: 'system.normal.fail' }}>
          {t('EXCEDD_FAVORITE_LIMIE').toString()}
        </Highlight>
      </Text>
    </TitledDialog>
  )
}
