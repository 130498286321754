import type { ComponentStyleConfig } from '@chakra-ui/react'

import {
  NAME,
  PARTS,
} from '@/modules/inhouse/common/components/cash-out/CashOut'

import theme from './inhouse'

const { mainTextColor } = theme
const parts = [...PARTS]
const componentStyle: ComponentStyleConfig = {
  parts,
  baseStyle: {
    box: {
      bgColor: 'gray.400',
      color: mainTextColor,
    },
    label: {
      color: mainTextColor,
    },
    input: {
      bgColor: 'gray.400',
    },
    button: {
      bgColor: 'gray.500',
      color: mainTextColor,
    },
  },
}

export const name = NAME
export default componentStyle
