import { useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import type { GameListItem } from '@/modules/game/gameInterface'
import {
  selectAllManuList,
  selectGameList,
  selectGmpInhouseGame,
  showTips,
} from '@/modules/game/gameSlice'
import { selectFeatSwitchWithKey } from '@/modules/app-layout/app-layout-slice'

export const gameDefensePath = [
  '/game/inhouse',
  '/game/slot',
  '/game/live',
  '/game/fundist',
  '/game/pgsoft',
  '/game/jili',
  '/game/facai',
  '/game/self',
]

/**
 * 解决某些游戏屏蔽的情况下，从路由进入游戏，需要弹出维护弹窗
 * @returns
 */
export const useCheckValidGame = () => {
  const location = useLocation()
  const { gameId } = useParams()
  const allGameList = useAppSelector(selectGameList)
  const manuList = useAppSelector(selectAllManuList)
  const dispatch = useAppDispatch()
  const inhouse2Gmp = useAppSelector(selectFeatSwitchWithKey('inhouse_2_gmp'))
  const gmpInhouseGame = useAppSelector(selectGmpInhouseGame)

  /**
   * inhouse游戏是否gmp
   */
  const switchIsOn = useMemo(() => {
    const toGmpGames = inhouse2Gmp?.param || []
    return (
      inhouse2Gmp?.enable === 1 && toGmpGames.includes(gameId?.toLowerCase())
    )
  }, [inhouse2Gmp, gameId])

  useEffect(() => {
    if (!gameId) return
    const pathname = location.pathname
    const isInhouseGame = pathname.startsWith('/game/inhouse')
    const isHitPath = gameDefensePath.some(item => pathname.startsWith(item))
    if (isHitPath) {
      let selectGame = {} as GameListItem
      if (isInhouseGame) {
        // 自营游戏的话，需要用item?.expand?.game_uuid去比较难
        if (!switchIsOn) {
          selectGame = allGameList.find(
            item => item?.expand?.game_uuid === gameId,
          ) as GameListItem
        } else {
          selectGame = gmpInhouseGame.find(
            item => item?.game_name?.toLowerCase() === gameId?.toLowerCase(),
          ) as GameListItem
        }
      } else {
        // 非自营游戏的话，需要拿游戏ID去比较
        selectGame = allGameList.find(
          item => item?.game_id === Number(gameId),
        ) as GameListItem
      }

      // 是否是有效游戏
      let isInvalidGame = false
      // 判断游戏是不是有效：游戏是否存在 || 游戏是否下线 || 游戏是否维护 || 或者游戏对应的厂商是否关闭或者在维护
      if (!selectGame) {
        isInvalidGame = true
      } else {
        if (selectGame?.game_status !== 1 || selectGame?.is_under_maint === 1) {
          isInvalidGame = true
        } else {
          // 找到对应的厂商
          const manuId = selectGame?.game_manufacturer
          if (manuId) {
            // 如果游戏配置了厂商
            const selectManu = manuList?.find(
              item => Number(item?.id) === Number(manuId),
            )
            isInvalidGame =
              (selectManu && selectManu?.is_enabled !== 1) ||
              selectManu?.is_under_maint === 1
          }
        }
      }

      if (allGameList.length > 0 && isInvalidGame) {
        dispatch(showTips(selectGame?.maintenance_text || ''))
      }
    }
  }, [location, allGameList, gameId, dispatch, switchIsOn, gmpInhouseGame])

  return {}
}
