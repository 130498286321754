import { Box, Flex, GridItem, Icon } from '@chakra-ui/react'
import { useEffect } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { isFeatureEnabled } from '@/common/feature'
import { Icon as SharedIcon } from '@/utils/atom-shared'

import { useAppDispatch } from '../app/store'
import { closeDrawerStart, DrawerKey } from '../app-layout/app-layout-slice'

const tabList = [
  {
    key: 'jogos',
    name: 'Jogos',
    path: '/',
    icon: <Icon as={SharedIcon.Game} boxSize='16px' marginRight='7px' />,
  },
  {
    key: 'sport',
    name: 'Sport',
    path: '/user/sport',
    icon: <Icon as={SharedIcon.Esport} boxSize='16px' marginRight='7px' />,
  },
]
const getTabStyle = (isActive: boolean) => {
  let tabStyle
  if (isActive) {
    tabStyle = {
      itemStyle: {
        color: 'white',
      },
    }
  } else {
    tabStyle = {
      itemStyle: {
        _hover: {
          color: 'white',
        },
      },
    }
  }
  return tabStyle
}
interface ISportTabProps {
  type: string
}
export default function SportTab(props: ISportTabProps) {
  const { type } = props
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const isSport = pathname === '/user/sport'

  useEffect(() => {
    if (!isFeatureEnabled('sport')) {
      return
    } else if (isSport) {
      dispatch(closeDrawerStart(DrawerKey.Left))
    }
  }, [dispatch, isSport])

  if (!isFeatureEnabled('sport')) {
    return null
  }

  return (
    <Flex
      width='220px'
      height='32px'
      maxWidth='100%'
      background='linear-gradient(360deg, #105BBC  0%, #022054 100%)'
      borderRadius='48px'
      position='relative'
      flexShrink='0'
      display={type === 'header' ? ['none', 'flex'] : ['flex', 'none']}
    >
      <Box
        position='absolute'
        top='3px'
        left='3px'
        bg='prim.500'
        width='103px'
        height='26px'
        borderRadius='80px'
        transition='all 0.3s ease 0s'
        transform={!isSport ? 'translateX(0px)' : 'translateX(111px)'}
      />
      {tabList.map(item => (
        <GridItem
          key={item.key}
          as={RouterLink}
          to={item.path}
          display='flex'
          width='50%'
          textStyle='h7'
          alignItems='center'
          justifyContent='center'
          cursor='pointer'
          zIndex='1'
          transition='all 0.3s ease 0s'
          {...getTabStyle(pathname === item.path).itemStyle}
        >
          {item.icon}
          {item.name}
        </GridItem>
      ))}
    </Flex>
  )
}
