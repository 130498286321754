export enum EShowType {
  Overview,
  Open,
  Result,
}

/**
 * 转盘类型
 */
export enum ERingType {
  /**
   * 准备阶段的转盘
   */
  Ready,
  /**
   * 抽奖转起来的转盘
   */
  Running,
}

export interface RecordItem {
  p_type: string
  small_type: string
  spec: string
  time: number
  user_name: string
}

export type RecordList = RecordItem[]

export interface IWheelResult {
  prize_id: number
  p_type: string
  small_type: string
  spec: string
}

export interface IPrize {
  p_type: string
  pid: number
  small_type: string
  spec: string
}

/**
 * 抽奖配置
 */
export interface IRaffleConfig {
  bet_integral: number
  image: string
  model: string
  prizes: Array<IPrize>
  temp_id: number
  update_at: number
  user_integral: number
}

export interface ITotalBonus {
  total_bonus: string
  type: string
}

export interface IWinnerItem {
  user_name: string
  raffle_bonus: string
}
