import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import {
  Box,
  Button,
  Center,
  CloseButton,
  Flex,
  Grid,
  GridItem,
  Highlight,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { sum, sumBy } from 'lodash-es'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { SwiperProps } from 'swiper/react'
import type { Swiper as SwiperClass } from 'swiper/types'
import jb1 from '@/assets/images/login-reward-modal/3-point.png'
import jb2 from '@/assets/images/login-reward-modal/5-point.png'
import jb3 from '@/assets/images/login-reward-modal/7-point.png'
import chest_day_1 from '@/assets/images/login-reward-modal/chest_day_1.png'
import chest_day_2 from '@/assets/images/login-reward-modal/chest_day_2.png'
import chest_day_3 from '@/assets/images/login-reward-modal/chest_day_3.png'
import chest_day_4 from '@/assets/images/login-reward-modal/chest_day_4.png'
import chest_day_5 from '@/assets/images/login-reward-modal/chest_day_5.png'
import chest_day_6 from '@/assets/images/login-reward-modal/chest_day_6.png'
import chest_day_7 from '@/assets/images/login-reward-modal/chest_day_7.png'
import { getSign, getCurrencyCode, formatNumberToK } from '@/common/locale/currency'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useModalConfig } from '@/modules/auto-modal/useModal'
import { toggleBalanceFloat, updateBanlance } from '@/modules/user/userSlice'
import { useUserAuth } from '@/utils/hooks'
import useExchangeCurrency from '@/utils/hooks/useExchangeCurrency'
import useUserInfo from '@/utils/hooks/useUserInfo'

import { useAppDispatch, useAppSelector } from '../app/store'
import {
  closeModal,
  openModal,
  selectModal,
} from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { selectADDLoginReward } from '../guide-event/guideEventSlice'
import useADDModalControl from '../guide-event/useADDModalControl'
import NewVipLevelIcon from '../new-vip/components/NewVipLevelIcon'
import { userVipConfigListSelector } from '../new-vip/newVipSlice'
import { rotateAnimation } from '../wheel-modal/style'
import CountDownToEnd from './CountDown'
import {
  useLoginTaskCfqQuery,
  useLogintaskClaimMutation,
  useLoginTaskInfoQuery,
} from './loginRewardModalApi'
import {
  selectLoginReward,
  selectLoginRewardAmountIsHidden,
} from './loginRewardSlice'
import { getNewRewardsStyle } from './style'
import type { CfgListItem, IInfoResult } from './types'

/**
 * 将item分为
 * finish：已领取
 * active：待领取
 * default：未领取
 * 三种状态
 */
const rewardsStatus = (item: CfgListItem, curInfo?: IInfoResult) => {
  if (!curInfo) {
    return 'default'
  }
  if (
    item?.day === 7 &&
    curInfo?.cur_day > 7 &&
    !curInfo?.cur_receive &&
    curInfo?.vip_level === item?.vip_level
  ) {
    return 'active'
  }
  if (curInfo?.cur_day > item?.day && curInfo?.vip_level === item?.vip_level) {
    return 'finish'
  }
  if (
    curInfo?.cur_day === item?.day &&
    curInfo?.vip_level === item?.vip_level
  ) {
    if (curInfo?.cur_receive) {
      return 'finish'
    } else {
      return 'active'
    }
  } else {
    return 'default'
  }
}
/**
 * 根据奖励金额区分金币图片
 */
const imgList = [jb1, jb1, jb1, jb2, jb2, jb2, jb3]

const rewardsImg = (goldLevel: number) => {
  return [jb1, jb1, jb2, jb2, jb3, jb3, jb3][goldLevel]
}

/**
 *
 * 有金额的奖励
 */
function RewardWithAmount({
  v,
  cfqAmountList,
  index,
  status,
}: {
  v: CfgListItem
  cfqAmountList: number[][]
  index: number
  status: string
}) {
  const CURRENCY_SYMBOL = getSign()
  const CURRENCY_CODE = getCurrencyCode()
  const { formatValue } = useExchangeCurrency()
  return (
    <div
      style={
        v.day === 7
          ? {
              display: 'flex',
              alignItems: 'center',
              margin: 'auto',
              justifyContent: 'space-around',
              paddingTop: 20,
            }
          : {}
      }
    >
      <LazyImage
        // width='108px'
        src={imgList[v.day - 1]}
        marginTop={v.day === 7 ? '-1px' : '28px'}
        margin={v.day === 7 ? '' : 'auto'}
        // marginTop={v.day === 7 ? '-1px' : '15px'}
        alt='coin'
        padding={v.day === 7 ? '0px 15px 0px 0px' : '32px 0px 5px 0px'}
        position='relative'
        // zIndex='1'
      />
      <Text
        color={status !== 'default' ? '#11645B' : '#fff'}
        fontWeight='800'
        lineHeight='24px'
        textAlign='center'
        fontSize={(CURRENCY_SYMBOL === 'KSh' || CURRENCY_CODE === 'COP') ? '12px' :'16px'}
        flex={v.day === 7 ? 1 : 0}
      >
        {CURRENCY_SYMBOL}
        {formatNumberToK(v?.reward_amount)}
      </Text>
    </div>
  )
}

function RewardWithOutAmount({ v }: { v: CfgListItem }) {
  const imgCoinList = useMemo(
    () => [
      chest_day_1,
      chest_day_2,
      chest_day_3,
      chest_day_4,
      chest_day_5,
      chest_day_6,
    ],
    [],
  )
  const imgSrc = useMemo(() => {
    return imgCoinList[v.day - 1]
  }, [imgCoinList, v.day])
  return (
    <>
      {v.day !== 7 ? (
        <Image
          maxW='100%'
          src={imgSrc}
          margin='0 auto'
          marginTop='35px'
          height='35px'
          alt='coin'
          position='relative'
        />
      ) : (
        <Flex justifyContent='space-between' alignItems='center' w='full'>
          {/* <Text
            ml='64px'
            textStyle='h4'
            background='linear-gradient(270deg, #F1F46A 0%, #FFF 95.56%)'
            backgroundClip='text'
          >
            {t('DAY7_BONUS')}
          </Text> */}
          <Image
            maxW='180px'
            src={chest_day_7}
            height='52px'
            alt='coin'
            margin='0 auto'
            marginTop={5}
            position='relative'
          />
        </Flex>
      )}
    </>
  )
}

function NewLoginReward() {
  const CURRENCY_SYMBOL = getSign()
  const CURRENCY_CODE = getCurrencyCode()
  const dispatch = useAppDispatch()
  const [activeLevel, setActiveLevel] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    window.onload = function () {
      console.log('All images are loaded')
    }
  }, [])
  const swiperRef = useRef<SwiperClass>(null)

  // 是否隐藏金额
  const isHideAmount = useAppSelector(selectLoginRewardAmountIsHidden)

  const cfqList = useAppSelector(
    (state: any) => selectLoginReward(state).cfgList,
  )
  const rechargeAmount = useAppSelector(
    state => selectLoginReward(state).rechargeAmount,
  )
  const progressAmount = useAppSelector(
    state => selectLoginReward(state).progressAmount,
  )

  const activityRule = useAppSelector(
    state => selectLoginReward(state).activityRule,
  )
  const [loginTaskClaim] = useLogintaskClaimMutation()
  const { userInfo } = useUserInfo()
  const { open } = useAppSelector(
    (state: any) => selectModal(state)[GlobalModalKey.LoginReward],
  )
  const { onCloseModal } = useModalConfig()
  const [userId, token] = useUserAuth()
  useLoginTaskCfqQuery({ token, user_id: userId }, { skip: !token || !userId })
  const {
    data: curInfo,
    refetch,
    isUninitialized,
  } = useLoginTaskInfoQuery(
    { token, user_id: userId },
    { skip: !token || !userId },
  )

  /** 引流下载App弹窗 */
  const diversionModalCfg = useAppSelector(selectADDLoginReward)
  const { handleAddModalShow } = useADDModalControl(diversionModalCfg)

  useEffect(() => {
    if (open && !isUninitialized) {
      refetch()
    }
  }, [open, refetch, isUninitialized])
  const [isloading, setLoading] = useState<boolean>(false)
  const [cfqAmountList, setCfqAmountList] = useState<number[][]>([[0]])
  const swiperParams: SwiperProps = {
    spaceBetween: 10,
    centeredSlides: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    slidesPerView: 'auto',
    initialSlide:
      cfqList.findIndex(
        item => item[0].vip_level === curInfo?.data.vip_level,
      ) === -1
        ? 0
        : cfqList.findIndex(
            item => item[0].vip_level === curInfo?.data.vip_level,
          ),
    onActiveIndexChange: swiper => {
      setActiveLevel(cfqList[swiper.realIndex][0]?.vip_level)
      setActiveIndex(swiper.realIndex)
    },
    onAfterInit: swiper => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      swiperRef.current = swiper
    },
    simulateTouch: false,
  }
  const handleClose = useCallback(() => {
    dispatch(closeModal({ key: GlobalModalKey.LoginReward }))
    onCloseModal(GlobalModalKey.LoginReward)
  }, [dispatch, onCloseModal])
  const userVipConfigList = useAppSelector(userVipConfigListSelector)
  const handleEventRule = () => {
    onCloseModal(GlobalModalKey.LoginReward)
    dispatch(closeModal({ key: GlobalModalKey.LoginReward }))

    dispatch(
      openModal({
        key: GlobalModalKey.newLoginRewardModal,
        data: {
          isEventRule: true,
          activityRule,
        },
      }),
    )
    return
  }
  const handleItemClick = async (v: CfgListItem, isItem?: boolean) => {
    if (isloading || rewardsStatus(v, curInfo?.data) !== 'active') {
      if (isItem) return
      if (!curInfo?.data?.vip_level) {
        dispatch(
          openModal({
            key: GlobalModalKey.newLoginRewardModal,
            data: {
              curInfo: curInfo?.data,
              amount: sumBy(cfqList[activeIndex], 'reward_amount'),
              rechargeAmount: (userVipConfigList ?? [])?.[
                curInfo?.data?.vip_level ?? 0
              ]?.vipInfo?.recharge_progress?.diff,
            },
          }),
        )
        return
      }
      return
    }
    if (handleAddModalShow()) {
      return
    }
    setLoading(true)
    await loginTaskClaim({ token, user_id: userId })
      .unwrap()
      .then((payload: { code: number }) => {
        setLoading(false)
        dispatch(closeModal({ key: GlobalModalKey.newLoginRewardModal }))
        if (payload?.code === 200) {
          const reward = v?.reward_amount
          const blc: number = userInfo.banlance?.amount as number
          const addAmount = reward
          dispatch(
            toggleBalanceFloat({
              balance: addAmount,
            }),
          )
          dispatch(
            openModal({
              key: GlobalModalKey.newLoginRewardModal,
              data: {
                isSuccessReward: true,
                nextDateRewardAmount:
                  cfqList?.[
                    (curInfo?.data?.vip_level || 1) - 1 || activeIndex
                  ]?.[
                    MAXIMUM_DATE_CHECKIN === v?.day
                      ? MAXIMUM_DATE_CHECKIN - 1
                      : v?.day
                  ]?.reward_amount || 0,
                rewardAmount: reward || 0,
              },
            }),
          )
          const res: number = Number(blc) + Number(addAmount)
          updateBanlance(Number(res))
        } else {
          dispatch(
            openModal({
              key: GlobalModalKey.newLoginRewardModal,
              data: {
                curInfo: curInfo?.data,
                vip_level: v?.vip_level,
                amount: sumBy(cfqList?.[
                  (curInfo?.data?.vip_level || 1) - 1 || activeIndex
                ], 'reward_amount'),
                rechargeAmount: progressAmount,
              },
            }),
          )
        }
      })
      .catch(() => {
        setLoading(false)
        dispatch(
          openModal({
            key: GlobalModalKey.newLoginRewardModal,
            data: {
              curInfo: curInfo?.data,
              vip_level: v?.vip_level,
              amount: sumBy(cfqList?.[
                (curInfo?.data?.vip_level || 1) - 1 || activeIndex
              ], 'reward_amount'),
              rechargeAmount: progressAmount,
            },
          }),
        )
      })
  }

  useEffect(() => {
    if (cfqList.length > 0) {
      setCfqAmountList(
        cfqList.map((item: any[]) => {
          return [
            ...new Set(
              item.map((v: { reward_amount: any }) => v.reward_amount),
            ),
          ].sort((a, b) => a - b)
        }),
      )
      setActiveLevel(cfqList[0][0]?.vip_level)
    }
  }, [cfqList])
  const MAXIMUM_DATE_CHECKIN = 7
  const vip1Value = `${CURRENCY_SYMBOL}${formatNumberToK(
    curInfo?.data?.vip_level
      ? progressAmount
      : (userVipConfigList ?? [])?.[curInfo?.data?.vip_level ?? 0]?.vipInfo
          ?.recharge_progress?.diff,
  )}`
  const viplevel = `VIP ${curInfo?.data?.vip_level}`
  const nextVipLevel = `VIP ${curInfo?.data?.vip_level + 1}`
  return (
    <>
      <Modal
        isOpen={open}
        onClose={handleClose}
        isCentered
        motionPreset='none'
        size='sm'
      >
        <ModalOverlay />
        <ModalContent
          width={['100%', '355px']}
          maxHeight='100%'
          maxWidth='100%'
          backgroundColor='transparent'
          boxShadow='none'
        >
          {curInfo && !!cfqList?.length && (
            <ModalBody padding='0' margin='0'>
              <div>
                <LazyImage
                  src='/assets/images/login-reward-modal/vip-bg-title.png'
                  alt=''
                  position='relative'
                  zIndex={2}
                  top='35px'
                  transform='translateX(-50%)'
                  left='50%'
                />
                <CloseButton
                  position='absolute'
                  right='1'
                  top='75px'
                  zIndex={999}
                  style={{
                    color: '#fff',
                  }}
                  onClick={handleClose}
                />
              </div>
              <div>
                {cfqList.map((item: any, index: number) =>
                  index === curInfo?.data?.vip_level - 1 ||
                  (curInfo?.data?.vip_level === index &&
                    curInfo?.data?.vip_level === 0) ? (
                    // index === activeIndex - 1 ||
                    // index === activeIndex + 1 ||
                    // index === cfqList.length - 1 ||
                    // index === 0
                    <div
                      key={'swiper_' + item[0].vip_level}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Box
                        position='relative'
                        borderRadius='33px'
                        minHeight='477px'
                        padding='26px 18px 12px'
                        bg='bg.modal'
                        overflow='hidden'
                        style={{
                          backgroundImage: `url('/assets/images/login-reward-modal/bgReward.png')`,
                          width: '355px',
                          backgroundSize: 'cover',
                        }}
                      >
                        {(progressAmount > 0 || !curInfo?.data?.vip_level) && (
                          <Flex>
                            <Text
                              textStyle='h3'
                              textAlign='left'
                              paddingTop='20px'
                              marginBottom='20px'
                              color='#fff'
                            >
                              {t('CONTINUOUS_LOGIN_TITTLE')}
                            </Text>
                            <Text
                              textStyle='h3'
                              paddingTop='20px'
                              textAlign='right'
                              justifyContent='end'
                              width='100%'
                              marginBottom='20px'
                              lineHeight={1.2}
                              color='#F9EB2D'
                            >
                              <p style={{ fontSize: (CURRENCY_SYMBOL === 'KSh' || CURRENCY_CODE === 'COP') ? '25px' : '40px'}}>
                                {CURRENCY_SYMBOL}
                                {formatNumberToK(sumBy(item, 'reward_amount'))}
                              </p>
                              <p style={{ fontSize: '12px' }}>
                                {t('CONTINUOUS_LOGIN_TITTLE1')}
                              </p>
                            </Text>
                          </Flex>
                        )}
                        <Flex
                          alignItems={
                            (!progressAmount && curInfo?.data?.vip_level)  ? 'center' : ''
                          }
                          justifyContent={
                            (!progressAmount && curInfo?.data?.vip_level)  ? 'center' : ''
                          }
                          paddingTop={
                            (!progressAmount && curInfo?.data?.vip_level)  ? '20px' : '0px'
                          }
                        >
                          {(progressAmount > 0 || !curInfo?.data?.vip_level) && (
                            <Text
                              width='50%'
                              fontSize='12px'
                              textAlign={
                                (!progressAmount && curInfo?.data?.vip_level) 
                                  ? 'center'
                                  : 'left'
                              }
                              color='#FFFFFF80'
                            >
                              <Highlight
                                query={vip1Value}
                                styles={{
                                  color: '#F9EB2D',
                                }}
                              >
                                {
                                  t(!curInfo?.data?.vip_level ? 'CONTINUOUS_LOGIN_TITTLE2_VIP0' : 'CONTINUOUS_LOGIN_TITTLE2_VIP1', {
                                    amount: vip1Value,
                                  })!
                                }
                              </Highlight>
                              {/* <span
                                style={{
                                  color: '#F9EB2D',
                                  padding: '0px 3px 0px 3px',
                                }}
                              >
                                {CURRENCY_SYMBOL}
                                {curInfo.data?.vip_level
                                  ? progressAmount
                                  : (userVipConfigList ?? [])?.[
                                      curInfo?.data?.vip_level ?? 0
                                    ]?.vipInfo?.recharge_progress?.diff}
                              </span>
                              {t('RECHARE_LOGIN_REWARD_2')} */}
                            </Text>
                          )}

                          <a href='/vip' style={{ cursor: 'pointer', marginBottom: (!progressAmount && curInfo?.data?.vip_level) ? 0 : 55 }}>
                            <Flex
                              alignItems='center'
                              justifyContent='center'
                              marginBottom={
                                (!progressAmount && curInfo?.data?.vip_level) ? '20px' : 0
                              }
                              style={{
                                backgroundImage: (!progressAmount && curInfo?.data?.vip_level) 
                                  ? `url('/assets/images/login-reward-modal/vip-bg-2.png')`
                                  : `url('/assets/images/login-reward-modal/vip-bg.png')`,
                                right: -5,
                                // width: '157px',
                                padding: (!progressAmount && curInfo?.data?.vip_level) 
                                  ? 'unset'
                                  : '0px 10px 8px 10px',
                                paddingTop: (!progressAmount && curInfo?.data?.vip_level) 
                                  ? 'unset'
                                  : '0px',
                                height: (!progressAmount && curInfo?.data?.vip_level) 
                                  ? '32px'
                                  : '40px',
                                position: (!progressAmount && curInfo?.data?.vip_level) 
                                  ? 'unset'
                                  : 'absolute',
                                width: '164px',
                                backgroundSize: 'cover',
                              }}
                            >
                              {/* <Box
                                cursor='pointer'
                                onClick={() => {
                                  swiperRef.current?.slidePrev()
                                }}
                              >
                                <LazyImage
                                  src='/assets/images/login-reward-modal/arrow-left.svg'
                                  alt=''
                                />
                              </Box> */}
                              <Center
                                color={
                                  activeLevel === curInfo.data.vip_level
                                    ? 'four.500'
                                    : 'white'
                                }
                                textStyle='h4'
                              >
                                <Center
                                  style={{
                                    display: 'flex',
                                    fontSize: '12px',
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingRight: '5px',
                                  }}
                                >
                                  <NewVipLevelIcon
                                    level={curInfo.data.vip_level}
                                    size={28}
                                    padding={
                                      curInfo.data.vip_level > 9
                                        ? '4px 4px 4px 20px'
                                        : '4px 4px 4px 25px'
                                    }
                                    defaultWidth={28}
                                  />
                                  <span
                                    style={{
                                      color: (!progressAmount && curInfo?.data?.vip_level) 
                                        ? '#ffffff'
                                        : '#4179C2',
                                    }}
                                  >
                                    {/* {t('CONTINUOUS_LOGIN_VIP')}:{' '} */}
                                    <Highlight
                                      query={viplevel.toString()}
                                      styles={{
                                        color: '#F9EB2D',
                                      }}
                                    >
                                      {
                                        t('CONTINUOUS_LOGIN_VIP', {
                                          level: viplevel,
                                        })!
                                      }
                                    </Highlight>
                                  </span>
                                  {/* <span style={{ color: '#F9EB2D' }}>
                                    {' '}
                                    {t('MOBILE_NAV_VIP')}
                                    {curInfo.data.vip_level}
                                  </span> */}
                                </Center>
                              </Center>
                              <LazyImage
                                src='/assets/images/login-reward-modal/btn-next.png'
                                alt='btn-next'
                              />
                            </Flex>
                          </a>
                        </Flex>
                        {(!progressAmount && curInfo?.data?.vip_level) ? (
                          <Grid
                            templateColumns='repeat(2, 153px)'
                            gap='9px'
                            paddingBottom={3}
                          >
                            <GridItem
                              height={'74px'}
                              padding={2}
                            borderRadius={8}
                              background='rgba(255, 255, 255, 0.1)'
                            >
                              {/* <Text align='center' color='#fff' fontSize={10}>
                                {t('CHECK_IN_RELIZE')}
                              </Text>
                              <Text
                                align='center'
                                color='#F9EB2D'
                                fontSize={10}
                              >
                                ({t('MOBILE_NAV_VIP')} {curInfo.data.vip_level})
                              </Text> */}
                              <Text align='center' color='#fff' fontSize={10}>
                                <Highlight
                                  query={viplevel.toString()}
                                  styles={{
                                    color: '#F9EB2D',
                                  }}
                                >
                                  {
                                    t('CONTINUOUS_LOGIN_TEXT1', {
                                      level: `(${viplevel})`,
                                    })!
                                  }
                                </Highlight>
                              </Text>

                              <Text
                                align='center'
                                color='#F9EB2D'
                                fontSize={(CURRENCY_SYMBOL === 'KSh' || CURRENCY_CODE === 'COP') ? '20px' :'26px'}
                                fontWeight='bold'
                              >
                                {CURRENCY_SYMBOL}
                                {formatNumberToK(sum(curInfo?.data?.receive_list))}
                              </Text>
                            </GridItem>
                            <GridItem
                              height={'74px'}
                              padding={2}
                              borderRadius={8}
                              background='rgba(255, 255, 255, 0.1)'
                            >
                              {/* <Text align='center' color='#fff' fontSize={10}>
                                {t('CHECK_IN_RELIZE_CONTINOUS')}{' '}
                                <span style={{ color: '#F9EB2D' }}>
                                  ({t('MOBILE_NAV_VIP')}{' '}
                                  {curInfo.data.vip_level + 1})
                                </span>
                              </Text> */}
                              <Text align='center' color='#fff' fontSize={10}>
                                <Highlight
                                  query={nextVipLevel}
                                  styles={{
                                    color: '#F9EB2D',
                                  }}
                                >
                                  {
                                    t('CONTINUOUS_LOGIN_TEXT2', {
                                      next_level: `(${nextVipLevel})`,
                                    })!
                                  }
                                </Highlight>
                              </Text>
                              <Text
                                align='center'
                                color='#F9EB2D'
                                fontSize={(CURRENCY_SYMBOL === 'KSh' || CURRENCY_CODE === 'COP') ? '20px' :'26px'}
                                fontWeight='bold'
                              >
                                {CURRENCY_SYMBOL}
                                {formatNumberToK(sumBy(
                                  cfqList?.[curInfo?.data?.vip_level],
                                  'reward_amount',
                                ))}
                              </Text>
                            </GridItem>
                          </Grid>
                        ) : ''}
                        <Grid
                          templateColumns='repeat(4, 72px)'
                          gap={'9px'}
                          style={{ justifyContent: 'center' }}
                        >
                          {item.map((v: CfgListItem, i: string) => (
                            <GridItem
                              key={'rewards_' + index + '_' + i}
                              width='100%'
                              position='relative'
                              overflow='hidden'
                              // cursor={
                              //   rewardsStatus(v, curInfo?.data) === 'active'
                              //     ? 'unset'
                              //     : 'not-allowed'
                              // }
                              backgroundColor={
                                rewardsStatus(v, curInfo?.data) !== 'default'
                                  ? '#C9FEF2'
                                  : '#1D71F0'
                              }
                              {...getNewRewardsStyle(v.day)}
                            >
                              {rewardsStatus(v, curInfo?.data) !==
                                'default' && (
                                <LazyImage
                                  src='/assets/images/login-reward-modal/light.png'
                                  height='223px'
                                  marginRight='-116px'
                                  marginTop='-112px'
                                  position='absolute'
                                  right='50%'
                                  top='50%'
                                  width='232px'
                                  maxWidth='initial'
                                  animation={
                                    rewardsStatus(v, curInfo?.data) === 'active'
                                      ? rotateAnimation
                                      : ''
                                  }
                                  zIndex='0'
                                  // cursor='pointer'
                                />
                              )}
                              <Box
                                backgroundColor={
                                  rewardsStatus(v, curInfo?.data) !== 'default'
                                    ? '#76F8EE'
                                    : '#40A3FF'
                                }
                                // borderRadius='10px 0'
                                borderRadius='10px 10px 0 0'
                                color={
                                  rewardsStatus(v, curInfo?.data) !== 'default'
                                    ? 'linear-gradient(to right, #C9FEF2 0%, #76F8EE 100%)'
                                    : '#fff'
                                }
                                fontSize='12px'
                                fontWeight='800'
                                height='24px'
                                lineHeight='24px'
                                left='0'
                                line-height='24px'
                                position='absolute'
                                textAlign='center'
                                top='0'
                                width='100%'
                              >
                                DAY{' '}
                                {v.day === MAXIMUM_DATE_CHECKIN
                                  ? `${MAXIMUM_DATE_CHECKIN}+`
                                  : v.day}
                              </Box>
                              {/* 如果后台开启了隐藏登录奖励的特性开关,则隐藏金额*/}
                              {!isHideAmount ? (
                                <RewardWithAmount
                                  v={v}
                                  cfqAmountList={cfqAmountList}
                                  index={index}
                                  status={rewardsStatus(v, curInfo?.data)}
                                />
                              ) : (
                                <RewardWithOutAmount v={v} />
                              )}
                              {rewardsStatus(v, curInfo?.data) === 'finish' && (
                                <Center
                                  position='absolute'
                                  top='0'
                                  left='0'
                                  width='100%'
                                  height='100%'
                                  backgroundColor='rgba(0,0,0,0.6)'
                                >
                                  <LazyImage
                                    src='/assets/images/login-reward-modal/icon-checked-success.png'
                                    alt='iconSuccess'
                                  />
                                </Center>
                              )}
                              {curInfo.data.vip_level === v.vip_level &&
                                curInfo.data.cur_receive &&
                                curInfo.data.cur_day + 1 === v.day && (
                                  <Center
                                    width='77px'
                                    height='22px'
                                    background='rgba(0,0,0,0.5)'
                                    borderRadius='32px'
                                    fontSize='14px'
                                    color='white'
                                    position='absolute'
                                    top='50%'
                                    left='50%'
                                    transform='translate(-50%,-50%)'
                                    zIndex={2}
                                  >
                                    <CountDownToEnd endOfType='day' />
                                  </Center>
                                )}
                            </GridItem>
                          ))}
                        </Grid>
                        {
                          // (
                          //   curInfo.data.vip_level ===
                          //     cfqList[cfqList.length - 1][0].vip_level &&
                          //   curInfo.data?.progress_amount === 0
                          // ) &&
                          <>
                            <Button
                              minHeight='50px'
                              colorScheme='fill-prim'
                              fontSize='lg'
                              color={
                                rewardsStatus(
                                  cfqList[
                                    curInfo?.data?.vip_level - 1 || activeIndex
                                  ]?.[
                                    (MAXIMUM_DATE_CHECKIN <
                                    curInfo?.data?.cur_day
                                      ? MAXIMUM_DATE_CHECKIN
                                      : curInfo?.data?.cur_day) - 1
                                  ],
                                  curInfo?.data,
                                ) === 'finish'
                                  ? '#fff'
                                  : '#87370A'
                              }
                              textAlign='center'
                              margin='auto'
                              fontWeight='extrabold'
                              marginTop='16px'
                              style={{
                                display: 'flex',
                                width: '215px',
                                backgroundImage:
                                  rewardsStatus(
                                    cfqList?.[
                                      curInfo?.data?.vip_level - 1 ||
                                        activeIndex
                                    ]?.[
                                      (MAXIMUM_DATE_CHECKIN <
                                      curInfo?.data?.cur_day
                                        ? MAXIMUM_DATE_CHECKIN
                                        : curInfo?.data?.cur_day) - 1
                                    ],
                                    curInfo?.data,
                                  ) === 'finish'
                                    ? `url('/assets/images/login-reward-modal/butbg-disabled.png')`
                                    : `url('/assets/images/login-reward-modal/butbg.png')`,
                                backgroundSize: 'cover',
                              }}
                              onClick={() =>
                                handleItemClick(
                                  cfqList?.[
                                    curInfo?.data?.vip_level - 1 || activeIndex
                                  ]?.[
                                    (MAXIMUM_DATE_CHECKIN <
                                    curInfo?.data?.cur_day
                                      ? MAXIMUM_DATE_CHECKIN
                                      : curInfo?.data?.cur_day) - 1
                                  ],
                                )
                              }
                            >
                              <span
                                style={{
                                  color:
                                    rewardsStatus(
                                      cfqList?.[
                                        curInfo?.data.vip_level - 1
                                      ]?.[0],
                                      curInfo?.data,
                                    ) === 'finish'
                                      ? '#fff'
                                      : '#87370A',
                                }}
                              >
                                {t('CONTINUOUS_LOGIN_BUTTON_UNCLAIMED')}
                              </span>
                            </Button>
                          </>
                        }

                        <Text
                          fontSize='12px'
                          fontWeight='600'
                          color='#F9EB2D'
                          marginTop='12px'
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                          textAlign='center'
                          onClick={() => handleEventRule()}
                        >
                          {t('CONTINUOUS_LOGIN_POPUP_TITTLE_RULE')}
                        </Text>
                      </Box>
                    </div>
                  ) : (
                    ''
                  ),
                )}
              </div>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

export default NewLoginReward
