export enum ModalType {
  bind = 'bind', //首次绑定
  bind_disabled = 'bind_disabled', //首次绑定,但不允许修改手机号
  bindsuccess = 'bindsuccess', //首次绑定成功
  rebind = 'rebind', //重新绑定
  changesuccess = 'changesuccess', // 解绑当前成功 需要再次绑定新手机号
  rebindsuccess = 'rebindsuccess', //再次绑定成功
}
export type ModalTypeE =
  | 'bind'
  | 'bind_disabled'
  | 'bindsuccess'
  | 'rebind'
  | 'changesuccess'
  | 'rebindsuccess'

export interface CommonVerifyProps {
  verify_btn_text: string //按钮-获取验证码
  verify_btn_error1: string //获取验证码超10次提示文案
  verify_phone_error1: string //输入限制错误提示
  verify_phone_error2: string //手机号已被绑定
  verify_code_label: string //验证码
  verify_code_error1: string //验证码错误
  verify_code_error2: string // 1分钟内重复获取验证码
}
export interface BindPhoneModalItem {
  title: string //
  desc: string //
  btn_text: string
  verify_label: string //
  verify_placeholder: string //input占位符
  disabled: boolean
  type?: ModalTypeE
}
export interface ModalTypeProps {
  [ModalType.bind]: BindPhoneModalItem
  [ModalType.bind_disabled]: BindPhoneModalItem
  [ModalType.bindsuccess]: BindPhoneModalItem
  [ModalType.rebind]: BindPhoneModalItem
  [ModalType.changesuccess]: BindPhoneModalItem
  [ModalType.rebindsuccess]: BindPhoneModalItem
}

//
export interface PhoneCode {
  phone: string
  area_code?: string
  code_type?: 'login' | 'resign' | 'withdraw' | 'personal'
}
export interface PhoneType {
  code: string
  phone: string
  area_code?: string
}

export interface ChangePhoneType extends PhoneType {
  old_phone: string
}
