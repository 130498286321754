import { getVariable } from '@/common/env'

export function isAlphabetic(text: string): boolean {
  const pattern = new RegExp('^[a-zA-Z]+$')
  return pattern.test(text)
}

export function isValidEmailAddress(emailAddress: string): boolean {
  const pattern = new RegExp(
    '^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_-]+([.][a-zA-Z]+){1,2}$',
  )
  return pattern.test(emailAddress)
}

export function isValidPassword(password: string): boolean {
  return password.length >= 8 && password.length <= 64
}

export function isSimplePasswordTest(password: string): boolean {
  return (
    password.length >= 8 &&
    (/^\d+$/.test(password) ||
      /^[a-zA-Z]+$/.test(password) ||
      /^[^a-zA-Z\d]+$/.test(password))
  )
}

// test

export function isLowQualityPassword(password: string): boolean {
  // 如果沒有輸入密碼，就也不走这里的检查
  if (!password) return false
  // 如果密碼長度小於 8，也不走这里的检查
  if (password.length < 8) return false

  // 如果密码长度大于64, 也不走这里的检查
  if (password.length > 64) return false
  // 如果密碼長度大於 8，但是只有數字、或只有字母、或只有符號，才走这里的检查
  return !isSimplePasswordTest(password)

  return true
}

export function isNotEmpty(value: string): boolean {
  return value.length > 0
}

export function isValidPhoneNumber(phoneNumber: string): boolean {
  // 如果是邮箱，就不走这里的检查
  if (phoneNumber.includes('@')) return true
  if (!phoneNumber.length) return false

  return phoneNumber.length === Number(getVariable('PHONE_AREA_LENGTH'))
}

export function isValidOnlyPhoneNumber(phoneNumber: string): boolean {
  if (!phoneNumber.length) return false

  return (
    phoneNumber.length === Number(getVariable('PHONE_AREA_LENGTH')) &&
    isAllNumber(phoneNumber)
  )
}

export function isAllNumber(value: string): boolean {
  const pattern = new RegExp('^[0-9]+$')
  return pattern.test(value)
}

export function getWrongPhoneFormatMsg(phone: string): string {
  if (!phone) return 'DEPOSIT_INPUT_PHONE'
  return 'PHONE_WRRONG_COMMON' ?? 'PHONE_WRRONG1'
}
