import type { AccountTypeForTrack } from '@/modules/user/userSlice'

import type { LoginType, RegType } from '../const'
import { EventName } from '../const'
import { getTrackInstance } from './track'

export function trackPageView(data: { pathname: string }) {
  getTrackInstance().track(EventName.ContentView, data)
}

export type TrackRegisterData = {
  user_id: string
  account_type: AccountTypeForTrack
  device_type: string
  from?: string
  hig_one?: string
}

export function isTrackRegisterData(v: unknown): v is TrackRegisterData {
  if (typeof v !== 'object' || v === null) {
    return false
  }

  const { user_id, account_type, device_type, from, hig_one } =
    v as TrackRegisterData

  if (
    typeof user_id !== 'string' ||
    typeof account_type !== 'string' ||
    typeof device_type !== 'string'
  ) {
    return false
  }

  if (from !== undefined && typeof from !== 'string') {
    return false
  }

  if (hig_one !== undefined && typeof hig_one !== 'string') {
    return false
  }

  return true
}

export function trackRegister(regType: RegType, data?: any) {
  getTrackInstance().track(EventName.CompleteRegistration, data)
}

export function trackLogin(loginType: LoginType) {}

type TrackFirstRechargeData = {
  value: number
  currency: string
  predicted_ltv: number
  user_id: string
  hig_one?: string
  order_id: string
  type?: string
}

export function isTrackFirstRechargeData(
  v: unknown,
): v is TrackFirstRechargeData {
  if (typeof v !== 'object' || v === null) {
    return false
  }

  const { value, currency, predicted_ltv, user_id, hig_one, order_id, type } =
    v as TrackFirstRechargeData

  if (
    typeof value !== 'number' ||
    typeof currency !== 'string' ||
    typeof predicted_ltv !== 'number' ||
    typeof user_id !== 'string' ||
    typeof order_id !== 'string'
  ) {
    return false
  }

  if (hig_one !== undefined && typeof hig_one !== 'string') {
    return false
  }

  if (type !== undefined && typeof type !== 'string') {
    return false
  }

  return true
}

export function trackFirstRecharge(data: TrackFirstRechargeData) {
  getTrackInstance().track(EventName.CompletePurchase, {
    ...data,
    type: 'FIRST_RECHARGE',
  })
}

/**
 * 进入首页的埋点
 */
export function trackEnterHome() {
  getTrackInstance().track(EventName.EnterHome)
}

/**
 * 进入注册页面的埋点
 */
export function trackEnterRegister() {
  getTrackInstance().track(EventName.EnterRegister)
}

/**
 * 进入登录页面的埋点
 */
export function trackEnterLogin() {
  getTrackInstance().track(EventName.EnterLogin)
}

/**
 * 点击钱包按钮
 */
export function trackClickWallet() {
  getTrackInstance().track(EventName.ClickWallet)
}

/**
 * 展示充值页面
 */
export function trackShowRechargePanel() {
  getTrackInstance().track(EventName.ShowRechargePanel)
}

/**
 * 点击充值按钮
 */
export function trackClickRecharge() {
  getTrackInstance().track(EventName.ClickRecharge)
}

/**
 * 展示提现页面
 */
export function trackShowWithdrawPanel() {
  getTrackInstance().track(EventName.ShowWithdrawPanel)
}

/**
 * 点击下载apk
 */
export function trackClickDownload() {
  getTrackInstance().track(EventName.ClickDownload)
}

/**
 * 浏览推广活动页面
 */
export function trackViewPromotion() {
  getTrackInstance().track(EventName.viewPromotion)
}

/**
 * 游戏收藏埋点
 */

export function trackAddToWishList() {
  getTrackInstance().track(EventName.addToWishList)
}

/**
 * 首次打开页面
 */
export function trackFirstOpen() {
  getTrackInstance().track(EventName.FirstOpen)
}
