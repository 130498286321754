const common = {
  borderRadius: '10px',
  background: 'linear-gradient(360deg, #105BBC 0%, #022054 100%)',
  padding: ' 12px 18px 0 12px',
  position: 'fixed',
}
export const boxStyle = {
  ...common,
  width: '365px',
  height: '80px',
  top: '20px',
  right: '40px',
}
export const boxMobileStyle = {
  ...common,
  height: '80px',
  left: '0px',
  right: '0px',
}

export const textOneStyle = {
  fontSize: '12px',
  fontWeight: '600',
  color: '#fff',
}
export const textTwoStyle = {
  fontSize: '16px',
  fontWeight: '800',
  color: '#F9EB2D',
}
