import { Box, Circle, HStack, Image, Text, VStack } from '@chakra-ui/react'
import { t } from 'i18next'

import BalanceImg from '@/websites/B2/public/assets/images/advantage/balance.png'
import CoinImag from '@/websites/B2/public/assets/images/advantage/coin.png'
import HandImg from '@/websites/B2/public/assets/images/advantage/hand.png'
import VipImg from '@/websites/B2/public/assets/images/advantage/vip.png'

function Advantage() {
  const titleStyle = {
    fontSize: 18,
    fontWeight: 800,
    lineHeight: '6',
    marginLeft: {
      lg: '2',
      base: '3',
    },
    marginRight: {
      lg: '2',
      base: '3',
    },
  }

  const circleSize = {
    lg: 2,
    base: 2,
  }

  const iconSize = '56'

  const numberStyle = {
    fontSize: 40,
    fontWeight: 900,
    lineHeight: '52px',
  }

  const subTitleStyle = {
    fontSize: 18,
    fontWeight: 800,
    lineHeight: '6',
    textAlign: 'center',
  }

  const descriptionStyle = {
    fontWeight: 600,
    width: '314px',
    fontSize: '12',
    lineHeight: '4',
    textAlign: 'center',
  }

  const icons = [
    {
      image: BalanceImg,
      numberColor: '#2781F8',
      subTitle: t('BZ_ADVANTAGE_T1'),
      description: t('BZ_ADVANTAGE_D1'),
    },
    {
      image: CoinImag,
      numberColor: '#ED1D49',
      subTitle: t('BZ_ADVANTAGE_T2'),
      description: t('BZ_ADVANTAGE_D2'),
    },
    {
      image: VipImg,
      numberColor: '#ED1D49',
      subTitle: t('BZ_ADVANTAGE_T3'),
      description: t('BZ_ADVANTAGE_D3'),
    },
    {
      image: HandImg,
      numberColor: '#2781F8',
      subTitle: t('BZ_ADVANTAGE_T4'),
      description: t('BZ_ADVANTAGE_D4'),
    },
  ]
  return (
    <Box
      sx={{
        marginTop: {
          lg: '6',
          base: '5',
        },
        marginBottom: '5',
        width: 'full',
        paddingLeft: {
          lg: '18',
          base: '8',
        },
        paddingRight: {
          lg: '16.5',
          base: '8',
        },
      }}
    >
      <VStack>
        <HStack display='flex' alignItems='center' justifyContent='center'>
          {/* 这里颜色用的配置表的颜色,如果后期用于各个平台这里，需要设计变更 */}
          <Circle size={circleSize} bgColor='fill-seco.pressed1' />
          <Text color='white' sx={titleStyle}>
            {t('BZ_ADVANTAGE')}
          </Text>
          <Circle size={circleSize} bg='fill-prim.pressed1' />
        </HStack>
        <VStack
          sx={{
            marginTop: {
              lg: '2.5',
            },
            display: 'grid',
            gridTemplateColumns: {
              lg: 'repeat(2, 1fr)',
              base: 'repeat(1, 1fr)',
            },
            gridRowGap: {
              lg: '8',
              base: '5',
            },
            width: 'full',
          }}
        >
          {icons.map((icon, index) => (
            <Box key={index}>
              <VStack>
                <Image src={icon.image} boxSize={iconSize} />
                <Text sx={numberStyle} color={icon.numberColor}>
                  {index + 1 < 10 ? `0${index + 1}` : index + 1}
                </Text>
                <Text sx={subTitleStyle} color='white'>
                  {icon.subTitle}
                </Text>
                <Text sx={descriptionStyle} color='gray.700'>
                  {icon.description}
                </Text>
              </VStack>
            </Box>
          ))}
        </VStack>
      </VStack>
    </Box>
  )
}

export default Advantage
