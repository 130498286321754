import property from '@/websites/current/property.json'

import type { EventConfigType, TrackFn } from '../const'
import { EventName } from '../const'

const { projectName } = property

function Cookies(name: string) {
  const value = '; ' + document.cookie
  const parts = value.split('; ' + name + '=')
  if (parts.length === 2) return parts?.pop()?.split(';').shift() || ''
}

const NORMAL_EVENT_CONFIGS: EventConfigType = {
  [EventName.ContentView]: ['PageView'],
  [EventName.CompleteRegistration]: ['CompleteRegistration'],
  [EventName.PlaceAnOrder]: ['InitiateCheckout'],
  [EventName.CompletePurchase]: ['Purchase'],
  [EventName.EmailRegister]: ['CompleteRegistration'],
  [EventName.PhoneRegister]: ['CompleteRegistration'],
}

const N1_EVENT_CONFIGS: EventConfigType = {
  [EventName.ContentView]: ['PageView'],
}

const EVENT_CONFIGS = (() => {
  switch (projectName) {
    case 'N1':
      return N1_EVENT_CONFIGS
    default:
      return NORMAL_EVENT_CONFIGS
  }
})()

const fbqTrack = (
  facebookId: string,
  event: string,
  data?: Record<string, any>,
) => {
  switch (projectName) {
    case 'N1':
      fbq('track', event, data)
      break
    default:
      fbq('trackSingleCustom', facebookId, event, data)
  }
}
export const trackerFn: TrackFn = (event, data) => {
  const channel = sessionStorage.getItem('channel')
  if (channel !== 'facebook') {
    return
  }
  const facebookId = sessionStorage.getItem('facebookId')

  const events = EVENT_CONFIGS[event]

  if (!events || !facebookId || !fbq) {
    return
  }

  for (const event of events) {
    fbqTrack(facebookId, event, data)
  }
}
