import { useCallback, useEffect, useState } from 'react'
import type { Location } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'

function useHistoryListen() {
  const location = useLocation()
  const navigate = useNavigate()
  const [routes, setRoutes] = useState<Location[]>([])

  useEffect(() => {
    // 监听路由变化
    setRoutes(prev => {
      const newRoutes = [...prev, location]
      return newRoutes
    })
  }, [location])

  const goBack = useCallback(() => {
    if (routes.length >= 1) {
      navigate(routes[routes.length - 1].state?.from || '/')
      setRoutes(prev => {
        const newRoutes = [...prev]
        newRoutes.pop()
        return newRoutes
      })
    } else {
      navigate('/')
    }
  }, [navigate, routes])
  return {
    goBack,
    routes,
  }
}

export default useHistoryListen
