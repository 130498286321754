import type { AuthQueryArgs } from '@/modules/user/userApi'
import { api } from '@/services/api'
import type { Response } from '@/services/types'

import type { Message } from './types'

export const notificationApi = api.injectEndpoints({
  endpoints: builder => ({
    getMessages: builder.query<
      Response<Message[]>,
      AuthQueryArgs & { uid: string; page?: number; pageSize?: number }
    >({
      query: ({ uid, page = 1, pageSize = 10, token, user_id }) => {
        return {
          url: '/api/v2/message/list',
          method: 'POST',
          params: {
            uid,
            page,
            pageSize,
          },
          data: {
            token,
            user_id,
          },
        }
      },
      providesTags: ['Auth', 'Message'],
    }),
    markMessageAsRead: builder.mutation<
      Response,
      AuthQueryArgs & { guid: string }
    >({
      query: data => {
        return {
          url: '/api/v2/message/set_read',
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Message'],
    }),
    markMessageDeleted: builder.mutation<
      Response,
      AuthQueryArgs & { guid: string }
    >({
      query: data => {
        return {
          url: '/api/v2/message/del',
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Message'],
    }),
    readerMessageDeleted: builder.mutation<
      Response<{ delete_times: number }>,
      AuthQueryArgs
    >({
      query: data => {
        return {
          url: '/api/v2/message/del_read',
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Message'],
    }),
    claimRewards: builder.mutation<Response, AuthQueryArgs & { guid: string }>({
      query: data => {
        return {
          url: '/api/v2/message/receive_rewards',
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Message'],
    }),
    claimAllRewards: builder.mutation<
      Response<{ amount?: number[] }>,
      AuthQueryArgs
    >({
      query: data => {
        return {
          url: '/api/v2/message/receive_rewards_all',
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Message'],
    }),
  }),
})

export const {
  useGetMessagesQuery,
  useMarkMessageAsReadMutation,
  useMarkMessageDeletedMutation,
  useClaimRewardsMutation,
  useClaimAllRewardsMutation,
  useReaderMessageDeletedMutation,
} = notificationApi
