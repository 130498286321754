import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const KEEP_ARR = [
  'promotionalChannel',
  'referralcode',
  'fromActivity',
  'userName',
  'fromAd',
]

/**
 * 该钩子为了保持在推广页时，切换页面路由，重刷新,路由中的query不丢失
 */
export function useRedirectWithQuery() {
  const { pathname, search } = useLocation()
  const prevLocationRef = useRef<{
    pathname: string
    search: URLSearchParams | string
  }>()

  useEffect(() => {
    if (
      prevLocationRef.current &&
      prevLocationRef.current.pathname !== pathname
    ) {
      const prevSearchParams = new URLSearchParams(
        prevLocationRef.current.search,
      )
      if (prevSearchParams.size > 0) {
        const searchParams = new URLSearchParams(search)
        prevSearchParams.forEach((value, key) => {
          if (KEEP_ARR.includes(key)) searchParams.set(key, value)
        })

        window.history.replaceState(
          null,
          '',
          `${pathname}${
            searchParams.toString().length ? `?${searchParams.toString()}` : ''
          }`,
        )
        // 如果以前有query,那query保持
        prevLocationRef.current = {
          pathname,
          search: prevLocationRef.current.search,
        }
      }
    } else {
      prevLocationRef.current = {
        pathname,
        search,
      }
    }
  }, [pathname, search])
}
