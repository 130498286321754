import { isFeatureEnabledV2 } from '@/common/featureSwiitch'
import { getDeviceType } from '@/utils/tools'
import { CloseIcon } from '@chakra-ui/icons'
import { Box, useToast, Button, IconButton, ToastId } from '@chakra-ui/react'
import { throttle } from 'lodash-es'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
const VERSION_CHECK_INTERVAL = 60 * 60 * 1000 // 1 hour in milliseconds
const VERSION_CHECK_THROTTLE = 15 * 60 * 1000 // 15 minuté in milliseconds
const useCheckVersion = () => { 
  const timerRef = useRef<NodeJS.Timeout | undefined>()
  const toast = useToast()
  const { t } = useTranslation()
  const isFeatureEnabled = isFeatureEnabledV2('enable_fe_smooth_update') 
  const toastIdRef = React.useRef<ToastId>()

  const _checkVersion = async () => {
    return fetch('/version.json', { cache: 'no-store' })
      .then(response => {
        if (!response.ok) {
          // Notify the React app when a new version is detected
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then(async data => {
        const currentData = await import('../../public/version.json')
        console.log(
          data.version,
          currentData.version,
          data.version === currentData.version,
          data.version === currentData.version,
          data.version === currentData.version,
        )

        if (data.version !== currentData.version) {
          if (toastIdRef.current) {
            toast.close(toastIdRef.current)
            toastIdRef.current = undefined
          }
          
          toastIdRef.current = toast({
            position: 'top',
            duration: null,
            isClosable: true,
            render: props => (
              <Box
                borderRadius='8px'
                bgColor='gray.500'
                p='16px'
                position='relative'
              >
                <IconButton
                  bg='transparent'
                  aria-label='close'
                  size='xs'
                  icon={<CloseIcon />}
                  onClick={props.onClose}
                  position='absolute'
                  right='0px'
                  top='0px'
                />
                {t('NEW_VERSION_TEXT')}
                <br />
                <Button size='sm' onClick={() => location.reload()}>
                  {t('NEW_VERSION_ACTION')}
                </Button>
              </Box> 
            ),
          })
          clearInterval(timerRef.current)
        }
      })
      .catch(error => {
        console.error('Error fetching version.json:', error)
      })
  }

  const checkVersion = throttle(_checkVersion, VERSION_CHECK_THROTTLE, { leading: true, trailing: false })

  useEffect(() => {
    const a = () => {
      if (
        getDeviceType() === 'apk' &&
        document.hidden === false &&
        isFeatureEnabled
      ) {
        checkVersion()
      }
    }

    document.addEventListener('visibilitychange', a)
    return () => {
      document.removeEventListener('visibilitychange', a)
    }
  }, [isFeatureEnabled])

  useEffect(() => {
    if (isFeatureEnabled) {
      timerRef.current = setInterval(checkVersion, VERSION_CHECK_INTERVAL)
    }
    return () => {
      clearInterval(timerRef.current)
    }
  }, [isFeatureEnabled])
}

export default useCheckVersion
