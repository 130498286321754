import { Box, HStack, Icon, Tab, TabList, Tabs, Text } from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { getVariable } from '@/common/env'
import { isFeatureEnabled } from '@/common/feature'
import AtomSelect from '@/components/atom-select/AtomSelect'
import { getPath as getReferralPath } from '@/routes/referral'
import { getIcon } from '@/utils/get-icon'
import { useLargerThanMobile } from '@/utils/hooks/useLargerThanMobile'

import { useAppDispatch, useAppSelector } from '../app/store'
import { setLoginAfterRedirect } from '../app-layout/app-layout-slice'
import { openModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { selectIsBigAgent, selectUserIsLogin } from '../user/userSlice'
import { useGetReferralTaskQuery, useGetUserReferralTaskQuery } from './api'
import { useIsBigAgentQuery } from './bigAgentApi'

type Option = {
  label: string
  value: string
  icon: string
}

/**
 * @description: 用于判断是否登录的高阶组件，如果没有登录则跳转到上一个页面，如果登录了则渲染子组件
 */
export function WithAuthGuard(props: PropsWithChildren) {
  const { children } = props
  const isLogin = !!useAppSelector(selectUserIsLogin)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [isBlocking, setIsBlocking] = useState(!isLogin)

  const redictToLogin = useCallback(() => {
    navigate('/')
    try {
      requestIdleCallback(() =>
        dispatch(openModal({ key: GlobalModalKey.Login })),
      )
    } catch (e) {
      setTimeout(() => {
        dispatch(openModal({ key: GlobalModalKey.Login }))
      }, 300)
    }
  }, [dispatch, navigate])

  useEffect(() => {
    if (!isLogin) {
      dispatch(setLoginAfterRedirect(location.pathname))
      redictToLogin()
    }
    setIsBlocking(!isLogin)
  }, [isLogin, redictToLogin])

  return isBlocking ? null : children
}

export default function ReferralLayout() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isLargerThanMobile = useLargerThanMobile()
  const { pathname } = useLocation()

  const isBigAgent = useAppSelector(selectIsBigAgent)

  useIsBigAgentQuery({}, { skip: !isFeatureEnabled('bigAgent') })
  useGetUserReferralTaskQuery(null)
  useGetReferralTaskQuery(null)
  const AGENT_ARCHIEVE_TAB = getVariable('AGENT_ARCHIEVE_TAB')

  const tabs = useMemo(() => {
    const staticRoutes = [
      {
        text: 'REFERRAL_INVITE',
        icon: 'Usergroup',
        pathname: getReferralPath('/referral'),
        show: true,
      },
      {
        text: 'REFERRAL_REWARD',
        icon: 'Cup',
        pathname: getReferralPath('/referral/reward'),
        show: AGENT_ARCHIEVE_TAB,
      },
      {
        text: 'REFERRAL_STATICS',
        icon: 'Rise',
        pathname: getReferralPath('/referral/statics'),
        show: true,
      },
      {
        text: 'REFERRAL_FORM',
        icon: 'Detail',
        pathname: getReferralPath('/referral/details'),
        show: true,
      },
      {
        text: 'PROMOTION_CENTER',
        icon: 'Agent',
        pathname: getReferralPath('/referral/big_agent'),
        show: isBigAgent,
      },
    ]

    return staticRoutes.filter(item => !!item.show)
  }, [AGENT_ARCHIEVE_TAB, isBigAgent])

  const options = tabs.map(item => ({
    label: t(item.text),
    value: item.pathname,
    icon: item.icon,
  }))

  const activedIndex = useMemo(
    () => tabs.findIndex(item => item.pathname === pathname),
    [pathname, tabs],
  )

  return (
    <Tabs
      isFitted
      variant='game'
      colorScheme='referral'
      mt='3'
      index={activedIndex}
      onChange={index => navigate(tabs[index].pathname)}
    >
      {isLargerThanMobile ? (
        <TabList mb='5'>
          {tabs.map(item => (
            <Tab key={item.pathname} h='12'>
              <HStack spacing='4' h='full' overflow='hidden'>
                <Icon
                  as={getIcon(item.icon)}
                  boxSize='6'
                  fontWeight='normal'
                  color='var(--tab-icon-color)'
                />
                <Text
                  textStyle='h5'
                  overflow='hidden'
                  textOverflow='ellipsis'
                  whiteSpace='nowrap'
                >
                  {t(item.text)}
                </Text>
              </HStack>
            </Tab>
          ))}
        </TabList>
      ) : (
        <Box mb='2.5'>
          <AtomSelect<Option>
            onChange={newValue => newValue && navigate(newValue.value)}
            value={options[activedIndex]}
            options={options}
            size='lg'
          />
        </Box>
      )}
      <Outlet />
    </Tabs>
  )
}
