import { PopoverContent } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'

import commonStyles from '@/websites/current/styles/next-styles/common'

const EmailTipsPopoverContent: FC<PropsWithChildren & { width: number }> = ({
  children,
  width,
}) => {
  return (
    <PopoverContent
      width={`${width}px`}
      // width={{ base: '100vw', sm: '320px' }}
      padding='10px 0'
      maxHeight='250px'
      overflow='auto'
      flexShrink='0'
      bg={commonStyles.boxBgColor}
    >
      {children}
    </PopoverContent>
  )
}

export default EmailTipsPopoverContent
