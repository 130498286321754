import { Box, Flex, Image, Link as ChakraLink, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import type { ReactNode } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { isFeatureEnabled } from '@/common/feature'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  closeDrawerStart,
  DrawerKey,
} from '@/modules/app-layout/app-layout-slice'
import {
  selectUserCurrencySign,
  selectUserOriginalCurrencySign,
} from '@/modules/user/userSlice'
import { getConfigLng, handleS3SourceDomain, isFromAd } from '@/utils/tools'
import AppIcon from '@/websites/current/public/assets/images/about-icon/app-icon.png'
import CustomerService from '@/websites/current/public/assets/images/customer-service.png'

import type { Message } from '../../types'
import Reward from '../Reward'

const sceneProvider = createContext<{
  scene: 'index' | 'notification'
  onClose?: () => void
}>({
  scene: 'notification',
  onClose: undefined,
})

const LINK_TEXT = {
  color: 'prim.500',
  fontSize: '12px',
  fontWeight: '700',
  marginTop: '8px',
  display: 'block',
  // marginInline: '1',
}

const MESSAGE_IMG = {
  width: '100%',
  height: '128px',
  objectFit: 'cover',
  objectPosition: 'center',
  borderRadius: '14px',
  marginBlock: '16px',
}

const lng = getConfigLng()

const CountDownText = ({ countDownTime }: { countDownTime?: number }) => {
  const [time, setTime] = useState(countDownTime ?? 3)
  const { t } = useTranslation()

  const { onClose } = useContext(sceneProvider)

  useEffect(() => {
    const timer = setInterval(() => {
      if (time === 0) {
        onClose && onClose()
        clearInterval(timer)
        return
      }
      setTime(time - 1)
    }, 1000)

    return () => clearInterval(timer) // 清除定时器
  }, [onClose, time])

  return (
    <Text textStyle='text5' mt='3'>
      {t('READ_LATER')}
      {time > 0 && <Text as='span'>&nbsp;({time}s)</Text>}
    </Text>
  )
}

const NormalMessage = ({
  item,
  showReward,
  showLink,
}: {
  item: Message
  showReward?: boolean
  showLink?: boolean
}) => {
  const { scene } = useContext(sceneProvider)
  // const { t } = useTranslation()

  // 首页消息推送
  const fromIndex = scene === 'index'

  const originalSign = useAppSelector(selectUserOriginalCurrencySign)
  const fromAd = isFromAd()
  const currency = useAppSelector(selectUserCurrencySign)
  const renderMessage = useMemo(() => {
    const CUSTOM_MESSAGE_VERSION = 1
    if (item.message_version === CUSTOM_MESSAGE_VERSION)
      return {
        title: item.message?.[lng]?.title,
        message: item.message?.[lng]?.content,
        img: item.message?.[lng]?.img,
      }
    return {
      title:
        item.title_key && fromAd
          ? t(item.title_key, item.params ?? {}).replaceAll(
              originalSign!,
              currency!,
            )
          : t(item.title_key!, item.params ?? {}),
      message:
        item.message_key && fromAd
          ? t(item.message_key, item.params ?? {}).replaceAll(
              originalSign!,
              currency!,
            )
          : t(item.message_key!, item.params ?? {}),
    }
  }, [
    currency,
    fromAd,
    item.message,
    item.message_key,
    item.message_version,
    item.params,
    item.title_key,
    originalSign,
  ])

  const showMessageImg = useMemo(() => {
    return renderMessage.img && scene === 'notification'
  }, [renderMessage.img, scene])

  const indexMessageStyle = {
    // 内容三行截断
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }

  const showIconImageSrc = useMemo(() => {
    if (item.title_key === 'RECHARGE_SUCCESS_TITLE') {
      return CustomerService
    }
    return AppIcon
  }, [item.title_key])

  const countDownTime = useMemo(() => {
    return isFeatureEnabled('isMseriesWallet') &&
      item.title_key === 'RECHARGE_SUCCESS_TITLE'
      ? 10
      : 3
  }, [item.title_key])

  return (
    <Flex gap='3'>
      <Image src={showIconImageSrc} w='10' h='10' borderRadius='4px' />
      <Box flexGrow='1'>
        <Text
          textStyle='h6'
          color='gray.900'
          noOfLines={2}
          maxW='240px'
          pr='8'
          dangerouslySetInnerHTML={{ __html: renderMessage.title || '' }}
        />
        {showMessageImg ? (
          <Image
            src={handleS3SourceDomain(renderMessage.img!)}
            sx={MESSAGE_IMG}
          />
        ) : null}

        <Text
          textStyle='text6'
          color='gray.700'
          noOfLines={fromIndex ? 3 : undefined}
          maxW='255px'
          mt='2'
          dangerouslySetInnerHTML={{ __html: renderMessage.message || '' }}
          sx={fromIndex ? indexMessageStyle : undefined}
        />

        {showReward && (
          <Reward
            reward={{
              is_claim_rewards: item.is_claim_rewards,
              guid: item.guid,
              ...item.rewards,
            }}
          />
        )}

        {showLink && <Link item={item} />}
        {fromIndex ? <CountDownText countDownTime={countDownTime} /> : null}
      </Box>
    </Flex>
  )
}

const Link = ({ item }: { item: Message }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const onItemClick = (item: Message) => {
    if (item.jump_url) {
      const isOutSizeLink =
        item.jump_url.startsWith('http') || item.jump_url.startsWith('www')
      if (isOutSizeLink) {
        window.open(item.jump_url, '_blank')
        return
      }
      navigate(item.jump_url)
      dispatch(closeDrawerStart(DrawerKey.Right))
    }
  }

  const getLinkContent = useCallback((item: Message) => {
    const OLD_VERSION = 0
    const CUSTOM_VERSION = 1
    const version = item.message_version ?? OLD_VERSION
    if (version === OLD_VERSION) {
      return item.button_name ?? t('FAIRNESS_CHECK')
    } else if (version === CUSTOM_VERSION) {
      return item.message?.[lng]?.jump_title
    }
  }, [])

  const NormalLink = () => {
    return (
      <ChakraLink
        sx={LINK_TEXT}
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          onItemClick(item)
        }}
      >
        <Text noOfLines={1} maxW='255px'>
          {t(getLinkContent(item) || '')}
        </Text>
      </ChakraLink>
    )
  }

  return <NormalLink />
}

const LinkMessage = ({ item }: { item: Message }) => {
  return (
    <>
      <NormalMessage item={item} showLink />
    </>
  )
}

const RewardMessage = ({ item }: { item: Message }) => {
  const { scene } = useContext(sceneProvider)
  return (
    <NormalMessage
      item={item}
      showReward={scene !== 'index'}
      showLink={!!item.jump_url}
    />
  )
}

function Message({
  item,
  onClick,
  scene,
  onClose,
}: {
  item: Message
  onClick?: (e: any) => Promise<void>
  scene?: 'index' | 'notification'
  onClose?: () => void
}) {
  const show_type = item.show_type ? item.show_type : item.jump_url ? 1 : 0

  const renderMessageArr: Record<number, ReactNode> = {
    // 0 为普通消息
    0: <NormalMessage item={item} />,
    // 1 为链接消息
    1: <LinkMessage item={item} />,
    // 2 为奖励消息
    2: <RewardMessage item={item} />,
    // 3 不在后台配置返回,只存在于 抽奖活动中，显示站内信中使用
    3: <NormalMessage item={item} />,
  }
  return (
    <Box
      onClick={e => {
        onClick && onClick(e)
      }}
    >
      <sceneProvider.Provider
        value={{
          scene: scene ?? 'notification',
          onClose,
        }}
      >
        {renderMessageArr[show_type] ?? <NormalMessage item={item} />}
      </sceneProvider.Provider>
    </Box>
  )
}

export default Message
