import { Box, Button, Icon, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from '@/modules/app/store'
import { STORAGE_KEY } from '@/modules/app-layout/TopBanner'
import { logout } from '../../userSlice'

function PopoverFooter() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  return (
    <Box borderTop='none' py='0px' px='0'>
      <Button
        variant='ghost'
        w='full'
        height='50px'
        onClick={() => {
          const appLaunchSearchString =
            localStorage.getItem('app_launch_search_string') || ''
          dispatch(logout())
          sessionStorage.removeItem(STORAGE_KEY)
          setTimeout(() => {
            window.location.href = `${
              appLaunchSearchString ? '/' + appLaunchSearchString : '/'
            }`
          }, 300)
        }}
      >
        <Text
          color='personalCenter.logout'
          fontWeight='500'
          fontSize='14px'
          lineHeight='18px'
          cursor='pointer'
        >
          {t('NAV_LOGOUT')}
        </Text>
      </Button>
    </Box>
  )
}

export default PopoverFooter
