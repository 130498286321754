import {
  Box,
  Button,
  Icon,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { useCallback } from 'react'

import getRewardSucceedImg from '@/assets/images/get-reward-succeed.png'
import { getSign } from '@/common/locale/currency'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  closeModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useCustomizedStyle } from '@/utils/hooks'
import useExchangeCurrency from '@/utils/hooks/useExchangeCurrency'
import { useInternalDefaultProps } from '@/utils/hooks/useInternalDefaultProps'

export type SuccessModalData =
  | number
  | {
      amount: number
      tips?: string | null
    }

export default function SuccessModal() {
  const dispatch = useAppDispatch()
  const { formatValue } = useExchangeCurrency()
  const { open, data = 0 } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.RewardSuccess],
  )
  const handleClose = useCallback(() => {
    dispatch(closeModal({ key: GlobalModalKey.RewardSuccess }))
  }, [dispatch])

  if (!open) return null
  const tips = typeof data === 'object' ? data.tips : undefined
  const tipsType = typeof data === 'object' ? data.tipsType : undefined
  const amount = typeof data === 'object' ? data.amount : data
  const amountFormatted = formatValue(Number(amount))
  const content = t('CODE_BONUS', {
    currencySign: getSign(),
    amount: amountFormatted,
  })

  return (
    <SuccessModalPresentational
      isOpen={open}
      onClose={handleClose}
      onConfirm={handleClose}
      title={t('REWARD_SUCCESS_TITLE')}
      content={content}
      tips={tips}
      tipsType={tipsType}
      confirmBtnText={t('COMMON_CONFIRM')}
    />
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const NAME = 'SuccessModal'
// eslint-disable-next-line react-refresh/only-export-components
export const PARTS = ['content']

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  title?: string | null
  content?: string | null
  tips?: string | null
  tipsType?: string
  confirmBtnText?: string | null
}

/**
 * @description: SuccessModalPresentational component for SuccessModal UI display
 */
function SuccessModalPresentational(props: Props) {
  const {
    isOpen,
    onClose,
    onConfirm,
    title = '',
    content = '',
    tips = '',
    confirmBtnText,
    tipsType,
  } = props
  const styles = useCustomizedStyle(
    NAME,
    {
      content: {
        layerStyle: 'ls-accent',
      },
    },
    PARTS,
  )

  const internalDefaultProps = useInternalDefaultProps(NAME, {
    confirmBtn: { colorScheme: 'fill-reward-success-confirm' },
  })

  const tipsRender = () => {
    if (!tips) return null
    if (tipsType === 'html') {
      return (
        <Text
          textStyle='text6'
          mt='4'
          sx={{
            a: { color: 'four.500', textDecoration: 'underline' },
          }}
          dangerouslySetInnerHTML={{ __html: tips }}
        />
      )
    } else {
      return (
        <Text textStyle='text6' mt='4'>
          {tips}
        </Text>
      )
    }
  }

  return (
    <Modal
      autoFocus={false}
      trapFocus={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset='none'
      size='sm'
      closeOnOverlayClick
    >
      <ModalOverlay />
      <ModalContent pt='0' px='5' pb='4' color='white' sx={styles.content}>
        <LazyImage
          src={getRewardSucceedImg}
          w='190px'
          h='163px'
          mx='auto'
          mt='-27px'
        />
        <IconButton
          as={ModalCloseButton}
          variant='unstyled'
          minW='auto'
          w='auto'
          lineHeight='0'
          icon={<Icon as={SharedIcon.Close} color='white' boxSize='6' />}
          aria-label='Close'
          position='absolute'
          right='14px'
          top='14px'
        />
        <Box textAlign='center'>
          <Text textStyle='h6'>{title}</Text>
          <Text textStyle='h3' mt='2' color='four.500'>
            {content}
          </Text>
          <Button
            size='lg'
            display='flex'
            colorScheme={internalDefaultProps.confirmBtn.colorScheme}
            onClick={onConfirm}
            mt='6'
            mb='2'
            width='full'
          >
            {confirmBtnText}
          </Button>
          {tipsRender()}
        </Box>
      </ModalContent>
    </Modal>
  )
}
