import {
  Container,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Switch,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import iconsPath from '@/assets/svg/icons.svg'
import { isFeatureEnabled } from '@/common/feature'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { setIsFreeTryMode } from '@/modules/game/gameSlice'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { FavoriteButton } from '@/modules/my-games/favorite-button/FavoriteButton'
import { selectUserIsLogin } from '@/modules/user/userSlice'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useMobile } from '@/utils/hooks'
import { requestFullscreen } from '@/utils/tools'
import { useContext } from 'react'
import { FreeModeContext } from '@/modules/game/third-game/ThirdPartGameWrap'

interface ISvgIconProps {
  name: string
}

export const SvgIcon = (props: ISvgIconProps) => {
  const { name } = props

  return (
    <svg width='20px' height='20px'>
      <use xlinkHref={`${iconsPath}#icon-${name}`} />
    </svg>
  )
}

const ButtonAction = ({
  onFullScreen,
  gameId,
  gameUuid,
  isDemoSupported,
}: {
  onFullScreen?: () => void
  gameId?: string
  gameUuid: string
  isDemoSupported?: boolean
}) => {
  const navigate = useNavigate()
  const isMobile = useMobile()
  /** 关闭 */
  const onClose = () => {
    navigate('/')
  }

  /** 全屏 */
  const clickFullScreen = () => {
    try {
      if (isMobile) {
        onFullScreen && onFullScreen()
      } else {
        const fullEle = document.querySelector('#full-screen-container')
        requestFullscreen(fullEle)
      }
    } catch (e) {
      //
    }
  }

  return (
    <Container maxW='container.lg' p='0' mt='6px'>
      <HStack spacing='2'>
        <IconButton
          icon={<Icon as={SharedIcon.Close} />}
          aria-label='close'
          onClick={onClose}
          colorScheme='white'
        />
        <IconButton
          icon={<Icon as={SharedIcon.Amplify} />}
          aria-label='full screen'
          onClick={clickFullScreen}
          colorScheme='white'
        />

        {isFeatureEnabled('gameCollection') && gameId && (
          <FavoriteButton
            gameId={gameId}
            gameUuid={gameUuid}
            tooltipPlacement='right'
            colorScheme='white'
            variant='solid'
          />
        )}
        <Spacer />
        {isDemoSupported && <FreeModeSwitch />}
      </HStack>
    </Container>
  )
}

function FreeModeSwitch() {
  const { t } = useTranslation()

  const isLogin = useAppSelector(selectUserIsLogin)
  const isFreeMode = useContext(FreeModeContext)
  const dispatch = useAppDispatch()

  const onFreeTryModeChange = () => {
    if (!isLogin) {
      dispatch(openModal({ key: GlobalModalKey.Login }))
    } else {
      dispatch(setIsFreeTryMode(!isFreeMode))
    }
  }

  return (
    <FormControl display='flex' alignItems='center' width='max-content'>
      <FormLabel
        htmlFor='free-play'
        m='0'
        textStyle='h6'
        color='text.accent'
        opacity={isFreeMode ? 1 : '0.5'}
      >
        {t('FREE_GAME')}
      </FormLabel>
      <Switch
        id='free-play'
        mx='2'
        isChecked={!isFreeMode}
        onChange={onFreeTryModeChange}
      />
      <FormLabel
        htmlFor='free-play'
        m='0'
        textStyle='h6'
        color='text.accent'
        opacity={!isFreeMode ? 1 : '0.5'}
      >
        {t('REAL_MONEY_GAME')}
      </FormLabel>
    </FormControl>
  )
}

export default ButtonAction
