import { getConfigLng } from '@/utils/tools'

import type { dataProps } from '../announce-modal/AnnounceTab'
import type { AnnounceContentProps } from './ModalInterface'

interface UrlProps {
  hostname: string
  search: string
  pathname: string
  port: number
  origin: string
  href: string
  hash: string
  host: string
}

// 本站包含的域名
const websiteDomain = ['pre-release.xyz', 'coinmoney.xyz']

/**
 * 检查url是否是外部站点
 */
export const checkUrlIsOutSite = (url: string) => {
  const urlObj = checkIsValidUrl(url) as UrlProps
  if (urlObj?.host) {
    // 是有效地址
    let endUrl = ''
    let { host } = location
    const { hostname } = location
    //  是否本地开发
    const isLocal = ['localhost', '127.0.0.1'].includes(hostname)
    host = isLocal ? 'coinmoney.xyz' : host
    const hostdomain = host.split('.')
    const host2 = hostdomain.slice(-3)
    if (host2?.[0] === 'www') {
      host2.shift()
    }
    websiteDomain.push(host2.join('.'))
    if (websiteDomain.some(item => urlObj?.host?.includes(item))) {
      // 站内跳转
      endUrl = urlObj?.pathname + urlObj?.search
      return {
        isOut: false,
        url: endUrl,
      }
    } else {
      // 站外跳转
      return {
        isOut: true,
        url,
      }
    }
  } else {
    // 非有效地址
    return {
      isOut: true,
      url: '',
    }
  }
}

/**
 * 检是否是有效链接地址
 */
export const checkIsValidUrl = (url: string) => {
  let urlObj = {}
  const reg =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'*+,;=.]+$/

  if (!reg.test(url)) {
    return urlObj
  }
  const withHttp = url.startsWith('http://') || url.startsWith('https://')
  url = withHttp ? url : `https://${url}`
  try {
    urlObj = new URL(url)
  } catch (e) {
    urlObj = {}
  }
  return urlObj
}

export const handleAnnounceList = (list: AnnounceContentProps[]) => {
  const lng = getConfigLng()
  const _data = [] as dataProps[]
  list?.forEach(item => {
    const { language_content = {} } = item || {}
    const data = language_content[lng] || {}
    _data.push({
      id: item.id,
      title: data.title || '',
    })
  })

  return _data || []
}

export const handleSliceText = (text: string, sliceNum: number) => {
  sliceNum = sliceNum || 8
  if (typeof text === 'string') {
    return text.length <= sliceNum ? text : text.slice(0, sliceNum) + '...'
  } else {
    return ''
  }
}
