import {
  Box,
  Button,
  Grid,
  GridItem,
  Icon,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import LazyImage from '@/components/lazy-image/LazyImage'
import type { IIconLink } from '@/modules/app-layout/types'
import { getIcon } from '@/utils/get-icon'
import { isSupportedImageUrl } from '@/utils/tools'
import appConfig from '@/websites/current/pages/layout/app-config.json'
import { UserProfilePopoverContext } from './NewUserProfilePopover'
import { trackClickWallet } from '@/common/tracker'
import {
  getIsBindCurrency,
  switchBindModal,
} from '@/modules/bind-currency/currencySlice'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { getVariable } from '@/common/env'
import { setWithdrawCurrency } from '@/modules/wallet/walletSlice'
import { openWallet } from '@/modules/global-modal/globalModalSlice'
const SHOW_BIND_SELECT = getVariable('SHOW_BIND_SELECT')

const userNavigations: IIconLink[] =
  appConfig?.userProfilePopover?.navigations ?? []

function PopoverMenuNavigations() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { onClose } = useContext(UserProfilePopoverContext)
  const navigate = useNavigate()
  const isBindCurrency = useAppSelector(getIsBindCurrency)

  const navigationLeftIconRender = (icon: string) =>
    isSupportedImageUrl(icon) ? (
      <LazyImage src={icon} boxSize="24px" />
    ) : (
      <Icon as={getIcon(icon)} boxSize='24px' color='personalCenter.navIconColor' />
    )

  const walletClick = () => {
    // 点击wallet埋点
    trackClickWallet()
    if (isBindCurrency || !SHOW_BIND_SELECT) {
      dispatch(openWallet())
      return
    }
    dispatch(switchBindModal())
  }

  const onItemClick = useCallback(
    (item: IIconLink) => {
      onClose()
      if (item.action?.href) {
        navigate(item.action?.href)
      } else if (item.action?.data?.actionType === 'openWallet') {
        dispatch(setWithdrawCurrency(''))
        walletClick()
      }
    },
    [onClose, navigate],
  )

  return (
    <Grid templateColumns='repeat(1, 1fr)' mt='12px' mb='8px'>
      {userNavigations.map(item => (
        <GridItem
          key={item.key}
          overflow='hidden'
          position='relative'
          _notLast={{
            _after: {
              position: 'absolute',
              content: '""',
              bottom: '0px',
              left: '0px',
              right: '0px',
              height: '1px',
              background: 'personalCenter.border',
            },
          }}
        >
          <Box
            h='40px'
            width='full'
            borderRadius='lg'
            px='3'
            justifyContent='flex-start'
            onClick={onItemClick.bind(null, item)}
            display='flex'
            gap='24px'
            alignItems='center'
          >
            {navigationLeftIconRender(item.icon)}
            <Text
              cursor='pointer'
              display='flex'
              alignItems='center'
              fontWeight={500}
              fontSize='14px'
              lineHeight='18px'
              color="personalCenter.navTextColor"
            >
              {t(item.text)}
            </Text>
          </Box>
        </GridItem>
      ))}
    </Grid>
  )
}

export default PopoverMenuNavigations
