import {
  Box,
  Button,
  Center,
  Flex,
  Hide,
  Highlight,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { debounce } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import noSearchResultPNG from '@/assets/images/no-search-result.png'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useNavigateToGame } from '@/modules/game/useNavigateToGame'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import useShushu from '@/utils/hooks/useShushu'

import { useAppDispatch, useAppSelector } from '../app/store'
import { HEADER_HEIGHT } from '../app-layout/constants'
import { usePageChangeSubscription } from '../app-layout/usePageChangeNotification'
import type { GameListItem } from '../game/gameInterface'
import {
  selectAllCateByLableType,
  selectAllGame,
  selectEnableManuList,
  selectEnableManuObj,
} from '../game/gameSlice'
import GameCardRender from '../game/logic/game-list/GameCardRender'
import AtomGameCard from '../game/units/gamecard/AtomGameCard'
import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { CollectionGamesProvider } from '../my-games/useGameGrid'
import { includesKeyword } from './utils'

const SEARCH_HISTORY_KEY = 'searchHistory'

const MAX_RESULTS_LENGTH = 99
const MAX_HISTORY_NUMBER = 10
const MIN_KEYWORD_LENGTH = 2
const PAGE_SIZE = 12

function useGameSearch() {
  const gameList = useAppSelector(selectAllGame)
  const manuList = useAppSelector(selectEnableManuList)
  const search = useCallback(
    (keyword: string) => {
      const results: GameListItem[] = []
      const manuMap = new Map<number, GameListItem[]>()

      manuList.forEach(item => {
        if (
          item.is_under_maint !== 1 &&
          item.name_show &&
          includesKeyword(item.name_show, keyword)
        ) {
          manuMap.set(item.id, [])
        }
      })

      gameList.forEach(item => {
        if (results.length >= MAX_RESULTS_LENGTH) {
          return
        }
        if (includesKeyword(item.game_name, keyword)) {
          results.push(item)
        } else if (manuMap.has(item.game_manufacturer)) {
          manuMap.get(item.game_manufacturer)?.push(item)
        }
      })

      for (const games of manuMap.values()) {
        if (games.length !== 0) {
          const maxSubListLen = MAX_RESULTS_LENGTH - results.length
          results.push(...games.slice(0, Math.max(maxSubListLen, 0)))
          if (results.length >= MAX_RESULTS_LENGTH) {
            break
          }
        }
      }

      return results
    },
    [gameList, manuList],
  )
  return search
}

const Search = () => {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const [searchHistory, setSearchHistory] = useState<string[]>(() => {
    const storedHistory = localStorage.getItem(SEARCH_HISTORY_KEY)
    return storedHistory ? JSON.parse(storedHistory) : []
  })
  const [searchResults, setSearchResults] = useState<GameListItem[]>([])
  const [resultEndIndex, setResultEndIndex] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)
  const navigateToGame = useNavigateToGame()
  const dispatch = useAppDispatch()
  const search = useGameSearch()
  const navigate = useNavigate()
  const allCateByLabelType = useAppSelector(selectAllCateByLableType)
  const manuObj = useAppSelector(selectEnableManuObj)
  const firstNotEmptyCate = useMemo(() => {
    const cate = allCateByLabelType[1]
    if (cate && Array.isArray(cate) && cate.length > 0) {
      return cate.find(
        item => Array.isArray(item?.data) && item?.data?.length !== 0,
      )
    } else {
      return null
    }
  }, [allCateByLabelType])

  const recommendGames = useMemo(() => {
    return firstNotEmptyCate ? firstNotEmptyCate.data.slice(0, 6) : []
  }, [firstNotEmptyCate])

  const { open } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.Search],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((keyword: string) => {
      setSearchResults(search(keyword))
      setResultEndIndex(PAGE_SIZE)
      setSearchHistory(prev => {
        const targetIndex = prev.indexOf(keyword)
        if (targetIndex !== -1) {
          return [
            ...prev.slice(0, targetIndex),
            ...prev.slice(targetIndex + 1),
            keyword,
          ]
        }
        if (prev.length < MAX_HISTORY_NUMBER) {
          return [...prev, keyword]
        } else {
          return [...prev.slice(1), keyword]
        }
      })
      setIsLoading(false)
    }, 200),
    [search],
  )

  const handleClose = () => {
    dispatch(closeModal({ key: GlobalModalKey.Search }))
  }

  const handleLoadMore = () => {
    setResultEndIndex(prev => prev + PAGE_SIZE)
  }

  const handleRemoveSingleHistory = (index: number) => {
    setSearchHistory(prev => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1),
    ])
  }

  usePageChangeSubscription(handleClose)

  useEffect(() => {
    localStorage.setItem(SEARCH_HISTORY_KEY, JSON.stringify(searchHistory))
  }, [searchHistory])

  const searchTermTrimed = searchTerm.trim()

  useEffect(() => {
    if (searchTermTrimed.length >= MIN_KEYWORD_LENGTH) {
      setIsLoading(true)
      handleSearch(searchTermTrimed)
    }
  }, [handleSearch, searchTermTrimed])

  const displayResults = searchResults.slice(0, resultEndIndex)

  const handleRecommandViewMoreClick = () => {
    handleClose()
    const labelId = firstNotEmptyCate?.label_id
    const cateId = labelId === 1 ? -1 : labelId
    navigate(
      `/game/all?cate=${cateId}&label=${labelId}&timestamp=${Date.now()}`,
    )
  }

  const { setShushuGameReportData } = useShushu()

  const resultsFilledRender = () => {
    const hasMore = searchResults.length > resultEndIndex
    return (
      <Box>
        <Flex justifyContent='space-between' alignItems='center' mb='3'>
          <Text textStyle='text4' color='text.base'>
            {t('SEARCH_RESULT')}
          </Text>
          <Text textStyle='text4' color='text.base'>
            <Highlight
              query={String(searchResults.length)}
              styles={{ color: 'system.normal.link' }}
            >
              {String(t(`ABOUT_RESULT`, { n: searchResults.length }))}
            </Highlight>
          </Text>
        </Flex>
        <SimpleGrid
          as='ul'
          columns={[3, 4, 6]}
          gap={2.5}
          mb='5'
          listStyleType='none'
        >
          {displayResults.map((item, index) => {
            const manufacturer = manuObj[item.game_manufacturer]
            const handleClick = () => {
              setShushuGameReportData({
                from: 'search',
                game_id: item.game_id,
                game_name: item.game_name,
                manufacturer: item.game_manufacturer,
              })
            }
            return (
              <Box as='li' key={index}>
                <GameCardRender info={item} onClick={handleClick} />
                <Text
                  color='text.base'
                  mt='1.5'
                  height='4'
                  noOfLines={1}
                  textStyle='text5'
                >
                  {manufacturer?.name_show}
                </Text>
              </Box>
            )
          })}
        </SimpleGrid>
        <Box mb='7' hidden={!hasMore}>
          <Button
            display='block'
            mx='auto'
            size='mdsm'
            onClick={handleLoadMore}
          >
            <Text as='span' textStyle='text3'>
              {t('HOME_LOAD_MORE')}
            </Text>
          </Button>
        </Box>
      </Box>
    )
  }

  const noSearchRender = () => {
    return (
      <Box>
        <Flex justifyContent='space-between' alignItems='center' mb='3'>
          <Text textStyle='text4' color='text.base'>
            {t('SEARCH_HISTORY')}
          </Text>
          <IconButton
            variant='unstyledIcon'
            color='text.base'
            icon={<Icon as={SharedIcon.Delete} boxSize={4} />}
            aria-label='delete search history'
            onClick={() => setSearchHistory([])}
          />
        </Flex>
        <Box as='ul'>
          {searchHistory.map((term, index) => (
            <Box
              as='li'
              key={index}
              display='inline-flex'
              borderRadius='base'
              bgColor='bg.control'
              alignItems='center'
              justifyContent='center'
              py='5px'
              px='2'
              mb='2'
              mr='2'
              maxW='33%'
              color='text.base'
            >
              <Text
                as='span'
                textStyle='text5'
                mr='1.5'
                flex='1'
                noOfLines={1}
                onClick={() => setSearchTerm(term)}
              >
                {term}
              </Text>
              <IconButton
                variant='unstyledIcon'
                icon={<Icon as={SharedIcon.Close} boxSize={4} />}
                aria-label='delete search keyword'
                onClick={() => handleRemoveSingleHistory(index)}
              />
            </Box>
          ))}
        </Box>
      </Box>
    )
  }

  const resultsEmptyRender = () => {
    return (
      <Box>
        <Box textAlign='center' my='16'>
          <LazyImage
            src={noSearchResultPNG}
            alt=''
            width='88px'
            height='88px'
            mb='4'
            mx='auto'
          />
          <Text textStyle='text4' color='text.base'>
            {t('NO_SEARCH_RESULT')}
          </Text>
        </Box>
        <Text textStyle='h4' color='text.accent' mb='5' textAlign='center'>
          {t('NEW_HOME_NAV_01')}
        </Text>
        <SimpleGrid
          as='ul'
          columns={[3, 4, 6]}
          gap={2.5}
          mb='5'
          listStyleType='none'
        >
          {recommendGames.map((item, index) => (
            <Box as='li' key={index}>
              <AtomGameCard
                src={item.game_cover}
                onClick={() => navigateToGame(item)}
              />
            </Box>
          ))}
        </SimpleGrid>
        <Box mb='14' textAlign='center'>
          <Button mx='auto' size='mdsm' onClick={handleRecommandViewMoreClick}>
            <Text as='span' textStyle='text3'>
              {t('NEW_HOME_PLAY_NAV_06')}
            </Text>
          </Button>
        </Box>
      </Box>
    )
  }

  const resultsLoadingRender = () => {
    return (
      <Box textAlign='center' my='16'>
        <Center width='88px' height='88px' mx='auto' mb='4'>
          <Spinner boxSize={8} />
        </Center>
      </Box>
    )
  }

  const contentRender = () => {
    if (searchTermTrimed.length < MIN_KEYWORD_LENGTH) {
      return noSearchRender()
    }
    if (isLoading) {
      return resultsLoadingRender()
    }
    if (displayResults.length > 0) {
      return resultsFilledRender()
    } else {
      return resultsEmptyRender()
    }
  }

  return (
    <CollectionGamesProvider>
      <Modal
        autoFocus={false}
        trapFocus={false}
        isOpen={open}
        onClose={handleClose}
        colorScheme='multilayer'
        scrollBehavior='inside'
      >
        <Hide below='md'>
          <ModalOverlay />
        </Hide>
        <ModalContent
          maxW='container.lg'
          w='full'
          maxH={{ base: 'full', md: '780px' }}
          h='full'
          m='0'
          borderRadius={{ base: '0', md: 'lg' }}
          mt={{ base: '0', md: `calc(${HEADER_HEIGHT} + 10px)` }}
          motionProps={{
            initial: { opacity: 0, x: '-100%' },
            animate: { opacity: 1, x: '0%' },
            transition: { x: { type: 'tween' } },
          }}
        >
          <ModalHeader px='2.5' pt='2.5' pb='0.5'>
            <InputGroup>
              <InputLeftElement>
                <Icon as={SharedIcon.Search} color='text.base' boxSize={4} />
              </InputLeftElement>
              <Input
                type='text'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                placeholder={String(t('SEARCH_INPUT_DSC'))}
                maxLength={50}
                pr='120px'
              />
              <InputRightElement w='80px'>
                <IconButton
                  icon={
                    <Icon
                      as={SharedIcon.Cancel}
                      boxSize={18}
                      color='text.base'
                    />
                  }
                  bg='none'
                  _hover={{
                    bg: 'none',
                  }}
                  _active={{
                    bg: 'none',
                  }}
                  _focusVisible={{
                    bg: 'none',
                  }}
                  onClick={() => setSearchTerm('')}
                  hidden={searchTerm.length <= 2}
                  position='absolute'
                  left='0'
                  transform='translateX(-100%)'
                  aria-label='clear'
                  _before={{
                    content: '""',
                    position: 'absolute',
                    top: '0',
                    bottom: '0',
                    right: '0',
                    display: 'inline-block',
                    w: '1px',
                    h: '4',
                    bgColor: 'gray.400',
                    my: 'auto',
                  }}
                />
                <Button onClick={handleClose} variant='unstyled' w='80px'>
                  <Text textStyle='h6' color='prim.500'>
                    {t('USER_HEAD_PROTRAIT_CANCEL')}
                  </Text>
                </Button>
              </InputRightElement>
            </InputGroup>
            <Text
              textStyle='text4'
              color='text.base'
              mt='3'
              textAlign='center'
              hidden={
                searchTermTrimed.length === 0 ||
                searchTermTrimed.length >= MIN_KEYWORD_LENGTH
              }
            >
              {t('SEARCH_REQUIRE_DSC')}
            </Text>
          </ModalHeader>
          <ModalBody p='2.5' sx={{ '--scrollbar-width': '0px' }}>
            <Box flex='1' overflowY='auto'>
              {contentRender()}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </CollectionGamesProvider>
  )
}

export default Search
