import {
  Container,
  Icon,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  closeModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { Icon as SharedIcon } from '@/utils/atom-shared'

import MainScene from './components/MainScene'
import RewardScene from './components/RewardScene'
import drawActivityEvent from './events'
import { changeScence } from './store/reducer-slice'
import { selectScence } from './store/selectors'

function DrawActivityModal() {
  const dispatch = useAppDispatch()
  const scene = useAppSelector(selectScence)

  const { open, data } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.DrawActivityModal],
  )
  const { open: isWalletModalOpen } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.Wallet],
  )

  const handleClose = () => {
    if (scene === 'reward') {
      dispatch(changeScence('normal'))
      drawActivityEvent.emit('backToMainScene')
    } else {
      dispatch(closeModal({ key: GlobalModalKey.DrawActivityModal }))
    }
  }

  return (
    <Modal
      isOpen={open}
      onClose={handleClose}
      size={{ base: 'full', lg: 'md' }}
      // isCentered
      closeOnOverlayClick={false}
      autoFocus={false}
      trapFocus={false}
      blockScrollOnMount={!isWalletModalOpen}
    >
      <ModalOverlay layerStyle='ls-modal-mask-blur-50' />
      <ModalContent
        bg='transparent'
        boxShadow='none'
        h='100vh'
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <IconButton
          as={ModalCloseButton}
          icon={<Icon as={SharedIcon.Close} boxSize='5' color='white' />}
          aria-label='close'
          variant='unstyled'
          cursor='pointer'
          position='absolute'
          lineHeight='1'
          minWidth='max-content'
          zIndex='sticky'
          width='7'
          height='7'
          fontSize='0'
          right='2'
          top='3'
        />
        <Container
          maxWidth='container.md'
          p='0'
          pb='12'
          h='100%'
          overflowY='scroll'
          scrollBehavior='smooth'
          sx={{
            // 隐藏滚动条的样式
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <MainScene
            isShow={scene === 'normal'}
            initTaskId={(data as { taskId?: number })?.taskId}
          />
          <RewardScene isShow={scene === 'reward'} />
        </Container>
      </ModalContent>
    </Modal>
  )
}

export default DrawActivityModal
