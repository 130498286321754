import gameIds from '@/modules/inhouse/gameIds'

import type { Games } from './helper'

// 自研游戏
export const INHOUSE_GAME: Readonly<Games> = gameIds.map(gameid => ({
  name: gameid,
  path: '/' + gameid,
  id: gameid,
}))

/** only inhouse gameid 仅有自研游戏id */
export const onlineGameList = [
  'double',
  'roulette',
  'mines',
  'ring',
  'dice',
  'crash',
  'limbo',
  'fierybot',
  'plinko',
  'keno',
  'stairs',
  'coinflip',
  'cryptos',
] as const

export type InHouseGameId = (typeof onlineGameList)[number]

export default INHOUSE_GAME
