import { api } from '@/services/api'
import type { Response } from '@/services/types'

import { getUserId, getUserToken } from '../user/storage'
import type { FirstLoginTaskResProps } from './type'

export const guideEventApi = api.injectEndpoints({
  endpoints: builder => ({
    claimFirstLoginReward: builder.mutation<any, { biz_id: string }>({
      query: data => ({
        url: '/api/v1/first-login-task/claim',
        method: 'POST',
        data: {
          ...data,
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
    }),
    getMyFirstLoginReward: builder.query<
      Response<FirstLoginTaskResProps>,
      void
    >({
      query: () => ({
        url: '/api/v1/first-login-task/my_reward',
        method: 'POST',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
      providesTags: ['Login'],
    }),
  }),
})

export const {
  useClaimFirstLoginRewardMutation,
  useGetMyFirstLoginRewardQuery,
  useLazyGetMyFirstLoginRewardQuery,
} = guideEventApi
