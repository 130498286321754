import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { keyBy, toNumber } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import digitalCellBg from '@/assets/images/recharge-comfirm-modal/digital-cell-bg.png'
import stripLlight1 from '@/assets/images/recharge-comfirm-modal/strip-light-1.png'
import stripLlight2 from '@/assets/images/recharge-comfirm-modal/strip-light-2.png'
import stripLlight3 from '@/assets/images/recharge-comfirm-modal/strip-light-3.png'
import { isFeatureEnabled } from '@/common/feature'
import i18n from '@/common/locale/i18n'
import AtomCountDown from '@/components/atom-count-down'
import type { RechargeActListProps } from '@/modules/activity/ActInterface'
import { selectRechargeActList } from '@/modules/activity/ActSlice'
import { deviceMap } from '@/modules/activity/type'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { usePageChangeSubscription } from '@/modules/app-layout/usePageChangeNotification'
import { closeModal, openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import type {
  BankruptRechargeCfg,
  RechargeActivityProps,
} from '@/modules/wallet/interface'
import { RECHARGE_ACTIVITY_SCOPE_TYPE } from '@/modules/wallet/interface'
import { getNewRewardAmount } from '@/modules/wallet/useRechargeActivity'
import {
  useBankruptRechargeConfigsQuery,
  useRechargeTaskListQuery,
} from '@/modules/wallet/walletApi'
import { createModal, useAtomModal } from '@/utils/atom-modal/AtomModal'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useUserAuth } from '@/utils/hooks'
import { getConfigLng, getDeviceType } from '@/utils/tools'

import { ListItem } from './ListItem'
import {
  default as RechargeBonusDetailModal,
  NAME as RechargeBonusDetailModalName,
} from './RechargeBonusDetailModal'

// eslint-disable-next-line react-refresh/only-export-components
export const NAME = 'RechargeComfirmModal'

const rechargeActivityV2 = isFeatureEnabled('rechargeActivityV2')
const lng = getConfigLng()

export interface ItemData {
  id: number
  title?: string
  image: string
  taskId: string
  maxRechargeAmount?: number
  minRechargeAmount?: number
  joinNumber?: number
  currentNumber?: number
  rechargeAmounts?: number[]
}

function getScopeByCurrentDevice(device: string) {
  if (['apk', 'ios-desktop-icon'].includes(device)) {
    return RECHARGE_ACTIVITY_SCOPE_TYPE.APP
  } else {
    return RECHARGE_ACTIVITY_SCOPE_TYPE.PC
  }
}

function isScopeMatch(activity: RechargeActivityProps) {
  return (
    !activity.scope ||
    activity.scope === getScopeByCurrentDevice(getDeviceType())
  )
}

export function filterActivityByScope(
  rechargeList: RechargeActivityProps[] | [],
): RechargeActivityProps[] | [] {
  return rechargeList.filter(isScopeMatch)
}

const getNextTargetDate = () => dayjs().add(3, 'm')

interface RechargeActivityReward {
  // 奖励系数
  coefficient_amount: string

  // 系数奖励金额上限
  coefficient_amount_limit: string

  // 固定奖励金额
  fixed_amount: string

  // 是否为系数奖励
  is_coefficient_amount: boolean

  // 是否为固定奖励
  is_fixed_amount: boolean
}

const getMaxRewardAmount = (reward: RechargeActivityReward) => {
  let result = 0
  if (reward.is_fixed_amount) {
    result += toNumber(reward.fixed_amount)
  }
  if (reward.is_coefficient_amount) {
    result += toNumber(reward.coefficient_amount_limit)
  }
  return result
}

const RechargeComfirmModal = createModal(
  NAME,
  ({ closeCb }: { closeCb?: () => void }) => {
    const [userId, token] = useUserAuth()
    const [show, setShow] = useState(false)
    const { t } = useTranslation()
    const { hide, visible } = useAtomModal(NAME)
    const { show: showDetailModal } = useAtomModal(RechargeBonusDetailModalName)
    const [targetDate, setTargetDate] = useState(getNextTargetDate)
    const rechargeActList = useAppSelector(selectRechargeActList)
    const dispatch = useAppDispatch()

    const { data, isFetching } = useBankruptRechargeConfigsQuery(
      {
        token,
        user_id: userId,
        device: deviceMap[getDeviceType()] || '',
      },
      {
        skip: !userId || !token,
      },
    )

    const rechargeTaskListQuery = useRechargeTaskListQuery({
      skip: !token || !userId,
    })

    const rechargeTasksMap = useMemo(() => {
      const rechargeTaskList: any =
        filterActivityByScope(rechargeTaskListQuery.data?.data || []) || []
      return keyBy<{
        name: string
        join_number: number
        current_number?: number
        pre_condition: { min_charge: string }
        reward: RechargeActivityReward
      }>(rechargeTaskList, 'id')
    }, [rechargeTaskListQuery.data])

    let newListData = [] as BankruptRechargeCfg[]
    const listData = (data?.data || [])
      .filter(item => item.recharge_task_id in rechargeTasksMap)
      .map(item => {
        const detail = rechargeTasksMap[item.recharge_task_id]
        const result: ItemData = {
          id: item.id,
          image: item.image_url,
          taskId: item.recharge_task_id.toString(),
          rechargeAmounts:
            item.button_state === 1
              ? item.recharge_buttons
              : [item.fixed_amount ?? 0],
        }

        if (detail) {
          result.title = detail.name
          result.maxRechargeAmount = getMaxRewardAmount(detail.reward)
          result.minRechargeAmount = detail.pre_condition.min_charge
            ? Number(detail.pre_condition.min_charge)
            : 0
          result.currentNumber = detail.current_number ?? 0
          result.joinNumber = detail.join_number ?? 0
        }
        return result
      })

    if (rechargeActivityV2) {
      // 新的充值活动
      newListData = data?.data || []
      newListData = newListData.map(item => {
        const minRecharge = Math.min(...(item?.recharge_buttons || []))
        const maxRecharge = Math.max(...(item?.recharge_buttons || []))
        const taskId = item.recharge_task_id.toString()
        const actInfo = (rechargeActList.find(subItem => {
          return Number(subItem?.id) === Number(taskId)
        }) || {}) as RechargeActListProps
        const { language_content } = actInfo?.meta || {}
        const meta = language_content?.[lng]
        return {
          ...item,
          image: item.image_url || '',
          title: meta?.active_name || '',
          minRechargeAmount: minRecharge,
          maxRechargeAmount: getNewRewardAmount(maxRecharge, actInfo),
          currentNumber: actInfo?.current_join || 0,
          joinNumber:
            actInfo?.join_type === 0 ? '999+' : actInfo?.join_number || 0,
        }
      })
    }

    const modalContentRender = () => {
      const stripLlightAssets = [stripLlight1, stripLlight2, stripLlight3]
      const stripLlightIndex =
        Math.min(listData.length, stripLlightAssets.length) - 1
      const stripLlight = stripLlightAssets[stripLlightIndex]
      return (
        <>
          <Box
            bgImage={`url(${stripLlight})`}
            bgSize='100%'
            px='5.89%'
            pb='2.5'
            bgRepeat='no-repeat'
          >
            {listData.map((item, index) => (
              <ListItem
                key={item.id}
                index={index}
                title={item.title}
                image={item.image}
                taskId={item.taskId}
                rechargeAmounts={item.rechargeAmounts}
              />
            ))}
          </Box>
        </>
      )
    }

    const newModalContentRender = () => {
      const stripLlightAssets = [stripLlight1, stripLlight2, stripLlight3]
      const stripLlightIndex =
        Math.min(newListData.length, stripLlightAssets.length) - 1
      const stripLlight = stripLlightAssets[stripLlightIndex]

      return (
        <>
          <Box
            bgImage={`url(${stripLlight})`}
            bgSize='100%'
            px='5.89%'
            pb='2.5'
            bgRepeat='no-repeat'
          >
            {newListData.map((item, index) => {
              const taskId = item.recharge_task_id.toString()
              const rechargeAmounts =
                item.button_state === 1
                  ? item.recharge_buttons
                  : [item.fixed_amount ?? 0]

              const image = item.image_url || ''
              const actInfo = (rechargeActList.find(subItem => {
                return Number(subItem?.id) === Number(taskId)
              }) || {}) as RechargeActListProps

              const { language_content } = actInfo?.meta || {}
              const meta = language_content?.[lng]

              return (
                <ListItem
                  key={item.id}
                  index={index}
                  title={meta?.active_name || ''}
                  image={image}
                  taskId={taskId}
                  rechargeAmounts={rechargeAmounts}
                />
              )
            })}
          </Box>
        </>
      )
    }

    const onClose = () => {
      hide()
      closeCb?.()
    }

    usePageChangeSubscription(({ pathname }) => {
      if (!pathname.startsWith('/game')) {
        onClose()
      }
    })

    useEffect(() => {
      if (isFetching || !visible) return
      const isShow =
        (rechargeActivityV2 && !!newListData?.length) ||
        (!rechargeActivityV2 && !!listData?.length)
      if (!isShow) {
        dispatch(openModal({ key: GlobalModalKey.Wallet }))
        onClose()
      } else {
        dispatch(closeModal({ key: GlobalModalKey.Wallet }))
        setShow(isShow)
      }
    }, [listData, newListData, isFetching])

    return (
      <>
        <RechargeBonusDetailModal
          list={rechargeActivityV2 ? newListData : listData}
        />

        <Modal
          autoFocus={false}
          trapFocus={false}
          size='sm'
          isOpen={visible && show}
          isCentered
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent bg='none' boxShadow='none'>
            <ModalHeader
              px='0'
              aspectRatio={2.669}
              bgImage='url("/assets/images/recharge-comfirm-modal/modal-head-bg.png")'
              bgSize='100%'
              display='flex'
              justifyContent='center'
              alignItems='flex-end'
            >
              <Flex
                alignItems='center'
                justifyContent='center'
                textStyle='text4'
              >
                <Text color='white' mr='2.5'>
                  {`${t('AVAILABLE_IN')} :`}
                </Text>
                <AtomCountDown
                  m='0'
                  w='auto'
                  p='0'
                  color='white'
                  targetDate={targetDate}
                  cellRender={n => (
                    <Text
                      textStyle='h4'
                      w='16px'
                      h='24px'
                      background={`url(${digitalCellBg})`}
                      bgSize='100%'
                      bgRepeat='no-repeat'
                      mr='1'
                      position='relative'
                      _before={{
                        content: "''",
                        display: 'block',
                        position: 'absolute',
                        left: '0',
                        right: '0',
                        top: '50%',
                        w: 'calc(100% - 4px)',
                        h: '1px',
                        mx: 'auto',
                        bgColor: '#7D4BC0',
                        zIndex: '1',
                        transform: 'scaleY(0.5)',
                      }}
                    >
                      {n}
                    </Text>
                  )}
                  onEnd={() => setTargetDate(getNextTargetDate())}
                />
              </Flex>
              <IconButton
                position='absolute'
                top='1.5'
                right='1'
                as={ModalCloseButton}
                icon={<Icon as={SharedIcon.Close} boxSize={6} color='white' />}
                aria-label='close'
                variant='unstyled'
                size='sm'
              />
            </ModalHeader>
            <ModalBody p='0'>
              {rechargeActivityV2
                ? newModalContentRender()
                : modalContentRender()}
            </ModalBody>
            <ModalFooter pt='1.5'>
              <Button
                variant='link'
                mx='auto'
                size='mdsm'
                color='white'
                fontWeight='600'
                rightIcon={<Icon as={SharedIcon.ArrowRight1} />}
                onClick={() => showDetailModal()}
              >
                {t('RECHARGE_REWARD_ACTIVITY_DETAILS')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  },
)

export default RechargeComfirmModal
