import dayjs from 'dayjs'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { isFeatureEnabled } from '@/common/feature'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { selectUserIsLogin } from '@/modules/user/userSlice'

import luckyWheelEvents from '../events'
import {
  getLuckyWheelActivityIsOpen,
  getLuckyWheelActivityTimeRange,
} from '../luckyWheelSlice'

function useWheelModalShow() {
  const dispatch = useAppDispatch()
  // 是否显示未登录弹窗
  const [isShowNoLoginModal, setIsShowNoLoginModal] = useState(false)
  // 是否显示已登录弹窗
  const [isShowLoginedModal, setIsShowLoginedModal] = useState(false)

  const isLogin = useAppSelector(selectUserIsLogin)

  const isOpen = useAppSelector(getLuckyWheelActivityIsOpen)

  const getNextDayStartTimeStamp = () =>
    dayjs().startOf('day').add(1, 'day').valueOf()

  const getLuckyWheelStorage = () =>
    JSON.parse(localStorage.getItem('luckyWheelStorage') ?? '{}') ?? {}

  const updateModalState = useCallback(
    (
      nextShowModalTimeStamp: number,
      setIsShowModal: Dispatch<SetStateAction<boolean>>,
    ) => {
      const nowTimeStamp = new Date().getTime()
      if (!nextShowModalTimeStamp || nextShowModalTimeStamp < nowTimeStamp) {
        setIsShowModal(true)
        return getNextDayStartTimeStamp()
      }
      setIsShowModal(false)
      return nextShowModalTimeStamp
    },
    [],
  )

  const location = useLocation()

  const { startTime, endTime } = useAppSelector(getLuckyWheelActivityTimeRange)

  // 打开奖励弹窗
  const handleOpenBonusModal = (data: {
    reward: string
    remainReward: string
  }) => {
    luckyWheelEvents.emit('openBonusModal', data)
  }

  // 打开充值邀请弹窗
  const handleOpenRechargeAndInviteModal = () => {
    luckyWheelEvents.emit('openRechargeAndInviteModal')
  }

  // 打开离开提示弹窗
  const handleOpenPromptModal = () => {
    luckyWheelEvents.emit('openPromptModal')
  }

  // 打开领取成功弹窗
  const handleOpenSuccessModal = (reward: number) => {
    luckyWheelEvents.emit('openSuccessModal', reward)
  }

  const handleOpenWheelModal = useCallback(() => {
    if (!isFeatureEnabled('luckyWheel')) return
    if (!isOpen) return
    // 如果时间为0， 则表示不限时间
    // if (!startTime || !endTime) return
    if (
      startTime > 0 &&
      endTime > 0 &&
      (dayjs().isBefore(startTime * 1000) || dayjs().isAfter(endTime * 1000))
    )
      return
    const luckyWheelStorage = getLuckyWheelStorage()
    const isHome = location.pathname === '/'
    if (!isHome) {
      return
    }
    if (luckyWheelStorage.nextShowLoginedModalTimeStamp) return
    if (isLogin) {
      luckyWheelStorage.nextShowLoginedModalTimeStamp = updateModalState(
        luckyWheelStorage.nextShowLoginedModalTimeStamp,
        setIsShowLoginedModal,
      )
    } else {
      setIsShowNoLoginModal(true)
    }
    dispatch(
      openModal({
        key: GlobalModalKey.LuckyWheelActivity,
      }),
    )
    localStorage.setItem('luckyWheelStorage', JSON.stringify(luckyWheelStorage))
  }, [
    dispatch,
    endTime,
    isLogin,
    isOpen,
    location.pathname,
    startTime,
    updateModalState,
  ])

  return {
    isShowNoLoginModal,
    isShowLoginedModal,
    handleOpenBonusModal,
    handleOpenRechargeAndInviteModal,
    handleOpenPromptModal,
    handleOpenWheelModal,
    handleOpenSuccessModal,
  }
}

export default useWheelModalShow
