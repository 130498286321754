import { isProduction } from '@/common/env'

export default function atomGtag(...args: any) {
  if (isProduction()) {
    if (typeof gtag === 'function') {
      gtag(...args)
    }
  } else {
    console.log('gtag')
    console.log('gtag', ...args)
  }
}

/**
 * GA4 campaign purchase ratio
 *
 **/
export const CHANNEL_PURCHASE_RATIO_KEY = 'channel_purchase_ratio'
export const setChannelPurchaseRatio = (amount: string | number) => {
  const cpr = sessionStorage.getItem(CHANNEL_PURCHASE_RATIO_KEY)
  return !!Number(cpr)
    ? String(Math.ceil(Number(amount) * Number(cpr)))
    : amount
}
