import { Box, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { isEmpty } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import rechargeActivtiyModalHead from '@/assets/images/recharge-comfirm-modal/recharge-activtiy-modal-head.png'
import { getCurrencyCode } from '@/common/locale/currency'
import TitledDialog from '@/components/modal/TitledDialog'
import { useUserAuth } from '@/utils/hooks'

import { useAppDispatch, useAppSelector } from '../app/store'
import { usePageChangeSubscription } from '../app-layout/usePageChangeNotification'
import type { GlobalModalKey } from '../global-modal/types'
import { useRechargeArgsQuery } from '../wallet/walletApi'
import WalletService from '../wallet/walletService'
import { getRechargePaymentOptions } from '../wallet/walletSlice'
import RechargeActBtn from './rechargeActBtn'
import {
  getOpenModal,
  selectTaskId,
  setRechargeAmount,
  setRechargeTyp,
  STORAGE_KEY_RECHARGE_TYPE,
  switchRecahrgeActModal,
} from './rechargeActSlice'
import RechargeInput from './rechargeInput'
import SelectWayItem from './selectWayItem'

export default function RechargeActivity({
  name,
}: {
  name: GlobalModalKey.RechargeActivity
}) {
  const CURRENCY = getCurrencyCode()
  const [user_id, token] = useUserAuth()
  const { t } = useTranslation()
  const options = useAppSelector(getRechargePaymentOptions)
  const [modalHeight, setModalHeight] = useState<number>(window.innerHeight)

  useEffect(() => {
    const resetHeight = () => {
      setModalHeight(window.innerHeight)
    }
    window.addEventListener('resize', resetHeight)
    return () => {
      window.removeEventListener('resize', resetHeight)
    }
  }, [])

  const isopen = useAppSelector(getOpenModal)

  useRechargeArgsQuery(
    {
      user_id,
      token,
      currency: CURRENCY,
    },
    { skip: !isopen },
  )

  const dispatch = useAppDispatch()

  const taskId = useAppSelector(selectTaskId)

  const onClose = useCallback(() => {
    dispatch(switchRecahrgeActModal())
    dispatch(setRechargeAmount(''))
  }, [dispatch])

  const onPageChange = useCallback(() => isopen && onClose(), [isopen, onClose])

  usePageChangeSubscription(onPageChange)

  useEffect(() => {
    if (!isEmpty(options)) {
      const rechargeTypeFromStorage = localStorage.getItem(
        STORAGE_KEY_RECHARGE_TYPE,
      )
      if (
        !rechargeTypeFromStorage ||
        !options.findIndex(item => item.value === rechargeTypeFromStorage)
      ) {
        dispatch(setRechargeTyp(options[0]?.value))
      }
    }
  }, [options, dispatch])

  return (
    <TitledDialog
      isOpen={isopen}
      onClose={onClose}
      colorScheme='multilayer'
      variant={{ base: 'fill-square', sm: 'round' }}
      size={{ base: 'full', sm: 'sm' }}
      title={''}
      closeColor='#fff'
      padding={true}
      contentBg='linear-gradient(178.92deg, rgba(79, 2, 128, 0.91) 6.93%, #621C91 15.76%)'
    >
      <Box
        height={{ base: `${modalHeight}px`, sm: '100%' }}
        bgImage={`url(${rechargeActivtiyModalHead})`}
        bgSize='100% auto'
        bgPosition='top'
        bgRepeat='no-repeat'
        display='flex'
        flexDirection='column'
        justifyContent='flex-end'
        pt='27%'
      >
        <Box
          px='2.5'
          pb='3'
          bg='linear-gradient(178.92deg, rgba(79, 2, 128, 0) 0%, #621C91 15.76%)'
        >
          <Text
            textAlign='center'
            fontSize='20px'
            fontStyle='italic'
            fontWeight='900'
            lineHeight='32px'
            background='linear-gradient(90deg, #FFFAAA 0%, #FFF 56.41%, #FFFAAC 100%)'
            backgroundClip='text'
            mb='2'
          >
            {t('BENEFITS_INTRODUCTION')}
          </Text>
          <VStack
            borderRadius='2xl'
            bgColor='whiteAlpha.300'
            alignItems='stretch'
            px='2.5'
            py='4'
            mb='2.5'
            spacing='2'
          >
            <Text color='white' mx='1.5' textStyle='text3'>
              {t('DEPOSIT_RECHARGEAMOUNT')}
            </Text>
            <RechargeInput />
          </VStack>
          <Box
            borderRadius='2xl'
            bgColor='whiteAlpha.300'
            alignItems='stretch'
            p='2.5'
            pt='4'
            mb='4'
          >
            <Text color='white' mx='1.5' textStyle='text3' mb='2.5'>
              {t('RECHARGE_TYPE')}
            </Text>
            <Box
              h='176px'
              overflowY='scroll'
              sx={{ '::-webkit-scrollbar': { display: 'none' } }}
            >
              <SimpleGrid columns={2} gap='2'>
                {options.map(item => {
                  return <SelectWayItem key={item.value} {...item} />
                })}
              </SimpleGrid>
            </Box>
          </Box>
          <RechargeActBtn
            type='modal'
            isRechargeOptionEmpty={options && options.length === 0}
            taskId={taskId}
          />
          <WalletService color='#fff' support_icon='white' cb={onClose} />
        </Box>
      </Box>
    </TitledDialog>
  )
}
