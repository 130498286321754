import axios from 'axios'

import { getModalCfgUrl } from '@/common/env'
import { getCurrencyCode } from '@/common/locale/currency'
import i18n, { DEFAULT_NS } from '@/common/locale/i18n'
import { fatal } from '@/common/logger'
import property from '@/websites/current/property.json'

/**
 * 从远程重载i18n资源
 */
export function reloadI18nResourceFromS3(nsArr: string[]) {
  const lng = i18n.language
  nsArr?.forEach(ns => {
    axios.get(getModalCfgUrl() + `/pageconfig/${ns}_${lng}.json`).then(res => {
      if (res.status !== 200) {
        fatal(`load i18n config from cdn failed: ${res.status}`)
        return
      }
      const i18nData = res?.data || {}
      i18n.addResourceBundle(i18n.language, ns, i18nData, true, true)
    })
  })
}

/** call this when all res are prepared */
export function initDynamicI18n() {
  const dynamicI18n = {
    APP_NAME: property.appName,
    currency: getCurrencyCode(),
    domain: property.appName,
    website: 'https://' + property.domain,
    proName: property.appName,
    TelegramLink: property?.social?.telegramLink || '',
    ...(property.i18n_dyn || {}),
  }
  i18n.addResources(i18n.language, 'dyn', dynamicI18n)
}

export function changeLanguage(lang: string) {
  i18n.changeLanguage(lang)
  initDynamicI18n()
  reloadI18nResourceFromS3(DEFAULT_NS)
}
