import React from 'react'
import ProgressBar from './ProgressBar'
import { formatLocalMoney } from '@/utils/tools'
import { Flex, Text } from '@chakra-ui/react'

const RichProgressBar = ({
  progress,
  title,
  target,
  current,
}: {
  progress: number
  title: string
  target: number
  current: number
}) => {
  return (
    <div>
      <Flex justifyContent="space-between" lineHeight="18px" fontSize="14px" fontWeight="500" color="personalCenter.progressLabel" mb="8px">
        <Text>{title}</Text>
        <Text>
          {current}/ <Text display="inline-block" color="personalCenter.inputText">{formatLocalMoney(target)}</Text>
        </Text>
      </Flex>

      <ProgressBar progress={progress} />
    </div>
  )
}

export default RichProgressBar
