/** Gcash支付渠道内嵌支付页 */

import { Box, Button, ModalBody, Text, useDisclosure } from '@chakra-ui/react'
import { createSelector } from '@reduxjs/toolkit'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PngRechargeOrderStatus from '@/assets/images/wallet/recharge-order-status.png'
import { getCostByMark } from '@/common/pmsdk/get-cost-by-mark'
import PerformanceMarkTag from '@/common/pmsdk/performance-mark-tag'
import { pmLog } from '@/common/pmsdk/pm-log'
import LazyImage from '@/components/lazy-image/LazyImage'
import Dialog from '@/components/modal/Dialog'
import TitledDialog from '@/components/modal/TitledDialog'
import Loading from '@/routes/Loading'
import useUpdateUserInfo from '@/utils/hooks/useUpdateUserInfo'

import { useAppDispatch, useAppSelector } from '../app/store'
import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import walletEvents from './events'
import { CONFIRM_RENDER_MAP } from './MPesaModal'
import { useRechargeStatusMutation } from './walletApi'

type OrderStatus = 'pending' | 'success' | 'failed'

export default function GcashModal() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const timerRef = useRef<number>()

  /**
   * 完成支付后的订单状态
   */
  const [orderStatus] = useState<OrderStatus>('success')

  const { open, data } = useAppSelector(
    createSelector(selectModal, globalModal => globalModal.GcashPaymentModal),
  )

  const updateUserInfo = useUpdateUserInfo()

  /**
   * 确认支付状态弹窗
   */
  const {
    isOpen: isConfirmRechargeFinishModalOpen,
    onOpen: handleOpenConfirmRechargeFinishModal,
  } = useDisclosure()

  const handleCloseModal = () => {
    dispatch(closeModal({ key: GlobalModalKey.GcashPaymentModal }))
  }

  const handleDistributeFc = () => {
    if (orderStatus === 'success') {
      /** 关闭三方iframe弹窗 */
      handleCloseModal()
    }
  }

  /**
   * 请求订单状态接口
   */
  const [getRechargeOrderStatus] = useRechargeStatusMutation()

  /**
   * 请求接口拿订单状态
   */
  const handleGetRechargeOrderStatus = async () => {
    if (data?.orderId) {
      clearInterval(timerRef.current)
      timerRef.current = setInterval(async () => {
        try {
          const { data: rechargeOrderStatus } = await getRechargeOrderStatus({
            order_id: data?.orderId,
          }).unwrap()
          if ((rechargeOrderStatus as any).state === 'accepted_and_done') {
            clearInterval(timerRef.current)
            handleOpenConfirmRechargeFinishModal()
            updateUserInfo()
          }
        } catch (error) {}
      }, 3000) as unknown as number
    } else {
      clearInterval(timerRef.current)
    }
  }

  const handleIframeOnload = () => {
    setIsLoading(false)

    const cost = getCostByMark(
      PerformanceMarkTag.RechargeStart,
      PerformanceMarkTag.RechargeEnd,
    )

    if (cost > 0) {
      pmLog({
        eventName: 'clientmetrics_page_load_time_spent',
        payload: {
          page_url: 'recharge_iframe',
          time_spent: cost,
        },
        target: {
          labels: ['page_url'],
          value: 'time_spent',
        },
      })
    }
  }

  useEffect(() => {
    walletEvents.on('handleOpenConfirmRechargeFinishModal', ({ orderId }) => {
      if (orderId === data?.orderId) {
        handleOpenConfirmRechargeFinishModal()
      }
    })
    return () => {
      walletEvents.off('handleOpenConfirmRechargeFinishModal')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    const handleVisibilitychange = () => {
      if (!document.hidden && !isConfirmRechargeFinishModalOpen) {
        handleGetRechargeOrderStatus()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilitychange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilitychange)
      clearInterval(timerRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmRechargeFinishModalOpen])

  useEffect(() => {
    if (open && !isConfirmRechargeFinishModalOpen) {
      setTimeout(() => {
        setIsLoading(false)
      }, 5000)
    }
  }, [isConfirmRechargeFinishModalOpen, open])

  return (
    <>
      <Dialog
        scrollBehavior='inside'
        isOpen={open && !isConfirmRechargeFinishModalOpen}
        onClose={handleCloseModal}
        size={{ base: 'md', xs: 'md', sm: 'xl', md: '3xl' }}
        iconStyle={{
          color: {
            base: 'gray.500',
            md: 'text.base',
          },
        }}
        maxH={'95vh'}
        maxW={'90vw'}
      >
        <ModalBody position='relative' padding='0px !important' zIndex={-1}>
          <Box
            height={{ base: '100vh', sm: '75vh' }}
            borderRadius={{ base: '0', sm: 'xl' }}
            overflow='scroll'
          >
            {isLoading && (
              <Box
                position='absolute'
                top={0}
                left={0}
                h='100%'
                w='100%'
                bgColor='var(--modal-bg)'
              >
                <Loading />
              </Box>
            )}
            <iframe
              onLoad={handleIframeOnload}
              style={{
                minHeight: '105%',
                width: '100%',
              }}
              src={data?.cashier}
            />
          </Box>
        </ModalBody>
      </Dialog>
      <TitledDialog
        title={t('DEPOSIT_WARN_PROMPT')}
        isOpen={isConfirmRechargeFinishModalOpen}
        onClose={handleCloseModal}
        size='sm'
      >
        <Confirm orderStatus={orderStatus} />
        <Button
          mt='16px'
          onClick={handleDistributeFc}
          h='46px'
          w='full'
          loadingText='Loading'
          colorScheme='fill-prim'
          _hover={{
            colorScheme: 'fill-prim',
          }}
        >
          {t('REQUEST_PIN')}
        </Button>
      </TitledDialog>
    </>
  )
}

interface ComfirmProps {
  orderStatus: OrderStatus
}
/**
 *
 * @param ComfirmProps
 * @returns Comfirm component
 */
function Confirm({ orderStatus }: ComfirmProps) {
  const { t } = useTranslation()
  const { statusColor, statusIcon } = CONFIRM_RENDER_MAP[orderStatus]
  return (
    <Box position='relative' overflow='hidden' textAlign='center'>
      <Box position='relative' w='72px' mx='auto'>
        <LazyImage src={PngRechargeOrderStatus} />
        {statusIcon && (
          <LazyImage
            w='30px'
            position='absolute'
            bottom='2px'
            right='-8px'
            src={statusIcon}
          />
        )}
      </Box>
      <Text textStyle='h5' m='10px 0' color={statusColor}>
        {t('PAYMENG_COMPLETED')}
      </Text>
      <Text textStyle='text4' color='gray.800'>
        {t('START_PLAY_GAME')}
      </Text>
    </Box>
  )
}
