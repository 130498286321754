import { Box, Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import TitledDialog from '@/components/modal/TitledDialog'
import { useAppSelector } from '@/modules/app/store'
import { selectModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'

import { useGoogleRecaptcha } from './useGoogleRecaptcha'

export default function GoogleReCaptchaModal() {
  const { open, data } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.GoogleRecpatcha],
  )
  const type = (data as { type: string })?.type ?? 'register'
  const { renderGoogleRecaptcha, closeGoogleRecaptcha } = useGoogleRecaptcha()

  const handleClose = () => {
    closeGoogleRecaptcha()
  }

  const { t } = useTranslation()

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        renderGoogleRecaptcha('atom_google_recaptcha')
      }, 100)
    }
  }, [open])

  const textI18nKey =
    type === 'register' ? 'VERIFICATION_HM' : 'VERIFICATION_HM2'

  return (
    <TitledDialog
      isOpen={open}
      onClose={handleClose}
      colorScheme='multilayer'
      size={{ base: 'sm', md: 'md' }}
      title={
        <Text
          fontWeight='normal'
          fontSize={'14px'}
          dangerouslySetInnerHTML={{
            __html: String(t(textI18nKey)),
          }}
        />
      }
    >
      <Box
        id='atom_google_recaptcha'
        display={'flex'}
        minH={'100px'}
        justifyContent={'center'}
        alignItems='center'
      />
    </TitledDialog>
  )
}
