import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react'
import { useSearchParams } from 'react-router-dom'

export const ReferralCodeContext = createContext<
  [string, () => void, () => void, (val: string) => void]
>([
  '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
])

export function ReferralCodeProvider({
  children,
}: {
  children?: React.ReactNode
}) {
  const [searchParams, setSearchParams] = useSearchParams()

  const formItemReferralCode = useRef('')

  const referralCode = useMemo(() => {
    const codeInSearchParams = searchParams?.get('referralcode')
    const codeInSessionStorage = sessionStorage.getItem('referralcode')
    if (codeInSearchParams && !codeInSessionStorage) {
      sessionStorage.setItem('referralcode', codeInSearchParams)
    }
    return codeInSearchParams || codeInSessionStorage || ''
  }, [searchParams])

  const removeReferralCode = useCallback(() => {
    if (sessionStorage.getItem('referralcode')) {
      sessionStorage.removeItem('referralcode')
      if (searchParams.has('referralcode')) {
        searchParams.delete('referralcode')
        setSearchParams(searchParams)
      }
    }
  }, [searchParams, setSearchParams])

  const setFormItemReferralCode = (val: string) => {
    formItemReferralCode.current = val
  }

  const getFormItemReferralCode = () => {
    return formItemReferralCode.current
  }

  return (
    <ReferralCodeContext.Provider
      value={[
        referralCode,
        removeReferralCode,
        getFormItemReferralCode,
        setFormItemReferralCode,
      ]}
    >
      {children}
    </ReferralCodeContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export function useReferralCode() {
  return useContext(ReferralCodeContext)
}
