import { Box } from '@chakra-ui/react'

import { useAppSelector } from '@/modules/app/store'

import { getLuckyWheelCurrentProgress } from '../luckyWheelSlice'

function Progress() {
  const progress = useAppSelector(getLuckyWheelCurrentProgress)
  return (
    <Box
      w='full'
      position='relative'
      borderRadius='80px'
      // opacity='0.3'
      background='rgba(255, 255, 255, 0.3)'
      height='3'
      mt='14px'
    >
      <Box
        position='absolute'
        top='0'
        left='0'
        borderRadius='80px'
        w={`${progress}%`}
        bg='linear-gradient(270deg, #F8EA2D 1.86%, #DD9A3C 100%)'
        height='full'
        zIndex='2'
        transition='all 0.3s'
      />
    </Box>
  )
}

export default Progress
