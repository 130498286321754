import { Box, Flex, Image, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useEffect, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import inviteFriendsIcon from '@/assets/images/lucky-wheel/inviteFriend.png'
import { useAtomCountDown } from '@/components/atom-count-down'
import { useAppSelector } from '@/modules/app/store'
import { useModalConfig } from '@/modules/auto-modal/useModal'
import { GlobalModalKey } from '@/modules/global-modal/types'

import luckyWheelEvents from '../events'
import { getCurrentUserRewardInfo } from '../luckyWheelSlice'

const landingPageStyle = {
  bg: 'rgba(255, 255, 255, .1)',
  btnColor: 'prim.500',
}

function InviteFriendCard({ origin }: { origin?: 'landingPage' }) {
  const { firstDrawTime } = useAppSelector(getCurrentUserRewardInfo)
  const { days, hours, minutes, seconds } = useAtomCountDown({
    targetDate: dayjs(firstDrawTime * 1000)
      .add(3, 'day')
      .format('YYYY-MM-DD HH:mm:ss'),
  })
  const inviteFriendCountDown = useMemo(() => {
    return `${Number(days) * 24 + Number(hours)}:${minutes}:${seconds}`
  }, [days, hours, minutes, seconds])

  const navigate = useNavigate()

  const { onCloseModal } = useModalConfig()

  function removeModalQueue() {
    onCloseModal(GlobalModalKey.LuckyWheelActivity, true)
  }

  const BoxRef = useRef<HTMLDivElement>(null)

  // 监听是否进入视图
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        // 如果进入视图 且不是从下往上滑动
        if (entries[0].boundingClientRect.top > 0) {
          if (entries[0].isIntersecting) {
            luckyWheelEvents.emit('hideMoreIcon')
          } else {
            luckyWheelEvents.emit('showMoreIcon')
          }
        }
      },
      { threshold: 1 },
    )
    if (BoxRef.current) {
      observer.observe(BoxRef.current)
    }
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <Box
      w='full'
      ref={BoxRef}
      bg={
        origin === 'landingPage'
          ? landingPageStyle.bg
          : 'linear-gradient(180deg, #0563DF 0%, #6FBEFE 100%)'
      }
      borderRadius='16px'
      mt='3'
      px='3'
      h='88px'
    >
      <Flex alignItems='flex-start' justifyContent='space-between' h='100%'>
        <Image
          flexShrink={0}
          src={inviteFriendsIcon}
          w='14'
          h='14'
          mt='3'
          objectFit='cover'
        />
        <Flex
          mx='8px'
          h='100%'
          flexWrap='wrap'
          flexDirection='column'
          justifyContent='center'
          w='100%'
        >
          <Text
            textStyle='h7'
            color='white'
            fontWeight='600'
            noOfLines={3}
            w='100%'
          >
            {t('INVITE_FRIEND_1')}
          </Text>
          <Text textStyle='h3' color='four.500' mt='4px' w='100%'>
            {inviteFriendCountDown}
          </Text>
        </Flex>

        <Box
          mt='18px'
          bg={origin === 'landingPage' ? landingPageStyle.btnColor : '#0562DE'}
          color='white'
          borderRadius='10px'
          textStyle='h6'
          py='2'
          w='82px'
          whiteSpace='normal'
          onClick={() => {
            luckyWheelEvents.emit('closeRechargeAndInviteModal')
            removeModalQueue()
            navigate('/activity/luckyWheel?action=openShare')
          }}
          cursor='pointer'
          flexShrink={0}
          textAlign='center'
        >
          {t('TO_INVITE')}
        </Box>
      </Flex>
    </Box>
  )
}

export default InviteFriendCard
