import type { BoxProps } from '@chakra-ui/react'
import {
  Box,
  Center,
  CloseButton,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { createSelector } from '@reduxjs/toolkit'
import React, { Suspense, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import privatePopBgPng from '@/assets/images/private-pop-bg.png'
import { getVariable } from '@/common/env'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  closeModal,
  openModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { selectUserRecharged } from '@/modules/user/userSlice'
import { getShowVirtualModal } from '@/modules/wallet/walletSlice'
import { Icon as SharedIcon } from '@/utils/atom-shared'

import {
  useGetBankruptListMutation,
  useGetRechargeGiftAmountStatusMutation,
} from '../activityApi'
import { selectBankruptCallbackUrl, selectWallet } from '../ActSlice'
import type { BankruptModelBankruptRechargeCfgV2 } from '../type'
import RechargeModal from './RechargeModal'

const BankruptRecharge = React.lazy(() => import('./BankruptRecharge'))
const RECHARGE_TASK_PRIVATE_URL = getVariable('RECHARGE_TASK_PRIVATE_URL')

const PRIVATE_POP_BTN_STYLE: BoxProps = {
  w: `calc((100% - 12px) / 2)`,
  h: '43px',
  borderRadius: '80px',
  border: '2px solid #0ED8FF',
  bg: 'linear-gradient(90deg, #020D8D 0%, #0084EC 49.48%, #04108A 100%)',
  color: '#fff',
  lineHeight: '39px',
  cursor: 'pointer',
}

export default function BankruptRechargeModal() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigator = useNavigate()
  const callbackType = useAppSelector(selectBankruptCallbackUrl)
  const isRechargeed = useAppSelector(selectUserRecharged)
  const [rechargeGiftAmountStatus, setRechargeGiftAmountStatus] = useState<
    'init' | boolean
  >('init')
  const [bankruptRechargeCfg, setBankruptRechargeCfg] = useState<
    BankruptModelBankruptRechargeCfgV2 | 'init' | null
  >('init')

  const { open, data } = useAppSelector(
    createSelector(
      selectModal,
      globalModal => globalModal.BankruptRechargeModal,
    ),
  )
  const showVirtualModal = useAppSelector(getShowVirtualModal)
  const isParamsDialogOpen = useAppSelector(
    state => selectWallet(state).isParamsDialogOpen,
  )
  const { open: isGcashPaymentModalOpen } = useAppSelector(
    createSelector(selectModal, globalModal => globalModal.GcashPaymentModal),
  )

  const [getBankruptList, { isLoading }] = useGetBankruptListMutation()

  /** 充值活动私域是否弹窗 */
  const [getRechargeGiftAmountStatus, { isLoading: isGetStatusLoading }] =
    useGetRechargeGiftAmountStatusMutation()

  const handleCloseModal = () => {
    if (rechargeGiftAmountStatus) {
      setRechargeGiftAmountStatus(false)
      return
    }
    if (callbackType) {
      navigator(callbackType)
    }
    dispatch(closeModal({ key: GlobalModalKey.BankruptRechargeModal }))
  }

  const handleFetchRechargeGiftAmountStatus = async () => {
    try {
      const { data } = await getRechargeGiftAmountStatus().unwrap()
      setRechargeGiftAmountStatus(data?.can)
    } catch (error) {
      setBankruptRechargeCfg(null)
    }
  }

  const handleFetchBankruptList = async () => {
    try {
      const { data } = await getBankruptList().unwrap()
      setBankruptRechargeCfg(data?.[0])
    } catch (error) {
      setBankruptRechargeCfg(null)
    }
  }

  useEffect(() => {
    if (open) {
      handleFetchRechargeGiftAmountStatus()
      handleFetchBankruptList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (
      bankruptRechargeCfg === 'init' ||
      (RECHARGE_TASK_PRIVATE_URL && rechargeGiftAmountStatus === 'init')
    ) {
      return
    }
    if (!isLoading && rechargeGiftAmountStatus && !isGetStatusLoading) {
      return
    }
    if (!isRechargeed && !isLoading && !isGetStatusLoading) {
      dispatch(openModal({ key: GlobalModalKey.Wallet }))
      dispatch(closeModal({ key: GlobalModalKey.BankruptRechargeModal }))
      return
    }
    if (!bankruptRechargeCfg && !isLoading && !isGetStatusLoading) {
      dispatch(openModal({ key: GlobalModalKey.Wallet }))
      dispatch(closeModal({ key: GlobalModalKey.BankruptRechargeModal }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bankruptRechargeCfg,
    data,
    isLoading,
    isGetStatusLoading,
    rechargeGiftAmountStatus,
    isRechargeed,
  ])

  return (
    <Modal
      autoFocus={false}
      trapFocus={false}
      isOpen={
        open &&
        !showVirtualModal &&
        !isParamsDialogOpen &&
        !isGcashPaymentModalOpen
      }
      onClose={handleCloseModal}
      isCentered
      size={'lg'}
    >
      <ModalOverlay />
      <Suspense
        fallback={
          <ModalContent
            bgColor='transparent'
            maxWidth='451px'
            pt='20px'
            sx={{ boxShadow: 'none' }}
            textAlign='center'
          >
            <Center height={['100vh', '30vh']}>
              <Spinner />
            </Center>
          </ModalContent>
        }
      >
        {isLoading || isGetStatusLoading ? (
          <ModalContent
            bgColor='transparent'
            maxWidth='451px'
            pt='20px'
            sx={{ boxShadow: 'none' }}
            textAlign='center'
          >
            <Center height={['100vh', '30vh']}>
              <Spinner />
            </Center>
          </ModalContent>
        ) : (
          <>
            {rechargeGiftAmountStatus === true ? (
              <ModalContent
                bgColor='transparent'
                maxWidth='375'
                sx={{ boxShadow: 'none' }}
                textAlign='center'
              >
                <CloseButton
                  as={SharedIcon.Close}
                  position={['fixed', 'absolute']}
                  right='8px'
                  top='8px'
                  zIndex={10}
                  width='24px'
                  onClick={() => setRechargeGiftAmountStatus(false)}
                  cursor='pointer'
                  color='announceDialog.closeBtnColor'
                />
                <Box minH='400px'>
                  <LazyImage src={privatePopBgPng} w='full' />
                  <Box position='absolute' bottom='24px' left={0} w='full'>
                    <Text
                      px='36px'
                      mb='28px'
                      fontSize='13px'
                      lineHeight='13px'
                      fontWeight={800}
                      background='linear-gradient(90deg, #FFF957 0%, #FFFEE6 51.5%, #FFF957 100%)'
                      backgroundClip='text'
                    >
                      {t('RG_DESPOSITE_MESS2')}
                    </Text>
                    <Flex
                      px='10px'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Box
                        {...PRIVATE_POP_BTN_STYLE}
                        textStyle='h6'
                        onClick={() => {
                          window.open(RECHARGE_TASK_PRIVATE_URL)
                          setRechargeGiftAmountStatus(false)
                        }}
                      >
                        {t('RG_DESPOSITE_BUTTON')}
                      </Box>
                      <Box
                        {...PRIVATE_POP_BTN_STYLE}
                        textStyle='h6'
                        onClick={() => {
                          setRechargeGiftAmountStatus(false)
                        }}
                      >
                        {t('RG_DESPOSITE_BUTTON2')}
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </ModalContent>
            ) : (
              <>
                <BankruptRecharge
                  bankruptRechargeCfg={
                    bankruptRechargeCfg as BankruptModelBankruptRechargeCfgV2
                  }
                  handleCloseModal={handleCloseModal}
                />
                <RechargeModal />
              </>
            )}
          </>
        )}
      </Suspense>
    </Modal>
  )
}
