import { Box, Button } from '@chakra-ui/react'
import type { ReactNode, Ref } from 'react'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

export type Rule = {
  /**
   * 是否必填
   */
  required?: boolean
  /**
   * 必填报错提示信息
   */
  message?: string
  /**
   * 校验方法
   * @param value 当前值
   * @returns 应该返回一个promise 如果是通过校验return  return Promise.resolve(); 如果是没通过校验 应该 return Promise.reject()具体错误信息;
   */
  validator?: (value: string) => Promise<void>
}

type FormProps = {
  /**
   * 默认值
   */
  initialValues?: Record<string, any>
  children: ReactNode
  /**
   * 提交事件
   */
  onSubmit: (formData: any) => Promise<void>
  /**
   * 按钮render
   */
  submitBtnRender?: JSX.Element
  /**
   * 按钮loading状态
   * 只在submitBtnRender没有传时生效
   */
  isLoading?: boolean
  /**
   * 字段更新时触发回调事件
   */
  onFieldsChange?: (
    changedFields: Record<string, any>,
    allFields: Record<string, any>,
  ) => void
  /**
   * 确认按钮文案
   */
  submitBtnText?: string
}

export type FormRef = {
  /**
   * 获取对应字段名的值
   */
  getFieldValue: (name: string) => any
  /**
   * 获取一组字段名对应的值，会按照对应结构返回。默认返回现存字段值
   */
  getFieldsValue: (val?: true | string[]) => Record<string, any>
  /**
   * 校验所有字段,返回是否有未通过校验字段
   */
  validateFormItems: () => Promise<boolean>
  /**
   * 校验对应字段名的值,返回是否未通过校验
   */
  validateFormItem: (name: string) => Promise<boolean>
  /**
   * 重置为initialValues
   */
  resetFields: () => void
  /**
   * 设置表单的值
   */
  setFieldValue: (name: string, value: any) => void
  /**
   * 设置表单的值
   */
  setFieldsValue: (val: Record<string, any>) => void
  /**
   * 获取对应字段名的值
   */
  getErrorValue: (name: string) => any
  /**
   * 获取一组字段名对应的表单错误，会按照对应结构返回。默认返回现存字段值的错误
   */
  getErrorsValue: (val?: true | string[]) => Record<string, any>
  /**
   * 重置错误信息
   */
  resetFormItemErrors: () => void
}

export type FormItemProps = {
  label?: ReactNode | string
  name: string
  value?: any
  onChange?: (name: string, value: any) => void
  registerFormItem?: (name: string, validate: () => Promise<boolean>) => void
  unregisterFormItem?: (name: string) => void
  updateFormItemError?: (name: string, errorMessage: string) => void
  error?: string
  rules?: Rule[]
  hidden?: boolean
}

const Form = forwardRef(
  (
    {
      children,
      onSubmit,
      initialValues,
      submitBtnRender,
      isLoading,
      onFieldsChange,
      submitBtnText,
    }: FormProps,
    ref: Ref<FormRef>,
  ) => {
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState<Record<string, any>>(
      initialValues || {},
    )
    const [preFormData, setPreFormData] = useState<Record<string, any>>(
      initialValues || {},
    )
    const [formItemErrors, setFormItemErrors] = useState<{
      [name: string]: string
    }>({})
    const formItems = useRef<
      { name: string; validate: () => Promise<boolean> }[]
    >([])

    const handleFilterFormData = () => {
      const formDataRes = formItems?.current?.reduce(
        (arrV: Record<string, any>, curV) => {
          if (formData?.[curV.name]) {
            arrV[curV.name] = formData[curV.name]
          }
          return arrV
        },
        {},
      )

      return formDataRes
    }

    const registerFormItem = (
      name: string,
      validate: () => Promise<boolean>,
    ) => {
      formItems.current.push({ name, validate })
    }

    const unregisterFormItem = (name: string) => {
      formItems.current = formItems.current.filter(item => item.name !== name)
      setFormItemErrors(prevErrors => {
        const { [name]: _, ...restErrors } = prevErrors
        return restErrors
      })
    }

    const handleChange = (name: string, value: any) => {
      setFormData(prevData => {
        setPreFormData(prevData)
        return { ...prevData, [name]: value }
      })
    }

    const updateFormItemError = (name: string, errorMessage: string) => {
      setFormItemErrors(prevErrors => {
        return { ...prevErrors, [name]: errorMessage }
      })
    }

    const validateFormItems = async () => {
      let hasErrors = false
      // const errors: { [name: string]: string } = {}

      await Promise.all(
        formItems.current.map(async ({ validate }) => {
          const isValid = await validate()
          if (!isValid) {
            hasErrors = true
          }
        }),
      )

      return hasErrors
    }

    const validateFormItem = async (searchName: string) => {
      let hasErrors = false
      // const errors: { [name: string]: string } = {}
      await Promise.all(
        formItems.current
          ?.filter(({ name }) => name === searchName)
          .map(async ({ validate }) => {
            const isValid = await validate()
            if (!isValid) {
              hasErrors = true
            }
          }),
      )

      return hasErrors
    }

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault()
      e.stopPropagation()
      setLoading(true)

      if (loading) {
        return
      }

      if (await validateFormItems()) {
        setLoading(false)
        return
      }

      await onSubmit(handleFilterFormData())
      setLoading(false)
    }

    const getFieldValue = (name: string) => {
      return handleFilterFormData()?.[name] ?? ''
    }

    const getFieldsValue = (val?: true | string[]) => {
      const currentFormData = handleFilterFormData()
      const formDataKey = Object.keys(currentFormData)
      if (!val || (val && val !== true)) {
        return formDataKey.reduce((accV, curV) => {
          if (currentFormData[curV]) {
            if (!val) {
              accV = { ...accV, [curV]: currentFormData[curV] }
            } else if (val?.includes(curV)) {
              accV = { ...accV, [curV]: currentFormData[curV] }
            }
          }

          return accV
        }, {})
      }

      return currentFormData
    }

    const getErrorValue = (name: string) => {
      return formItemErrors?.[name] ?? ''
    }

    const getErrorsValue = (val?: true | string[]) => {
      const formDataKey = Object.keys(formItemErrors)
      if (!val || (val && val !== true)) {
        return formDataKey.reduce((accV, curV) => {
          if (formItemErrors[curV]) {
            if (!val) {
              accV = { ...accV, [curV]: formItemErrors[curV] }
            } else if (val?.includes(curV)) {
              accV = { ...accV, [curV]: formItemErrors[curV] }
            }
          }

          return accV
        }, {})
      }

      return formItemErrors
    }

    const resetFields = () => {
      setFormData(preV => {
        setPreFormData(preV)
        return initialValues ?? {}
      })
    }

    const resetFormItemErrors = () => {
      setFormItemErrors({})
    }

    const setFieldsValue = (val: Record<string, any>) => {
      setFormData(prevData => {
        setPreFormData(prevData)
        return { ...prevData, ...val }
      })
    }

    useEffect(() => {
      if (onFieldsChange) {
        const changedFields = Object.keys(formData).reduce(
          (accv: Record<string, any>, curv: string) => {
            if (preFormData?.[curv] !== formData[curv]) {
              accv[curv] = formData[curv]
            }
            return accv
          },
          {},
        )

        onFieldsChange(changedFields, formData)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    useImperativeHandle(ref, () => ({
      getFieldValue,
      getFieldsValue,
      validateFormItems,
      validateFormItem,
      resetFields,
      setFieldValue: handleChange,
      setFieldsValue,
      getErrorsValue,
      getErrorValue,
      resetFormItemErrors,
    }))

    const render = (children: ReactNode) => {
      return React.Children.map(children, child => {
        if (
          React.isValidElement(child) &&
          (child.type as any).displayName === 'FormItem'
        ) {
          return React.cloneElement(child, {
            value: formData?.[child.props.name] || '',
            onChange: handleChange,
            registerFormItem,
            unregisterFormItem,
            updateFormItemError,
            error: formItemErrors[child.props.name] || '',
          })
        } else if (React.isValidElement(child) && child.props.children) {
          // 处理可能的嵌套组件
          const nestedChildren = render(child.props.children)
          return React.cloneElement(child, {}, nestedChildren)
        }
        return child
      })
    }

    return (
      <Box as='form' onSubmit={handleSubmit} position='relative'>
        {render(children)}
        {submitBtnRender ? (
          submitBtnRender
        ) : (
          <Button
            isLoading={typeof isLoading === 'boolean' ? isLoading : loading}
            size='lg'
            w='full'
            mt='15px'
            colorScheme='fill-prim'
            type='submit'
          >
            {submitBtnText || 'Submit'}
          </Button>
        )}
      </Box>
    )
  },
)

Form.displayName = 'Form'

export default Form
