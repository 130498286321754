import type { SystemStyleObject } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { getVariable } from '@/common/env'
import TitledDialog from '@/components/modal/TitledDialog'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { selectUserIsLogin } from '@/modules/user/userSlice'
import { getFeatures } from '@/utils/tools'

import { useBindUserPhoneInfoQuery } from './bindPhoneApi'
import {
  getOpen,
  getPhone,
  setCode,
  switchBindPhoneModal,
} from './bindPhoneSlice'
import PhoneVerify from './phoneVerify'
import { useBindPhone } from './useBindPhone'

const SHOW_BIND_PHONE = getVariable('SHOW_BIND_PHONE')

export default function BindPhoneModal() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { lang, isSuccess } = useBindPhone()
  const isLogin = !!useAppSelector(selectUserIsLogin)
  const phone = useAppSelector(getPhone)
  const { refetch: bindPhoneQuery } = useBindUserPhoneInfoQuery(
    {},
    { skip: !isLogin || !SHOW_BIND_PHONE },
  )
  const isOpen = useAppSelector(getOpen)
  const successStyle: SystemStyleObject = isSuccess
    ? {
        textAlign: 'center',
        padding: '24px 20px',
      }
    : {}
  useEffect(() => {
    if (isSuccess && isLogin && SHOW_BIND_PHONE) {
      bindPhoneQuery()
    }
  }, [isSuccess, isLogin, bindPhoneQuery])

  const desc = useMemo(() => {
    if (lang?.type === 'rebindsuccess') {
      return t('PHONE_CHANGE_LOG', { number: phone })
    }
    return t(lang.desc)
  }, [lang, phone, t])

  return (
    <TitledDialog
      isOpen={isOpen}
      title={t(lang.title)}
      onClose={() => {
        dispatch(switchBindPhoneModal())
        dispatch(setCode(''))
      }}
    >
      <Text
        fontSize='12px'
        color='gray.700'
        lineHeight='20px'
        marginBottom='20px'
        sx={successStyle}
      >
        {desc}
      </Text>
      <PhoneVerify />
    </TitledDialog>
  )
}
