import { Box, Text } from '@chakra-ui/react'
import React, { forwardRef, useImperativeHandle, useMemo } from 'react'

import AtomCountDown, { useAtomCountDown } from '@/components/atom-count-down'
import { localStorage } from '@/utils/localStorage'

import { calcHMS, calcIsCountDownFinished, getSeconds } from './utils'

export interface RechargerCountDownRef {
  saveCountDown: () => void
}

const RECHARGER_COUNT_DOWN_BOX_STYLE = {}

const RECHARGER_COUNT_DOWN_TITLE_STYLE = {
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '18px',
  color: 'gray.900',
  textAlign: 'center',
}

const RechargerCountDown = forwardRef(
  (
    props: {
      style?: React.CSSProperties
    },
    ref,
  ) => {
    // 时分秒
    const HMS = calcHMS()

    const leftTime = useMemo(() => {
      const hours = Number(HMS[0][0]) * 10 + Number(HMS[0][1])
      const minutes = Number(HMS[1][0]) * 10 + Number(HMS[1][1])
      const seconds = Number(HMS[2][0]) * 10 + Number(HMS[2][1])
      return (hours * 60 * 60 + minutes * 60 + seconds) * 1000
    }, [HMS])

    // 是否结束
    const { isFinished } = useAtomCountDown({
      leftTime,
    })

    // 导出保存倒计时的方法
    useImperativeHandle(ref, () => ({
      saveCountDown: () => {
        const seconds = getSeconds(HMS)
        localStorage.setItem(
          'rechargeCountDownFinishedTimeStamp',
          seconds.toString(),
        )
      },
    }))

    return (
      <>
        {!isFinished ? (
          <Box
            sx={RECHARGER_COUNT_DOWN_BOX_STYLE}
            style={{
              ...props.style,
            }}
          >
            <Text sx={RECHARGER_COUNT_DOWN_TITLE_STYLE} as='p'>
              Available in
            </Text>
            <AtomCountDown leftTime={leftTime} />
          </Box>
        ) : null}
      </>
    )
  },
)

RechargerCountDown.displayName = 'RechargerCountDown'

export default RechargerCountDown
