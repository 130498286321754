import { Box, HStack, Text, VStack } from '@chakra-ui/react'
import { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useMatch } from 'react-router-dom'

import { isFeatureEnabled } from '@/common/feature'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useCustomizedStyle } from '@/utils/hooks'
import type { Action } from '@/utils/hooks/useActionTrigger'
import { useActionTrigger } from '@/utils/hooks/useActionTrigger'
import appConfig from '@/websites/current/pages/layout/app-config.json'

import { useAppSelector } from '../app/store'
import { createDrawerOpenSelector, DrawerKey } from './app-layout-slice'

interface TabItem {
  icon?: string
  iconActived?: string
  title?: string
  action?: Action
  badge?: string
  isActived?: boolean | (() => boolean)
  key: string
}

const tabItems: TabItem[] = appConfig?.tabBar?.items ?? []

function AtomTabBarItem(props: TabItem) {
  const { icon, iconActived, isActived, action, title, badge } = props
  const { t } = useTranslation()
  const match = useMatch({ path: action?.href ?? 'NOT_A_PATH' })
  const actionTrigger = useActionTrigger()

  const {
    container: containerStyle,
    icon: iconStyle,
    badge: badgeStyle,
    text: textStyle,
  } = useCustomizedStyle(
    'AtomTabBarItem',
    {
      container: {
        userSelect: 'none',
        h: '100%',
        transition: 'transform .4s',
        _active: {
          transform: 'scale(1.2)',
        },
      },
      icon: {
        boxSize: 7,
      },
      badge: {
        position: 'absolute',
        boxSize: '4',
        top: '0',
        right: '-8px',
        transform: 'translate3d(25%,-40%,0)',
      },
      text: {
        textStyle: 'h7',
      },
    },
    tabBarItemParts,
  )

  return (
    <VStack
      flex='1'
      onClick={actionTrigger(action)}
      sx={containerStyle}
      as='button'
      spacing='1.5'
      justifyContent='center'
    >
      <Box position='relative'>
        {badge ? <LazyImage src={badge} sx={badgeStyle} /> : null}
        <LazyImage
          src={isActived || match ? iconActived : icon}
          alt={title}
          sx={iconStyle}
        />
      </Box>
      <Text sx={textStyle}>{t(title ?? '')}</Text>
    </VStack>
  )
}

function _TabBar() {
  const isLeftDrawerOpen = useAppSelector(
    createDrawerOpenSelector(DrawerKey.Left),
  )
  const { pathname } = useLocation()
  const isSport = pathname === '/user/sport'

  const isVisibleSportTabItem = useCallback(
    (tab: TabItem) =>
      isFeatureEnabled('sport') &&
      ((isSport && tab?.action?.href === '/') ||
        (!isSport && tab?.action?.href === '/user/sport')),
    [isSport],
  )

  const tabList = useMemo(
    () =>
      tabItems.filter(
        item => item.key !== 'sport' || isVisibleSportTabItem(item),
      ),
    [isVisibleSportTabItem],
  )

  return (
    <HStack w='full' h='full' overflow='hidden' bg='bg.appLayout.tabBar'>
      {tabList.map(item => (
        <AtomTabBarItem
          {...item}
          isActived={
            (item.action?.eventName === 'toggle-menu' && isLeftDrawerOpen) ||
            (item.key === 'sport' && isSport)
          }
          key={`${item.key}-${item.title}`}
        />
      ))}
    </HStack>
  )
}

const tabBarItemParts = ['container', 'icon', 'badge', 'text'] as const

const TabBar = memo(_TabBar)

export default TabBar
