import { isArray, isEmpty } from 'lodash-es'
import { useEffect, useMemo } from 'react'

import { localStorage } from '@/utils/localStorage'

import { useAppDispatch, useAppSelector } from '../app/store'
import { getUserId } from '../user/storage'
import { selectUser } from '../user/userSlice'
import type { FormatVirtualOrder } from './interface'
import { getVirtualOrderList, setVirtualOrderList } from './walletSlice'

export const useHomeVirtualList = () => {
  const virtualOrderList = useAppSelector(getVirtualOrderList)

  const isLogin = !!useAppSelector(selectUser)?.user

  const dispatch = useAppDispatch()
  const _list = useMemo(() => {
    return virtualOrderList || getLocalList()
  }, [virtualOrderList])

  useEffect(() => {
    const orderList = getLocalList()

    if (!isEmpty(orderList) && isEmpty(virtualOrderList)) {
      dispatch(
        setVirtualOrderList({
          list: orderList,
          index: -1,
        }),
      )
    }
  }, [isLogin, virtualOrderList, dispatch])

  return {
    list: _list,
  }
}
function getLocalList() {
  const _v = localStorage.getItem('virtualOrderList')
  const user_id = getUserId()
  const now = new Date().valueOf()
  if (_v !== null) {
    try {
      const list = JSON.parse(_v)
      if (isArray(list)) {
        return list.filter(
          (item: FormatVirtualOrder) =>
            item.status === 'pending' &&
            item.user_id === user_id &&
            new Date(item.start_time).valueOf() > now,
        )
      }
    } catch (error) {
      throw new Error(`net work error`)
    }
  }
  return []
}
