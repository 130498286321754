import {
  Box,
  Flex,
  Highlight,
  Icon,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { useEffect, useMemo } from 'react'

import coinIcon from '@/assets/images/login-reward-modal/jb7.png'
import store, { useAppSelector } from '@/modules/app/store'
import { selectModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { formatLocalMoney } from '@/utils/tools'
import { projectName } from '@/websites/current/property.json'

import DrawRecord from './components/DrawRecord'
import InviteFriendCard from './components/InviteFriendCard'
import RechargeCard from './components/RechargeCard'
import RechargeProgressCard from './components/RechargeProgressCard'
import { calcNumber, calculateResult } from './components/utils'
import luckyWheelEvents from './events'
import {
  getCurrentUserRewardInfo,
  getLuckyWheelConfigReward,
  getLuckyWheelCurrentProgress,
  getLuckyWheelCurrentRewardDiff,
  getRewardMinDeposit,
} from './luckyWheelSlice'

function RechargeAndInviteModal() {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { currentReward } = useAppSelector(getCurrentUserRewardInfo)
  const diff = useAppSelector(getLuckyWheelCurrentRewardDiff)
  const { reward } = useAppSelector(getLuckyWheelConfigReward)
  const { open: isWalletOpen } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.Wallet],
  )

  const { isFirstRecharge, firstDrawTime } = useAppSelector(
    getCurrentUserRewardInfo,
  )

  const rewardMinDeposit = useAppSelector(getRewardMinDeposit)

  const progress = useAppSelector(getLuckyWheelCurrentProgress)

  useEffect(() => {
    luckyWheelEvents.on('openRechargeAndInviteModal', () => {
      onOpen()
    })
    luckyWheelEvents.on('closeRechargeAndInviteModal', () => {
      onClose()
    })
    return () => {
      luckyWheelEvents.off('openRechargeAndInviteModal')
      luckyWheelEvents.off('closeRechargeAndInviteModal')
    }
  }, [onClose, onOpen])

  const isShowRechargeCard = useMemo(() => {
    if (!firstDrawTime) return false
    if (isFirstRecharge) return false
    return true
  }, [firstDrawTime, isFirstRecharge])

  const isShowInviteCard = useMemo(() => {
    if (!firstDrawTime) return false
    return true
  }, [firstDrawTime])

  const sign = store.getState().user.currencySign

  return (
    <Modal
      autoFocus={false}
      trapFocus={false}
      isOpen={isOpen && !isWalletOpen}
      onClose={onClose}
      isCentered
      size='md'
    >
      <ModalOverlay />
      <ModalContent bg='luckyWheel.rechargeAndInviteModalBg' px='13px' py='7'>
        <Icon
          as={SharedIcon.Close}
          fontWeight='800'
          w='5'
          h='5'
          position='absolute'
          top='4'
          right='4'
          cursor='pointer'
          onClick={() => {
            onClose()
          }}
        />
        <Text textStyle='h4' color='gray.900' textAlign='center' mx='4'>
          {t('CONTRATE_LUCK')}
        </Text>
        <Flex
          textAlign='center'
          w='full'
          justifyContent='center'
          mt='3'
          alignItems='center'
        >
          <Image src={coinIcon} width='60px' height='8' objectFit='cover' />
          <Text textStyle='h2' color='tert.500'>
            {/* {formatLocalMoney(currentReward)} */}
            {`${sign}${calcNumber(currentReward)}`}
            {/* {Math.floor(currentReward * 100) / 100} */}
          </Text>
        </Flex>
        {/* 抽离一下作为一个真正的组件，传入父组件的bg，子组件的bg,子组件的长度*/}
        <Box
          mt='3'
          position='relative'
          w='full'
          bg='rgba(182, 189, 197, 0.3)'
          height='3'
          borderRadius='80px'
        >
          <Box
            position='absolute'
            w={`${progress}%`}
            bg='linear-gradient(90deg, #DD9A3C 0%, #F7C066 99.94%)'
            borderRadius='80px'
            height='3'
          />
        </Box>
        <Text textStyle='text5' color='gray.700' mt='3' textAlign='center'>
          <Highlight
            query={[
              `${sign}${calculateResult(reward, currentReward)}`,
              formatLocalMoney(Number(reward)),
            ]}
            styles={{
              color: 'tert.500',
            }}
          >
            {t('BALANCE_TO_WITHDRAW', {
              balance: `${sign}${calculateResult(reward, currentReward)}`,
              amount: formatLocalMoney(Number(reward)),
            }) ?? ''}
          </Highlight>
        </Text>

        {/* 邀请/充值 部分 */}
        {isShowInviteCard || isShowRechargeCard ? (
          <Text textStyle='h6' textAlign='center' color='gray.900' mt='5'>
            {t('GET_SPIN_TICKET')}
          </Text>
        ) : null}

        {/* 邀请 */}
        {firstDrawTime ? <InviteFriendCard /> : null}
        {/* 充值 */}

        {/** 是否开启了充值要求限制 */}
        {firstDrawTime &&
        rewardMinDeposit &&
        (rewardMinDeposit?.currentDeposit || 0) <
          (rewardMinDeposit?.rewardMinDeposit || 0) ? (
          <RechargeProgressCard />
        ) : null}

        {/* 是否首抽过,如果没有就不展示，如果有，则判断有没有充值过 */}
        {firstDrawTime ? isFirstRecharge ? null : <RechargeCard /> : null}

        {/* 抽奖记录 抽离组件 */}
        <Box mt='5'>
          <DrawRecord />
        </Box>
      </ModalContent>
    </Modal>
  )
}

export default RechargeAndInviteModal
