import GoToAppIcon from '@/assets/images/go-to-app-icon.png'
import LazyImage from '@/components/lazy-image/LazyImage'
import {
  closeModal,
  openModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { downloadApp } from '@/utils/download-app'
import { getDeviceType } from '@/utils/tools'
import {
  Box,
  Button,
  Center,
  Grid,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../app/store'
import { GlobalModalKey } from '../global-modal/types'
import { selecAlltUserInviteUrl } from '../new-referral/referralSlice'

export const isSameOrigin = (a:any, b:any) => {
  try {
    const urlA = new URL(a)
    const urlB = new URL(b)
    return urlA.origin === urlB.origin
  } catch (e) {
    return false
  }
}

const DownloadAppGuideModal = () => {
  const { t } = useTranslation()
  const deviceType = getDeviceType()
  const dispatch = useAppDispatch()
  const shareAllUrl = useAppSelector(selecAlltUserInviteUrl)
  const navigate = useNavigate()

  const currentUrl = window.location.origin
  const isShareLink = shareAllUrl.some(url => isSameOrigin(url, currentUrl))
  const { open } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.DownloadAppGuide],
  )
  const handleClose = useCallback(() => {
    dispatch(closeModal({ key: GlobalModalKey.DownloadAppGuide }))
  }, [dispatch])

  const openAddHomeScreenModal = () => {
    dispatch(closeModal({ key: GlobalModalKey.DownloadAppGuide }))

    dispatch(
      openModal({
        key: GlobalModalKey.AddToHomeScreenGuide,
      }),
    )
  }
  const handleClick = () => {
    if (deviceType === 'android') {
      downloadApp()
      return
    }
    if (deviceType === 'ios' || deviceType === 'ios-desktop-icon') {
      openAddHomeScreenModal()
    }
  }

  const handleOpenWallet = useCallback(() => {
    dispatch(closeModal({ key: GlobalModalKey.DownloadAppGuide }))
    dispatch(openModal(
      { key: GlobalModalKey.Wallet,
        data: {
          defaultActivedTabKey: 'recharge',
          isShareLink: isShareLink,
        }
       }
      ))
    return
  }, [dispatch, isShareLink])
  function isIOS() {
    return isShareLink ? (
      openAddHomeScreenModal()
    ) : (
      <Grid
        padding={25}
        gap={5}
        gridTemplateColumns={'repeat(1, minmax(0, 1fr))'}
      >
        <Text
          color='#FFFFFF'
          fontWeight={800}
          textAlign={'center'}
          fontSize='16px'
        >
          {t('IOS_GO_TO_MOBILE_TITLE')}
        </Text>
        <Center>
          <LazyImage src={GoToAppIcon} w='80px' h='80px' />
        </Center>
        <Text fontSize='12px'>{t('IOS_GO_TO_MOBILE_CONTENT')}</Text>
        <Box>
          <Text fontSize='12px'>{t('IOS_GO_TO_MOBILE_CONTENT_2')}</Text>
          <Text fontSize='12px'>{t('IOS_GO_TO_MOBILE_CONTENT_3')}</Text>
        </Box>
        <Box lineHeight={1.5}>
          <Text fontWeight={800} fontSize='12px'>
            {t('IOS_GO_TO_MOBILE_CONTENT_4')}
          </Text>
          <Text fontSize='12px' color='#FFFFFF'>
            {t('IOS_GO_TO_MOBILE_CONTENT_5')}
          </Text>
          <Text fontSize='12px' color='#FFFFFF'>
            {t('IOS_GO_TO_MOBILE_CONTENT_6')}
          </Text>
        </Box>
        <Button
          colorScheme='fill-prim'
          onClick={() => navigate('/go_sharelink')}
        >
          {t('IOS_GO_TO_BUTTON')}
        </Button>
      </Grid>
    )
  }

  function isAndroid() {
    return (
      <Grid
        padding={25}
        gap={5}
        gridTemplateColumns={'repeat(1, minmax(0, 1fr))'}
      >
        <Text
          color='#FFFFFF'
          fontWeight={800}
          textAlign={'center'}
          fontSize='16px'
        >
          {t('ANDROID_GO_TO_MOBILE_TITLE')}
        </Text>
        <Center>
          <LazyImage src={GoToAppIcon} w='80px' h='80px' />
        </Center>
        <Text fontSize='12px'>{t('ANDROID_GO_TO_MOBILE_CONTENT')}</Text>
        <Box>
          <Text fontSize='12px'>{t('ANDROID_GO_TO_MOBILE_CONTENT_2')}</Text>
          <Text fontSize='12px'>{t('ANDROID_GO_TO_MOBILE_CONTENT_3')}</Text>
        </Box>
        <Box lineHeight={1.5}>
          <Text fontWeight={800} fontSize='12px'>
            {t('ANDROID_GO_TO_MOBILE_CONTENT_4')}
          </Text>
          <Text fontSize='12px' color='#FFFFFF'>
            {t('ANDROID_GO_TO_MOBILE_CONTENT_5')}
          </Text>
          <Text fontSize='12px' color='#FFFFFF'>
            {t('ANDROID_GO_TO_MOBILE_CONTENT_6')}
          </Text>
        </Box>
        <Button colorScheme='fill-prim' onClick={handleClick}>
          {t('ANDROID_GO_TO_BUTTON')}
        </Button>
      </Grid>
    )
  }

  function isPc() {
    return isShareLink ? handleOpenWallet() : (
      <Grid
        padding={25}
        gap={5}
        gridTemplateColumns={'repeat(1, minmax(0, 1fr))'}
      >
        <Text
          color='#FFFFFF'
          fontWeight={800}
          textAlign={'center'}
          fontSize='16px'
        >
          {t('PC_GO_TO_MOBILE_TITLE')}
        </Text>
        <Center>
          <LazyImage src={GoToAppIcon} w='80px' h='80px' />
        </Center>
        <Text fontSize='12px'>{t('PC_GO_TO_MOBILE_CONTENT')}</Text>
        <Box>
          <Text fontSize='12px'>{t('PC_GO_TO_MOBILE_CONTENT_2')}</Text>
          <Text fontSize='12px'>{t('PC_GO_TO_MOBILE_CONTENT_3')}</Text>
        </Box>
        <Box lineHeight={1.5}>
          <Text fontWeight={800} fontSize='12px'>
            {t('PC_GO_TO_MOBILE_CONTENT_4')}
          </Text>
          <Text fontSize='12px' color='#FFFFFF'>
            {t('PC_GO_TO_MOBILE_CONTENT_5')}
          </Text>
          <Text fontSize='12px' color='#FFFFFF'>
            {t('PC_GO_TO_MOBILE_CONTENT_6')}
          </Text>
        </Box>
        <Button
          colorScheme='fill-prim'
          onClick={() => navigate('/go_sharelink')}
        >
          {t('PC_GO_TO_BUTTON')}
        </Button>
      </Grid>
    )
  }

  function DeviceTypeContent() {
    switch (deviceType) {
      case 'pc':
        return isPc()
      case 'ios':
        return isIOS()
      default:
        return isAndroid()
    }
  }
  return (
    <Modal size='xl' isOpen={open} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent width={['355px', '560px']}>
        {DeviceTypeContent() || null}
      </ModalContent>
    </Modal>
  )
}

export default DownloadAppGuideModal
