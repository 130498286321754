import { useMemo } from 'react'

import { selectUserInviteUrl } from '@/modules/new-referral/referralSlice'
import { useUserAuth } from '@/utils/hooks'
import sharesJson from '@/websites/current/public/config/shares.json'

import { useAppDispatch, useAppSelector } from '../../../app/store'
import { openModal } from '../../../global-modal/globalModalSlice'
import { GlobalModalKey } from '../../../global-modal/types'
import { getLuckyWheelSharePlatform } from '../luckyWheelSlice'

function mask(s: string) {
  const prefix = s.slice(0, 4)
  const suffix = s.slice(-3)
  return `${prefix}...${suffix}`
}

export default function useLuckyWheelShares(
  activity: 'luckyWheel' | 'drawActivity' = 'luckyWheel',
  activityId?: number,
) {
  const luckyWheelShares = useAppSelector(getLuckyWheelSharePlatform) || []
  const drawShares = ['whatsapp', 'facebook', 'twitter', 'telegram', 'email']

  const luckyWheelJson = sharesJson.luckyWheel
  const drawSharesJson = sharesJson.draw

  const platforms = activity === 'luckyWheel' ? luckyWheelShares : drawShares
  const shareJson = activity === 'luckyWheel' ? luckyWheelJson : drawSharesJson
  return useShares(platforms, shareJson, activity, activityId)
}

export function useShares(
  platforms: string[],
  platformConfigs?: Record<
    string,
    {
      title: string
      desc: string
      content: string
    }
  >,
  activity = '',
  activityId = 0,
) {
  const dispatch = useAppDispatch()
  const [user_id, _, userName] = useUserAuth()
  const shareUrl = useAppSelector(selectUserInviteUrl)
  const sharesArr = useMemo(() => {
    const { email, whatsapp, telegram, twitter } = platformConfigs || {}

    const tempOrigin = shareUrl || window.location.origin
    const tempUrl =
      tempOrigin +
      `/?u=${window.btoa(
        `referralcode=${user_id}&userName=${mask(
          userName,
        )}&fromActivity=${activity}${
          activityId ? `&fromActivityId=${activityId}` : ''
        }&t=${new Date().getTime()}`,
      )}`

    const encodedUrl = encodeURIComponent(tempUrl)

    const res = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
      whatsapp: `whatsapp://send?text=${
        encodeURIComponent(whatsapp.content) + '%0D%0D' + encodedUrl
      }`,
      // telegram: `https://t.me/share/url?url=${encodeURIComponent(
      //   `https://t.me/iv?url=${tempUrl}`,
      // )}&text=${encodeURIComponent(telegram.content)}`,
      // telegram:`https://t.me/share/url?url=https%3A%2F%2F321779f.xyz%2Fsharecwbr%2Fjo8OZRsE&text=Receba%20100%20BRL%20de%20gra%C3%A7a%2CPix%20SAQUE%20R%C3%81PIDO`
      // telegram:
      //   'https://t.me/share/url?url=https%3A%2F%2Ft.me%2Fiv%3Furl%3Dhttps%253A%252F%252Ftest-b3-lucky-wheel-second.dev.pre-release.xyz%252Factivity%252FluckyWheel%26rhash%3D1dd865a21633f7d',
      telegram: `https://t.me/share/url?url=${encodeURIComponent(
        tempUrl,
      )}&text=${encodeURIComponent(telegram.content)}`,
      twitter: `https://twitter.com/intent/tweet?text=${`${encodeURIComponent(
        twitter.content,
      )}%0D%0D${encodedUrl}`}`,
      email: `mailto:?subject=${email.title}&body=${email.content}%20${encodedUrl}`,
    } as Record<string, string>
    if (platforms?.includes('all')) return res
    Object.keys(res).forEach((key: string) => {
      if (!platforms?.includes(key) && res?.[key]) {
        delete res[key]
      }
    })

    return res
  }, [
    platformConfigs,
    shareUrl,
    user_id,
    userName,
    activity,
    activityId,
    platforms,
  ])

  const handleOpenSharesModal = (
    scene?: 'landingPage' | 'successWithDrawModal' | 'drawActivity',
    amount?: number,
  ) => {
    dispatch(
      openModal({
        key: GlobalModalKey.SharesModal,
        data: {
          scene,
          amount,
          activity,
          activityId,
          ...sharesArr,
        },
      }),
    )
  }

  return {
    sharesArr,
    handleOpenSharesModal,
  }
}
