import type { SystemStyleObject } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import type { ButtonProps } from './FreeTryModal'
import * as Images from './image-imports'

export function Button(props: ButtonProps) {
  const { t } = useTranslation()
  const { text, onClick, colorScheme = 'primary' } = props
  let sx: SystemStyleObject = {
    color: 'white',
    backgroundImage: `url(${Images.blueBtnBgPng})`,
  }

  if (colorScheme === 'secondary') {
    sx = {
      color: '#87370A',
      backgroundImage: `url(${Images.yellowBtnBgPng})`,
    }
  }

  return (
    <Box
      as='button'
      onClick={onClick}
      textStyle='h5'
      textAlign='center'
      display='inline-flex'
      justifyContent='center'
      alignItems='center'
      width='146px'
      height='55px'
      pb='5px'
      backgroundSize='100% 100%'
      backgroundRepeat='no-repeat'
      sx={sx}
    >
      {t(text)}
    </Box>
  )
}
