import {
  Box,
  Center,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'
import React, { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Icon2 from '@/assets/images/act/modal-unactive-2.png'
import { isFeatureEnabled } from '@/common/feature'
import { ActTipsbtnRenderType } from '@/modules/activity/type'
import { useUserAuth } from '@/utils/hooks'

import { useAppDispatch, useAppSelector } from '../app/store'
import { useModalConfig } from '../auto-modal/useModal'
import {
  closeModal,
  openModal,
  selectModal,
} from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { selectUserIsLogin } from '../user/userSlice'
import { getShowVirtualModal, selectWallet } from '../wallet/walletSlice'
import { useLazyRechargePackageGiftListQuery } from './activityApi'
import {
  selectRechargePackageLoaded,
  setRechargePackageLoaded,
} from './ActSlice'

const RechargePackage = React.lazy(() => import('./RechargePackage'))
const rechargePackageV3 = isFeatureEnabled('rechargePackageV3')
export interface RankGameItem {
  amount: number
  nickname: string
}

export function SplitLine({ position }: { position: 'left' | 'right' }) {
  return (
    <Box
      position='relative'
      width='40px'
      height='100%'
      mr={position === 'left' ? '14px' : 0}
      ml={position === 'left' ? 0 : '14px'}
      _after={{
        position: 'absolute',
        top: '50%',
        left: 0,
        width: '100%',
        height: '1px',
        background: `linear-gradient(${
          position === 'left' ? '270' : '90'
        }deg, rgba(255, 255, 255, 0) 0%, #fff 100%)`,
        transform: 'translateY(-50%)',
        content: '""',
      }}
    />
  )
}

export default function RechargePackageModal() {
  const [userId, token] = useUserAuth()
  const { onCloseModal } = useModalConfig()
  const dispatch = useAppDispatch()
  const rechargePackageLoaded = useAppSelector(selectRechargePackageLoaded)
  const { open, data, extra } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.RechargePackage],
  )
  const { open: isLoginModalOpen } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.Login],
  )
  const showVirtualModal = useAppSelector(getShowVirtualModal)
  const isParamsDialogOpen = useAppSelector(
    state => selectWallet(state).isParamsDialogOpen,
  )
  const isLogin = useAppSelector(selectUserIsLogin)
  const { t } = useTranslation()

  const [getRechargePackage] = useLazyRechargePackageGiftListQuery()

  function handleClose() {
    dispatch(closeModal({ key: GlobalModalKey.RechargePackage }))
    dispatch(setRechargePackageLoaded(false))
    if (rechargePackageV3)
      onCloseModal(GlobalModalKey.RechargePackage, extra?.isManu)
  }

  useEffect(() => {
    if (open && !data && !rechargePackageLoaded && isLogin) {
      getRechargePackage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data, rechargePackageLoaded])

  useEffect(() => {
    if (isLogin && open && rechargePackageLoaded) {
      if (!data?.rechargePackageInfo.id) {
        // 手动点击打开
        if (extra?.isManu) {
          dispatch(
            openModal({
              key: GlobalModalKey.ActTips,
              data: {
                title: 'EXCHANGE_TITLE',
                content: t('EVENT_NOT_STARTED'),
                icon: Icon2,
                btnRender: ActTipsbtnRenderType.ACTIVITY_CENTER_BTN,
              },
            }),
          )
          return
        }
        handleClose()
      } else {
        data?.rechargePackageInfo?.pop_state !== 1 &&
          data?.isAuto &&
          !extra?.isManu &&
          handleClose()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLogin, open, rechargePackageLoaded, extra])

  return (
    <Modal
      autoFocus={false}
      trapFocus={false}
      isOpen={
        !!(
          open &&
          !isLoginModalOpen &&
          (data || (!data && (!userId || !token))) &&
          !showVirtualModal &&
          !isParamsDialogOpen
        )
      }
      onClose={handleClose}
      isCentered
      size={'lg'}
    >
      <ModalOverlay />
      <Suspense
        fallback={
          <ModalContent
            bgColor='transparent'
            maxWidth='451px'
            pt='20px'
            sx={{ boxShadow: 'none' }}
            textAlign='center'
          >
            <Center height={['100vh', '30vh']}>
              <Spinner />
            </Center>
          </ModalContent>
        }
      >
        <RechargePackage />
      </Suspense>
    </Modal>
  )
}
