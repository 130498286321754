import { Box, Flex, HStack, Icon, Image, Text, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import galaxyImg from '@/assets/images/galaxy.png'
import store, { useAppSelector } from '@/modules/app/store'
import NewVipLevelIcon from '@/modules/new-vip/components/NewVipLevelIcon'
import {
  selecLastVipLevel,
  selectNextVipLevel,
} from '@/modules/new-vip/newVipSlice'
import { selectVipLevel } from '@/modules/user/userSlice'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useLargerThanMobile, useUserProgress } from '@/utils/hooks'

import BaseNavigateButton from './BaseNavigateButton'
import RichProgressBar from './RichProgressBar'

function RechargeProgress() {
  const { t } = useTranslation()

  const { percentage, current, target } = useUserProgress('recharge')
  if (target === 0) return
  return (
    <RichProgressBar
      title={t('VIP_DEPOSIT').toString()}
      progress={percentage}
      current={current}
      target={target}
    />
  )
}

function BetProgress() {
  const { t } = useTranslation()
  const { percentage, current, target } = useUserProgress('bet')
  if (target === 0) return
  return (
    <RichProgressBar
      title={t('VIP_BET').toString()}
      progress={percentage}
      current={current}
      target={target}
    />
  )
}

const VipInfoCard = ({ fromPopover = false }: { fromPopover?: boolean }) => {
  const vipLevel = useAppSelector(selectVipLevel)
  const isLargerThanMobile = useLargerThanMobile()
  const { t } = useTranslation()
  const iconProps = !isLargerThanMobile
    ? {
        size: 50,
        defaultWidth: 50,
        width: 50,
        height: 50,
      }
    : {
        size: 64,
        defaultWidth: 64,
        width: 64,
        height: 64,
      }
  const navigator = useNavigate()
  const maxVipLevel = useAppSelector(selecLastVipLevel)
  const nextVipLevel = selectNextVipLevel(store.getState())

  const renderNextLevel = (nextVipLevel: number, maxVipLevel: number) => {
    if (nextVipLevel === maxVipLevel) {
      return <div />
    }

    return (
      <Box color='personalCenter.inputText' fontSize='12px' lineHeight='16px' fontWeight='600'>
        {t('NEXT_LEVEL')}:{' '}
        <Text color='personalCenter.primText' display='inline-block'>
          VIP {nextVipLevel}
        </Text>
      </Box>
    )
  }

  const notFromPopoverStyle = fromPopover
    ? { flexDirection: 'column', gap: '16px' }
    : {
        flexDirection: { md: 'row', base: 'column' },
        gap: { md: '24px', base: '16px' },
      }

  const cardStyle = {
    background: fromPopover
      ? 'personalCenter.gradient'
      : 'personalCenter.detailPageGradient',
    borderRadius: '12px',
    borderWidth: "1px",
    borderStyle:"solid",
    borderColor :"personalCenter.border"
  }

  const imgStyle = fromPopover
    ? {
        transform: 'rotate(40deg)',
        top: '-150%',
        left: '-50%',
      }
    : {
        transform: ' rotate(40deg)',
        top: '-250%',
        right: ' -50%',
      }

  return (
    <Box sx={cardStyle} position='relative' overflow='hidden'>
      <Image src={galaxyImg} position='absolute' sx={imgStyle} />
      <VStack p='16px' gap='8px'>
        <HStack justifyContent='space-between' w='100%'>
          <Flex alignItems='center' gap='12px'>
            {/* <NewVipLevelIcon {...iconProps} level={vipLevel} /> */}
            {/* <VipLevelNumIcon level={vipLevel} {...iconProps} /> */}
            <Box
              position='relative'
              height={iconProps.size + 'px'}
              width={iconProps.size + 'px'}
            >
              <Box position='absolute'>
                <NewVipLevelIcon level={vipLevel} />
                {/* <VipLevelIcon level={vipLevel} {...iconProps} /> */}
              </Box>
            </Box>

            <VStack alignItems='flex-start' gap='4px'>
              <Text
                color='personalCenter.currentRankTextColor'
                fontSize='12px'
                lineHeight='16px'
                fontWeight='600'
              >
                {t('CURRENT_RANK')}
              </Text>

              <Text
                color='personalCenter.primText'
                fontSize='16px'
                lineHeight='24px'
                fontWeight='700'
              >
                VIP {vipLevel}
              </Text>
            </VStack>
          </Flex>

          {fromPopover ? (
            <Text
              cursor='pointer'
              color='#1376FA'
              lineHeight='20px'
              fontSize='14px'
              fontWeight='700'
              onClick={() => navigator('/vip')}
            >
              {t('DEPOSIT_VIP_LEVEL')}
              <Icon as={SharedIcon.ArrowRight1} height='16px' width='16px' />
            </Text>
          ) : (
            <div />
          )}
        </HStack>

        <Flex
          py='4px'
          alignItems='normal'
          width='100%'
          {...(notFromPopoverStyle as any)}
        >
          <Box w='100%'>
            <RechargeProgress />
          </Box>
          <Box w='100%'>
            <BetProgress />
          </Box>
        </Flex>

        {!fromPopover && (
          <Flex
            alignItems='center'
            w='100%'
            justifyContent='space-between'
            mt='8px'
          >
            {renderNextLevel(nextVipLevel, maxVipLevel)}
            <BaseNavigateButton path='/vip'>
              {t('DEPOSIT_VIP_LEVEL')}
            </BaseNavigateButton>
          </Flex>
        )}
      </VStack>
    </Box>
  )
}

export default VipInfoCard
