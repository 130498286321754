import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import { Flex, Link, useTheme } from '@chakra-ui/react'
import { useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import LazyImage from '@/components/lazy-image/LazyImage'
import { useLargerThanMobile } from '@/utils/hooks/useLargerThanMobile'

import { useAppSelector } from '../app/store'
import { selectIsChristmasDressUpEnable } from '../christmas-dress-up/api'
import { selectWebsiteLogo } from './appApi'

function LogoSwiper({
  websiteLogoMap,
  mobileImg,
  desktopImg,
}: {
  websiteLogoMap: Record<'mobile' | 'pc', string[]>
  mobileImg: string
  desktopImg: string
}) {
  const isLargeThanMobile = useLargerThanMobile()

  const imgList = useMemo(() => {
    if (isLargeThanMobile) return [desktopImg, ...(websiteLogoMap?.pc || [])]

    return [mobileImg, ...(websiteLogoMap?.mobile || [])]
  }, [desktopImg, isLargeThanMobile, mobileImg, websiteLogoMap])

  return (
    <Swiper
      key={`logo_${isLargeThanMobile ? 'pc' : 'mobile'}`}
      direction='vertical'
      slidesPerView='auto'
      loop
      noSwipingClass='logo'
      autoplay={imgList?.length > 1 ? { delay: 4000 } : false}
      style={{
        position: 'relative',
        height: '42px',
        width: '100%',
      }}
      modules={[Autoplay]}
    >
      {imgList?.map((item, index) => {
        return (
          <SwiperSlide
            key={`logo_${isLargeThanMobile ? 'pc' : 'mobile'}_${index}`}
            // style={{ width: '100px' }}
          >
            <Flex>
              <LazyImage
                className='logo'
                noLazy
                fallbackSrc={isLargeThanMobile ? desktopImg : mobileImg}
                src={item || ''}
                srcSet=''
                alt='site logo'
                height='42px'
                objectFit='contain'
              />
            </Flex>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default function WebsiteLogo() {
  const isChristmasDressUpEnable = useAppSelector(
    selectIsChristmasDressUpEnable,
  )

  const websiteLogoMap = useAppSelector(selectWebsiteLogo)

  const theme = useTheme()

  const [desktopImg, mobileImg] = useMemo(() => {
    if (isChristmasDressUpEnable) {
      return [
        '/assets/images/about-icon/christmas-logos/site-logo.png',
        '/assets/images/about-icon/christmas-logos/site-logo-short.png',
      ]
    }

    return [
      '/assets/images/about-icon/site-logo.png',
      '/assets/images/about-icon/site-logo-short.png',
    ]
  }, [isChristmasDressUpEnable])

  return (
    <Link as={RouterLink} to='/'>
      <picture>
        <source
          srcSet={desktopImg}
          media={`(min-width: ${theme.breakpoints.md})`}
        />
        {websiteLogoMap ? (
          <LogoSwiper
            websiteLogoMap={websiteLogoMap}
            mobileImg={mobileImg}
            desktopImg={desktopImg}
          />
        ) : (
          <LazyImage
            src={mobileImg}
            srcSet=''
            alt='site logo'
            height='42px'
            objectFit='contain'
          />
        )}
      </picture>
    </Link>
  )
}
