import { Button, Flex, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { useCallback } from 'react'

import TitledDialog from '@/components/modal/TitledDialog'

import { useAppDispatch, useAppSelector } from '../app/store'
import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'

export interface props {
  isOpen: boolean
  onClose(): void
}
export default function ConfirmModal() {
  const dispatch = useAppDispatch()
  const { open, data } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.Confirm],
  )
  const handleClose = useCallback(() => {
    dispatch(closeModal({ key: GlobalModalKey.Confirm }))
  }, [dispatch])
  return (
    <TitledDialog
      isCentered
      isOpen={open}
      onClose={handleClose}
      title={t(data?.title)}
    >
      <Flex
        flexDirection='column'
        justifyContent='space-between'
        alignItems='center'
      >
        <Text color='gray.700' margin='56px 28px' textStyle='text4'>
          {t(data?.content)}
        </Text>

        <Button
          width='100%'
          height='46px'
          colorScheme={'fill-prim'}
          fontSize='lg'
          fontWeight='extrabold'
          onClick={handleClose}
        >
          {t('HOME_LOBBY')}
        </Button>
      </Flex>
    </TitledDialog>
  )
}
