import { Button, Icon, Text } from '@chakra-ui/react'

import { Icon as SharedIcon } from '@/utils/atom-shared'

function NewMessageButtonArea({
  showReceiveButton = true,
  showDeleteButton = true,
  receiveButtonContext = 'MESSAGE_BUTTON1',
  deleteButtonContext = 'MESSAGE_BUTTON2',
  receiveButtonClickCb = () => {
    return
  },
  deleteButtonClickCb = () => {
    return
  },
  receiveBtnLoading = false,
  delBtnLoading = false,
}: {
  showReceiveButton?: boolean
  showDeleteButton?: boolean
  receiveButtonContext?: string
  deleteButtonContext?: string
  receiveButtonClickCb?: () => void
  deleteButtonClickCb?: () => void
  receiveBtnLoading?: boolean
  delBtnLoading?: boolean
}) {
  return (
    <>
      {showReceiveButton && (
        <Button
          flex='1'
          colorScheme='fill-prim'
          px='18px'
          py='12px'
          onClick={() => receiveButtonClickCb()}
          cursor='pointer'
          isLoading={receiveBtnLoading}
        >
          <Icon as={SharedIcon.Gift} boxSize='4' color='white' />
          <Text textStyle='text6' ml='8px'>
            {receiveButtonContext}
          </Text>
        </Button>
      )}
      {showDeleteButton && (
        <Button
          flex='1'
          colorScheme='fill-tert'
          px='18px'
          py='12px'
          onClick={() => deleteButtonClickCb()}
          cursor='pointer'
          isLoading={delBtnLoading}
        >
          <Icon as={SharedIcon.Delete} boxSize='4' color='white' />
          <Text textStyle='text6' ml='8px'>
            {deleteButtonContext}
          </Text>
        </Button>
      )}
    </>
  )
}

export default NewMessageButtonArea
