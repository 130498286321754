import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import {
  Box,
  Button,
  Center,
  CloseButton,
  Flex,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import type { SwiperProps } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { Swiper as SwiperClass } from 'swiper/types'

import jbBox from '@/assets/images/login-reward-modal/box.png'
import jb1 from '@/assets/images/login-reward-modal/jb1.png'
import jb2 from '@/assets/images/login-reward-modal/jb2.png'
import jb3 from '@/assets/images/login-reward-modal/jb3.png'
import jb4 from '@/assets/images/login-reward-modal/jb4.png'
import jb5 from '@/assets/images/login-reward-modal/jb5.png'
import jb6 from '@/assets/images/login-reward-modal/jb6.png'
import jb7 from '@/assets/images/login-reward-modal/jb7.png'
import newCoin1 from '@/assets/images/login-reward-modal/newCoin1.png'
import newCoin2 from '@/assets/images/login-reward-modal/newCoin2.png'
import newCoin3 from '@/assets/images/login-reward-modal/newCoin3.png'
import newCoin4 from '@/assets/images/login-reward-modal/newCoin4.png'
import newCoin5 from '@/assets/images/login-reward-modal/newCoin5.png'
import newCoin6 from '@/assets/images/login-reward-modal/newCoin6.png'
import newCoin7 from '@/assets/images/login-reward-modal/newCoin7.png'
import { getSign } from '@/common/locale/currency'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useModalConfig } from '@/modules/auto-modal/useModal'
import { toggleBalanceFloat, updateBanlance } from '@/modules/user/userSlice'
import { useUserAuth } from '@/utils/hooks'
import useExchangeCurrency from '@/utils/hooks/useExchangeCurrency'
import useUserInfo from '@/utils/hooks/useUserInfo'
import VipStyle from '@/websites/current/styles/components/VipStyle.json'

import { useAppDispatch, useAppSelector } from '../app/store'
import {
  closeModal,
  openModal,
  selectModal,
} from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { selectADDLoginReward } from '../guide-event/guideEventSlice'
import useADDModalControl from '../guide-event/useADDModalControl'
import { rotateAnimation } from '../wheel-modal/style'
import CountDownToEnd from './CountDown'
import {
  useLoginTaskCfqQuery,
  useLogintaskClaimMutation,
  useLoginTaskInfoQuery,
} from './loginRewardModalApi'
import {
  selectLoginReward,
  selectLoginRewardAmountIsHidden,
} from './loginRewardSlice'
import { getRewardsStyle } from './style'
import type { CfgListItem, IInfoResult } from './types'

/**
 * 将item分为
 * finish：已领取
 * active：待领取
 * default：未领取
 * 三种状态
 */
const rewardsStatus = (item: CfgListItem, curInfo?: IInfoResult) => {
  if (!curInfo) {
    return 'default'
  }
  if (
    item.day === 7 &&
    curInfo?.cur_day > 7 &&
    !curInfo?.cur_receive &&
    curInfo.vip_level === item.vip_level
  ) {
    return 'active'
  }
  if (curInfo.cur_day > item.day && curInfo.vip_level === item.vip_level) {
    return 'finish'
  }
  if (curInfo.cur_day === item.day && curInfo.vip_level === item.vip_level) {
    if (curInfo.cur_receive) {
      return 'finish'
    } else {
      return 'active'
    }
  } else {
    return 'default'
  }
}
/**
 * 根据奖励金额区分金币图片
 */
const rewardsImg = (goldLevel: number) => {
  return [jb1, jb2, jb3, jb4, jb5, jb6, jb7][goldLevel]
}

/**
 *
 * 有金额的奖励
 */
function RewardWithAmount({
  v,
  cfqAmountList,
  index,
}: {
  v: CfgListItem
  cfqAmountList: number[][]
  index: number
}) {
  const CURRENCY_SYMBOL = getSign()
  const { formatValue } = useExchangeCurrency()
  return (
    <>
      <LazyImage
        width='108px'
        src={rewardsImg(
          Math.max(cfqAmountList[index]?.indexOf(v.reward_amount) ?? 0, 0),
        )}
        marginTop={v.day === 7 ? '-1px' : '28px'}
        alt='coin'
        position='relative'
        // zIndex='1'
      />
      <Text
        color='#fff'
        fontWeight='800'
        lineHeight='24px'
        marginTop='-7px'
        textAlign='center'
        fontSize={v.day === 7 ? '20px' : '18px'}
        flex={v.day === 7 ? 1 : 0}
      >
        {CURRENCY_SYMBOL}
        {formatValue(v?.reward_amount)}
      </Text>
    </>
  )
}

function RewardWithOutAmount({ v }: { v: CfgListItem }) {
  const imgCoinList = useMemo(
    () => [
      newCoin1,
      newCoin2,
      newCoin3,
      newCoin4,
      newCoin5,
      newCoin6,
      newCoin7,
    ],
    [],
  )

  const imgSrc = useMemo(() => {
    return imgCoinList[v.day - 1]
  }, [imgCoinList, v.day])

  return (
    <>
      {v.day !== 7 ? (
        <Image
          maxW='100%'
          h='88px'
          src={imgSrc}
          margin='0 auto'
          marginTop='32px'
          alt='coin'
          position='relative'
        />
      ) : (
        <Flex justifyContent='space-between' alignItems='center' w='full'>
          <Text
            ml='64px'
            textStyle='h4'
            background='linear-gradient(270deg, #F1F46A 0%, #FFF 95.56%)'
            backgroundClip='text'
          >
            {t('DAY7_BONUS')}
          </Text>
          <Image
            maxW='180px'
            src={newCoin7}
            marginTop='2px'
            alt='coin'
            marginRight='2'
            position='relative'
          />
        </Flex>
      )}
    </>
  )
}

function LoginRewardModal() {
  const { formatValue } = useExchangeCurrency()
  const CURRENCY_SYMBOL = getSign()
  const dispatch = useAppDispatch()
  const [activeLevel, setActiveLevel] = useState(0)
  const [activeIndex, setActiveIndex] = useState(0)

  const swiperRef = useRef<SwiperClass>(null)

  // 是否隐藏金额
  const isHideAmount = useAppSelector(selectLoginRewardAmountIsHidden)

  const cfqList = useAppSelector(
    (state: any) => selectLoginReward(state).cfgList,
  )
  const rechargeAmount = useAppSelector(
    state => selectLoginReward(state).rechargeAmount,
  )
  const progressAmount = useAppSelector(
    state => selectLoginReward(state).progressAmount,
  )
  const [loginTaskClaim] = useLogintaskClaimMutation()
  const { userInfo } = useUserInfo()
  const { open } = useAppSelector(
    (state: any) => selectModal(state)[GlobalModalKey.LoginReward],
  )
  const { onCloseModal } = useModalConfig()
  const [userId, token] = useUserAuth()
  useLoginTaskCfqQuery({ token, user_id: userId }, { skip: !token || !userId })
  const {
    data: curInfo,
    refetch,
    isUninitialized,
  } = useLoginTaskInfoQuery(
    { token, user_id: userId },
    { skip: !token || !userId },
  )

  /** 引流下载App弹窗 */
  const diversionModalCfg = useAppSelector(selectADDLoginReward)
  const { handleAddModalShow } = useADDModalControl(diversionModalCfg)

  useEffect(() => {
    if (open && !isUninitialized) {
      refetch()
    }
  }, [open, refetch, isUninitialized])
  const [isloading, setLoading] = useState<boolean>(false)
  const [cfqAmountList, setCfqAmountList] = useState<number[][]>([[0]])
  const swiperParams: SwiperProps = {
    spaceBetween: 10,
    centeredSlides: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    slidesPerView: 'auto',
    initialSlide:
      cfqList.findIndex(
        item => item[0].vip_level === curInfo?.data.vip_level,
      ) === -1
        ? 0
        : cfqList.findIndex(
            item => item[0].vip_level === curInfo?.data.vip_level,
          ),
    onActiveIndexChange: swiper => {
      setActiveLevel(cfqList[swiper.realIndex][0].vip_level)
      setActiveIndex(swiper.realIndex)
    },
    onAfterInit: swiper => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      swiperRef.current = swiper
    },
    simulateTouch: false,
  }
  const handleClose = useCallback(() => {
    dispatch(closeModal({ key: GlobalModalKey.LoginReward }))
    onCloseModal(GlobalModalKey.LoginReward)
  }, [dispatch, onCloseModal])

  const handleItemClick = async (v: CfgListItem) => {
    if (isloading || rewardsStatus(v, curInfo?.data) !== 'active') {
      return
    }
    if (handleAddModalShow()) {
      return
    }
    setLoading(true)
    await loginTaskClaim({ token, user_id: userId })
      .unwrap()
      .then((payload: { code: number }) => {
        setLoading(false)
        dispatch(closeModal({ key: GlobalModalKey.LoginReward }))
        if (payload?.code === 200) {
          const reward = v?.reward_amount || 0
          const blc: number = userInfo.banlance?.amount as number
          const addAmount = reward
          dispatch(
            toggleBalanceFloat({
              balance: addAmount,
            }),
          )
          dispatch(
            openModal({
              key: GlobalModalKey.RewardSuccess,
              data: addAmount,
            }),
          )
          const res: number = Number(blc) + Number(addAmount)
          updateBanlance(Number(res))
        } else {
          dispatch(openModal({ key: GlobalModalKey.RechargeGuide }))
        }
      })
      .catch(() => {
        setLoading(false)
        dispatch(openModal({ key: GlobalModalKey.RechargeGuide }))
      })
  }

  useEffect(() => {
    if (cfqList.length > 0) {
      setCfqAmountList(
        cfqList.map((item: any[]) => {
          return [
            ...new Set(
              item.map((v: { reward_amount: any }) => v.reward_amount),
            ),
          ].sort((a, b) => a - b)
        }),
      )
      setActiveLevel(cfqList[0][0]?.vip_level)
    }
  }, [cfqList])

  return (
    <>
      <Modal
        autoFocus={false}
        trapFocus={false}
        isOpen={open}
        onClose={handleClose}
        isCentered
        motionPreset='none'
      >
        <ModalOverlay />
        <ModalContent
          width={['100%', '375px']}
          maxHeight='100%'
          maxWidth='100%'
          backgroundColor='transparent'
          boxShadow='none'
        >
          {curInfo && !!cfqList?.length && (
            <ModalBody padding='0' margin='0'>
              <Swiper {...swiperParams}>
                {cfqList.map((item: any, index: number) =>
                  index === activeIndex ||
                  index === activeIndex - 1 ||
                  index === activeIndex + 1 ||
                  index === cfqList.length - 1 ||
                  index === 0 ? (
                    <SwiperSlide key={'swiper_' + item[0].vip_level}>
                      <Box
                        position='relative'
                        borderRadius='16px'
                        minHeight='527px'
                        padding='26px 18px 12px'
                        bg='bg.modal'
                        overflow='hidden'
                      >
                        <Box
                          width='56px'
                          height='56px'
                          position='absolute'
                          left='0'
                          top='0'
                        >
                          <Center
                            width='38px'
                            height='40px'
                            color='white'
                            fontSize='10px'
                            fontWeight='800'
                            transform='rotate(-45deg)'
                            cursor='default'
                            _before={{
                              content: '""',
                              w: '9999px',
                              h: '2.25em',
                              bg: 'prim.500',
                              position: 'absolute',
                              mt: '-0.2em',
                              zIndex: '-1',
                            }}
                          >
                            <LazyImage
                              src='/assets/images/login-reward-modal/icon-crown.svg'
                              alt=''
                              width='12px'
                              marginRight='3px'
                            />
                            {t('MOBILE_NAV_VIP')}
                            {item[0].vip_level}
                          </Center>
                        </Box>

                        <CloseButton
                          position='absolute'
                          right='3'
                          top='3'
                          onClick={handleClose}
                        />
                        <Text
                          textStyle='h4'
                          textAlign='center'
                          marginBottom='20px'
                          color='gray.900'
                        >
                          {t('CONTINUE_LOGIN_MODAL_TITLE')}
                        </Text>
                        <Grid templateColumns='repeat(3, 1fr)' gap={'8px'}>
                          {item.map((v: CfgListItem, i: string) => (
                            <GridItem
                              key={'rewards_' + index + '_' + i}
                              width='100%'
                              position='relative'
                              overflow='hidden'
                              cursor={
                                rewardsStatus(v, curInfo?.data) === 'active'
                                  ? 'pointer'
                                  : 'not-allowed'
                              }
                              sx={
                                rewardsStatus(v, curInfo?.data) === 'default'
                                  ? VipStyle.loginRewardItemsBg
                                  : VipStyle.loginRewardItemsActiveBg
                              }
                              {...getRewardsStyle(v.day)}
                              onClick={() => handleItemClick(v)}
                            >
                              {rewardsStatus(v, curInfo?.data) !==
                                'default' && (
                                <LazyImage
                                  src='/assets/images/login-reward-modal/light.png'
                                  height='223px'
                                  marginRight='-116px'
                                  marginTop='-112px'
                                  position='absolute'
                                  right='50%'
                                  top='50%'
                                  width='232px'
                                  maxWidth='initial'
                                  animation={
                                    rewardsStatus(v, curInfo?.data) === 'active'
                                      ? rotateAnimation
                                      : ''
                                  }
                                  zIndex='0'
                                  cursor='pointer'
                                />
                              )}
                              <Box
                                backgroundColor='#f89827'
                                borderRadius='10px 0'
                                color='white'
                                fontSize='12px'
                                fontWeight='800'
                                height='24px'
                                lineHeight='24px'
                                left='0'
                                line-height='24px'
                                position='absolute'
                                textAlign='center'
                                top='0'
                                width='59px'
                              >
                                Day {v.day === 7 ? '7+' : v.day}
                              </Box>
                              {/* 如果后台开启了隐藏登录奖励的特性开关,则隐藏金额*/}
                              {!isHideAmount ? (
                                <RewardWithAmount
                                  v={v}
                                  cfqAmountList={cfqAmountList}
                                  index={index}
                                />
                              ) : (
                                <RewardWithOutAmount v={v} />
                              )}
                              {rewardsStatus(v, curInfo?.data) === 'finish' && (
                                <Center
                                  position='absolute'
                                  top='0'
                                  left='0'
                                  width='100%'
                                  height='100%'
                                  backgroundColor='rgba(0,0,0,0.6)'
                                >
                                  <LazyImage
                                    src='/assets/images/login-reward-modal/icon-success.svg'
                                    alt='iconSuccess'
                                  />
                                </Center>
                              )}
                              {curInfo.data.vip_level === v.vip_level &&
                                curInfo.data.cur_receive &&
                                curInfo.data.cur_day + 1 === v.day && (
                                  <Center
                                    width='77px'
                                    height='22px'
                                    background='rgba(0,0,0,0.5)'
                                    borderRadius='32px'
                                    fontSize='14px'
                                    color='white'
                                    position='absolute'
                                    top='50%'
                                    left='50%'
                                    transform='translate(-50%,-50%)'
                                    zIndex={2}
                                  >
                                    <CountDownToEnd endOfType='day' />
                                  </Center>
                                )}
                            </GridItem>
                          ))}
                        </Grid>
                        {!(
                          curInfo.data.vip_level ===
                            cfqList[cfqList.length - 1][0].vip_level &&
                          curInfo.data?.progress_amount === 0
                        ) && (
                          <>
                            {curInfo.data?.progress_amount > 0 ? (
                              <Text
                                color='gray.700'
                                textAlign='center'
                                marginTop='16px'
                                fontSize='12px'
                                dangerouslySetInnerHTML={{
                                  __html:
                                    t('VIP_AWARDS_DESCRIPTION_3_COLOR', {
                                      text_color:
                                        'var(--chakra-colors-prim-500)',
                                      VIP_BONUS_RECHARGE:
                                        CURRENCY_SYMBOL +
                                        '&nbsp;' +
                                        (rechargeAmount - progressAmount) +
                                        '/' +
                                        rechargeAmount,
                                      VIP_BONUS_DAY: t('DAY7'),
                                    }) ?? '',
                                }}
                              />
                            ) : (
                              <Text
                                color='gray.700'
                                textAlign='center'
                                marginTop='12px'
                                textStyle='text5'
                              >
                                {t('VIP_HIGHER_REWARD')}
                              </Text>
                            )}
                            <Button
                              width='100%'
                              height='46px'
                              colorScheme='fill-prim'
                              fontSize='lg'
                              fontWeight='extrabold'
                              marginTop='16px'
                              onClick={() => {
                                dispatch(
                                  openModal({
                                    key: GlobalModalKey.RechargeGuide,
                                  }),
                                )
                              }}
                            >
                              {t('AWARD_GUIDE_BUTTON')}
                            </Button>
                          </>
                        )}

                        <Text
                          fontSize='12px'
                          fontWeight='700'
                          lineHeight='15px'
                          color='gray.700'
                          marginTop='10px'
                          textAlign='center'
                        >
                          {t('USER_TITLE_THREE')} : {t('MOBILE_NAV_VIP')}{' '}
                          {curInfo.data.vip_level}
                        </Text>
                      </Box>
                    </SwiperSlide>
                  ) : (
                    <SwiperSlide key={'swiper_' + item[0].vip_level}>
                      <Box width='100%' height='527px' />
                    </SwiperSlide>
                  ),
                )}
                <Flex
                  alignItems='center'
                  justifyContent='center'
                  marginTop='16px'
                >
                  <Box
                    cursor='pointer'
                    onClick={() => {
                      swiperRef.current?.slidePrev()
                    }}
                  >
                    <LazyImage
                      src='/assets/images/login-reward-modal/arrow-left.svg'
                      alt=''
                    />
                  </Box>
                  <Center
                    width='117px'
                    color={
                      activeLevel === curInfo.data.vip_level
                        ? 'four.500'
                        : 'white'
                    }
                    cursor='default'
                    textStyle='h4'
                  >
                    {t('MOBILE_NAV_VIP')} {activeLevel}
                  </Center>
                  <Box
                    cursor='pointer'
                    onClick={() => {
                      swiperRef.current?.slideNext()
                    }}
                  >
                    <LazyImage
                      src='/assets/images/login-reward-modal/arrow-right.svg'
                      alt=''
                    />
                  </Box>
                </Flex>
              </Swiper>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

export default LoginRewardModal
