import { Box, Text } from '@chakra-ui/react'
import { t } from 'i18next'

function NewMessageStatusIcon() {
  return (
    <Box
      borderTopRightRadius='10px'
      bgColor='#F43434'
      px='5.5px'
      py='2px'
      borderBottomLeftRadius='10px'
      position='absolute'
      top='0'
      right='0'
    >
      <Text color='white' textStyle='text6'>
        {t('MESSAGE_HINT')}
      </Text>
    </Box>
  )
}

export default NewMessageStatusIcon
