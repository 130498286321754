import { Box, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react'
import { memo, useContext, useEffect, useMemo, useState } from 'react'
import type { PathMatch } from 'react-router-dom'
import { useLocation, useMatch } from 'react-router-dom'

import DropdownIcon from '@/assets/images/lucky-wheel/drop_down.png'
import LazyImage from '@/components/lazy-image/LazyImage'
import type {
  MobileBarMenuProps,
  MobileBarMenuPropsConfig,
} from '@/modules/app/home-page-config-types'
import { HomePageConfigContext } from '@/modules/app-layout/AppLayout'
import { useCustomizedStyle } from '@/utils/hooks'
import { useActionTrigger } from '@/utils/hooks/useActionTrigger'
import { getConfigLng } from '@/utils/tools'

import luckyWheelEvents from '../activity/lucky-wheel/events'
import { getCurrentUserRewardInfo } from '../activity/lucky-wheel/luckyWheelSlice'
import { useAppSelector } from '../app/store'
import { createDrawerOpenSelector, DrawerKey } from './app-layout-slice'

const lng = getConfigLng()

function AtomTabBarItem(props: MobileBarMenuProps) {
  const { language_content, action, action_active, isActived } = props
  let match: PathMatch<string> | boolean | null = useMatch({
    path: action?.href ?? 'NOT_A_PATH',
  })
  match =
    !!match || (location.pathname === '/' && action?.eventName === 'go-home')

  const actionTrigger = useActionTrigger()

  const config = (language_content?.[lng] || {}) as MobileBarMenuPropsConfig

  const selectCfg = useMemo(() => {
    if (match || isActived) {
      return {
        name: config.name_active || config.name || '',
        img: config.image_active || config.image || '',
        action: action_active,
      }
    } else {
      return {
        name: config.name || '',
        img: config.image || '',
        action: action,
      }
    }
  }, [match, isActived, action_active, action])

  const isBadge =
    selectCfg?.action?.eventName === 'navigation' &&
    selectCfg?.action?.href === '/referral'

  const {
    container: containerStyle,
    icon: iconStyle,
    badge: badgeStyle,
    text: textStyle,
  } = useCustomizedStyle(
    'AtomTabBarItem',
    {
      container: {
        userSelect: 'none',
        h: '100%',
        transition: 'transform .4s',
        _active: {
          transform: 'scale(1.2)',
        },
      },
      badge: {
        position: 'absolute',
        boxSize: '4',
        top: '0',
        right: '-8px',
        transform: 'translate3d(25%,-40%,0)',
      },
      icon: {
        boxSize: 7,
      },
      text: {
        textStyle: 'h7',
      },
    },
    tabBarItemParts,
  )

  return (
    <VStack
      flex='1'
      onClick={actionTrigger(selectCfg?.action || {})}
      sx={containerStyle}
      as='button'
      spacing='1.5'
      justifyContent='center'
    >
      <Box position='relative'>
        {isBadge ? (
          <LazyImage
            src={'/assets/images/tab-bar/coin-icon.png'}
            sx={badgeStyle}
          />
        ) : null}
        <LazyImage src={selectCfg.img} alt={selectCfg.name} sx={iconStyle} />
      </Box>
      <Text sx={textStyle}>{selectCfg.name}</Text>
    </VStack>
  )
}

function _TabBar() {
  const isLeftDrawerOpen = useAppSelector(
    createDrawerOpenSelector(DrawerKey.Left),
  )
  const { pathname } = useLocation()
  const isSport = pathname === '/user/sport'

  const homePageConfig = useContext(HomePageConfigContext)

  const tabList = useMemo(() => {
    return (homePageConfig?.tabBar || []).filter(item => item.status === 1)
  }, [homePageConfig])
  const [isShowMoreIcon, setIsShowMoreIcon] = useState(false)

  const { firstDrawTime } = useAppSelector(getCurrentUserRewardInfo)

  // 判断是否在大转盘活动中。
  const isInWheelActivity = pathname.includes('/activity/luckyWheel')

  useEffect(() => {
    luckyWheelEvents.on('hideMoreIcon', () => {
      setIsShowMoreIcon(false)
    })
    luckyWheelEvents.on('showMoreIcon', () => {
      setIsShowMoreIcon(true)
    })
    return () => {
      luckyWheelEvents.off('hideMoreIcon')
      luckyWheelEvents.off('showMoreIcon')
    }
  }, [])

  return (
    <Box position='relative' h='full'>
      {isShowMoreIcon && firstDrawTime && isInWheelActivity ? (
        <Box position='absolute' top='-32px' h='8' w='full'>
          <Flex
            position='absolute'
            h='full'
            justifyContent='center'
            w='full'
            bottom='0'
            background='linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)'
          >
            <Image w='6' h='6' src={DropdownIcon} />
          </Flex>
        </Box>
      ) : null}
      <HStack w='full' h='full' overflow='hidden' bg='bg.appLayout.tabBar'>
        {tabList.map(item => (
          <AtomTabBarItem
            {...item}
            isActived={item.key === 'sport' && isSport}
            key={`${item.id}`}
          />
        ))}
      </HStack>
    </Box>
  )
}

const tabBarItemParts = ['container', 'icon', 'badge', 'text'] as const

const TabBar = memo(_TabBar)

export default TabBar
