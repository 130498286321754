import { isFeatureEnabledV2 } from '@/common/featureSwiitch'
import VipLevelIconV3 from '@/modules/vip/components/VipLevelIcon'

import VipLevelIcon from '../new-vip/components/VipLevelIcon'
import VipLevelNumIcon from '../new-vip/components/VipLevelNumIcon'

interface Props {
  vipLevel: number
}

export default function UserProfilePopoverLevelIcon({ vipLevel }: Props) {
  const vipV3 = isFeatureEnabledV2('vip_v3_opt')
  return (
    <>
      {true ? (
        <VipLevelIconV3 level={vipLevel} />
      ) : (
        <VipLevelIcon level={vipLevel} />
      )}
      <VipLevelNumIcon level={vipLevel} />
    </>
  )
}
