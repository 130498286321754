import { Box, Flex, Text } from '@chakra-ui/react'
import { inRange } from 'lodash-es'

function ProgressBar({ current, total }: { current: number; total: number }) {
  const percent = current / total
  return (
    <Flex
      width='full'
      height='14px'
      position='relative'
      bg='rgba(0,0,0,0.2)'
      borderRadius='full'
      fontSize={0}
      justifyContent='center'
    >
      <Text
        display='inline-block'
        color='white'
        textStyle='text8'
        lineHeight='14px'
        fontSize='10px'
        position='absolute'
        zIndex='2'
        px='2'
      >
        {current}/{total}
      </Text>
      <Box
        display='inline-block'
        width={`${percent * 100 >= 100 ? 100 : percent * 100}%`}
        height='14px'
        bg='prim.500'
        borderRadius='full'
        zIndex='1'
        position='absolute'
        top='0'
        left='0'
      />
    </Flex>
  )
}

export default ProgressBar
