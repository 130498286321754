import {
  getWrongPhoneFormatMsg,
  isAllNumber,
  isValidPhoneNumber,
} from '@/utils/validform'

const emailPattern = new RegExp(
  '^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_-]+([.][a-zA-Z]+){1,2}$',
)

export async function handlePhoneValidator(value: any) {
  let errorMsg = ''
  if (!value) {
    errorMsg = 'DEPOSIT_INPUT_PHONE'
  } else {
    // 如果不是在输出邮箱 且不是合法的手机号
    if (!isValidPhoneNumber(value)) {
      // 如果不是全数字
      errorMsg = isAllNumber(value)
        ? getWrongPhoneFormatMsg(value)
        : 'WITHDRAW_EMAIL_RIGHT'
    }
  }
  if (errorMsg) {
    return Promise.reject(new Error(errorMsg))
  }

  return Promise.resolve()
}

export async function handleEmailValidator(value: any) {
  let errorMsg = ''
  if (!value) {
    errorMsg = 'PH_EMAIL_PLACE'
  } else {
    if (value.length > 200) {
      errorMsg = 'WITHDRAW_EMAIL_RIGHT'
    }
  }
  if (errorMsg) {
    return Promise.reject(new Error(errorMsg))
  }

  return Promise.resolve()
}

export async function handleRealEmailValidator(value: any) {
  let errorMsg = ''
  if (!value) {
    errorMsg = 'PH_EMAIL_PLACE'
  } else {
    if (value.length > 200 || !emailPattern.test(value)) {
      errorMsg = 'WITHDRAW_EMAIL_RIGHT'
    }
  }
  if (errorMsg) {
    return Promise.reject(new Error(errorMsg))
  }

  return Promise.resolve()
}

export async function handleComboAccountValidatorForReal(value: any) {
  let errorMsg = ''
  if (!value) {
    errorMsg = 'NULL_ALL'
  } else {
    const isInputEmail = value?.includes('@')
    if (isInputEmail && (value.length > 200 || !emailPattern.test(value))) {
      errorMsg = 'WITHDRAW_EMAIL_RIGHT'
    }

    // 如果不是在输出邮箱 且不是合法的手机号
    if (!isInputEmail && !isValidPhoneNumber(value)) {
      // 如果不是全数字
      errorMsg = isAllNumber(value)
        ? getWrongPhoneFormatMsg(value)
        : 'WITHDRAW_EMAIL_RIGHT'
    }
  }
  if (errorMsg) {
    return Promise.reject(new Error(errorMsg))
  }

  return Promise.resolve()
}

export async function handleComboAccountValidator(value: any) {
  let errorMsg = ''
  if (!value) {
    errorMsg = 'NULL_ALL'
  } else {
    const isInputEmail = value?.includes('@')
    if (isInputEmail && value.length > 200) {
      errorMsg = 'WITHDRAW_EMAIL_RIGHT'
    }

    // 如果不是在输出邮箱 且不是合法的手机号
    if (!isInputEmail && !isValidPhoneNumber(value)) {
      // 如果不是全数字
      errorMsg = isAllNumber(value)
        ? getWrongPhoneFormatMsg(value)
        : 'WITHDRAW_EMAIL_RIGHT'
    }
  }
  if (errorMsg) {
    return Promise.reject(new Error(errorMsg))
  }

  return Promise.resolve()
}

export async function handlePwdValidator(value: any) {
  let errorMsg = ''
  if (!value) {
    errorMsg = 'USER_INPUT_PWD2'
  } else {
    if (value.length < 8) {
      errorMsg = 'USER_PWD_OVERLENGTH'
    }
    if (value.length > 64) {
      errorMsg = 'USER_PWD_TOOLONG'
    }
  }
  if (errorMsg) {
    return Promise.reject(new Error(errorMsg))
  }

  return Promise.resolve()
}
