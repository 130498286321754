import { useCountDown } from 'ahooks'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import type { FC } from 'react'
import { useEffect, useMemo, useState } from 'react'

dayjs.extend(duration)

interface IProps {
  endOfType: 'day' | 'week' | 'month' | 'fixed'
  fixedTimeStamp?: number
}

const CountDownToEnd: FC<IProps> = ({ endOfType, fixedTimeStamp }) => {
  const targetDate = useMemo(() => {
    switch (endOfType) {
      case 'day':
      default:
        return dayjs()
          .startOf('day')
          .add(1, 'day')
          .format('YYYY-MM-DD HH:mm:ss')
      case 'week':
        // 如果今天時間是星期天，显示周一的倒计时
        if (dayjs().day() === 0) {
          return dayjs()
            .startOf('day')
            .add(1, 'day')
            .format('YYYY-MM-DD HH:mm:ss')
        }
        // 否则显示下周一的倒计时
        return dayjs()
          .startOf('week')
          .add(1, 'week')
          .add(1, 'day')
          .format('YYYY-MM-DD HH:mm:ss')
      case 'month':
        return dayjs()
          .startOf('month')
          .add(1, 'month')
          .format('YYYY-MM-DD HH:mm:ss')
      case 'fixed':
        return dayjs(fixedTimeStamp || 0).format('YYYY-MM-DD HH:mm:ss')
    }
  }, [endOfType, fixedTimeStamp])

  const [_, FormattedRes] = useCountDown({
    targetDate,
  })

  const current = useMemo(() => {
    const { days, hours, minutes, seconds } = FormattedRes
    // 将天数转换为小时
    const HH = String(days * 24 + hours).padStart(2, '0')
    const mm = String(minutes).padStart(2, '0')
    const ss = String(seconds).padStart(2, '0')
    return `${HH}:${mm}:${ss}`
  }, [FormattedRes])

  return <>{current}</>
}

export default CountDownToEnd
