import { pmLog } from '../pm-log'

export default function logRechargeApiSuccess() {
  pmLog({
    eventName: 'clientmetrics_event_counter',
    payload: {
      event: 'recharge_api_success',
      step: 1,
    },
    target: {
      labels: ['event'],
      value: 'step',
    },
  })
}
