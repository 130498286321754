import type { AuthQueryArgs } from '@/modules/user/userApi'
import { api } from '@/services/api'
import type { Response } from '@/services/types'

import type { AgentInfoResponseData } from './referralSlice'
import type { AnalyseData, InvitationRecord } from './types'

export const referralApi = api.injectEndpoints({
  endpoints: builder => ({
    getClientAnalyse: builder.query<
      Response<AnalyseData>,
      AuthQueryArgs & { type: string; date: string; currency: string }
    >({
      query: data => {
        return {
          url: '/user/client_analyse',
          method: 'POST',
          data,
        }
      },
      providesTags: ['Auth'],
    }),
    agentAchieve: builder.query<Response<any>, { currency: string }>({
      query: data => {
        return {
          url: `/api/affilate/accomplishment/config/${data.currency}`,
          method: 'GET',
          data,
        }
      },
    }),
    getAgentCfg: builder.query<
      Response<AgentInfoResponseData>,
      { currency: string }
    >({
      query: data => {
        return {
          url: `/api/affilate/spread/config/${data.currency}`,
          method: 'GET',
          data,
        }
      },
    }),
    getInvitationRecords: builder.query<
      Response<{ count: number; data: InvitationRecord[] }>,
      AuthQueryArgs & {
        size?: number
        //  created_at?: string
        // page_last?: string
        pos?: number
        spread_type: 'recharge' | 'bet_daily'
        //currency?: string
      }
    >({
      query: ({
        size = 10,
        pos = 0,
        //  page_last = '',
        // created_at = '',
        ...data
      }) => {
        return {
          url: '/user/list_spread_award',
          params: {
            size,
            pos,
            //page_last,
            //created_at,
          },
          method: 'POST',
          data,
        }
      },
    }),
  }),
})

export const {
  useGetClientAnalyseQuery,
  useGetInvitationRecordsQuery,
  useAgentAchieveQuery,
  useGetAgentCfgQuery,
} = referralApi
