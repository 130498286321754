import type { ButtonProps } from '@chakra-ui/react'
import { Box, Spinner, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { useMemo } from 'react'

import * as Images from '../image-imports'

type Props = ButtonProps & { disabledText?: string }

function DrawButton({ onClick, isDisabled, isLoading, disabledText }: Props) {
  const content = useMemo(() => {
    return isDisabled ? disabledText : t('HOME_PLAY')
  }, [isDisabled, disabledText])

  return (
    <Box
      as='button'
      onClick={onClick}
      pb='16px'
      userSelect='none'
      transformOrigin='bottom'
      transition='transform 0.2s ease-in-out'
      cursor='pointer'
      disabled={isDisabled}
      role='group'
      display='block'
      mt='26px'
      mx='auto'
      bg={`no-repeat center / 100% 100% url(${Images.playBtnBg})`}
      w='190px'
      h='60px'
      _disabled={{
        bg: `no-repeat center / 100% 100% url(${Images.playBtnDisabledBg})`,
        w: '235px',
        h: '60px',
        bgSize: 'cover',
        pointerEvents: 'none',
      }}
      _focusVisible={{ outline: 'none' }}
      _active={{
        transform: 'perspective(4rem) rotateX(2deg) translateY(4px)',
      }}
    >
      {isLoading ? (
        <Box
          transformOrigin='bottom'
          transform='perspective(10px) rotateX(4deg)'
        >
          <Spinner thickness='4px' color='#FFFFFF' size='lg' />
        </Box>
      ) : (
        <Text
          textStyle='h2'
          color='#9b4c12'
          transform='perspective(1rem) rotateX(5deg)'
          cursor='inherit'
          _groupDisabled={{
            textStyle: 'h7',
            color: '#fff',
            transform: 'perspective(5rem) rotateX(5deg)',
          }}
        >
          <span>{content}</span>
        </Text>
      )}
    </Box>
  )
}

export default DrawButton
