import { useLargerThanMobile } from '@/utils/hooks/useLargerThanMobile'

import { DesktopGroupGame } from './DesktopGroupGame'
import type { GroupGameProps } from './GroupGameProps'
import { MobileGroupGame } from './MobileGroupGame'

export function GroupGames(props: GroupGameProps) {
  const isLargerThanMobile = useLargerThanMobile()

  if (isLargerThanMobile) {
    return <DesktopGroupGame {...props} />
  } else {
    return <MobileGroupGame {...props} />
  }
}
