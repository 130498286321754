import { useMemo } from 'react'

import { useAppSelector } from '@/modules/app/store'

import { selectRechargeActList } from './ActSlice'
import { useRechargeInfo } from './useRechargeInfo'

export enum RECHARGE_ACTIVITY_TYPE {
  DEFAULT,
  NONE,
  RECHARGE_PACKAGE,
  RECHARGE_ACTIVITY,
}

export default function useRechargeActEntry() {
  const { handleActStatus } = useRechargeInfo()
  const rechargeActList = useAppSelector(selectRechargeActList) || []

  // 用户可以参与的排序最高的充值活动
  const actInfo = useMemo(() => {
    let info = null
    for (let i = 0; i < rechargeActList.length; i++) {
      const cur = rechargeActList[i]
      const { verify } = handleActStatus && handleActStatus(cur)
      if (verify) {
        info = cur
        break
      }
    }
    return info
  }, [rechargeActList])

  return {
    actInfo,
  }
}
