import { Box, Button, Checkbox, Link, Text } from '@chakra-ui/react'
import { t } from 'i18next'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { closeModal, openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'

import { useLoginAndSignUpStyles } from '../../context/login-sign-context'
import { LoginModalTab } from '../../LoginModalTab'
import {
  selectBonusShowPosition,
  selectBonusText,
} from '../newLoginAndSignUpConfigSlice'

export interface SignUpCommonRef {
  showAcceptServiceTip: () => void
}

export const Bonus = () => {
  const bonusText = useAppSelector(selectBonusText)

  // 如果是新登录注册，那么就显示新的注册奖励文案
  const text = bonusText

  const newLoginAndSignUpBonusStyle = {
    left: '50%',
    top: '-30px',
    transform: 'translateX(-50%)',
  }

  return (
    <Box
      position={'absolute'}
      {...newLoginAndSignUpBonusStyle}
      minWidth='110px'
      paddingX={1}
      borderRadius='12px'
      bgColor='prim.500'
      height='24px'
      _after={{
        content: "''",
        position: 'absolute',
        left: '50%',
        top: '100%',
        width: '0',
        height: '0',
        borderLeft: '6px solid transparent',
        borderRight: '4px solid transparent',
        borderTop: '4px solid var(--chakra-colors-prim-500)',
      }}
    >
      <Text fontSize='xs' color='white' lineHeight='24px' textAlign='center'>
        🎁 {text}
      </Text>
    </Box>
  )
}

const SignUpCommon = forwardRef(
  (
    {
      isLoading,
      onAgreeOnStateChange,
      type,
    }: {
      isLoading: boolean
      onAgreeOnStateChange: (agreeOnState: boolean) => void
      type: 'email' | 'phone'
    },
    ref,
  ) => {
    const loginAndSignUpStyles = useLoginAndSignUpStyles()
    const dispatch = useAppDispatch()
    /** 协议勾选状态 */
    const [agreeOnState, setAgreeOnState] = useState(false)

    /**
     * 是否显示接受服务条款提示
     */
    const [isShowAcceptService, setIsShowAcceptService] = useState(false)

    /**
     * 点击服务条款
     */
    const handleService = () => {
      dispatch(closeModal({ key: GlobalModalKey.Login }))
    }

    useImperativeHandle(ref, () => ({
      showAcceptServiceTip: () => {
        setIsShowAcceptService(true)
      },
    }))

    useEffect(() => {
      onAgreeOnStateChange(agreeOnState)
    }, [agreeOnState, onAgreeOnStateChange])

    const bonusShowPosition = useAppSelector(selectBonusShowPosition) ?? []

    const isShowBonus = bonusShowPosition.includes('registerBtn')

    return (
      <>
        <Box py={5} px={3.5} sx={loginAndSignUpStyles.SignupForm}>
          <Checkbox
            variant='signup'
            isChecked={agreeOnState}
            onChange={e => {
              setAgreeOnState(e.target.checked)
              if (e.target.checked) {
                setIsShowAcceptService(false)
              } else {
                setIsShowAcceptService(true)
              }
            }}
          >
            {t('USER_CONSENT_SIGNUP')} &nbsp;
            <Link
              as={RouterLink}
              to='/help/terms_and_condition'
              onClick={handleService}
            >
              {t('USER_TERMS')}
            </Link>
          </Checkbox>
          {isShowAcceptService ? (
            <Text textAlign='right' color='#F43434' textStyle='text4'>
              {t('USER_ACCEPT_TERMS')}
            </Text>
          ) : null}
        </Box>
        <Box px={3.5} sx={loginAndSignUpStyles.SignupForm}>
          <Checkbox variant='signup' defaultChecked>
            {t(type === 'email' ? 'USER_ACCEPT_PROMOS' : 'USER_ACCEPT_PROMOS1')}
          </Checkbox>
        </Box>
        <Box mt={10} position={'relative'}>
          {isShowBonus ? <Bonus /> : null}
          <Box
            position={'absolute'}
            left={'100px'}
            top={'-6px'}
            width={0}
            height={0}
            overflow='hidden'
          />
          <Button
            size='lg'
            width='100%'
            colorScheme='fill-prim'
            type='submit'
            isLoading={isLoading}
          >
            {t('USER_CREATE_ACCOUNT')}
          </Button>
        </Box>

        <Box w='full' textAlign='center' mt='5'>
          <Text textStyle='text4' color='gray.700'>
            {t('USER_DO_HAVE_ACCOUNT')} &nbsp;
            <Link
              onClick={() => {
                dispatch(
                  openModal({
                    key: GlobalModalKey.Login,
                    data: {
                      index: LoginModalTab.SignIn,
                    },
                  }),
                )
              }}
            >
              {t('USER_LOGIN')}
            </Link>
          </Text>
        </Box>
      </>
    )
  },
)

SignUpCommon.displayName = 'SignUpCommon'

export default SignUpCommon
