import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  Flex,
  Grid,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import isMobile from 'ismobilejs'
import { QRCodeSVG } from 'qrcode.react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ticket2Icon from '@/assets/images/lucky-wheel/ticke2.png'
import ticketIcon from '@/assets/images/lucky-wheel/ticket.png'
import facebookIcon from '@/assets/images/share/icon-share-facebook.png'
import messageIcon from '@/assets/images/share/icon-share-message.png'
import telegramIcon from '@/assets/images/share/icon-share-telegram.png'
import twitterIcon from '@/assets/images/share/icon-share-twitter.png'
import whatsappIcon from '@/assets/images/share/icon-share-whatsapp.png'
import { isFeatureEnabled } from '@/common/feature'
import CopyInput from '@/components/input/CopyInput'
import LazyImage from '@/components/lazy-image/LazyImage'
import TitledDialog from '@/components/modal/TitledDialog'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  closeModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { selectUserInviteUrl } from '@/modules/new-referral/referralSlice'
import { useUserAuth } from '@/utils/hooks'
import { projectName } from '@/websites/current/property.json'

import ApanaloSharedHeader from './components/ApanaloShareHeader'
import LandingPage from './LandingPage'

const { phone: isPhone } = isMobile(window.navigator)

function NormalShareHeader() {
  const { data: shareArr } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.SharesModal],
  )
  const scene = useMemo(() => {
    return shareArr?.scene ?? 'landingPage'
  }, [shareArr])
  const { t } = useTranslation()
  const shareTitle = useMemo(() => {
    const titleMap = {
      landingPage: t('INVITE_FRIEND_2'),
      successWithDrawModal: t('SHARE_INVITE_FREE_BONUS', {
        amount: shareArr?.amount ?? 0,
      }),
      drawActivity: t('INVITE_SPIN_AGAIN'),
    }
    return titleMap[scene] || titleMap['landingPage']
  }, [scene, shareArr?.amount, t])

  const shareIcon = useMemo(() => {
    const iconMap = {
      landingPage: ticketIcon,
      successWithDrawModal: ticket2Icon,
      drawActivity: ticketIcon,
    }
    return iconMap[scene] || iconMap['landingPage']
  }, [scene])
  return (
    <Flex justifyContent='space-between' alignItems='center' p='0 4px'>
      <Image src={shareIcon} w='48px' h='48px' />
      <Text textStyle='h6' color='gray.900' ml='4'>
        {shareTitle}
      </Text>
    </Flex>
  )
}

function SharedModal() {
  const { open, data: shareArr } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.SharesModal],
  )

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const scene = useMemo(() => {
    return shareArr?.scene ?? 'landingPage'
  }, [shareArr])

  const handleClose = () => {
    dispatch(closeModal({ key: GlobalModalKey.SharesModal }))
    const params = new URLSearchParams(window.location.search)
    if (params.get('action')) {
      params.delete('action')
      window.history.replaceState(null, '', `${window.location.pathname}`)
    }
  }

  const [user_id, _, userName] = useUserAuth()

  const shareUrl = useAppSelector(selectUserInviteUrl)
  const activity = shareArr?.activity ?? 'luckyWheel'
  const activityId = shareArr?.activityId

  const shareLink = useMemo(() => {
    const tempUrl = shareUrl || window.location.origin
    return (
      tempUrl +
      `/?u=${window.btoa(
        `referralcode=${user_id}&userName=${userName.slice(
          0,
          4,
        )}...${userName.slice(-3)}&fromActivity=${activity}${
          activityId ? `&fromActivityId=${activityId}` : ''
        }&t=${new Date().getTime()}`,
      )}`
    )
  }, [shareUrl, user_id, userName, activity, activityId])

  const landingPageLink = useMemo(() => {
    return window.location.origin + '/activity/luckyWheel'
  }, [])

  const drawActivityLink = useMemo(() => {
    // return window.location.origin + '?openModal=drawActivity
    // 先确认当前路径是否有参数，有则直接加上openModal参数，没有则加上?openModal参数
    const url = window.location.href
    const hasParams = url.includes('?')
    const prefix = hasParams ? '&' : '?'
    return url + `${prefix}openModal=drawActivity&activityId=${activityId}`
  }, [activityId])

  const qrCodeValue = useMemo(() => {
    const qrCodeMap = {
      luckyWheel: landingPageLink,
      drawActivity: drawActivityLink,
    } as Record<string, string>
    return qrCodeMap[activity] || qrCodeMap['luckyWheel']
  }, [activity, drawActivityLink, landingPageLink])

  const shareRender = (icon: string, url: string, text: string) => {
    return (
      <Flex
        as='a'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        key={text}
        href='javascript:void(0)'
        onClick={() => {
          // window.open(url, '_blank')
          window.open(url, '_self')
        }}
      >
        <LazyImage src={icon} w='10' h='10' />
        <Text mt='4px' textAlign='center' color='gray.700' fontSize='10px'>
          {text}
        </Text>
      </Flex>
    )
  }

  const shareOperatorArrRender = useMemo(() => {
    return [
      shareArr?.facebook &&
        shareRender(facebookIcon, shareArr.facebook, 'Facebook'),
      shareArr?.whatsapp &&
        shareRender(whatsappIcon, shareArr.whatsapp, 'WhatsApp'),
      shareArr?.telegram &&
        shareRender(telegramIcon, shareArr.telegram, 'Telegram'),
      shareArr?.twitter &&
        shareRender(twitterIcon, shareArr.twitter, 'Twitter'),
      shareArr?.email && shareRender(messageIcon, shareArr.email, 'Email'),
    ]
  }, [shareArr])

  const shareTitle = useMemo(() => {
    const titleMap = {
      landingPage: t('INVITE_FRIEND_2'),
      successWithDrawModal: t('SHARE_INVITE_FREE_BONUS', {
        amount: shareArr?.amount ?? 0,
      }),
      drawActivity: t('INVITE_SPIN_AGAIN'),
    }
    return titleMap[scene] || titleMap['landingPage']
  }, [scene, shareArr?.amount, t])

  if (!isPhone) {
    return (
      <TitledDialog
        title={shareTitle}
        isOpen={open}
        onClose={handleClose}
        size='sm'
      >
        <Box
          position='relative'
          h='194px'
          w='194px'
          display='flex'
          alignItems='center'
          justifyContent='center'
          mx='auto'
        >
          <QRCodeSVG
            level={'Q'}
            includeMargin={false}
            value={qrCodeValue}
            size={170}
          />
        </Box>
        <Box mt='3'>
          <CopyInput text={shareLink} colorScheme='field' />
        </Box>
        <Text
          m='auto'
          pt={4}
          fontSize={12}
          // maxW='240px'
          lineHeight={'16px'}
          fontWeight='600'
          color='gray.700'
          textAlign='center'
        >
          {t('WEB_SHARE_SPIN')}
        </Text>

        <Button
          mt='4'
          h='46px'
          w='full'
          colorScheme='prim'
          onClick={() => {
            handleClose()
          }}
        >
          {t('COMMON_CONFIRM')}
        </Button>
      </TitledDialog>
    )
  }

  return (
    <Drawer isOpen={open} onClose={handleClose} placement='bottom' variant=''>
      <DrawerContent borderRadius='16px 16px 0 0' bg='gray.50'>
        <DrawerCloseButton />
        <DrawerBody padding='36px 20px 20px'>
          <Box>
            {isFeatureEnabled('enableApanaloWheel') ? (
              <ApanaloSharedHeader scene={scene} />
            ) : (
              <NormalShareHeader />
            )}
            {/* 替换成后台配置的分享渠道 */}
            <Grid templateColumns='repeat(5, 1fr)' gap='3' mt='4'>
              {shareOperatorArrRender?.map(item => item)}
            </Grid>

            <Box mt='5'>
              <CopyInput text={shareLink} colorScheme='field' />
            </Box>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default SharedModal
