export function getLoginCodeErrorMessage(code: string) {
  const errorMessageMap = {
    100001: 'ACORPW_ERROR',
    100004: 'VERIFY_CODE_ERR',
    100005: 'GET_VERIfY_CODE_TOO_FAST',
    100006: 'VERIFY_CODE_ERR2',
    20: 'USER_BANNED_ERROR_MSG',
    23: 'IP_BANNED_ERROR_MSG',
  } as Record<string, string>
  return errorMessageMap[code] ?? ''
}
