import type { ComponentStyleConfig } from '@chakra-ui/react'

import {
  NAME,
  PARTS,
} from '@/modules/inhouse/common/components/mines/MinesSelector'

const parts = [...PARTS]
const componentStyle: ComponentStyleConfig = {
  parts,
  baseStyle: {
    radio: {
      bgColor: 'gray.400',
      color: '#fff',
    },
  },
}

export const name = NAME
export default componentStyle
