import getIsSupportPmsdk from '@/utils/features/get-is-support-pmsdk'

interface Target {
  /** 维度keys */
  labels: string[]

  /** 指标含义 如 fps, time_spent */
  value?: string
}

interface PmLogArgs {
  eventName: string
  payload: Record<string, any>
  target: Target
}

export function pmLog({ eventName, payload, target }: PmLogArgs): void {
  if (getIsSupportPmsdk(window.pmSdk)) {
    window.pmSdk.log(eventName, payload, target)
  }
}
