import { Button } from '@chakra-ui/react'

import type { WalletButtonProps } from '@/modules/wallet/interface'

export default function WalletButton({
  children,
  loading = false,
  onClick,
  colorScheme = 'fill-seco',
  disabled = false,
}: WalletButtonProps) {
  return (
    <Button
      colorScheme={colorScheme}
      size='lg'
      w='full'
      isLoading={loading}
      loadingText='Loading'
      onClick={onClick}
      _hover={{
        colorScheme,
      }}
      isDisabled={disabled}
    >
      {children}
    </Button>
  )
}
