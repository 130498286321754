import { Container } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useLocation, useMatch } from 'react-router-dom'

export default function MainContainer(props: { children: React.ReactNode }) {
  const { pathname } = useLocation()
  const matchActivity = useMatch('/activity/:actType/*')
  const isActivtiyRoute = !!matchActivity
  const style = useMemo(() => {
    const fullScreenStyle = {
      maxWidth: 'inherit',
      minHeight: 'lg',
      padding: '0',
    }
    if (['/promotions', '/cashback'].includes(pathname)) {
      return {
        maxWidth: 'container.lg',
        minHeight: 'lg',
        pb: '6',
        px: 0,
      }
    }
    if (pathname === '/vip/interest') {
      return { maxWidth: 'inherit', px: 0, minHeight: 'lg', pb: '6' }
    }
    if (pathname === '/user/sport') {
      fullScreenStyle.padding = '5px'
      return fullScreenStyle
    } else if (isActivtiyRoute || pathname === '/vip') {
      return fullScreenStyle
    } else {
      return {
        maxWidth: 'container.lg',
        minHeight: 'lg',
        pb: '6',
        px: { base: '2.5', md: '0' },
      }
    }
  }, [pathname, isActivtiyRoute])

  return (
    <Container
      position='relative'
      as='main'
      boxSizing='content-box'
      overflowX='hidden'
      sx={style}
    >
      {props.children}
    </Container>
  )
}
