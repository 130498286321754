import { type FC, Suspense, type SuspenseProps } from 'react'

import Loading from '@/routes/Loading'

import ErrorBoundary from '../error/ErrorBoundary'

const CustomSuspense: FC<SuspenseProps> = ({ children, ...props }) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>{children}</Suspense>
    </ErrorBoundary>
  )
}

export default CustomSuspense
