import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import privateTitleIconPng from '@/assets/images/private-title-icon.png'
import i18n from '@/common/locale/i18n'
import LazyImage from '@/components/lazy-image/LazyImage'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { formatLocalMoney, getConfigLng } from '@/utils/tools'

import { useGetRechargeTaskPrivateSwitchQuery } from '../activity/activityApi'
import type { RechargeActivityProps } from './interface'
import useRechargeActivity from './useRechargeActivity'

export default function RechargeTaskPrivate({
  activedItem,
  rechargeAmount,
}: {
  activedItem: RechargeActivityProps
  rechargeAmount: number
}) {
  const { t } = useTranslation()
  const [isShowDetail, setIsShowDetail] = useState(false)
  const { data } = useGetRechargeTaskPrivateSwitchQuery()

  const { getBonusByAmount } = useRechargeActivity()
  const lng = getConfigLng()

  const actActivitedItem = useMemo(() => {
    try {
      const param = JSON.parse(data.data.param)
      if (data?.data?.enable === 1 && activedItem.id === param.id) {
        return activedItem
      }
    } catch (error) {
      return null
    }
  }, [data, activedItem])

  const amountDesc = useMemo(() => {
    const bonusAmount = getBonusByAmount(rechargeAmount)

    return `[${formatLocalMoney(rechargeAmount)}+${formatLocalMoney(
      bonusAmount,
    )} = ${formatLocalMoney(rechargeAmount + bonusAmount)}]`
  }, [rechargeAmount])

  const content = (actActivitedItem as any)?.meta?.language_content?.[lng] || {}

  return actActivitedItem ? (
    <Box
      my={3}
      backgroundColor={'var(--chakra-colors-bg-control)'}
      borderRadius='10px'
      p='6px 12px 10px'
    >
      <Flex alignItems='center' justifyContent='space-between' mb='5px'>
        <Flex alignItems='center'>
          <LazyImage w='24px' h='24px' src={privateTitleIconPng} />
          <Text textStyle='text6' wordBreak='break-word' ml='4px'>
            {t('RG_DESPOSITE_MESS')}:
            <Text
              as='span'
              textStyle='h7'
              color='four.400'
            >{` ${amountDesc}`}</Text>
          </Text>
          {/* <Text textStyle={'h6'} ml='4px' color='white'>
            <Text
              as='span'
              className='ck-content'
              wordBreak='break-word'
              dangerouslySetInnerHTML={{
                __html: content.active_name,
              }}
            />
          </Text> */}
          <Icon
            // position='absolute'
            // top='0'
            // right='0'
            w='20px'
            h='20px'
            as={!isShowDetail ? SharedIcon.Expand : SharedIcon.Retract}
            onClick={e => {
              e.preventDefault()
              setIsShowDetail(!isShowDetail)
            }}
          />
        </Flex>
      </Flex>
      <Box
        mt='5px'
        position='relative'
        h={isShowDetail ? 'auto' : '20px'}
        overflow='hidden'
      >
        <Text
          as='div'
          className='ck-content'
          wordBreak='break-word'
          dangerouslySetInnerHTML={{
            __html: content.active_name,
          }}
        />
      </Box>
    </Box>
  ) : null
}
