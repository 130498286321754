import { Box, Center, HStack, Text, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import hotStickers from '@/assets/images/recharge-comfirm-modal/hot-stickers.png'
import { isFeatureEnabled } from '@/common/feature'
import LazyImage from '@/components/lazy-image/LazyImage'
import AtomModal, {
  createModal,
  useAtomModal,
} from '@/utils/atom-modal/AtomModal'
import { formatLocalMoney } from '@/utils/tools'

import { itemAssets } from './ListItem'
import type { ItemData } from './RechargeComfirmModal'

// eslint-disable-next-line react-refresh/only-export-components
export const NAME = 'RechargeBonusDetailModal'

interface Props {
  list: ItemData[]
}

const rechargeActivityV2 = isFeatureEnabled('rechargeActivityV2')

const RechargeBonusDetailModal = createModal(NAME, (props: Props) => {
  const { list } = props
  const { t } = useTranslation()
  const { hide } = useAtomModal(NAME)

  const fieldRender = (label: string, number: string) => (
    <Box textStyle='text5' noOfLines={1}>
      <Text as='span' color='text.base' mr='2'>
        {label}
      </Text>
      <Text as='span' color='text.accent'>
        {number}
      </Text>
    </Box>
  )

  const itemRender = (item: ItemData, index: number) => {
    const bgImage = itemAssets[index % itemAssets.length].detailImageBg
    return (
      <HStack key={item.id} spacing='5'>
        <Center
          flexShrink='0'
          borderRadius='2xl'
          boxSize='80px'
          bg={bgImage}
          position='relative'
          p='2'
        >
          {index === 0 && (
            <LazyImage
              src={hotStickers}
              alt=''
              boxSize='40px'
              objectFit='contain'
              position='absolute'
              top='-12px'
              right='-10px'
            />
          )}
          <LazyImage src={item.image} alt='' />
        </Center>
        <Box flex='1'>
          <Text textStyle='h4' color='text.money' mb='1' noOfLines={1}>
            {item.title}
          </Text>
          <VStack spacing='0.5' alignItems='flex-start' overflow='hidden'>
            {fieldRender(
              t('MINIMUM_DEPOSIT'),
              formatLocalMoney(item.minRechargeAmount),
            )}
            {fieldRender(
              t('GET_UP_TO'),
              formatLocalMoney(item.maxRechargeAmount),
            )}
            {fieldRender(
              t('REACHRGA_TASK_MODAL_TIME'),
              `${item.currentNumber}/${item.joinNumber}`,
            )}
          </VStack>
        </Box>
      </HStack>
    )
  }

  const newItemRender = (item: ItemData, index: number) => {
    const bgImage = itemAssets[index % itemAssets.length].detailImageBg
    return (
      <HStack key={item.id} spacing='5'>
        <Center
          flexShrink='0'
          borderRadius='2xl'
          boxSize='80px'
          bg={bgImage}
          position='relative'
          p='2'
        >
          {index === 0 && (
            <LazyImage
              src={hotStickers}
              alt=''
              boxSize='40px'
              objectFit='contain'
              position='absolute'
              top='-12px'
              right='-10px'
            />
          )}
          <LazyImage src={item.image || ''} alt='' />
        </Center>
        <Box flex='1'>
          <Text textStyle='h4' color='text.money' mb='1' noOfLines={1}>
            {item.title}
          </Text>
          <VStack spacing='0.5' alignItems='flex-start' overflow='hidden'>
            {fieldRender(
              t('MINIMUM_DEPOSIT'),
              formatLocalMoney(item.minRechargeAmount),
            )}
            {fieldRender(
              t('GET_UP_TO'),
              formatLocalMoney(item.maxRechargeAmount),
            )}
            {fieldRender(
              t('REACHRGA_TASK_MODAL_TIME'),
              `${item.currentNumber}/${item.joinNumber}`,
            )}
          </VStack>
        </Box>
      </HStack>
    )
  }

  return (
    <AtomModal
      size='sm'
      type='title'
      title={t('DEPOSIT_BONUS_RULES_TITLE')}
      id={NAME}
      onClose={() => hide()}
    >
      <Text textStyle='text4' color='text.base' mb='5'>
        {t('DEPOSIT_BONUS_RULES_DSC')}
      </Text>
      <VStack alignItems='stretch' spacing='4'>
        {list.map(rechargeActivityV2 ? newItemRender : itemRender)}
      </VStack>
    </AtomModal>
  )
})

export default RechargeBonusDetailModal
