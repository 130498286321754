import { Box, Flex, Text, useToast } from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import TitledDialog from '@/components/modal/TitledDialog'
import { useAppDispatch } from '@/modules/app/store'
import { closeModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { localStorage } from '@/utils/localStorage'

import useVerifyCode from '../hooks/useVerifyCode'
import {
  AccountType,
  UnloginVerifyCodeType,
  useResetPasswordByCodeMutation,
  useVerifyCodeUnloginMutation,
} from '../loginApi'
import { getLoginCodeErrorMessage } from '../tools'
import {
  handleComboAccountValidatorForReal,
  handlePwdValidator,
} from '../utils'
import ComboAccountFormItem from './ComboAccountFormItem'
import EmailFormItem from './EmailFormItem'
import type { FormRef } from './Form'
import Form from './Form'
import PhoneFormItem from './PhoneFormItem'
import PwdFormItem from './PwdFormItem'
import VerifyCodeFormItem from './VerifyCodeFormItem'

type ForgetPwdModalProps = {
  isOpen: boolean
  onClose: () => void
  accountValue?: string
  accountType?: AccountType
}

const MODAL_STEP_CONFIG = {
  first: {
    title: 'USER_RESET_PWD',
    btnText: 'NEXT_STEP_BUTTON',
  },
  sec: {
    title: 'CHANGE_PASSWORD',
    btnText: 'NEXT_STEP_BUTTON',
  },
  third: {
    title: 'CHANGE_PASSWORD',
    btnText: 'PH_CONFIRM_BTN',
  },
  finally: {
    title: 'USER_RESET_PWD_SUCCESS1',
    btnText: 'OK_BUTTON',
  },
}

export default function ForgetPwdModal({
  accountValue,
  accountType,
  isOpen,
  onClose,
}: ForgetPwdModalProps) {
  const formRef = useRef<FormRef>(null)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()
  const [verifyType, setVerifyType] = useState<
    | UnloginVerifyCodeType.vctPasswordResetEmail
    | UnloginVerifyCodeType.vctPasswordResetPhone
  >(
    UnloginVerifyCodeType[
      accountType === AccountType.AT_EMAIL
        ? 'vctPasswordResetEmail'
        : 'vctPasswordResetPhone'
    ],
  )
  const [type, setType] = useState<'email' | 'phone'>(
    accountType === AccountType.AT_EMAIL ? 'email' : 'phone',
  )
  const [modalStep, setModalStep] = useState<
    'first' | 'sec' | 'third' | 'finally'
  >(accountValue ? 'sec' : 'first')

  const { t } = useTranslation()
  const toast = useToast()

  /** 校验验证码 */
  const [verifyCode, { isLoading: isVerifying }] =
    useVerifyCodeUnloginMutation()

  /** 更改密码 */
  const [resetPassword, { isLoading: isReseting }] =
    useResetPasswordByCodeMutation()

  const handlePrecalibration = async () => {
    return (await formRef.current?.validateFormItem('account_value')) || false
  }

  const account = () => {
    return formRef.current?.getFieldValue('account_value')
  }

  const { BtnRender, flowId } = useVerifyCode({
    handlePrecalibration,
    unLoginType: verifyType,
    account,
  })

  const handleClose = () => {
    if (modalStep === 'finally') {
      dispatch(
        closeModal({
          key: GlobalModalKey.WrongPassword,
        }),
      )
    }
    onClose()
  }

  const handleSubmit = async (
    value: {
      verify_type: UnloginVerifyCodeType
      verify_code: string
      password: string
    } & Record<string, any>,
  ) => {
    setIsLoading(true)
    if (modalStep === 'first') {
      handleAccountType()
      setModalStep('sec')
      setIsLoading(false)
      return
    }

    if (modalStep === 'sec') {
      /**
       * todo 登录注册
       * 验证验证码 verifyCodeUnlogin
       * 入参 verifyType flowId value.verify_ode
       */
      try {
        await verifyCode({
          ...value,
          flow_id: flowId || '',
          verify_type: verifyType,
        }).unwrap()
        localStorage.removeItem(`unlogin_verify_code_${value.verify_type}`)
      } catch (e: any) {
        const title = t(
          getLoginCodeErrorMessage(e.status) || 'ABNORMAL_OPERATION',
        )
        toast({
          title,
          status: 'error',
        })
        setIsLoading(false)
        return
      }

      setModalStep('third')
      setIsLoading(false)
      return
    }

    if (modalStep === 'third') {
      /**
       * todo 登录注册
       * 调用重置密码接口 resetPasswordByCode
       * 入参 flowId value.password
       */
      try {
        await resetPassword({ ...value, flow_id: flowId || '' })
      } catch (e: any) {
        const title = t(
          getLoginCodeErrorMessage(e.status) || 'ABNORMAL_OPERATION',
        )
        toast({
          title,
          status: 'error',
        })
        setIsLoading(false)
        return
      }
      setModalStep('finally')
      setIsLoading(false)
      return
    }
    handleClose()
    return
  }

  const handleAccountType = () => {
    const type = formRef.current?.getFieldValue('account_value').includes('@')
      ? 'email'
      : 'phone'

    setVerifyType(
      UnloginVerifyCodeType[
        type === 'email' ? 'vctPasswordResetEmail' : 'vctPasswordResetPhone'
      ],
    )
    setType(type)
  }

  const firstStepRender = () => {
    return (
      <ComboAccountFormItem
        label={t('ENTER_ACCOUNT_PASSWORD')}
        fieldProps={{ placeholder: t('ENTER_ACCOUNT').toString() }}
        name='account_value'
        isNeedAddonBefore={false}
        rules={[{ validator: handleComboAccountValidatorForReal }]}
      />
    )
  }

  const secStepRender = () => {
    return (
      <>
        {type === 'email' ? (
          <EmailFormItem
            label={t('USER_EMAIL')}
            name='account_value'
            disabled={true}
          />
        ) : (
          <PhoneFormItem
            label={t('HISTORY_PHONE')}
            name='account_value'
            disabled={true}
          />
        )}
        <Flex>
          <VerifyCodeFormItem
            label={t('PHONE_CODELABEL')}
            name='verify_code'
            rules={[{ required: true, message: 'USER_RESET_PW_CODE_INPUT' }]}
            fieldProps={{ placeholder: t('PHONE_CODELABEL').toString() }}
          />
          <Box mt='26px'>
            <BtnRender />
          </Box>
        </Flex>
      </>
    )
  }

  const handlePrePwdValidator = async (value: string) => {
    if (value !== formRef.current?.getFieldValue('password')) {
      return Promise.reject(new Error('USER_PWD_NOT_MATCH'))
    }
    return Promise.resolve()
  }

  const thirdStepRender = () => {
    return (
      <>
        <PwdFormItem
          name='password'
          label={t('USER_NEW_PWD')}
          fieldProps={{ placeholder: t('USER_NEW_PWD').toString() }}
          rules={[{ validator: handlePwdValidator }]}
          isPwdLevelShow
        />
        <PwdFormItem
          name='prePassword'
          label={t('CONFIRM_NEW_PASSWORD')}
          rules={[
            { validator: handlePwdValidator },
            {
              validator: handlePrePwdValidator,
            },
          ]}
          fieldProps={{
            name: 'prePassword',
            placeholder: t('CONFIRM_NEW_PASSWORD').toString(),
          }}
        />
      </>
    )
  }

  const finallyStepRender = () => {
    return (
      <Text textStyle='text5' px='55px' py='30px' textAlign='center'>
        {t('USER_RESET_PWD_SUCCESS')}
      </Text>
    )
  }

  const handleFieldsChange = (changedFields: Record<string, any>) => {
    if (
      changedFields?.password &&
      changedFields?.password === formRef.current?.getFieldValue('prePassword')
    ) {
      formRef.current?.validateFormItem('prePassword')
    }
  }

  return (
    <TitledDialog
      isOpen={isOpen}
      onClose={handleClose}
      title={t(MODAL_STEP_CONFIG[modalStep].title)}
      size='sm'
    >
      <Form
        key='ForgetPwdModal'
        initialValues={{
          account_value: accountValue,
        }}
        ref={formRef}
        isLoading={isLoading || isVerifying || isReseting}
        onSubmit={handleSubmit}
        submitBtnText={t(MODAL_STEP_CONFIG[modalStep].btnText).toString()}
        onFieldsChange={handleFieldsChange}
      >
        {modalStep === 'first' && firstStepRender()}
        {modalStep === 'sec' && secStepRender()}
        {modalStep === 'third' && thirdStepRender()}
        {modalStep === 'finally' && finallyStepRender()}
      </Form>
    </TitledDialog>
  )
}
