/**
 * 游戏角标map
 */
export const GameIconMap = {
  1: '/assets/images/games/hot-icon.png',
  2: '/assets/images/games/new-icon.png',
  3: '/assets/images/games/rtp-icon.png',
}

// 现有的游戏平台
export const GAME_PLATFORMS = [
  'inhouse',
  'hillingo-popular',
  'slotegrator',
  'evoplay',
  'live_casino',
  'pgsoft',
  'jili',
  'fundist',
  'facai',
  'hillingo',
] as const

export type GamePlatform = (typeof GAME_PLATFORMS)[number]
