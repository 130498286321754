import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash-es'

type ModalPayloadAction = {
  modalId: string
  args?: any
}

interface ModalCache {
  visible: boolean
  payload?: any
}
const initialState: Partial<Record<string, ModalCache>> = {}

const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    show: (state, action: PayloadAction<ModalPayloadAction>) => {
      const { modalId, args } = action.payload
      const modalCache: ModalCache = {
        visible: true,
      }
      if (!isEmpty(args)) modalCache['payload'] = args
      state[modalId] = modalCache
    },

    hide: (
      state,
      action: PayloadAction<{
        modalId: string
        force?: boolean
      }>,
    ) => {
      const { modalId, force } = action.payload

      if (force) {
        delete state[modalId]
      } else {
        const _ = state[modalId]
        if (_) {
          _.visible = false
        }
      }
    },
  },
})

export const { show, hide } = modalSlice.actions
export default modalSlice.reducer
