import {
  Box,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'
import React, { Suspense, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import Icon2 from '@/assets/images/act/modal-unactive-2.png'
import { ActTipsbtnRenderType } from '@/modules/activity/type'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { useModalConfig } from '@/modules/auto-modal/useModal'
import {
  closeModal,
  openModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { Icon as SharedIcon } from '@/utils/atom-shared'

import useMoneyRainImageLoad from './hooks/useMoneyRainImageLoad'
// import MoneyRainGameModal from './MoneyRainGameModal'
const MoneyRainGameModal = React.lazy(() => import('./MoneyRainGameModal'))
// import MoneyRainRulesModal from './MoneyRainRulesModal'
const MoneyRainRulesModal = React.lazy(() => import('./MoneyRainRulesModal'))

function MoneyRainModal() {
  const { onCloseModal } = useModalConfig()
  const { t } = useTranslation()

  const { loadedImages } = useMoneyRainImageLoad()

  const { open, data, extra } = useAppSelector(
    (state: any) => selectModal(state)[GlobalModalKey.MoneyRain],
  )

  const dispatch = useAppDispatch()

  const handleClose = useCallback(() => {
    dispatch(
      closeModal({
        key: GlobalModalKey.MoneyRain,
        data: {
          modalType: 'rules',
          isOpen: false,
        },
      }),
    )
    onCloseModal(GlobalModalKey.MoneyRain, extra?.isManu)
  }, [dispatch, onCloseModal])

  const renderModal = useMemo(() => {
    if (!loadedImages)
      return (
        <>
          <ModalOverlay layerStyle='ls-modal-mask-blur-50' />
          <ModalContent bg='transparent' boxShadow='none'>
            <ModalBody w='full' h='100vh' position='relative'>
              <Box
                h='100vh'
                w='full'
                display='flex'
                height='100vh'
                bg='transparent'
                alignItems='center'
                justifyContent='center'
              >
                <Icon
                  as={SharedIcon.Close}
                  w='5'
                  h='5'
                  color='white'
                  fontWeight='bold'
                  onClick={handleClose}
                  position='absolute'
                  right='40px'
                  top='60px'
                />
                <Spinner color='#fff' />
              </Box>
            </ModalBody>
          </ModalContent>
        </>
      )
    const type = data?.modalType
    return type === 'rules' ? (
      <Suspense fallback={<Spinner />}>
        <MoneyRainRulesModal onClose={handleClose} />
      </Suspense>
    ) : (
      <Suspense fallback={<Spinner />}>
        <MoneyRainGameModal onClose={handleClose} />
      </Suspense>
    )
  }, [data?.modalType, handleClose, loadedImages])

  const { open: isLoginOpen } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.Login],
  )

  useEffect(() => {
    // 手动点击打开
    if (!(open && Boolean(data?.isOpen) && !isLoginOpen) && extra?.isManu) {
      dispatch(
        openModal({
          key: GlobalModalKey.ActTips,
          data: {
            title: 'EXCHANGE_TITLE',
            content: t('EVENT_NOT_STARTED'),
            icon: Icon2,
            btnRender: ActTipsbtnRenderType.ACTIVITY_CENTER_BTN,
          },
        }),
      )
    }
  }, [isLoginOpen, extra, open, data])

  return (
    <>
      <Modal
        autoFocus={false}
        trapFocus={false}
        isOpen={open && Boolean(data?.isOpen) && !isLoginOpen}
        onClose={handleClose}
        isCentered
        closeOnOverlayClick={false}
        size={{
          base: 'full',
          lg: 'md',
        }}
        // variant='fill-square'
      >
        {renderModal}
      </Modal>
    </>
  )
}

export default MoneyRainModal
