import { Avatar } from '@chakra-ui/react'
import React from 'react'

import appConfig from '@/websites/current/pages/layout/app-config.json'

interface BuiltInAvatarProps
  extends Omit<React.ComponentProps<typeof Avatar>, 'src'> {
  avatarId: string
}

function getBuiltInAvatarById(id: string, type = 'normal') {
  //个人中心 - 绑定手机号的用户头像右下角有个镂空
  //type='normal' 常规用户头像; 个人中心type=center
  const { builtInAvatars } = appConfig
  const defaultAvatar = builtInAvatars[0]

  return builtInAvatars[parseInt(id, 10)] ?? defaultAvatar
}

const maskImage =
  'radial-gradient(circle at 88% 93%, transparent, transparent 18%, #000 19%, #000 100%)'

const maskImageStyle = {
  maskImage,
  WebkitMaskImage: maskImage,
}

export default function BuiltInAvatar(props: BuiltInAvatarProps) {
  const { avatarId, type = 'normal', ...restProps } = props
  return (
    <Avatar
      cursor='pointer'
      src={getBuiltInAvatarById(avatarId, type)}
      alt=''
      sx={type === 'normal' ? undefined : maskImageStyle}
      {...restProps}
    />
  )
}
