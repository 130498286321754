import { Box, Flex, Highlight, Image, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useMemo } from 'react'

import rechargeIcon from '@/assets/images/lucky-wheel/recharge-countdown.png'
import { useAtomCountDown } from '@/components/atom-count-down'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { openWallet } from '@/modules/global-modal/globalModalSlice'
import { formatLocalMoney } from '@/utils/tools'

import {
  getCurrentUserRewardInfo,
  getLuckyWheelConfigReward,
  getLuckyWheelRewardInfo,
} from '../luckyWheelSlice'

export const useGetRechargeCountDown = () => {
  const { firstDrawTime } = useAppSelector(getCurrentUserRewardInfo)
  const rechargeCountDown = useMemo(() => {
    if (firstDrawTime) {
      return dayjs(firstDrawTime * 1000)
        .add(2, 'hour')
        .format('YYYY-MM-DD HH:mm:ss')
    }
    const storageRechargeCountDown = localStorage.getItem('rechargeCountDown')
    if (storageRechargeCountDown) {
      return dayjs(storageRechargeCountDown).format('YYYY-MM-DD HH:mm:ss')
    } else {
      const temp = dayjs().add(2, 'hour').format('YYYY-MM-DD HH:mm:ss')
      localStorage.setItem('rechargeCountDown', temp)
      return temp
    }
  }, [firstDrawTime])

  return { rechargeCountDown }
}

const landingPageStyle = {
  bg: 'rgba(255, 255, 255, .1)',
  btnColor: 'prim.500',
}

function RechargeCard({ origin }: { origin?: 'landingPage' }) {
  const { reward } = useAppSelector(getLuckyWheelConfigReward)
  const dispatch = useAppDispatch()
  // 两小时的倒计时

  const { rechargeCountDown } = useGetRechargeCountDown()

  const {
    hours: rHours,
    minutes: rMins,
    seconds: rSeconds,
    isFinished,
  } = useAtomCountDown({
    targetDate: rechargeCountDown,
  })

  return (
    <>
      {isFinished ? null : (
        <Box
          w='full'
          bg={
            origin === 'landingPage'
              ? landingPageStyle.bg
              : 'linear-gradient(180deg, #7527E7 0%, #B67BFF 100%)'
          }
          borderRadius='16px'
          mt='3'
          px='3'
          h='88px'
        >
          <Flex alignItems='flex-start' justifyContent='space-between' h='100%'>
            <Image
              src={rechargeIcon}
              w='14'
              h='14'
              mt='3'
              objectFit='cover'
              flexShrink={0}
            />
            <Flex
              mx='8px'
              h='100%'
              flexWrap='wrap'
              flexDirection='column'
              justifyContent='center'
              w='100%'
            >
              <Text
                textStyle='h7'
                color='white'
                fontWeight='600'
                noOfLines={3}
                w='100%'
              >
                <Highlight
                  styles={{
                    color: 'four.500',
                  }}
                  query={formatLocalMoney(reward)}
                >
                  {t('DEPOSIT_IN_TIME', {
                    amount: formatLocalMoney(reward),
                  }) ?? ''}
                </Highlight>
              </Text>
              <Text textStyle='h3' color='four.500' mt='4px' w='100%'>
                {`${rHours}:${rMins}:${rSeconds}`}
              </Text>
            </Flex>

            <Box
              mt='18px'
              bg={
                origin === 'landingPage' ? landingPageStyle.btnColor : '#762BDD'
              }
              color='white'
              borderRadius='10px'
              textStyle='h6'
              py='2'
              w='82px'
              onClick={() => {
                dispatch(openWallet())
              }}
              cursor='pointer'
              flexShrink={0}
              textAlign='center'
            >
              {t('DEPOSIT_DEPOSIT')}
            </Box>
          </Flex>
        </Box>
      )}
    </>
  )
}

export default RechargeCard
