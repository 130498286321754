import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import 'swiper/css/grid'
import 'swiper/css/navigation'

import { Box, SimpleGrid, useBreakpointValue } from '@chakra-ui/react'
import React, { memo, useMemo } from 'react'
// import { Grid } from 'swiper/modules'
import type { SwiperProps } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'

import type { GameListItem } from '../../gameInterface'
import { GRID_COLUMNS } from '../constants'

function chunkArray(array: any[], chunkSize: number) {
  const result = []
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize))
  }
  return result
}

interface Props {
  rowsPerView: number
  games: GameListItem[]
  onSwiperInit?: (swiper: any) => void
  itemRender: (game: GameListItem) => React.ReactNode
  showAllRender?: () => JSX.Element
}

function GamesListwiper({
  games,
  onSwiperInit,
  itemRender,
  rowsPerView,
  showAllRender,
}: Props) {
  const chunkSize = useBreakpointValue({
    base: 6,
    sm: 8,
    md: 10,
    lg: 12,
  })

  const swiperProps: SwiperProps = useMemo(() => {
    return {
      style: {
        width: '100%',
        height: '100%',
      },
      grabCursor: true,
      observer: true,
      observeParents: true,
      freeMode: true,
      onInit: (swiper: any) => {
        onSwiperInit && onSwiperInit(swiper)
      },
      simulateTouch: false,
    }
  }, [onSwiperInit])

  const gameLists = useMemo(() => {
    if (rowsPerView === 1) {
      return chunkArray(games, (chunkSize || 12) / 2)
    }
    return chunkArray(games, chunkSize || 12)
  }, [games, rowsPerView, chunkSize])

  const handleTwoRowItemRender = (itemList: GameListItem[]) => {
    return (
      <SimpleGrid columns={GRID_COLUMNS} columnGap='3' rowGap='3'>
        {itemList?.map(gameItem => {
          return <Box key={gameItem.game_id}>{itemRender(gameItem)}</Box>
        })}
        {showAllRender &&
          gameLists?.[gameLists?.length - 1]?.[0]?.game_id ===
            itemList?.[0]?.game_id &&
          showAllRender()}
      </SimpleGrid>
    )
  }

  return (
    <Swiper {...swiperProps}>
      {(gameLists as GameListItem[][])?.map((gameList, index) => {
        return (
          <SwiperSlide key={`game_row_${index}`}>
            {handleTwoRowItemRender(gameList)}
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

const GamesListwiperMemo = memo(GamesListwiper)

export default GamesListwiperMemo
