import type { EventConfigType, TrackFn } from '../const'
import { EventName } from '../const'

const EVENT_CONFIGS: EventConfigType = {
  [EventName.ContentView]: ['ViewContent'],
  [EventName.CompleteRegistration]: ['CompleteRegistration'],
  [EventName.PlaceAnOrder]: ['PlaceAnOrder'],
  [EventName.CompletePurchase]: ['CompletePayment'],
  // [EventName.EmailRegister]: ['CompleteRegistration'],
  // [EventName.PhoneRegister]: ['CompleteRegistration'],
}

export const trackerFn: TrackFn = (event, data) => {
  const channel = sessionStorage.getItem('channel')
  if (!(channel === 'tiktlk' || channel === 'tiktok')) {
    return
  }
  const tiktokId = sessionStorage.getItem('tiktokId')

  const events = EVENT_CONFIGS[event]
  if (!events || !tiktokId || !ttq) {
    return
  }
  for (const event of events) {
    ttq.instance(tiktokId).track(event, data)
  }
}
