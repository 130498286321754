import type { ChakraProps, ThemingProps } from '@chakra-ui/react'
import { Box, useStyleConfig } from '@chakra-ui/react'
import { useMemo } from 'react'

export default function AtomBadge({
  show,
  variant,
  children,
  size = 'md',
  showNumber,
  number,
  scene,
  ...restProps
}: ChakraProps & {
  show?: boolean
  children: React.ReactNode
  size?: ThemingProps['size']
  variant?: ThemingProps['variant']
  showNumber?: boolean
  number?: number
  scene?: string
}) {
  const styles = useStyleConfig('AtomBadge', { size, variant })

  const specialScene = ['header']

  const content = useMemo(() => {
    if (!showNumber) return ''
    if (number === undefined) return ''
    if (number === 0) return ''
    if (number > 99) return '99+'
    return number
  }, [number, showNumber])

  const oldNotification = useMemo(() => {
    return {
      display: show ? 'block' : 'none',
      content: `''`,
      position: 'absolute',
      top: 0,
      right: 0,
      transform: 'translate3d(50%, -50%, 0)',
      bg: 'system.normal.fail',
      w: '4',
      h: '4',
      borderRadius: '50%',
      ...styles,
    }
  }, [show, styles])

  const newNotification = useMemo(() => {
    return {
      display: show ? 'block' : 'none',
      content: `'${content}'`,
      position: 'absolute',
      top: 0.5,
      right: 0,
      transform: 'translate3d(50%, -50%, 0) scale(0.8)',
      bg: 'system.normal.fail',
      w: '26px',
      h: '4',
      borderRadius: '40px',
      color: 'white',
      fontSize: '12px',
    }
  }, [content, show])

  return (
    <Box
      position='relative'
      display='block'
      w='full'
      _after={
        scene && specialScene.includes(scene)
          ? newNotification
          : oldNotification
      }
      {...restProps}
    >
      {children}
    </Box>
  )
}
