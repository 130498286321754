import type { PropsWithChildren } from 'react'

import Loading from '@/routes/Loading'
import { useUserAuth } from '@/utils/hooks/useUserAuth'

import {
  useLoginTaskCfqQuery,
  useLoginTaskInfoQuery,
} from '../login-reward-modal/loginRewardModalApi'
import {
  useGetCommonSettingsQuery,
  useGetIsSetVipServiceQuery,
} from './apis/endpoints'

/**
 * 需要依赖的接口
 * @param param0
 * @returns
 */
export default function VipApiLayout({ children }: PropsWithChildren) {
  const { isLoading: commonConfigLoading } = useGetCommonSettingsQuery()
  const [user_id, token] = useUserAuth()
  const { isLoading: vipServiceLoading } = useGetIsSetVipServiceQuery({
    user_id,
    token,
  })
  useLoginTaskCfqQuery({ token, user_id })
  useLoginTaskInfoQuery({ token, user_id })

  if (commonConfigLoading || vipServiceLoading) return <Loading />

  return children
}
