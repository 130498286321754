import type { ButtonProps, TabProps } from '@chakra-ui/react'
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'

import AtomBadge from '@/components/atom-badge/AtomBadge'
import TitledDialog from '@/components/modal/TitledDialog'
import LoopResult from '@/modules/activity/draw-activity/components/LoopResult'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  closeModal,
  setModalData,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'

import CommonTipsModal from '../CommonTipsModal'
import drawActivityEvent from '../events'
import * as Images from '../image-imports'
import { playStart } from '../store/reducer-slice'
import { selectIsPlaying } from '../store/selectors'
import type { ScenceProps } from '../types'
import { useDrawActivityData } from '../useDrawActivityData'
import { ActivityStage } from './ActivityStage'
import DrawButton from './DrawButton'
import { Heading } from './Heading'
import RecordModal from './RecordModal'
import TaskItemBet from './TaskItemBet'
import TaskItemDaily from './TaskItemDaily'
import TaskItemInvite from './TaskItemInvite'
import TaskItemRecharge from './TaskItemRecharge'
import { handleS3SourceDomain } from '@/utils/tools'

function MainScene(props: ScenceProps & { initTaskId?: number }) {
  const { isShow, initTaskId } = props
  const { t } = useTranslation()
  const [tabIndex, setTabIndex] = useState(0)
  const dispatch = useAppDispatch()
  const ruleModal = useDisclosure()
  const recordModal = useDisclosure()
  const anchorRef = useRef<HTMLDivElement>(null)
  const isPlaying = useAppSelector(selectIsPlaying)
  const { data, isLoading } = useDrawActivityData({ isOpen: isShow })
  const [canShowTips, setCanShowTips] = useState(false)

  const navigate = useNavigate()
  const { isOpen: isShowTips, onClose, onOpen: showTips } = useDisclosure()
  const closeTips = useCallback(() => {
    onClose()
    dispatch(
      closeModal({
        key: GlobalModalKey.DrawActivityModal,
      }),
    )
  }, [dispatch, onClose])
  const tips = {
    title: t('EVENT_NOT_AVAILABLE'),
    desc: t('EVENT_FORBINDEN_DSC'),
    buttonText: t('MUNE_ACTIVITY_CENTER_TEXT'),
    buttonClickCb: () => {
      closeTips()
      navigate('/promotions')
    },
    onClose: () => {
      closeTips()
      navigate('/promotions')
    },
  }
  useEffect(() => {
    if (isLoading) return
    if (!data || !data.length) {
      showTips()
      return
    }
    const targetIndex = data?.findIndex(item => item.taskId === initTaskId)
    if (targetIndex !== -1) {
      setTabIndex(targetIndex)
      setCanShowTips(true)
      dispatch(
        setModalData({
          key: GlobalModalKey.DrawActivityModal,
          data: undefined,
        }),
      )
    } else {
      setCanShowTips(true)
    }
  }, [data, dispatch, initTaskId, isLoading, showTips])

  const scrollToAnchor = useCallback(
    () => anchorRef.current?.scrollIntoView({ behavior: 'smooth' }),
    [],
  )

  const play = () => {
    dispatch(playStart())
    drawActivityEvent.emit('playStart')
  }

  if (isLoading) {
    return (
      <Box mt='320px' mx='auto' width='full' maxWidth='240px'>
        <Text mb='4' color='#FFFFFF' textAlign='center'>
          {t('GAME_TEXT_LOADING')}
        </Text>
        <Progress isIndeterminate />
      </Box>
    )
  }

  if (!data) return null
  const activity = data[tabIndex]
  const activityTask = activity?.task

  return (
    <>
      <Box hidden={!isShow}>
        <Tabs
          isFitted
          variant='unstyled'
          index={tabIndex}
          onChange={index => {
            setTabIndex(index)
            setCanShowTips(true)
          }}
          isLazy
          lazyBehavior='keepMounted'
        >
          <TabList
            w='full'
            gap='4'
            h='58px'
            mt='40px'
            mx='auto'
            maxWidth='320px'
          >
            {data?.map(item => (
              <CustomizedTab
                key={item?.taskId}
                image={item.image ? handleS3SourceDomain(item.image) : ''}
                isDisabled={isPlaying}
                isRedDotShow={item.drawTimes > 0}
              >
                {item.name}
              </CustomizedTab>
            ))}
          </TabList>

          <LoopResult />

          <Box mt='2.5' w='full' position='relative'>
            <VStack
              spacing={2}
              position='absolute'
              right='8px'
              top='0'
              zIndex='9'
            >
              <IconButton onClick={() => recordModal.onOpen()}>
                <Image src={Images.recordsIcon} w='18px' h='18px' />
              </IconButton>
              <IconButton onClick={() => ruleModal.onOpen()}>
                <Image src={Images.ruleIcon} w='18px' h='18px' />
              </IconButton>
            </VStack>
            <Heading>{activity?.name}</Heading>
            <TabPanels>
              {data?.map((item, index) => (
                <TabPanel key={item.taskId} p='0'>
                  <ActivityStage
                    isVisible={tabIndex === index}
                    activity={item}
                    scrollToAnchor={scrollToAnchor}
                    canShowTips={canShowTips}
                  />
                </TabPanel>
              ))}
            </TabPanels>
            <DrawButton
              onClick={play}
              isDisabled={!activity?.canPlay || isPlaying}
              disabledText={activity?.buttonDisabledText}
              isLoading={isPlaying}
            />
          </Box>
        </Tabs>

        <Flex
          textAlign='center'
          margin='0 auto'
          w='full'
          justifyContent='center'
          alignItems='center'
          mt='3'
        >
          <Text textStyle='text5' color='#fff'>
            {t('LOTTERY_TICKET')}
          </Text>
          <Image src={Images.ticket} w='22px' h='20px' ml='4px' />
          <Text fontSize='12px' fontWeight='800' color='four.500' ml='2px'>
            {activity?.drawTimes}
          </Text>
        </Flex>

        <Box
          visibility={activity?.canPlay ? 'visible' : 'hidden'}
          ref={anchorRef}
        >
          <Text textStyle='h5' color='white' textAlign='center' mt='6'>
            {t('HOW_TO_GET_TICKET')}
          </Text>
          <VStack mt='4' px='2.5' spacing='2'>
            <TaskItemDaily
              taskId={activity?.taskId}
              data={activityTask?.checkIn}
            />
            <TaskItemBet data={activityTask?.bet} />
            <TaskItemRecharge data={activityTask?.deposit} />
            <TaskItemInvite
              data={{
                ...activityTask?.invite,
                activityId: activity?.taskId,
              }}
            />
          </VStack>
        </Box>
      </Box>

      <TitledDialog
        title={t('WHEEL_RULE_TITLE')}
        isOpen={ruleModal.isOpen}
        onClose={ruleModal.onClose}
      >
        <Box
          dangerouslySetInnerHTML={{ __html: activity?.rule ?? '' }}
          className='ck-content'
        />
      </TitledDialog>
      <RecordModal
        isOpen={recordModal.isOpen}
        onClose={recordModal.onClose}
        taskId={activity?.taskId}
      />
      <CommonTipsModal
        isOpen={isShowTips}
        title={tips.title}
        desc={tips.desc}
        onClose={tips.onClose}
        buttonText={tips.buttonText}
        buttonClickCb={tips.buttonClickCb}
        showButton
      />
    </>
  )
}

const MainSceneMemo = memo(MainScene)

export default MainSceneMemo

function IconButton(props: ButtonProps) {
  const { children, ...restProps } = props
  return (
    <Button
      variant='unstyledIcon'
      w='26px'
      h='26px'
      bg='rgba(0,0,0,0.2)'
      borderRadius='4px'
      alignItems='center'
      justifyContent='center'
      cursor='pointer'
      {...restProps}
    >
      {children}
    </Button>
  )
}

function CustomizedTab(
  props: TabProps & { image: string; isRedDotShow?: boolean },
) {
  const { image, children, isRedDotShow, ...restProps } = props
  return (
    <Tab
      h='58px'
      p='0'
      alignItems='center'
      flexDirection='column'
      justifyContent='center'
      position='relative'
      cursor='pointer'
      opacity={0.5}
      _selected={{ opacity: 1 }}
      {...restProps}
    >
      <AtomBadge size='md' show={isRedDotShow} width='max-content'>
        <Image
          src={image}
          w='56px'
          h='56px'
          objectFit='contain'
          flexShrink={1}
        />
      </AtomBadge>
      <Text
        position='absolute'
        bottom='0'
        textStyle='h7'
        backgroundImage='linear-gradient(0deg, #C89600, #FEF9AC)'
        backgroundClip='text'
        color='transparent'
        filter='drop-shadow(0 0 2px rgba(200, 150, 0, 1))'
        marginTop='-12px'
      >
        {children}
      </Text>
    </Tab>
  )
}
