import { EventName } from '../const'
import type { Channel, ITrackerBase } from './types'

export class TrackerFacebook implements ITrackerBase {
  channel: Channel

  eventMap = {
    [EventName.ContentView]: ['PageView'], // 浏览
    [EventName.CompleteRegistration]: ['CompleteRegistration'], // 注册
    [EventName.CompletePurchase]: ['Purchase'], // 充值
  }

  constructor(private channelId: string) {
    this.channel = { name: 'facebook', id: channelId }
  }

  trackSingleEvent(
    event: string,
    _originEventName?: EventName,
    data?: any,
  ): void {
    if (typeof fbq !== 'function' || !this.channelId) return
    fbq('trackSingleCustom', this.channelId, event, data)
  }
}
