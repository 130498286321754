import { Center, Spinner } from '@chakra-ui/react'
import { createSelector } from '@reduxjs/toolkit'
import { lazy, Suspense, useCallback, useEffect, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import PerformanceMarkTag from '@/common/pmsdk/performance-mark-tag'
import TitledDialog from '@/components/modal/TitledDialog'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { getOpen } from '@/modules/bind-phone/bindPhoneSlice'
import ErrorBoundary from '../error/ErrorBoundary'

import {
  changeTabs,
  getShowVirtualModal,
  selectWallet,
  setIsFromRechargeAct,
  WalletModalTab,
} from '@/modules/wallet/walletSlice'

import { selectBankruptCallbackUrl } from '../activity/ActSlice'
import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'

const Wallet = lazy(() => import('./Wallet'))

export default function WalletModal() {
  const dispatch = useAppDispatch()
  const navigator = useNavigate()
  const { t } = useTranslation()
  const { open: isOpen } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.Wallet],
  )
  const showVirtualModal = useAppSelector(getShowVirtualModal)
  const isParamsDialogOpen = useAppSelector(
    state => selectWallet(state).isParamsDialogOpen,
  )
  const isOpenBindPhoneModal = useAppSelector(getOpen)

  const callbackType = useAppSelector(selectBankruptCallbackUrl)

  const { open: isOpenBindInfoModal } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.BindInfoModal],
  )

  const { open: WalletGiftTipsModal } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.WalletGiftTips],
  )

  const { open: isGcashPaymentModalOpen } = useAppSelector(
    createSelector(selectModal, globalModal => globalModal.GcashPaymentModal),
  )

  const { open: newRechargeParamsModal } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.WalletConfirmForm],
  )

  // 关闭弹窗
  const onClose = useCallback(() => {
    if (callbackType) {
      navigator(callbackType)
    }
    dispatch(changeTabs(WalletModalTab.recharge))
    dispatch(closeModal({ key: GlobalModalKey.Wallet }))
  }, [dispatch, callbackType])

  useEffect(() => {
    dispatch(setIsFromRechargeAct(!isOpen))
  }, [isOpen])

  useLayoutEffect(() => {
    performance.mark(PerformanceMarkTag.WalletPageStart)
  }, [])
  return (
    <>
      {/* isOpen */}
      <TitledDialog
        title={t('WALLET_TITLE')}
        isOpen={
          isOpen &&
          !newRechargeParamsModal &&
          !showVirtualModal &&
          !isParamsDialogOpen &&
          !isOpenBindPhoneModal &&
          !isOpenBindInfoModal &&
          !isGcashPaymentModalOpen &&
          !WalletGiftTipsModal
        }
        onClose={onClose}
        colorScheme='multilayer'
        variant={{ base: 'fill-square', md: 'round' }}
        size={{ base: 'full', md: 'md' }}
      >
        <ErrorBoundary>
          <Suspense
            fallback={
              <Center height={['100vh', '30vh']}>
                <Spinner />
              </Center>
            }
          >
            <Wallet onClose={onClose} />
          </Suspense>
        </ErrorBoundary>
      </TitledDialog>
    </>
  )
}
