/* eslint-disable react-refresh/only-export-components */
import { lazy } from 'react'
import type { RouteObject } from 'react-router-dom'

import { isFeatureEnabled } from '@/common/feature'
import CustomSuspense from '@/modules/app/CustomSuspense'
import { WithAuthGuard } from '@/modules/new-referral/ReferralLayout'
import { features } from '@/websites/current/property.json'

import Loading from './Loading'

const ReferralDetails = lazy(() =>
  features?.referralNew
    ? import('@/modules/new-referral/ReferralDetails')
    : import(`@/modules/referral/ReferralDetails`),
)
const ReferralInvite = lazy(() =>
  features?.referralNew
    ? isFeatureEnabled('referralRedesigned')
      ? import('@/modules/new-referral/redesigned/ReferralInviteRedesigned')
      : import('@/modules/new-referral/ReferralInvite')
    : import('@/modules/referral/ReferralInvite'),
)
const ReferralLayout = lazy(() =>
  features?.referralNew
    ? import('@/modules/new-referral/ReferralLayout')
    : import('@/modules/referral/ReferralLayout'),
)
const ReferralReward = lazy(() =>
  features?.referralNew
    ? import('@/modules/new-referral/ReferralReward')
    : import('@/modules/referral/ReferralReward'),
)
const ReferralStatics = lazy(() =>
  features?.referralNew
    ? import('@/modules/new-referral/ReferralStatics')
    : import('@/modules/referral/ReferralStatics'),
)
const ReferralBigAgent = lazy(() =>
  features?.referralNew
    ? import('@/modules/new-referral/ReferralBigAgent')
    : import('@/modules/referral/ReferralBigAgent'),
)

const PATH_PREFIX = isFeatureEnabled('referral') ? '' : '/__disable__'

export const getPath = (path = '') => `${PATH_PREFIX}${path}`

export const referralRouter: RouteObject[] = [
  {
    path: getPath('/referral'),
    element: (
      <CustomSuspense fallback={<Loading />}>
        <WithAuthGuard>
          <ReferralLayout />
        </WithAuthGuard>
      </CustomSuspense>
    ),
    children: [
      {
        path: getPath('/referral'),
        element: (
          <CustomSuspense fallback={<Loading />}>
            <ReferralInvite />
          </CustomSuspense>
        ),
      },
      {
        path: getPath('/referral/reward'),
        element: (
          <CustomSuspense fallback={<Loading />}>
            <ReferralReward />
          </CustomSuspense>
        ),
      },
      {
        path: getPath('/referral/statics'),
        element: (
          <CustomSuspense fallback={<Loading />}>
            <ReferralStatics />
          </CustomSuspense>
        ),
      },
      {
        path: getPath('/referral/details'),
        element: (
          <CustomSuspense fallback={<Loading />}>
            <ReferralDetails />
          </CustomSuspense>
        ),
      },
      {
        path: getPath('/referral/big_agent'),
        element: (
          <CustomSuspense fallback={<Loading />}>
            <ReferralBigAgent />
          </CustomSuspense>
        ),
      },
    ],
  },
]
