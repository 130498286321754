import type { ChangeEventHandler, Dispatch, SetStateAction } from 'react'
import { useCallback, useState } from 'react'

export function useInputValidState(
  initVal: any,
  validator?: (val: string) => boolean,
): {
  value: string
  setValue: Dispatch<SetStateAction<string>>
  valid: boolean
  handleChange: ChangeEventHandler<HTMLInputElement>
  isValid: () => 1 | 0
} {
  const [value, setValue] = useState(initVal)
  const [valid, setValid] = useState(true)
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value)
      validator && setValid(validator(e.target.value))
    },
    [validator],
  )

  const isValid = () => {
    if (!validator) {
      return 1
    }
    setValid(validator(value))
    return validator(value) ? 1 : 0
  }

  return { value, setValue, valid, handleChange, isValid }
}
