import { getCurrencyCode } from '@/common/locale/currency'

import { isProduction } from '../env'
import { EventName, LoginType, PayType, RegType } from './const'
import { matchCase } from './helpers'
import { initSentry } from './sentry'
import { track } from './tracker'

export {
  trackFirstRecharge,
  trackPageView,
  trackRegister,
} from './new-trackers/event'

const isFromApk = !!window?.AndroidWebView

export function init() {
  if (isProduction()) {
    initSentry()
  }
}

function trackPageViewLegacy(data: { pathname: string }) {
  // TODO: 页面跳转需要记录哪些字段
  track(EventName.ContentView, data)
}

export function trackLogin(loginType: LoginType) {
  matchCase(loginType, {
    [LoginType.Login]: () => {
      track(EventName.AdjustLogin)
      track(EventName.Login)

      if (isFromApk) {
        track(EventName.ApkLogin)
      }
    },
    [LoginType.FbLogin]: () => {
      track(EventName.AdjustLogin)
      track(EventName.FbLogin)

      if (isFromApk) {
        track(EventName.ApkLogin)
      }
    },
    [LoginType.GoogleLogin]: () => {
      track(EventName.AdjustLogin)
      if (isFromApk) {
        track(EventName.ApkLogin)
      }
      /** google 登录 暂时不上报 */
    },
    [LoginType.PhoneLogin]: () => {
      track(EventName.PhoneLogin)

      if (isFromApk) {
        track(EventName.ApkLogin)
      }
    },
    [LoginType.EmailLogin]: () => {
      track(EventName.EmailLogin)

      if (isFromApk) {
        track(EventName.ApkLogin)
      }
    },
    [LoginType.ApkLogin]: () => {
      track(EventName.ApkLogin)
    },
  })
}

function trackRegisterLegacy(regType: RegType, data?: any) {
  matchCase(regType, {
    [RegType.Register]: () => {
      track(EventName.CompleteRegistration)

      if (isFromApk) {
        track(EventName.ApkRegister)
      }
    },
    [RegType.FbRegister]: () => {
      track(EventName.CompleteFbRegistration)
      if (isFromApk) {
        track(EventName.ApkRegister)
      }
    },
    [RegType.GoogleRegister]: () => {
      track(EventName.CompleteGoogleRegistration)
      if (isFromApk) {
        track(EventName.ApkRegister)
      }
    },
    [RegType.EmailRegister]: () => {
      track(EventName.EmailRegister)

      if (isFromApk) {
        track(EventName.ApkRegister)
      }
    },
    [RegType.PhoneRegister]: () => {
      track(EventName.PhoneRegister)

      if (isFromApk) {
        track(EventName.ApkRegister)
      }
    },
    [RegType.ApkRegister]: () => {
      track(EventName.ApkRegister)
    },
  })
}

export function trackPlaceAnOrder() {
  track(EventName.PlaceAnOrder)
}

/**
 * 支付埋点
 * @param rechargeType 支付类型，首充,二次付费,普通付费
 * @param amount 支付金额
 */
export function trackRecharge(rechargeType: PayType, amount: string | number) {
  const params = {
    value: amount,
    currency: getCurrencyCode(),
    predicted_ltv: amount,
    type: '',
  }

  matchCase(rechargeType, {
    [PayType.FirstPay]: () => {
      params.type = 'FIRST_RECHARGE'
      track(EventName.CompletePurchase, params)
    },
    [PayType.SecondPay]: () => {
      track(EventName.SecondRecharge, params)
    },
    [PayType.MorePay]: () => {
      track(EventName.MoreRecharge, params)
    },
  })
}

/**
 * 进入首页的埋点
 */
export function trackEnterHome() {
  track(EventName.EnterHome)
}

/**
 * 进入注册页面的埋点
 */
export function trackEnterRegister() {
  track(EventName.EnterRegister)
}

/**
 * 进入登录页面的埋点
 */
export function trackEnterLogin() {
  track(EventName.EnterLogin)
}

/**
 * 点击钱包按钮
 */
export function trackClickWallet() {
  track(EventName.ClickWallet)
}

/**
 * 展示充值页面
 */
export function trackShowRechargePanel() {
  track(EventName.ShowRechargePanel)
}

/**
 * 点击充值按钮
 */
export function trackClickRecharge() {
  track(EventName.ClickRecharge)
}

/**
 * 展示提现页面
 */
export function trackShowWithdrawPanel() {
  track(EventName.ShowWithdrawPanel)
}

/**
 * 点击下载apk
 */
export function trackClickDownload() {
  track(EventName.ClickDownload)
}

/**
 * 浏览推广活动页面
 */
export function trackViewPromotion() {
  track(EventName.viewPromotion)
}

/**
 * 游戏收藏埋点
 */

export function trackAddToWishList() {
  track(EventName.addToWishList)
}

/**
 * 首次打开页面
 */
export function trackFirstOpen() {
  track(EventName.FirstOpen)
}
