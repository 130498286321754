import type { SystemStyleObject } from '@chakra-ui/styled-system'

export const NAME = 'AtomActiveLink'
export const PARTS = ['container', 'content', 'icon', 'text'] as const
export const VARIANTS = ['primary', 'secondary'] as const
export type Part = (typeof PARTS)[number]
export type Variant = (typeof VARIANTS)[number]
export type VariantsStyles = Record<
  Variant,
  Partial<Record<Part, SystemStyleObject>>
>
