import { useEffect, useState } from 'react'

export type LoadListFunc = () => Promise<void>[]

export function useLoader(
  loaderFunc: LoadListFunc,
  onComplete?: () => void,
): [isLoading: boolean, progress: number] {
  const [isLoading, setIsLoading] = useState(true)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const list = loaderFunc()
    if (list.length === 0) {
      // 没有需要加载的资源，直接加载主页面
      setProgress(1)
      setIsLoading(false)
      if (onComplete) onComplete()
    } else {
      // 有需要加载的资源文件，需要等同步资源加载完毕，才可加载主页面，（剩下的异步资源可在加载主页面的同时异步继续加载）
      let completed = 0
      list.forEach(item => {
        item.finally(() => {
          completed += 1
          setProgress(completed / list.length)
          if (completed >= list.length) {
            setIsLoading(false)
            if (onComplete) onComplete()
          }
        })
      })
    }
  }, [loaderFunc, onComplete])

  return [isLoading, progress]
}

export default useLoader
