import bankLogo from '@/assets/images/wallet/bank.png'
import cashLogo from '@/assets/images/wallet/cash.png'
import chekwallet from '@/assets/images/wallet/clp/chekwallet.png'
import khipuwallet from '@/assets/images/wallet/clp/khipuwallet.png'
import machwallet from '@/assets/images/wallet/clp/machwallet.png'
import vitawallet from '@/assets/images/wallet/clp/vitawallet.png'
import codiLogo from '@/assets/images/wallet/codi.png'
import CLAROPAYWALLET from '@/assets/images/wallet/cop/ClaroPay.png'
import DALEWALLET from '@/assets/images/wallet/cop/Dale.png'
import DAVIPLATAWALLET from '@/assets/images/wallet/cop/Daviplata.png'
import MOVIIWALLET from '@/assets/images/wallet/cop/Movii.png'
import NEQUIWALLET from '@/assets/images/wallet/cop/Nequi.png'
import RAPPIPAYWALLET from '@/assets/images/wallet/cop/Rappipay.png'
import TPAGAWALLET from '@/assets/images/wallet/cop/TPaga.png'
import transfiya from '@/assets/images/wallet/cop/transfiya.png'
import eleLogo from '@/assets/images/wallet/eleLogo.png'
import gcash from '@/assets/images/wallet/gcash.png'
import gcashqr from '@/assets/images/wallet/gcash_qr.png'
import oxxoLogo from '@/assets/images/wallet/oxxo.png'
import paymaya from '@/assets/images/wallet/paymaya.png'
import Paypal from '@/assets/images/wallet/paypal.png'
import pixLogo from '@/assets/images/wallet/pix.png'
import qrPixLogo from '@/assets/images/wallet/qr_pix.png'
import qrPixNewLogo from '@/assets/images/wallet/qr_pix_new.png'
import speiLogo from '@/assets/images/wallet/spei.png'
import speiWithdrawLogo from '@/assets/images/wallet/speiLogo.png'
import toditoLogo from '@/assets/images/wallet/todito.png'
import TPAGAWITHDRAW from '@/assets/images/wallet/tpaga_withdraw.png'
import { getVariable } from '@/common/env'
import { isFeatureEnabled } from '@/common/feature'
import { getCurrencyCode } from '@/common/locale/currency'
import type {
  CommonProps,
  FormRegProps,
  RechargSelectProps,
  WithdrawalMethodDetail,
} from '@/modules/wallet/interface'
import walletConfig from '@/websites/current/public/config/wallet.json'

import {
  verifyAccount,
  verifyBseriesName,
  verifyCommon,
  verifyEmail,
  verifyGcashAccountNo,
  verifyIdNumber,
  verifyMxnWithdrawDCType,
  verifyMxnWithdrawParams,
  verifyPhone,
  verifyPix,
} from './verifyFunction'

const CURRENCY = getCurrencyCode()

const currenctWalletConfig =
  (walletConfig as any)[CURRENCY] || walletConfig['DEFAULT']

export const MAX_BONUS = 10000
export const MIN_RECHARGE_AMOUNT = currenctWalletConfig.MIN_RECHARGE_AMOUNT
export const MAX_RECHARGE_AMOUNT = currenctWalletConfig.MAX_RECHARGE_AMOUNT
export const MIN_WITHDRAW_AMOUNT = currenctWalletConfig.MIN_WITHDRAW_AMOUNT
export const MAX_WITHDRAW_AMOUNT = currenctWalletConfig.MAX_WITHDRAW_AMOUNT
export const DEFAULT_AMOUNT = currenctWalletConfig.DEFAULT_RECHARGE_AMOUNT
export const DEFAULT_INDEX = currenctWalletConfig.DEFAULT_RECHARGE_INDEX

const FORM_WITHDRAW_BANK_ACCOUNT_LABEL_HELP = getVariable(
  'FORM_WITHDRAW_BANK_ACCOUNT_LABEL_HELP',
)

export interface AmountRechargeProp {
  value: number
  isHot: boolean
  index: number
}

export const AMOUNT_LIST =
  currenctWalletConfig.AMOUNT_LIST as AmountRechargeProp[]

/** COL1特有的提示字段 */
export const COL1_FORM_INPUT_TIPS: CommonProps = {
  id_type: {
    label: 'ID_TYPE',
    tips: 'WITHDRAW_ACCOUNT_ID_CHOOSE_TYPE',
  },
  id_number: {
    label: 'ID_NUMBER',
    tips: 'COMMON_ENTER_ID_NUMBER',
  },
  account_no: {
    label: 'WITHDRAW_PAYPAL_ACCOUNT',
    tips: 'COMMON_ENTER_YOUR_ACCOUNT',
  },
  bank_account: {
    label: 'WITHDRAW_BANK_ACCOUNT',
    tips: 'WITHDRAW_BANK_ACCOUNT_INPUT_TIPS',
  },
}
export const FORM_INPUT_TIPS: CommonProps = {
  pix: {
    label: 'DEPOSIT_CPF',
    tips: 'COMPLETE_FIX',
    // reg: /^\d{11}$|^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
  },
  cpf: {
    label: 'DEPOSIT_CPF',
    tips: 'COMPLETE_FIX',
    // reg: /^\d{11}$|^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
  },
  typ: {
    label: 'WALLET_TYPE_TEXT',
    tips: 'WALLET_TYPE_TEXT',
  },
  name: {
    label: 'DEPOSIT_ACCOUNT',
    tips: 'DEPOSIT_INPUT_ACCOUNT_NAME',
    // reg: /^[a-zA-Z]*$/g
  },
  bank_code: {
    label: 'WITHDRAW_BANK_CODE',
    tips: 'WITHDRAW_BANK_CODE_INPUT_TIPS',
  },
  account_type: {
    label: 'WITHDRAW_ACCOUNT_TYPE',
    tips: 'WITHDRAW_ACCOUNT_TYPE_INPUT_TIPS',
  },
  account_id: {
    label: 'WITHDRAW_ACCOUNT_ID',
    tips: 'WITHDRAW_ACCOUNT_ID_INPUT_TIPS',
  },
  bank_account: {
    label: 'WITHDRAW_BANK_ACCOUNT',
    tips: 'WITHDRAW_BANK_ACCOUNT_INPUT_TIPS',
    labelHelp: FORM_WITHDRAW_BANK_ACCOUNT_LABEL_HELP,
  },
  document_type: {
    label: 'WITHDRAW_ACCOUNT_ID_CHOOSE_TYPE',
    tips: 'WITHDRAW_ACCOUNT_ID_CHOOSE_TYPE',
  },
  phone: {
    label: 'DEPOSIT_PHONE',
    tips: 'DEPOSIT_INPUT_PHONE',
    // reg: /^[123456789]\d{8}/,
  },
  email: {
    label: 'WALLET_EMAIL_TEXT',
    tips: 'WITHDRAW_EMAIL_RIGHT',
    // reg: /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/,
  },
  payment_method: {
    label: 'WITHDRAW_CHOOSE_METHOD',
    tips: 'WITHDRAW_CHOOSE_METHOD',
  },
  account_name: {
    label: 'WITHDRAW_PAYPAL_NAME',
    tips: 'WITHDRAW_PAYPAL_NAME_ERROR',
  },
  account_no: {
    label: 'WITHDRAW_PAYPAL_ACCOUNT',
    tips: 'WITHDRAW_PAYPAL_ACCOUNT_CORRECT',
  },
  bank_area: {
    label: 'localización del Banco',
    tips: 'Seleccione la región donde se encuentra el banco',
  },
  area: {
    label: 'localización del Banco',
    tips: 'Seleccione la región donde se encuentra el banco',
  },
  code: {
    label: 'PH_BANK_SELECT_PLACE',
    tips: 'PH_BANK_SELECT_TIPS',
  },
  account: {
    label: 'WALLET_ACCOUNT_NUMBER',
    tips: 'WITHDRAW_BANK_CODE2',
  },
}
export const FORM_INPUT_REG: FormRegProps = {
  email: verifyEmail,
  phone: verifyPhone,
  common: verifyCommon,
  account_no: verifyGcashAccountNo,
  cpf: verifyPix,
  pix: verifyPix,
  bank_account: verifyMxnWithdrawParams,
  account_id: verifyMxnWithdrawDCType,
  name: verifyBseriesName,
  account: verifyAccount,
  account_name: verifyBseriesName,
  id_number: verifyIdNumber,
  // verifyEmail, verifyPhone, verifyPix
  // account_name: /^[a-zA-Z0-9_]{0,}$/,
}
export const CODE_MAP: {
  [key: string]: string
} = {
  '1001': 'INCORRECT_FORMAT',
  '1002': 'authentication',
  '1003': 'ERROR_UNKNOWN_MISTSKE', // unknown error
  '1006': 'RECHARGE_MAX_ONE',
  '1007': 'RECHARGE_MIN_ONE',
  '1011': 'MOUNT_LESS_TEXT',
  '1014': 'AMOUNT_NOT_TIME',
  '1016': 'AMOUNT_NOT_PERMIT',
  '1018': 'AMOUNT_BALANCE_LOW',
  '1019': 'AMOUNT_PIX_EXISTS',
  '1021': 'AMOUNT_IS_LOW',
  '1022': 'AMOUNT_RETURN_FAIL',
  '1025': 'RECHARGE_MAX_ONE',
  '1026': 'WITHDRAW_MIN_ONE',
  '1027': 'WITHDRAWAL_TOAST1',
  '1028': 'BAN_WITHDRAW',
  '1030': 'GET_VERIfY_CODE_TOO_FAST',
  // 后面完善的key
  '1010': `ACCOUNT_INSUF_BALANCE_RECHARGE`,
  '400': 'WRONG_REGISTER',
  '1004': 'USER_LOGIN_EXISTS',
  '1005': 'WITHDRAW_USER_FORBIDDEN',
  '1009': 'USER_VIP_NOT_EXIST',
  '1012': 'BET_HIGH',
  '1013': 'PAYOUT_HIGH',
  '1023': 'WITHDRAW_REQEATED_SUBMIT',
  '1024': 'WITHDRAW_DATA_NOT_FOUND',
  // 任务中心与新vip
  '2000': 'ALREADY_CLAIM_REWARD',
  '2001': 'REACH_SINGLE_WITHDRAW_LIMIT',
  '2002': 'REACH_MONTHLY_WITHDRAW_LIMIT',
  '2003': 'NOT_SATISFIED_MINIMUM_WITHDRAW_LIMIT',
  '2004': 'REACH_MONTHLY_WITHDRAW_TIMES',
  '2005': 'LEVEL_IS_CONFLICT',
  '2006': 'MAX_QUERY_LIMIT',
  '2007': 'SORT_IS_CONFLICT',
  '2008': 'NOT_REACH',
  '2009': 'NOT_FOUND_CONFIG',
  '2010': 'TOO_FREQUENT',
  '2011': 'WHEEL_ACTIVITY_ENDED',
  '2012': 'NOT_REACH',
  // 关于兑换码
  '3000': 'CODE_USED',
  '3001': 'CODE_EXPIRED',
  '3002': 'CODE_NOT_FOUND',
  '3004': 'REGISTER_TIME_OUT',
  '3005': 'RECHARGE_TIME_OUT',
  '3006': 'RECHARGE_FOR_NEW',
  '3007': 'VIP_LEVEL_ERROR',
  // 关于游戏下注
  '4000': 'BET_LOW',
  '4001': 'BET_HIGH',
  '4002': 'BET_INT',
  '4003': 'GAME_STARTED',
  '4004': 'GAME_COINFLIP_ALREADYSTART',
  '4005': 'ALREADY_BET',
  '4006': 'NOT_BET',
  '4007': 'GAME_GOING',
  // 关于提现参数验证
  '100001': 'PARAM_NAME_ERROR',
  '100002': 'PARAM_CPF_ERROR',
  '100003': 'PARAM_EMAIL_ERROR',
  '100004': 'PARAM_PHONE_ERROR',
}

export const rechargeErrorCode: {
  [key: number]: string
} = {
  100001: 'NAME_ILLEGAL',
  100002: 'CPF_ILLEGAL',
  100003: 'EMAIL_ILLEGAL',
  100004: 'PHONE_ILLEGAL',
  100005: 'WITHDRAW_PAYMAYA_ACCOUNT_ERROR',
}
export const CURRENCY_RATE: {
  [key: string]: number
} = {
  PHP: 0.018,
  BRL: 0.2,
  MXN: 0.057,
  PEN: 0.27,
  COP: 0.00024,
  CLP: 0.0013,
}

//充值map
export const RECHARGE_STATUE_MAP: {
  [key: string]: string
} = {
  pending: 'COMMON_PENDING',
  done: 'COMMON_DONE',
  accepted_and_done: 'COMMON_DONE',
}

export const WITHDRAW_WAYS_TEXT: {
  [key: string]: string
} = {
  mex: 'WALLET_ELECTRONIC_TEXT_SPEI',
  electronic_wallet: 'WITHDRAW_PAYPAL_WALLET',
  bank: 'Bank',
  pix: 'CPF',
  transfiya: 'Transfiya',
  pse: 'Pse',
}

export const options: {
  [key: string]: RechargSelectProps
} = {
  bank: {
    value: 'bank',
    label: '',
    icon: bankLogo,
  },
  cash: {
    value: 'cash',
    label: '',
    icon: cashLogo,
  },
  oxxo: {
    value: 'oxxo',
    label: '',
    icon: oxxoLogo,
  },
  'spei(personnal)': {
    value: 'spei',
    label: '',
    icon: speiLogo,
  },
  spei: {
    value: 'spei',
    label: '',
    icon: speiLogo,
  },
  todito: {
    value: 'todito',
    label: '',
    icon: toditoLogo,
  },
  codi: {
    // codiLogo
    value: 'codi',
    label: '',
    icon: codiLogo,
  },
  gcash: {
    value: 'gcash',
    label: '',
    icon: gcash,
  },
  gcashqr: {
    value: 'gcashqr',
    label: '',
    icon: gcashqr,
  },
  paymaya: {
    value: 'paymaya',
    label: '',
    icon: paymaya,
  },
  tpagawallet: {
    value: 'TPAGAWALLET',
    label: '',
    icon: TPAGAWALLET,
  },
  claropaywallet: {
    value: 'CLAROPAYWALLET',
    label: '',
    icon: CLAROPAYWALLET,
  },
  dalewallet: {
    value: 'DALEWALLET',
    label: '',
    icon: DALEWALLET,
  },
  daviplatawallet: {
    value: 'DAVIPLATAWALLET',
    label: '',
    icon: DAVIPLATAWALLET,
  },
  moviiwallet: {
    value: 'MOVIIWALLET',
    label: '',
    icon: MOVIIWALLET,
  },
  nequiwallet: {
    value: 'NEQUIWALLET',
    label: '',
    icon: NEQUIWALLET,
  },
  rappipaywallet: {
    value: 'RAPPIPAYWALLET',
    label: '',
    icon: RAPPIPAYWALLET,
  },
  pse: {
    value: 'PSE',
    label: '',
    icon: bankLogo,
  },
  transfiya: {
    value: 'transfiya',
    label: '',
    icon: transfiya,
  },
  electronic_wallet: {
    value: 'electronic_wallet',
    label: '',
    // Paypal
    icon: isFeatureEnabled('isCOLseriesWallet') ? NEQUIWALLET : eleLogo,
  },
  mex: {
    value: 'mex',
    label: '',
    icon: speiLogo,
  },
  khipuwallet: {
    value: 'KHIPUWALLET',
    label: '',
    icon: khipuwallet,
  },
  vitawallet: {
    value: 'VITAWALLET',
    label: '',
    icon: vitawallet,
  },
  machwallet: {
    value: 'MACHWALLET',
    label: '',
    icon: machwallet,
  },
  chekwallet: {
    value: 'CHEKWALLET',
    label: '',
    icon: chekwallet,
  },
  pix: {
    value: 'pix',
    label: '',
    icon: pixLogo,
  },
  pixqr: {
    value: 'pixqr',
    label: '',
    icon: qrPixLogo,
  },
  pixqrnew: {
    value: 'pixqrnew',
    label: '',
    icon: qrPixNewLogo,
  },
}

// 五国提款icon "MXN", "COP","CLP","PEN","USD"
export const WITHDRAW_WAY_ICON_MAP: {
  [key: string]: {
    [key: string]: string
  }
} = {
  MXN: {
    mex: speiWithdrawLogo,
    electronic_wallet: Paypal,
  },
  COP: {
    bank: bankLogo,
    electronic_wallet: NEQUIWALLET,
    transfiya: transfiya,
    pse: bankLogo,
  },
  CLP: {
    bank: bankLogo,
    electronic_wallet: TPAGAWITHDRAW,
  },
  PEN: {
    bank: bankLogo,
  },
  USD: {
    bank: bankLogo,
  },
  BRL: {},
  PHP: {
    electronic_wallet: eleLogo,
  },
  NGN: {
    electronic_wallet: eleLogo,
  },
  KES: {},
}
// 各个平台提款方式显示数据
export const getSelectLabelByItem = (item: WithdrawalMethodDetail) => {
  let title = '',
    name = ''
  switch (item.pay_method) {
    case 'electronic_wallet':
      title = item.account_no
      name = item.account_name
      break
    case 'bank':
      title = item.account
      name = item.name
      break
    case 'mex':
      title = item.account_id
      name = item.name
      break
    case 'pix':
      title = item.pix
      name = item.name
      break
    case 'transfiya':
      title = item.account_no
      name = item.name
      break
    case 'pse':
      title = item.bank_account
      name = item.name
      break
  }

  return {
    title,
    name,
  }
}

// 提现界面底部右侧按钮文字
export const WITHDRAW_MODAL_BTN_TEXT: {
  [key: string]: string
} = {
  update: 'UPDATE_BUTTON',
  add: 'ADD_BUTTON',
  withdraw: 'ADD_BUTTON',
  recharge: 'DEPOSIT_RECHARGE',
}

export const MAX_RETYR_COUNT = 3
export const delay = (mileSeconds = 2500) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true)
    }, mileSeconds)
  })
}

//需要分步校验的字段在这添加 并给对应的字段加上校验函数
export const list: {
  [key: string]: string[]
} = {
  Bseries: ['name', 'pix', 'phone', 'email', 'account'],
  Pseries: ['account_no', 'account_name', 'phone', 'email'],
  Mseries: ['account_id', 'phone', 'bank_account', 'email'],
  COLseries: [
    'phone',
    'email',
    'account_name',
    'account_no',
    'name',
    'id_type',
    'id_number',
    'bank_code',
    'bank_account',
    'account_type',
  ],
}

export const getSpecialKey = () => {
  if (
    isFeatureEnabled('isBseriesWallet') ||
    isFeatureEnabled('isNseriesWallet')
  )
    return list.Bseries
  if (isFeatureEnabled('isPseriesWallet')) return list.Pseries
  if (isFeatureEnabled('isMseriesWallet')) return list.Mseries
  if (isFeatureEnabled('isCOLseriesWallet')) return list.COLseries
}

/** COL1 提现方式配置 */
export const id_type_2_id_number: Record<
  string,
  { min_length: number; max_length: number; error_tips_text: string }
> = {
  CC: {
    min_length: 6,
    max_length: 10,
    error_tips_text: 'COL_IDCC_LIMIT_TIP',
  },
  CE: {
    min_length: 1,
    max_length: 12,
    error_tips_text: 'COL_IDCE_LIMIT_TIP',
  },
  PEP: {
    min_length: 1,
    max_length: 15,
    error_tips_text: 'COL_IDPEP_LIMIT_TIP',
  },
}
