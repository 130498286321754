import Platform from '@/common/platform'

import { TrackerAdjustNative } from './TrackerAdjustNative'
import { AdjustWebParams, TrackerAdjustWeb } from './TrackerAdjustWeb'
import type { ITrackerBase } from './types'

abstract class TrackerBuilderAdjustBase {
  build() {
    if (Platform || window?.AndroidWebView) {
      return this.buildHybird()
    } else {
      return this.buildWeb()
    }
  }

  abstract buildWeb(): ITrackerBase | null

  abstract buildHybird(): ITrackerBase | null
}

export class TrackerBuilderAdjust extends TrackerBuilderAdjustBase {
  buildWeb() {
    if (!window.Adjust) return null
    const search = window.location.search
    const params = AdjustWebParams.parse(new URLSearchParams(search))
    if (!params.appToken) return null
    return new TrackerAdjustWeb(params, params.appToken)
  }

  buildHybird() {
    return new TrackerAdjustNative()
  }
}

export class TrackerBuilderAdjustOnlyHybird extends TrackerBuilderAdjustBase {
  buildWeb() {
    return null
  }

  buildHybird() {
    return new TrackerAdjustNative()
  }
}
