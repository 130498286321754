import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'

// 末尾渐隐效果
export function FadingBox(props: BoxProps) {
  return (
    <Box
      position='relative'
      _after={{
        content: "''",
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        width: '64px',
        height: '100%',
        zIndex: 999,
        background:
          'linear-gradient(270deg, var(--chakra-colors-bg-appLayout-content) 0%, transparent 100%)',
      }}
      {...props}
    />
  )
}
