import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  createStylesContext,
  HStack,
  Icon,
  Image,
  Text,
} from '@chakra-ui/react'
import React from 'react'

import { getIcon } from '@/utils/get-icon'
import useCustomizedStyle from '@/utils/hooks/useCustomizedStyle'
import { isSupportedImageUrl } from '@/utils/tools'

import LazyImage from '../lazy-image/LazyImage'
import type { Variant, VariantsStyles } from './constants'
import { NAME, PARTS } from './constants'

const [StylesProvider, useStyles] = createStylesContext(NAME)

export default function AtomNavAccordionItem(props: {
  headingProps: AtomNavAccordionButtonProps
  children?: React.ReactNode
  variant?: Variant
}) {
  const { headingProps, children, variant = 'simple' } = props

  const variants: VariantsStyles = {
    simple: {
      accordionItem: {
        border: 0,
        '--bg-color': 'var(--chakra-colors-gray-50)',
      },
      panel: {
        m: '0',
        px: '0',
        py: '2.5',
        bg: 'var(--bg-color)',
        borderBottomRadius: 'lg',
      },
      buttonRoot: {
        h: '9',
        p: '0',
        bg: 'var(--bg-color)',
        color: 'gray.900',
        borderRadius: 'md',
        '--icon-color': 'var(--chakra-colors-text-base)',
        _hover: {
          bg: 'var(--bg-color)',
        },
        _expanded: {
          borderRadius: 'lg',
          borderBottomRadius: 'none',
        },
      },
      buttonContent: {
        px: '3',
        w: 'full',
      },
      buttonHeading: {
        textStyle: 'h6',
        fontWeight: '700',
        color: 'text.accent',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      buttonBeforeIcon: {
        boxSize: '5',
        color: 'var(--icon-color)',
      },
      buttonSubheading: {},
    },
    image: {
      accordionItem: {
        border: 0,
        '--bg-color': 'var(--chakra-colors-gray-500)',
      },
      panel: {
        bg: 'var(--bg-color)',
        borderRadius: 'lg',
        mt: '2',
        p: '1.5',
        pb: '3',
      },
      buttonRoot: {
        h: 16,
        borderRadius: 'lg',
        bg: headingProps.bg,
        _hover: { bg: headingProps.bg },
        textTransform: 'uppercase',
      },
      buttonContent: {
        spacing: '4',
        w: 'full',
      },
      buttonHeading: {
        color: 'text.accent',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textStyle: 'h7',
        noOfLines: 1,
      },
      buttonSubheading: {
        color: 'text.base',
        textStyle: 'text6',
        noOfLines: 1,
      },
    },
  }

  const styles = useCustomizedStyle(NAME, variants[variant], PARTS, {
    variant,
  })

  return (
    <StylesProvider value={styles}>
      <AccordionItem sx={styles.accordionItem}>
        <AtomNavAccordionButton {...headingProps} variant={variant} />
        <AccordionPanel sx={styles.panel}>{children}</AccordionPanel>
      </AccordionItem>
    </StylesProvider>
  )
}

export interface AtomNavAccordionButtonProps {
  heading?: string
  subheading?: string
  bg?: string
  icon?: string
  elementBefore?: React.ReactNode
  variant?: Variant
  isExpanded?: boolean
}

function AtomNavAccordionButton(props: AtomNavAccordionButtonProps) {
  const {
    heading,
    subheading,
    elementBefore,
    icon = '',
    variant = 'simple',
  } = props
  const styles = useStyles()

  const elementBeforeRender = () => {
    if (elementBefore) {
      return elementBefore
    } else if (isSupportedImageUrl(icon)) {
      return (
        <LazyImage
          src={icon}
          objectFit='contain'
          sx={styles.buttonBeforeIcon}
        />
      )
    } else {
      return <Icon as={getIcon(icon)} sx={styles.buttonBeforeIcon} />
    }
  }

  return (
    <AccordionButton sx={styles.buttonRoot}>
      <HStack sx={styles.buttonContent}>
        <Box flexShrink='0'>{elementBeforeRender()}</Box>
        <Box flex='1' textAlign='start' overflow='hidden'>
          <Text sx={styles.buttonHeading}>{heading}</Text>
          {variant === 'simple' || (
            <Text sx={styles.buttonSubheading}>{subheading}</Text>
          )}
        </Box>
        <Center flexShrink='0'>
          {variant === 'image' ? (
            <AccordionIcon
              as={Image}
              src='/assets/images/side-nav/accordion-heading-arrow.png'
              boxSize='6'
              objectFit='contain'
            />
          ) : (
            <AccordionIcon
              as={getIcon('ArrowDown')}
              boxSize='4'
              color='var(--icon-color)'
            />
          )}
        </Center>
      </HStack>
    </AccordionButton>
  )
}
