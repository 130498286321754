import { api } from '@/services/api'
import type { Response } from '@/services/types'

import { getUserId, getUserToken } from '../user/storage'
import type { AuthQueryArgs } from '../user/userApi'
import type {
  BankruptModelBankruptRechargeCfgV2,
  RechargePackageProps,
} from './type'

export const activityApi = api.injectEndpoints({
  endpoints: builder => ({
    rechargePackageGiftList: builder.query<
      Response<RechargePackageProps[]>,
      void
    >({
      query: () => ({
        url: '/api/v1/recharge-gift/list',
        method: 'POST',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
      providesTags: ['Login'],
    }),
    // 获取充值活动列表
    getRechargeActList: builder.query<Response<any>, AuthQueryArgs>({
      query: data => ({
        url: '/api/v2/recharge-task/list',
        method: 'POST',
        data: {
          ...data,
        },
      }),
    }),
    // 获取某个活动是否有资格参与
    getRechargeActQualification: builder.mutation<
      Response<any>,
      AuthQueryArgs & { id?: number | string; device?: string }
    >({
      query: data => ({
        url: '/api/v2/recharge-task/check_join',
        method: 'POST',
        data,
      }),
    }),
    /**
     * 获取后台是否启用活动中心列表
     */
    getActivityCenterListSwitch: builder.query<
      Response<{ enable: number; param: string }>,
      void
    >({
      query: () => ({
        url: '/feat_switch/activity_center',
        method: 'GET',
      }),
    }),
    /**
     * 破产充值活动V3
     */
    getBankruptList: builder.mutation<
      Response<BankruptModelBankruptRechargeCfgV2[]>,
      void
    >({
      query: () => ({
        url: '/api/v2/bankrupt/top',
        method: 'POST',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
    }),
    /**
     * 破产充值活动触发金额
     */
    getBankruptTrigger: builder.query<
      Response<{ gmp: number; in_house: number; third: number }>,
      void
    >({
      query: () => ({
        url: '/api/v2/bankrupt/trigger',
        method: 'POST',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
    }),
    /**
     * 获取是否满足充值活动赠金未领取
     */
    getRechargeGiftAmountStatus: builder.mutation<
      Response<{ can: boolean }>,
      void
    >({
      query: () => ({
        url: '/api/v2/recharge-task/private_pop',
        method: 'POST',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
    }),
    /**
     * 获取后台是否启用了人工发奖充值活动
     */
    getRechargeTaskPrivateSwitch: builder.query<
      Response<Response<{ enable: number; param: string }>>,
      void
    >({
      query: () => ({
        url: '/feat_switch/RECHARGE_TASK_PRIVATE',
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useRechargePackageGiftListQuery,
  useLazyRechargePackageGiftListQuery,
  useGetRechargeActListQuery,
  useGetRechargeActQualificationMutation,
  useLazyGetRechargeActListQuery,
  useLazyGetActivityCenterListSwitchQuery,
  useGetBankruptListMutation,
  useGetRechargeGiftAmountStatusMutation,
  useGetRechargeTaskPrivateSwitchQuery,
  useGetBankruptTriggerQuery,
} = activityApi
