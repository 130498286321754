import {
  createDraftSafeSelector,
  createSelector,
  createSlice,
  isAllOf,
} from '@reduxjs/toolkit'

import { isFeatureEnabled } from '@/common/feature'
import i18n from '@/common/locale/i18n'
import { startAppListening } from '@/modules/app/listenerMiddleware'
import { type RootState, store } from '@/modules/app/store'
import {
  openModal,
  setModalData,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { getConfigLng } from '@/utils/tools'

import { moneyRainApi } from '../api/moneyRainApi'
import type {
  MoneyRainAct,
  MoneyRainActInfo,
  UserReward,
} from '../types/moneyRainTypes'

interface StateProps {
  // 正在进行的活动
  startingAct: MoneyRainAct | undefined
  // 活动预告
  nextActs: MoneyRainAct[]
  // 下一场活动
  nextAct?: MoneyRainAct
  // 用户的奖品列表
  userReward: UserReward[]
  // 用户的总奖品数
  userRewardTotal: number
  // 用户抽中的奖金
  userDrawResult: number
  // 用户可参与活动的次数
  userDrawCount: number
  //  活动状态  true 代表已经结束 false 代表正在进行中
  actStatus?: boolean
  // 活动信息
  moneyRainInfos?: MoneyRainActInfo[]
  // 是否正在游戏中
  isPlaying?: boolean
}

const initialState: StateProps = {
  startingAct: undefined,
  nextAct: undefined,
  nextActs: [],
  userReward: [],
  userRewardTotal: 0,
  userDrawResult: 0,
  userDrawCount: 0,
  actStatus: undefined,
  moneyRainInfos: [],
  isPlaying: false,
}

const lng = getConfigLng()

export const moneyRainSlice = createSlice({
  name: 'moneyRainSlice',
  initialState,
  reducers: {
    changeStartingAct(state, { payload }) {
      state.startingAct = payload
    },
    changeNextAct(state, { payload }) {
      state.nextAct = payload
    },
    changeNextActs(state, { payload }) {
      state.nextActs = payload
    },
    //
    changeUserDrawResult(state, { payload }) {
      state.userDrawResult = payload.amount
    },
    changeIsPlaying(state, { payload }) {
      state.isPlaying = payload
    },
  },
  extraReducers: builder => {
    builder
      // .addCase(openModal, (state, { payload }) => {
      //   if (payload.key === GlobalModalKey.MoneyRain && !state.isShowedModal) {
      //     state.isShowedModal = true
      //   }
      // })
      .addMatcher(
        moneyRainApi.endpoints.getStartingMoneyRainAct.matchFulfilled,
        (state, { payload }) => {
          state.startingAct = payload.data.Act?.[0] || undefined
          state.actStatus = payload.data.status
        },
      )
      .addMatcher(
        moneyRainApi.endpoints.getNextMoneyRainAct.matchFulfilled,
        (state, { payload }) => {
          state.nextActs = payload.data.Act || []
          state.actStatus = payload.data.status
          state.nextAct = payload.data.Act?.[0] ?? undefined
        },
      )
      .addMatcher(
        moneyRainApi.endpoints.getUserReward.matchFulfilled,
        (state, { payload }) => {
          state.userReward = payload.data?.list || []
          state.userRewardTotal = payload.data?.total || 0
        },
      )
      .addMatcher(
        moneyRainApi.endpoints.drawMoneyRain.matchFulfilled,
        (state, { payload }) => {
          state.userDrawResult = payload.data
        },
      )
      .addMatcher(
        moneyRainApi.endpoints.getDrawCount.matchFulfilled,
        (state, { payload }) => {
          state.userDrawCount = payload.data as number
        },
      )
      .addMatcher(
        moneyRainApi.endpoints.getShowMoneyRainAct.matchFulfilled,
        (state, { payload }) => {
          state.moneyRainInfos = payload.data.list
        },
      )
  },
})

export const moneyRainReducer = moneyRainSlice.reducer

export const {
  changeNextAct,
  changeStartingAct,
  changeNextActs,
  changeUserDrawResult,
  changeIsPlaying,
} = moneyRainSlice.actions

const selectSelf = (state: RootState) => state

export const selectMoneyRain = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => state.moneyRain,
)

// 获取正在进行中的活动
export const selectStartingAct = createSelector(
  selectMoneyRain,
  moneyRain => moneyRain.startingAct,
)

// 获取最近的活动们
export const selectNextActs = createSelector(
  selectMoneyRain,
  moneyRain => moneyRain.nextActs,
)

// 获取下一场活动
export const selectNextAct = createSelector(
  selectMoneyRain,
  moneyRain => moneyRain.nextAct,
)

// 是否显示入口按钮
export const selectIsShowEntryButton = createSelector(
  selectMoneyRain,
  moneyRain => {
    const isEnableMoneyRain = isFeatureEnabled('moneyRain')
    if (!isEnableMoneyRain) return false
    // 如果没有正在进行中的活动，也没有下一场活动，就不显示入口按钮
    const { startingAct, nextAct } = moneyRain
    if (!startingAct?.guid && !nextAct) return false
    return true
  },
)

// 获取用户已抽取的次数
export const selectUserDrawCount = createSelector(
  selectMoneyRain,
  moneyRain => moneyRain.userDrawCount,
)

// 是否显示正在进行中的Banner
export const selectIsShowMoneyRainBanner = createSelector(
  selectStartingAct,
  selectUserDrawCount,
  (staringAct, drawCount) => {
    const isEnableMoneyRain = isFeatureEnabled('moneyRain')
    if (!isEnableMoneyRain) return false
    return staringAct && drawCount === 0
  },
)

// 获取下一场活动
export const selectNextMoneyRainAct = createSelector(
  selectMoneyRain,
  moneyRain => {
    const { nextAct } = moneyRain
    if (!nextAct) return undefined
    return nextAct
  },
)

// 获取用户的奖品列表
export const selectUserRewards = createSelector(
  selectMoneyRain,
  moneyRain => moneyRain.userReward,
)

// 获取用户的总奖品数
export const selectUserRewardTotal = createSelector(
  selectMoneyRain,
  moneyRain => moneyRain.userRewardTotal,
)

// 获取用户抽中的奖金
export const selectUserDrawResult = createSelector(
  selectMoneyRain,
  moneyRain => moneyRain.userDrawResult,
)

// 当前入口按钮是否显示红点
export const selectIsShowEntryButtonRedDot = createSelector(
  selectUserDrawCount,
  selectStartingAct,
  selectIsShowEntryButton,
  (userDrawCount, startingAct, isShowEntryButton) => {
    if (!isShowEntryButton) return false
    if (!startingAct?.guid) return false
    // 只有当用户的抽取次数为0时，才显示红点
    return userDrawCount === 0
  },
)

// 获取当前活动状态
export const selectMoneyRainStatus = createSelector(
  selectMoneyRain,
  selectStartingAct,
  selectNextAct,
  selectUserDrawCount,
  (moneyRain, startingAct, nextAct, drawCount) => {
    // 如果actStaus返回了true,表示所有活动都已经结束了
    if (moneyRain.actStatus) return 'allActEnd'
    if (startingAct) {
      if (drawCount === 0) return 'startingAndNotDraw'
      return nextAct ? 'nextAct' : 'actEnd'
    }
    if (nextAct) return 'nextAct'
    return 'actEnd'
  },
)

// 获取当前平台的活动展示信息
export const selectMoneyRainInfos = createSelector(selectMoneyRain, moneyRain =>
  moneyRain.moneyRainInfos?.map(item => {
    const language_content = JSON.parse(item.language_content ?? '{}')
    return {
      ...item,
      title: language_content?.[lng]?.title || '',
    }
  }),
)

// 获取最后一场活动信息
export const selectLastMoneyRainInfo = createSelector(
  selectMoneyRain,
  moneyRain => {
    if (!moneyRain.nextActs) return undefined
    return moneyRain.nextActs.find(item => item.is_last)
  },
)

// 是否只有一场活动了
export const selectIsOnlyOneAct = createSelector(selectNextAct, nextAct => {
  return nextAct?.is_last
})

// 是否正在游戏中
export const selectIsPlaying = createSelector(
  selectMoneyRain,
  moneyRain => moneyRain.isPlaying,
)
