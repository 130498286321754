import { api } from '@/services/api'
import type { Response } from '@/services/types'

import type { AuthQueryArgs } from '../user/userApi'
import type { BindCurrencyInfo, Currency } from './types'

export const currencyApi = api.injectEndpoints({
  endpoints: builder => ({
    // 币种列表
    currencyList: builder.query<Response<Currency[]>, AuthQueryArgs>({
      query: data => ({
        url: `/api/v1/platform/asset_order/user_bind_currency/currency_list`,
        method: 'POST',
        data,
      }),
      providesTags: ['Auth'],
    }),
    // 绑定币种信息
    bindCurrencyInfo: builder.query<Response<BindCurrencyInfo>, AuthQueryArgs>({
      query: data => ({
        url: `/api/v1/platform/asset_order/user_bind_currency/info`,
        method: 'POST',
        data,
      }),
    }),
    // 绑定币种
    bindCurrency: builder.mutation<
      Response<BindCurrencyInfo>,
      AuthQueryArgs & { currency_guid: string }
    >({
      query: data => ({
        url: `/api/v1/platform/asset_order/user_bind_currency/bind`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Auth', 'newVip'],
    }),
    // 币种汇率列表
    currencyRateInfo: builder.query<any, any>({
      query: data => ({
        url: '/api/v1/platform/asset_order/agent_currency_config/currency_list',
        method: 'POST',
        data,
      }),
    }),
    // 币种固定比例接口
    currencyFixedRateInfo: builder.query<any, any>({
      query: data => ({
        url: '/api/v1/platform/asset_order/currency_exchange_config/list',
        method: 'GET',
        data,
      }),
    }),
  }),
})

export const {
  useCurrencyListQuery,
  useBindCurrencyInfoQuery,
  useBindCurrencyMutation,
  useLazyCurrencyRateInfoQuery,
  useCurrencyRateInfoQuery,
  useCurrencyFixedRateInfoQuery,
} = currencyApi
