import type { Reducer } from '@reduxjs/toolkit'
import { configureStore } from '@reduxjs/toolkit'
import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'

import { drawActivityReducer } from '@/modules/activity/draw-activity/store/reducer-slice'
import { luckyWheelReducer } from '@/modules/activity/lucky-wheel/luckyWheelSlice'
import { api } from '@/services/api'
import { gmpDemoApi } from '@/services/gmp-demo-api'
import { jsonApi, pageConfigApi } from '@/services/jsonApi'
import { features } from '@/websites/current/property.json'

import { actReducer } from '../activity/ActSlice'
import { moneyRainReducer } from '../activity/money-rain/store/monyRainSlice'
import { appLayoutReducer } from '../app-layout/app-layout-slice'
import { autoModalReducer } from '../auto-modal/ModalSlice'
import { currencyModalReducer } from '../bind-currency/currencySlice'
import { bindPhoneModalReducer } from '../bind-phone/bindPhoneSlice'
import { gameNewReducer } from '../game/gameApiSlice'
import { gameReducer } from '../game/gameSlice'
import { globalModalReducer } from '../global-modal/globalModalSlice'
import { guideEventReducer } from '../guide-event/guideEventSlice'
import { newLoginAndSignUpConfigReducer } from '../login/New/newLoginAndSignUpConfigSlice'
import { loginRewardReducer } from '../login-reward-modal/loginRewardSlice'
import { loginAndSignUpAuthConfigReducer } from '../new-login/loginAndSignUpAuthConfigSlice'
import type { ReferralDetailsStateNew } from '../new-referral/referral-details-slice'
import { referralDetailsNewReducer } from '../new-referral/referral-details-slice'
import type { ReferralStateNew } from '../new-referral/referralSlice'
import { referralNewReducer } from '../new-referral/referralSlice'
import { newVipReducer } from '../new-vip/newVipSlice'
import { recahrgeActModalReducer } from '../recharge-activity/rechargeActSlice'
import type { ReferralDetailsState } from '../referral/referral-details-slice'
import { referralDetailsReducer } from '../referral/referral-details-slice'
import type { ReferralState } from '../referral/referralSlice'
import { referralReducer } from '../referral/referralSlice'
import { authMiddleware, userReducer } from '../user/userSlice'
import vipReducer from '../vip/store/vipSlice'
import { walletModalReducer } from '../wallet/walletSlice'
import { wheelModalReducer } from '../wheel-modal/wheelModalSlice'
import { listenerMiddleware } from './listenerMiddleware'
import modalsReducer from './modalSlice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    globalModal: globalModalReducer,
    modals: modalsReducer,
    appLayout: appLayoutReducer,
    wallet: walletModalReducer,
    referralDetails: (features?.referralNew
      ? referralDetailsNewReducer
      : referralDetailsReducer) as Reducer<
      ReferralDetailsState | ReferralDetailsStateNew
    >,
    referral: (features?.referralNew
      ? referralNewReducer
      : referralReducer) as Reducer<ReferralStateNew | ReferralState>,
    // referralDetailsNew: referralDetailsNewReducer,
    // referralNew: referralNewReducer,
    newVip: newVipReducer,
    loginReward: loginRewardReducer,
    wheelModal: wheelModalReducer,
    act: actReducer,

    currencyModal: currencyModalReducer,
    gameModal: gameReducer,
    gameNewModal: gameNewReducer,
    autoModal: autoModalReducer,
    bindPhoneModal: bindPhoneModalReducer,
    rechargeModal: recahrgeActModalReducer,
    guideEvent: guideEventReducer,
    luckyWheel: luckyWheelReducer,
    drawActivity: drawActivityReducer,
    moneyRain: moneyRainReducer,
    newLoginAndSignUpConfig: newLoginAndSignUpConfigReducer,
    loginAndSignUpAuthConfig: loginAndSignUpAuthConfigReducer,

    vip: vipReducer,
    [api.reducerPath]: api.reducer,
    [jsonApi.reducerPath]: jsonApi.reducer,
    [pageConfigApi.reducerPath]: pageConfigApi.reducer,
  },

  middleware: gDM =>
    gDM()
      .prepend(listenerMiddleware.middleware)
      .concat(api.middleware)
      .concat(jsonApi.middleware)
      .concat(pageConfigApi.middleware)
      .concat(gmpDemoApi.middleware)
      .concat(authMiddleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
