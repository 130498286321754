import { useCallback, useState } from 'react'

import useBalanceDecrease from '@/utils/hooks/useBalanceDecrease'
import { localStorage } from '@/utils/localStorage'
import { delay } from '@/utils/tools'

import type { GameFavoriteTipsDumpy } from './GameFavoriteTips'
import GameFavoriteTips from './GameFavoriteTips'

export function useIsFavoriteTipsShow(
  gameId: string,
  isFavorited = false,
): [boolean, () => void] {
  const [hasBet, setHasBet] = useState(false)
  const [isShow, setIsShow] = useState(false)

  const balanceDecreaseCb = useCallback(async () => {
    if (hasBet) return
    if (isFavorited) return
    setHasBet(true)
    await delay(5000)
    hanldeTipsWithStorage(gameId, tips => {
      if (tips.shouldShow) {
        setIsShow(true)
        tips.afterShow()
      }
    })
    await delay(5000)
    setIsShow(false)
  }, [gameId, hasBet, isFavorited])

  useBalanceDecrease(balanceDecreaseCb)

  const afterFavorite = useCallback(() => {
    setIsShow(false)
    hanldeTipsWithStorage(gameId, tips => {
      tips.afterCollection()
    })
  }, [gameId])

  return [isShow, afterFavorite]
}

const LOCAL_STORAGE_KEY = 'favoriteGames'

type Handler = (tips: GameFavoriteTips) => void

function hanldeTipsWithStorage(gameId: string, handler: Handler) {
  const localData = localStorage.getItem(LOCAL_STORAGE_KEY)
  const originData = (localData ? JSON.parse(localData) : {}) as Record<
    string,
    GameFavoriteTipsDumpy
  >
  const originRecord = originData[gameId]
  const tips = originRecord
    ? GameFavoriteTips.load(originRecord)
    : new GameFavoriteTips(gameId)
  handler(tips)
  originData[gameId] = tips.dump()
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(originData))
}

export function clearFavoriteTipsStorage() {
  localStorage.removeItem(LOCAL_STORAGE_KEY)
}
