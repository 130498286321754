import { Box, useMediaQuery, useTheme } from '@chakra-ui/react'
import React, { memo, useCallback, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { useLargerThanMobile } from '@/utils/hooks'

import {
  closeDrawerStart,
  createDrawerOpenSelector,
  createDrawerStatusSelector,
  DrawerKey,
  DrawerOpenStatus,
  drawerTransitionEnd,
  openDrawerStart,
} from './app-layout-slice'
import {
  BOTTOM_BAR_HEIGHT,
  HEADER_HEIGHT,
  LEFT_DRAWER_WIDTH,
} from './constants'
import SideNav from './SideNav'
import type { PageChangeEvent } from './usePageChangeNotification'
import { usePageChangeSubscription } from './usePageChangeNotification'

function _LeftDrawer() {
  const dispatch = useAppDispatch()

  const leftDrawerOpenStatus = useAppSelector(
    createDrawerStatusSelector(DrawerKey.Left),
  )

  const isLeftDrawerOpen = useAppSelector(
    createDrawerOpenSelector(DrawerKey.Left),
  )

  useLeftDrawerScrollLock(isLeftDrawerOpen)
  useLeftDrawerAutoToggler()

  const handleLeftDrawerTranslationEnd = () => {
    dispatch(drawerTransitionEnd(DrawerKey.Left))
  }

  const handleDrawerMaskClick = () => {
    dispatch(closeDrawerStart(DrawerKey.Left))
  }

  const drawerStyles: React.ComponentProps<typeof Box> = {
    position: ['fixed', 'sticky'],
    paddingTop: `calc(${HEADER_HEIGHT} + env(safe-area-inset-top))`,
    width: ['100%', 'auto'],
    overflow: 'hidden',
    top: '0',
    zIndex: 'sticky',
  }

  const drawerBaseStyles: React.ComponentProps<typeof Box> = {
    marginTop: `-${HEADER_HEIGHT}`,
  }

  const isClosed = leftDrawerOpenStatus === DrawerOpenStatus.Closed
  return (
    <Box {...drawerBaseStyles}>
      <Box {...drawerStyles} width={[isClosed ? '0px' : 'full', 'auto']}>
        <Box
          height={[
            `calc(100vh - ${HEADER_HEIGHT} - env(safe-area-inset-top) - ${BOTTOM_BAR_HEIGHT})`,
            `calc(100vh - ${HEADER_HEIGHT})`,
          ]}
        >
          <Box
            aria-label='mask'
            position='absolute'
            left='0'
            top='0'
            display={['block', 'none']}
            w='full'
            h='full'
            transition='opacity 0.3s'
            bg='blackAlpha.500'
            backdropFilter='blur(10px)'
            opacity={isLeftDrawerOpen ? '1' : '0'}
            onClick={handleDrawerMaskClick}
          />
          <Box
            h='full'
            w={LEFT_DRAWER_WIDTH}
            overflow='hidden'
            transform={[
              isLeftDrawerOpen
                ? 'translate3d(0px, 0px,0px)'
                : 'translate3d(-100%, 0px,0px)',
              'none',
            ]}
            transition='transform 0.3s'
            onTransitionEnd={handleLeftDrawerTranslationEnd}
          >
            {isClosed ? null : <SideNav />}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

function useLeftDrawerScrollLock(isOpen: boolean) {
  const theme = useTheme()
  const [isLargerThanSm] = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`)
  useEffect(() => {
    const isBodyOverflowHidden = document.body.style.overflow === 'hidden'
    if (!isLargerThanSm) {
      if (isOpen && !isBodyOverflowHidden) {
        document.body.style.overflow = 'hidden'
      } else if (!isOpen && isBodyOverflowHidden) {
        document.body.style.overflow = 'auto'
      }
      return
    } else {
      if (isBodyOverflowHidden) {
        document.body.style.overflow = 'auto'
      }
    }
  }, [isOpen, isLargerThanSm])
}

function useLeftDrawerAutoToggler() {
  const dispatch = useAppDispatch()
  const isLargerThanMobile = useLargerThanMobile()

  // 移动设备时,当导航到新的页面时，自动关闭左侧菜单
  const closeOnPageChange = useCallback(
    ({ pathname, prevPathname }: PageChangeEvent) => {
      if (!isLargerThanMobile && prevPathname !== pathname) {
        dispatch(closeDrawerStart(DrawerKey.Left))
      }
    },
    [dispatch, isLargerThanMobile],
  )
  usePageChangeSubscription(closeOnPageChange)

  // 切换到桌面设备时，自动打开左侧菜单
  useEffect(() => {
    if (isLargerThanMobile) {
      dispatch(openDrawerStart(DrawerKey.Left))
    } else {
      dispatch(closeDrawerStart(DrawerKey.Left))
    }
  }, [dispatch, isLargerThanMobile])
}

const LeftDrawer = memo(_LeftDrawer)

export default LeftDrawer
