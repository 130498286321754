import { useEffect, useRef } from 'react'

import { useAppSelector } from '@/modules/app/store'

import { selectIsChristmasDressUpEnable } from '../api'
import { setupSnow } from './setup-snow'

function SnowBackdrop() {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  useEffect(() => {
    if (canvasRef.current) {
      setupSnow(canvasRef.current)
    }
  }, [])

  return <canvas ref={canvasRef} />
}

export default function SnowBackdropWithFeatSwitch() {
  const isChristmasDressUpEnable = useAppSelector(
    selectIsChristmasDressUpEnable,
  )
  if (isChristmasDressUpEnable) {
    return <SnowBackdrop />
  } else {
    return null
  }
}
