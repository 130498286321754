import dayjs from 'dayjs'
import { useCallback, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { closeModal, openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { useUserAuth } from '@/utils/hooks'
import { localStorage } from '@/utils/localStorage'

import { appLayoutEvents } from '../app-layout/appLayoutEvents'
import type { dialog_type } from './ModalInterface'
import {
  selectModalLoaded,
  selectModalQueue,
  selectValidModalQueue,
  setCurrentModalId,
  setInited,
  setModalQueue,
  setShowType,
  shiftValidModalQueue,
} from './ModalSlice'

/**
 * 自动弹窗列表配置
 */
const type2GlobalKey: Record<dialog_type, GlobalModalKey> = {
  1: GlobalModalKey.Announce,
  2: GlobalModalKey.SplashAds,
  3: GlobalModalKey.LoginReward,
  4: GlobalModalKey.TgModal,
  5: GlobalModalKey.IntroductionToDownloadModal,
  6: GlobalModalKey.LuckyWheelActivity,
  7: GlobalModalKey.MoneyRain,
  8: GlobalModalKey.RechargePackage,
}

/**
 * 设置弹窗缓存
 * @param userId
 * @param id
 */
export function setModalStorage(userId: string, id: string, checked?: number) {
  userId = userId || 'unlogin'
  const today = dayjs().format('YYYY-MM-DD')
  const key = 'modalCfgState_' + userId
  const defaultData = { [today]: {} }
  const dataStr = localStorage.getItem(key) || JSON.stringify(defaultData)
  let todayStorage = {} as any
  const data = JSON.parse(dataStr) || {}
  todayStorage = data || {}
  const strObj = {
    ...data,
    [today]: {
      ...(todayStorage[today] || {}),
      [id]: {
        ...(todayStorage?.[today]?.[id] || {}),
        show_count:
          (todayStorage?.[today]?.[id]?.show_count || 0) +
          (checked === undefined ? 1 : 0),
        hide:
          checked === undefined
            ? todayStorage?.[today]?.[id]?.hide || 0
            : checked,
      },
    },
  }
  localStorage.setItem(key, JSON.stringify(strObj))
}

export const useModalConfig = () => {
  const dispatch = useAppDispatch()
  const [userId] = useUserAuth()
  const validModalQueue = useAppSelector(selectValidModalQueue)

  const modalQueue = useAppSelector(selectModalQueue)

  const modalLoaded = useAppSelector(selectModalLoaded)

  const reset = () => {
    dispatch(setShowType([]))
    dispatch(setModalQueue(false))
    dispatch(setInited(false))
  }

  /**
   * 打开弹窗
   */
  const onStartModal = useCallback(() => {
    const tmpValidQueue = validModalQueue?.slice() || []
    const showModal = tmpValidQueue[0]
    if (showModal && !!type2GlobalKey[showModal?.type]) {
      dispatch(openModal({ key: type2GlobalKey[showModal.type] }))
      dispatch(shiftValidModalQueue(tmpValidQueue.slice(1)))
      dispatch(setCurrentModalId(showModal.id))
      setModalStorage(userId, showModal.id)
    }
    if (tmpValidQueue.length === 0) {
      appLayoutEvents.emit('setNotificationListIsModalAllClose', true)
      reset()
    }
  }, [validModalQueue, userId])

  /** 关于站内信弹出 */
  useEffect(() => {
    if (modalQueue?.length === 0 && modalLoaded) {
      setTimeout(() => {
        appLayoutEvents.emit('setNotificationListIsModalAllClose', true)
      }, 100)
    }
  }, [modalQueue?.length, modalLoaded])

  /**
   * 关闭弹窗
   */
  const onCloseModal = (key: GlobalModalKey, isStop?: boolean) => {
    dispatch(closeModal({ key }))
    !isStop && onStartModal()
    isStop && reset()
  }

  return {
    onCloseModal,
    onStartModal,
  }
}
