import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { isProduction, isStaging } from '@/common/env'

import type { Response } from './types'

const DOMAIN = (() => {
  if (isProduction()) {
    return 'tapi.gaming-panda.com'
  } else if (isStaging()) {
    return 'gatesrv.pre-release.xyz'
  } else {
    return 'gatesrv.dev.pre-release.xyz'
  }
})()

const BASE_URL = `https://${DOMAIN}/demo/gmp`

interface LoginReqArgs {
  user_id: string
  user_name: string
  amount: string
  project_name: string
}

interface LoginResData {
  amount: string
  user_id: string
  user_name: string
}

interface PlayReqArgs {
  user_id: string
  username: string
  user_token: string
  currency: string
  game_id: string
  lang: string
}

interface PlayResData {
  game_url: string
  user_name: string
}

export const gmpDemoApi = createApi({
  reducerPath: 'gmpDemo',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),
  endpoints: builder => ({
    login: builder.mutation<Response<LoginResData>, LoginReqArgs>({
      query: body => {
        return {
          url: '/login',
          method: 'POST',
          body,
        }
      },
    }),
    play: builder.mutation<Response<PlayResData>, PlayReqArgs>({
      query: body => {
        return {
          url: '/play',
          method: 'POST',
          body,
        }
      },
    }),
  }),
})

export const { useLoginMutation, usePlayMutation } = gmpDemoApi
