import { Flex, HStack, Icon, Link } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import support_black from '@/assets/images/support.png'
import support_white from '@/assets/images/support_white.png'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  selectWallet,
  setIsFromRechargeAct,
  WalletModalTab,
} from '@/modules/wallet/walletSlice'
import { Icon as SharedIcon } from '@/utils/atom-shared'

import { closeModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { useWalletService } from './useWalletService'

export default function WalletService(props: {
  color?: string
  support_icon?: string
  cb?: () => void
  hideRecord?: boolean
}) {
  const { color = 'link', support_icon = 'black', cb, hideRecord } = props
  const dispatch = useAppDispatch()
  const openWalletService = useWalletService()
  const { t } = useTranslation()
  const activeTab = useAppSelector(state => selectWallet(state).activeTab)
  const serviceClick = () => {
    openWalletService()
  }
  const supportIcon = useMemo(() => {
    return support_icon === 'black' ? support_black : support_white
  }, [support_icon])
  return (
    <Flex mt='3' alignItems='center' justifyContent='space-between'>
      {!hideRecord && (
        <HStack spacing='4px' color={color}>
          <Icon as={SharedIcon.Detail} boxSize='5' ml='0' />
          <Link
            as={RouterLink}
            to='/user/transaction-history'
            state={{ activeTab }}
            textStyle='text6'
            color={color}
            onClick={() => {
              cb && cb()
              dispatch(closeModal({ key: GlobalModalKey.Wallet }))
              dispatch(closeModal({ key: GlobalModalKey.newRechargeActModal }))
              // dispatch(setIsFromRechargeAct(false))
            }}
          >
            {activeTab === WalletModalTab.recharge
              ? t('REACHRGA_TASK_HISTORY')
              : t('WITHDRAW_TASK_HISTORY')}
          </Link>
        </HStack>
      )}

      <LazyImage
        src={supportIcon}
        onClick={serviceClick}
        w='24px'
        h='24px'
        cursor='pointer'
        alt=''
      />
    </Flex>
  )
}
