import {
  Box,
  Center,
  keyframes,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { selelctGlobalLoadingIsOpen } from '@/modules/global-modal/globalModalSlice'
import { getColorModeValue } from '@/utils/tools'

import { hideGlobalLoading } from './global-loading-slice'

export default function GlobalLoading() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isOpen = useAppSelector(selelctGlobalLoadingIsOpen)

  const onClose = () => dispatch(hideGlobalLoading())

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay bg='none' backdropFilter='none' />
      <ModalContent
        w='146px'
        bgColor={getColorModeValue('#D2D6DB80', '#393d5f99')}
        backdropFilter='blur(15px)'
      >
        <Center h='106px' flexDirection='column'>
          <Wrap w='10' transform='rotate(45deg)'>
            <WrapItem>
              <SpinnerItem index={0} />
            </WrapItem>
            <WrapItem>
              <SpinnerItem index={1} />
            </WrapItem>
            <WrapItem>
              <SpinnerItem index={3} />
            </WrapItem>
            <WrapItem>
              <SpinnerItem index={2} />
            </WrapItem>
          </Wrap>
          <Text textStyle='text4' color='text.accent' mt='3'>
            {t('GAME_TEXT_LOADING')}
          </Text>
        </Center>
      </ModalContent>
    </Modal>
  )
}

const fadeAnimationKeyframes = keyframes`
    0% { opacity: 0.8 }
    100% { opacity: 0.2 }
  `

function SpinnerItem(props: { index?: number }) {
  const { index = 0 } = props
  return (
    <Box
      borderRadius='full'
      bgColor='text.accent'
      w='3'
      h='3'
      animation={`${fadeAnimationKeyframes} 2s ${index * 500}ms ease infinite`}
    />
  )
}
