import { getUserId, getUserToken } from '@/modules/user/storage'
import { api } from '@/services/api'

import type { PgsoftApiType } from '../../logic/game-list/data/fundistType'

export interface Response<T = unknown> {
  code: number
  data: T
}

export const walletApi = api.injectEndpoints({
  endpoints: builder => ({
    getSocketUrl: builder.query<Response<PgsoftApiType>, void>({
      query: () => ({
        url: '/third/game/play',
        method: 'POST',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
    }),
  }),
})

export const { useGetSocketUrlQuery } = walletApi
