import { useCallback, useMemo } from 'react'

import { selectFeatSwitch } from '@/modules/app-layout/app-layout-slice'

import { useAppDispatch, useAppSelector } from '../app/store'
import { openModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'

const KEY = 'recharge_withdraw_tutorial'
export function useWalletTutorial() {
  const featSwitchObj = useAppSelector(selectFeatSwitch)
  const dispatch = useAppDispatch()

  const showRechargeTutorial = useMemo(() => {
    return (
      featSwitchObj?.[KEY]?.enable === 1 &&
      !!featSwitchObj?.[KEY]?.param?.recharge_url
    )
  }, [featSwitchObj])

  const showWithdrawTutorial = useMemo(() => {
    return (
      featSwitchObj?.[KEY]?.enable === 1 &&
      !!featSwitchObj?.[KEY]?.param?.withdraw_url
    )
  }, [featSwitchObj])

  const openTutorialModal = useCallback(
    (type: 'recharge' | 'withdraw') => {
      const recharge_url = featSwitchObj?.[KEY]?.param?.recharge_url
      const withdraw_url = featSwitchObj?.[KEY]?.param?.withdraw_url
      const url = type === 'recharge' ? recharge_url : withdraw_url
      url &&
        dispatch(
          openModal({
            key: GlobalModalKey.WalletTutorial,
            data: {
              url,
            },
          }),
        )
    },
    [featSwitchObj],
  )

  return {
    showRechargeTutorial,
    showWithdrawTutorial,
    openTutorialModal,
  }
}
