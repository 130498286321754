import elementResizeDetectorMaker from 'element-resize-detector'
import { debounce } from 'lodash-es'
import React, {
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

/**
 * 监听元素大小发生变化
 * 当前组件需要消耗大量性能, 正确用法是把 ResizeDetector 作为一个容器组件
 * 然后通过 context/props 向子组件分发 width/height
 */
const ResizeDetector = React.forwardRef(
  (
    {
      as = 'div',
      children,
      ...props
    }: {
      /** 目标渲染元素 */
      as?: keyof JSX.IntrinsicElements
      /** 子元素渲染方法 */
      children: ({
        width,
        height,
      }: {
        width: number
        height: number
      }) => React.ReactElement
      [key: string]: any
    },
    ref,
  ) => {
    const innerRef = useRef<HTMLElement>()

    const [mounted, setMounted] = useState(false)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    useLayoutEffect(() => {
      const node = innerRef.current!
      setMounted(true)
      setWidth(node.offsetWidth)
      setHeight(node.offsetHeight)

      const erd = elementResizeDetectorMaker({
        strategy: 'scroll',
      })
      erd.listenTo(
        node,
        debounce((element: HTMLElement) => {
          const { offsetWidth, offsetHeight } = element
          setWidth(offsetWidth)
          setHeight(offsetHeight)
        }, 300),
      )
      return () => erd.uninstall(node)
    }, [])
    useImperativeHandle(ref, () => innerRef.current)

    return React.createElement(
      as,
      { ...props, ref: innerRef },
      mounted ? children({ width, height }) : null,
    )
  },
)
ResizeDetector.displayName = 'ResizeDetector'
export default ResizeDetector
