import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

import theme from './inhouse'

const { switchCheckedColor, switchNormalColor } = theme
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys)

const baseStyle = definePartsStyle({})

export const switchTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    auto: {
      track: {
        bg: switchNormalColor,
        _checked: {
          bg: switchCheckedColor,
        },
      },
    },
  },
})
export default switchTheme
