import { isEmpty } from 'lodash-es'
import { useEffect } from 'react'

import { calcIsRechargeSuccessInLimitTime } from '@/modules/inhouse/logic/components/rechargeCountDown/utils'
import {
  useGetUserInfoQuery,
  useLazyRechargeTrackerQuery,
} from '@/modules/user/userApi'
import { useUserAuth } from '@/utils/hooks/useUserAuth'

/**
 * 支付后轮询结果埋点
 */

export function useRechargeResult() {
  const [userId, token] = useUserAuth()
  const { isLoading: isUserInfoLoading } = useGetUserInfoQuery(
    { token, user_id: userId },
    { skip: !token || !userId },
  )
  const [trigger, result] = useLazyRechargeTrackerQuery({
    pollingInterval: 1000 * 60,
  })

  useEffect(() => {
    // const isAndroid =
    //   navigator.userAgent.indexOf('Android') >= 0 &&
    //   navigator.userAgent.indexOf('wv') >= 0
    // const channel_id = sessionStorage.getItem('channel_id')
    // const channel = sessionStorage.getItem('channel')

    // 去除了 isAndroid 的判断 以及 channel_id 和 channel 的 判断，因为需要轮询支付结果。
    if (userId && token && !isUserInfoLoading) {
      trigger({
        user_id: userId,
        token,
      })
    }
  }, [userId, token, isUserInfoLoading, trigger])

  useEffect(() => {
    const events = result.data?.data?.events
    const could = !isEmpty(events) && result.status === 'fulfilled'
    if (could) {
      calcIsRechargeSuccessInLimitTime()
    }
  }, [result])
}
