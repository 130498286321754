import { Box, Button, Center, Grid, Icon, Text, VStack } from '@chakra-ui/react'
import { t } from 'i18next'
import { useCallback } from 'react'

import { getSign } from '@/common/locale/currency'
import LazyImage from '@/components/lazy-image/LazyImage'
import TitledDialog from '@/components/modal/TitledDialog'
import { openWallet } from '@/modules/wallet/walletSlice'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useUserAuth } from '@/utils/hooks'

import { useAppDispatch, useAppSelector } from '../app/store'
import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { filterActivityByScope } from '../inhouse/logic/RechargeComfirmModal'
import { useRechargeTaskQuery } from './loginRewardModalApi'
import { selectLoginReward } from './loginRewardSlice'

export interface props {
  isOpen: boolean
  onClose(): void
  progressAmount: number
}

export default function RechargeGuideModal() {
  const [userId, token] = useUserAuth()
  const dispatch = useAppDispatch()
  const CURRENCY_SYMBOL = getSign()
  const { open } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.RechargeGuide],
  )
  const rechargeAmount = useAppSelector(
    state => selectLoginReward(state).rechargeAmount,
  )
  const progressAmount = useAppSelector(
    state => selectLoginReward(state).progressAmount,
  )
  const handleClose = useCallback(() => {
    dispatch(closeModal({ key: GlobalModalKey.RechargeGuide }))
  }, [dispatch])
  const goToWallet = () => {
    handleClose()
    dispatch(openWallet({ type: 'recharge', index: 0 }))
  }

  const { taskinfo } = useRechargeTaskQuery(
    { token, user_id: userId },
    {
      selectFromResult: result => ({
        ...result,
        taskinfo: filterActivityByScope(
          (result?.data?.data || [])?.slice(),
        ).sort((a, b) => b.sort - a.sort)[0],
      }),
    },
  )
  return (
    <TitledDialog title='' isOpen={open} onClose={handleClose}>
      <Box mx='-2' mb='-2'>
        <Grid gridTemplateColumns={'20px 1fr'} gap='15px' marginBottom='4'>
          <Center>
            <Icon as={SharedIcon.Question} boxSize='20px' />
          </Center>
          {(progressAmount as number) > 0 ? (
            <Text
              textStyle='text4'
              dangerouslySetInnerHTML={{
                __html:
                  t('VIP_AWARDS_DESCRIPTION_3_COLOR', {
                    text_color: 'var(--chakra-colors-prim-500)',
                    VIP_BONUS_RECHARGE:
                      CURRENCY_SYMBOL +
                      '&nbsp;' +
                      (rechargeAmount - progressAmount) +
                      '/' +
                      rechargeAmount,
                    VIP_BONUS_DAY: t('DAY7'),
                  }) ?? '',
              }}
            />
          ) : (
            <Text textStyle='text4'>{t('VIP_HIGHER_REWARD')}</Text>
          )}
        </Grid>
        <VStack spacing='2.5' marginBottom='4'>
          <LazyImage
            w='full'
            src='/assets/images/login-reward-modal/fail-rewards-1.png'
            alt=''
            borderRadius='lg'
          />
          {taskinfo && taskinfo.image_url && (
            <LazyImage
              w='full'
              src={taskinfo.image_url}
              alt=''
              borderRadius='lg'
            />
          )}
        </VStack>
        <Button
          width='full'
          size='lg'
          colorScheme='fill-prim'
          onClick={goToWallet}
        >
          {t('RECHARGE_TO_BONUS')}
        </Button>
      </Box>
    </TitledDialog>
  )
}
