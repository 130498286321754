/**
 * 破产活动 路由拦截
 */
import React, { useMemo } from 'react'
import type { unstable_BlockerFunction as BlockerFunction } from 'react-router-dom'
import { unstable_useBlocker as useBlocker } from 'react-router-dom'

import { isFeatureEnabled } from '@/common/feature'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { getShowTips, selectIsFreeMode } from '@/modules/game/gameSlice'
import { MINIMAL_BET } from '@/modules/game/third-game/ThirdGameMinimalBet'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { selectUserBalance } from '@/modules/user/userSlice'

import {
  selectBankruptCallbackUrl,
  selectBankruptTrigger,
  setBankruptCallbackUrl,
} from '../ActSlice'

const BankruptRechargeV3 = isFeatureEnabled('bankruptRechargeV3')

export function useBankruptRechargePrompt() {
  const dispatch = useAppDispatch()
  return React.useCallback(
    (callback: string) => {
      dispatch(
        openModal({
          key: GlobalModalKey.BankruptRechargeModal,
          data: { type: 'third' },
        }),
      )
      dispatch(setBankruptCallbackUrl(callback))
    },
    [dispatch],
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export function useBankruptRechargeBlocker() {
  const prompt = useBankruptRechargePrompt()
  const dispatch = useAppDispatch()
  const balance = useAppSelector(state => selectUserBalance(state)?.amount || 0)
  const callBackType = useAppSelector(selectBankruptCallbackUrl)
  const protectShowTips = useAppSelector(getShowTips)
  const bankruptTrigger = useAppSelector(selectBankruptTrigger)
  const isFreeMode = useAppSelector(selectIsFreeMode)

  const realMinimalBet = useMemo(() => {
    if (bankruptTrigger) {
      return (
        bankruptTrigger[
          location.pathname.includes('/game/self/') ? 'gmp' : 'third'
        ] || MINIMAL_BET
      )
    }
    return MINIMAL_BET
  }, [bankruptTrigger])

  const shouldBlock = React.useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      if (protectShowTips) {
        dispatch(setBankruptCallbackUrl(''))
        return false
      }
      if (
        !BankruptRechargeV3 ||
        callBackType === nextLocation.pathname ||
        !currentLocation.pathname.includes('/game/') ||
        currentLocation.pathname.includes('/game/inhouse') ||
        currentLocation.pathname.includes('/game/all') ||
        isFreeMode
      ) {
        dispatch(setBankruptCallbackUrl(''))
        return false
      }
      if (currentLocation.pathname !== nextLocation.pathname) {
        return balance !== undefined && balance < realMinimalBet
      } else {
        dispatch(setBankruptCallbackUrl(''))
        return false
      }
    },
    [balance, callBackType, protectShowTips, realMinimalBet, isFreeMode],
  )

  const blocker = useBlocker(shouldBlock)

  React.useEffect(() => {
    if (blocker.state === 'blocked') {
      prompt(`${blocker.location.pathname}`)
      blocker.reset()
    }
  }, [blocker, prompt, dispatch])
}
