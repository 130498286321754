import { Text } from '@chakra-ui/react'
import { createSelector } from '@reduxjs/toolkit'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { getCurrencyCode } from '@/common/locale/currency'
import AtomDrawer from '@/components/atom-drawer/AtomDrawer'
import TitledDialog from '@/components/modal/TitledDialog'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  closeModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import RechargePanel from '@/modules/wallet/rechargePanel'
import { useGetWalletConfigInfoQuery } from '@/modules/wallet/walletApi'
import {
  getShowVirtualModal,
  selectWallet,
  setIsFromRechargeAct,
} from '@/modules/wallet/walletSlice'
import { useMobile } from '@/utils/hooks'

export default function RechargeActModal() {
  const isMobile = useMobile()
  const dispatch = useAppDispatch()

  const { open } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.newRechargeActModal],
  )

  const { open: MPesaModalOOpen } = useAppSelector(
    createSelector(
      selectModal,
      globalModal => globalModal[GlobalModalKey.MPesaModal],
    ),
  )

  const isParamsDialogOpen = useAppSelector(
    state => selectWallet(state).isParamsDialogOpen,
  )
  const showVirtualModal = useAppSelector(getShowVirtualModal)

  useGetWalletConfigInfoQuery({ currency: getCurrencyCode() })

  const { t } = useTranslation()

  /** 关闭弹窗 */
  const onClose = () => {
    dispatch(closeModal({ key: GlobalModalKey.newRechargeActModal }))
  }

  useEffect(() => {
    dispatch(setIsFromRechargeAct(open))
  }, [open])

  const props = {
    title: <Text color={'white'}>{t('DEPOSIE_BENEFITS')}</Text>,
    isOpen:
      open && !MPesaModalOOpen && !isParamsDialogOpen && !showVirtualModal,
    onClose: onClose,
  }

  if (isMobile) {
    return (
      <AtomDrawer placement='bottom' {...props} colorScheme='normal'>
        <RechargePanel />
      </AtomDrawer>
    )
  } else {
    return (
      <TitledDialog {...props} colorScheme='blackTransparent'>
        <RechargePanel />
      </TitledDialog>
    )
  }
}
