import type { ModalFooterProps, ModalProps } from '@chakra-ui/react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'
import React from 'react'

import { isFromIpa, judgeBigScreen } from '@/common/platform/utils'

import type { IDialog } from './Dialog'

export type TitledDialogProps = IDialog<
  PropsWithChildren<{
    title: string | React.ReactNode
    withInnerFrame?: boolean
    action?: React.ReactNode
    padding?: boolean
    closeColor?: string
    contentBg?: string
    footerStyle?: ModalFooterProps
    bodyProps?: React.ComponentProps<typeof ModalBody>
  }>
> &
  ModalProps

/** 使用时应该配合外层的 useDisclosure / Modal 使用 */
export default function TitledDialog(props: TitledDialogProps) {
  const { footerStyle = {}, bodyProps = {} } = props
  return (
    <Modal
      autoFocus={false}
      trapFocus={false}
      {...props}
      isOpen={props.isOpen}
      onClose={props.onClose}
      isCentered
      scrollBehavior='inside'
      // motionPreset='none'
    >
      <ModalOverlay />
      <ModalContent
        overflowX='hidden'
        // maxHeight={['100vh', '85vh']}
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <ModalHeader
          cursor='default'
          lineHeight='24px'
          padding={props?.padding ? '0px' : ''}
        >
          {props.title}
        </ModalHeader>
        <ModalCloseButton
          color={props?.closeColor ? props?.closeColor : 'inherit'}
          zIndex='2'
          // style={modalCloseStyle}
          top={isFromIpa() && judgeBigScreen() ? '10' : '1.5'}
        />
        <ModalBody
          position='relative'
          padding={props?.padding ? '0px !important' : ''}
          bg={
            props?.contentBg
              ? props.contentBg
              : props.withInnerFrame
              ? 'gray.200'
              : undefined
          }
          {...bodyProps}
        >
          {props.children}
        </ModalBody>
        {props.action ? (
          <ModalFooter {...footerStyle}>{props.action}</ModalFooter>
        ) : null}
      </ModalContent>
    </Modal>
  )
}
