import { Button, Flex, Image, Text } from '@chakra-ui/react'
import { t } from 'i18next'

import EmptyImg from '@/assets/images/lucky-wheel/empty.png'
import type { TitledDialogProps } from '@/components/modal/TitledDialog'
import TitledDialog from '@/components/modal/TitledDialog'

function CommonTipsModal({
  isOpen,
  onClose,
  title,
  img,
  showButton,
  desc,
  buttonText,
  buttonClickCb,
  ...props
}: {
  isOpen: boolean
  onClose?: () => void
  title: string
  img?: string
  desc: string
  showButton?: boolean
  buttonText?: string
  buttonClickCb?: () => void
} & Omit<TitledDialogProps, 'children'>) {
  return (
    <TitledDialog isOpen={isOpen} onClose={onClose} title={title} {...props}>
      <Flex justifyContent='center'>
        <Image w='123px' h='auto' src={img ?? EmptyImg} objectFit='cover' />
      </Flex>
      <Text textStyle='h6' textAlign='center'>
        {desc}
      </Text>
      {showButton ? (
        <Button
          colorScheme='fill-prim'
          w='full'
          whiteSpace='nowrap'
          color='white'
          mt='4'
          py='4'
          h='46px'
          onClick={() => {
            buttonClickCb?.()
          }}
        >
          <Text textStyle='h4'>
            {buttonText ?? t('EVENT_CASHRAIN_WINNING_BUTTON1')}
          </Text>
        </Button>
      ) : null}
    </TitledDialog>
  )
}

export default CommonTipsModal
