import { clamp } from 'lodash-es'
import { useCallback, useMemo } from 'react'

import { useAppSelector } from '@/modules/app/store'
import { selecVipConfigList } from '@/modules/new-vip/newVipSlice'
import {
  selectUserBetAmount,
  selectUserRechargeAmount,
  selectVipLevel,
} from '@/modules/user/userSlice'

export function useUserProgress(type: 'recharge' | 'bet') {
  const configs = useAppSelector(selecVipConfigList)
  const level = useAppSelector(selectVipLevel)
  const rechargeAmount = useAppSelector(selectUserRechargeAmount)
  const betAmount = useAppSelector(selectUserBetAmount)

  const getLevelFloor = useCallback(
    (lv: number) => {
      const MAX = 9999999999
      const conf = configs[lv]?.config
      if (!conf) {
        return MAX
      } else {
        return Number(
          type === 'recharge' ? conf.recharge_upgrade : conf.bet_upgrade,
        )
      }
    },
    [type, configs],
  )

  return useMemo(() => {
    const result = {
      current: 0,
      target: 0,
      diff: 0,
      percentage: 0,
    }

    const currLevelFloor = getLevelFloor(level)
    const nextLevelFloor = getLevelFloor(level + 1)
    result.target = Math.max(0, nextLevelFloor - currLevelFloor)
    const currValue = type === 'recharge' ? rechargeAmount : betAmount
    result.current = clamp(Number(currValue) - currLevelFloor, 0, result.target)
    result.percentage =
      result.target !== 0
        ? Math.floor(clamp((result.current * 100) / result.target, 0, 100))
        : 0
    result.diff = result.target - result.current
    return result
  }, [rechargeAmount, betAmount, getLevelFloor, level, type])
}
