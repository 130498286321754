import type { ToastProviderProps } from '@chakra-ui/react'

const globalToastOption: ToastProviderProps = {
  defaultOptions: {
    position: 'top',
    duration: 2000,
  },
}

export default globalToastOption
