import { Box, Image, Text } from '@chakra-ui/react'

import Coin from '@/assets/images/notification/coin.png'
import LazyImage from '@/components/lazy-image/LazyImage'
import { rotateAnimation } from '@/modules/wheel-modal/style'
import property from '@/websites/current/property.json'

import type { Message } from '../../types'

const CoinReward = ({ num, currency }: { num?: number; currency?: string }) => {
  return (
    <>
      <Image
        src={Coin}
        sx={{ width: 'auto', height: '57px', objectFit: 'cover' }}
      />
      <Text
        textStyle='text6'
        color='gray.700'
        sx={{
          textAlign: 'center',
          fontSize: '14px',
          fontWeight: '800',
          color: 'white',
        }}
      >
        {currency ?? property.locale.currencies[0]}
        <Text
          as={'span'}
          sx={{
            ml: '1',
          }}
        >
          {num ?? 0}
        </Text>
      </Text>
    </>
  )
}

const RewardItem = ({
  item,
  isClaim,
}: {
  item: Message['rewards']
  isClaim: boolean
}) => {
  return (
    <Box>
      {!isClaim ? (
        <LazyImage
          src='/assets/images/login-reward-modal/light.png'
          height='100px'
          marginRight='-50px'
          marginTop='-50px'
          position='absolute'
          right='50%'
          top='50%'
          width='100px'
          maxWidth='initial'
          animation={rotateAnimation}
          zIndex='0'
          cursor='pointer'
        />
      ) : null}
      <CoinReward num={item?.num} currency={item?.currency} />
    </Box>
  )
}

export default RewardItem
