import { Box, Button, Image, Spacer, Text, VStack } from '@chakra-ui/react'
import { useCountDown } from 'ahooks'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'

import drawActivityEvent from '../events'
import * as Images from '../image-imports'
import { changeScence } from '../store/reducer-slice'
import { selectRewardPrize } from '../store/selectors'
import type { ScenceProps } from '../types'
import { Heading } from './Heading'

const COUNT_DOWN = 10000

function RewardScene(props: ScenceProps) {
  const { isShow } = props
  const { t } = useTranslation()
  const rewardPrize = useAppSelector(selectRewardPrize)
  if (!isShow) return null
  return (
    <>
      <Box mt='100px' mx='auto' textAlign='center'>
        <VStack
          spacing={0}
          height='360px'
          position='relative'
          background={`no-repeat center center / 360px 360px url(${Images.spinLight}), no-repeat center center / 300px 272px url(${Images.rewardBg})`}
        >
          <Text textStyle='h3' color='#FFFFFF' mt='41px'>
            {t('CONTRATE_ON')}
          </Text>
          <Spacer />
          <Image
            src={rewardPrize?.image}
            position='absolute'
            top={0}
            right={0}
            bottom={0}
            left={0}
            m='auto'
            width='auto'
            height='136px'
            objectFit='contain'
          />
          <Heading mb='1'>{rewardPrize?.text}</Heading>
          <Text textStyle='text6' color='#FFFFFF' mb='5'>
            {t('PRIZE_GET_DSC')}
          </Text>
        </VStack>
        <OkButton />
      </Box>
    </>
  )
}

const RewardSceneMemo = React.memo(RewardScene)
export default RewardSceneMemo

function OkButton() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [leftTime, setLeftTime] = useState(COUNT_DOWN)

  const backToMainScene = () => {
    setLeftTime(0)
    dispatch(changeScence('normal'))
    drawActivityEvent.emit('backToMainScene')
  }

  const [countDown] = useCountDown({
    leftTime: leftTime,
    onEnd: backToMainScene,
  })

  return (
    <Button
      size='lg'
      width='full'
      maxWidth='300px'
      colorScheme='prim'
      onClick={backToMainScene}
    >
      {t(`Feliz de aceptar ( ${Math.floor(countDown / 1000)}s )`)}
    </Button>
  )
}
