import { Box, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useContext, useMemo } from 'react'

import i18n from '@/common/locale/i18n'
import { useAppSelector } from '@/modules/app/store'
import {
  selectUserCurrencySign,
  selectUserOriginalCurrencySign,
} from '@/modules/user/userSlice'
import { getCompatibleColors, getConfigLng, isFromAd } from '@/utils/tools'

import NewMessageContext from './NewMessageContext'

function NewMessageContent() {
  const { message } = useContext(NewMessageContext)
  const originalSign = useAppSelector(selectUserOriginalCurrencySign)
  const fromAd = isFromAd()
  const currency = useAppSelector(selectUserCurrencySign)
  const lng = getConfigLng()

  const renderMessage = useMemo(() => {
    const CUSTOM_MESSAGE_VERSION = 1
    if (message.message_version === CUSTOM_MESSAGE_VERSION)
      return {
        title: message.message?.[lng]?.title,
        message: message.message?.[lng]?.content,
        desc: message.message?.[lng]?.desc,
        img: message.message?.[lng]?.img,
      }
    return {
      title:
        message.title_key && fromAd
          ? t(message.title_key, message.params ?? {}).replaceAll(
              originalSign!,
              currency!,
            )
          : t(message.title_key!, message.params ?? {}),
      message:
        message.message_key && fromAd
          ? t(message.message_key, message.params ?? {}).replaceAll(
              originalSign!,
              currency!,
            )
          : t(message.message_key!, message.params ?? {}),
    }
  }, [
    currency,
    fromAd,
    message.message,
    message.message_key,
    message.message_version,
    message.params,
    message.title_key,
    originalSign,
  ])
  return (
    <Box>
      <Text
        textStyle='h6'
        maxW='240px'
        noOfLines={2}
        color={getCompatibleColors('notification.title', 'white')}
        dangerouslySetInnerHTML={{ __html: renderMessage.title || '' }}
      />
      {renderMessage?.desc && message.message_version === 1 && (
        <Text
          textStyle='text6'
          mt='2'
          noOfLines={3}
          maxW='255px'
          color={getCompatibleColors('gray.800', 'rgba(173, 182, 196, 1)')}
          dangerouslySetInnerHTML={{ __html: renderMessage.desc || '' }}
        />
      )}
      {message.message_version !== 1 && (
        <Text
          textStyle='text6'
          mt='2'
          noOfLines={3}
          maxW='255px'
          color={getCompatibleColors('gray.800', 'rgba(173, 182, 196, 1)')}
          dangerouslySetInnerHTML={{ __html: renderMessage.message || '' }}
        />
      )}
      {message.effect_time && (
        <Text
          textStyle='text6'
          mt='2'
          color={getCompatibleColors(
            'notification.validTime',
            'rgba(85, 101, 126, 1)',
          )}
        >
          {t('MESSAGE_COUNT_DOWN', {
            date: dayjs(message?.created_at ? message.created_at * 1000 : '')
              .add(message?.effect_time, 'day')
              .format('MM/DD'),
          })}
        </Text>
      )}
    </Box>
  )
}

export default NewMessageContent
