import store from '@/modules/app/store'
import { currencyApi } from '@/modules/bind-currency/currencyApi'
import { selectUserId, selectUserToken } from '@/modules/user/userSlice'

export async function currentInfoLoader() {
  await store.dispatch(
    currencyApi.endpoints.bindCurrencyInfo.initiate({
      user_id: selectUserId(store.getState()),
      token: selectUserToken(store.getState()),
    }),
  )
}
