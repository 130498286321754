export const BANK_CODE_MAP: {
  [key: string]: number
} = {
  BANXICO: 2001,
  BANCOMEXT: 37006,
  BANOBRAS: 37009,
  BANJERCITO: 37019,
  NAFIN: 37135,
  BANSEFI: 37166,
  HIPOTECARIAFED: 37168,
  BANAMEX: 40002,
  BBVABANCOMER: 40012,
  SANTANDER: 40014,
  HSBC: 40021,
  BAJIO: 40030,
  INBURSA: 40036,
  MIFEL: 40042,
  SCOTIABANK: 40044,
  BANREGIO: 40058,
  INVEX: 40059,
  BANSI: 40060,
  AFIRME: 40062,
  BANORTE: 40072,
  ACCENDOBANCO: 40102,
  AMERICANEXPRES: 40103,
  BANKOFAMERICA: 40106,
  MUFG: 40108,
  JPMORGAN: 40110,
  BMONEX: 40112,
  VEPORMAS: 40113,
  DEUTSCHE: 40124,
  CREDITSUISSE: 40126,
  AZTECA: 40127,
  AUTOFIN: 40128,
  BARCLAYS: 40129,
  COMPARTAMOS: 40130,
  MULTIVABANCO: 40132,
  ACTINVER: 40133,
  INTERCAMBANCO: 40136,
  BANCOPPEL: 40137,
  ABCCAPITAL: 40138,
  CONSUBANCO: 40140,
  VOLKSWAGEN: 40141,
  CIBANCO: 40143,
  BBASE: 40145,
  BANKAOOL: 40147,
  PAGATODO: 40148,
  INMOBILIARIO: 40150,
  DONDE: 40151,
  BANCREA: 40152,
  BANCOFINTERRA: 40154,
  ICBC: 40155,
  SABADELL: 40156,
  SHINHAN: 40157,
  MIZUHOBANK: 40158,
  BANCOS3: 40160,
  MONEXCB: 90600,
  GBM: 90601,
  MASARI: 90602,
  VALUE: 90605,
  ESTRUCTURADORES: 90606,
  VECTOR: 90608,
  MULTIVACBOLSA: 90613,
  FINAMEX: 90616,
  VALMEX: 90617,
  PROFUTURO: 90620,
  CBINTERCAM: 90630,
  CIBOLSA: 90631,
  FINCOMUN: 90634,
  HDISEGUROS: 90636,
  AKALA: 90638,
  REFORMA: 90642,
  STP: 90646,
  EVERCORE: 90648,
  CREDICAPITAL: 90652,
  KUSPIT: 90653,
  UNAGRA: 90656,
  ASPINTEGRAOPC: 90659,
  LIBERTAD: 90670,
  CAJAPOPMEXICA: 90677,
  CRISTOBALCOLON: 90680,
  CAJATELEFONIST: 90683,
  TRANSFER: 90684,
  FONDOFIRA: 90685,
  INVERCAP: 90686,
  FOMPED: 90689,
  SANTANDER2: 90814,
  INDEVAL: 90902,
  CODIVALIDA: 90903,
  CODI_VALIDA: 90903,
}
