import type { ProjectConfig } from '@/types/project-config/ProjectConfig'

const config: ProjectConfig = {
  emailSuffixs: [
    '@gmail.com',
    '@hotmail.com',
    '@yahoo.com.br',
    '@yahoo.com',
    '@outlook.com',
    '@bol.com.br',
    '@bol.com',
    '@icloud.com',
    '@live.com',
    '@uol.com.br',
    '@uol.com',
    '@terra.com.br',
    '@msn.com',
    '@globo.com',
    '@gmx.com',
  ],
}

export default config
