// export const getRewardsStyle: any = (day: number) => {
//   let rewardsStyle: any
//   if (day === 7) {
//     rewardsStyle = {
//       alignItems: 'center',
//       display: 'flex',
//       flexDirection: 'row-reverse',
//       colSpan: '3',
//       height: '81px',
//       borderRadius: '12px',
//     }
//   } else {
//     rewardsStyle = {
//       height: '137px',
//       borderRadius: '10px',
//     }
//   }
//   return rewardsStyle
// }

export const getRewardsStyle: any = (day: number) => {
  let rewardsStyle: any
  if (day === 7) {
    rewardsStyle = {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row-reverse',
      colSpan: '3',
      height: '81px',
      borderRadius: '12px',
    }
  } else {
    rewardsStyle = {
      height: '137px',
      borderRadius: '10px',
    }
  }
  return rewardsStyle
}

export const getNewRewardsStyle: any = (day: number) => {
  let rewardsStyle: any
  if (day === 7) {
    rewardsStyle = {
      alignItems: 'center',
      display: 'flex',
      colSpan: '2',
      height: '88px',
      borderRadius: '12px',
    }
  } else {
    rewardsStyle = {
      height: '88px',
      borderRadius: '10px',
    }
  }
  return rewardsStyle
}
