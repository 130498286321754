import { Icon, Link } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from '@/modules/app/store'
import {
  closeDrawerStart,
  DrawerKey,
} from '@/modules/app-layout/app-layout-slice'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { getConfigLng } from '@/utils/tools'

import type { Message } from '../../types'

function NewMessageLink({ message }: { message: Message | null }) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const lng = getConfigLng()
  const { t } = useTranslation()

  const title = useMemo(() => {
    const CUSTOM_MESSAGE_VERSION = 1
    if (!message) return t('MESSAGE_LINK_COPY')
    if (message.message_version === CUSTOM_MESSAGE_VERSION) {
      return message?.message?.[lng]?.jump_title || t('MESSAGE_LINK_COPY')
    }
    return message.button_name || t('MESSAGE_LINK_COPY')
  }, [lng, message, t])
  const redirectNav = (message: Message | null) => () => {
    if (!message) return
    if (message.jump_url) {
      const isOutSizeLink =
        message.jump_url.startsWith('http') ||
        message.jump_url.startsWith('www')
      if (isOutSizeLink) {
        window.open(message.jump_url, '_blank')
        return
      }
      navigate(message.jump_url)
      dispatch(closeDrawerStart(DrawerKey.Right))
    }
  }
  return (
    <Link mt='2' textStyle='text5' onClick={redirectNav(message)}>
      {title} <Icon as={SharedIcon.ArrowRight1} />
    </Link>
  )
}

export default NewMessageLink
