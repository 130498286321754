import type { HeadingProps } from '@chakra-ui/react'
import { Heading as ChakraHeading } from '@chakra-ui/react'

export function Heading(props: HeadingProps) {
  const { children, ...restProps } = props
  return (
    <ChakraHeading
      backgroundImage='linear-gradient(180deg, #FFFFFF 27.88%, #FDF35F 65.11%)'
      backgroundClip='text'
      fontWeight='900'
      fontSize='32px'
      textAlign='center'
      color='transparent'
      filter='drop-shadow(0 0 1px #EAE040) drop-shadow(0 0 1px #EC4FA2) drop-shadow(0 0 1px #EC4FA2) drop-shadow(0 0 1px #EAE040) '
      mb='2.5'
      {...restProps}
    >
      {children}
    </ChakraHeading>
  )
}
