import type { GameListItem, ManulacturerListItem } from '../gameInterface'

export type ManusKeybyId = Record<number, ManulacturerListItem>
interface GetSortedManuReturn {
  list: ManulacturerListItem[]
  enableManus: ManulacturerListItem[]
  manusKeybyId: Record<number, ManulacturerListItem>
  enableManusKeybyId: Record<number, ManulacturerListItem>
}

/**
 * 过滤掉未开启的厂商 & 允许游戏维护（已经排序）
 * @param manus
 * @returns
 */
export default function getSortedManu(
  manus: ManulacturerListItem[],
): GetSortedManuReturn {
  // 启用 && 展示在首页列表
  const list = []
  // 启用 && 不在维护中
  const enableManus = []
  const manusKeybyId: GetSortedManuReturn['manusKeybyId'] = {}
  const enableManusKeybyId: GetSortedManuReturn['manusKeybyId'] = {}

  /**
   * 循环， 得到 list 和 manusKeybyId
   */
  for (const item of manus) {
    if (item.is_enabled === 1) {
      const _item = {
        ...item,
        supportCurrency: ['ALL'],
        data: [] as GameListItem[],
        name: item.name,
        icon: item.icon,
        type: item.name,
      }
      if (item.display_games === 1) {
        list.push(_item)
        manusKeybyId[item.id] = _item
      }

      if (item?.is_under_maint !== 1) {
        enableManus.push(_item)
        enableManusKeybyId[item.id] = _item
      }
    }
  }

  /** 厂商需要先排序一下 */
  list.sort((a, b) => a.sort_weight - b.sort_weight)
  enableManus.sort((a, b) => a.sort_weight - b.sort_weight)

  return { list, manusKeybyId, enableManus, enableManusKeybyId }
}
