import { Center, Td } from '@chakra-ui/react'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

const Empty: FC = () => {
  const { t } = useTranslation()
  return (
    <Center h='full' padding='40px 0'>
      {t('GAME_NO_INFORMATION')}{' '}
    </Center>
  )
}

export default Empty
