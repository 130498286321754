import { Box } from '@chakra-ui/react'
import type { WidgetState } from '@livechat/widget-react'
import { LiveChatWidget } from '@livechat/widget-react'
import { useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from '../app/store'
import {
  selectAppLayout,
  selectIsSaleSmartlyEnable,
  selectLiveChatWidgetLicense,
  setLiveChatWigetVisibility,
} from '../app-layout/app-layout-slice'
import { selectUserId, selectUserName, selectVipLevel } from '../user/userSlice'

function LiveChat() {
  const visibility = useAppSelector(
    state => selectAppLayout(state).liveChatWigetVisibility,
  )
  const userName = useAppSelector(selectUserName)
  const userVipLevel = useAppSelector(selectVipLevel)

  const isSaleSmartlyEnable = useAppSelector(selectIsSaleSmartlyEnable)

  const userId = useAppSelector(selectUserId)
  const liveChatWidgetLicense = useAppSelector(selectLiveChatWidgetLicense)
  const dispatch = useAppDispatch()
  const onChange = ({ visibility }: Pick<WidgetState, 'visibility'>) => {
    if (visibility === 'minimized') {
      dispatch(setLiveChatWigetVisibility('hidden'))
    } else {
      dispatch(setLiveChatWigetVisibility(visibility))
    }
  }

  if (isSaleSmartlyEnable) return null

  return (
    <LiveChatWidget
      key={liveChatWidgetLicense}
      sessionVariables={{ user_id: userId }}
      license={liveChatWidgetLicense}
      visibility={visibility}
      onVisibilityChanged={onChange}
      customerName={`VIP${userVipLevel}: ` + userName}
    />
  )
}

/** 延迟 1500 初始化，提高首屏速度 */
export default function DelayLiveChat() {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, 1500)
  }, [])

  if (mounted) return <LiveChat />

  return null
}
