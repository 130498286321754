import type { TooltipProps } from '@chakra-ui/react'
import { Text, Tooltip } from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface GameFavoriteTooltipProps
  extends PropsWithChildren,
    Pick<TooltipProps, 'isOpen' | 'placement'> {
  onLabelClick?(e: React.MouseEvent): void
}

export function GameFavoriteTooltip(props: GameFavoriteTooltipProps) {
  const { children, isOpen, placement, onLabelClick } = props
  const { t } = useTranslation()

  return (
    <Tooltip
      label={
        <Text cursor='pointer' onClick={onLabelClick} pointerEvents='all'>
          {t('GIVE_IT_LIKE')}
        </Text>
      }
      isOpen={isOpen}
      hasArrow
      placement={placement}
      colorScheme='tert'
      shouldWrapChildren
    >
      {children}
    </Tooltip>
  )
}
