import { getVariable } from '../env'
import { AndroidStorage, AndroidThirdPartAuth, AndroidTracker } from './Android'
import { IosStorage, IosThirdPartAuth, IosTracker, IosVestTracker } from './IOS'
import PlatformContainer from './PlatformContainer'
import { isFromApk, isFromIpa, isFromVest } from './utils'

const serverClientId = getVariable('NEXT_PUBLIC_GOOGLE_CLICENT_ID') ?? ''
const fbAppId = getVariable('NEXT_PUBLIC_FACEBOOK_APP_ID') ?? ''

function createPlatform() {
  const platform = new PlatformContainer()

  if (isFromApk()) {
    platform.addTracker(new AndroidTracker())
    platform.addStorage(new AndroidStorage())
    platform.addThridPartAuth(new AndroidThirdPartAuth(serverClientId, fbAppId))
    return platform
  }

  if (isFromIpa()) {
    if (!isFromVest()) {
      platform.addTracker(new IosTracker())
      platform.addStorage(new IosStorage())
      platform.addThridPartAuth(new IosThirdPartAuth(serverClientId, fbAppId))
    } else {
      platform.addTracker(new IosVestTracker())
    }
    return platform
  }

  return null
}

const Platform = createPlatform()

export default Platform
