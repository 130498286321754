import { Box } from '@chakra-ui/react'

export function MaskLayer() {
  return (
    <Box
      pos='absolute'
      w='100%'
      h='100%'
      left='0'
      top='0'
      opacity={0.2}
      bgColor='#fff'
      zIndex='9'
      cursor='not-allowed'
    />
  )
}

export default MaskLayer
