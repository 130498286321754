import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice, isAllOf, isAnyOf } from '@reduxjs/toolkit'

import type { RootState } from '../app/store'
import { loginRewardApi } from './loginRewardModalApi'
import type { DailyLoginRewardConfigByVipLevel } from './stores/daily-login-reward-config-by-vip-level'
import {
  calculateDailyLoginRewardConfigByVipLevelState,
  getInitialDailyLoginRewardConfigByVipLevelState,
} from './stores/daily-login-reward-config-by-vip-level'
import type { CfgListItem, IInfoResult } from './types'

export type LoginRewardState = {
  /** true 已领取  */
  rewardStatus: boolean | undefined
  rewardAmount: number
  cfgList: CfgListItem[][]
  cfgInfo: IInfoResult
  rechargeAmount: number
  progressAmount: number
  activityRule: string
  hideRewardAmount: boolean
  dailyLoginRewardConfigByVipLevel?: DailyLoginRewardConfigByVipLevel
}

const initialState: LoginRewardState = {
  rewardStatus: undefined,
  rewardAmount: 0,
  cfgList: [],
  rechargeAmount: 0,
  progressAmount: 0,
  activityRule: '',
  cfgInfo: {
    can_receive: false,
    cur_day: 0,
    cur_receive: false,
    receive_list: [0],
    vip_level: 0,
    progress_amount: 0,
  },
  hideRewardAmount: false,
}

export const loginRewardSlice = createSlice({
  name: 'loginReward',
  initialState,
  reducers: {
    setRewardAmount(state, { payload }: PayloadAction<number>) {
      state.rewardAmount = payload
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        loginRewardApi.endpoints.loginTaskInfo.matchFulfilled,
        (state, { payload }) => {
          state.cfgInfo = payload.data
          state.rewardStatus = payload.data.cur_receive
          state.progressAmount = payload.data.progress_amount
        },
      )
      .addMatcher(
        loginRewardApi.endpoints.loginTaskCfq.matchFulfilled,
        (state, { payload }) => {
          state.rechargeAmount = payload.data.recharge_amount
          state.activityRule = payload.data.activity_rule
          const list = payload.data.cfg?.list || []

          type CfgMapType = { [key in number]: CfgListItem[] }

          /** daily login reward initial state*/
          const dailyLoginRewardConfigByVipLevel =
            getInitialDailyLoginRewardConfigByVipLevelState()

          const result: CfgMapType = list.reduce((acc, item) => {
            const { vip_level } = item

            /** mutate dailyLoginRewardConfigByVipLevel */
            calculateDailyLoginRewardConfigByVipLevelState(
              dailyLoginRewardConfigByVipLevel,
              item,
            )

            return {
              ...acc,
              [vip_level]: [...(acc[vip_level] || []), item],
            }
          }, {} as CfgMapType)
          state.cfgList = Object.values(result)
          state.dailyLoginRewardConfigByVipLevel =
            dailyLoginRewardConfigByVipLevel
        },
      )
  },
})

export const selectState = (state: RootState) => state

export const selectLoginReward = (state: RootState) => state.loginReward

export const selectLoginRewardStatus = createSelector(
  selectLoginReward,
  (loginReward: LoginRewardState) => loginReward.rewardStatus,
)

export const selectDailyLoginRewardConfigByVipLevel = createSelector(
  selectLoginReward,
  (loginReward: LoginRewardState) =>
    loginReward.dailyLoginRewardConfigByVipLevel,
)

export const selectRewardAmount = createSelector(
  selectLoginReward,
  (loginReward: LoginRewardState) => {
    const { cfgList, cfgInfo } = loginReward
    const cur_day = cfgInfo?.cur_day
    if (cfgList.length > 0 && !!cfgInfo) {
      let vipLevelList = cfgList.find(
        item => item[0].vip_level === cfgInfo?.vip_level,
      )

      if (!vipLevelList) {
        vipLevelList = cfgList[0]
      }
      return vipLevelList[
        cur_day > 6 ? 6 : cur_day - (cfgInfo?.cur_receive ? 0 : 1)
      ]?.reward_amount
    }
  },
)

export const { setRewardAmount } = loginRewardSlice.actions

export const loginRewardReducer = loginRewardSlice.reducer

export const selectLoginRewardAmountIsHidden = createSelector(
  selectState,
  state => state.appLayout.featSwitch['hide_login_reward_amount']?.enable === 1,
)
