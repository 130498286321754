import { Box, Flex, Link } from '@chakra-ui/react'
import React from 'react'
import { RecordSwiperProps } from '../GameRecordsAndCollection.type'
import GameCardRender from '@/modules/game/logic/game-list/GameCardRender'
import AtomGameCard from '@/modules/game/units/gamecard/AtomGameCard'
import { VIEW_ALL_GAMES_IMG } from '../constants'
import { Link as RouterLink } from 'react-router-dom'
import { FadingBox } from '@/modules/game/logic/game-list/recommend-game-groups/FadingBox'

const RecordsSwiper = ({ games, detailPagePath }: RecordSwiperProps) => {
  const cardContainerStyles = {
    width: ['29%', '23%', '18%', '14%'],
    flexShrink: '0',
  }
  return (
    <FadingBox>
      <Box overflowX={'scroll'}>
        <Flex width='100%' gap='2.5'>
          {games.map(game => {
            return (
              <Box {...cardContainerStyles} key={game.game_id + game.game_name}>
                <GameCardRender
                  info={game}
                  //   onClick={() => onItemClick?.(item)}
                  orientation={'portrait'}
                />
              </Box>
            )
          })}
          <Link
            as={RouterLink}
            to={detailPagePath}
            display='block'
            {...cardContainerStyles}
          >
            <AtomGameCard src={VIEW_ALL_GAMES_IMG} orientation={'portrait'} />
          </Link>
        </Flex>
      </Box>
    </FadingBox>
  )
}

export default RecordsSwiper
