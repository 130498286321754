import { useCallback } from 'react'

import { getVariable } from '@/common/env'
import { getCurrencyCode } from '@/common/locale/currency'
import { decimalFormatByCode } from '@/common/locale/currency-const'
import { useAppSelector } from '@/modules/app/store'
import type { CurrencyRateInfo } from '@/modules/bind-currency/currencySlice'
import {
  getCurrencyFixedRateList,
  getCurrencyRateList,
} from '@/modules/bind-currency/currencySlice'

export interface ExchangeProps {
  base_currency?: string
  target_currency?: string
  exchange_currency?: string
  exchange_value: number | string
}

// const default_base_currency = 'USD'
const NEED_EXCHANGE_CURRENCY = getVariable('NEED_EXCHANGE_CURRENCY')

export const useExchangeCurrency = () => {
  const CURRENCY = getCurrencyCode()
  const currencyRateList = useAppSelector(getCurrencyRateList)
  const currencyFixedRateList = useAppSelector(getCurrencyFixedRateList)
  // 转换汇率
  const exchangeCurrency = useCallback(
    (props: ExchangeProps) => {
      if (NEED_EXCHANGE_CURRENCY) {
        const exchange_value = props.exchange_value || 0
        // const base_currency = props.base_currency || default_base_currency
        const target_currency = props.target_currency || CURRENCY
        const exchange_currency = props.exchange_currency || 'MXN'
        const exchange_config: CurrencyRateInfo | undefined =
          currencyRateList.find(item => item.currency === exchange_currency)
        const target_config: CurrencyRateInfo | undefined =
          currencyRateList.find(item => item.currency === target_currency)
        const exchange_to_base_value =
          Number(exchange_value) * (exchange_config?.exchange_rate || 1)

        const exchange_to_target_value =
          Number(exchange_to_base_value) / (target_config?.exchange_rate || 1)

        return exchange_to_target_value as unknown as number
      } else {
        return props.exchange_value as number
      }
    },
    [CURRENCY, currencyRateList],
  )

  // 转换固定比例
  const exchangeFixedCurrency = useCallback(
    (props: ExchangeProps) => {
      if (NEED_EXCHANGE_CURRENCY) {
        const exchange_value = props.exchange_value || 0
        const target_currency = props.target_currency || CURRENCY
        const exchange_currency = props.exchange_currency || 'MXN'
        const exchange_config: CurrencyRateInfo | undefined =
          currencyFixedRateList.find(
            item => item.currency === exchange_currency,
          )
        const target_config: CurrencyRateInfo | undefined =
          currencyFixedRateList.find(item => item.currency === target_currency)
        const exchange_to_base_value =
          Number(exchange_value) * (exchange_config?.exchange_rate || 1)

        const exchange_rate = exchange_config?.exchange_rate || 1
        const target_rate = target_config?.exchange_rate || 1

        const exchange_to_target_value =
          Number(exchange_to_base_value) * (target_rate / exchange_rate)

        return exchange_to_target_value as unknown as number
      } else {
        return props.exchange_value as number
      }
    },
    [CURRENCY, currencyFixedRateList],
  )

  const formatValue = useCallback(
    (value: number, type?: 't' | 'b' | '' | undefined, number?: number) => {
      const fixedValue = Number(value.toFixed(3))
      if (NEED_EXCHANGE_CURRENCY) {
        const _type = type
        if (_type === 'b') {
          // 向下取整
          return Math.floor(fixedValue)
        } else if (_type === 't') {
          // 向上取整
          return Math.ceil(fixedValue)
        } else {
          return decimalFormatByCode(CURRENCY, fixedValue) as unknown as number
        }
      } else {
        return decimalFormatByCode(CURRENCY, fixedValue)
      }
    },
    [],
  )
  return {
    exchangeFixedCurrency,
    exchangeCurrency,
    formatValue,
  }
}

export default useExchangeCurrency
