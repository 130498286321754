import type {
  ChakraProps,
  ComponentDefaultProps,
  ImageProps,
} from '@chakra-ui/react'
import {
  AspectRatio,
  Box,
  chakra,
  Flex,
  Icon,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import { type FC, useState } from 'react'

import LazyImage from '@/components/lazy-image/LazyImage'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useCustomizedStyle } from '@/utils/hooks'

import type GameCardHover from './AtomGameCardHover'

export const GameCardParts = ['gamecard', 'gamecardhover'] as const
const ChakraGameCard = chakra(Box)

interface GameCardProps
  extends ChakraProps,
    Pick<GameCardCoverProps, 'src' | 'isLazy' | 'orientation'> {
  onClick?: (path: string) => void
  children?: ReturnType<typeof GameCardHover>
  style?: {
    [key: string]: string
  }
}

const AtomGameCard: FC<GameCardProps> = ({
  src,
  onClick,
  children,
  isLazy = true,
  style = {},
  orientation,
}) => {
  const { gamecard: gameCardStyle } = useCustomizedStyle(
    'AtomGameCard',
    {
      gamecard: {
        borderRadius: 'lg',
        cursor: 'pointer',
        position: 'relative',
        overflow: 'hidden',
        color: 'white',
        ...style,
      },
    },
    GameCardParts,
  )

  return (
    <ChakraGameCard role='group' sx={gameCardStyle} onClick={onClick}>
      <GameCardCover
        src={src}
        alt={src}
        isLazy={isLazy}
        orientation={orientation}
      />
      {children}
    </ChakraGameCard>
  )
}

export type Orientation = 'landscape' | 'portrait'

export interface GameCardCoverProps extends Pick<ImageProps, 'src' | 'alt'> {
  isLazy?: boolean
  orientation?: Orientation
}

const ASPECT_RATIO_LANDSCAPE = 157 / 112
const ASPECT_RATIO_PORTRAIT = 112 / 149
function GameCardCover(props: GameCardCoverProps) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  const { src, alt, isLazy = true, orientation = 'portrait' } = props
  const onError = () => {
    setIsLoaded(true)
    setIsFailed(true)
  }
  const aspectRatio =
    orientation === 'landscape' ? ASPECT_RATIO_LANDSCAPE : ASPECT_RATIO_PORTRAIT
  return (
    <Skeleton isLoaded={isLoaded}>
      <AspectRatio ratio={aspectRatio}>
        {isFailed ? (
          <Flex
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            bgColor='gray.400'
            color='text.base'
          >
            <Icon as={SharedIcon.Info} boxSize='40px' />
            <Text mt='2' textStyle='text6'>
              image broken
            </Text>
          </Flex>
        ) : (
          <LazyImage
            src={src}
            alt={alt}
            objectFit='cover'
            objectPosition='center'
            width='100%'
            height='100%'
            borderRadius='8px'
            _groupHover={{
              filter: 'blur(5px)',
              transform: 'scale(1.1)',
              transition: 'all .2s',
            }}
            noLazy={!isLazy}
            onLoad={() => setIsLoaded(true)}
            onError={onError}
          />
        )}
      </AspectRatio>
    </Skeleton>
  )
}

export default AtomGameCard
