import { isProduction } from '@/common/env'

import getIsSupportPmsdk from '../features/get-is-support-pmsdk'

/** FPS 阈值 */
const FPS_SHRESHOLD = 45
class FPSMonitor {
  private static instance: FPSMonitor
  private lastTime: number
  private frameCount: number
  private lastWarnTime: number
  private animationFrameId: number | null
  private isProd: boolean

  constructor() {
    this.lastTime = performance.now()
    this.frameCount = 0
    this.lastWarnTime = 0 // 上次警告时间
    this.animationFrameId = null
    this.isProd = isProduction()

    if (FPSMonitor.instance) {
      return FPSMonitor.instance
    }

    FPSMonitor.instance = this
  }

  static getInstance() {
    if (!FPSMonitor.instance) {
      FPSMonitor.instance = new FPSMonitor()
    }
    return FPSMonitor.instance
  }

  public start() {
    requestAnimationFrame(this.checkFPS.bind(this))
  }

  public destroy() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId)
      this.animationFrameId = null // 清除ID
    }
  }

  private checkFPS() {
    const isProd = this.isProd
    const now = performance.now()
    const delta = now - this.lastTime
    this.frameCount++

    // 每秒计算一次FPS
    if (delta >= 1000) {
      let fps = 0
      try {
        fps = parseFloat((this.frameCount / (delta / 1000)).toFixed(2))
      } catch (e) {
        fps = this.frameCount / (delta / 1000)
      }

      this.frameCount = 0
      this.lastTime = now

      const lastWarnTimeDelta = now - this.lastWarnTime
      // FPS警告且距离上次警告超过10秒时，输出警告
      if (
        fps <= FPS_SHRESHOLD &&
        (lastWarnTimeDelta >= 10000 || this.lastWarnTime === 0)
      ) {
        !isProd && console.warn(`low fps: ${fps}`)
        this.report(fps)
        this.lastWarnTime = now
      }
    }

    requestAnimationFrame(this.checkFPS.bind(this))
  }

  private report(fps: number) {
    if (getIsSupportPmsdk(window.pmSdk)) {
      window.pmSdk.log(
        'clientmetrics_page_fps_spike',
        {
          page_url: location.pathname,
          fps,
        },
        {
          labels: ['page_url'],
          value: 'fps',
        },
      )
    }
  }
}

export default FPSMonitor
