import * as Sentry from '@sentry/react'

import property from '@/websites/current/property.json'

export function initSentry() {
  Sentry.init({
    dsn: property.sdk.SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.02,
    replaysSessionSampleRate: 0.02,
    replaysOnErrorSampleRate: 1.0,
  })
}
