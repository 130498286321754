import { useDisclosure } from '@chakra-ui/react'
import { useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { activityApi as actApi } from '@/modules/activity/activityApi'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { selectUserRecharged } from '@/modules/user/userSlice'

import { selectFreeTryModalStatus, setIsFreeTryMode } from '../../gameSlice'
import { FREE_TRY_MODAL_SHOW_AGAGIN_KEY as STORAGE_KEY } from './constants'
import { FreeTryModalPresentational } from './FreeTryModalPresentational'
import { useWaitingForLogin } from './hooks'
import { dontShowItAgainToday } from './utils'

export interface ButtonProps {
  text: string
  colorScheme?: 'primary' | 'secondary'
  onClick: () => void
}

export interface FreeTryModalProps {
  isOpen: boolean
  onClose(): void
  containerRef: React.RefObject<HTMLElement | null>
  gameCover: string
  desc: string
  buttons: [ButtonProps, ButtonProps]
  showCheckbox: boolean
}

export default function FreeTryModal(
  props: Pick<FreeTryModalProps, 'containerRef' | 'gameCover'> & {
    isDemoSupported: boolean
  },
) {
  const { containerRef, gameCover, isDemoSupported } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dispatch = useAppDispatch()
  const status = useAppSelector(selectFreeTryModalStatus)
  const isRecharged = useAppSelector(selectUserRecharged)

  useEffect(() => {
    if (!isDemoSupported) return
    if (
      status === 'notEnoughBalance' ||
      status === 'notLogin' ||
      (status === 'normal' && dontShowItAgainToday(STORAGE_KEY).canShow())
    ) {
      onOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpen])

  const turnOffFreeTryMode = useCallback(
    () => dispatch(setIsFreeTryMode(false)),
    [dispatch],
  )

  const onLogin = useWaitingForLogin(turnOffFreeTryMode)

  const content = useMemo((): Pick<FreeTryModalProps, 'desc' | 'buttons'> => {
    const thenClose = (cb: () => void) => () => {
      cb()
      onClose()
    }

    const turnOnFreeTryMode = () => dispatch(setIsFreeTryMode(true))

    const turnOffFreeTryMode = () => dispatch(setIsFreeTryMode(false))

    const onRecharge = async () => {
      turnOffFreeTryMode()
      if (!isRecharged) {
        dispatch(openModal({ key: GlobalModalKey.Wallet }))
        return
      }

      const res = await dispatch(actApi.endpoints.getBankruptList.initiate())
      if ('data' in res && res.data.data) {
        dispatch(openModal({ key: GlobalModalKey.BankruptRechargeModal }))
      } else {
        dispatch(openModal({ key: GlobalModalKey.Wallet }))
      }
    }

    const freeGameBtn = {
      text: 'FREE_GAME',
      onClick: thenClose(turnOnFreeTryMode),
    }

    switch (status) {
      case 'notLogin':
        return {
          desc: 'FREE_UNSIGN_GAME',
          buttons: [
            { text: 'USER_LOGIN', onClick: thenClose(onLogin) },
            freeGameBtn,
          ],
        }
      case 'notEnoughBalance':
        return {
          desc: 'FREE_MQ_GAME',
          buttons: [
            { text: 'DEPOSIT_RECHARGE', onClick: thenClose(onRecharge) },
            freeGameBtn,
          ],
        }
      default:
        return {
          desc: 'FREE_SIGN_GAME',
          buttons: [
            { text: 'REAL_MONEY_GAME', onClick: thenClose(turnOffFreeTryMode) },
            freeGameBtn,
          ],
        }
    }
  }, [dispatch, isRecharged, onClose, onLogin, status])

  if (!containerRef?.current || !isOpen) {
    return null
  }

  const { desc, buttons } = content
  return (
    <FreeTryModalPresentational
      desc={desc}
      buttons={buttons}
      isOpen={isOpen}
      onClose={onClose}
      gameCover={gameCover}
      containerRef={containerRef}
      showCheckbox={status === 'normal'}
    />
  )
}
