import type { IconButtonProps, PlacementWithLogical } from '@chakra-ui/react'
import { Icon, IconButton } from '@chakra-ui/react'
import type { ComponentProps } from 'react'
import { TiStarFullOutline, TiStarOutline } from 'react-icons/ti'

import starFlickerGif from '@/assets/images/games/star-flicker.gif'
import LazyImage from '@/components/lazy-image/LazyImage'
import { GameFavoriteTooltip } from '@/modules/my-games/GameFavoriteTooltip'

import { useFavoriteButton } from './useFavoriteButton'

interface FavoriteButtonProps
  extends Partial<Omit<IconButtonProps, 'onClick' | 'disabled' | 'icon'>> {
  gameId: string
  gameUuid: string
  tooltipPlacement?: PlacementWithLogical
  iconBoxSize?: ComponentProps<typeof Icon>['boxSize']
  isAnimate?: boolean
}

export function FavoriteButton(props: FavoriteButtonProps) {
  const {
    gameId,
    gameUuid,
    tooltipPlacement,
    iconBoxSize,
    color,
    isAnimate,
    ...restProps
  } = props
  const { onClick, isFavorited, isLoading, isTipsShow } = useFavoriteButton(
    gameId,
    gameUuid,
    gameUuid,
  )

  const iconElem =
    isTipsShow && isAnimate ? (
      <LazyImage src={starFlickerGif} alt='' boxSize='6' />
    ) : (
      <Icon
        as={isFavorited ? TiStarFullOutline : TiStarOutline}
        boxSize={iconBoxSize}
      />
    )

  return (
    <GameFavoriteTooltip
      onLabelClick={onClick}
      isOpen={isTipsShow}
      placement={tooltipPlacement}
    >
      <IconButton
        icon={iconElem}
        aria-label='favorite'
        onClick={onClick}
        variant='unstyledIcon'
        disabled={isLoading}
        color={isFavorited ? 'tert.500' : color}
        {...restProps}
      />
    </GameFavoriteTooltip>
  )
}
