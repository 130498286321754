// 放弃支付时提示内容
import { Box, Flex } from '@chakra-ui/react'

import TitledDialog from '@/components/modal/TitledDialog'
import { useAppSelector } from '@/modules/app/store'
import { getShowVirtualTipsModal } from '@/modules/wallet/walletSlice'

import type { VirtualModalTipsProps } from './interface'

export default function VirtualModalTips(props: VirtualModalTipsProps) {
  const {
    title,
    cancel_text,
    cancel_cb,
    confirm_text,
    confirm_cb,
    content,
    close_cb,
  } = props
  const buttonStyle = {
    width: '46%',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '800',
    cursor: 'pointer',
    height: '46px',
    lineHeight: '46px',
    borderRadius: '10px',
    color: '#fff',
  }
  const showVirtualTipsModal = useAppSelector(getShowVirtualTipsModal)
  return (
    <TitledDialog
      isOpen={showVirtualTipsModal}
      title={title}
      onClose={close_cb}
      size='sm'
    >
      <Box padding='0 8px'>
        {content}
        <Flex justifyContent='space-between' marginTop='16px'>
          <Box
            {...buttonStyle}
            bg='linear-gradient(180deg, #3F526E 0%, #222C3D 100%)'
            onClick={confirm_cb}
          >
            {cancel_text}
          </Box>
          <Box
            {...buttonStyle}
            bg='linear-gradient(180deg, #0B9AFE 0%, #1955EF 100%)'
            onClick={cancel_cb}
          >
            {confirm_text}
          </Box>
        </Flex>
      </Box>
    </TitledDialog>
  )
}
