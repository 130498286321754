/**
 * 代替switch...case...语法，从tslint上强制匹配类型T的所有分支
 */
export function matchCase<T extends string | number>(
  value: T,
  cases: Record<T, () => void>,
) {
  const fn = cases[value]
  if (typeof fn === 'function') {
    fn()
  }
}
