//
import { useToast } from '@chakra-ui/react'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { isQueryError } from '@/services/types'

import { useOffBindUserPhoneMutation } from './bindPhoneApi'
import {
  setCode,
  setCountDownTime,
  setModalType,
  setPhone,
  setShowCountDown,
} from './bindPhoneSlice'
import { VERIFY_CODE_MESSAGE } from './constants'
import type { PhoneType } from './type'
import { ModalType } from './type'
//取消绑定
export const useOffBindPhone = () => {
  const [offBindPhone, offBindPhoneResult] = useOffBindUserPhoneMutation()
  const {
    error: offError,
    isSuccess: offSuccess,
    isLoading: offLoading,
  } = offBindPhoneResult
  const { t } = useTranslation()
  const toast = useToast()

  const dispatch = useAppDispatch()
  //
  const userOffBindPhone = useCallback(
    async (params: PhoneType) => {
      try {
        await offBindPhone(params).unwrap()
      } catch (error) {
        throw new Error('network error')
      }
    },
    [offBindPhone],
  )
  useEffect(() => {
    //获取失败提示
    if (offError && isQueryError(offError)) {
      const text =
        t(VERIFY_CODE_MESSAGE[offError?.status]) || t('NETWORK_EXCEPTION')
      toast({ status: 'error', title: t(text), duration: 5000 })
      return
    }
    // 核销成功获取成功  跳到changesuccess
    if (offSuccess) {
      sessionStorage.removeItem('code_time')
      sessionStorage.removeItem('last_phone')
      dispatch(setCountDownTime(0))
      dispatch(setShowCountDown(false))
      dispatch(setCode(''))
      dispatch(setPhone(''))
      dispatch(setModalType(ModalType.changesuccess))
    }
  }, [offError, offSuccess, t, toast, dispatch])

  return {
    userOffBindPhone,
    offLoading,
  }
}
