import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { checkPasswordStrength } from '@/modules/login/utils'
import { Icon as SharedIcon } from '@/utils/atom-shared'

import type { FormItemProps } from './Form'
import type { ExtraFormItemProps } from './FormItem'
import { LoginFormInput } from './LoginFormInput'

const PWD_LEVEL_MAP = {
  low: {
    text: 'PASSWORD_SECURITY_LEVEL3',
    color: ['#00AC41', '#00AC41', '#00AC41'],
  },
  middle: {
    text: 'PASSWORD_SECURITY_LEVEL2',
    color: ['#FCA327', '#FCA327', 'gray.400'],
  },
  high: {
    text: 'PASSWORD_SECURITY_LEVEL1',
    color: ['#F43434', 'gray.400', 'gray.400'],
  },
}

const PWD_STYLE = {
  as: 'span',
  w: 'calc((100% - 24px) / 3)',
  h: '4px',
  borderRadius: '8px',
  mr: '8px',
}

const FormItem: React.FC<
  FormItemProps & ExtraFormItemProps & { isPwdLevelShow?: boolean }
> = ({
  label,
  name = '',
  value,
  onChange,
  registerFormItem,
  unregisterFormItem,
  updateFormItemError,
  error,
  rules,
  disabled,
  fieldProps = {},
  addonAfter,
  addonBefore,
  isPwdLevelShow = false,
}) => {
  const { t } = useTranslation()
  const isInit = useRef(true)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(name, e.target.value)
  }

  const validate = useCallback(async () => {
    let errorMsg = ''

    if (rules?.length) {
      for (const rule of rules) {
        try {
          if (rule?.validator) {
            await rule.validator(value)
          } else if (rule?.required) {
            if (!value) {
              throw new Error(rule?.message || 'is empty'.toString())
            }
          }
        } catch (error: any) {
          errorMsg = error.message
          // 处理抛出的错误，可以在此加入您的逻辑
          break // 终止循环
        }
      }
    }

    if (errorMsg) {
      updateFormItemError?.(name, t(errorMsg)) // 更新错误信息
      return false
    }

    updateFormItemError?.(name, '')
    return true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    registerFormItem?.(name, validate)
    return () => {
      unregisterFormItem?.(name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate])

  const pwdLevel = useMemo(() => {
    if (!isPwdLevelShow || error || isInit.current) {
      return false
    }
    return PWD_LEVEL_MAP?.[checkPasswordStrength(value)]
  }, [error, value, isPwdLevelShow])

  useEffect(() => {
    !isInit.current && validate()
    isInit.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <>
      <LoginFormInput
        disabled={disabled}
        label={label}
        value={value}
        handleValueChange={handleChange}
        errorMsg={error}
        addonAfter={addonAfter}
        addonBefore={addonBefore || <Icon boxSize='5' as={SharedIcon.Lock} />}
        fieldProps={{
          name: 'password',
          type: 'password',
          placeholder: t('USER_ENTER_PWD') || '',
          ...fieldProps,
        }}
      />
      {pwdLevel && (
        <Flex
          px='8px'
          justifyContent='space-between'
          alignItems='center'
          h='16px'
          mt='-10px'
          mb='8px'
        >
          <Flex flex={1} alignItems='center' mr='12px'>
            {pwdLevel.color.map((item, index) => (
              <Box
                key={`pwd_${pwdLevel.text}_${index}`}
                {...PWD_STYLE}
                bgColor={item}
              />
            ))}
          </Flex>
          <Text textStyle='text6' color={pwdLevel.color[0]}>
            {t(pwdLevel.text)}
          </Text>
        </Flex>
      )}
    </>
  )
}

const PwdFormItem = memo(FormItem)

PwdFormItem.displayName = 'FormItem'

export default PwdFormItem
