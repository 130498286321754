import type { GameListItem } from '../gameInterface'

export default function getSortedList(gameList: GameListItem[]): {
  games: GameListItem[]
  GMPGames: GameListItem[]
  selfGames: GameListItem[]
  gmpInhouseGame: GameListItem[]

  gmpJili: GameListItem[]
  gmpFundist: GameListItem[]
  gmpFacai: GameListItem[]
  gmpClassic: GameListItem[]
  gmpPgsoft: GameListItem[]
} {
  const games = [] as GameListItem[]
  const GMPGames = [] as GameListItem[]
  const selfGames = [] as GameListItem[]
  const gmpInhouseGame = [] as GameListItem[]

  const gmpJili = [] as GameListItem[]
  const gmpFundist = [] as GameListItem[]
  const gmpFacai = [] as GameListItem[]
  const gmpClassic = [] as GameListItem[]
  const gmpPgsoft = [] as GameListItem[]
  
  for (const game of gameList) {
    if (game['game_status'] === 1) {
      games.push({
        ...game,
        id: game?.game_name,
        name: game?.game_name,
        title: game?.game_name,
      })

      if (game?.expand?.game_platform === 'hillingo-popular') {
        gmpInhouseGame.push(game)
      }
      if (game?.expand?.game_platform === 'hillingo') {
        GMPGames.push(game)
      }

      if (game?.is_self_developed === 1) {
        selfGames.push(game)
      }

      if (game?.expand?.game_platform === 'hillingo-facai') {
        gmpFacai.push(game)
      }

      if (game?.expand?.game_platform === 'hillingo-classic') {
        gmpClassic.push(game)
      }

      if (
        typeof game?.expand?.game_platform === 'string' &&
        game?.expand?.game_platform.startsWith('hillingo-fundist')
      ) {
        gmpFundist.push(game)
      }
      
      if (game?.expand?.game_platform === 'hillingo-jili') {
        gmpJili.push(game)
      }

      if (game?.expand?.game_platform === 'hillingo-pgsoft') {
        gmpPgsoft.push(game)
      }
    }
  }

  games.sort(
    (
      { sort_weight: sortA, is_under_maint: maintA },
      { sort_weight: sortB, is_under_maint: maintB },
    ) => {
      const offset = 50000
      if (maintA === 1) sortA - offset
      if (maintB === 1) sortB - offset
      return sortA - sortB
    },
  )

  return {
    games: games,
    GMPGames: GMPGames,
    selfGames: selfGames,
    gmpInhouseGame: gmpInhouseGame,
    gmpJili,
    gmpFundist,
    gmpClassic,
    gmpFacai,
    gmpPgsoft
  }
}
