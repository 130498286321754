import { Text } from '@chakra-ui/react'
import type { PropsWithChildren } from 'react'

export function CustomizedFormErrorMessage(props: PropsWithChildren) {
  return (
    <Text
      color='red.500'
      textAlign={'right'}
      my='px'
      paddingRight='6px'
      textStyle='text5'
    >
      {props.children}
    </Text>
  )
}
