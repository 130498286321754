import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import { isFeatureEnabled } from '@/common/feature'
import { luckyWheelApi } from '@/modules/activity/lucky-wheel/luckyWheelApi'
import { pageConfigApi } from '@/services/jsonApi'
import { getDeviceType } from '@/utils/tools'

import type { DiversionItemProps } from '../app/home-page-config-types'
import { FUNCTION_TYPE } from '../app/home-page-config-types'
import { startAppListening } from '../app/listenerMiddleware'
import type { RootState } from '../app/store'
import { openModal, setModalData } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { guideEventApi } from './guideEventApi'
import type { FirstLoginTaskResProps } from './type'

const APP_DOWNLOAD_DIVERSION_FUNCTION_MAP = {
  [FUNCTION_TYPE.AGENT]: 'agent',
  [FUNCTION_TYPE.CASHBACK]: 'cashback',
  [FUNCTION_TYPE.COUPON]: 'coupon',
  [FUNCTION_TYPE.DAILY_TASK]: 'dailyTask',
  [FUNCTION_TYPE.LOGIN_REWARD]: 'loginReward',
  [FUNCTION_TYPE.VIP_REWARD]: 'vipReward',
  [FUNCTION_TYPE.WHEEL]: 'wheel',
  [FUNCTION_TYPE.WITHDRAW]: 'withdraw',
}

interface StateProps {
  appDownloadDiversion: Record<string, DiversionItemProps>
  firstLoginRewardInfoOrigin?: 'init' | 'login'
  firstLoginRewardInfo?: FirstLoginTaskResProps
  loaded: boolean
}

const initialState: StateProps = {
  appDownloadDiversion: {},
  loaded: false,
}

const DEVICE = getDeviceType()
const newAppLoginReward = isFeatureEnabled('newAppLoginReward')

function adjustFirstLoginReward(
  platformArr: ('ios_desktop' | 'android_app' | 'ios_app')[],
) {
  if (newAppLoginReward) {
    return newAdjustFirstLoginReward(platformArr)
  }
  if (DEVICE === 'ios' || DEVICE === 'ios-desktop-icon') {
    return platformArr.includes('ios_desktop')
  }

  if (DEVICE === 'ios_app') {
    return platformArr.includes('ios_app')
  }

  return platformArr.includes('android_app')
}

function newAdjustFirstLoginReward(
  platformArr: ('ios_desktop' | 'android_app' | 'ios_app')[],
) {
  if (['ios', 'ios-desktop-icon'].includes(DEVICE)) {
    return (
      platformArr.includes('ios_desktop') || platformArr.includes('ios_app')
    )
  }

  if (DEVICE === 'ios_app') {
    return platformArr.includes('ios_app')
  }

  return platformArr.includes('android_app')
}

export const guideEventSlice = createSlice({
  name: 'guideEvent',
  initialState,
  reducers: {
    setFirstLoginRewardInfoOrigin: (
      state,
      action: PayloadAction<'init' | 'login' | undefined>,
    ) => {
      state.firstLoginRewardInfoOrigin = action.payload
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      pageConfigApi.endpoints.getHomePageConfig.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action.payload || {}
        const { app_download_diversion } = data
        const appDownloadDiversion: Record<string, DiversionItemProps> = {}
        app_download_diversion?.forEach((item: DiversionItemProps) => {
          const functionName =
            APP_DOWNLOAD_DIVERSION_FUNCTION_MAP?.[item.function_type]
          if (functionName) {
            appDownloadDiversion[functionName] = item
          }
        })
        state.appDownloadDiversion = appDownloadDiversion
      },
    ),
      builder.addMatcher(
        guideEventApi.endpoints.getMyFirstLoginReward.matchFulfilled,
        (state, action) => {
          const { payload } = action
          const data = payload?.data || []
          state.loaded = true

          if (!state.firstLoginRewardInfoOrigin) {
            state.firstLoginRewardInfoOrigin = 'init'
            state.firstLoginRewardInfo =
              !data?.task ||
              data?.task?.state === 2 ||
              data?.reward?.is_claim === 1 ||
              data?.reward?.is_resend === 1 ||
              (data.task &&
                data.task.config?.open_platform &&
                !adjustFirstLoginReward(data.task.config?.open_platform)) ||
              (typeof data.user_is_allow === 'boolean' && !data.user_is_allow)
                ? undefined
                : data
            return
          }

          if (state.firstLoginRewardInfoOrigin === 'init') {
            state.firstLoginRewardInfoOrigin = 'login'
            state.firstLoginRewardInfo =
              !data?.task ||
              data?.task?.state === 2 ||
              data?.reward?.is_claim === 1 ||
              data?.reward?.is_resend === 1 ||
              (data.task &&
                data.task.config?.open_platform &&
                !adjustFirstLoginReward(data.task.config?.open_platform)) ||
              (typeof data.user_is_allow === 'boolean' && !data.user_is_allow)
                ? undefined
                : data
            return
          }
        },
      ),
      builder.addMatcher(
        guideEventApi.endpoints.getMyFirstLoginReward.matchRejected,
        state => {
          state.firstLoginRewardInfo = undefined
          state.firstLoginRewardInfoOrigin = 'init'
          state.loaded = true
        },
      )
  },
})

export const GuideEventReducer = guideEventSlice.reducer
export const guideEvent = (state: RootState) => state.guideEvent

export const { setFirstLoginRewardInfoOrigin } = guideEventSlice.actions

// 监听getMyFirstLoginReward结果判断来源后执行action
startAppListening({
  matcher: guideEventApi.endpoints.getMyFirstLoginReward.matchFulfilled,
  effect: (_, store) => {
    const guideEventState = store.getState().guideEvent
    const userState = store.getState().user

    if (!userState.token || !userState.userId) {
      store.dispatch(setFirstLoginRewardInfoOrigin('init'))
    }

    if (!newAppLoginReward) {
      if (
        guideEventState.firstLoginRewardInfoOrigin === 'login' &&
        guideEventState.firstLoginRewardInfo &&
        userState.token &&
        userState.userId
      ) {
        const { firstLoginRewardInfo } = guideEventState
        const reward =
          Number(firstLoginRewardInfo?.reward?.reward) ||
          Number(firstLoginRewardInfo.task?.config?.reward) ||
          (['apk', 'android-desktop-icon', 'android', 'pc'].includes(DEVICE)
            ? firstLoginRewardInfo?.task?.config?.platform_reward?.[
                'android_app'
              ] || 0
            : firstLoginRewardInfo?.task?.config?.platform_reward?.[
                'ios_desktop'
              ] || 0)
        store.dispatch(setFirstLoginRewardInfoOrigin('init'))
        store.dispatch(
          openModal({
            key: GlobalModalKey.IntroductionToDownloadModal,
            data: {
              firstLoginRewardInfo: {
                biz_id: firstLoginRewardInfo.reward?.biz_id || undefined,
                reward,
                ...(firstLoginRewardInfo?.task?.config?.is_long_term_task
                  ? {}
                  : {
                      endTime:
                        (firstLoginRewardInfo?.task?.config?.task_end || 0) *
                        1000,
                    }),
              },
            },
          }),
        )
      }
    } else {
      if (
        guideEventState.firstLoginRewardInfo &&
        userState.token &&
        userState.userId
      ) {
        const { firstLoginRewardInfo } = guideEventState
        const reward =
          Number(firstLoginRewardInfo?.reward?.reward) ||
          Number(firstLoginRewardInfo.task?.config?.reward) ||
          (['apk', 'android-desktop-icon', 'android', 'pc'].includes(DEVICE)
            ? firstLoginRewardInfo?.task?.config?.platform_reward?.[
                'android_app'
              ] || 0
            : firstLoginRewardInfo?.task?.config?.platform_reward?.[
                'ios_desktop'
              ] ||
              firstLoginRewardInfo?.task?.config?.platform_reward?.[
                'ios_app'
              ] ||
              0)
        store.dispatch(setFirstLoginRewardInfoOrigin('init'))
        store.dispatch(
          setModalData({
            key: GlobalModalKey.IntroductionToDownloadModal,
            data: {
              firstLoginRewardInfo: {
                open_platform:
                  firstLoginRewardInfo?.task?.config?.open_platform || [],
                user_is_recharge: !!firstLoginRewardInfo?.user_is_recharge,
                is_require_recharge:
                  !!firstLoginRewardInfo?.task?.config?.is_require_recharge,
                biz_id: firstLoginRewardInfo.reward?.biz_id || undefined,
                reward,
                ...(firstLoginRewardInfo?.task?.config?.is_long_term_task
                  ? {}
                  : {
                      endTime:
                        (firstLoginRewardInfo?.task?.config?.task_end || 0) *
                        1000,
                    }),
              },
            },
          }),
        )
      }
    }
  },
})

// 监听转盘裂变接口请求
startAppListening({
  matcher: luckyWheelApi.endpoints.getLuckyWheelConfig.matchFulfilled,
  effect: (_, store) => {
    const luckyWheelState = store.getState().luckyWheel
    const { startTime, endTime, state } = luckyWheelState
    const apiNotOpen =
      state !== 1 ||
      (startTime > 0 &&
        endTime > 0 &&
        (dayjs().isBefore(startTime * 1000) || dayjs().isAfter(endTime * 1000)))

    store.dispatch(
      setModalData({
        key: GlobalModalKey.LuckyWheelActivity,
        data: {
          isOpen: !apiNotOpen,
        },
      }),
    )
  },
})

export const selectFirstLoginRewardInfo = (state: RootState) =>
  state.guideEvent.firstLoginRewardInfo

/**
 * 引流弹窗配置
 */
export const selectAppDownloadDiversion = (state: RootState) =>
  guideEvent(state).appDownloadDiversion

/**
 * 引流下载弹窗代理配置
 */
export const selectADDAgent = (state: RootState) =>
  selectAppDownloadDiversion(state)?.[
    APP_DOWNLOAD_DIVERSION_FUNCTION_MAP[FUNCTION_TYPE.AGENT]
  ] || undefined

/**
 * 引流下载弹窗CASHBACK配置
 */
export const selectADDCashback = (state: RootState) =>
  selectAppDownloadDiversion(state)?.[
    APP_DOWNLOAD_DIVERSION_FUNCTION_MAP[FUNCTION_TYPE.CASHBACK]
  ] || undefined

/**
 * 引流下载弹窗转盘配置
 */
export const selectADDWheel = (state: RootState) =>
  selectAppDownloadDiversion(state)?.[
    APP_DOWNLOAD_DIVERSION_FUNCTION_MAP[FUNCTION_TYPE.WHEEL]
  ] || undefined

/**
 * 引流下载弹窗提现配置
 */
export const selectADDWithdraw = (state: RootState) =>
  selectAppDownloadDiversion(state)?.[
    APP_DOWNLOAD_DIVERSION_FUNCTION_MAP[FUNCTION_TYPE.WITHDRAW]
  ] || undefined

/**
 * 引流下载弹窗登录奖励配置
 */
export const selectADDLoginReward = (state: RootState) =>
  selectAppDownloadDiversion(state)?.[
    APP_DOWNLOAD_DIVERSION_FUNCTION_MAP[FUNCTION_TYPE.LOGIN_REWARD]
  ] || undefined

/**
 * 引流下载弹窗Vip奖励配置
 */
export const selectADDVipReward = (state: RootState) =>
  selectAppDownloadDiversion(state)?.[
    APP_DOWNLOAD_DIVERSION_FUNCTION_MAP[FUNCTION_TYPE.VIP_REWARD]
  ] || undefined

/**
 * 引流下载弹窗每日任务
 */
export const selectADDDailyTask = (state: RootState) =>
  selectAppDownloadDiversion(state)?.[
    APP_DOWNLOAD_DIVERSION_FUNCTION_MAP[FUNCTION_TYPE.DAILY_TASK]
  ] || undefined

/**
 * 引流下载弹窗兑换码
 */
export const selectADDCoupon = (state: RootState) =>
  selectAppDownloadDiversion(state)?.[
    APP_DOWNLOAD_DIVERSION_FUNCTION_MAP[FUNCTION_TYPE.COUPON]
  ] || undefined

/**
 * APP登录奖励接口请求完毕
 */
export const selectAppLoginRewardLoaded = (state: RootState) =>
  state.guideEvent.loaded

export const guideEventReducer = guideEventSlice.reducer
