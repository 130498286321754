import i18n from '@/common/locale/i18n'
import { isQueryError } from '@/services/types'

export const ERROR_CODE_MAP: Record<number, string> = {
  1004: 'ACCOUNT_REGISTED',
  400: 'ACORPW_ERROR',
  1005: 'ACCOUNT_BANNED',
  1033: 'VERIFY_CODE_ERR',
  1034: 'WRONG_REGISTER3',
}

export function getErrorMessage(
  error: unknown,
  defaultMessageKey = 'NETWORK_ERROR',
) {
  let message
  if (isQueryError(error) && error.status in ERROR_CODE_MAP) {
    message = i18n.t(ERROR_CODE_MAP[error.status])
  }
  return message || i18n.t(defaultMessageKey)
}
