import { useEffect, useLayoutEffect } from 'react'

/** 全局事件总线 */
export default class Event<T> {
  private _slots: Array<(data: T) => void> = []

  /** 注册事件监听 */
  on(slot: (data: T) => void): (data: T) => void {
    this._slots.push(slot)
    return slot
  }

  /** 取消事件监听 */
  off(slot: (data: T) => void): void {
    this._slots = this._slots.filter(s => s !== slot)
  }

  /** 触发事件 */
  emit(data: T): void {
    this._slots.forEach(slot => slot(data))
  }
}

/** 监听事件（建议handler使用useCallback再包装一下） */
export function useEvent<T>(event: Event<T>, handler: (data: T) => void) {
  useLayoutEffect(() => {
    event.on(handler)
    return () => event.off(handler)
  }, [event, handler])
}
