import Platform from '@/common/platform'
import { getUserId } from '@/modules/user/storage'
import { localStorage } from '@/utils/localStorage'
import { projectName } from '@/websites/current/property.json'

import { warn } from '../../logger'
import type { EventConfigType, TrackFn } from '../const'
import { EventName } from '../const'

const EVENT_CONFIGS: EventConfigType =
  projectName === 'N1'
    ? {
        [EventName.FbLogin]: ['sy08pv'],
        [EventName.CompleteRegistration]: ['yiwusm'],
        [EventName.CompleteFbRegistration]: ['ee8wyn'],
        [EventName.AdjustLogin]: ['7nxu1y'],
        [EventName.EnterHome]: ['upw4y2'],
        [EventName.EnterRegister]: ['3hcmph'],
        [EventName.PhoneRegister]: ['pvphmq'],
        [EventName.EmailRegister]: ['mpj0id'],
        [EventName.EnterLogin]: ['lf7dee'],
        [EventName.PhoneLogin]: ['nt832b'],
        [EventName.EmailLogin]: ['32u1gl'],
        [EventName.ClickWallet]: ['o1jaqp'],
        [EventName.ShowRechargePanel]: ['tlwdva'],
        [EventName.ClickRecharge]: ['uphbc6'],
        [EventName.ShowWithdrawPanel]: ['c0xj50'],
        [EventName.ClickDownload]: ['5dkj13'],
        [EventName.ApkRegister]: ['16lwpr'],
        [EventName.ApkLogin]: ['3ia6ml'],
      }
    : {
        [EventName.ContentView]: ['PAGE_VIEW'],
        [EventName.Login]: ['LOGIN'],
        [EventName.FbLogin]: ['FB_LOGIN'],
        [EventName.CompleteRegistration]: ['REGISTER'],
        [EventName.CompleteFbRegistration]: ['FB_REGISTER'],
        [EventName.PlaceAnOrder]: ['PlaceAnOrder'],
        // [EventName.PhoneRegister]: ['REGISTER'],
        // [EventName.EmailRegister]: ['REGISTER'],
        [EventName.PhoneLogin]: ['LOGIN'],
        [EventName.EmailLogin]: ['LOGIN'],
      }

export const trackerFn: TrackFn = event => {
  try {
    const events = EVENT_CONFIGS[event]
    if (!events) {
      throw new Error('Unknown event type')
    }

    for (const event of events) {
      const id =
        event.includes('LOGIN') || event.includes('REGISTER') ? getUserId() : ''
      Platform?.firebaseEvent({ key: event, value: id })
    }
  } catch (error) {
    warn(`[Tracker]: ADJUST event ${event} fail to send ${error}`)
  }
}
