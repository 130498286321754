import { t } from 'i18next'
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'

export interface VerifyCodeCountDownRef {
  startCountDown: () => void
  isCountDown: boolean
}

const VerifyCodeCountDown = forwardRef(
  (
    {
      countDownEndCb,
      seconds,
      scene,
    }: {
      countDownEndCb?: () => void
      seconds?: number
      scene: string
    },
    ref,
  ) => {
    // 获取localStorage中对应场景的倒计时时间
    const sceneCountDown = localStorage.getItem(scene)

    // 剩余秒数
    const lessSeconds = useMemo(() => {
      if (sceneCountDown) {
        const temp = Math.round(
          (Number(sceneCountDown) - new Date().getTime()) / 1000,
        )
        if (temp > 0) {
          return temp
        }
        localStorage.removeItem(scene)
        setTimeout(() => {
          countDownEndCb?.()
        })
        return seconds ?? 60
      } else {
        return seconds ?? 60
      }
    }, [scene, sceneCountDown, seconds])

    // 如果localStorage中有对应场景的倒计时时间，且剩余秒数大于0，则说明正在倒计时
    const [isCountDown, setIsCountDown] = useState(() => {
      return sceneCountDown && Number(sceneCountDown) - new Date().getTime() > 0
    })

    // 倒计时剩余秒数
    const [countDown, setCountDown] = useState(() => {
      return lessSeconds
    })

    // 导出ref, 用于父组件调用 开启倒计时
    useImperativeHandle(ref, () => ({
      startCountDown: () => {
        setIsCountDown(true)
        localStorage.setItem(
          scene ?? 'countdown',
          String(new Date().getTime() + (seconds ?? 60) * 1000),
        )
      },
      isCountDown,
    }))

    useEffect(() => {
      if (isCountDown) {
        // 开启倒计时
        const timer = setInterval(() => {
          setCountDown(countDown => {
            // 倒计时结束
            if (countDown <= 0) {
              setIsCountDown(false)
              clearInterval(timer)
              // 清除localStorage中对应场景的倒计时时间
              localStorage.removeItem(scene)
              // 倒计时结束回调
              setTimeout(() => {
                countDownEndCb?.()
              })
              return seconds ?? 60
            } else {
              return countDown - 1
            }
          })
        }, 1000)
        return () => clearInterval(timer)
      }
    }, [countDownEndCb, isCountDown, scene, seconds])

    return (
      <div>
        <span>{isCountDown ? countDown : t('PH_SEND_BTN')}</span>
      </div>
    )
  },
)

VerifyCodeCountDown.displayName = 'VerifyCodeCountDown'
const VerifyCodeCountDownWrapper = memo(VerifyCodeCountDown)

export default VerifyCodeCountDownWrapper
