import type { TabsProps } from '@chakra-ui/react'
import { Tab, TabList, Tabs, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import LazyImage from '@/components/lazy-image/LazyImage'

export function ManufacturerTabList({
  items,
  textOnly = false,
  index = 0,
}: {
  index?: number
  items: { id: number; name: string; icon?: string }[]
  textOnly?: boolean
}) {
  const [swiper, setSwiper] = useState<any>()

  useEffect(() => {
    if (swiper) {
      swiper?.slideTo(index, 500, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiper, index])

  return (
    <TabList
      onSwiper={(val: any) => setSwiper(val)}
      as={Swiper}
      key='ManufacturerTabs'
      slidesPerView='auto'
      mx='0'
      p='0'
    >
      {items.map(({ id, name, icon }) => (
        <SwiperSlide key={id + '_' + name} style={{ width: 'max-content' }}>
          <Tab cursor='pointer'>
            {textOnly ? (
              <Text textStyle='text3' cursor='pointer'>
                {name}
              </Text>
            ) : (
              <LazyImage src={icon} height={'32px'} width='74px' />
            )}
          </Tab>
        </SwiperSlide>
      ))}
    </TabList>
  )
}

export function ManufacturerTabs({
  items,
  onChange,
  index = 0,
  textOnly = false,
  ...props
}: Omit<TabsProps, 'children'> & {
  items: { id: number; name: string; icon?: string }[]
  textOnly?: boolean
}) {
  return (
    <Tabs
      variant='second-game'
      colorScheme='second-game'
      index={index}
      onChange={onChange}
      {...props}
    >
      <ManufacturerTabList items={items} textOnly={textOnly} index={index} />
    </Tabs>
  )
}

export default ManufacturerTabs
