import { usePrevious } from 'ahooks'
import { useEffect } from 'react'

import { useAppSelector } from '@/modules/app/store'
import { selectUserBalance } from '@/modules/user/userSlice'

export default function useBalanceDecrease(cb: () => void) {
  const balance = useAppSelector(state => selectUserBalance(state)?.amount || 0)
  const balancePrev = usePrevious(balance)

  useEffect(() => {
    if (
      balance !== undefined &&
      balancePrev !== undefined &&
      balance < balancePrev
    ) {
      cb()
    }
  }, [balance, balancePrev, cb])
}
