import { Center, Spinner } from '@chakra-ui/react'
import { lazy, Suspense } from 'react'
import { useNavigate } from 'react-router-dom'

import Dialog from '@/components/modal/Dialog'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  closeModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import type {
  LoginModalState,
  ModalStateMap,
} from '@/modules/global-modal/types'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { useMobile } from '@/utils/hooks'

const LoginAndSignUp = lazy(() => import('./LoginAndSignUp'))

export default function LoginAndSignUpProvider() {
  return (
    <>
      <LoginAndSignupContent />
    </>
  )
}

/**
 * 登录注册弹窗
 */

const LoginAndSignupContent = () => {
  const navigate = useNavigate()
  const { open, data } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.Login] as LoginModalState,
  )

  const dispath = useAppDispatch()

  const handleCloseLoginModal = () => {
    dispath(closeModal({ key: GlobalModalKey.Login }))
    if (data?.isBackToHomeOnClosed) {
      navigate('/')
    }
  }

  const isMobile = useMobile()

  return (
    <Dialog
      isOpen={open}
      onClose={handleCloseLoginModal}
      size='3xl'
      variant={{ base: 'fill-square', md: 'round' }}
      isCentered={!isMobile}
      colorScheme='multilayer'
      motionPreset='scale'
    >
      <Suspense
        fallback={
          <Center height={['100vh', '30vh']}>
            <Spinner />
          </Center>
        }
      >
        <LoginAndSignUp />
      </Suspense>
    </Dialog>
  )
}
