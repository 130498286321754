import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  PinInput,
  PinInputField,
  SimpleGrid,
  useToast,
} from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdMailOutline, MdOutlineLock } from 'react-icons/md'

import TitledDialog from '@/components/modal/TitledDialog'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  closeModal,
  openModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import {
  useResetPasswordByPasswordMutation,
  useSentResetPasswordEmailMutation,
} from '@/modules/user/userApi'
import { useInputValidState } from '@/utils/hooks'
import { isValidEmailAddress, isValidPassword } from '@/utils/validform'

import { LoginFormInput } from './LoginForm'

const MAX_CODE_LEN = 6

export default function ResetPwdDialog() {
  const { t } = useTranslation()
  const toast = useToast()

  /** 弹窗状态 */
  const { open } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.ResetPassword],
  )

  const { open: isOpenWrongPassword } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.WrongPassword],
  )

  /** 重置密码接口 */
  const [resetPwd, { isLoading: isResetLoading }] =
    useResetPasswordByPasswordMutation()
  const [sendEmail, { isLoading: isSendEmailLoading }] =
    useSentResetPasswordEmailMutation()

  const dispath = useAppDispatch()
  /** 验证码 */
  const [codes, setCodes] = useState<string>()

  /** 邮箱 */
  const [emailDisabled, setEmailDisabled] = useState(false)
  const {
    value: account,
    setValue: setEmail,
    valid: emailValid,
    handleChange: handleEmailChange,
    isValid: handleEmailValid,
  } = useInputValidState('', isValidEmailAddress)

  /** 密码 */
  const {
    value: password,
    setValue: setPassword,
    valid: passwordValid,
    handleChange: handlePasswordChange,
    isValid: handlePasswordValid,
  } = useInputValidState('', isValidPassword)

  const handleResetForm = () => {
    setCodes('')
    setEmail('')
    setPassword('')
    setEmailDisabled(false)
  }

  const handleDialogClose = () => {
    handleResetForm()
    // dispath(openModal({ key: GlobalModalKey.Login }))
    dispath(closeModal({ key: GlobalModalKey.ResetPassword }))
  }

  const handleCodeInputChange = (val: string) => {
    setCodes(val)
  }

  /** 请求接口，发送邮件 */
  const sendCode: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault()
    if (handleEmailValid()) {
      try {
        await sendEmail({
          account,
        }).unwrap()

        /** 这里需要替换多语言 */
        toast({
          title: t('USER_RESET_PW_CODE'),
          status: 'success',
        })
        setEmailDisabled(true)
      } catch (err: unknown) {
        toast({
          title: err.message ? err.message : '',
          status: 'error',
        })
      }
    }
  }

  /** 请求接口更改密码 */
  const handleResetPwd: React.FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault()
    if (!codes || codes?.length < 6) {
      /** 这里需要替换多语言 */
      toast({
        title: t('USER_RESET_PW_CODE_INPUT'),
        status: 'error',
      })
      return
    }
    if (handlePasswordValid() && codes) {
      try {
        /** 需要补上 密码重置成功后的提示 - 多语言 */
        await resetPwd({
          account,
          code: codes,
          password,
        }).unwrap()

        toast({
          title: t('USER_RESET_PW_CODE_SUCCESS'),
          status: 'success',
        })

        dispath(closeModal({ key: GlobalModalKey.ResetPassword }))
        if (isOpenWrongPassword) {
          dispath(closeModal({ key: GlobalModalKey.WrongPassword }))
        }
      } catch (err: unknown) {
        toast({
          title: err.message ? err.message : '',
          status: 'error',
        })
      }
    }
  }

  const errorTips = useMemo(() => {
    if (!passwordValid) {
      if (!password.length) return t('USER_INPUT_PWD2')
      if (password.length < 8) {
        return t('USER_PWD_OVERLENGTH')
      }
      if (password.length > 64) {
        return t('USER_PWD_TOOLONG')
      }
    }

    return ''
  }, [password.length, passwordValid, t])

  return (
    <TitledDialog
      isOpen={open}
      onClose={handleDialogClose}
      title={t('USER_RESET_PWD')}
      size='sm'
    >
      <Box as='form' onSubmit={emailDisabled ? handleResetPwd : sendCode}>
        <LoginFormInput
          label={t('USER_INPUT_EMAIL').toString()}
          disabled={emailDisabled}
          value={account}
          handleValueChange={handleEmailChange}
          addonBefore={<Icon boxSize='5' as={MdMailOutline} />}
          validator={{
            valueValid: emailValid,
            errorMsg: t('USER_EMAIL_INVALID'),
          }}
          fieldProps={{
            name: 'email',
            type: 'email',
            placeholder: t('USER_INPUT_EMAIL').toString(),
          }}
        />

        {emailDisabled && (
          <>
            <FormControl isInvalid={!emailValid} mb='4'>
              <FormLabel color='gray.700'>
                {t('USER_VERIFICATION_CODE')}
              </FormLabel>
              <SimpleGrid columns={MAX_CODE_LEN}>
                <PinInput placeholder='' onChange={handleCodeInputChange}>
                  {Array.from({ length: MAX_CODE_LEN }).map((_, index) => {
                    return <PinInputField key={`verication_code1_${index}`} />
                  })}
                </PinInput>
              </SimpleGrid>
            </FormControl>
            <LoginFormInput
              label={t('USER_ENTER_PWD').toString()}
              value={password}
              handleValueChange={handlePasswordChange}
              addonBefore={<Icon boxSize='5' as={MdOutlineLock} />}
              validator={{
                valueValid: passwordValid,
                errorMsg: errorTips,
              }}
              fieldProps={{
                name: 'password',
                type: 'password',
                placeholder: t('USER_ENTER_PWD').toString(),
              }}
            />
          </>
        )}

        <Box mt={1}>
          <Button
            size='lg'
            width='100%'
            type='submit'
            colorScheme='fill-prim'
            isLoading={emailDisabled ? isResetLoading : isSendEmailLoading}
          >
            {emailDisabled ? t('USER_RESET_PWD') : t('REQUEST_PIN')}
          </Button>
        </Box>
      </Box>
    </TitledDialog>
  )
}
