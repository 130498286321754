import { Flex, Icon, Image, Text } from '@chakra-ui/react'
import React from 'react'

import { getIcon } from '@/utils/get-icon'

import LazyImage from '../lazy-image/LazyImage'

interface AtomSectionHeadingProps {
  children?: React.ReactNode
  icon?: string | React.ReactNode
  useImage?: boolean
}

export function AtomSectionHeading(props: AtomSectionHeadingProps) {
  const { icon, children, useImage } = props
  return (
    <Flex alignItems='center' textStyle='h5'>
      {typeof icon === 'string' ? (
        useImage ? (
          <LazyImage src={icon} boxSize={'5'} />
        ) : (
          <Icon as={getIcon(icon)} boxSize='5' color='gray.700' />
        )
      ) : (
        icon
      )}
      <Text ml='2' cursor='default' color='tokenColors.sectionHeading'>
        {children}
      </Text>
    </Flex>
  )
}
