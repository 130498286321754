import { keyBy } from 'lodash-es'

import type { GameListItem } from '../gameInterface'
import type { CatesKeyByLabelId, CatesKeybyLabelType } from './getSortedCate'
import type { ManusKeybyId } from './getSortedManu'
import sortGame from './sortGame'

interface Args {
  manusKeybyId: ManusKeybyId
  enableManusKeybyId: ManusKeybyId
  catesKeyByLabelId: CatesKeyByLabelId
  catesKeybyLabelType: CatesKeybyLabelType
  games: GameListItem[]
}

export const isValidGame = (game: GameListItem) => {
  return game?.game_status === 1
}

export default function getGameData({
  manusKeybyId,
  catesKeyByLabelId,
  catesKeybyLabelType,
  games,
  enableManusKeybyId,
}: Args) {
  const gamesKeybyId = keyBy(games, 'game_id')
  for (let index = 0; index < games.length; index++) {
    /** 重组 */
    const { game_manufacturer, game_id, game_category, group_label } = games[
      index
    ] as GameListItem
    const game = {
      ...games[index],
      id: games[index]?.game_name,
      name: games[index]?.game_name,
      title: games[index]?.game_name,
    }

    games[index] = game

    /**
     * 厂商插入数据
     */
    if (manusKeybyId[game_manufacturer] && isValidGame(gamesKeybyId[game_id])) {
      manusKeybyId[game_manufacturer].data.push(gamesKeybyId[game_id])
    }

    if (
      enableManusKeybyId[game_manufacturer] &&
      isValidGame(gamesKeybyId[game_id]) &&
      !manusKeybyId[game_manufacturer]?.data?.length
    ) {
      enableManusKeybyId[game_manufacturer].data.push(gamesKeybyId[game_id])
    }
  }

  for (const item in manusKeybyId) {
    const data = sortGame(manusKeybyId[item]?.data || [])
    manusKeybyId[item].data = data
  }

  for (const item in enableManusKeybyId) {
    const data = sortGame(enableManusKeybyId[item]?.data || [])
    enableManusKeybyId[item].data = data
  }
}
