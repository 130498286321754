/**
 * 活动提示弹窗
 */

import {
  Box,
  Button,
  Flex,
  Text,
  useClipboard,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import service from '@/assets/images/service.png'
import LazyImage from '@/components/lazy-image/LazyImage'
import TitledDialog from '@/components/modal/TitledDialog'
import { useWalletService } from '@/modules/wallet/useWalletService'
import { downloadApp } from '@/utils/download-app'
import { getDeviceType } from '@/utils/tools'

import { useAppDispatch, useAppSelector } from '../app/store'
import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { ActTipsbtnRenderType, type ActTipsProps } from './type'

const DEVICE = getDeviceType()

export default function ActTips() {
  const { open, data } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.ActTips],
  )
  const { open: ServiceListOpen } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.ServiceList],
  )
  const openWalletService = useWalletService()
  const navigate = useNavigate()
  const toast = useToast()

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const tipsData = data as ActTipsProps

  /** 活动中心 */
  function ActivityCenterBtn() {
    function handleClick() {
      dispatch(closeModal({ key: GlobalModalKey.ActTips }))
      navigate('/promotions')
    }
    return (
      <Button onClick={handleClick} colorScheme='fill-prim' w='full' h='46px'>
        {t('MENU_ACTIVITY_CENTER')}
      </Button>
    )
  }

  /** 下载APP */
  function ActivityAppBtn() {
    function handleClick() {
      downloadApp()
    }
    return (
      <Button onClick={handleClick} colorScheme='fill-seco' w='full' h='46px'>
        {t(DEVICE === 'ios' ? 'MOBILE_IOS_COLLECT_TEXT' : 'ADD_PHONE_BUTTON')}
      </Button>
    )
  }

  /** 全部游戏 */
  function ActivityAllGameBtn() {
    function handleClick() {
      dispatch(closeModal({ key: GlobalModalKey.ActTips }))
      navigate('/game/all')
    }
    return (
      <Button onClick={handleClick} colorScheme='fill-seco' w='full' h='46px'>
        {t('GAMELIST_ALLGAME')}
      </Button>
    )
  }

  /** 复制链接：平台地址 */
  function CopyPlatformUrlBtn() {
    const { onCopy } = useClipboard(window.location.href)

    function handleClick() {
      onCopy()
      toast({
        title: t('COMMON_CONTENT_CANCEL'),
        status: 'success',
      })
    }

    return (
      <Button onClick={handleClick} colorScheme='fill-seco' w='full' h='46px'>
        {t('INVITE_COPY_LINK')}
      </Button>
    )
  }

  const BTN_RENDER_MAP = {
    [ActTipsbtnRenderType.ACTIVITY_CENTER_BTN]: <ActivityCenterBtn />,
    [ActTipsbtnRenderType.ACTIVITY_INTRODUTION_BTN]: <ActivityAppBtn />,
    [ActTipsbtnRenderType.ACTIVITY_ALL_GAME_BTN]: <ActivityAllGameBtn />,
    [ActTipsbtnRenderType.ACTIVITY_COPY_URL_BTN]: <CopyPlatformUrlBtn />,
  }

  const onClose = () => {
    dispatch(closeModal({ key: GlobalModalKey.ActTips }))
    if (tipsData?.isBlocking) navigate('/')
  }

  return (
    <TitledDialog
      isOpen={open && !ServiceListOpen}
      onClose={onClose}
      colorScheme='multilayer'
      size={{ base: 'sm', md: 'md' }}
      closeOnOverlayClick={!tipsData?.isBlocking}
      title={
        <Text
          textAlign='center'
          fontSize='18px'
          lineHeight='24px'
          fontWeight='800'
          color='gray.900'
        >
          {t(tipsData?.title)}
        </Text>
      }
    >
      <VStack>
        <LazyImage src={tipsData?.icon} w='110px' height='auto' />
        <Text
          textAlign='center'
          fontSize='12px'
          lineHeight='16px'
          fontWeight='600'
          color='gray.700'
        >
          {t(tipsData?.content).toString()}
        </Text>
        {!tipsData?.btnRender ? (
          <Button
            colorScheme='fill-seco'
            size='lg'
            w='full'
            onClick={onClose}
            _hover={{
              colorScheme: 'fill-seco',
            }}
          >
            {t(tipsData?.okButtonText || 'OK_BUTTON')}
          </Button>
        ) : (
          <>{BTN_RENDER_MAP[tipsData?.btnRender] || null}</>
        )}

        {tipsData?.needCustomer && (
          <Flex
            alignItems='center'
            justifyContent='center'
            onClick={() => {
              openWalletService()
              onClose()
            }}
          >
            <LazyImage
              src={service}
              mr='4px'
              w='20px'
              h='20px'
              cursor='pointer'
              alt=''
            />
            <Box
              color={'#1376FA'}
              fontSize={'12px'}
              textDecoration={'underline'}
              cursor='pointer'
            >
              {t('MENU_ONLINE_SERVICE')}
            </Box>
          </Flex>
        )}
      </VStack>
    </TitledDialog>
  )
}
