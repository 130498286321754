import { api } from '@/services/api'
import type { Response } from '@/services/types'

import type {
  DrawpbDrawInfoRsp,
  DrawpbDrawListRsp,
  DrawpbDrawRecordListRsp,
  DrawpbDrawTask,
  DrawRes,
  GetDrawDrawRecordListReq,
} from '../types'

export const drawActivityApi = api.injectEndpoints({
  endpoints: build => ({
    // 获取抽奖活动信息
    getDrawActivityInfo: build.query<
      Response<DrawpbDrawInfoRsp>,
      Pick<DrawpbDrawTask, 'task_id'>
    >({
      query: data => {
        return {
          url: '/gw/draw/info',
          data,
          method: 'POST',
        }
      },
      providesTags: (_r, _e, { task_id }) => [
        { type: 'DrawActivityInfo', id: task_id },
      ],
    }),
    // 抽奖
    drawPlay: build.mutation<
      DrawRes,
      Pick<DrawpbDrawTask, 'task_id'> & { version: number }
    >({
      query: data => {
        return {
          url: '/gw/draw/draw',
          data,
          method: 'POST',
        }
      },
      invalidatesTags: (_r, error, { task_id }) =>
        error ? [] : [{ type: 'DrawActivityInfo', id: task_id }],
    }),
    //   获取可用的抽奖列表
    getDrawActivityList: build.query<Response<DrawpbDrawListRsp>, void>({
      query: data => {
        return {
          url: '/gw/draw/list',
          data,
          method: 'POST',
        }
      },
    }),
    getRecordList: build.query<
      Response<DrawpbDrawRecordListRsp>,
      GetDrawDrawRecordListReq
    >({
      query: data => {
        return {
          url: '/gw/draw/record_list',
          data,
          method: 'POST',
        }
      },
      providesTags: (_r, _e, { task_id }) => [
        { type: 'DrawActivityInfo', id: task_id },
      ],
    }),
    checkIn: build.mutation<Response<unknown>, Pick<DrawpbDrawTask, 'task_id'>>(
      {
        query: data => {
          return {
            url: '/gw/draw/sign_in',

            data,
            method: 'POST',
          }
        },
        invalidatesTags: (_result, error, { task_id }) =>
          error ? [] : [{ type: 'DrawActivityInfo', id: task_id }],
      },
    ),
  }),
})

export const {
  useDrawPlayMutation,
  useCheckInMutation,
  useGetRecordListQuery,
  useGetDrawActivityListQuery,
  useGetDrawActivityInfoQuery,
} = drawActivityApi
