import type { ChakraProps } from '@chakra-ui/react'
import { Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { GlobalModalKey } from '@/modules/global-modal/types'
import { Icon as SharedIcon } from '@/utils/atom-shared'

import { useAppDispatch } from '../app/store'
import { openModal } from '../global-modal/globalModalSlice'
import { getColorModeValue } from '@/utils/tools'

export default function SearchTigger(
  props: ChakraProps & { colorScheme?: 'normal' | 'gray' },
) {
  const { colorScheme = 'normal', ...restProps } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const show = () => {
    dispatch(openModal({ key: GlobalModalKey.Search }))
  }

  return (
    <InputGroup {...restProps}>
      <InputLeftElement>
        <Icon as={SharedIcon.Search} color='text.base' boxSize={4} />
      </InputLeftElement>
      <Input
        onClick={show}
        readOnly
        placeholder={String(t('SEARCH_INPUT_DSC'))}
        _placeholder={{ color: 'text.base' }}
        _focusVisible={{ borderColor: 'gray.400' }}
        sx={
          colorScheme === 'gray'
            ? {
                bg: getColorModeValue('gray.50', 'gray.500'),
                borderColor: getColorModeValue('gray.300', 'gray.500'),
                _focusVisible: {
                  borderColor: getColorModeValue('gray.300', 'gray.500'),
                },
              }
            : undefined
        }
      />
    </InputGroup>
  )
}
