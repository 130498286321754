// B3充值信息保存

import { isEmpty } from 'lodash-es'
import { useCallback, useMemo } from 'react'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { selectUserIsLogin } from '@/modules/user/userSlice'
import { features } from '@/websites/current/property.json'

import type { UserSaveListProps, WithdrawSelectListProps } from './interface'
import { handlePIXSubmitInfo } from './utils'
import {
  useGetUserRechargeInfoMutation,
  useSetUserRechargeInfoMutation,
} from './walletApi'
import {
  getRecahrgeList,
  openParamsModal,
  selectWallet,
  setHandleType,
  setRechargeFormParams,
  setRechargeInfoList,
} from './walletSlice'

const { RECAHRGE_INFO_LIST = false } = features

export const useRechargeWays = () => {
  const isLogin = !!useAppSelector(selectUserIsLogin)

  const [_getUserRechargeInfo] = useGetUserRechargeInfoMutation()

  const [_setUserRechargeInfo] = useSetUserRechargeInfoMutation()

  const rechargeInfoList = useAppSelector(getRecahrgeList)

  const rechargeParams = useAppSelector(
    state => selectWallet(state).rechargeParams,
  )

  const dispatch = useAppDispatch()

  const _get = useCallback(() => {
    try {
      _getUserRechargeInfo()
        .unwrap()
        .then((result: WithdrawSelectListProps) => {
          dispatch(setRechargeInfoList(result?.data))
        })
    } catch (error) {
      throw new Error(`error`)
    }
  }, [_getUserRechargeInfo, dispatch])

  const _update = useCallback(
    (data: UserSaveListProps) => {
      if (!RECAHRGE_INFO_LIST) return
      try {
        _setUserRechargeInfo(handlePIXSubmitInfo(data || {}))
          .unwrap()
          .then(() => _get())
      } catch (error) {
        throw new Error(`userRechargeInfo error`)
      }
    },
    [_setUserRechargeInfo, _get],
  )

  const getRechargInfoList = useCallback(() => {
    if (RECAHRGE_INFO_LIST && isLogin) {
      _get()
    }
  }, [isLogin, _get])
  //只有一条记录
  const rechargeSaveInfo = useMemo(() => {
    if (isEmpty(rechargeInfoList)) {
      return {}
    }
    return rechargeInfoList
  }, [rechargeInfoList])
  //
  const userSaveInfoUpdate = useCallback(
    (data: UserSaveListProps) => {
      //新增
      _update(data)
    },
    [_update],
  )
  //参数错误处理
  const paramasErrorHandal = useCallback(() => {
    if (
      RECAHRGE_INFO_LIST &&
      (rechargeParams?.typ === 'PIXQR' || rechargeParams?.typ === 'PIXQRNEW')
    ) {
      dispatch(setHandleType('recharge'))
      dispatch(
        setRechargeFormParams({
          data: {
            ...rechargeParams,
            ...rechargeSaveInfo,
          },
        }),
      )
      dispatch(openParamsModal())
    }
  }, [dispatch, rechargeSaveInfo, rechargeParams])
  return {
    userSaveInfoUpdate,
    rechargeInfoList,
    getRechargInfoList,
    rechargeSaveInfo,
    paramasErrorHandal,
  }
}
