import { Box, Button, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { createSelector } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { useEffect, useMemo, useRef, useState } from 'react'

import service from '@/assets/images/service.png'
import { getVariable } from '@/common/env'
import type { FormRef } from '@/components/form/Form'
import Form from '@/components/form/Form'
import InputFormItem from '@/components/form/FormItem'
import PhoneFormItem from '@/components/form/PhoneFormItem'
import SelectFormItem from '@/components/form/SelectFormItem'
import { InfoTooltip } from '@/components/info-tooltip/InfoTooltip'
import LazyImage from '@/components/lazy-image/LazyImage'
import TitledDialog from '@/components/modal/TitledDialog'
import formVerifyJson from '@/websites/current/public/config/form-verify.json'

import { useAppDispatch, useAppSelector } from '../app/store'
import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { useRecharge } from './useRecharge'
import { useWalletService } from './useWalletService'
import { useEditPlayerRechargeInfoMutation } from './walletApi'
import { getHandleType, selectRechargeForm, selectWallet } from './walletSlice'

const RECHARGE_ERROR_TIPS = getVariable('RECHARGE_ERROR_TIPS')

const WITHDRAW_MODAL_BTN_TEXT: {
  [key: string]: string
} = {
  withdraw_edit: 'UPDATE_BUTTON',
  withdraw_add: 'ADD_BUTTON',
  recharge_edit: 'UPDATE_BUTTON',
  recharge_add: 'ADD_BUTTON',
  withdraw: 'ADD_BUTTON',
  recharge: 'DEPOSIT_RECHARGE',
  add: 'ADD_BUTTON',
  update: 'UPDATE_BUTTON',
}

export default function WalletConfirmFormModal() {
  const rechargeForm = useAppSelector(selectRechargeForm)
  const dispatch = useAppDispatch()
  const formRef = useRef<FormRef>(null)
  const [changedFields, setChangedFields] = useState<Record<string, any>>()
  const { open: isOpen, data } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.WalletConfirmForm],
  )

  const [editPlayerRechargeInfo, { isLoading: isEditingRechargeInfo }] =
    useEditPlayerRechargeInfoMutation()

  const handleCloseModal = () => {
    if (isEditingRechargeInfo) {
      return
    }
    dispatch(closeModal({ key: GlobalModalKey.WalletConfirmForm }))
  }

  const handleEditPlayerRechargeInfo = async (val: Record<string, any>) => {
    try {
      for (const key in val) {
        if (formVerifyJson?.[key as keyof typeof formVerifyJson]?.trimBlank) {
          val[key] = val[key]?.trim()
        }
      }
      await editPlayerRechargeInfo(val).unwrap()
      return true
    } catch (error) {
      /* empty */
    }
    return false
  }

  /**
   * 充值hook入参
   */
  const initRechargeHookParam = useMemo(() => {
    if (data?.type === 'recharge') {
      return data?.rechargeParams
    }
  }, [data])

  /**
   * 充值参数获取
   */
  const rechargeParams = useAppSelector(
    createSelector(selectWallet, wallet => wallet.rechargeParams),
  )

  /** 调用充值hook */
  const {
    trigger: recharge,
    result: { isLoading: isRecharging, isSuccess: isRechargingSuccess },
  } = useRecharge(initRechargeHookParam)

  const handleRecharge = (val: Record<string, any>) => {
    recharge({
      ...rechargeParams,
      ...val,
    })
  }

  const openWalletService = useWalletService()
  const WITHDRAW_TYPE = useAppSelector(getHandleType)
  const title = data?.type?.includes('recharge')
    ? t('DEPOSIT_RECHARGE')
    : t('DEPOSIT_WITHDRAWN')

  const args = useMemo(() => {
    if (data?.type.includes('recharge')) {
      return rechargeForm
    }
    return []
  }, [data, rechargeForm])

  const handleFieldsChange = (changedFields: Record<string, any>) => {
    if (changedFields.id_type) {
      setChangedFields(changedFields)
      formRef.current?.setFieldValue('id_number', '')
    }
  }

  const formSchema = useMemo(() => {
    return args?.map((item: any) => ({
      type: item.type,
      name: item.name,
      label: t(item.title),
      ...(item.type === 'select' ? { options: item.data } : {}),
      fieldProps: {
        ...((formVerifyJson as any)?.[item.name]?.fieldProps || {}),
        placeholder: t(
          (formVerifyJson as any)?.[item.name]?.fieldProps?.placeholder || '',
        ),
      },
      key: (formVerifyJson as any)?.[item.name]?.rules?.some(
        (item: any) => item.dependencies,
      )
        ? `${item.name}_${formRef.current?.getFieldValue(
            (formVerifyJson as any)?.[item.name]?.rules?.[0]?.dependencies,
          )}`
        : item.name,
      rules: (formVerifyJson as any)?.[item.name]?.rules?.map((rule: any) => {
        if (rule.isRequire) {
          return {
            required: true,
            message: t(rule?.message || ''),
          }
        }
        const realDependenciesVal =
          formRef.current?.getFieldValue(rule.dependencies) ||
          data?.initialValues?.[rule.dependencies]
        const isMatch =
          (rule?.dependencies && realDependenciesVal === rule.value) ||
          (!rule?.dependencies && rule.regex)
        if (isMatch) {
          return {
            validator: async (value: string) => {
              if ((formVerifyJson as any)?.[item.name]?.trimBlank) {
                value = value.trim()
              }
              const errorMsg = t(rule?.message || '')
              const pattern = new RegExp(rule.regex)
              if (!pattern.test(value)) {
                return Promise.reject(new Error(errorMsg))
              }

              return Promise.resolve()
            },
          }
        }
        return undefined
      }),
    }))
  }, [args, changedFields, data?.initialValues])

  const formItemRender = (item: any) => {
    if (item.type === 'select') {
      return <SelectFormItem hasBorder {...item} />
    }

    if (item.type === 'input') {
      if (item.name === 'phone') {
        return <PhoneFormItem {...item} />
      }

      return <InputFormItem {...item} />
    }
  }

  const serviceClick = () => {
    openWalletService()
  }

  useEffect(() => {
    if (isRechargingSuccess) {
      handleCloseModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRechargingSuccess])

  const modalTitleElem = (
    <VStack spacing={5}>
      {/* {isB3QRCodePayment && <TitleWithRechargeWayIcon />} */}
      <HStack justifyContent='center'>
        <Text>{title}</Text>
        {data?.type?.includes('withdraw') && (
          <InfoTooltip
            tooltipProps={{
              label: t('PH_PAY_WAY_TIPS').toString(),
              colorScheme: 'white',
            }}
            iconProps={{
              color: 'icon.base',
            }}
          />
        )}
      </HStack>
    </VStack>
  )

  const handleFormSubmit = async (val: Record<string, any>) => {
    if (data?.type === 'recharge') {
      const res = await handleEditPlayerRechargeInfo(val)
      if (res) {
        handleRecharge(val)
      }
    }

    if (data?.type.includes('recharge')) {
      const res = await handleEditPlayerRechargeInfo(val)
      if (res) {
        handleCloseModal()
      }
    }
  }

  return (
    <TitledDialog
      title={modalTitleElem}
      isOpen={isOpen}
      onClose={handleCloseModal}
      size='sm'
    >
      <Form
        initialValues={data?.initialValues}
        ref={formRef}
        onFieldsChange={handleFieldsChange}
        onSubmit={async val => handleFormSubmit(val)}
        submitBtnRender={
          <SimpleGrid columns={2} spacing='2.5' w='full' mt='8'>
            <Button
              colorScheme='fill-tert'
              size='lg'
              isLoading={isEditingRechargeInfo}
              loadingText='Loading'
              onClick={handleCloseModal}
            >
              {t('USER_HEAD_PROTRAIT_CANCEL')}
            </Button>

            <Button
              type='submit'
              colorScheme='fill-prim'
              size='lg'
              isLoading={isRecharging || isEditingRechargeInfo}
              loadingText='Loading'
            >
              {t(WITHDRAW_MODAL_BTN_TEXT[WITHDRAW_TYPE || 'update'])}
            </Button>
          </SimpleGrid>
        }
      >
        {formSchema?.map((item: any) => formItemRender(item))}
      </Form>

      {/* 只有B系列的提现才展示客服入口 */}
      {data?.type.includes('withdraw') && RECHARGE_ERROR_TIPS && (
        <Box
          mt={'20px'}
          mb={'-10px'}
          display={'inline-flex'}
          alignItems={'center'}
          as='button'
          onClick={serviceClick}
        >
          <LazyImage
            src={service}
            mr='5px'
            w='24px'
            h='24px'
            cursor='pointer'
            alt=''
          />
          <Box color={'#1376FA'} fontSize={'12px'} textDecoration={'underline'}>
            {t('MENU_ONLINE_SERVICE')}
          </Box>
        </Box>
      )}
    </TitledDialog>
  )
}
