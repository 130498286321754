import { Center, Icon, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useCustomizedStyle } from '@/utils/hooks'

export interface AtomEmptyProps {
  tips?: string
  iconStyled?: Record<string, string>
  tipsStyled?: Record<string, string>
}

const EpmtyParts = ['container', 'icon', 'tips']

export default function AtomEmpty(props: AtomEmptyProps) {
  const { t } = useTranslation()
  const {
    tips = t('GAME_FORM_NO_DATA').toString(),
    iconStyled = {},
    tipsStyled = {},
  } = props

  const {
    container: containerStyle,
    icon: iconStyle,
    tips: tipsStyle,
  } = useCustomizedStyle(
    'AtomEmpty',
    {
      container: {
        flexDirection: 'column',
        w: 'full',
        h: 'full',
        py: '10',
        userSelect: 'none',
        color: 'text.base',
      },
      icon: {
        boxSize: '12',
        color: 'gray.600',
        ...iconStyled,
      },
      tips: {
        textStyle: 'text4',
        color: 'gray.600',
        ...tipsStyled,
      },
    },
    EpmtyParts,
  )

  return (
    <Center sx={containerStyle}>
      <Icon as={SharedIcon.EmptyBox} sx={iconStyle} />
      <Text textStyle='text4' sx={tipsStyle}>
        {tips}
      </Text>
    </Center>
  )
}
