import type { ComponentDefaultProps, SystemStyleObject } from '@chakra-ui/react'
import { chakra, Flex, Text } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'
import { AiFillCaretRight } from 'react-icons/ai'

const CharkraGameCardHover = chakra(Flex)

type GameCardHoverProps = ComponentDefaultProps & PropsWithChildren

export const AtomGameCardHover: FC<GameCardHoverProps> = ({
  orientation,
  children,
}) => {
  const innerStyles: SystemStyleObject = {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    bgColor: 'rgba(0,0,0,0.45)',
    position: 'absolute',
    left: '0',
    top: '0',
    w: '100%',
    h: '100%',
    opacity: '0',
    transitionProperty: 'all',
    transitionDuration: '0.1s',
    transitionTimingFunction: 'ease-in-out',

    zIndex: '1',
    textAlign: 'center',
    rowGap: orientation === 'horizontal' ? '8px' : '15px',
  }

  return (
    <CharkraGameCardHover
      sx={innerStyles}
      _groupHover={{
        opacity: 1,
      }}
    >
      <Text>{children}</Text>
      <Flex
        justifyContent='center'
        alignItems='center'
        w={orientation === 'horizontal' ? '42px' : '56px'}
        h={orientation === 'horizontal' ? '42px' : '56px'}
        borderRadius='50%'
        bgColor='#ed1d49'
        lineHeight={orientation === 'horizontal' ? '42px' : '56px'}
        fontSize='24px'
      >
        <AiFillCaretRight />
      </Flex>
    </CharkraGameCardHover>
  )
}

export default AtomGameCardHover
