import { useMediaQuery } from '@chakra-ui/react'

export const MAX_MOBILE_WIDTH = 768

export function useLargerThanMobile(point: number = MAX_MOBILE_WIDTH): boolean {
  const [isLargerThanMobile] = useMediaQuery(`(min-width: ${point}px)`, {
    ssr: false,
  })
  return isLargerThanMobile
}
