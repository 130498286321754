// export function handleDiff(num: number) {
//   console.log(num)
//   const num1 = (num * 10000) / 10000
//   return Math.ceil(num1 * 10000) / 10000
// }
// 99.999912

import { projectName } from '@/websites/current/property.json'

export function calculateResult(target: string, current: string): number {
  const decimalPlaces = current?.split('.')[1]?.length || 0
  if (decimalPlaces) {
    // 截取小数点后两位
    const temp = current
      .split('.')
      .map((part, index) =>
        index === 1 ? part.slice(0, Math.min(decimalPlaces, 2)) : part,
      )
      .join('.')
    return Math.round((Number(target) - Number(temp)) * 100) / 100
  } else {
    return Math.round(Number(target) - Number(current))
  }
}

export function calcNumber(num: string): string {
  const decimalPlaces = num?.split('.')[1]?.length || 0
  if (decimalPlaces) {
    // 截取小数点后两位
    return num
      .split('.')
      .map((part, index) =>
        index === 1 ? part.slice(0, Math.min(decimalPlaces, 2)) : part,
      )
      .join('.')
  } else {
    return num
  }
}

export function handleENumber(str: string) {
  try {
    if (str?.includes('e')) {
      const eSplit = str.split('e')
      const ePrefix = eSplit[0] || ''
      const eSuffix = eSplit[1] || ''
      const isNegative = Number(eSuffix) < 0
      const absSuffix = Math.abs(Number(eSuffix))
      const hasPot = ePrefix.includes('.') //  是否有小数点
      if (isNegative) {
        if (hasPot) {
          const num1 = ePrefix.split('.')[0] // 整数部分
          const num2 = ePrefix.split('.')[1] // 小树部分
          if (num1.length <= absSuffix) {
            return (
              '0.' +
              num1.substring(-1, absSuffix).padStart(absSuffix, '0') +
              num2
            )
          } else {
            return (
              num1.slice(0, num1.length - absSuffix) +
              '.' +
              num1.slice(num1.length - absSuffix) +
              num2
            )
          }
        } else {
          if (ePrefix.length > absSuffix) {
            return (
              ePrefix.slice(0, ePrefix.length - absSuffix) +
              '.' +
              ePrefix.slice(ePrefix.length - absSuffix)
            )
          } else {
            return '0.' + ePrefix.padStart(absSuffix, '0')
          }
        }
        // 科学技术法后面是负的
      } else {
        // 科学技术法后面是正的
        if (hasPot) {
          const num1 = ePrefix.split('.')[0] // 整数部分
          const num2 = ePrefix.split('.')[1] // 小树部分
          if (num2.length <= absSuffix) {
            return num1 + num2.slice(0).padEnd(absSuffix, '0')
          } else {
            return num1 + num2.slice(0, absSuffix) + '.' + num2.slice(absSuffix)
          }
        } else {
          return ePrefix.padEnd(absSuffix + ePrefix.length, '0')
        }
      }
    } else {
      return str
    }
  } catch (e) {
    return str
  }
}
