import { useEffect, useRef } from 'react'

import property from '@/websites/current/property.json'
/**
 * 各个平台的loading主题色
 */
const themeObj = {
  b3: 'light',
  p2: 'light',
  p3: 'dark',
  b1: 'dark',
  b2: 'dark',
  n1: 'dark',
  m1: 'dark',
  m5: 'dark',
}
const brand_type = property?.projectName?.toLocaleLowerCase() || ''

function Game({
  gameUrl,
  gameDom,
  isMobile,
}: {
  gameUrl: string
  isMobile: boolean
  gameDom?: string
}) {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const handleMessage = (event: any) => {
      // 检查消息来源是否是预期的 iframe 源
      if (
        event.source === iframeRef.current?.contentWindow &&
        event.data === 'ready'
      ) {
        // 在这里可以执行父级窗口的方法或逻辑
        handleIframeLoad()
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  const handleIframeLoad = () => {
    if (gameDom) {
      iframeRef.current?.contentWindow?.postMessage({ pgsoft: gameDom }, '*')
    }
  }

  return (
    <iframe
      ref={iframeRef}
      style={{
        overflow: 'hidden',
        width: '100%',
        height: isMobile ? '100%' : '67vh',
      }}
      allowFullScreen
      src={`${gameUrl}${
        gameDom ? `?theme=${themeObj?.[brand_type] || 'dark'}` : ''
      }`}
      // onLoad={handleIframeLoad}
    />
  )
}

export default Game
