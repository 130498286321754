import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import { pageConfigApi } from '@/services/jsonApi'
import { localStorage } from '@/utils/localStorage'

import type { RootState } from '../app/store'
import { getUserId } from '../user/storage'
import type {
  AnnounceContentProps,
  ModalConfigProps,
  PopularizeContentProps,
  RecommendContentProps,
  ShowType,
} from './ModalInterface'
import { getDeviceType, getTgDeviceType, isTelegramMiniApp } from '@/utils/tools'

interface StateProps {
  modal_config: ModalConfigProps[]
  modal_content: AnnounceContentProps[]
  recommend_content: RecommendContentProps[]
  popularize_content: PopularizeContentProps[]
  modalQueue: ModalConfigProps[]
  validModalQueue: ModalConfigProps[]
  inited: boolean
  showType: ShowType[]
  modalLoaded: boolean
  // 当前弹窗id
  currentModalId: string
}

interface FilterDataProps {
  modal_config: ModalConfigProps[]
  announcement_content: AnnounceContentProps[]
  recommend_content?: RecommendContentProps[]
  popularize_content?: PopularizeContentProps[]
  emitLogin?: boolean
}

/**
 * 根据次数过滤弹窗
 */
function filterDialogByCount(list: ModalConfigProps[], emitLogin?: boolean) {
  const today = dayjs().format('YYYY-MM-DD')
  const userId = getUserId() || 'unlogin'
  const key = 'modalCfgState_' + userId
  const dataStr = localStorage.getItem(key)
  let todayStorage = {} as any
  const filterList = [] as ModalConfigProps[]
  try {
    if (dataStr) {
      const data = JSON.parse(dataStr) || {}
      todayStorage = data || {}
      if (!todayStorage?.[today]) {
        localStorage.removeItem(key)
        todayStorage = {}
      }
    }
  } catch (e) {
    //
  }
  list.forEach(item => {
    // 该弹窗还剩余的展示次数
    const storageShowCount = todayStorage?.[today]?.[item.id]?.show_count || 0
    const remainCount = item.show_count - storageShowCount
    if (todayStorage?.[today]?.[item.id]?.hide !== 1 && remainCount > 0) {
      if (item.show_type === 1) {
        // 点击登陆后展示 | 次数还没展示过
        if (storageShowCount < 1 || emitLogin) {
          filterList?.push({
            ...item,
            show_count: remainCount,
          })
        }
      } else {
        // 其他两种展示规则
        filterList?.push({
          ...item,
          show_count: remainCount,
        })
      }
    }
  })
  return filterList
}

/**
 * 过滤掉停用的弹窗信息(弹窗队列)
 */
function filterDialogData(props: FilterDataProps) {
  const {
    modal_config,
    announcement_content,
    emitLogin,
    recommend_content,
    popularize_content,
  } = props

  const list = [] as ModalConfigProps[]
  modal_config?.forEach(item => {
    item = {
      ...item,
      content: [],
    }
    /** ============ 以下弹窗内容也在弹窗配置中配置 =============== */
    if (item.type === 1) {
      // 公告信息
      announcement_content?.forEach((subItem: AnnounceContentProps) => {
        if (subItem.status === 1 && item.status === 1) {
          item.content?.push(subItem)
        }
      })
    } else if (item.type === 2) {
      // 推荐信息
      recommend_content?.forEach((subItem: RecommendContentProps) => {
        if (subItem.status === 1 && item.status === 1) {
          item.content?.push(subItem)
        }
      })
    } else if (item.type === 4) {
      // TG信息
      popularize_content?.forEach((subItem: RecommendContentProps) => {
        if (subItem.status === 1 && item.status === 1) {
          item.content?.push(subItem)
        }
      })
    }
    ;(([1, 2, 4].includes(item.type) && !!item?.content?.length) ||
      ![1, 2, 4].includes(item.type)) &&
      list.push(item)
  })

  return {
    modalQueue: filterDialogByCount(list, emitLogin),
    modal_config: list,
  }
}

/**
 * 初始化有效弹窗队列
 */
function handleInitQueue(list: ModalConfigProps[], validQueue: ShowType[]) {
  const _list = [] as ModalConfigProps[]
  list?.forEach(item => {
    if (validQueue?.includes(item.show_type) && item.status === 1) {
      item = {
        ...item,
        valid: 1,
      }
      _list.push({
        ...item,
      })
    }
  })
  return [..._list]
}

const initialState: StateProps = {
  modal_config: [],
  modal_content: [],
  recommend_content: [],
  popularize_content: [],
  modalQueue: [],
  validModalQueue: [],
  inited: false,
  showType: [],
  modalLoaded: false,
  currentModalId: '',
}

const _DEVICE_TYPE = {
  apk: 'android_app',
  'android-desktop-icon': 'android_desktop',
  pc: 'pc',
  android: 'android_web',
  'ios-desktop-icon': 'ios_desktop',
  ios: 'ios_web',
  ios_app: 'ios_app',
}[getDeviceType()]

export const autoModalSlice = createSlice({
  name: 'modalCfgInfo',
  initialState,
  reducers: {
    // 设置弹窗队列
    setModalQueue: (state, action: PayloadAction<boolean>) => {
      const { modalQueue } = filterDialogData({
        modal_config: state.modal_config,
        announcement_content: state.modal_content,
        recommend_content: state.recommend_content,
        popularize_content: state.popularize_content,
        emitLogin: !!action.payload,
      })
      state.modalQueue = modalQueue
    },
    initValidModalQueue: (
      state,
      action: PayloadAction<{ list: ModalConfigProps[]; queue: ShowType[] }>,
    ) => {
      const { queue, list } = action.payload
      const validModalQueue = handleInitQueue(list, queue)
      state.validModalQueue = validModalQueue
    },
    setInited: (state, action: PayloadAction<boolean>) => {
      state.inited = action.payload
    },
    setShowType: (state, action: PayloadAction<ShowType[]>) => {
      state.showType = action.payload
    },
    shiftValidModalQueue: (
      state,
      action: PayloadAction<ModalConfigProps[]>,
    ) => {
      state.validModalQueue = action.payload || []
    },
    setCurrentModalId: (state, action: PayloadAction<string>) => {
      state.currentModalId = action.payload
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      pageConfigApi.endpoints.getModalInfo.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action.payload || {}
        const {
          modal_config_v2,
          announcement_content_v2,
          recommend_content,
          popularize_content,
        } = data

        const filtered_announcement_content_v2 = (
          announcement_content_v2 || []
        ).filter(({ display_device = [] }: { display_device: string[] }) =>
          display_device.includes(
            window?.Telegram?.WebView?.initParams?.tgWebAppData ? 'tg_bot' : _DEVICE_TYPE,
          ),
        )

        const { modalQueue, modal_config } = filterDialogData({
          modal_config: modal_config_v2,
          announcement_content: filtered_announcement_content_v2,
          recommend_content: recommend_content,
          popularize_content: popularize_content,
        })
        state.modalQueue = modalQueue
        state.modal_config = modal_config || []
        state.modal_content = filtered_announcement_content_v2 || []
        state.recommend_content = recommend_content || []
        state.popularize_content = popularize_content || []
        state.modalLoaded = true
      },
    )
  },
})

export const {
  setModalQueue,
  shiftValidModalQueue,
  initValidModalQueue,
  setInited,
  setShowType,
  setCurrentModalId,
} = autoModalSlice.actions

export const autoModalReducer = autoModalSlice.reducer
export const selectModal = (state: RootState) => state.autoModal

// 弹窗列表
export const selectModalQueue = (state: RootState) =>
  selectModal(state).modalQueue

// 弹窗列表
export const selectValidModalQueue = (state: RootState) =>
  selectModal(state).validModalQueue

// modal配置
export const selectModalConfig = (state: RootState) =>
  selectModal(state).modal_config

// showType
export const selectShowType = (state: RootState) => selectModal(state).showType

// 是否需要重置
export const selectInited = (state: RootState) => selectModal(state).inited

// 公告内容
export const selectAnnounceContent = (state: RootState) =>
  selectModal(state).modal_content

// 推荐内容
export const selectRecommendContent = (state: RootState) =>
  selectModal(state).recommend_content

// 推广内容
export const selectPopularizeContent = (state: RootState) =>
  selectModal(state).popularize_content

// 接口是否请求完毕
export const selectModalLoaded = (state: RootState) =>
  selectModal(state).modalLoaded

// 获取当前弹窗id
export const selectCurrentModalId = (state: RootState) =>
  selectModal(state).currentModalId
