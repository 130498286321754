import { getCurrencyCode } from '@/common/locale/currency'

import { handleRemovePhoneWithAreaCode } from './utils'
//基础手机号校验 https://project.feishu.cn/tringame/story/detail/14830485?tab_key=comment&tab_item_id=2242295#comment

// B系列：仅限11位数字，前2位不可包含55，清除空格
// P系列：仅限10位数字，前2位不可包含63，清除空格
// M系列：
// 墨西哥：0~15位数字，前2位不可包含52，清除空格
// 智利：0~15位数字，前2位不可包含56，首位不可为9，清除空格 clp
// 秘鲁：0~15位数字，前2位不可包含52，清除空格            pen
// 哥伦比亚：0~15位数字，前2位不可包含57，清除空格         cop
// 厄瓜多尔：仅限10位数字，前3位不可包含593，清除空格       usd

// B系列手机号输入限制
// PHONE_WRRONG1

// P系列手机号输入限制
// PHONE_WRRONG2

// 墨西哥、秘鲁手机号输入限制
// PHONE_WRRONG3

// 智利手机号输入限制
// PHONE_WRRONG4

// 哥伦比亚手机号输入限制
// PHONE_WRRONG5

// 厄瓜多尔手机号输入限制
// PHONE_WRRONG6

const PHONE_VERIFY_CONFIG: {
  [key: string]: {
    min_length: number
    max_length: number
    not_start_with_one: string
    not_start_with_two: string
    start_error_text: string
  }
} = {
  brl: {
    min_length: 11,
    max_length: 11,
    // 巴西地区存在55区号，不再做限制
    not_start_with_one: '',
    not_start_with_two: '',
    start_error_text: 'PHONE_WRRONG1',
  },
  php: {
    min_length: 10,
    max_length: 10,
    not_start_with_one: '63',
    not_start_with_two: '63',
    start_error_text: 'PHONE_WRRONG2',
  },
  mxn: {
    min_length: 10,
    max_length: 10,
    not_start_with_one: '52',
    not_start_with_two: '52',
    start_error_text: 'PHONE_WRRONG3',
  },
  cop: {
    min_length: 10,
    max_length: 10,
    not_start_with_one: '',
    not_start_with_two: '',
    start_error_text: 'PHONE_WRRONG5',
  },
  clp: {
    min_length: 0,
    max_length: 15,
    not_start_with_one: '56',
    not_start_with_two: '56',
    start_error_text: 'PHONE_WRRONG4',
  },
  pen: {
    min_length: 0,
    max_length: 15,
    not_start_with_one: '52',
    not_start_with_two: '52',

    start_error_text: 'PHONE_WRRONG3',
  },
  usd: {
    min_length: 10,
    max_length: 10,
    not_start_with_one: '593',
    not_start_with_two: '593',
    start_error_text: 'PHONE_WRRONG6',
  },
  ngn: {
    min_length: 11,
    max_length: 11,
    not_start_with_one: '',
    not_start_with_two: '',
    start_error_text: 'PHONE_WRRONG7',
  },
  kes: {
    min_length: 9,
    max_length: 9,
    not_start_with_one: '',
    not_start_with_two: '',
    start_error_text: 'PHONE_WRRONG8',
  },
}

function getConfigByCoin(coin?: string) {
  const _coin = (coin || getCurrencyCode() || 'brl').toLocaleLowerCase()
  return PHONE_VERIFY_CONFIG[_coin] || {}
}
export function verifySimpleNumber(
  value: string,
  coin?: string,
): {
  verify: boolean
  text?: string
} {
  // value = handleRemovePhoneWithAreaCode(value)
  const {
    min_length,
    max_length,
    not_start_with_one,
    not_start_with_two,
    start_error_text,
  } = getConfigByCoin(coin)
  if (
    (!!not_start_with_one && value.startsWith(not_start_with_one)) ||
    (!!not_start_with_two && value.startsWith(not_start_with_two))
  ) {
    return {
      verify: false,
      text: start_error_text,
    }
  }
  const _reg = new RegExp(`^[0-9]{${min_length},${max_length}}$`)
  // return /^[0-9]{10,10}/.test(value)
  return {
    verify: _reg.test(value),
    text: start_error_text,
  }
}
