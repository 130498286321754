import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'

import { startAppListening } from '../app/listenerMiddleware'
import type { RootState } from '../app/store'
import type { RechargeActDetail, RechargeActSlice } from './type'

export const STORAGE_KEY_RECHARGE_TYPE = 'STORAGE_KEY_RECHARGE_TYPE'

const initialState: RechargeActSlice = {
  open: false,
  tipsType: 'success',
  openTips: false,
  rechargeTyp: localStorage.getItem(STORAGE_KEY_RECHARGE_TYPE) || '',
  detailRechargeAct: {
    list: [],
    maxAmount: 0,
    minAmount: 0,
  },
  showRechargeTips: true,
  rechargeAmount: '',
  taskId: null,
}
export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    //从充值活动页面进入
    switchRecahrgeActModal: (
      state,
      action: PayloadAction<{ taskId: string | undefined } | undefined>,
    ) => {
      const { taskId } = action.payload || {}
      state.open = !state.open
      if (taskId) {
        state.taskId = taskId
      }
    },
    //设置充值渠道
    setRechargeTyp: (state, action: PayloadAction<string>) => {
      state.rechargeTyp = action.payload
    },
    setRechargeAmount: (state, action: PayloadAction<number | string>) => {
      state.rechargeAmount = action.payload
    },
    setDetailRechargeAct: (state, action: PayloadAction<RechargeActDetail>) => {
      state.detailRechargeAct = action.payload
    },
    setTipsInfo: (
      state,
      action: PayloadAction<{
        open: boolean
        type: string
      }>,
    ) => {
      const { open, type } = action.payload
      state.openTips = open
      state.tipsType = type
    },
  },
})

export const {
  switchRecahrgeActModal,
  setRechargeTyp,
  setRechargeAmount,
  setDetailRechargeAct,
  setTipsInfo,
} = currencySlice.actions

export const recahrgeActModalReducer = currencySlice.reducer

export const selectRecharge = (state: RootState) => state.rechargeModal
//充值状态
export const getTipsType = (state: RootState) => selectRecharge(state).tipsType
export const getTipsOpen = (state: RootState) => selectRecharge(state).openTips
//充值活动弹窗
export const getOpenModal = (state: RootState) => selectRecharge(state).open
//充值渠道
export const getRechargeTyp = (state: RootState) =>
  selectRecharge(state).rechargeTyp

//充值渠道
export const getRechargeAmount = (state: RootState) =>
  selectRecharge(state).rechargeAmount

// 充值活动任务 ID
export const selectTaskId = createSelector(
  selectRecharge,
  recharge => recharge.taskId || '-1',
)

// 监听绑定货币成功的 action，动态更新 i18n
startAppListening({
  actionCreator: setRechargeTyp,
  effect: action => {
    const type = action.payload
    localStorage.setItem(STORAGE_KEY_RECHARGE_TYPE, type)
  },
})
