import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../app/store'
import { openModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { selectUserIsLogin } from '../user/userSlice'
/**
 * 该钩子为了在某些情景中, 自动打开非可在后台中配置的模态框
 *
 * 目前已有的场景:
 *  - 新抽奖活动，PC端点击分享弹窗，扫描二维码后，跳转到首页，并自动打开抽奖活动模态框
 */

export function useAutoOpenModal() {
  const { pathname, search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const openModalParams = searchParams.get('openModal')
  const dispatch = useAppDispatch()
  const isUserLogin = useAppSelector(selectUserIsLogin)

  useEffect(() => {
    const modalParamsMap: Record<string, GlobalModalKey> = {
      drawActivity: GlobalModalKey.DrawActivityModal,
    }
    if (openModalParams) {
      if (!modalParamsMap[openModalParams]) return
      switch (openModalParams) {
        case 'drawActivity':
          if (!searchParams.get('activityId')) return
          if (!isUserLogin) {
            dispatch(openModal({ key: GlobalModalKey.Login }))
            return
          }
          dispatch(
            openModal({
              key: modalParamsMap[openModalParams],
              data: { taskId: searchParams.get('activityId') },
            }),
          )
          searchParams.delete('openModal')
          searchParams.delete('activityId')
          window.history.replaceState(
            null,
            '',
            `${pathname}${
              searchParams.toString().length
                ? `?${searchParams.toString()}`
                : ''
            }`,
          )
          break
        default:
          break
      }
    }
  }, [dispatch, isUserLogin, openModalParams, pathname, searchParams])
}
