import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '@/modules/app/store'

export const selectDrawActivity = (state: RootState) => state.drawActivity

export const selectScence = createSelector(
  selectDrawActivity,
  state => state.scene,
)

export const selectRewardPrize = createSelector(
  selectDrawActivity,
  state => state.rewardPrize,
)

export const selectIsPlaying = createSelector(
  selectDrawActivity,
  state => state.isPlaying,
)
