export interface RechargePackageProps {
  /**
   * 任务开始时间
   */
  begin_at?: number
  /**
   * 打码量倍数
   */
  bet_target?: number
  /**
   * 业务显示id
   */
  biz_id?: string
  /**
   * 创建时间戳
   */
  created_at?: number
  /**
   * 活动描述
   */
  desc?: string
  /**
   * 任务结束时间
   */
  end_at?: number
  /**
   * 赠送金额
   */
  gift_amount?: number
  /**
   * id
   */
  id?: number
  /**
   * 任务名称
   */
  image_url?: string
  /**
   * 可参与次数，>999为无限
   */
  join_number?: number
  /**
   * 前置条件上限
   */
  max_pre_condition?: number
  /**
   * 前置条件下限
   */
  min_pre_condition?: number
  /**
   * 任务名称
   */
  name?: string
  /**
   * 前置条件类型 0. 无限制 1. 注册时长 2. vip等级
   */
  pre_condition?: number
  /**
   * 充值金额
   */
  recharge_amount?: number
  /**
   * 排序
   */
  sort?: number
  /**
   * 任务状态 1. 开启 2. 关闭
   */
  state?: number
  /**
   * 时间限制， 0. 无限制 1. 限时
   */
  time_limit?: number
  /**
   * 最近修改人
   */
  update_user_name?: string
  /**
   * 更新时间戳
   */
  updated_at?: number
  /**
   * 用户类型，0. 无限制 1. 付费用户 2. 非付费用户
   */
  user_type?: number
}

export enum ActTipsbtnRenderType {
  ACTIVITY_CENTER_BTN = 'ActivityCenterBtn',
  ACTIVITY_CENTER_AND_ALL_GAME_BTN = 'ActivityCenterAndAllGameBtn',
  ACTIVITY_INTRODUTION_BTN = 'ActivityIntrodutionBtn',
  ACTIVITY_ALL_GAME_BTN = 'ActivityAllGameBtn',
  ACTIVITY_COPY_URL_BTN = 'ActivityCopyUrlBtn',
  ACTIVITY_LIVE_CHAT_BTN = 'ActivityLiveChatBtn',
}

export interface ActTipsProps {
  title: string
  content: string | null
  icon: string
  okButtonText?: string
  btnRender?: ActTipsbtnRenderType | null
  needCustomer?: boolean
  isBlocking?: boolean
  style?: 'fail_status' | 'success_status'
}

export interface InviteItem {
  invite: number
  price: number | string
  priceWithoutUnit?: number
}

export interface RechargeTableProps {
  requirements: string
  bonus: string
}

export const deviceMap = {
  'android-desktop-icon': 'android_desktop', // 安卓收藏
  android: 'android_web', // 安卓web端
  'ios-desktop-icon': 'ios_desktop', // ios收藏
  ios: 'ios_web', // ios web端
  pc: 'pc', // web端
  apk: 'android_app', // 安卓客户端
}

/**
 * 1: 活动未开始 & 处于有效期
 * 2: 活动未开始 & 为处于有效期 & 活动未开始
 * 3: 活动未开始 & 为处于有效期 & 活动已结束
 * 4: 活动开启 & 不可参加此活动（不符合参与条件）
 * 5: 活动开启 & 达到参与条件 & 活动平台不符合
 * 6: 活动开启 & 达到参与条件 & 活动平台符合 & 活动次数没有了但是明日还有次数
 * 7: 活动开启 & 达到参与条件 & 活动平台符合 & 活动次数没有了并且永久没有次数
 * 8: 活动开启 & 达到参与条件 & 活动平台符合 & 还有次数
 * 10: 可点击 & 打开充值活动充值弹窗
 * 11: 可点击 & 跳转链接
 */
export const ActStatus = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] as const

// 活动状态
export type ACT_STATUS = (typeof ActStatus)[number]

/**
 * 破产充值
 */
export interface BankruptModelBankruptRechargeCfgV2 {
  /**
   * 礼包
   */
  gifts?: BankruptGift[]
  /**
   * id
   */
  id: number
  /**
   * 活动元素(名称、备注、标题、副标题等)
   */
  meta?: string
}

/**
 * bankrupt.Gift
 */
export interface BankruptGift {
  /**
   * 赠送金额
   */
  gift_amount?: number
  /**
   * 充值金额
   */
  recharge_amount?: number
  /**
   * 权重
   */
  sort: number
}
