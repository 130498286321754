import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '@/modules/app/store'

import type { IRaffleConfig } from './types'
import { wheelApi } from './wheelModalApi'

export type WheelModalState = {
  wheelStatus: boolean
  wheelConfInfo?: IRaffleConfig
}

const initialState: WheelModalState = {
  wheelStatus: false,
}

export const wheelModalSlice = createSlice({
  name: 'wheelModal',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      wheelApi.endpoints.raffleConfig.matchFulfilled,
      (state, { payload }) => {
        if (payload.data) {
          state.wheelStatus = true
          state.wheelConfInfo = payload.data
        }
      },
    )
  },
})
export const selectWheelModal = (state: RootState) => state.wheelModal
export const selectWheelStatus = createSelector(
  selectWheelModal,
  (state: WheelModalState) => state.wheelStatus,
)
export const wheelModalReducer = wheelModalSlice.reducer
