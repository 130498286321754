import {
  Box,
  Checkbox,
  CloseButton,
  Modal,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { getVariable } from '@/common/env'
import LazyImage from '@/components/lazy-image/LazyImage'
import { selectModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { useGetModalInfoQuery } from '@/services/jsonApi'
import { useUserAuth } from '@/utils/hooks'

import { useAppDispatch, useAppSelector } from '../app/store'
import type {
  AnnounceContentProps,
  ModalConfigProps,
  ShowType,
} from '../auto-modal/ModalInterface'
import {
  initValidModalQueue,
  selectAnnounceContent,
  selectInited,
  selectModalConfig,
  selectModalQueue,
  selectShowType,
  setInited,
  setModalQueue,
  setShowType,
} from '../auto-modal/ModalSlice'
import { setModalStorage, useModalConfig } from '../auto-modal/useModal'
import { handleAnnounceList } from '../auto-modal/utils'
import {
  selectClickLogin,
  selectClickLogout,
  selectUser,
  setClickLogin,
} from '../user/userSlice'
import AnnounceCon from './AnnounceCon'
import AnnounceTab from './AnnounceTab'

function AnnounceModal() {
  const [curTab, changeTab] = useState(0)
  const [userId] = useUserAuth()
  const { t } = useTranslation()
  const modalConfig = useAppSelector(selectModalConfig)
  
  const { open, data, extra } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.Announce],
  )

  const announceContentList = useAppSelector(selectAnnounceContent)

  // 弹窗队列
  const { onCloseModal } = useModalConfig()

  const curModalCfg: ModalConfigProps = useMemo(() => {
    if (extra?.isManu) {
      // 用户手动触发
      const content = []
      announceContentList?.forEach(item => {
        if (item?.status === 1) {
          content.push({
            language_content: item.language_content,
            action: item.action || {},
          })
        }
      })
      return {
        content,
      }
    }
    return modalConfig.find(item => item.type === 1) as ModalConfigProps
  }, [modalConfig, announceContentList, data])

  const curContent = useMemo(() => {
    return (curModalCfg?.content?.[curTab] || {}) as AnnounceContentProps
  }, [curModalCfg, curTab])

  const handleClose = () => {
    onCloseModal(GlobalModalKey.Announce)
  }

  // 切换tab
  const onChangeTab = (id: number) => {
    changeTab(id)
  }

  const onChangeCheckbox = (checked: boolean) => {
    !!curModalCfg?.id &&
      setModalStorage(userId, curModalCfg?.id || '', checked ? 1 : 0)
  }

  return (
    <Modal isOpen={open} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent
        bg='announceDialog.bg'
        color='announceDialog.announceModalColor'
        textAlign='center'
        padding='26px 10px 0px'
        width='95vw'
        maxWidth='375px'
        display={'flex'}
        flexDirection={'column'}
        position={'relative'}
      >
        <CloseButton
          position='absolute'
          right='3'
          top='-9'
          onClick={handleClose}
          color='announceDialog.closeBtnColor'
          zIndex={3}
        />
        {/* 头图 */}
        <LazyImage
          src='/assets/images/act/announce-head.webp'
          width={'318px'}
          height={'147px'}
          margin={'auto'}
          marginTop={'-90px'}
          position={'relative'}
          zIndex={2}
        />
        {/* 公告内容 */}
        <AnnounceCon
          info={curContent as any}
          content={(curModalCfg?.content || []) as AnnounceContentProps[]}
        />
        {/* 公告tab */}
        {Number(curModalCfg?.content?.length || 0) > 1 && (
          <AnnounceTab
            datasource={handleAnnounceList(
              (curModalCfg?.content || []) as AnnounceContentProps[],
            )}
            onChange={id => {
              onChangeTab(id)
            }}
          />
        )}
        {curModalCfg?.can_hide === 1 && (
          <Box
            width={'100%'}
            position={'absolute'}
            bottom={'0px'}
            height='50px'
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Checkbox
              variant='announceModal'
              onChange={e => onChangeCheckbox(e.target.checked)}
            >
              {t('NOT_REMIND')}
            </Checkbox>
          </Box>
        )}
      </ModalContent>
    </Modal>
  )
}

let flag = false

export default function WrapAnnounceModal() {
  const isLoggedIn = useAppSelector(state => selectUser(state).isLoggedIn)
  const location = useLocation()
  const { onStartModal } = useModalConfig()
  const modalQueue = useAppSelector(selectModalQueue)
  // 展示哪种场景下的弹窗
  const ShowTypeQueue = useAppSelector(selectShowType)
  const inited = useAppSelector(selectInited)
  const clickLogin = useAppSelector(selectClickLogin)
  const clickLogout = useAppSelector(selectClickLogout)
  const dispatch = useAppDispatch()
  const { open } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.Announce],
  )

  const MODAL_RESOURCE_SIGN = !!getVariable('MODAL_RESOURCE_SIGN')

  useGetModalInfoQuery(null, { skip: !MODAL_RESOURCE_SIGN })

  useEffect(() => {
    // 点击了登录按钮来弹窗
    if (clickLogin) {
      dispatch(setModalQueue(true))
      dispatch(setShowType([1]))
      dispatch(setClickLogin(false))
      flag = true
      setTimeout(() => {
        flag = false
      }, 1000)
    }
  }, [clickLogin, dispatch])

  useEffect(() => {
    if (inited) {
      onStartModal()
    }
  }, [inited])

  useEffect(() => {
    if (ShowTypeQueue.length > 0 && modalQueue.length > 0) {
      dispatch(setInited(true))
      dispatch(
        initValidModalQueue({
          list: modalQueue,
          queue: ShowTypeQueue,
        }),
      )
    }
  }, [ShowTypeQueue, modalQueue])

  useEffect(() => {
    // 点击登录的时候不会走到这里
    const queue = [] as ShowType[]
    if (!clickLogin && !flag && !clickLogout) {
      queue.push(location?.pathname === '/' ? 2 : 3)
      isLoggedIn && queue.push(1)
      dispatch(setShowType(queue))
    }
  }, [location?.pathname, isLoggedIn, clickLogin, clickLogout])
  if (!open) return null
  return <AnnounceModal />
}
