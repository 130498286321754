import { getSign } from '@/common/locale/currency'
import i18n from '@/common/locale/i18n'
import { formatLocalMoney, getConfigLng } from '@/utils/tools'

import type {
  DrawpbDrawInfoRsp,
  DrawpbDrawPrize,
  DrawTaskOneTime,
  DrawTaskWithProgress,
  PrizeProps,
} from './types'
import { PrizeType } from './types'

function createDrawTaskWithProgress(
  enable: boolean | undefined,
  condition: { reward_nums?: number } | null | undefined,
  current = 0,
  target = 0,
): DrawTaskWithProgress | null {
  if (!enable || !condition) return null
  return {
    progress: { current, target },
    drawTimes: condition.reward_nums ?? 0,
  }
}

function createDrawTaskOneTime(
  enable: boolean | undefined,
  condition: { reward_nums?: number } | null | undefined,
  isDone = false,
): DrawTaskOneTime | null {
  if (!enable || !condition) return null
  return {
    isDone,
    drawTimes: condition.reward_nums ?? 0,
  }
}

export function prizeTextFormatter(
  prize: Partial<
    Pick<DrawpbDrawPrize, 'reward_num' | 'reward_type' | 'reward_unit'>
  >,
) {
  const {
    reward_type: type = PrizeType.Currency,
    reward_num: num = 0,
    reward_unit: unit = '',
  } = prize
  if (type === PrizeType.Currency) {
    const sign = getSign()
    return formatLocalMoney(num, sign)
  } else if (type === PrizeType.VirtualCoin) {
    return `${num} ${unit}`
  } else if (type === PrizeType.Custom) {
    return unit
  } else {
    return ''
  }
}

export function transPrizeFromBackend(prize: DrawpbDrawPrize): PrizeProps {
  const {
    prize_id: id,
    reward_image: image = '',
    reward_type: type,
    reward_num: num = 0,
    reward_unit: unit = '',
  } = prize

  return {
    id,
    image,
    num,
    unit,
    text: prizeTextFormatter(prize),
    type,
  }
}

export function transDrawpbDrawInfoRsp({ info, task }: DrawpbDrawInfoRsp) {
  const lng = getConfigLng()
  return {
    taskId: task.task_id,
    drawStyle: task.draw_style,
    name: task.config?.name?.[lng] ?? '',
    rule: task.config?.desc?.[lng] ?? '',
    buttonDisabledText: task.config?.button_text?.[lng] ?? '',
    image: task.image_url ?? '',
    prizes: task.prize_list?.map(transPrizeFromBackend) ?? [],
    canPlay: info.play_status === 1,
    drawTimes: info.draw_ticket_nums,
    updatedAt: task.updated_at ?? 0,
    task: {
      bet: createDrawTaskWithProgress(
        task?.config.is_bet,
        task?.config?.bet_condition,
        info?.bet_progress,
        task.config?.bet_condition?.bet_amount,
      ),
      invite: createDrawTaskWithProgress(
        task?.config.is_invite,
        task?.config?.invite_condition,
        info?.invite_progress,
        task.config?.invite_condition?.invite_limit,
      ),
      deposit: createDrawTaskWithProgress(
        task?.config.is_deposit,
        task.config?.deposit_condition,
        info?.deposit_progress,
        task.config?.deposit_condition?.deposit_amount,
      ),
      checkIn: createDrawTaskOneTime(
        task?.config.is_sign_in,
        task?.config?.sign_in_condition,
        info?.is_claim_today,
      ),
    },
    begin: task.config?.begin || 0,
    end: task.config?.end || 0,
  }
}
