import type { SystemStyleObject } from '@chakra-ui/react'
import { Box, Flex, HStack, Text, useRadioGroup } from '@chakra-ui/react'
import type { FC } from 'react'

import AtomSelect from '@/components/atom-select/AtomSelect'
import { useCustomizedStyle } from '@/utils/hooks'

import MaskLayer from '../mask-layer/MaskLayer'
import MinesRadioCard from './MinesRadioCard'

export const PARTS = ['radio', 'selector'] as const
export const NAME = 'MinesSelector'

const radio: SystemStyleObject = {
  width: '46px',
  height: '50px',
  lineHeight: '50px',
  textAlign: 'center',
  cursor: 'pointer',
  borderWidth: '1px',
  borderRadius: '10px',
  bgColor: '#fff',

  _checked: {
    bg: '#235cf1',
    color: 'white',
  },
  _hover: {
    bg: '#235cf1',
    color: 'white',
  },
}

/** MinesSelector */

export type MinesSelectorOption = {
  label: string
  value: string
}
export type MinesSelectorOptions = MinesSelectorOption[]
export type MinesSelectorProps = {
  value?: string
  options?: MinesSelectorOption[]
  quickOptions?: number[]
  disabled?: boolean
  onChange?: (value: string) => void
}

export const MinesSelector: FC<MinesSelectorProps> = ({
  value,
  options = [],
  quickOptions = [],
  disabled,
  onChange,
}) => {
  const { radio: radioStyle } = useCustomizedStyle(NAME, { radio }, PARTS)
  const { getRootProps, getRadioProps } = useRadioGroup({
    value,
    name: 'MinesSelector',
    onChange,
  })

  const groupProps = getRootProps()
  const quicks = quickOptions.map(index => options[index])

  return (
    <Flex columnGap='10px' position='relative'>
      {disabled && <MaskLayer />}
      <HStack {...groupProps} display={{ base: 'none', md: 'flex' }}>
        {quicks.map(({ value }) => {
          const radio = getRadioProps({ value })
          return (
            <MinesRadioCard key={value} {...radio} sx={radioStyle}>
              {value}
            </MinesRadioCard>
          )
        })}
      </HStack>

      <Box flex='1'>
        <AtomSelect
          value={options.find(opt => opt.value === value)}
          size='2xl'
          options={options}
          onChange={opt =>
            onChange && onChange((opt as MinesSelectorOption).value)
          }
          valuePrefix={
            <Text mr='2' textStyle='h6'>
              {'MINES:'}
            </Text>
          }
        />
      </Box>
    </Flex>
  )
}

export default MinesSelector
