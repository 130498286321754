import dayjs from 'dayjs'

export interface GameFavoriteTipsDumpy {
  gameId: string
  times: number
  lastShowTimestamp: number
  lastCollectionTimestamp: number
}

// 将TipsRecord 改写成一个类
export default class GameFavoriteTips {
  static MAX_SHOW_TIMES = 3

  gameId: string
  times: number
  lastShowTimestamp: number
  lastCollectionTimestamp: number

  constructor(gameId: string) {
    this.gameId = gameId
    this.times = 0
    this.lastShowTimestamp = 0
    this.lastCollectionTimestamp = 0
  }

  get hasFavoritedToday() {
    return dayjs().isSame(dayjs(this.lastCollectionTimestamp), 'day')
  }

  get hasShowedToday() {
    return dayjs().isSame(dayjs(this.lastShowTimestamp), 'day')
  }

  get shouldShow() {
    if (
      this.hasFavoritedToday ||
      (this.hasShowedToday && this.times >= GameFavoriteTips.MAX_SHOW_TIMES)
    ) {
      return false
    }

    return true
  }

  afterShow() {
    const time = Date.now()
    if (dayjs().isSame(dayjs(this.lastShowTimestamp), 'day')) {
      this.times += 1
    } else {
      this.times = 1
    }
    this.lastShowTimestamp = time
  }

  afterCollection() {
    const time = Date.now()
    this.lastCollectionTimestamp = time
  }

  dump() {
    return {
      gameId: this.gameId,
      times: this.times,
      lastShowTimestamp: this.lastShowTimestamp,
      lastCollectionTimestamp: this.lastCollectionTimestamp,
    }
  }

  static load(data: GameFavoriteTipsDumpy) {
    const tips = new GameFavoriteTips(data.gameId)
    tips.times = data.times
    tips.lastShowTimestamp = data.lastShowTimestamp
    tips.lastCollectionTimestamp = data.lastCollectionTimestamp
    return tips
  }
}
