export function getLimitedResult(
  value: number,
  max?: number,
  min?: number,
): number {
  let result: number = value

  if (max !== undefined) {
    result = result > max ? max : result
  }

  if (min !== undefined) {
    result = result < min ? min : result
  }

  if (max !== undefined && min !== undefined && max < min) {
    console.warn(`max should be greater than min: max ${max}, min ${min}`)
    return value
  }

  return result
}

export default getLimitedResult
