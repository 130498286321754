import { createSelector } from '@reduxjs/toolkit'

import type { RootState } from '@/modules/app/store'

const selectSelf = (state: RootState) => state
export const selectIsChristmasDressUpEnable = createSelector(
  selectSelf,
  featSwitch =>
    featSwitch.appLayout.featSwitch['christmas_dress_up']?.enable === 1,
)
