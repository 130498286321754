import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../app/store'
import { gameApi } from './api'
import type {
  CategoryGameListProps,
  CategoryLabelListItem,
  CategoryLabelMap,
  GameListItem,
  ManulacturerListItem,
} from './gameInterface'

interface GameSlice {
  showTips: boolean
  tipsText: string
  // 启用 && 在首页展示
  manus: ManulacturerListItem[]
  // 启用& & 不再维护中
  enableManus: ManulacturerListItem[]
  allManus: ManulacturerListItem[]
  games: GameListItem[]
  GMPGames: GameListItem[]
  selfGames: GameListItem[]
  catesKeyByLabelId: Record<string | number, CategoryLabelListItem>
  catesKeybyLabelType: Record<string | number, CategoryLabelListItem[]>
  manusKeybyId: Record<string | number, ManulacturerListItem>
}

interface NewGameSlice {
  // 所有的游戏列表
  homeAllGameList: (CategoryGameListProps & CategoryLabelListItem)[]
  activeTab: null | number
  // 所有的厂商列表
  allProviderList: ManulacturerListItem[]
  // 所有的推荐标签列表
  allRecomLabelList: CategoryLabelListItem[]
}

/**
 * 几种路由跳转方式
 */
export const labelType: CategoryLabelMap = {
  jili: {
    baseUrl: '/game/jili',
    type: 'jili',
    supportCurrency: ['ALL'],
  },
  facai: {
    baseUrl: '/game/facai',
    type: 'facai',
    supportCurrency: ['ALL'],
  },
  slotegrator: {
    baseUrl: '/game/slot',
    type: 'slot',
    supportCurrency: ['ALL'],
  },
  evoplay: {
    baseUrl: '/game/slot',
    type: 'evoplay',
    supportCurrency: ['ALL'],
  },
  hillingo: {
    baseUrl: '/game/self',
    type: 'inhouse',
    supportCurrency: ['ALL'],
  },

  inhouse: {
    baseUrl: '/game/inhouse',
    type: 'inhouse',
    supportCurrency: ['ALL'],
  },
  live_casino: {
    baseUrl: '/game/live',
    type: 'live',
    supportCurrency: ['ALL'],
  },
  pgsoft: {
    baseUrl: '/game/pgsoft',
    type: 'pgsoft',
    supportCurrency: ['ALL'],
  },
  fundist: {
    baseUrl: '/game/fundist',
    type: 'fundist',
    supportCurrency: ['ALL'],
  },
}

export const FAVORITE_LABEL_ID = 999

const newInitialState: NewGameSlice = {
  // 首页所有的游戏列表
  homeAllGameList: [],
  // 当前选中的tabID
  activeTab: null,
  // 开启的所有厂商列表
  allProviderList: [],
  // 推荐列表下的分组游戏
  allRecomLabelList: [],
}
export const gameNewSlice = createSlice({
  name: 'gameApiList',
  initialState: newInitialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      gameApi.endpoints.getHomeGameList.matchFulfilled,
      (state, action: PayloadAction<any>) => {
        const { payload } = action
        const allGameList = payload?.data?.category_game_list || []
        state.homeAllGameList = allGameList?.map(
          (item: CategoryLabelListItem) => {
            return {
              ...item,
              data: item?.game_list || [],
            }
          },
        )
        // provider列表
        const providerObj = allGameList.find(
          (item: CategoryLabelListItem) => item?.label_id === 8,
        )
        const allProviderList = (providerObj?.provider_list || [])?.map(
          (item: ManulacturerListItem) => {
            return {
              ...item,
              data: item?.game_list,
            }
          },
        )
        state.allProviderList = allProviderList
        // 推荐列表
        const recomObj = allGameList.find(
          (item: CategoryLabelListItem) => item?.label_id === 6,
        )
        const allRecomLabelList = (recomObj?.category_list || [])?.map(
          (item: CategoryLabelListItem) => {
            return {
              ...item,
              name: item?.label_name,
              data: item?.game_list,
            }
          },
        )
        state.allRecomLabelList = allRecomLabelList
      },
    )
  },
})

export const gameNewReducer = gameNewSlice.reducer
export const selectGame = (state: RootState) => state.gameNewModal

// *********** API 方式获取首页游戏列表 **************
export const selectHomeAllGameList = createSelector(
  selectGame,
  gamesState => gamesState.homeAllGameList,
)

export const selectHomeAllManuList = createSelector(
  selectGame,
  gamesState => gamesState.allProviderList,
)

export const selectHomeAllRecomList = createSelector(
  selectGame,
  gamesState => gamesState.allRecomLabelList,
)
