import type { PayloadAction } from '@reduxjs/toolkit'
import {
  createDraftSafeSelector,
  createSelector,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import { startAppListening } from '../app/listenerMiddleware'
import type { RootState } from '../app/store'
import { globalModalSlice } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import type { RechargeActListProps } from './ActInterface'
import { activityApi } from './activityApi'
import { handleRechargeActData } from './Utils'

export const selectWallet = (state: RootState) => state.wallet

interface RechargeActProps {
  // 活动列表
  rechargeActList: RechargeActListProps[]
  // 是否可以参加活动
  actQualification: boolean
  rechargeActId: string
  // 充值礼包接口请求状态
  rechargePackageLoaded: boolean
  /** 破产引导V3跳转url */
  bankruptCallbackUrl?: string
  /** 破产引导V3触发金额 */
  bankruptTrigger?: {
    gmp: number
    in_house: number
    third: number
  }
}

const initialState: RechargeActProps = {
  rechargeActList: [],
  actQualification: false,
  rechargeActId: '',
  rechargePackageLoaded: false,
}
export const actSlice = createSlice({
  name: 'act',
  initialState,
  reducers: {
    setRechargeActId: (state, action: PayloadAction<string | number>) => {
      state.rechargeActId = String(action.payload || '')
    },
    setRechargePackageLoaded: (state, action: PayloadAction<boolean>) => {
      state.rechargePackageLoaded = action.payload
    },
    setBankruptCallbackUrl: (state, action: PayloadAction<string>) => {
      state.bankruptCallbackUrl = action.payload
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      activityApi.endpoints.getRechargeActList.matchFulfilled,
      (state, action) => {
        const { payload } = action
        const { data } = payload
        state.rechargeActList = handleRechargeActData(data) || []
      },
    ),
      builder.addMatcher(
        activityApi.endpoints.getBankruptTrigger.matchFulfilled,
        (state, action) => {
          const { payload } = action
          const { data } = payload
          state.bankruptTrigger = data
        },
      )
  },
})

export const {
  setRechargeActId,
  setRechargePackageLoaded,
  setBankruptCallbackUrl,
} = actSlice.actions

export const actReducer = actSlice.reducer

export const SelectActCfg = (state: RootState) => state.act

const selectSelf = (state: RootState) => state
export const selectActCfg = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => state.act,
)

/**
 * 获取破产引导V3跳转链接
 */
export const selectBankruptCallbackUrl = createSelector(
  selectActCfg,
  actCfg => actCfg.bankruptCallbackUrl,
)

/**
 * 获取破产引导触发金额
 */
export const selectBankruptTrigger = createSelector(
  selectActCfg,
  actCfg => actCfg.bankruptTrigger,
)

/**
 * 充值礼包接口请求完毕
 */
export const selectRechargePackageLoaded = createSelector(
  selectActCfg,
  actCfg => actCfg.rechargePackageLoaded,
)

// 获取充值活动列表
export const selectRechargeActList = (state: RootState) =>
  SelectActCfg(state).rechargeActList

// 选择的是哪个充值活动
export const selectRechargeActId = (state: RootState) =>
  SelectActCfg(state).rechargeActId

startAppListening({
  matcher: isAnyOf(
    activityApi.endpoints.rechargePackageGiftList.matchFulfilled,
  ),
  effect: (action, store) => {
    const rechargePackageInfo = action?.payload?.data?.[0]
    store.dispatch(actSlice.actions.setRechargePackageLoaded(true))
    if (
      rechargePackageInfo &&
      (!rechargePackageInfo.end_at ||
        (rechargePackageInfo.end_at &&
          rechargePackageInfo.end_at * 1000 > dayjs().valueOf()))
    ) {
      store.dispatch(
        globalModalSlice.actions.setModalData({
          key: GlobalModalKey.RechargePackage,
          data: { rechargePackageInfo, isAuto: true },
        }),
      )
    }
  },
})
