import { api } from '@/services/api'
import type { Response } from '@/services/types'

export const NewLoginAndSignUpApi = api.injectEndpoints({
  endpoints: builder => ({
    getLoginAndSignUpConfig: builder.query<Response<any>, void>({
      query: params => {
        return {
          url: `/api/v1/platform/asset_order/auth_config/get_auth_config`,
          method: 'GET',
          params,
        }
      },
    }),
  }),
})

export const { useGetLoginAndSignUpConfigQuery } = NewLoginAndSignUpApi
