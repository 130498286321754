import {
  Box,
  Button,
  CloseButton,
  Icon,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import type { TDate } from 'ahooks/lib/useCountDown'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AtomCountDown, { useAtomCountDown } from '@/components/atom-count-down'
import { useModalConfig } from '@/modules/auto-modal/useModal'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { downloadApp } from '@/utils/download-app'
import { useUserInfo } from '@/utils/hooks'
import { localStorage } from '@/utils/localStorage'
import { formatLocalMoney, getDeviceType } from '@/utils/tools'

import { useAppDispatch, useAppSelector } from '../app/store'
import { selectActionSystemConfig } from '../app-layout/app-layout-slice'
import {
  closeModal,
  openModal,
  selectModal,
  setModalData,
} from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import {
  selectUserIsLogin,
  toggleBalanceFloat,
  updateBanlance,
} from '../user/userSlice'
import { useClaimFirstLoginRewardMutation } from './guideEventApi'
import { selectAppLoginRewardLoaded } from './guideEventSlice'

const DEVICE = getDeviceType()

const DEFAULT_TARGET_DATEE = dayjs().add(30, 'minutes').format()

function FirstLoginRewardCountDown({ endTime }: { endTime?: TDate }) {
  const { t } = useTranslation()
  const [firstLoginRewardTargetDate, setFirstLoginRewardTargetDate] = useState(
    endTime ||
      localStorage.getItem('firstLoginRewardTargetDate') ||
      DEFAULT_TARGET_DATEE,
  )

  const { days } = useAtomCountDown({
    targetDate: firstLoginRewardTargetDate,
  })

  useEffect(() => {
    localStorage.setItem(
      'firstLoginRewardTargetDate',
      `${firstLoginRewardTargetDate}`,
    )
  }, [])

  const onCountDownEnd = () => {
    if (!endTime) {
      setFirstLoginRewardTargetDate(dayjs().add(30, 'minutes').format())
      localStorage.setItem(
        'firstLoginRewardTargetDate',
        `${DEFAULT_TARGET_DATEE}`,
      )
    }
  }

  return (
    <Box>
      <Text as='p' color='#fff' fontSize='14px'>
        {t(Number(days) ? 'AVAILABLE_IN2' : 'AVAILABLE_IN', { day: days })}
      </Text>
      <AtomCountDown
        onEnd={onCountDownEnd}
        targetDate={firstLoginRewardTargetDate}
      />
    </Box>
  )
}
// 是否来自安卓APK，ios_app， IOS桌面端
const isAppOrDesktop = ['ios_app', 'ios-desktop-icon', 'apk'].includes(DEVICE)

export default function IntroductionToDownloadModal() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { onCloseModal } = useModalConfig()
  const toast = useToast()
  const { userInfo } = useUserInfo()
  const banlance: number = userInfo.banlance?.amount as number
  const loaded = useAppSelector(selectAppLoginRewardLoaded)
  const isLogin = useAppSelector(selectUserIsLogin)

  const { open, data } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.IntroductionToDownloadModal],
  )

  const [claimFirstLoginReward] = useClaimFirstLoginRewardMutation()

  /** app包配置 */
  const actionSystemConfig = useAppSelector(selectActionSystemConfig)

  /**
   * IOS配置
   */
  const iosConfig = useMemo(() => {
    return actionSystemConfig?.IOS || undefined
  }, [actionSystemConfig])

  const firstLoginRewardInfo = useMemo(() => {
    if (data?.firstLoginRewardInfo) {
      return {
        ...data.firstLoginRewardInfo,
      }
    }
    return undefined
  }, [data])

  const modalCfg = useMemo(() => {
    const isIosConfig =
      ['ios-desktop-icon', 'ios', 'ios_app'].includes(DEVICE) &&
      !iosConfig?.app_url
    if (data && data?.modalCfg) {
      return {
        title: data.modalCfg.title,
        content: data.modalCfg.content,
        reward: data.modalCfg.reward,
        btnText: isIosConfig
          ? 'MOBILE_IOS_COLLECT_TEXT'
          : 'MOBILE_AND_COLLECT_NEWTEXT',
      }
    }

    if (firstLoginRewardInfo) {
      if (!firstLoginRewardInfo.biz_id || !isAppOrDesktop) {
        return {
          title: isIosConfig ? 'LOGIN_REWARD4' : 'LOGIN_REWARD1',
          content: isIosConfig ? 'LOGIN_REWARD5' : 'LOGIN_REWARD2',
          reward: formatLocalMoney(firstLoginRewardInfo.reward),
          btnText: isIosConfig
            ? 'MOBILE_IOS_COLLECT_TEXT' // 添加到主屏幕
            : 'MOBILE_AND_COLLECT_NEWTEXT', // 下载APP
        }
      } else {
        const isCanClaim = !(
          firstLoginRewardInfo.is_require_recharge &&
          !firstLoginRewardInfo.user_is_recharge
        )
        return {
          title: 'LOGIN_REWARD1',
          content: !isCanClaim ? 'LOGIN_REWARD7' : 'LOGIN_REWARD8',
          reward: formatLocalMoney(firstLoginRewardInfo.reward),
          btnText: !isCanClaim ? 'NAV_DEPOSIT' : 'LOGIN_REWARD3',
        }
      }
    }
    return {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, firstLoginRewardInfo])

  const handleClose = () => {
    dispatch(closeModal({ key: GlobalModalKey.IntroductionToDownloadModal }))
    onCloseModal(GlobalModalKey.IntroductionToDownloadModal)
  }

  /** 领取首登奖励 */
  const handleClaimFirstLoginReward = async (biz_id: string) => {
    try {
      await claimFirstLoginReward({ biz_id }).unwrap()
      dispatch(
        toggleBalanceFloat({
          balance: firstLoginRewardInfo?.reward || 0,
        }),
      )
      updateBanlance(Number((firstLoginRewardInfo?.reward || 0) + banlance))
      dispatch(
        openModal({
          key: GlobalModalKey.RewardSuccess,
          data: firstLoginRewardInfo?.reward || 0,
        }),
      )
      dispatch(closeModal({ key: GlobalModalKey.IntroductionToDownloadModal }))
      onCloseModal(GlobalModalKey.IntroductionToDownloadModal, true)
      dispatch(
        setModalData({
          key: GlobalModalKey.IntroductionToDownloadModal,
          data: {},
        }),
      )
    } catch (error) {
      toast({
        title: t('FAILED_COLLECT'),
        status: 'error',
      })
    }
  }

  /** 点击按钮 */
  const handleClick = () => {
    if (
      isAppOrDesktop &&
      firstLoginRewardInfo?.is_require_recharge &&
      !firstLoginRewardInfo?.user_is_recharge
    ) {
      dispatch(openModal({ key: GlobalModalKey.Wallet }))
      dispatch(closeModal({ key: GlobalModalKey.IntroductionToDownloadModal }))
      onCloseModal(GlobalModalKey.IntroductionToDownloadModal, true)
      return
    }

    if (isAppOrDesktop && firstLoginRewardInfo?.biz_id) {
      /**
       * 执行领取首登奖励
       */
      handleClaimFirstLoginReward(firstLoginRewardInfo.biz_id)
      return
    }
    downloadApp()
  }

  const showQrCode = useMemo(() => {
    return !(
      firstLoginRewardInfo &&
      ['apk', 'ios-desktop-icon'].includes(DEVICE) &&
      firstLoginRewardInfo.biz_id
    )
  }, [firstLoginRewardInfo])

  useEffect(() => {
    if (!!loaded && open && isLogin) {
      const deviceNoConform =
        (DEVICE === 'ios_app' &&
          !data?.firstLoginRewardInfo?.open_platform?.includes('ios_app')) ||
        (DEVICE === 'ios-desktop-icon' &&
          !data?.firstLoginRewardInfo?.open_platform?.includes('ios_desktop'))

      if (
        (!data?.modalCfg && !data?.firstLoginRewardInfo) ||
        !!deviceNoConform
      ) {
        handleClose()
      }
    }
  }, [open, data, loaded, isLogin])

  return (
    <Modal
      autoFocus={false}
      trapFocus={false}
      isOpen={open && !!(data?.modalCfg || data?.firstLoginRewardInfo)}
      onClose={handleClose}
      isCentered
      size={'md'}
    >
      <ModalOverlay />
      <ModalContent
        bg='actDialog.bg'
        color='announceDialog.announceModalColor'
        textAlign='center'
        width='100vw'
        maxWidth='375px'
        minH='470px'
        display={'flex'}
        flexDirection={'column'}
        position={'relative'}
        // paddingBottom={'20px'}
        sx={{ boxShadow: 'none' }}
        px='10px'
      >
        <CloseButton
          as={SharedIcon.Close}
          position='absolute'
          right='3'
          top='3'
          zIndex={10}
          width='24px'
          onClick={handleClose}
          cursor='pointer'
          color='announceDialog.closeBtnColor'
        />
        {firstLoginRewardInfo && (
          <FirstLoginRewardCountDown endTime={firstLoginRewardInfo.endTime} />
        )}
        <Image
          src='/assets/images/about-icon/introduction-to-download-bg.png'
          alt=''
          borderRadius='2xl'
          loading='lazy'
          width='100%'
        />

        <Box
          top={`calc(180px + ${firstLoginRewardInfo ? 75 : 0}px)`}
          px={'20px'}
          w='calc(100% - 20px)'
          zIndex={1}
          position='absolute'
        >
          <Text
            fontWeight='900'
            fontSize='20px'
            lineHeight='32px'
            background='rechargeActBlock.numBg'
            backgroundClip='text'
            noOfLines={2}
            height='auto'
            fontStyle='italic'
            dangerouslySetInnerHTML={{
              __html: t(modalCfg?.title || '') || '',
            }}
          />

          <Text
            my={'10px'}
            lineHeight='40px'
            color='four.500'
            fontSize='30'
            fontWeight='800'
            height='40px'
          >
            {t(modalCfg?.reward || '')}
          </Text>
          <Text
            fontSize={14}
            lineHeight='18px'
            color={'#fff'}
            // noOfLines={2}
            height='auto'
            padding='0px 25px'
            dangerouslySetInnerHTML={{
              __html: t(modalCfg?.content || '') || '',
            }}
          />
          {firstLoginRewardInfo?.is_require_recharge && !isAppOrDesktop && (
            <Text fontSize={12} color={'rgba(255, 0,0)'} mt='5px'>
              {t('LOGIN_REWARD6')}
            </Text>
          )}
        </Box>
        <Button
          bg='four.500'
          _hover={{
            bg: 'four.600',
            color: '#000',
          }}
          mt={'12px'}
          color='#000'
          size='lg'
          fontWeight={800}
          fontSize={18}
          fontFamily='Montserrat-Black, Montserrat'
          onClick={handleClick}
          position={'absolute'}
          left='0'
          right='0'
          margin='auto'
          bottom='20px'
          w='calc(100% - 80px)'
          zIndex={10}
        >
          {firstLoginRewardInfo && isAppOrDesktop ? (
            <>{t(modalCfg.btnText || '')}</>
          ) : (
            <>
              {DEVICE !== 'ios' && (
                <Icon as={SharedIcon.Down} boxSize={6} mr={2} />
              )}
              {t(modalCfg.btnText || '')}
            </>
          )}
        </Button>
      </ModalContent>
    </Modal>
  )
}
