/**
 *
 * @param start
 * @param end
 * @returns millisecond, -1 means throw error
 */
export function getCostByMark(start: string, end: string): number {
  try {
    performance.mark(end)
    const name = `${start} - ${end}`
    performance.measure(name, start, end)
    const cost = performance.getEntriesByName(name)[0].duration
    performance.clearMeasures()

    return cost
  } catch (e) {
    return -1
  }
}
