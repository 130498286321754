function AddIcon({ color }: { color?: string }) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_4203_110444)'>
        <path
          d='M19.1529 0H4.84714C3.56195 0.00113483 2.32972 0.512181 1.42095 1.42095C0.512181 2.32972 0.00113483 3.56197 0 4.84717V19.1528C0.00113483 20.438 0.512181 21.6703 1.42095 22.5791C2.32972 23.4878 3.56195 23.9989 4.84714 24H19.1529C20.4381 23.9989 21.6703 23.4878 22.5791 22.5791C23.4878 21.6703 23.9989 20.438 24 19.1528V4.84717C23.9989 3.56197 23.4878 2.32972 22.5791 1.42095C21.6703 0.512181 20.4381 0.00113483 19.1529 0ZM22.2857 19.1528C22.2846 19.9834 21.9541 20.7796 21.3669 21.3669C20.7796 21.9542 19.9834 22.2846 19.1529 22.2857H4.84714C4.0166 22.2846 3.22041 21.9542 2.63314 21.3669C2.04586 20.7796 1.71542 19.9834 1.71429 19.1528V4.84717C1.71542 4.01663 2.04586 3.22039 2.63314 2.63311C3.22041 2.04583 4.0166 1.71542 4.84714 1.71429H19.1529C19.9834 1.71542 20.7796 2.04583 21.3669 2.63311C21.9541 3.22039 22.2846 4.01663 22.2857 4.84717V19.1528Z'
          fill={color ?? '#3F4F70'}
        />
        <path
          d='M17.1429 11.1429H12.8571V6.85714C12.8571 6.62981 12.7668 6.41181 12.6061 6.25106C12.4453 6.09032 12.2273 6 12 6C11.7727 6 11.5547 6.09032 11.3939 6.25106C11.2332 6.41181 11.1429 6.62981 11.1429 6.85714V11.1429H6.85714C6.62981 11.1429 6.41181 11.2332 6.25106 11.3939C6.09032 11.5547 6 11.7727 6 12C6 12.2273 6.09032 12.4453 6.25106 12.6061C6.41181 12.7668 6.62981 12.8571 6.85714 12.8571H11.1429V17.1429C11.1429 17.3702 11.2332 17.5882 11.3939 17.7489C11.5547 17.9097 11.7727 18 12 18C12.2273 18 12.4453 17.9097 12.6061 17.7489C12.7668 17.5882 12.8571 17.3702 12.8571 17.1429V12.8571H17.1429C17.3702 12.8571 17.5882 12.7668 17.7489 12.6061C17.9097 12.4453 18 12.2273 18 12C18 11.7727 17.9097 11.5547 17.7489 11.3939C17.5882 11.2332 17.3702 11.1429 17.1429 11.1429Z'
          fill={color ?? '#3F4F70'}
        />
      </g>
      <defs>
        <clipPath id='clip0_4203_110444'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AddIcon
