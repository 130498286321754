import {
  Box,
  Button,
  Flex,
  Image,
  keyframes,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ScaleFade,
  Spinner,
  Text,
} from '@chakra-ui/react'
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import giftPackagePng from '@/assets/images/lucky-wheel/gift-package.png'
import lhsdWebp from '@/assets/images/lucky-wheel/lhsd.webp'
import lightCircle from '@/assets/images/lucky-wheel/light-circle.png'
import spinLight from '@/assets/images/lucky-wheel/spin-light.webp'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { useModalConfig } from '@/modules/auto-modal/useModal'
import {
  closeModal,
  openModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import LoginModalTab from '@/modules/login/LoginModalTab'
import { getUserId } from '@/modules/user/storage'
import {
  selectUserInfoLoaded,
  selectUserIsLogin,
} from '@/modules/user/userSlice'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useLargerThanMobile, useUserAuth } from '@/utils/hooks'

import BonusModal from './BonusModal'
import NoResultModal from './components/NoResultModal'
import SuccessWithdrawModal from './components/SuccessWithdrawModal'
import luckyWheelEvents from './events'
import useWheelModalShow from './hooks/useWheelModalShow'
import { luckyWheelApi, useDrawMutation } from './luckyWheelApi'
import {
  getCurrentUserRewardInfo,
  selectWheelInfoLoaded,
} from './luckyWheelSlice'
import PromptModal from './PromptModal'
import RechargeAndInviteModal from './RechargeAndInviteModal'

const LuckyWheel = React.lazy(() => import('./LuckyWheel'))

const randomThreeDigitNumber = Math.floor(Math.random() * 900) + 100

// 呼吸动画
const breathAnimation = keyframes`
    from {
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    transform: translate(-50%, -50%) scale(.95);
  }

  to {
    transform: translate(-50%, -50%) scale(1);
  }
`

// 绕中心点旋转
const rotateAnimation = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`

function GuideContent({
  handleClose,
  isOpen,
}: {
  handleClose: () => void
  isOpen: boolean
}) {
  const [draw, { isLoading }] = useDrawMutation()
  const { handleOpenBonusModal } = useWheelModalShow()

  const dispatch = useAppDispatch()
  const isUserLogin = useAppSelector(selectUserIsLogin)
  const [user_id, token] = useUserAuth()

  const [searchParams] = useSearchParams()
  const { t } = useTranslation()

  const userName =
    searchParams.get('userName') || `User...${randomThreeDigitNumber}`

  const handleReceivedReward = async e => {
    e.stopPropagation()
    if (!isUserLogin) {
      dispatch(
        openModal({
          key: GlobalModalKey.Login,
          data: { index: LoginModalTab.SignUp },
        }),
      )
      return
    }
    const { data } = await draw({
      user_id,
      token,
    }).unwrap()
    handleOpenBonusModal({
      reward: data.current_reward,
      remainReward: data.remain_reward,
    })

    dispatch(luckyWheelApi.util.invalidateTags(['luckyWheelInfo']))
  }

  return (
    <>
      <ModalContent
        layerStyle='ls-modal-mask-blur-100'
        w='full'
        h='100vh'
        my='0'
        maxW='100vw'
        borderRadius={0}
      >
        <ModalCloseButton
          color='white'
          w='5'
          h='5'
          mt='5'
          onClick={handleClose}
          as={SharedIcon.Close}
        />
        <Box
          w='full'
          h='full'
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
        >
          <Text
            textAlign='center'
            textStyle='h3'
            color='white'
            whiteSpace='break-spaces'
            maxWidth='244px'
          >
            <Box as='span' color='prim.500' mr='4px'>
              {userName}
            </Box>
            {t('SEND_YOU_CASHGIFT')}
          </Text>
          <ScaleFade initialScale={0.1} in={isOpen} style={{ width: '100%' }}>
            <Flex
              mt='50px'
              justifyContent='center'
              position='relative'
              w='full'
            >
              <Image
                src={giftPackagePng}
                w='172px'
                h='168px'
                objectFit='cover'
                position='relative'
                zIndex='2'
              />
              <Image
                src={lhsdWebp}
                w='172px'
                h='168px'
                objectFit='cover'
                position='absolute'
                top='50%'
                left='50%'
                transform='translate(-50%, -50%)'
                zIndex='1'
              />
              <Image
                src={spinLight}
                w='360px'
                h='360px'
                position='absolute'
                top='50%'
                left='50%'
                transform='translate(-50%, -50%)'
                zIndex='1'
                animation={`${rotateAnimation} 24s linear infinite`}
                transformOrigin='center center'
              />
              <Image
                src={lightCircle}
                w='320px'
                h='320px'
                objectFit='cover'
                position='absolute'
                left='50%'
                top='50%'
                transform='translate(-50%, -50%)'
                animation={`${breathAnimation} .8s linear infinite`}
              />
            </Flex>
          </ScaleFade>

          <Flex mt='43px' alignItems='center' justifyContent='center'>
            <Text textStyle='h6' fontWeight={700} color='white'>
              {t('SIGN_UP_GET_IT')}
            </Text>
          </Flex>
          <Button
            colorScheme='fill-prim'
            w='300px'
            whiteSpace='nowrap'
            color='white'
            mt='32px'
            h='46px'
            cursor='pointer'
            onClick={handleReceivedReward}
            textStyle='h4'
            isLoading={isLoading}
            position='relative'
            zIndex='99'
            // 默认不要聚焦
          >
            {t('VIP_RECIVE_BONUS')}
          </Button>
        </Box>
      </ModalContent>
    </>
  )
}

function LuckyWheelModal() {
  const isUserInfoLoaded = useAppSelector(selectUserInfoLoaded)
  const isWheelInfoLoaded = useAppSelector(selectWheelInfoLoaded)
  const [searchParams] = useSearchParams()
  const [userId] = useUserAuth()
  const modalRef = useRef<HTMLDivElement>(null)

  const activity = searchParams.get('fromActivity')
  const isFromActivity = activity && activity === 'luckyWheel'
  const referralcode = searchParams.get('referralcode')
  /** 获取是否是首次 **/
  const { firstDrawTime } = useAppSelector(getCurrentUserRewardInfo)

  const isFromShare = useMemo(() => {
    return (
      isFromActivity &&
      !firstDrawTime &&
      referralcode !== userId &&
      isWheelInfoLoaded
    )
  }, [isFromActivity, firstDrawTime, referralcode, userId, isWheelInfoLoaded])

  const dispatch = useAppDispatch()
  const [isShadowShow, setIsShadowShow] = useState(false)
  const isUserLogin = useAppSelector(selectUserIsLogin)
  const isLargerThanMobile = useLargerThanMobile()
  const { onCloseModal } = useModalConfig()
  const { open, data } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.LuckyWheelActivity],
  )
  const { opportunity } = useAppSelector(getCurrentUserRewardInfo)
  const { handleOpenPromptModal } = useWheelModalShow()
  const handleClose = () => {
    if (opportunity > 0 && isUserLogin) {
      handleOpenPromptModal()
    } else {
      dispatch(
        closeModal({
          key: GlobalModalKey.LuckyWheelActivity,
        }),
      )
      onCloseModal(GlobalModalKey.LuckyWheelActivity)
    }
  }
  /** 引导弹窗关闭方法 */
  const handleCloseGuide = () => {
    dispatch(
      closeModal({
        key: GlobalModalKey.LuckyWheelActivity,
      }),
    )
    onCloseModal(GlobalModalKey.LuckyWheelActivity)
  }
  // 是否显示蒙层
  const [isShowCover, setIsShowCover] = useState(false)
  useEffect(() => {
    luckyWheelEvents.on('showCover', () => {
      setIsShowCover(true)
      setTimeout(() => {
        setIsShowCover(false)
      }, 3000)
    })
  }, [])

  useEffect(() => {
    open && !data?.isOpen && onCloseModal(GlobalModalKey.LuckyWheelActivity)
  }, [open, data])

  const { open: isLoginOpen } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.Login],
  )

  const { open: isWalletOpen } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.Wallet],
  )

  const modalOpen = useMemo(() => {
    return !!(
      (open && !!data?.isOpen && !isLoginOpen && !isWalletOpen)
      // isUserInfoLoaded &&
      // isWheelInfoLoaded
    )
  }, [data, isLoginOpen, isWalletOpen, open])

  return (
    <>
      <Modal
        autoFocus={false}
        trapFocus={false}
        isOpen={modalOpen}
        onClose={handleClose}
        isCentered={isLargerThanMobile}
        initialFocusRef={undefined}
      >
        {isFromShare ? (
          <GuideContent handleClose={handleCloseGuide} isOpen={modalOpen} />
        ) : (
          <>
            <Box
              hidden={!isShadowShow}
              position='absolute'
              top={isUserLogin ? '21%' : '13%'}
              zIndex='1'
              left='0'
              right='0'
              margin='0 auto'
              display='block'
              bgColor='#44096B'
              w='328px'
              h='361px'
              borderRadius='361px'
            />
            <ModalOverlay layerStyle='ls-modal-mask-blur-50' />

            <Suspense fallback={<Spinner />}>
              <LuckyWheel
                handleClose={handleClose}
                onInit={() => setIsShadowShow(true)}
                opportunity={opportunity}
                isShowCover={isShowCover}
              />
            </Suspense>
          </>
        )}
      </Modal>

      {modalOpen && <RechargeAndInviteModal />}

      <PromptModal />
      <BonusModal />
      <SuccessWithdrawModal />
      <NoResultModal />
    </>
  )
}
export default LuckyWheelModal
