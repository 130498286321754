import type { RechargeFormItemOption } from '@/modules/wallet/interface'

export interface Validate {
  label?: string
  required: boolean
  error_msg?: string
}

export interface TextValidate extends Validate {
  len?: number
  min_length?: number
  max_length?: number
  regex?: string
}

export interface NumberValidate extends Validate {
  min_value?: number
  max_value?: number
  is_integer?: boolean
}

export type ValidateOption = TextValidate | NumberValidate

export interface FormOption {
  label?: string
  error_msg?: string
  required?: boolean
  valid: boolean
}

export enum FormType {
  text = 'text',
  number = 'number',
  select = 'select',
}
export enum DynamicFromType {
  text = 'text',
  phone = 'phone',
  select = 'select',
  hidden = 'hidden',
}
export interface DynamicFormsOption {
  type: DynamicFromType
  form_id?: string
  key: string
  validate?: ValidateOption
  label?: string
  group?: string // 经在当前使用组有效的的时候展示
  // for text
  title?: string // i18n key
  place_holder?: string // i18n key
  // for phone
  region_codes?: string[]
  // for select
  options?: RechargeFormItemOption[]
  // for hidden
  value?: string
}

export interface Forms {
  [key: string]: {
    value: string
    valid?: boolean
    error?: string | null
    validate?: ValidateOption
    type: FormType
  }
}
