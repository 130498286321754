import { useToast } from '@chakra-ui/react'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { trackAddToWishList } from '@/common/tracker'
import { useAppDispatch } from '@/modules/app/store'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import {
  useAddToCollectionMutation,
  useRemoveFromCollectionMutation,
} from '@/modules/my-games/api'
import { useIsGameFavorited } from '@/modules/my-games/useIsGameFavorited'
import { useUserAuth } from '@/utils/hooks'

import { CollectionGamesContext } from '../useGameGrid'
import { useIsFavoriteTipsShow } from './useIsFavoriteTipsShow'

export const useFavoriteButton = (
  gameId: string,
  gameName: string,
  gameUuid: string,
) => {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const { t } = useTranslation()
  const isGameFavorited = useIsGameFavorited()
  const { getRemoveId } = useContext(CollectionGamesContext)
  const [userId, token] = useUserAuth()
  const [addToCollection, { isLoading: isAdding }] =
    useAddToCollectionMutation()
  const [removeFromCollection, { isLoading: isRemoving }] =
    useRemoveFromCollectionMutation()
  // Todo 如何辨别索引是 game_uuid 还是 game_id
  // 先用 game_id 搜，如果搜不到再用 game_uuid 搜
  const isFavoritedByGameId = isGameFavorited(gameId)
  const isFavoritedByGameUuid = isGameFavorited(gameUuid)
  const isFavorited = isFavoritedByGameId || isFavoritedByGameUuid

  const [isTipsShow, afterFavorite] = useIsFavoriteTipsShow(gameId, isFavorited)

  const onClick = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation()
      trackAddToWishList()
      if (!userId || !token) {
        dispatch(openModal({ key: GlobalModalKey.Login }))
        return
      }
      if (!isFavorited) {
        addToCollection({
          user_id: userId,
          token,
          game_id: gameId,
          game_name: gameName,
          game_uuid: gameUuid,
        })
          .unwrap()
          .then(() => afterFavorite())
          .then(() =>
            toast({
              title: t('ADD_IN_FAV'),
              status: 'success',
            }),
          )
      } else {
        removeFromCollection({
          user_id: userId,
          token,
          game_id: getRemoveId(gameId, gameUuid),
          game_name: gameName,
          game_uuid: gameUuid,
        })
          .unwrap()
          .then(() =>
            toast({
              title: t('REMOVE_FROM_FAV'),
              status: 'success',
            }),
          )
      }
    },
    [
      userId,
      token,
      isFavorited,
      dispatch,
      addToCollection,
      gameId,
      gameName,
      gameUuid,
      afterFavorite,
      toast,
      t,
      removeFromCollection,
      getRemoveId,
    ],
  )

  const result = useMemo(
    () => ({
      onClick,
      isFavorited,
      isTipsShow,
      isLoading: isAdding || isRemoving,
    }),
    [onClick, isFavorited, isTipsShow, isAdding, isRemoving],
  )

  return result
}
