import { useDisclosure, useToast } from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch } from '@/modules/app/store'
import { closeModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { isQueryError } from '@/services/types'

import { useDrawPlayMutation } from '../api/drawActivityApi'
import CommonTipsModal from '../CommonTipsModal'
import type { ActivityStageProps } from './StageWheel'

const StageBlindBox = React.lazy(() => import('./StageBlindBox'))
const StageWheel = React.lazy(() => import('./StageWheel'))
const StageSudoku = React.lazy(() => import('./StageSudoku'))

export function ActivityStage(
  props: Pick<ActivityStageProps, 'activity'> & {
    scrollToAnchor?: () => void
    isVisible?: boolean
    canShowTips?: boolean
  },
) {
  const { activity, scrollToAnchor, isVisible, canShowTips } = props
  const { t } = useTranslation()
  const toast = useToast()
  const dispatch = useAppDispatch()
  const [draw] = useDrawPlayMutation({})

  const { isOpen: isShowTips, onClose, onOpen: showTips } = useDisclosure()

  const navigate = useNavigate()
  const [tips, setTips] = useState<{
    title: string
    buttonText: string
    desc: string
    buttonClickCb: () => void
    onClose: () => void
  }>({
    title: t('EVENT_NOT_AVAILABLE'),
    desc: t('EVENT_FORBINDEN_DSC'),
    buttonText: t('MUNE_ACTIVITY_CENTER_TEXT'),
    buttonClickCb: () => {
      closeTips()
      navigate('/promotions')
    },
    onClose: () => {
      closeTips()
      navigate('/promotions')
    },
  })

  const closeTips = useCallback(() => {
    onClose()
    dispatch(
      closeModal({
        key: GlobalModalKey.DrawActivityModal,
      }),
    )
  }, [dispatch, onClose])

  const checkStatus = useCallback(() => {
    if (!canShowTips) return
    const forbiddenTips = {
      title: t('EVENT_NOT_AVAILABLE'),
      desc: t('EVENT_FORBINDEN_DSC'),
      buttonText: t('MUNE_ACTIVITY_CENTER_TEXT'),
      buttonClickCb: () => {
        closeTips()
        navigate('/promotions')
      },
      onClose: () => {
        closeTips()
        navigate('/promotions')
      },
    }
    if (!activity) {
      setTips(forbiddenTips)
      showTips()
      return
    }
    const { begin = 0, end = 0 } = activity
    if (!begin || !end) {
      setTips(forbiddenTips)
      showTips()
      return
    }
    const isInTheTimeRange =
      Date.now() >= begin * 1000 && Date.now() <= end * 1000
    if (!isInTheTimeRange) {
      setTips({
        title: t('EVENT_NOT_AVAILABLE'),
        desc: t('EVENT_NOT_AVAILABLE_DSC', {
          time: `${dayjs(begin * 1000).format('YYYY-MM-DD HH:mm')} - ${dayjs(
            end * 1000,
          ).format('YYYY-MM-DD HH:mm')}`,
        }),
        buttonText: t('MUNE_ACTIVITY_CENTER_TEXT'),
        buttonClickCb: () => {
          closeTips()
          navigate('/promotions')
        },
        onClose: () => {
          onClose()
        },
      })
      showTips()
      return
    } else {
      onClose()
    }
  }, [activity, canShowTips, closeTips, navigate, onClose, showTips, t])

  const onDraw = useCallback(async () => {
    try {
      return await draw({
        task_id: activity.taskId,
        version: activity.updatedAt,
      }).unwrap()
    } catch (error) {
      if (isQueryError(error) && error.status === 101005) {
        toast({ title: t('DRAW_NOT_PRIZE'), status: 'error' })
      } else if (error) {
        toast({ title: t('SPIN_NO_TIME'), status: 'error' })
        scrollToAnchor?.()
      } else if (isVisible) {
        checkStatus()
      }
      throw error
    }
  }, [
    activity.taskId,
    activity.updatedAt,
    checkStatus,
    draw,
    isVisible,
    scrollToAnchor,
    t,
    toast,
  ])

  const stageProps = {
    isVisible,
    activity,
    onDraw,
  }

  const stageRender = () => {
    switch (activity.drawStyle) {
      case 1:
        return <StageSudoku {...stageProps} />
      case 2:
        return <StageWheel {...stageProps} />
      case 3:
        return <StageBlindBox {...stageProps} />
      default:
        return null
    }
  }

  useEffect(() => {
    if (isVisible) {
      checkStatus()
    }
  }, [isVisible, checkStatus])

  return (
    <Suspense fallback={null}>
      <>
        {stageRender()}
        <CommonTipsModal
          isOpen={isShowTips}
          onClose={tips.onClose}
          title={tips.title}
          buttonText={tips.buttonText}
          desc={tips.desc}
          buttonClickCb={tips.buttonClickCb}
          showButton
        />
      </>
    </Suspense>
  )
}
