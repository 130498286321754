import { pmLog } from '../pm-log'

export default function logPageLoadTime(
  time: number,
  page_url: string,
  type: 'lcp' | 'fcp',
  lcp_url?: string,
): void {
  pmLog({
    eventName: 'clientmetrics_page_load_time_spent',
    payload: {
      page_url: page_url,
      load_type: type,
      type_url: lcp_url,
      time_spent: time,
      domain: window.location.host,
    },
    target: {
      labels: ['page_url', 'load_type', 'type_url', 'domain'],
      value: 'time_spent',
    },
  })
}
