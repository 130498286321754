import {
  Box,
  Button,
  Flex,
  Highlight,
  Image,
  keyframes,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ScaleFade,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'

import bonusCoin from '@/assets/images/lucky-wheel/bonus-coin.png'
import lightCircle from '@/assets/images/lucky-wheel/light-circle.png'
import spinLight from '@/assets/images/lucky-wheel/spin-light.webp'
import { isFeatureEnabled } from '@/common/feature'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { formatLocalMoney } from '@/utils/tools'
import { projectName } from '@/websites/current/property.json'

import luckyWheelEvents from '../events'
import useLuckyWheelShares from '../hooks/useLuckyWheelSharesInfo'
// 绕中心点旋转
const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

function generateRanking(minRanking: number) {
  // 生成五位数的排名，最小值是传入的minRanking,要根据时间戳去做生成,保证后生成的比前生成的大
  const now = Date.now()
  const tempRanking = (now % 90000) + minRanking
  const ranking = tempRanking > 99999 ? '99999+' : tempRanking
  return ranking
}

function SuccessWithdrawModal() {
  const minRanking = 29975
  const ranking = generateRanking(minRanking)
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [reward, setReward] = useState(0)

  const { handleOpenSharesModal } = useLuckyWheelShares()

  useEffect(() => {
    luckyWheelEvents.on('openSuccessModal', reward => {
      setReward(reward)
      onOpen()
    })
  }, [onOpen])

  const textContent = useMemo(() => {
    return t('SHARE_SC_WITH_FRIEND')
  }, [])

  const buttonClick = useCallback(() => {
    isFeatureEnabled('luckyWheelSuccessWithdrawRedirectToCustomService')
      ? window.open('http://tinyurl.com/apanalohelp', '_blank')
      : handleOpenSharesModal('successWithDrawModal', reward)
  }, [handleOpenSharesModal, reward])

  const buttonContent = useMemo(() => {
    return t('TO_INVITE')
  }, [])

  return (
    <Modal
      autoFocus={false}
      trapFocus={false}
      isOpen={isOpen}
      onClose={onClose}
      size='full'
      variant={{
        base: 'fill-square',
        sm: 'round',
      }}
    >
      <ModalOverlay />
      <ModalContent
        layerStyle='ls-modal-mask-blur-100'
        w='full'
        h='100vh'
        my='0'
      >
        <ModalCloseButton
          color='white'
          w='5'
          h='5'
          onClick={() => {
            onClose()
          }}
          as={SharedIcon.Close}
        />
        <Box
          w='full'
          h='full'
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
        >
          <Text textStyle='h3' color='white' textAlign='center'>
            {t('CONTRATE_ON')}
          </Text>
          <ScaleFade initialScale={0.1} in={isOpen}>
            <Image
              src={lightCircle}
              w='320px'
              h='320px'
              objectFit='cover'
              position='absolute'
              left='50%'
              top='50%'
              transform='translate(-50%, -75%)'
            />
          </ScaleFade>
          <ScaleFade initialScale={0.1} in={isOpen}>
            <Box>
              <Flex
                mt='50px'
                justifyContent='center'
                position='relative'
                w='full'
                p='40px'
              >
                <Image
                  src={bonusCoin}
                  w='300px'
                  h='165px'
                  objectFit='cover'
                  position='relative'
                  zIndex='2'
                />
                <Image
                  src={spinLight}
                  w='100%'
                  h='auto'
                  objectFit='cover'
                  position='absolute'
                  top='-32%'
                  //   left='-2%'
                  transform='translate(-50%, -50%)'
                  zIndex='1'
                  animation={`${rotateAnimation} 24s linear infinite`}
                  transformOrigin='center center'
                />
              </Flex>
            </Box>
          </ScaleFade>
          <Text
            textAlign='center'
            textStyle='h1'
            color='white'
            textShadow='0px 2px 8px rgba(202, 194, 15, 0.50)'
            bg='linear-gradient(180deg, #FFF 0%, #F2F199 74.48%, #E8D195 100%)'
            bgClip='text'
            textColor='transparent'
          >
            {formatLocalMoney(reward)}
          </Text>
          <Flex mt='6' alignItems='center'>
            <Text textStyle='h8' color='white' ml='1' textAlign='center'>
              {t('SHARE_INVITE_FREE_BONUS', {
                amount: formatLocalMoney(reward),
              })}
            </Text>
          </Flex>
          <Text
            textStyle='text5'
            color='white'
            px='10'
            textAlign='center'
            mt='4'
          >
            <Highlight
              query={[ranking.toString(), '99%']}
              styles={{
                color: 'four.500',
              }}
            >
              {
                t('YOU_ARE_NUMBER', {
                  number: ranking,
                })!
              }
            </Highlight>
            {t('DEFEAT_99')}
          </Text>
          <Text
            mt='10'
            textStyle='text6'
            color='white'
            px='10'
            textAlign='center'
            maxW='300px'
          >
            {/* {t('SHARE_SC_WITH_FRIEND')} */}
            {textContent}
          </Text>
          <Button
            colorScheme='fill-prim'
            w='300px'
            whiteSpace='nowrap'
            color='white'
            mt='4'
            py='4'
            position='relative'
            zIndex='4'
            onClick={() => {
              {
                buttonClick()
              }
            }}
          >
            <Text textStyle='h4'>{buttonContent}</Text>
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  )
}

export default SuccessWithdrawModal
