import {
  createDraftSafeSelector,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'

import type { RootState } from '../app/store'
import type { AuthConfig } from './loginApi'
import { loginApi } from './loginApi'

export type loginAndSignUpAuthConfigState = AuthConfig

const initialState: loginAndSignUpAuthConfigState = {
  login: {
    enable_phone_password: false,
    enable_phone_verify_code: false,
    enable_email_password: false,
    enable_recaptcha_visible: 0,
  },
  register: {
    enable_phone_password: false,
    enable_phone_verify_code: false,
    enable_email_password: false,
    enable_email_verify_code: false,
    enable_email_phone_verify: false,
    enable_email_verify_phone_verify: false,
    enable_recaptcha_visible: 0,
  },
  third_login: {
    enable_google: false,
    enable_facebook: false,
    enable_telegram: false,
  },
}

export const loginAndSignUpAuthConfigSlice = createSlice({
  name: 'loginAndSignUpAuthConfig',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      loginApi.endpoints.getLoginAndSignUpConfigV2.matchFulfilled,
      (_, { payload }) => {
        const config = payload.data ?? ''
        return config
      },
    )
  },
})

const selectSelf = (state: RootState) => state
export const selectLoginAndSignUpAuthConfig = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => state.loginAndSignUpAuthConfig,
)

export const selectLoginAuthConfig = createSelector(
  selectLoginAndSignUpAuthConfig,
  loginAndSignUpAuthConfig => loginAndSignUpAuthConfig.login,
)

export const selectRegisterAuthConfig = createSelector(
  selectLoginAndSignUpAuthConfig,
  loginAndSignUpAuthConfig => loginAndSignUpAuthConfig.register,
)

export const selectThirdLoginAuthConfig = createSelector(
  selectLoginAndSignUpAuthConfig,
  loginAndSignUpAuthConfig => loginAndSignUpAuthConfig.third_login,
)

export const selectEnablePhoneVerifyLogin = createSelector(
  selectLoginAuthConfig,
  loginAuthConfig => loginAuthConfig.enable_phone_verify_code,
)

export const selectEnablePhonePwdVerifyLogin = createSelector(
  selectLoginAuthConfig,
  loginAuthConfig => loginAuthConfig.enable_phone_password,
)

export const selectEnableEmailPwdVerifyLogin = createSelector(
  selectLoginAuthConfig,
  selectEnablePhoneVerifyLogin,
  selectEnablePhonePwdVerifyLogin,
  (loginConfig, EnablePhoneVerifyLogin, EnablePhonePwdVerifyLogin) => {
    if (!EnablePhonePwdVerifyLogin && !EnablePhoneVerifyLogin) {
      return true
    }
    return loginConfig?.enable_email_password
  },
)

export const selectEnablePhoneVerifyRegister = createSelector(
  selectRegisterAuthConfig,
  registerAuthConfig => registerAuthConfig.enable_phone_verify_code,
)

export const selectEnablePhonePwdVerifyRegister = createSelector(
  selectRegisterAuthConfig,
  registerAuthConfig => registerAuthConfig.enable_phone_password,
)

export const selectEnableEmailVerifyRegister = createSelector(
  selectRegisterAuthConfig,
  registerAuthConfig =>
    registerAuthConfig.enable_email_verify_code &&
    !registerAuthConfig.enable_email_phone_verify &&
    !registerAuthConfig.enable_email_verify_phone_verify,
)

export const selectEnableEmailAndPhoneCodeRegister = createSelector(
  selectRegisterAuthConfig,
  registerAuthConfig =>
    registerAuthConfig.enable_email_phone_verify &&
    !registerAuthConfig.enable_email_verify_phone_verify,
)

export const selectEnableEmailCodeAndPhoneCodeRegister = createSelector(
  selectRegisterAuthConfig,
  registerAuthConfig => registerAuthConfig.enable_email_verify_phone_verify,
)

export const selectEnableEmailPwdVerifyRegister = createSelector(
  selectRegisterAuthConfig,
  selectEnablePhoneVerifyRegister,
  selectEnablePhonePwdVerifyRegister,
  selectEnableEmailVerifyRegister,
  selectEnableEmailCodeAndPhoneCodeRegister,
  (
    registerConfig,
    EnablePhoneVerifyRegister,
    EnablePhonePwdVerifyRegister,
    EnableEmailVerifyRegister,
    EnableEmailCodeAndPhoneCodeRegister,
  ) => {
    if (
      !EnablePhoneVerifyRegister &&
      !EnablePhonePwdVerifyRegister &&
      !EnableEmailVerifyRegister &&
      !EnableEmailCodeAndPhoneCodeRegister
    ) {
      return true
    }
    return registerConfig?.enable_email_password
  },
)

export const selectLoginRecaptchaVisible = createSelector(
  selectLoginAuthConfig,
  config => config.enable_recaptcha_visible,
)

export const selectEnableRecaptchaVisible = createSelector(
  selectRegisterAuthConfig,
  registerAuthConfig => registerAuthConfig.enable_recaptcha_visible,
)

export const loginAndSignUpAuthConfigReducer =
  loginAndSignUpAuthConfigSlice.reducer
