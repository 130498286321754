import type { SystemStyleObject, ThemingProps } from '@chakra-ui/react'
import { useStyleConfig } from '@chakra-ui/react'
import { isEmpty } from 'lodash-es'
import { useMemo } from 'react'

import type { Expand } from '../typecast'

export function hasSetParts<T extends string>(
  styles: SystemStyleObject | undefined,
  parts: T[] | Readonly<T[]>,
): styles is Expand<typeof styles, T> {
  if (isEmpty(styles)) return false

  for (const key of parts) {
    if (key in styles) return true
  }
  return false
}

type InArrayMap<T extends string, S> = {
  [P in keyof S]: P extends T ? SystemStyleObject : never
}

/**
 *
 * @param name Customized component name
 * @param baseStyle Base style
 * @returns A styled system object you custom
 */
export function useCustomizedStyle(
  name: string,
  baseStyle: SystemStyleObject,
): SystemStyleObject

/**
 *
 * @param name Customized component name
 * @param baseStyle Multiple base style
 * @param parts Multiple component names: name[]
 * @returns A multiple styled system object you custom
 */
export function useCustomizedStyle<T extends string, S>(
  name: string,
  baseStyle: InArrayMap<T, S>,
  parts: T[] | Readonly<T[]>,
  props?: ThemingProps<any>,
): InArrayMap<T, S> & Partial<Record<T, SystemStyleObject>>

export function useCustomizedStyle<T extends string, S>(
  name: string,
  baseStyle: SystemStyleObject | InArrayMap<T, S>,
  parts?: T[] | Readonly<T[]>,
  props?: ThemingProps<any>,
):
  | SystemStyleObject
  | (InArrayMap<T, S> & Partial<Record<T, SystemStyleObject>>) {
  const customizedStyles = useStyleConfig(name, props)
  let _style: any = {}

  if (parts !== undefined && parts.length > 0) {
    const setParts = hasSetParts<T>(customizedStyles, parts)
    if (setParts) {
      for (const part of parts) {
        const _baseStyle = (baseStyle as any)[part]
        const customizedStyle = customizedStyles[part]
        _style[part] = {
          ..._baseStyle,
          ...customizedStyle,
        }
      }
    } else {
      _style = baseStyle
    }
  } else {
    _style = {
      ...(baseStyle as SystemStyleObject),
      ...(customizedStyles ?? {}),
    }
  }

  return useMemo(() => _style, [])
}

export default useCustomizedStyle
