import {
  Box,
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  SimpleGrid,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import rechargeActivtiyModalHead from '@/assets/images/recharge-comfirm-modal/recharge-activtiy-modal-head.png'
import { getCurrencyCode } from '@/common/locale/currency'
import TitledDialog from '@/components/modal/TitledDialog'
import { useAppSelector } from '@/modules/app/store'
import { getRechargeTyp } from '@/modules/recharge-activity/rechargeActSlice'
import SelectWayItem from '@/modules/recharge-activity/selectWayItem'
import { useRechargeButtonClick } from '@/modules/recharge-activity/useRechargeButtonClick'
import { useWalletTutorial } from '@/modules/wallet/useWalletTutorial'
import WalletService from '@/modules/wallet/walletService'
import { getRechargePaymentOptions } from '@/modules/wallet/walletSlice'
import { Icon as SharedIcon } from '@/utils/atom-shared'

import type { BankruptGift } from '../type'
import bankruptRechargeEvents from './events'

const currency = getCurrencyCode()

export default function RechargeModal() {
  const [modalHeight, setModalHeight] = useState<number>(window.innerHeight)
  const { t } = useTranslation()
  const [rechargeProps, setRechargeProps] = useState<{
    reward: BankruptGift
    activtiyId: number
  }>()
  const { showRechargeTutorial, openTutorialModal } = useWalletTutorial()

  const options = useAppSelector(getRechargePaymentOptions)
  const rechargeType = useAppSelector(getRechargeTyp)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    trigger: onRechargeButtonClick,
    result: { isLoading },
  } = useRechargeButtonClick(
    Number(rechargeProps?.reward.recharge_amount),
    true,
    options && options.length === 0,
    rechargeType,
    ``,
    -1,
    rechargeProps?.activtiyId,
  )

  const onModalClose = () => {
    setRechargeProps(undefined)
    onClose()
  }

  useEffect(() => {
    const resetHeight = () => {
      setModalHeight(window.innerHeight)
    }
    window.addEventListener('resize', resetHeight)
    return () => {
      window.removeEventListener('resize', resetHeight)
    }
  }, [])

  useEffect(() => {
    const handleOpenBankrupRecharge = (data: any) => {
      setRechargeProps(data)
      onOpen()
    }

    bankruptRechargeEvents.on(
      'handleOpenBankrupRecharge',
      handleOpenBankrupRecharge,
    )

    return () => {
      bankruptRechargeEvents.off('handleOpenBankrupRecharge')
    }
  }, [])

  return (
    <TitledDialog
      isOpen={isOpen}
      onClose={onModalClose}
      colorScheme='multilayer'
      variant={{ base: 'fill-square', sm: 'round' }}
      size={{ base: 'full', sm: 'sm' }}
      title={''}
      closeColor='#fff'
      padding={true}
      contentBg='linear-gradient(178.92deg, rgba(79, 2, 128, 0.91) 6.93%, #621C91 15.76%)'
    >
      <Box
        height={{ base: `${modalHeight}px`, sm: '100%' }}
        bgImage={`url(${rechargeActivtiyModalHead})`}
        bgSize='100% auto'
        bgPosition='top'
        bgRepeat='no-repeat'
        display='flex'
        flexDirection='column'
        justifyContent='flex-end'
        pt='27%'
      >
        <Box
          px='2.5'
          pb='3'
          bg='linear-gradient(178.92deg, rgba(79, 2, 128, 0) 0%, #621C91 15.76%)'
        >
          <Text
            textAlign='center'
            fontSize='20px'
            fontStyle='italic'
            fontWeight='900'
            lineHeight='32px'
            background='linear-gradient(90deg, #FFFAAA 0%, #FFF 56.41%, #FFFAAC 100%)'
            backgroundClip='text'
            mb='2'
          >
            {t('BENEFITS_INTRODUCTION')}
          </Text>
          <VStack
            borderRadius='2xl'
            bgColor='whiteAlpha.300'
            alignItems='stretch'
            px='2.5'
            py='4'
            mb='2.5'
            spacing='2'
          >
            <Text color='white' mx='1.5' textStyle='text3'>
              {t('DEPOSIT_RECHARGEAMOUNT')}
            </Text>
            <Input
              value={rechargeProps?.reward.recharge_amount}
              readOnly
              bg='white'
              color='text.base'
              borderColor='white'
              _readOnly={{
                color: 'text.base',
                opacity: 0.8,
                borderColor: 'white',
              }}
            />
            <HStack justifyContent='space-between' mx='1.5'>
              <Text color='white' textStyle='text3'>
                {t('RECHARGE_AMOUNT')}
              </Text>
              <Text color='four.500' textStyle='h5'>
                {(rechargeProps?.reward.recharge_amount || 0) +
                  (rechargeProps?.reward.gift_amount || 0)}
                {currency}
              </Text>
            </HStack>
            {/* <RechargeInput /> */}
          </VStack>
          <Box
            borderRadius='2xl'
            bgColor='whiteAlpha.300'
            alignItems='stretch'
            p='2.5'
            pt='4'
            mb='4'
          >
            <Text color='white' mx='1.5' textStyle='text3' mb='2.5'>
              {t('RECHARGE_TYPE')}

              {showRechargeTutorial && (
                <IconButton
                  ml={'7px'}
                  aria-label='info'
                  variant='unstyledIcon'
                  verticalAlign='middle'
                  onClick={() => {
                    openTutorialModal('recharge')
                  }}
                  icon={<Icon as={SharedIcon.Question} boxSize='4' />}
                />
              )}
            </Text>
            <Box
              minH='176px'
              overflowY='scroll'
              sx={{ '::-webkit-scrollbar': { display: 'none' } }}
            >
              <SimpleGrid columns={2} gap='2'>
                {options.map((item, index) => {
                  return (
                    <SelectWayItem
                      key={`bankrupt_recharge_typ_${index}`}
                      {...item}
                    />
                  )
                })}
              </SimpleGrid>
            </Box>
          </Box>
          <Button
            colorScheme='four'
            size='lg'
            w='full'
            onClick={() => onRechargeButtonClick()}
            isLoading={isLoading}
            loadingText='Loading'
          >
            {t('DEPOSIT_RECHARGE')}
          </Button>
          <WalletService color='#fff' support_icon='white' cb={onClose} />
        </Box>
      </Box>
    </TitledDialog>
  )
}
