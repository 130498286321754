import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { selectUserIsLogin } from '@/modules/user/userSlice'

import type { GamePlatform } from './contants'
import type { GameListItem } from './gameInterface'
import { initFreeTryMode, switchShowTips } from './gameSlice'
import { getGamePlatform, isInhousePlatform } from './utils'
import { getDeviceType } from '@/utils/tools'
import { isFromApp } from '@/common/platform/utils'
import { isFeatureEnabledV2 } from '@/common/featureSwiitch'
import { selecAlltUserInviteUrl } from '../new-referral/referralSlice'
import { isSameOrigin } from '../download-app-guide-modal/downloadAppGuide'

interface Checker {
  predicate: (game: GameListItem) => boolean
  failCallback: (game: GameListItem) => void
}

export function useNavigateToGame() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isLogin = useAppSelector(selectUserIsLogin)

  const DEVICE = getDeviceType()
  const fromApp = isFromApp()
  const isGotoMobile = isFeatureEnabledV2('enable_goto_mobile')

  // 需要登录
  const needLoginChecker: Checker = useMemo(
    () => ({
      predicate: () => !!isLogin,
      failCallback: () => dispatch(openModal({ key: GlobalModalKey.Login })),
    }),
    [dispatch, isLogin],
  )

  // 维护中
  const underMaintChecker: Checker = useMemo(
    () => ({
      predicate: (game: GameListItem) => game?.is_under_maint !== 1,
      failCallback: game =>
        dispatch(switchShowTips(game?.maintenance_text || '')),
    }),
    [dispatch],
  )
  const shareAllUrl = useAppSelector(selecAlltUserInviteUrl)

  const location = useLocation()
  const currentUrl = window.location.origin
  const isShareLink = shareAllUrl.some(url => isSameOrigin(url, currentUrl))
  const navigateToGame = useCallback(
    (game: GameListItem) => {
      const platform = getGamePlatform(game)
      const checkers: Checker[] = [underMaintChecker]

      if (!isInhousePlatform(platform) && !game?.try_play_switch) {
        checkers.push(needLoginChecker)
      }

      for (const checker of checkers) {
        if (!checker.predicate(game)) {
          checker.failCallback(game)
          return
        }
      }

      if (game?.try_play_switch) dispatch(initFreeTryMode())
      navigate(getGamePageUrl(game), {
        state: {
          from: location.pathname + location.search + location.hash,
        },
      })
    },
    [
      underMaintChecker,
      dispatch,
      navigate,
      location.pathname,
      location.search,
      location.hash,
      needLoginChecker,
    ],
  )

  return (DEVICE === 'ios' || DEVICE === 'android' || DEVICE === 'pc') &&
    !fromApp &&
    isGotoMobile &&
    !isShareLink
    ? () =>
        dispatch(
          openModal({
            key: GlobalModalKey.DownloadAppGuide,
          }),
        )
    : DEVICE === 'ios' && isShareLink && isGotoMobile
    ? () =>
        dispatch(
          openModal({
            key: GlobalModalKey.DownloadAppGuide,
          }),
        )
    : navigateToGame
}

function getGamePageUrl(game: GameListItem) {
  const platform = getGamePlatform(game)
  const PLATFORM_TO_LABEL_MAP: Record<GamePlatform, string> = {
    jili: 'jili',
    facai: 'facai',
    slotegrator: 'slot',
    evoplay: 'slot',
    hillingo: 'self',
    inhouse: 'inhouse',
    'hillingo-popular': 'inhouse',
    live_casino: 'live',
    pgsoft: 'pgsoft',
    fundist: 'fundist',
  }
  const label = PLATFORM_TO_LABEL_MAP[platform] ?? ''
  const { game_id, game_name } = game
  const gameId = isInhousePlatform(platform)
    ? game_name?.toLowerCase()
    : game_id
  return `/game/${label}/${gameId}`
}
