import { TrackerFacebook } from './TrackerFacebook'
import { TrackerGoogleAnalytics } from './TrackerGoogle'
import { TrackerKwai } from './TrackerKwai'
import { TrackerTiktok } from './TrackerTiktok'
import type { ITrackerBase } from './types'

export class TrackerBuilderPixel {
  build() {
    const channel = sessionStorage.getItem('channel')
    const channelId = sessionStorage.getItem('channel_id')
    const tiktokId = sessionStorage.getItem('tiktokId')
    const facebookId = sessionStorage.getItem('facebookId')
    const kwaiId = sessionStorage.getItem('kwaiId')
    switch (channel) {
      case 'tiktok':
      case 'tiktlk':
        if (tiktokId) return this.buildTiktokTracker(tiktokId)
        break
      case 'facebook':
        if (facebookId) return this.buildFacebookTracker(facebookId)
        break
      case 'kwaiq':
        if (kwaiId) return this.buildKwaiTracker(kwaiId)
        break
      case 'google':
        if (channelId) return this.buildGoogleAnalyticsTracker(channelId)
        break
      default:
        return null
    }
    return null
  }

  buildTiktokTracker(tiktokId: string): ITrackerBase {
    return new TrackerTiktok(tiktokId)
  }

  buildFacebookTracker(facebookId: string): ITrackerBase {
    return new TrackerFacebook(facebookId)
  }

  buildKwaiTracker(kwaiId: string): ITrackerBase {
    return new TrackerKwai(kwaiId)
  }

  buildGoogleAnalyticsTracker(channelId: string): ITrackerBase {
    return new TrackerGoogleAnalytics(channelId)
  }
}
