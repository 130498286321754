import dayjs from 'dayjs'

import { localStorage } from '@/utils/localStorage'

const DEFAULT_HMS = (() => {
  const _dev = window.location.href.includes('_mode=dev')
  if (_dev) return ['00', '00', '10']
  return ['00', '15', '00']
})()

export function formatDateTime(time: string) {
  const seconds = Number(time)
  const hours = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, '0')
  const minutes = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, '0')
  const secs = Math.floor(seconds % 60)
    .toString()
    .padStart(2, '0')
  return [hours, minutes, secs]
}

export function getSeconds(HMS: string[]) {
  const seconds = HMS.reduce((acc, cur, index) => {
    if (index === 0) {
      return acc + Number(cur) * 3600
    }
    if (index === 1) {
      return acc + Number(cur) * 60
    }
    return acc + Number(cur)
  }, 0)
  return seconds
}

// 获取展示的时分秒
export const calcHMS = () => {
  // 获取本地存储的倒计时时间戳
  const tempCountDown = localStorage.getItem(
    'rechargeCountDownFinishedTimeStamp',
  )
  // 获取当前时间戳
  const nowTimeStamp = new Date().getTime()

  // 如果没有倒计时时间戳，就设置一个
  if (tempCountDown === undefined || tempCountDown === null) {
    const finishedTimeStamp = nowTimeStamp + getSeconds(DEFAULT_HMS) * 1000
    localStorage.setItem(
      'rechargeCountDownFinishedTimeStamp',
      finishedTimeStamp.toString(),
    )
  }

  // 获取本地存储的下一次倒计时时间戳
  const nextCountDownTimeStamp = localStorage.getItem(
    'nextRechargeCountDownTimeStamp',
  )

  // 如果没有下一次倒计时时间戳，或者当前时间戳大于下一次倒计时时间戳，就设置一个
  if (
    !nextCountDownTimeStamp ||
    nowTimeStamp > Number(nextCountDownTimeStamp)
  ) {
    const nextDayStart = (() => {
      const _dev = window.location.href.includes('_mode=dev')
      if (_dev) return dayjs().add(20, 'second').startOf('second').valueOf()
      return dayjs().add(1, 'day').startOf('day').valueOf()
    })()
    localStorage.setItem(
      'nextRechargeCountDownTimeStamp',
      nextDayStart.toString(),
    )

    if (tempCountDown) {
      // 如果倒计时时间戳大于当前时间戳，就计算倒计时
      if (Number(tempCountDown) > nowTimeStamp) {
        return formatDateTime(Number(tempCountDown) - nowTimeStamp + '')
      } else {
        // 如果倒计时时间戳小于当前时间戳，说明已经倒计时结束，就重新设置一个倒计时时间戳
        const finishedTimeStamp = nowTimeStamp + getSeconds(DEFAULT_HMS) * 1000
        localStorage.setItem(
          'rechargeCountDownFinishedTimeStamp',
          finishedTimeStamp.toString(),
        )
        // 返回默认的时分秒
        return DEFAULT_HMS
      }
    } else {
      // 如果没有倒计时时间戳，就返回默认的时分秒
      return DEFAULT_HMS
    }
  } else {
    // 如果有下一次倒计时时间戳，且当前时间戳小于下一次倒计时时间戳，就计算倒计时
    if (tempCountDown) {
      if (nowTimeStamp < Number(nextCountDownTimeStamp)) {
        return formatDateTime(
          String((Number(tempCountDown) - nowTimeStamp) / 1000),
        )
      } else {
        return DEFAULT_HMS
      }
    } else {
      return DEFAULT_HMS
    }
  }
}

// 计算是否已经倒计时结束
export const calcIsCountDownFinished = () => {
  // 是否在指定时间内充值成功
  const isRechargeSuccessInLimitTime = localStorage.getItem(
    'isRechargeSuccessInLimitTime',
  )

  // 获取本地存储的倒计时时间戳
  const tempCountDown = localStorage.getItem(
    'rechargeCountDownFinishedTimeStamp',
  )
  // 获取本地存储的下一次倒计时时间戳
  const nextCountDownTimeStamp = localStorage.getItem(
    'nextRechargeCountDownTimeStamp',
  )
  const nowTimeStamp = new Date().getTime()
  if (!tempCountDown) return true
  if (nowTimeStamp < Number(nextCountDownTimeStamp)) {
    // 如果指定时间内充值成功，就不显示倒计时
    if (isRechargeSuccessInLimitTime) return true
    if (Number(tempCountDown) > nowTimeStamp) {
      return false
    } else {
      return true
    }
  } else {
    return false
  }
}

// 计算是否在指定时间内充值成功
export const calcIsRechargeSuccessInLimitTime = () => {
  // 0911 充值引导弹窗 新增默认时间内，如果充值成功，引导弹窗不显示倒计时
  const rechargeCountDownFinishedTimeStamp = localStorage.getItem(
    'rechargeCountDownFinishedTimeStamp',
  )
  const currentTimeStamp = Date.now()
  if (rechargeCountDownFinishedTimeStamp) {
    if (currentTimeStamp < Number(rechargeCountDownFinishedTimeStamp)) {
      localStorage.setItem('isRechargeSuccessInLimitTime', 'true')
    }
  }
}

export const clearAllTimers = () => {
  let id = window.setTimeout(() => {
    return
  }, 0)
  while (id--) {
    window.clearTimeout(id)
    window.clearInterval(id)
  }
}
