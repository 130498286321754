import { debug } from '@/common/logger'
import Platform from '@/common/platform'
import { isFromApp } from '@/common/platform/utils'

const win = window as any

function getItem(key: string): string | undefined {
  return win.localStorage.getItem(key)
}

function setItem(key: string, val: string): any {
  if (isFromApp()) {
    try {
      Platform?.setClientStorage(key, val)
    } catch (error) {
      debug(error)
    }
  }
  return win.localStorage.setItem(key, val)
}

function removeItem(key: string): any {
  if (isFromApp()) {
    try {
      Platform?.removeClientStorage(key)
    } catch (error) {
      debug(error)
    }
  }
  return win.localStorage.removeItem(key)
}

function clear(): any {
  if (isFromApp()) {
    try {
      Platform?.clearClientStorage()
    } catch (error) {
      debug(error)
    }
  }
  return win.localStorage.clear()
}

export const localStorage = {
  getItem,
  setItem,
  removeItem,
  clear,
}
