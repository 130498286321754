import type { TabListProps } from '@chakra-ui/react'
import { Tab, TabList, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'

import LazyImage from '@/components/lazy-image/LazyImage'
import { useLargerThanMobile } from '@/utils/hooks/useLargerThanMobile'

export type GameCategoryTabProps = { id: number; name: string; icon?: string }

interface GameCatagoryTabsProps extends TabListProps {
  items: GameCategoryTabProps[]
}

function GameCategoryTab(props: GameCategoryTabProps) {
  const { t } = useTranslation()
  const { name, icon } = props
  return (
    <Tab cursor='pointer' flexShrink='0'>
      <LazyImage
        src={icon}
        boxSize='var(--icon-size, 16px)'
        mr='2'
        filter='brightness(var(--icon-brightness, 1))'
      />
      <Text cursor='pointer'>{t(name)}</Text>
    </Tab>
  )
}

function GameCatagoryTabs(props: GameCatagoryTabsProps) {
  const isLargerThanMobile = useLargerThanMobile()
  const { items, ...restProps } = props
  if (isLargerThanMobile) {
    return (
      <TabList
        as={Swiper}
        key='GameCatagoryTabs'
        slidesPerView='auto'
        mx='0'
        {...restProps}
      >
        {items.map(({ id, name, icon }, index) => (
          <SwiperSlide key={id + '_' + index} style={{ width: 'max-content' }}>
            <GameCategoryTab id={id} name={name} icon={icon} />
          </SwiperSlide>
        ))}
      </TabList>
    )
  }

  return (
    <TabList
      key='GameCatagoryTabs'
      mx='0'
      overflowX='scroll'
      sx={{ '--scrollbar-width': '0px' }}
    >
      {items.map(({ id, name, icon = '' }, index) => (
        <GameCategoryTab
          key={id + '_' + index}
          id={id}
          name={name}
          icon={icon}
        />
      ))}
    </TabList>
  )
}

export default GameCatagoryTabs
