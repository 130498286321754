// 这里是底层的track接口，业务不要直接使用这些接口，而是使用index.ts中的接口

import { projectName, sdk } from '@/websites/current/property.json'

import { isProduction } from '../env'
import type { EventName, TrackData, TrackFn } from './const'
import { trackerFn as adjustTracker } from './trackers/adjust'
import { trackerFn as adjustPayTracker } from './trackers/adjustPay'
import {
  initAdjustWeb,
  initAdjustWebFromParams,
  trackerFn as adjustWeb,
} from './trackers/adjustWeb'
import { trackerFn as facebookTracker } from './trackers/facebook'
import { initFirebase, trackFn as firebaseTracker } from './trackers/firebase'
import { trackerFn as kwaiTracker } from './trackers/kwai'
import { trackerFn as tiktokTracker } from './trackers/tiktok'

const _commonData: TrackData = {}
const _trackers: Array<TrackFn> = []

const isEnableParamsProjects = ['B3', 'M1', 'M5']
const initFirebasePlatform = ['B3', 'M1']

export function initN1Tracker() {
  addTracker(facebookTracker)
  addTracker(kwaiTracker)
  addTracker(tiktokTracker)
  addTracker(adjustTracker)
  // 判断是否需要初始化adjustWeb
  if (sdk.ADJUST_WEB) {
    if (!window.AndroidWebView)
      initAdjustWeb(sdk.ADJUST_WEB, isProduction() ? 'production' : 'sandbox')
    addTracker(adjustWeb)
  }
}

export function init() {
  addTracker(facebookTracker)
  addTracker(kwaiTracker)
  addTracker(tiktokTracker)
  addTracker(adjustTracker)
  if (isEnableParamsProjects.includes(projectName)) {
    initAdjustWebFromParams()
    addTracker(adjustWeb)
  }
  if (initFirebasePlatform.includes(projectName)) {
    initFirebase(projectName as 'B3' | 'M1')
    addTracker(firebaseTracker)
  }

  addTracker(adjustPayTracker)
}

export function addTracker(fn: TrackFn) {
  _trackers.push(fn)
}

export function setCommonData(data: TrackData) {
  Object.assign(_commonData, data)
}

export function track(event: EventName, data?: TrackData) {
  for (const tk of _trackers) {
    tk(event, data)
  }
}
