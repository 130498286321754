import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react'
import QRCode from 'qrcode.react'
import { memo, useContext, useMemo } from 'react'

import DonationIcon from '@/assets/images/donation-icon.png'
import DownloadIcon from '@/assets/images/download-icon.png'
import liveChatIcon from '@/assets/images/livechat-icon.png'
import { isFeatureEnabled } from '@/common/feature'
import AtomBadge from '@/components/atom-badge/AtomBadge'
import { DownloadAppBtn } from '@/components/btn-wrapper/DownloadAppBtn'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  selectActionSystemConfig,
  selectServiceEntryConfig,
  toggleLiveChatWigetVisibility,
} from '@/modules/app-layout/app-layout-slice'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { selectLoginReward } from '@/modules/login-reward-modal/loginRewardSlice'
import { selectUser } from '@/modules/user/userSlice'
import { downloadApp } from '@/utils/download-app'
import { useMobile } from '@/utils/hooks'
import { useActionTrigger } from '@/utils/hooks/useActionTrigger'
import {
  getColorModeValue,
  handleS3SourceDomain,
  isFromApk,
} from '@/utils/tools'

import MoneyRainEntryButton from '../activity/money-rain/components/MoneyRainEntryButton'
import { selectMoneyRainStatus } from '../activity/money-rain/store/monyRainSlice'
import RechargePackageEntry from '../activity/RechargePackageEntry'
import {
  type FloatButton,
  HOME_HOVER_BTN_TYPE,
} from '../app/home-page-config-types'
import { HomePageConfigContext } from '../app-layout/AppLayout'
import { selectIsOpenActive } from '../bind-currency/currencySlice'

const textStyles = {
  fontSize: 12,
  lineHeight: '14px',
  fontWeight: 800,
  transform: {
    base: 'scale(0.625)',
    lg: 'scale(0.8)',
  },
  marginTop: {
    base: '-1',
    lg: '0',
  },
}

const btnStyles = {
  borderRadius: { base: '12', lg: '16' },
  w: { base: '12', lg: '16' },
  h: { base: '12', lg: '16' },
  padding: '1',
  display: 'grid',
  placeItems: 'center',
  gridTemplateRows: 'auto auto',
  cursor: 'pointer',
}

const iconBoxSize = {
  base: '5',
  lg: '6',
}

const rechargePackageV3 = isFeatureEnabled('rechargePackageV3')

const commonButtonStyles = (btn: FloatButton, btnBgDefault: string) => {
  return {
    bg: btn?.imageSrc
      ? `url(${handleS3SourceDomain(btn?.imageSrc)})`
      : btnBgDefault,
    bgSize: 'cover',
    bgPosition: 'center',
    bgRepeat: 'no-repeat',
    _hover: {
      bg: btn?.imageSrc
        ? `url(${handleS3SourceDomain(btn?.imageSrc)})`
        : btnBgDefault,
      bgSize: 'cover',
      bgPosition: 'center',
    },
    _active: {
      bg: btn?.imageSrc
        ? `url(${handleS3SourceDomain(btn?.imageSrc)})`
        : btnBgDefault,
      bgSize: 'cover',
      bgPosition: 'center',
    },
  }
}

{
  /* 每日签到按钮 */
}
function SignInButton({ signBtn }: { signBtn?: FloatButton }) {
  const isOpenActive = useAppSelector(selectIsOpenActive)
  const rewardStatus = useAppSelector(
    state => selectLoginReward(state).rewardStatus,
  )
  const dispatch = useAppDispatch()
  const isLogin = !!useAppSelector(selectUser)?.user

  const onOpenLoginReward = () => {
    if (isLogin) {
      dispatch(openModal({ key: GlobalModalKey.LoginReward }))
    } else {
      dispatch(openModal({ key: GlobalModalKey.Login }))
    }
  }

  return (
    <>
      {isOpenActive && (
        <Box position='relative'>
          {signBtn ? (
            <AtomBadge show={!rewardStatus} variant='round'>
              <Button
                onClick={() => onOpenLoginReward()}
                colorScheme='fill-prim'
                {...commonButtonStyles(
                  signBtn,
                  'linear-gradient(0deg, #FCE256 0%, #FFB067 100%)',
                )}
                position='relative'
                borderRadius='full'
                aria-label='loginReward'
                sx={btnStyles}
              />
            </AtomBadge>
          ) : (
            <AtomBadge show={!rewardStatus} variant='round'>
              <Button
                onClick={() => onOpenLoginReward()}
                colorScheme='fill-prim'
                bg='linear-gradient(0deg, #FCE256 0%, #FFB067 100%)'
                _hover={{
                  bg: 'linear-gradient(0deg, #FCE256 0%, #FFB067 100%)',
                }}
                _active={{
                  bg: 'linear-gradient(0deg, #FCE256 0%, #FFB067 100%)',
                }}
                position='relative'
                borderRadius='full'
                aria-label='loginReward'
                sx={btnStyles}
              >
                <LazyImage
                  src={DonationIcon}
                  mt='-12px'
                  boxSize={{
                    lg: '10',
                    base: '8',
                  }}
                />
                <Text
                  color={getColorModeValue('gray.900', 'gray.100')}
                  {...textStyles}
                >
                  <>
                    FREE
                    <br /> MONEY
                  </>
                </Text>
              </Button>
            </AtomBadge>
          )}
        </Box>
      )}
    </>
  )
}

// 客服按钮
function ServiceButton({ serviceBtn }: { serviceBtn?: FloatButton }) {
  const dispatch = useAppDispatch()
  const serviceButtonStyles = {
    sx: btnStyles,
    'aria-label': 'liveChat',
    onClick: () => dispatch(toggleLiveChatWigetVisibility()),
    ...(serviceBtn ? commonButtonStyles(serviceBtn, 'fill-seco') : {}),
  }
  return (
    <>
      {serviceBtn ? (
        <Button {...serviceButtonStyles} aria-label='liveChat' />
      ) : (
        <Button
          colorScheme='fill-seco'
          sx={btnStyles}
          onClick={() => dispatch(toggleLiveChatWigetVisibility())}
        >
          <LazyImage src={liveChatIcon} boxSize={iconBoxSize} />
          <Text color='white' sx={textStyles}>
            <>
              7*24
              <br />
              ONLINE
            </>
          </Text>
        </Button>
      )}
    </>
  )
}

function DownloadButtonContent({ button }: { button?: FloatButton }) {
  return (
    <DownloadAppBtn placement='left-start' eventName='download-app'>
      {button ? (
        <Button
          sx={btnStyles}
          {...commonButtonStyles(button, 'fill-prim')}
          aria-label='download'
          onClick={() => {
            downloadApp()
          }}
        />
      ) : (
        <Button
          colorScheme='fill-prim'
          sx={btnStyles}
          aria-label='download'
          onClick={() => {
            downloadApp()
          }}
        >
          <LazyImage src={DownloadIcon} boxSize={iconBoxSize} />
          <Text color='white' sx={textStyles}>
            DOWN
            <br />
            LOAD
          </Text>
        </Button>
      )}
    </DownloadAppBtn>
  )
}

// 下载按钮
function DownloadButton({ downloadBtn }: { downloadBtn?: FloatButton }) {
  const mobileResult = useMobile()
  if (!mobileResult && !isFromApk()) {
    return <DownloadButtonContent button={downloadBtn} />
  }
  return null
}

function RenderButton({ button }: { button: FloatButton }) {
  const isSignIn =
    button?.action?.eventName === 'open-global-modal' &&
    button?.action?.data?.key === 'LoginReward'

  const isDownApp = button?.action?.eventName === 'download-app'
  const { floatButtonServiceType } = useAppSelector(selectServiceEntryConfig)
  const dispatch = useAppDispatch()
  const actionTrigger = useActionTrigger()

  const onClick =
    isFeatureEnabled('newServiceList') &&
    button?.action?.eventName === 'service-list'
      ? () => {
          if (floatButtonServiceType === 'livechat') {
            dispatch(toggleLiveChatWigetVisibility())
          } else {
            dispatch(openModal({ key: GlobalModalKey.ServiceList }))
          }
        }
      : actionTrigger(button?.action || {})
  if (isSignIn) return <SignInButton signBtn={button} />
  if (isDownApp) return <DownloadButton downloadBtn={button} />

  return (
    <Button
      sx={btnStyles}
      {...commonButtonStyles(button, 'fill-prim')}
      aria-label='download'
      onClick={onClick}
    />
  )
}

function RenderButtonOld({ button }: { button: FloatButton }) {
  if (button.type === HOME_HOVER_BTN_TYPE.SIGN_IN)
    return <SignInButton signBtn={button} />
  if (button.type === HOME_HOVER_BTN_TYPE.LIVE_CHAT) {
    return <ServiceButton serviceBtn={button} />
  }
  if (button.type === HOME_HOVER_BTN_TYPE.DOENLOAD_APP) {
    return <DownloadButton downloadBtn={button} />
  }
  return null
}

function useHomePageConfigFloatButtons() {
  const homePageConfig = useContext(HomePageConfigContext)
  return homePageConfig?.floatButtons || []
}

function FloatButtons() {
  const homePageConfigFloatButtons = useHomePageConfigFloatButtons()

  const homePageConfigFloatButtonsMemo = useMemo(() => {
    return homePageConfigFloatButtons?.reduce((arrV: FloatButton[], curV) => {
      if (
        ![
          HOME_HOVER_BTN_TYPE.RECHARGE_ACTIVITY,
          HOME_HOVER_BTN_TYPE.RECHARGE_PACKAGE,
        ]?.includes(curV?.type)
      ) {
        arrV.push(curV)
      } else {
        const curIndex = arrV?.findIndex(item =>
          [
            HOME_HOVER_BTN_TYPE.RECHARGE_ACTIVITY,
            HOME_HOVER_BTN_TYPE.RECHARGE_PACKAGE,
          ]?.includes(item?.type),
        )
        if (curIndex < 0) {
          arrV.push({
            ...curV,
            rechargeActivity: [curV],
          })
        } else {
          arrV[curIndex] = {
            ...curV,
            rechargeActivity: [
              ...(arrV[curIndex]?.rechargeActivity || []),
              curV,
            ],
          }
        }
      }

      return arrV
    }, [])
  }, [homePageConfigFloatButtons])

  const moneyRainStatus = useAppSelector(selectMoneyRainStatus)
  return (
    <VStack
      position='fixed'
      right={['2.5', '2.5', '8']}
      bottom={{ base: '24', lg: '8' }}
      zIndex='overlay'
      alignItems='flex-end'
    >
      {!rechargePackageV3 ? <RechargePackageEntry /> : null}

      <MoneyRainEntryButton />
      {homePageConfigFloatButtonsMemo.length ? (
        homePageConfigFloatButtonsMemo.map(item => {
          return item.rechargeActivity ? (
            <RechargePackageEntry
              floatBtnQueue={item.rechargeActivity}
              key={item.id}
            />
          ) : (
            <RenderButton button={item} key={item.id} />
          )
        })
      ) : (
        <>
          <SignInButton />
          <DownloadButton />
          <ServiceButton />
        </>
      )}
    </VStack>
  )
}

const MemoFloatButtons = memo(FloatButtons)
export default MemoFloatButtons
