import { Button, Center, Text } from '@chakra-ui/react'
import { t } from 'i18next'

import TitledDialog from '@/components/modal/TitledDialog'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  closeModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'

function LoginAndSignUpConfigErrorModal() {
  const { open } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.LoginAndSignUpConfigError],
  )

  const dispatch = useAppDispatch()

  return (
    <TitledDialog
      title=''
      isOpen={open}
      onClose={() => {
        dispatch(closeModal({ key: GlobalModalKey.LoginAndSignUpConfigError }))
        window.location.reload()
      }}
      action={
        <Button
          colorScheme='seco'
          size='lg'
          w='full'
          onClick={() => {
            window.location.reload()
          }}
        >
          {t('PH_CONFIRM_BTN')}
        </Button>
      }
    >
      <Text textAlign='center'>{t('CONTECT_TIMEOUT')}</Text>
    </TitledDialog>
  )
}

export default LoginAndSignUpConfigErrorModal
