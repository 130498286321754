import type { SystemStyleObject, UseRadioProps } from '@chakra-ui/react'
import { Box, useRadio } from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'

type MinesRadioCardProps = PropsWithChildren &
  UseRadioProps & {
    sx?: SystemStyleObject
  }
export const MinesRadioCard: FC<MinesRadioCardProps> = ({ sx, ...rest }) => {
  const { getInputProps, getRadioProps } = useRadio(rest)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Box as='label'>
      <input {...input} />
      <Box {...checkbox} sx={sx}>
        {rest.children}
      </Box>
    </Box>
  )
}

export default MinesRadioCard
