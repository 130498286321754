import { Box, Flex, keyframes } from '@chakra-ui/react'
import { t } from 'i18next'
import { useMemo, useState } from 'react'

import CoinsPng from '@/assets/images/coins.png'
import { isFromApp } from '@/common/platform/utils'
import LazyImage from '@/components/lazy-image/LazyImage'
import { downloadApp } from '@/utils/download-app'
import { localStorage } from '@/utils/localStorage'
import { formatLocalMoney, getDeviceType } from '@/utils/tools'

import { useAppDispatch, useAppSelector } from '../app/store'
import { openModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { selectFirstLoginRewardInfo } from '../guide-event/guideEventSlice'
import { selectActionSystemConfig } from './app-layout-slice'

const BREATHE_ANIMATION_FRAMES = keyframes`
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.05);
    }
    75% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(1);
    }
  `

const FLOAT_CENTER_CONTAINER_STYLE = {
  position: 'fixed',
  bottom: '85px',
  display: 'flex',
  left: 0,
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  zIndex: 'overlay',
}
const FLOAT_CENTER_BUTTON_STYLE = {
  background: 'prim.500',
  borderRadius: '8px',
  padding: '9px 12px',
  color: 'white',
  lineHeight: '18px',
  animation: `${BREATHE_ANIMATION_FRAMES} 1.5s infinite linear`,
}

function ButtonContent({ deviceType }: { deviceType: 'ios' | 'android' }) {
  // 打开添加到主屏幕的弹窗
  const dispatch = useAppDispatch()

  /** app包配置 */
  const actionSystemConfig = useAppSelector(selectActionSystemConfig)

  /**
   * IOS配置
   */
  const iosConfig = useMemo(() => {
    return actionSystemConfig?.IOS || undefined
  }, [actionSystemConfig])

  // 首充活动信息
  const firstLoginRewardInfo = useAppSelector(state =>
    selectFirstLoginRewardInfo(state),
  )

  const openAddHomeScreenModal = () => {
    dispatch(
      openModal({
        key: GlobalModalKey.AddToHomeScreen,
        data: { position: 'float-center-button' },
      }),
    )
  }

  const firstLoginRewardInfoCfg = useMemo(() => {
    if (!firstLoginRewardInfo) {
      return undefined
    }
    return {
      biz_id: firstLoginRewardInfo?.reward?.biz_id || undefined,
      ...firstLoginRewardInfo?.task?.config,
      ...{
        reward:
          Number(firstLoginRewardInfo?.reward?.reward) ||
          Number(firstLoginRewardInfo.task?.config?.reward) ||
          (deviceType === 'android'
            ? firstLoginRewardInfo?.task?.config?.platform_reward?.[
                'android_app'
              ] || 0
            : firstLoginRewardInfo?.task?.config?.platform_reward?.[
                'ios_desktop'
              ] ||
              firstLoginRewardInfo?.task?.config?.platform_reward?.[
                'ios_app'
              ] ||
              0),
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoginRewardInfo])

  const handleClick = () => {
    if (
      !firstLoginRewardInfoCfg ||
      (firstLoginRewardInfoCfg &&
        deviceType === 'android' &&
        !firstLoginRewardInfoCfg.open_platform?.includes('android_app')) ||
      (deviceType === 'ios' &&
        !firstLoginRewardInfoCfg.open_platform?.includes('ios_desktop') &&
        !firstLoginRewardInfoCfg.open_platform?.includes('ios_app'))
    ) {
      if (deviceType === 'android') {
        downloadApp()
        return
      }

      // ios设备，打开添加到主屏幕的弹窗

      if (deviceType === 'ios') {
        if (iosConfig?.app_url) {
          dispatch(
            openModal({
              key: GlobalModalKey.IOSSecurityAwareness,
            }),
          )
          return
        }
        openAddHomeScreenModal()
      }
      return
    }
    dispatch(
      openModal({
        key: GlobalModalKey.IntroductionToDownloadModal,
        data: {
          firstLoginRewardInfo: {
            open_platform:
              firstLoginRewardInfo?.task?.config?.open_platform || [],
            user_is_recharge: !!firstLoginRewardInfo?.user_is_recharge,
            is_require_recharge:
              !!firstLoginRewardInfo?.task?.config?.is_require_recharge,
            biz_id: firstLoginRewardInfoCfg.biz_id,
            reward: firstLoginRewardInfoCfg.reward || 0,
            ...(firstLoginRewardInfoCfg.is_long_term_task
              ? {}
              : { endTime: (firstLoginRewardInfoCfg.task_end || 0) * 1000 }),
          },
        },
      }),
    )
  }

  return (
    <Box onClick={handleClick}>
      {firstLoginRewardInfoCfg ? (
        <Flex>
          <LazyImage
            position='absolute'
            bottom={1}
            left='8px'
            w='44px'
            h='39px'
            src={CoinsPng}
            objectFit='contain'
          />
          <Box w='48px' />
          {t('LOGIN_REWARD', {
            amount: formatLocalMoney(firstLoginRewardInfoCfg?.reward || 0),
          })}
        </Flex>
      ) : deviceType === 'ios' && !iosConfig?.app_url ? (
        t('MOBILE_IOS_COLLECT_TEXT')
      ) : (
        t('MOBILE_AND_COLLECT_NEWTEXT')
      )}
    </Box>
  )
}

function FloatCenterButtonContent({
  deviceType,
}: {
  deviceType: 'ios' | 'android'
}) {
  const [showButton] = useState(() => {
    // ios设备，已经点击过不再显示按钮，不显示该按钮
    return localStorage.getItem('NOT_SHOW_ADD_TO_HOME_SCREEN') ? false : true
  })
  return (
    <>
      {showButton ? (
        <Box position='fixed' sx={FLOAT_CENTER_CONTAINER_STYLE}>
          <Box sx={FLOAT_CENTER_BUTTON_STYLE} as='button'>
            <ButtonContent deviceType={deviceType as 'ios' | 'android'} />
          </Box>
        </Box>
      ) : null}
    </>
  )
}

function FloatCenterButton() {
  const deviceType = getDeviceType()
  //   桌面端 安卓首页图标  苹果首页图标  安卓app,都不显示该按钮
  if (
    [
      'pc',
      'android-desktop-icon',
      'ios-desktop-icon',
      'apk',
      'ios_app',
    ].includes(deviceType) ||
    isFromApp()
  ) {
    return null
  }

  const NOT_SHOW_ADD_TO_HOME_SCREEN = localStorage.getItem(
    'NOT_SHOW_ADD_TO_HOME_SCREEN',
  )

  //   ios设备，已经点击过不再显示按钮，不显示该按钮
  if (deviceType === 'ios' && NOT_SHOW_ADD_TO_HOME_SCREEN) return null

  return (
    <FloatCenterButtonContent deviceType={deviceType as 'android' | 'ios'} />
  )
}

export default FloatCenterButton
