// 最早执行的JS代码
import '@/styles/globals.css'
import '@/styles/ckeditor.css'
import 'react-datepicker/dist/react-datepicker.css'
import '@/styles/atom-date-picker.css'
import '@/common/locale/currency'

import queryString from 'query-string'

import i18n from '@/common/locale/i18n'
import { init } from '@/common/tracker'
import property from '@/websites/current/property.json'

// init tracker
init()

// debug i18n
const query = queryString.parse(window.location.search)
if (query.lang) {
  i18n.changeLanguage((query.lang as string) || property.locale.fallbackLocale)
}

const sp = new URLSearchParams(window.location.search)
if (sp.has('fromApk') || sp.has('fromIpa')) {
  localStorage.setItem('app_launch_search_string', window.location.search)
}
