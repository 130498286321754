import { useEffect } from 'react'

import { isFeatureEnabled } from '@/common/feature'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  openModal,
  setModalData,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { useUserAuth } from '@/utils/hooks'

import {
  useGetDrawCountQuery,
  useGetNextMoneyRainActQuery,
  useGetStartingMoneyRainActQuery,
} from '../api/moneyRainApi'
import {
  selectIsPlaying,
  selectMoneyRainStatus,
  selectStartingAct,
} from '../store/monyRainSlice'

function useMoneyRainRequest() {
  const isEnableMoneyRain = isFeatureEnabled('moneyRain')
  const { isFetching: staringActLoading } = useGetStartingMoneyRainActQuery(
    undefined,
    {
      skip: !isEnableMoneyRain,
    },
  )

  const [user_id, token] = useUserAuth()

  const startingAct = useAppSelector(selectStartingAct)

  const isPlaying = useAppSelector(selectIsPlaying)

  const dispatch = useAppDispatch()

  const { isUninitialized, isFetching: drawCountLoading } =
    useGetDrawCountQuery(
      {
        user_id,
        token,
        guid: startingAct?.guid,
      },
      {
        skip:
          !user_id ||
          !token ||
          !startingAct?.guid ||
          staringActLoading ||
          !isEnableMoneyRain,
      },
    )
  const { isFetching: nextActLoading } = useGetNextMoneyRainActQuery(
    undefined,
    {
      skip: !isEnableMoneyRain,
    },
  )

  const moneyRainStatus = useAppSelector(selectMoneyRainStatus)

  useEffect(() => {
    if (!isEnableMoneyRain) return
    if (isPlaying) return
    if (
      staringActLoading ||
      (!isUninitialized && drawCountLoading) ||
      nextActLoading
    )
      return
    // 如果当前所有的活动已经结束了，就不再弹出活动弹窗
    if (moneyRainStatus === 'allActEnd') return

    if (moneyRainStatus === 'startingAndNotDraw') {
      dispatch(
        openModal({
          key: GlobalModalKey.MoneyRain,
          data: {
            modalType: 'game',
            isOpen: true,
          },
        }),
      )
      return
    }

    if (moneyRainStatus !== 'actEnd') {
      dispatch(
        setModalData({
          key: GlobalModalKey.MoneyRain,
          data: {
            modalType: 'rules',
            isOpen: true,
          },
        }),
      )
    }
  }, [
    staringActLoading,
    isUninitialized,
    drawCountLoading,
    nextActLoading,
    moneyRainStatus,
    dispatch,
    isPlaying,
    isEnableMoneyRain,
  ])
}

export default useMoneyRainRequest
