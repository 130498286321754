import { useToast } from '@chakra-ui/react'
import type React from 'react'
import { useCallback } from 'react'

import { getVariable } from '@/common/env'
import { useAppDispatch } from '@/modules/app/store'

import { closeModal, openModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'

interface renderRecaptchaProps {
  node?: string | React.ReactNode
  recaptchaType?: 'hidden' | 'show'
}

let atom_widget_id = null as number | null
let businessCb = null as any
export const useGoogleRecaptcha = (type = 'register') => {
  const toast = useToast()
  const dispatch = useAppDispatch()

  /** 重置 */
  const resetRecaptcha = () => {
    atom_widget_id !== null && grecaptcha?.reset(atom_widget_id)
  }

  /** 关闭弹窗 */
  const closeGoogleRecaptcha = useCallback(() => {
    dispatch(closeModal({ key: GlobalModalKey.GoogleRecpatcha }))
    resetRecaptcha()
    businessCb = null
  }, [])

  /** 获取token成功 */
  const successCallback = (args: string) => {
    businessCb &&
      businessCb({
        status: 'success',
        token: args,
      })
    closeGoogleRecaptcha()
  }

  /** token过期 */
  const expiredCallback = function (args: string) {
    resetRecaptcha()
    toast({
      title: args || 'google expired',
      status: 'error',
    })
    businessCb &&
      businessCb({
        status: 'fail',
      })
  }

  /** 获取token失败 */
  const errorCallback = function (args: string) {
    resetRecaptcha()
    toast({
      title: args || 'google fail',
      status: 'error',
    })
    businessCb &&
      businessCb({
        status: 'fail',
      })
  }

  /** 渲染 recaptcha 元素*/
  const renderRaptchaElement = useCallback(
    ({ node, recaptchaType }: renderRecaptchaProps) => {
      const _node = node || 'atom-recaptcha'
      // 隐式recaptcha参数
      const sitekey =
        recaptchaType === 'show'
          ? getVariable('NEW_GOOGLE_RECAPTCHA_SITE_KEY')
          : getVariable('INVISIBLE_GOOGLE_RECAPTCHA_SITE_KEY')
      const params = {
        // 官方测试密钥
        // sitekey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
        sitekey: sitekey,
        callback: (args: string) => {
          successCallback(args)
        },
        'expired-callback': (args: string) => {
          expiredCallback(args)
        },
        'error-callback': (args: string) => {
          errorCallback(args)
        },
        isolated: false,
      }
      // 显式recaptcha参数
      recaptchaType === 'show' &&
        Object.assign(params, {
          theme: 'light', // light | dark
          size: 'normal', // normal: 长方形； compact： 正方形
        })
      if (!_node) {
        throw Error('google recaptcha must be wrap node Element')
      } else {
        try {
          atom_widget_id = grecaptcha?.render(_node, params)
        } catch (e) {
          const res = grecaptcha?.getResponse(atom_widget_id)
          if (res) {
            successCallback(res)
          }
          businessCb &&
            businessCb({
              status: 'fail',
            })
        }
      }
    },
    [],
  )

  /** 打开弹窗 */
  const openGoogleRecaptcha = useCallback(
    async ({ cb, robot }: { cb?: () => void; robot?: boolean }) => {
      businessCb = cb
      if (robot) {
        // 需要展示robot
        dispatch(
          openModal({
            key: GlobalModalKey.GoogleRecpatcha,
            data: { type },
          }),
        )
      } else {
        // 不需要展示robot
        renderRaptchaElement({})
        grecaptcha.execute()
      }
    },
    [dispatch, renderRaptchaElement, type],
  )

  /** render google recpatcha node */
  const renderGoogleRecaptcha = useCallback(
    (_node: string | React.ReactNode) => {
      renderRaptchaElement({
        node: _node,
        recaptchaType: 'show',
      })
    },
    [renderRaptchaElement],
  )

  return {
    renderGoogleRecaptcha,
    openGoogleRecaptcha,
    closeGoogleRecaptcha,
  }
}
