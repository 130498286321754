import { useMemo } from 'react'

import { useAppSelector } from '@/modules/app/store'
import {
  selectUserId,
  selectUserName,
  selectUserToken,
} from '@/modules/user/userSlice'

export const useUserAuth = () => {
  const userId = useAppSelector(selectUserId)
  const token = useAppSelector(selectUserToken)
  const userName = useAppSelector(selectUserName)
  return useMemo(() => [userId, token, userName], [userId, token, userName])
}
