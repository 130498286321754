const { href, search } = window.location
const searchParams = new URLSearchParams(search)
const from = searchParams.get('from')

const _isFromApk = href.indexOf('fromApk') !== -1 || from === 'apk'
_isFromApk && sessionStorage.setItem('fromApk', '1')

export const isFromApk = () => _isFromApk || sessionStorage.getItem('fromApk')

const _isFromIpa =
  window.location.href.indexOf('fromIpa') !== -1 || from === 'ipa'
_isFromIpa && sessionStorage.setItem('fromIpa', '1')

function createIsFrom(searchParam: string) {
  const _isFrom = window.location.search?.indexOf(searchParam) > -1
  if (_isFrom) {
    sessionStorage.setItem(searchParam, '1')
  }
  return () => _isFrom || sessionStorage.getItem(searchParam)
}

export const isFromVest = createIsFrom('fromVest')

export const isFromIpa = () => _isFromIpa || sessionStorage.getItem('fromIpa')

export const isFromApp = () => isFromIpa() || isFromApk()

export function judgeBigScreen() {
  let result = false
  const rate = window.screen.height / window.screen.width
  const limit = window.screen.height === window.screen.availHeight ? 1.8 : 1.65 // 臨界判斷值

  if (rate > limit) {
    result = true
  }
  return result
}
