import { Box, Button, CloseButton, Flex, Text } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'

import LazyImage from '@/components/lazy-image/LazyImage'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { getUserId } from '@/modules/user/storage'
import { selectUser } from '@/modules/user/userSlice'
import { useRechargeStatusMutation } from '@/modules/wallet/walletApi'
import {
  deleteVirtualOrder,
  getVirtualItemIndex,
  getVirtualOrderList,
  setCurrentVirtualItem,
  setVirtualItemIndex,
  setVirtualOrderList,
  switchVirtualModal,
} from '@/modules/wallet/walletSlice'
import { useMobile } from '@/utils/hooks'
import { localStorage } from '@/utils/localStorage'

import CountDown from './countDonw'
import {
  boxMobileStyle,
  boxStyle,
  textOneStyle,
  textTwoStyle,
} from './homeVirtualListStyle'
import type {
  FormatVirtualOrder,
  RechargeOrderStatusProps,
  TimerProps,
} from './interface'
import { useHomeVirtualList } from './useHomeVirtualList'

export function HomeVirtualList() {
  const dispatch = useAppDispatch()
  // const virtualOrderList = useAppSelector(getVirtualOrderList)
  const { list: virtualOrderList } = useHomeVirtualList()
  const isLogin = !!useAppSelector(selectUser)?.user
  const currentIndex = useAppSelector(getVirtualItemIndex)
  const isMobile = useMobile()
  const [getRechargeOrderStatus] = useRechargeStatusMutation()
  const openVirtualDetail = (item: FormatVirtualOrder, index: number) => {
    dispatch(setVirtualItemIndex({ index }))
    dispatch(setCurrentVirtualItem(item))
    dispatch(switchVirtualModal())
  }
  const [timer, setTimer] = useState<TimerProps>({})
  //这个订单超时或者其他原因
  const onFinish = (item: FormatVirtualOrder, index: number) => {
    dispatch(deleteVirtualOrder(index))
  }
  useEffect(() => {
    if (isLogin) {
      const _v = localStorage.getItem('virtualOrderList')
      const user_id = getUserId()
      const now = new Date().valueOf()
      if (_v !== null) {
        const list = JSON.parse(_v).filter(
          (item: FormatVirtualOrder) =>
            item.status === 'pending' &&
            item.user_id === user_id &&
            new Date(item.start_time).valueOf() > now,
        )
        dispatch(
          setVirtualOrderList({
            list: list,
            index: -1,
          }),
        )
      }
    }
    return () => {
      setTimer({})
    }
  }, [isLogin, dispatch])
  //
  const getOrderStatusById = useCallback(
    async (order_id: string, start_time: number) => {
      try {
        const intervalTimer = setInterval(async () => {
          const res: RechargeOrderStatusProps = await getRechargeOrderStatus({
            order_id,
          })

          const { code, data } = res?.data || {}
          const { state } = data || {}
          const now = new Date().valueOf()

          if (code === 200 && (state !== 'pending' || now > start_time)) {
            const index: number = virtualOrderList.findIndex(
              (item: FormatVirtualOrder) => item.order_id === order_id,
            )
            if (index >= 0) {
              try {
                const temp_list: FormatVirtualOrder[] = [...virtualOrderList]
                const status: string = now > start_time ? 'timeout' : state
                temp_list[index] = {
                  ...temp_list[index],
                  status,
                }
                dispatch(
                  setVirtualOrderList({
                    list: temp_list,
                    index: currentIndex,
                  }),
                )
                localStorage.setItem(
                  'virtualOrderList',
                  JSON.stringify([...temp_list]),
                )
                clearInterval(intervalTimer)
                setTimer({
                  ...timer,
                  [order_id]: '',
                })
              } catch (error) {
                throw new Error(`net work error`)
              }
            }
          }
        }, 10 * 1000)
      } catch (error) {
        throw new Error(`net work error`)
      }
    },
    [dispatch, currentIndex, timer, virtualOrderList, getRechargeOrderStatus],
  )
  //
  const allOrderStatus = useCallback(async () => {
    if (isLogin && virtualOrderList.length > 0) {
      for (let i = 0; i < virtualOrderList.length; i++) {
        const { order_id, status, start_time } = virtualOrderList[i]
        if (status === 'pending' && !timer[order_id]) {
          setTimer({
            ...timer,
            [order_id]: order_id,
          })
          await getOrderStatusById(order_id, start_time)
        }
      }
    }
  }, [virtualOrderList, isLogin, timer, getOrderStatusById])
  useEffect(() => {
    allOrderStatus()
  }, [allOrderStatus])

  const _style = isMobile ? boxMobileStyle : boxStyle
  return (
    <>
      {virtualOrderList.length > 0 &&
        isLogin &&
        virtualOrderList.map((item: FormatVirtualOrder, index: number) => {
          return (
            item.status === 'pending' && (
              <Flex
                {..._style}
                justifyContent='center'
                alignItems='center'
                key={item.order_id}
                top={isMobile ? '64px' : index * 100 + 220 + 'px'}
                zIndex={isMobile ? 3 : 2}
              >
                <LazyImage
                  src='/assets/images/spei-icon.png'
                  width='56px'
                  height='auto'
                />
                <Flex flex='1' flexDirection='column' padding='6px '>
                  <Text {...textOneStyle}>Tienes un pedido abierto</Text>
                  <Box {...textTwoStyle}>
                    <CountDown
                      start_time={item.start_time}
                      on_finish={() => {
                        //关闭当前订单
                        onFinish(item, index)
                      }}
                    />
                  </Box>
                </Flex>
                <Button
                  colorScheme='fill-prim'
                  variant='solid'
                  size='sm'
                  onClick={() => {
                    openVirtualDetail(item, index)
                  }}
                >
                  Continuar
                </Button>
                <CloseButton
                  top='1'
                  right='1'
                  size={{ base: 'md', md: 'sm' }}
                  position={{ base: 'static', md: 'absolute' }}
                  color='white'
                  onClick={() => {
                    //关闭当前订单
                    onFinish(item, index)
                  }}
                />
              </Flex>
            )
          )
        })}
    </>
  )
}

/**
 * 不要直接调用该组卷，应该可见的时候才渲染
 * 虽然没渲染，但里面的函数计算开销仍然走了一遍！
 *
 * @returns 优化渲染
 */
export default function NiceHomeVirtualList() {
  const { list: virtualOrderList } = useHomeVirtualList()

  const isLogin = !!useAppSelector(selectUser)?.user

  const isOpen = virtualOrderList.length > 0 && isLogin

  if (!isOpen) return null

  return <HomeVirtualList />
}
