import { getUserId, getUserToken } from '@/modules/user/storage'
import { api } from '@/services/api'
import property from '@/websites/current/property.json'

import type { BigAgentStat } from './types'

const availableCurrencies = property.locale.currencies

export const bigAgentApi = api.injectEndpoints({
  endpoints: builder => ({
    isBigAgent: builder.query<any, any>({
      query: () => ({
        url: `/api/v1/platform/asset_order/agent_white_list/is_bind`,
        method: 'POST',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
      providesTags: ['Auth'],
    }),
    agentCurrencyConfig: builder.query<any, any>({
      query: () => ({
        url: '/api/v1/platform/asset_order/agent_currency_config/currency_list',
        method: 'POST',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
      providesTags: ['Auth'],
    }),
    /** 获取所有账户钱 包信息 */
    agentAllAsset: builder.query<any, any>({
      query: () => ({
        url: '/user/get_all_asset',
        method: 'POST',
        data: {
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
      providesTags: ['Auth', 'Wallet'],
    }),
    /** 获取大代理统计信息 */
    agentStatInfo: builder.query<Record<any, BigAgentStat>, any>({
      query: () => ({
        url: `/api/affilate/statistics`,
        method: 'POST',
        data: {
          currencies: availableCurrencies,
          // token: getUserToken(),
          user_id: getUserId(),
        },
      }),
      providesTags: ['Auth'],
    }),
    /**
     * 大代理汇率转换
     */
    currencyExchange: builder.mutation<
      any,
      {
        from_currency: string
        to_currency: string
      }
    >({
      query: data => ({
        url: `/api/v1/platform/asset_order/agent_currency_config/currency_exchange`,
        method: 'POST',
        data: {
          ...data,
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
      invalidatesTags: ['Auth'],
    }),
    /**
     * 计算转汇结果
     */
    currencyExchangeResult: builder.mutation<
      any,
      {
        from_currency: string
        to_currency: string
        amount: number
      }
    >({
      query: data => ({
        url: `/api/v1/platform/asset_order/agent_currency_config/currency_exchange_calculate`,
        method: 'POST',
        data: {
          ...data,
          token: getUserToken(),
          user_id: getUserId(),
        },
      }),
    }),
  }),
})

export const {
  useIsBigAgentQuery,
  useAgentCurrencyConfigQuery,
  useAgentAllAssetQuery,
  useAgentStatInfoQuery,
  useCurrencyExchangeMutation,
  useCurrencyExchangeResultMutation,
} = bigAgentApi
