import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { useCallback, useMemo } from 'react'

import LazyImage from '@/components/lazy-image/LazyImage'
import { getIcon } from '@/utils/get-icon'
import { handleS3SourceDomain } from '@/utils/tools'

import { useAppDispatch, useAppSelector } from '../app/store'
import type { RechargSelectProps } from '../wallet/interface'
import { getRechargeTyp, setRechargeTyp } from './rechargeActSlice'

export default function SelectWayItem(props: RechargSelectProps) {
  const { icon, value, markImg } = props
  const typ = useAppSelector(getRechargeTyp)
  const dispatch = useAppDispatch()

  const isSelect = useMemo((): boolean => {
    return value === typ
  }, [typ, value])

  const selectClick = useCallback(() => {
    dispatch(setRechargeTyp(value))
  }, [dispatch, value])

  return (
    <Flex
      onClick={() => selectClick()}
      alignItems='center'
      justifyContent='center'
      height='64px'
      borderRadius='10px'
      backgroundColor={'gray.50'}
      cursor='pointer'
      position='relative'
      overflow='hidden'
      borderColor='prim.500'
      borderWidth='2px'
      p='6'
    >
      {(markImg as string) && (
        <LazyImage
          position='absolute'
          top='0'
          right='0'
          height='16px'
          src={markImg as string}
        />
      )}
      {icon ? (
        <LazyImage
          src={handleS3SourceDomain(icon ?? '')}
          objectFit='contain'
          w='full'
        />
      ) : (
        <Text cursor='pointer'>{value as string}</Text>
      )}

      {isSelect && (
        <>
          <Box
            position='absolute'
            borderTop='30px solid transparent'
            borderRightWidth='30px'
            borderRightColor='prim.500'
            right='0'
            bottom='0'
            width='0'
            height='0'
          />
          <Icon
            position='absolute'
            right='-2px'
            bottom='0'
            boxSize='5'
            color='white'
            as={getIcon('Reach')}
          />
        </>
      )}
    </Flex>
  )
}
