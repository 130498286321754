import { Button, Icon, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { GameGridEmpty } from '@/modules/my-games/GamesGrid'
import { Icon as SharedIcon } from '@/utils/atom-shared'

import type { GameListItem } from '../../gameInterface'
import type { Orientation } from '../../units/gamecard/AtomGameCard'
import { GRID_COLUMNS, GRID_MAX_SIZE } from '../constants'
import GameCardRender from '../game-list/GameCardRender'
import { isFeatureEnabledV2 } from '@/common/featureSwiitch'

function GameGridWithLoadMore(props: {
  list?: GameListItem[] | null
  gameQuantity?: number
  onItemClick?: (game: GameListItem) => void
  emptyProps?: {
    onButtonClick?: () => void
    emptyText?: string
  }
  gridMaxSize?: number
  viewAllHref: string
  coverOrientation?: Orientation
}) {
  const {
    list,
    onItemClick,
    viewAllHref,
    emptyProps,
    gridMaxSize = GRID_MAX_SIZE,
    coverOrientation,
    gameQuantity,
  } = props
  const { t } = useTranslation()
  if (!list) return null
  if (list.length === 0 && emptyProps) {
    const { emptyText, onButtonClick } = emptyProps
    return <GameGridEmpty emptyText={emptyText} onClick={onButtonClick} p='0' />
  }
  const isOptimizeDisplay = isFeatureEnabledV2('optimise_display_game_quantity') ? gameQuantity ?? GRID_MAX_SIZE : GRID_MAX_SIZE
  const listSlice = list.slice(0, gameQuantity)
  return (
    <>
      <SimpleGrid columns={GRID_COLUMNS} columnGap='3' rowGap='3'>
        {listSlice.map(item => (
          <GameCardRender
            key={item.game_id + item.game_name}
            info={item}
            onClick={onItemClick?.bind(null, item)}
            orientation={coverOrientation}
          />
        ))}
      </SimpleGrid>
      <Button
        as={RouterLink}
        size='mdsm'
        display='flex'
        mb={5}
        mx='auto'
        colorScheme='white'
        hidden={listSlice.length < isOptimizeDisplay}
        to={viewAllHref}
      >
        {t('HOME_LOAD_MORE')}
        <Icon as={SharedIcon.More} boxSize='5' ml='1' />
      </Button>
    </>
  )
}
export const GameGridWithLoadMoreMemo = React.memo(GameGridWithLoadMore)
