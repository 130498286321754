import { Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useEffect } from 'react'

import type { CountDownProps } from './interface'
import useCountDown from './useCountDown'

export default function CountDown(props: CountDownProps & { format?: string }) {
  // const { mss } = props
  const { start_time, on_finish, format = 'mm:ss' } = props
  const current = new Date().valueOf()
  //分钟
  const t = (start_time - current) / 1000
  const [time] = useCountDown({ mss: t })
  useEffect(() => {
    if (time <= 0) {
      on_finish && on_finish({})
    }
  }, [time, on_finish])
  return (
    <>
      <Text>{dayjs(time * 1000 <= 0 ? 0 : time * 1000).format(format)}</Text>
    </>
  )
}
