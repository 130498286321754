import EventEmitter from 'eventemitter3'

class Channel {
  _handlers: { [event: string]: Array<(data: any) => void> } = {}

  listen(event: string, callback: (data: any) => void): VoidFunction {
    if (this._handlers[event] === undefined) {
      this._handlers[event] = []
    }
    this._handlers[event].push(callback)
    return () => {
      const handlers = this._handlers[event]
      handlers.splice(handlers.indexOf(callback), 1)
    }
  }

  emit(event: string, data: any) {
    const handlers = this._handlers[event]
    if (handlers) {
      handlers.forEach(handler => handler(data))
    }
  }

  clearListeners() {
    this._handlers = {}
  }
}

interface BridgeConfig {
  gameSound?: boolean
  platform?: string
  [key: string]: any
}

export class GameBridge {
  gameName = ''
  config: BridgeConfig = {}

  uiEvent: Channel = new Channel()

  gameEvent: Channel = new Channel()

  onceEvent: EventEmitter = new EventEmitter()

  // eslint-disable-next-line @typescript-eslint/ban-types
  getTranslate: Function | undefined

  clear() {
    this.gameName = ''
    this.config = {}
    this.uiEvent.clearListeners()
    this.gameEvent.clearListeners()
    this.onceEvent.removeAllListeners()
  }
}

declare global {
  interface Window {
    __gameBridge__: GameBridge
  }
}

const gameBridge = new GameBridge()
window.__gameBridge__ = gameBridge

export default gameBridge
