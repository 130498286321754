import { keyBy } from 'lodash-es'
import { useCallback, useMemo } from 'react'

import { decimalFormat, getSign } from '@/common/locale/currency'
import { useAppSelector } from '@/modules/app/store'
import { useRankGameQuery } from '@/modules/game/api/gameInfoApi.ts'
import type { GameListItem } from '@/modules/game/gameInterface'
import { selectAllGMPGames, selectAllSelfGames } from '@/modules/game/gameSlice'
import useShushu from '@/utils/hooks/useShushu'
import fallbackIcon from '@/websites/current/public/icon.png'

import { useNavigateToGame } from '../../game/useNavigateToGame'
import type { RankGameItem } from './types'

const isInhouseRankGameItem = (item: RankGameItem) =>
  item?.game_id === item?.game

export function useTwentyFourHoursRank() {
  const gmpGameList = useAppSelector(selectAllGMPGames) as GameListItem[]
  const selfGameList = useAppSelector(selectAllSelfGames)

  const { data } = useRankGameQuery({ type: 1, size: 30 })
  const navigateToGame = useNavigateToGame()
  const { setShushuGameReportData } = useShushu()

  const items = useMemo(() => {
    let list = data?.data ?? []
    list = list.filter(item => !!item.game)
    return list
  }, [data?.data])

  const { gmpGameMap, selfGameMap } = useMemo(() => {
    const gmpGameMap = keyBy(gmpGameList, 'expand.game_uuid')
    const selfGameMap = keyBy(selfGameList, 'game_name')
    return { gmpGameMap, selfGameMap }
  }, [gmpGameList, selfGameList])

  const getGameListItem = useCallback(
    (item: RankGameItem): GameListItem | undefined => {
      const { game_id: gameId = '' } = item
      return (isInhouseRankGameItem(item) ? selfGameMap : gmpGameMap)[gameId]
    },
    [gmpGameMap, selfGameMap],
  )

  const onItemClick = useCallback(
    (item: RankGameItem) => {
      const gameListItem = getGameListItem(item)
      if (!gameListItem) return
      setShushuGameReportData({
        from: '24hour',
        game_id: item.game_id ?? '',
        game_name: item.game,
        manufacturer: 'inhouse | gmp',
      })
      navigateToGame(gameListItem)
    },
    [getGameListItem, setShushuGameReportData, navigateToGame],
  )

  const rankingData = useMemo(
    () =>
      items.map(item => {
        const gameListItem = getGameListItem(item)
        if (!gameListItem) return {}
        return {
          gameName: item.game,
          gameCover: getGameListItem(item)?.ranking_icon || fallbackIcon,
          amountFormated: `${getSign()} ${decimalFormat(Number(item.amount))}`,
          onClick: onItemClick.bind(null, item),
        }
      }),
    [getGameListItem, items, onItemClick],
  )
  return (rankingData ?? []).filter(item => !!item?.gameName).slice(0, 10)
}
