import {
  Box,
  CloseButton,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getVariable } from '@/common/env'
import { isFeatureEnabled } from '@/common/feature'
import { isFeatureEnabledV2 } from '@/common/featureSwiitch'
import { getCurrencyCode } from '@/common/locale/currency'
import { getSocket } from '@/common/ws'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  closeDrawerStart,
  DrawerKey,
} from '@/modules/app-layout/app-layout-slice'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import atomGtag, { setChannelPurchaseRatio } from '@/utils/gtag/atom-gtag'
import GAEvent from '@/utils/gtag/GAEvent'
import { useCustomizedStyle } from '@/utils/hooks'
import { formatLocalMoney, getDeviceType } from '@/utils/tools'

import { usePageChangeSubscription } from '../app-layout/usePageChangeNotification'
import { selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { useGetMyFirstLoginRewardQuery } from '../guide-event/guideEventApi'
import { selectUserId, selectUserToken } from '../user/userSlice'
import {
  useGetMessagesQuery,
  useMarkMessageAsReadMutation,
  useMarkMessageDeletedMutation,
} from './api'
import Message from './components/Message'
import NewMessage from './components/NewMessage/NewMessage'
import NewMessageButtonArea from './components/NewMessage/NewMessageButtonArea'
import NewMessageDetailModal from './components/NewMessage/NewMessageDetailModal'
import NewMessageReceiveModal from './components/NewMessage/NewMessageReceiveModal'
import NotificationEmpty from './components/NotificationEmpty'
import useClaimReward from './hooks/useClaimRewards'
import useDeleteNotification from './hooks/useDeleteNotification'
import type { Message as MessageType } from './types'

const MESSAGES_AMOUNT_FIELDS = [
  'dataaward_amount',
  'amount',
  'cash_back',
  'reward',
  'actual_amount',
  'fee_base',
]

export function parseMessageParams(params: Message['params']) {
  if (!params) {
    return undefined
  }
  return Object.keys(params).reduce((newParams, key) => {
    const newKey = key.includes('.') ? key.split('.').join('') : key
    const oldValue = params[key]
    newParams[newKey] = MESSAGES_AMOUNT_FIELDS.includes(newKey)
      ? formatLocalMoney(Number(oldValue))
      : oldValue
    return newParams
  }, {} as Record<string, string | number>)
}
const FIRST_LOGIN_REWARD = getVariable('FIRST_LOGIN_REWARD')

export default function Notification() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const userId = useAppSelector(selectUserId)
  const token = useAppSelector(selectUserToken)
  const toast = useToast()
  const [markMessageAsRead] = useMarkMessageAsReadMutation()
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const { data, refetch, isSuccess } = useGetMessagesQuery(
    { uid: userId, user_id: userId, token },
    { skip: !token || !userId },
  )
  const { refetch: rewardRefetch } = useGetMyFirstLoginRewardQuery(undefined, {
    skip:
      !FIRST_LOGIN_REWARD ||
      ((!token || !userId) &&
        !!isFeatureEnabled('new_app_login_reward_progress')),
  })
  const [markMessageDeleted] = useMarkMessageDeletedMutation()
  const { claimAllReward, isClaimingAll } = useClaimReward()
  const { handleDeleteNotificationForReaded, isReadedMsgDeling } =
    useDeleteNotification()

  const DEVICE = getDeviceType()
  const isAppOrDesktop = ['ios_app', 'ios-desktop-icon', 'apk'].includes(DEVICE)

  // Whether to enable the new notification version V2 required time 2024-02-27
  const isEnableNewNotificationV2 = isFeatureEnabled('newNotificationV2')

  const styles = useCustomizedStyle(
    'Notification',
    {
      root: {},
      title: {
        textStyle: 'h4',
        color: 'text.accent',
        textAlign: 'center',
      },
      itemConainer: {
        textStyle: 'text6',
      },
    },
    ['root', 'title', 'itemConainer'],
  )

  useEffect(() => {
    const { listen, unlisten } = getSocket()
    //new GA4 first recharge tracking base on WS message_push, not same at src\modules\user\userSlice.ts
    const handleMessagePush = (data: MessageType) => {
      if (!!Number(data?.is_first_recharge)) {
        atomGtag('event', GAEvent.FIRST_RECHARGE, {
          transaction_id: data?.params?.trans_id,
          currency: getCurrencyCode(),
          amount: setChannelPurchaseRatio(data?.params?.amount ?? 0),
        })
      }
      return refetch()
    }
    listen('messagePush', 'message_push', handleMessagePush)
    return () => {
      unlisten('messagePush', 'message_push')
    }
  }, [refetch])

  const handleCloseBtnClick = () => {
    dispatch(closeDrawerStart(DrawerKey.Right))
  }

  useEffect(() => {
    setIsFirstLoad(prev => {
      if (isSuccess && prev) return false
      return prev
    })
  }, [isSuccess])

  usePageChangeSubscription(() => {
    if (!isFirstLoad) {
      refetch()
    }
    if (isAppOrDesktop && isFeatureEnabledV2('new_app_login_reward_progress')) {
      rewardRefetch()
    }
  })

  // Remove the logic to filter out unread messages
  const messages: MessageType[] = useMemo(() => {
    const res = JSON.parse(JSON.stringify(data?.data || []))
    return res
      ?.sort(
        (a: MessageType, b: MessageType) =>
          (b?.created_at || 0) - (a?.created_at || 0),
      )
      .map((item: MessageType) => ({
        ...item,
        params: parseMessageParams(item.params),
      }))
  }, [data])

  const handleMarkMessageAsRead = async (
    e: React.MouseEvent<HTMLElement>,
    item: Message,
  ) => {
    e.preventDefault()
    if (!item.guid || item.is_read) {
      return
    }
    try {
      await markMessageAsRead({
        user_id: userId,
        token,
        guid: item.guid,
      }).unwrap()
      toast({ title: t('USER_READ_SIGNED'), status: 'success' })
    } catch (error) {
      toast({ title: t('USER_READ_SIGNED_FAIL'), status: 'error' })
    }
  }

  const handleDeleteMessage = async (
    e: React.MouseEvent<HTMLElement>,
    item: Message,
  ) => {
    e.preventDefault()
    if (!item.guid) {
      return
    }
    try {
      await markMessageDeleted({
        user_id: userId,
        token,
        guid: item.guid,
      }).unwrap()
      toast({ title: t('NOTIFY_MSG_DELETE_SUCCESS'), status: 'success' })
    } catch (error) {
      toast({ title: t('NOTIFY_MSG_DELETE_FAIL'), status: 'error' })
    }
  }

  const RedDot = () => {
    return (
      <Box
        position='absolute'
        top='-1'
        right='-1'
        w='3'
        h='3'
        borderRadius='50%'
        bg='red.500'
      />
    )
  }

  const OldVersionItemRender = ({ item }: { item: Message }) => {
    return (
      <Box
        borderRadius='lg'
        px='12px'
        py='18px'
        position='relative'
        bg='var(--item-bg)'
      >
        <IconButton
          colorScheme='unstyled'
          as={SharedIcon.Delete}
          aria-label='delete'
          position='absolute'
          cursor='pointer'
          top='2.5'
          right='2.5'
          boxSize='4'
          minW='4'
          color='var(--content-color)'
          onClick={e => handleDeleteMessage(e, item)}
        />
        {!item?.is_read ? <RedDot /> : null}
        <Message item={item} onClick={e => handleMarkMessageAsRead(e, item)} />
      </Box>
    )
  }

  const itemRender = (item: MessageType) => {
    const createTime = item.created_at ? item.created_at * 1000 : 0
    const createTimeFormatted = dayjs(createTime).format('MM/DD HH:mm:ss')

    return (
      <Box key={item.guid} sx={styles.itemConainer}>
        <Text
          textStyle='text6'
          textAlign='center'
          mb={['2.5', '2.5', '3']}
          color='var(--content-color)'
        >
          {createTimeFormatted}
        </Text>
        {!isEnableNewNotificationV2 ? (
          <OldVersionItemRender item={item} />
        ) : (
          <NewMessage message={item} />
        )}
      </Box>
    )
  }

  return (
    <VStack
      h='full'
      w='full'
      alignItems='stretch'
      bg='bg.appLayout.noticePanel'
      pt='env(safe-area-inset-top)'
      pb='10'
      spacing='0'
      overflow='auto'
      sx={{
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Flex
        position='relative'
        alignItems='center'
        justifyContent='center'
        pt={['4', '4', '7']}
        pb='5'
        flexShrink='0'
        sx={styles.root}
      >
        <Text sx={styles.title}>{t('MESSAGE_TITTLE')}</Text>
        <Tooltip
          label={(() => {
            return (
              <>
                <Text>{t('NOTIFY_RULE_01')}</Text>
                <Text>{t('NOTIFY_RULE_02')}</Text>
                <Text>{t('NOTIFY_RULE_03')}</Text>
              </>
            )
          })()}
          hasArrow
          shouldWrapChildren
          size='lg'
          sx={{
            width: 'full',
          }}
          colorScheme='white'
        >
          <IconButton
            icon={<Icon as={SharedIcon.Question} boxSize='4' />}
            aria-label='info'
            display='inline-block'
            variant='unstyledIcon'
            ml='2'
          />
        </Tooltip>

        <CloseButton
          position='absolute'
          top='2'
          right='2'
          variant='ghost'
          size='sm'
          onClick={handleCloseBtnClick}
        />
      </Flex>
      <VStack spacing='4' px='4' pb='16' alignItems='stretch' flex='1'>
        {messages.length === 0 ? (
          <NotificationEmpty />
        ) : (
          messages.map(itemRender)
        )}

        {isEnableNewNotificationV2 ? (
          <HStack w='full' h='44px' gap='12px' mb='16px' px='10px'>
            <NewMessageButtonArea
              receiveButtonClickCb={claimAllReward}
              deleteButtonClickCb={handleDeleteNotificationForReaded}
              delBtnLoading={isReadedMsgDeling}
              receiveBtnLoading={isClaimingAll}
              receiveButtonContext={t('MESSAGE_BUTTON1').toString()}
              deleteButtonContext={t('MESSAGE_BUTTON2').toString()}
            />
          </HStack>
        ) : null}
      </VStack>

      <NewMessageDetailModal />
      <NewMessageReceiveModal />
    </VStack>
  )
}
