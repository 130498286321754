import type { ImageProps } from '@chakra-ui/react'
import { AspectRatio, Image, Skeleton } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import type { LazyLoadProps } from 'react-lazyload'
import LazyLoad from 'react-lazyload'

import { handleS3SourceDomain } from '@/utils/tools'

export type LazyImageProps = ImageProps &
  LazyLoadProps & {
    noLazy?: boolean
    fallbackSrc?: string
  }

/**
 *
 * @param props
 *  - `once` 是否重复加载，默认为false，即只加载一次
 * - `debounce` 延迟加载时间 单位ms
 * - `offset` 偏移量 用于提前加载
 * - `height` 高度 用于占位
 * 更多参数请参考
 * https://github.com/twobin/react-lazyload
 * 其余参数同Image保持一致
 * @returns
 */
function LazyImage({
  noLazy = true,
  fallbackSrc = '',
  ...props
}: LazyImageProps) {
  const [imgSrc, setImgSrc] = useState(handleS3SourceDomain(props.src || ''))

  useEffect(() => {
    props.src && setImgSrc(handleS3SourceDomain(props.src || ''))
  }, [props.src])

  const handleError = () => {
    if (fallbackSrc && imgSrc !== fallbackSrc) {
      setImgSrc(fallbackSrc)
    }
  }

  return (
    <>
      {noLazy ? (
        <Image {...props} src={imgSrc} loading='lazy' onError={handleError} />
      ) : (
        <LazyLoad offset={10} height='auto' {...props}>
          <Image {...props} src={imgSrc} onError={handleError} />
        </LazyLoad>
      )}
    </>
  )
}

export default LazyImage

interface ImageWithLoadingProps {
  ratio: number
  src: string
  alt?: string
  noLazy?: boolean
}

export function ImageWithLoading({
  src,
  ratio,
  alt = src,
  noLazy = false,
  ...props
}: ImageWithLoadingProps & LazyImageProps) {
  const [isLoaded, setIsLoaded] = useState(false)

  return (
    <Skeleton isLoaded={isLoaded}>
      <AspectRatio ratio={ratio}>
        <LazyImage
          src={src}
          alt={alt}
          objectFit='cover'
          objectPosition='center'
          noLazy={noLazy}
          onLoad={() => setIsLoaded(true)}
          {...props}
        />
      </AspectRatio>
    </Skeleton>
  )
}
