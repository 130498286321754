import { lazy } from 'react'
import type { RouteObject } from 'react-router-dom'
import { createBrowserRouter } from 'react-router-dom'

import { isFeatureEnabled } from '@/common/feature'
import AtomErrorBoundary from '@/components/atom-error-boundary/AtomErrorBoundary'
import NotFound from '@/components/not-found/NotFound'
import CustomSuspense from '@/modules/app/CustomSuspense'
import AppLayout from '@/modules/app-layout/AppLayout'
import { ExtranalLayout } from '@/modules/extranal-layout/ExtranalLayout'
import Home from '@/modules/home/Home'
import AsyncVip from '@/modules/vip/AsyncVip'
import VipApiLayout from '@/modules/vip/VipApiLayout'
import { AuthRoute } from '@/routes/AuthRoute'

import { devRouter } from './dev'
import { gameRouter } from './game'
import { helpRouter } from './help'
import Loading from './Loading'
import { referralRouter } from './referral'
import { userRouter } from './user'

// 是否新的充值活动
const rechargeActivityV2 = isFeatureEnabled('rechargeActivityV2')

/** 是否新版活动中心 */
const isPromotionV3 = isFeatureEnabled('promotion_v3')

/** VIP 权益列表页面 */
const InterestStatement = lazy(
  () => import('@/modules/vip/pages/InterestStatement'),
)

const AsyncPromotions = lazy(
  () =>
    import(
      isPromotionV3
        ? '@/modules/promotions/PromotionsV3'
        : rechargeActivityV2
        ? '@/modules/promotions/PromotionsNew'
        : '@/modules/promotions/Promotions'
    ),
)
const GoShareLink = lazy(() => import('@/modules/app-layout/GoShareLink'))
const AsyncCashback = lazy(() => import('@/modules/cashback/Cashback'))
const AsyncCampaign = lazy(() => import('@/modules/campaign/Campaign'))
const AsyncActivity = lazy(() => import('@/modules/activity/Activity'))
const AsyncUnauthorized = lazy(
  () => import('@/modules/unauthorized/Unauthorized'),
)
const AsyncPrivatePolicy = lazy(() => import('@/modules/help/Privacy'))

const AsyncOldVip = lazy(() => import('@/modules/new-vip/NewVip'))

const allRouterList: RouteObject[] = [
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <AtomErrorBoundary />,
    children: [
      {
        path: '*',
        element: <NotFound />,
      },
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/campaign/:campaignId',
        element: (
          <CustomSuspense fallback={<Loading />}>
            <AsyncCampaign />
          </CustomSuspense>
        ),
      },
      // 自定义活动页
      {
        path: '/activity/:actType/:actId?',
        element: (
          <CustomSuspense fallback={<Loading />}>
            <AsyncActivity />
          </CustomSuspense>
        ),
      },
      {
        path: '/promotions',
        element: (
          <CustomSuspense fallback={<Loading />}>
            <AsyncPromotions />
          </CustomSuspense>
        ),
      },
      {
        path: '/areaError',
        element: (
          <CustomSuspense fallback={<Loading />}>
            <AsyncUnauthorized />
          </CustomSuspense>
        ),
      },
      {
        path: '/vip',
        element: <AuthRoute />,
        children: [
          {
            path: '',
            element: (
              <CustomSuspense fallback={<Loading />}>
                <AsyncVip />
              </CustomSuspense>
            ),
          },
          {
            path: '/vip/interest',
            element: (
              <CustomSuspense fallback={<Loading />}>
                <VipApiLayout>
                  <InterestStatement />
                </VipApiLayout>
              </CustomSuspense>
            ),
          },
        ],
      },
      {
        path: '/vipp',
        element: (
          <CustomSuspense fallback={<Loading />}>
            <AsyncOldVip />
          </CustomSuspense>
        ),
      },
      {
        path: '/cashback',
        element: (
          <CustomSuspense fallback={<Loading />}>
            <AsyncCashback />
          </CustomSuspense>
        ),
      },

      userRouter,
      ...referralRouter,
      ...devRouter,
      ...helpRouter,
      ...gameRouter,
      {
        path: '/go_sharelink',
        element: (
          <CustomSuspense fallback={<Loading />}>
            <GoShareLink />
          </CustomSuspense>
        )
      }
    ],
  },
  {
    path: '/extranal',
    element: <ExtranalLayout />,
    children: [
      {
        path: '/extranal/help/privacy',
        element: <AsyncPrivatePolicy />,
      },
    ],
  },
]

export const router = createBrowserRouter(allRouterList)
