import { lazy } from 'react'

import { isFeatureEnabledV2 } from '@/common/featureSwiitch'

const OldVip = lazy(() => import('@/modules/new-vip/NewVip'))
const NewVip = lazy(() => import('@/modules/vip/Vip'))

export default function AsyncVip() {
  const vipV3 = isFeatureEnabledV2('vip_v3_opt')
  return vipV3 ? <NewVip /> : <OldVip />
}
