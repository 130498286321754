import {
  Box,
  Checkbox,
  CloseButton,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import { clamp } from 'lodash-es'
import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import type {
  ModalConfigProps,
  RecommendContentProps,
} from '@/modules/auto-modal/ModalInterface'
import {
  selectModalConfig,
  selectRecommendContent,
} from '@/modules/auto-modal/ModalSlice'
import { setModalStorage, useModalConfig } from '@/modules/auto-modal/useModal'
import {
  selectUser,
  selectUserRegisterTime,
  selectVipLevel,
} from '@/modules/user/userSlice'
import { useUserAuth } from '@/utils/hooks'
import { useActionTrigger } from '@/utils/hooks/useActionTrigger'
import { getConfigLng, handleS3SourceDomain } from '@/utils/tools'
import appConfig from '@/websites/current/pages/layout/app-config.json'

import { useAppDispatch, useAppSelector } from '../app/store'
import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'

const { splashAds } = appConfig

const lng = getConfigLng()

function SplashAdsModal() {
  const bannerStyle = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0px',
    left: '0px',
    padding: '155px 0px 125px 0px',
    overflowY: 'hidden',
  }
  const [userId] = useUserAuth()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState(false)
  const isLoggedIn = useAppSelector(state => selectUser(state).isLoggedIn)

  const {
    open: openSplashAds,
    data,
    extra,
  } = useAppSelector(state => selectModal(state)[GlobalModalKey.SplashAds])

  const recommendContentList = useAppSelector(selectRecommendContent)

  const actionTrigger = useActionTrigger()

  // 用户VIP等级
  const vipLevel = useAppSelector(selectVipLevel)

  // 用户注册时间
  const regiaterTime = useAppSelector(selectUserRegisterTime)

  const { onCloseModal } = useModalConfig()
  const modalConfig = useAppSelector(selectModalConfig)

  const curModalCfg: ModalConfigProps = useMemo(() => {
    if (extra?.isManu) {
      // 用户手动触发
      const content = []
      recommendContentList?.forEach(item => {
        if (item?.status === 1) {
          content.push({
            language_content: item.language_content,
            action: item.action || {},
          })
        }
      })
      return {
        content,
      }
    }
    return modalConfig.find(item => item.type === 2) as ModalConfigProps
  }, [modalConfig, data, recommendContentList])

  const size = useMemo(() => {
    const sizes = ['md', 'lg', '3xl']
    return sizes[clamp(splashAds.length - 1, 0, sizes.length - 1)]
  }, [])

  const handleClose = useCallback(() => {
    dispatch(closeModal({ key: GlobalModalKey.SplashAds }))
    onCloseModal(GlobalModalKey.SplashAds)
  }, [dispatch, onCloseModal])

  const onChangeCheckbox = (checked: boolean) => {
    setModalStorage(userId, curModalCfg?.id || '', checked ? 1 : 0)
  }

  const onClose = () => {
    onCloseModal(GlobalModalKey.SplashAds, true)
  }

  useEffect(() => {
    // 用户手动打开
    if (data?.isManu) {
      setIsVisible(true)
      return
    }
    // 进入平台自动打开
    const { visible_user } = curModalCfg || {}
    if (visible_user?.type === 2) {
      // 所有用户可见
      setIsVisible(true)
    } else {
      // 其他符合条件的用户可见
      if (isLoggedIn) {
        if (visible_user?.type === 1) {
          // 新用户可见
          const { max_register_day, min_register_day } = visible_user as any
          const regiater_time = +new Date(regiaterTime)
          const curTime = +new Date()
          const days = (curTime - regiater_time) / (1000 * 60 * 60 * 24)
          const flag = days >= min_register_day && days <= max_register_day
          setIsVisible(flag)
          !flag && handleClose()
        } else if (visible_user?.type === 3) {
          // VIP用户可见
          const { max_vip_level, min_vip_level } = visible_user as any
          const flag = vipLevel >= min_vip_level && vipLevel <= max_vip_level
          setIsVisible(flag)
          !flag && handleClose()
        }
      } else {
        handleClose()
      }
    }
  }, [
    curModalCfg,
    isLoggedIn,
    vipLevel,
    regiaterTime,
    extra,
    recommendContentList,
  ])

  return (
    <Modal
      autoFocus={false}
      trapFocus={false}
      isOpen={openSplashAds && isVisible}
      onClose={handleClose}
      isCentered
      size={size}
    >
      <ModalOverlay />
      <ModalContent
        bg='actDialog.bg'
        color='announceDialog.announceModalColor'
        textAlign='center'
        width='100vw'
        maxWidth='375px'
        display={'flex'}
        flexDirection={'column'}
        position={'relative'}
        paddingBottom={'20px'}
        sx={{ boxShadow: 'none' }}
      >
        <CloseButton
          position='absolute'
          right='3'
          top='3'
          zIndex={10}
          onClick={handleClose}
          color='announceDialog.closeBtnColor'
        />
        <Image
          src={'/assets/images/act/bg.webp'}
          alt=''
          borderRadius='2xl'
          loading='lazy'
          minHeight={'560px'}
        />
        <Box sx={bannerStyle}>
          {(curModalCfg?.content?.length || 0) > 0 && (
            <Box
              width={'100%'}
              height={'100%'}
              overflowY={'auto'}
              sx={{ '--scrollbar-width': '0px' }}
            >
              {curModalCfg?.content?.map(
                (item: RecommendContentProps, index) => {
                  const language_content = item.language_content
                  const lang = lng
                  const obj = language_content[lang]
                  if (!obj?.banner_url) return null
                  return (
                    <Box
                      width='100%'
                      cursor={'pointer'}
                      onClick={actionTrigger(item?.action, onClose)}
                      key={index}
                    >
                      <Image
                        width='280px'
                        height={'120px'}
                        margin={'auto'}
                        src={handleS3SourceDomain(obj?.banner_url || '') || ''}
                        alt=''
                        loading='lazy'
                      />
                    </Box>
                  )
                },
              )}
            </Box>
          )}
        </Box>
        {curModalCfg?.can_hide === 1 && (
          <Box
            width={'100%'}
            position={'absolute'}
            bottom={'0px'}
            height='50px'
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Checkbox
              variant='actModal'
              onChange={e => onChangeCheckbox(e.target.checked)}
            >
              {t('NOT_REMIND')}
            </Checkbox>
          </Box>
        )}
      </ModalContent>
    </Modal>
  )
}

const HomeSplashAdsModal: FC = () => {
  const { pathname } = useLocation()
  if (pathname === '/') {
    return <SplashAdsModal />
  } else {
    return null
  }
}

export default SplashAdsModal
