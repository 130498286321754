import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react'
import type { ReactNode } from 'react'
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { getVariable } from '@/common/env'
import type { AtomOption } from '@/components/atom-select/AtomSelect'
import AtomSelect from '@/components/atom-select/AtomSelect'
import type { DynamicFormsOption, Forms } from '@/components/forms/types'
import { FormType } from '@/components/forms/types'

import validator from './vaildator'

const PHONE_AREA_CODE = getVariable('PHONE_AREA_CODE') || '+55'

interface DynamicFormsProps {
  dForms?: DynamicFormsOption[]
  onChange: (key: string, value: string) => void
}

// eslint-disable-next-line react/display-name,, react/prop-types
const DynamicForms = forwardRef((props: DynamicFormsProps, ref) => {
  const { dForms = [], onChange } = props
  const { t } = useTranslation()

  const initForms = useMemo(
    () =>
      dForms.reduce((acc, cur) => {
        acc[cur.key] = {
          value: '',
          type: cur.type as unknown as FormType,
          error: null,
          valid: true,
          validate: cur.validate,
        }
        return acc
      }, {} as Forms),
    [dForms],
  )

  const [forms, setForms] = useState<Forms>({})

  useEffect(() => {
    setForms(initForms)
  }, [initForms])

  const validate = useCallback(
    (name: string) => (val: string) => {
      const { type, validate } = forms[name]
      let error = null
      if (type === FormType.text) {
        error = validator.text(val, validate)
      } else if (type === FormType.number) {
        error = validator.number(val, validate)
      }
      return error
    },
    [forms],
  )

  const handleChange = useCallback(
    (name: string) => (value: string) => {
      const error = validate(name)(value)
      setForms(prevState => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value,
          valid: !error,
          error,
        },
      }))

      if (typeof onChange === 'function') {
        onChange(name, value)
      }
    },
    [validate, onChange],
  )

  const isValid = useCallback(
    (name: string) => () => {
      let error: null | string = null
      setForms(prevState => {
        error = validate(name)(prevState[name].value)
        return {
          ...prevState,
          [name]: {
            ...prevState[name],
            valid: Boolean(error),
            error,
          },
        }
      })
      return error ? 1 : 0
    },
    [validate],
  )

  useImperativeHandle(ref, () => ({
    isValid: () => Object.keys(forms).every(key => Boolean(isValid(key)())),
  }))

  return (
    <Box>
      {dForms.map(item => {
        const formItem = forms[item.key.toLowerCase()]
        if (!formItem) return
        return (
          <FormItem
            key={`${item.form_id}-${item.key}`}
            type={item.type as unknown as FormType}
            label={t(item.label || '').toString()}
            value={formItem.value}
            placeholder={item.place_holder && t(item.place_holder).toString()}
            onChange={handleChange(item.key)}
            isGroup={Boolean(item.group)}
            isRequired={item.validate?.required}
            isInvalid={!formItem.valid}
            error={formItem.error ? t(formItem.error).toString() : null}
            options={item?.options?.map(opt => ({
              value: opt.value,
              label: opt.label ?? opt.title,
            }))}
          />
        )
      })}
    </Box>
  )
})

interface FormItemProps {
  type?: FormType
  value?: string
  label?: string
  onChange(value: string): void
  options?: AtomOption<string>[]
  isRequired?: boolean
  isInvalid?: boolean
  isDisabled?: boolean
  isReadOnly?: boolean
  isGroup?: boolean
  error?: string | null
  placeholder?: string
  showPhoneAreaCode?: boolean
  showCurrency?: boolean
  currencySymbol?: string
  hideLabel?: boolean
  preStyle?: Record<string, any>
  labelHelp?: ReactNode
}

export function FormItem(props: FormItemProps) {
  const {
    type,
    value,
    label,
    onChange,
    options = [],
    isRequired,
    isDisabled,
    isReadOnly,
    isInvalid,
    isGroup,
    error,
    placeholder,
    showPhoneAreaCode = false,
    showCurrency = false,
    hideLabel = false,
    currencySymbol = '',
    preStyle = {},
    labelHelp = undefined,
  } = props

  const style = {}
  showCurrency &&
    Object.assign(style, {
      textAlign: 'right',
      paddingRight: '15px',
      paddingLeft: '0',
    })

  let inputElem: React.ReactNode = null

  if (type === FormType.number || type === FormType.text) {
    inputElem = (
      <>
        <InputGroup>
          {showPhoneAreaCode && (
            <InputLeftElement
              pointerEvents='none'
              color='text.accent'
              fontSize='14px'
              fontWeight='600'
              top='0px'
              lineHeight='46px'
              height='46px'
              left='4px'
            >
              {PHONE_AREA_CODE}
            </InputLeftElement>
          )}
          {showCurrency && (
            <InputLeftElement
              pointerEvents='none'
              color='text.accent'
              fontSize='14px'
              fontWeight='600'
              top='0px'
              lineHeight='46px'
              height='46px'
              left='4px'
              {...preStyle}
            >
              {currencySymbol}
            </InputLeftElement>
          )}
          <Input
            value={value}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder}
            padding={showPhoneAreaCode || showCurrency ? '0 42px' : ''}
            {...style}
          />
        </InputGroup>
      </>
    )
  }

  if (type === FormType.select) {
    // 定义自定义样式
    inputElem = (
      <AtomSelect
        hasBorder
        options={options}
        isSearchable
        value={options?.find(opt => opt.value === value)}
        onChange={newValue => newValue && onChange(newValue.value)}
      />
    )
  }

  if (!inputElem || isGroup) {
    return null
  }

  return (
    <FormControl
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
    >
      {!hideLabel && (
        <FormLabel color='text.secondary' mt='4' mr='0'>
          <Flex justifyContent='space-between' alignItems='center'>
            <Text
              as='span'
              _before={{
                content: isRequired ? '"*"' : '""',
                display: 'inline-block',
                w: '3',
                lineHeight: '0.5',
                verticalAlign: 'middle',
                color: 'red.500',
              }}
            >
              {label}
            </Text>
            {labelHelp}
          </Flex>
        </FormLabel>
      )}
      {inputElem}
      {/* h='3' */}
      <FormErrorMessage textAlign='end'>{error}</FormErrorMessage>
    </FormControl>
  )
}

const DynamicFormsMemoed = memo(DynamicForms)

export default DynamicFormsMemoed
