import { Box, Flex, Icon, Image, Text } from '@chakra-ui/react'
import { t, use } from 'i18next'
import { useEffect, useState } from 'react'

// import MoneyRain from '@/assets/images/money-rain/money_rain.webp'
// import TopNotification from '@/assets/images/money-rain/top_notification.png'
// import TopNotificationPhilucky from '@/assets/images/money-rain/top_notification_p2.png'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { projectName } from '@/websites/current/property.json'

import { useAppDispatch, useAppSelector } from '../app/store'
import { openModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'

function MoneyRainBanner() {
  const [show, setShow] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setShow(true)
  }, [])
  return (
    <>
      {show ? (
        <Flex
          bg={`url(/assets/images/money-rain/top_notification.png)`}
          bgPos='center'
          bgSize='cover'
          w={{ base: 'full', md: '375px' }}
          position={{ base: 'relative', md: 'fixed' }}
          right={{ base: '0', md: '12' }}
          alignItems='center'
          zIndex='1000'
          h='72px'
          cursor='pointer'
          lineHeight='20px'
          mt={{
            base: '0',
            md: show ? '0' : '4',
          }}
          borderRadius={{ base: '0', md: 'lg' }}
        >
          <Flex
            alignItems='center'
            onClick={() => {
              dispatch(
                openModal({
                  key: GlobalModalKey.MoneyRain,
                  data: {
                    modalType: 'game',
                    isOpen: true,
                  },
                }),
              )
            }}
          >
            {/* <Image src={MoneyRain} w='87px' h='72px' objectFit='cover' /> */}
            <Text
              bg='linear-gradient(90deg, #FFF 3.03%, #FFE870 98.48%)'
              transform='skew(-8.8deg)'
              backgroundClip='text'
              fontWeight='bold'
              w='244px'
              ml='80px'
            >
              {t('EVENT_CASHRAIN_WINDOW_TOP')}
            </Text>
          </Flex>
          <Icon
            as={SharedIcon.Close}
            right='5'
            top='5'
            fontWeight='bold'
            w='5'
            h='5'
            position='absolute'
            zIndex='9999'
            color='#fff'
            onClick={() => {
              setShow(false)
            }}
          />
        </Flex>
      ) : null}
    </>
  )
}

function CustomTopBanner() {
  return <MoneyRainBanner />
}

export default CustomTopBanner
