import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from '@chakra-ui/react'
import { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { CustomizedFormErrorMessage } from '@/modules/new-login/components/CustomizedFormErrorMessage'

import type { AtomOption } from '../atom-select/AtomSelect'
import AtomSelect from '../atom-select/AtomSelect'
// import { useLoginAndSignUpStyles } from '@/modules/login/context/login-sign-context'
import type { FormItemProps } from './Form'

const FormItem: React.FC<
  FormItemProps & {
    hasBorder?: boolean
    isSearchable?: boolean
    options?: AtomOption<string>[]
    fieldProps?: {
      placeholder?: string
      size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
    }
  }
> = ({
  label,
  name = '',
  value,
  onChange,
  registerFormItem,
  unregisterFormItem,
  updateFormItemError,
  error,
  options,
  rules,
  hasBorder,
  isSearchable,
  fieldProps,
  isRequired,
}: FormItemProps & {
  hasBorder?: boolean
  isSearchable?: boolean
  options?: AtomOption<string>[]
  fieldProps?: {
    placeholder?: string
    size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  }
}) => {
  // const loginAndSignUpStyles = useLoginAndSignUpStyles()
  const { t } = useTranslation()
  const isInit = useRef(true)

  const handleChange = (newVal: string) => {
    onChange?.(name, newVal)
  }

  const validate = useCallback(async () => {
    let errorMsg = ''

    if (rules?.length) {
      for (const rule of rules) {
        try {
          if (rule?.validator) {
            await rule.validator(value)
          } else if (rule?.required) {
            if (!value) {
              throw new Error(rule?.message || 'is empty'.toString())
            }
          }
        } catch (error: any) {
          errorMsg = error.message
          // 处理抛出的错误，可以在此加入您的逻辑
          break // 终止循环
        }
      }
    }

    if (errorMsg) {
      updateFormItemError?.(name, t(errorMsg)) // 更新错误信息
      return false
    }

    updateFormItemError?.(name, '')
    return true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const isInvalid = useMemo(() => !!error, [error])

  useEffect(() => {
    registerFormItem?.(name, validate)
    return () => {
      unregisterFormItem?.(name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate])

  useEffect(() => {
    !isInit.current && validate()
    isInit.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const optionsArr = useMemo(() => {
    return options?.map(opt => ({
      value: opt.value,
      label: opt.label || opt.value,
    }))
  }, [options])

  return (
    <FormControl isInvalid={isInvalid} minH={label ? '92px' : '64px'}>
      {label && (
        <FormLabel color='text.secondary' htmlFor=''>
          <Text
            as='span'
            _before={{
              content: isRequired ? '"*"' : '""',
              display: 'inline-block',
              w: '3',
              lineHeight: '0.5',
              verticalAlign: 'middle',
              color: 'red.500',
            }}
          >
            {label}
          </Text>
        </FormLabel>
      )}
      <AtomSelect
        hasBorder={hasBorder}
        options={optionsArr}
        isSearchable={isSearchable}
        value={optionsArr?.find(opt => opt.value === value)}
        onChange={newValue => newValue && handleChange(newValue.value)}
        {...fieldProps}
      />
      <FormErrorMessage textAlign='end'>{error}</FormErrorMessage>
    </FormControl>
  )
}

const SelectFormItem = memo(FormItem)
SelectFormItem.displayName = 'FormItem'
export default SelectFormItem
