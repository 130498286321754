import {
  Box,
  Button,
  Highlight,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { t } from 'i18next'
import { useCallback } from 'react'
import { formatLocalMoney } from '@/utils/tools'
import jb2 from '@/assets/images/login-reward-modal/5-point.png'
import { getSign } from '@/common/locale/currency'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  closeModal,
  openModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useCustomizedStyle } from '@/utils/hooks'
import useExchangeCurrency from '@/utils/hooks/useExchangeCurrency'
import { useInternalDefaultProps } from '@/utils/hooks/useInternalDefaultProps'
import { formatNumberToK } from '@/common/locale/currency'

export type SuccessModalData =
  | number
  | {
      amount: number
      tips?: string | null
    }

export default function NewLoginRewardModal() {
  const dispatch = useAppDispatch()
  const { formatValue } = useExchangeCurrency()
  const { open, data = 0 } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.newLoginRewardModal],
  )
  const handleClose = useCallback(() => {
    dispatch(closeModal({ key: GlobalModalKey.newLoginRewardModal }))
  }, [dispatch])

  const handleConfirm = useCallback(() => {
    dispatch(openModal({ key: GlobalModalKey.Wallet }))
  }, [])
  if (!open) return null

  return (
    <SuccessModalPresentational
      isOpen={open}
      onClose={handleClose}
      onConfirm={handleConfirm}
      title={t('CONTINUOUS_LOGIN_POPUP_TITTLE1')}
      data={data}
      confirmBtnText={t('COMMON_CONFIRM')}
    />
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const NAME = 'newLoginRewardModal'
// eslint-disable-next-line react-refresh/only-export-components
export const PARTS = ['content']

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  title?: string | null
  content?: string | null
  tips?: string | null
  tipsType?: string
  confirmBtnText?: string | null
  data?: any
}

/**
 * @description: SuccessModalPresentational component for SuccessModal UI display
 */
function SuccessModalPresentational(props: Props) {
  const {
    isOpen,
    onClose,
    onConfirm,
    title = '',
    data,
    tips = '',
    confirmBtnText,
    tipsType,
  } = props
  const styles = useCustomizedStyle(
    NAME,
    {
      content: {
        layerStyle: 'ls-accent',
      },
    },
    PARTS,
  )

  const { formatValue } = useExchangeCurrency()
  const dispatch = useAppDispatch()

  const internalDefaultProps = useInternalDefaultProps(NAME, {
    confirmBtn: { colorScheme: 'fill-reward-success-confirm' },
  })
  const amount1 = `${formatLocalMoney(data?.rechargeAmount)}`
  const amount2 = `${formatLocalMoney(data?.amount)}`
  const amount3 = `${formatLocalMoney(data?.rechargeAmount)}`
  const amount4 = `${formatLocalMoney(data?.rewardAmount)}`
  const amount5 = `${formatLocalMoney(data?.nextDateRewardAmount)}`
  const tipsRender = () => {
    if (!tips) return null
    if (tipsType === 'html') {
      return (
        <Text
          textStyle='text6'
          mt='4'
          sx={{
            a: { color: 'four.500', textDecoration: 'underline' },
          }}
          dangerouslySetInnerHTML={{ __html: tips }}
        />
      )
    } else {
      return (
        <Text textStyle='text6' mt='4'>
          {tips}
        </Text>
      )
    }
  }
  const handleClose = () => {
    if (data?.isEventRule) {
      dispatch(closeModal({ key: GlobalModalKey.newLoginRewardModal }))
      dispatch(openModal({ key: GlobalModalKey.LoginReward }))
    } else {
      onClose()
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      motionPreset='none'
      size='sm'
      scrollBehavior='outside'
      closeOnOverlayClick
    >
      <ModalOverlay />
      <ModalContent
        pt={data?.isEventRule ? '33px' : 0}
        px={data?.isEventRule ? '0' : '5'}
        pb={data?.isEventRule ? '0' : '4'}
        sx={data?.isSuccessReward ? styles.content : undefined}
      >
        <IconButton
          as={ModalCloseButton}
          variant='unstyled'
          minW='auto'
          w='auto'
          lineHeight='0'
          icon={<Icon as={SharedIcon.Close} color='#55657E' boxSize='6' />}
          aria-label='Close'
          position='absolute'
          right='14px'
          top='14px'
        />
        {data?.isEventRule ? (
          <ModalBody>
            <div className='ck-content'>
              <Box
                overflowY='scroll'
                maxHeight={'422px'}
                style={{ padding: '0px 24px 55px 24px' }}
              >
                <div
                  style={{
                    fontWeight: 'normal',
                    color: '#606E8A',
                  }}
                  dangerouslySetInnerHTML={{ __html: data?.activityRule }}
                />
              </Box>
            </div>
          </ModalBody>
        ) : data?.isSuccessReward ? (
          <>
            <Box
              textAlign='center'
              style={{ paddingTop: 35, paddingBottom: 25 }}
            >
              <Text
                style={{ fontSize: '18px', color: '#fff', fontWeight: 'bold' }}
              >
                {t('CONTINUOUS_LOGIN_POPUP_TITTLE2')}
              </Text>
              <Text
                style={{ fontSize: '12px', color: '#fff' }}
                mt='2'
                color='four.500'
              >
                <Highlight
                  query={[amount4, amount5]}
                  styles={{
                    color: '#F9EB2D',
                  }}
                >
                  {
                    t('CONTINUOUS_LOGIN_POPUP_TEXT1', {
                      amount4,
                      amount5,
                    })!
                  }
                </Highlight>
                {/* {t('REWARD_SUCESS_DESC')}
                <b style={{ color: '#F9EB2D' }}>
                  {getSign() + data?.rewardAmount}
                </b>
                ,{t('REWARD_SUCESS_DESC_2')}
                <b style={{ color: '#F9EB2D' }}>
                  {getSign() + data?.nextDateRewardAmount}
                </b> */}
              </Text>
            </Box>
            <LazyImage
              src='/assets/images/login-reward-modal/light.png'
              height='90px'
              marginRight='-45px'
              marginTop='-27px'
              position='absolute'
              right='50%'
              top='50%'
              width='90px'
              maxWidth='initial'
              zIndex='0'
            />
            <div
              style={{
                background: 'linear-gradient(0deg, #9BEEFF 0%, #D1F5FF 100%)',
                borderRadius: '10px',
                width: '68px',
                height: '68px',
                margin: 'auto',
              }}
            >
              <LazyImage src={jb2} pt='2' mx='auto' />
              <b
                style={{
                  color: '#11645B',
                  justifyContent: 'center',
                  display: 'flex',
                  fontSize: '14px',
                }}
              >
                {getSign() + formatNumberToK(data?.rewardAmount ?? 0, 0)}
              </b>
            </div>
            <Button
              size='lg'
              display='flex'
              onClick={onClose}
              mt='6'
              mb='2'
              width='full'
              style={{
                background: '#FCA327',
              }}
            >
              <span style={{ color: '#fff' }}>{confirmBtnText}</span>
            </Button>
          </>
        ) : (
          <Box textAlign='center' style={{ paddingTop: 35 }}>
            <Text size='18px' fontWeight='bold'>
              {title}
            </Text>
            <Text size='12px' mt='2' color='#ADB6C4'>
              {data?.vip_level ? (
                <>
                  <Highlight
                    query={[amount1, amount2]}
                    styles={{
                      color: '#DD9A3C',
                      fontWeight: 'bold',
                    }}
                  >
                    {
                      t('CONTINUOUS_LOGIN_POPUP_TEXT_VIP1', {
                        amount1,
                        amount2,
                      })!
                    }
                  </Highlight>
                  {/* {t('RECHARE_LOGIN_REWARD')}
                  <b style={{ color: '#DD9A3C' }}>
                    {getSign() + data?.rechargeAmount}
                  </b>
                  {t('TITLE_CHECKIN_VIP_2')}
                  <b style={{ color: '#DD9A3C' }}>{getSign() + data?.amount}</b> */}
                </>
              ) : (
                <>
                  <Highlight
                    query={[amount3, amount2]}
                    styles={{
                      color: '#DD9A3C',
                      fontWeight: 'bold',
                    }}
                  >
                    {
                      t('CONTINUOUS_LOGIN_POPUP_TEXT_VIP0', {
                        amount3,
                        amount2,
                      })!
                    }
                  </Highlight>
                  {/* {t('RECHARE_LOGIN_REWARD')}
                  <b style={{ color: '#DD9A3C' }}>
                    {getSign() + data?.rechargeAmount}
                  </b>
                  {t('TITLE_CHECKIN_VIP_3')}
                  <b style={{ color: '#DD9A3C' }}>{getSign() + data?.amount}</b>
                  {t('TITLE_CHECKIN_VIP_4')} */}
                </>
              )}
            </Text>
            <Button
              size='lg'
              display='flex'
              onClick={onConfirm}
              mt='6'
              mb='2'
              width='full'
              colorScheme='fill-login-reward-success-confirm'
              // style={{
              //   background:
              //     'linear-gradient(to right, rgba(0, 172, 69, 1), rgba(54, 201, 114, 1))',
              // }}
            >
              <span style={{ color: '#fff' }}>{confirmBtnText}</span>
            </Button>
            {tipsRender()}
          </Box>
        )}
      </ModalContent>
    </Modal>
  )
}
