// eslint-disable-next-line simple-import-sort/imports
import { api } from '@/services/api'
import type { Response } from '@/services/types'
import type { AgentInfoResponseData } from './referralSlice'
import type {
  AnalyseData,
  InvitationRecord,
  ReferralConfig,
  ReferralTaskItem,
  ReferralUseTaskItem,
  InviteConfig,
  AnalyseV2Data,
} from './types'

export const referralApi = api.injectEndpoints({
  endpoints: builder => ({
    getStatDetail: builder.query<Response<AnalyseData>, { date: string }>({
      query: date => {
        return {
          url: '/api/v1/stat/detail',
          params: {
            ...date,
          },
          method: 'GET',
        }
      },
      providesTags: ['Auth'],
    }),
    agentAchieve: builder.query<Response<any>, { currency: string }>({
      query: data => {
        return {
          url: `/api/affilate/accomplishment/config/${data.currency}`,
          method: 'GET',
          data,
        }
      },
    }),
    getAgentCfg: builder.query<
      Response<AgentInfoResponseData>,
      { currency: string }
    >({
      query: data => {
        return {
          url: `/api/affilate/spread/config/${data.currency}`,
          method: 'GET',
          data,
        }
      },
    }),
    getWalletHistory: builder.query<
      Response<{ total: number; list: InvitationRecord[] }>,
      {
        page?: number
        pageSize?: number
        source_type?: 1 | 2 | 3 | 4
        create_time_begin?: number
        create_time_end?: number
      }
    >({
      query: ({ page = 1, pageSize = 10, ...data }) => {
        return {
          url: '/api/v1/wallet/history',
          params: {
            page,
            pageSize,
            ...data,
          },
          method: 'GET',
        }
      },
    }),
    getWalletUser: builder.query({
      query: () => {
        return {
          url: '/api/v1/wallet/user',
          method: 'get',
        }
      },
    }),

    getUserReferralTask: builder.query<Response<ReferralUseTaskItem[]>, any>({
      query: () => {
        return {
          url: '/api/v1/achievement-task/user_task_list',
          method: 'get',
        }
      },
      providesTags: ['ReferralTask'],
    }),
    getReferralTask: builder.query<Response<ReferralTaskItem[]>, any>({
      query: () => {
        return {
          url: '/api/v1/achievement-task/task_list',
          method: 'get',
        }
      },
    }),
    onClaimReferralTask: builder.mutation<Response<{ reward: number }>, number>(
      {
        query: taskId => ({
          url: `/api/v1/achievement-task/claim_reward`,
          method: 'POST',
          data: { task_id: taskId },
        }),
        invalidatesTags: ['ReferralTask'],
      },
    ),
    getReferralConfig: builder.query<Response<ReferralConfig>, any>({
      query: () => {
        return {
          url: '/api/v1/stat/reward_config',
          method: 'get',
        }
      },
    }),
    getReferralInviteConfig: builder.query<
      Response<InviteConfig[] | InviteConfig>,
      any
    >({
      query: () => {
        return {
          url: '/api/v1/stat/invite_config',
          method: 'get',
        }
      },
      providesTags: ['Auth'],
    }),
    getStatDetailV2: builder.query<Response<AnalyseV2Data>, any>({
      query: () => {
        return {
          url: '/api/v1/stat/detailv2',
          method: 'GET',
        }
      },
      providesTags: ['Auth'],
    }),
  }),
})

export const {
  useGetStatDetailQuery,
  useAgentAchieveQuery,
  useGetAgentCfgQuery,
  useGetWalletUserQuery,
  useGetWalletHistoryQuery,
  useGetUserReferralTaskQuery,
  useGetReferralTaskQuery,
  useOnClaimReferralTaskMutation,
  useGetReferralConfigQuery,
  useGetReferralInviteConfigQuery,
  useGetStatDetailV2Query,
} = referralApi
