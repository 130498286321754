export interface Response<T = unknown> {
  code: number
  message?: string
  msg: string
  data: T
}

export interface WithMessageObject {
  status: any
  message: string
}

export function isWithMessageObject(v: unknown): v is WithMessageObject {
  return (
    typeof v === 'object' &&
    v !== null &&
    'message' in v &&
    typeof v.message === 'string'
  )
}

export interface QueryError {
  status: number
  message: string
  data: unknown
}

export function isQueryError(e: unknown): e is QueryError {
  return (
    isWithMessageObject(e) &&
    'status' in e &&
    typeof e.status === 'number' &&
    'data' in e
  )
}
