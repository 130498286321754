import { pmLog } from '../pm-log'

export default function logRechargeApiFailed(reason: string): void {
  pmLog({
    eventName: 'clientmetrics_event_counter',
    payload: {
      event: 'recharge_api_failed',
      param: reason,
      step: 1,
    },
    target: {
      labels: ['event', 'param'],
      value: 'step',
    },
  })
}
