import { Flex, Icon, Link, Text } from '@chakra-ui/react'
import { AiFillCaretRight } from 'react-icons/ai'
import { Link as RouterLink } from 'react-router-dom'

const IconLinkBtn = ({
  to,
  extra,
  text,
}: {
  to: string
  extra: string | number
  text: string | number | null
}) => {
  return (
    <Flex
      h='9'
      ml='auto'
      mr='0'
      pl='3'
      pr='3'
      bgColor='bg.control'
      color='text.accent'
      borderRadius='base'
      alignItems='center'
      justifyContent='center'
      cursor='pointer'
    >
      <Link as={RouterLink} to={to} textDecoration='none' color='inherit'>
        <Text textStyle='text3' as='span' cursor='pointer'>
          {text}
        </Text>
        {extra && (
          <Text textStyle='text3' color='prim.500' as='span' cursor='pointer'>
            {` ${extra}`}
          </Text>
        )}
      </Link>
      <Icon as={AiFillCaretRight} boxSize='3' ml='1' />
    </Flex>
  )
}

export default IconLinkBtn
