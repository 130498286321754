import type { ChakraProps } from '@chakra-ui/react'
import {
  Box,
  Button,
  Center,
  Progress,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react'
import type { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import AtomEmpty from '@/components/atom-empty/AtomEmpty'
import useShushu from '@/utils/hooks/useShushu'

import type { GameListItem } from '../game/gameInterface'
import AtomGameCard from '../game/units/gamecard/AtomGameCard'
import { useNavigateToGame } from '../game/useNavigateToGame'
import { FavoriteButtonInGameCover } from './favorite-button/FavoriteButtonInGameCover'

interface GamesGridProps {
  data?: GameListItem[]
  total?: number
  onLoadMore(): void
  isLoading: boolean
  canLoadMore: boolean
  emptyText?: string
  from: 'favorite' | 'recently'
}

interface GamesCardProps
  extends Pick<ComponentProps<typeof AtomGameCard>, 'src' | 'onClick'> {
  gameId: string
  gameUuid: string
  isFavorited?: boolean
  onFavoriteClick?: (e: React.MouseEvent) => void
}

function GameCard(props: GamesCardProps) {
  const { gameId, gameUuid, src, onClick } = props

  return (
    <AtomGameCard src={src} onClick={onClick} isLazy={false}>
      <FavoriteButtonInGameCover
        gameId={gameId}
        gameUuId={gameUuid}
        onlyShowWhenHover={false}
      />
    </AtomGameCard>
  )
}

export function GameGridEmpty(
  props: ChakraProps & {
    emptyText?: string
    href?: string
    onClick?: () => void
  },
) {
  const { t } = useTranslation()
  const { emptyText, href = '/', ...restProps } = props
  const onClick: React.MouseEventHandler = e => {
    if (typeof props.onClick === 'function') {
      e.preventDefault()
      props.onClick()
    }
  }

  return (
    <Center flexDirection='column' py='24' {...restProps}>
      <AtomEmpty tips={emptyText} />
      <Button
        as={RouterLink}
        to={href}
        w='315px'
        colorScheme='prim'
        onClick={onClick}
      >
        {t('PLAY_NOW')}
      </Button>
    </Center>
  )
}

export default function GamesGrid(props: GamesGridProps) {
  const {
    data = [],
    onLoadMore,
    total,
    isLoading,
    canLoadMore = false,
    emptyText = '',
    from,
  } = props
  const { t } = useTranslation()
  const navigateToGame = useNavigateToGame()
  const { setShushuGameReportData } = useShushu()
  const onClick = (game: GameListItem) => navigateToGame(game)
  const percentage = Math.floor((data.length / (total ?? 1)) * 100)

  if (data.length === 0) {
    return <GameGridEmpty emptyText={emptyText} />
  }

  return (
    <VStack spacing='5' alignItems='stretch' w='full'>
      <SimpleGrid columns={[3, 4, 6]} rowGap='2' columnGap='2.5'>
        {data.map(item => {
          if (!item) return null
          const {
            game_cover: gameCover,
            game_name: gameName,
            game_manufacturer,
          } = item
          const gameId = String(item.game_id)
          const gameUuid = String(item?.expand?.game_uuid)

          const handleClick = () => {
            setShushuGameReportData({
              from,
              game_id: gameId,
              game_name: gameName,
              manufacturer: game_manufacturer,
            })
            onClick(item)
          }
          return (
            <GameCard
              key={gameId}
              gameId={gameId}
              gameUuid={gameUuid}
              src={gameCover}
              onClick={handleClick}
            />
          )
        })}
      </SimpleGrid>
      <Box mx='auto' w='120px'>
        <Progress value={percentage} w='full' h='1' colorScheme='prim' />
        <Box textStyle='text6' mt='1' textAlign='center'>
          <Text color='prim.500' as='span'>
            {data?.length}
          </Text>
          <span>{`/${total}`}</span>
        </Box>
      </Box>
      {canLoadMore && (
        <Button
          size='mdsm'
          mx='auto'
          onClick={onLoadMore}
          isLoading={isLoading}
          colorScheme='white'
        >
          {t('HOME_LOAD_MORE')}
        </Button>
      )}
    </VStack>
  )
}
