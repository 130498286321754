let integratedFeatSwitch = {}

/** 判断某个功能是否开放 */
export function isFeatureEnabledV2(key: string): boolean {
  return !!(integratedFeatSwitch as Record<string, boolean>)[key]
}

/*** 设置后台设置的集成开关 */
export function setIntegratedFeatSwitch(obj: Record<string, boolean>): void {
  integratedFeatSwitch = {
    ...integratedFeatSwitch,
    ...obj,
  }
}
