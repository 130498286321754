interface UserInfoProps {
  user_id: string
  user_name: string
}

export default class SaleSmartly {
  private static instance: SaleSmartly

  _isChatOpen = false

  static getInstance() {
    if (!SaleSmartly.instance) {
      SaleSmartly.instance = new SaleSmartly()
    }
    return SaleSmartly.instance
  }

  get hasInited() {
    return typeof window.ssq !== 'undefined' && window.ssq.loaded
  }

  get isChatOpen() {
    return this._isChatOpen
  }

  set isChatOpen(value) {
    this._isChatOpen = value
  }

  init() {
    this.listen()
  }

  listen() {
    if (typeof window.ssq === 'undefined') return
    window.ssq.push('onOpenChat', () => {
      this.isChatOpen = true
    })
    window.ssq.push('onCloseChat', () => {
      this.isChatOpen = false
    })
  }

  openChat(userInfo: UserInfoProps) {
    if (!this.hasInited) {
      this.init()
    }
    window.ssq?.push('chatOpen')
    try {
      userInfo?.user_id && window.ssq?.push('setLoginInfo', userInfo as any)
    } catch (e) {
      //
    }
  }

  closeChat() {
    if (typeof window.ssq === 'undefined') return
    window.ssq.push('chatClose')
  }

  toggleChat(userInfo: UserInfoProps) {
    if (this.isChatOpen) {
      this.closeChat()
    } else {
      this.openChat(userInfo)
    }
  }
}
