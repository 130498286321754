import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { createSelector } from '@reduxjs/toolkit'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import IconMPesa from '@/assets/images/wallet/m-pesa-icon.png'
import PngRechargeOrderStatus from '@/assets/images/wallet/recharge-order-status.png'
import PngRechargeOrderStatusFail from '@/assets/images/wallet/recharge-order-status-fail.png'
import PngRechargeOrderStatusSuccess from '@/assets/images/wallet/recharge-order-status-success.png'
import { getVariable } from '@/common/env'
import LazyImage from '@/components/lazy-image/LazyImage'
import TitledDialog from '@/components/modal/TitledDialog'
import { Icon as SharedIcon } from '@/utils/atom-shared'

import { useAppDispatch, useAppSelector } from '../app/store'
import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { useRecharge } from './useRecharge'
import { useRechargeStatusMutation } from './walletApi'
import { FORM_INPUT_REG } from './walletContansts'
import { selectWallet } from './walletSlice'

const PHONE_REG = FORM_INPUT_REG.phone
const PHONE_AREA_CODE = getVariable('PHONE_AREA_CODE') || '+55'
const FLAG = getVariable('FLAG')
const PHONE_INPUT_PADDING = `${
  (FLAG ? 40 : 0) + 44 + (PHONE_AREA_CODE.length - 1) * 10
}px`

export const CONFIRM_RENDER_MAP = {
  pending: {
    statusColor: 'gray.900',
    statusText: 'PAYMENG_PENDING',
    statusDetail: 'PAYMENG_PENDING_DSC',
    btnText: 'HAVE_PAID',
    statusIcon: '',
  },
  success: {
    statusColor: '#00AC41',
    statusText: 'PAYMENG_COMPLETED',
    statusDetail: 'PAYMENG_COMPLETED_DSC',
    btnText: 'COMMON_CONFIRM',
    statusIcon: PngRechargeOrderStatusSuccess,
  },
  failed: {
    statusColor: '#F43434',
    statusText: 'PAYMENG_NOT_COMPLETED',
    statusDetail: 'PAYMENG_NOT_COMPLETED_DSC',
    btnText: 'COMMON_CONFIRM',
    statusIcon: PngRechargeOrderStatusFail,
  },
}

export default function MPesaModal() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  /**
   * 手机号
   */
  const [phone, setPhone] = useState<string>()
  const [errorInput, setErrorInput] = useState<Record<string, string>>({})
  const [type, setType] = useState<'recharge' | 'confirm'>('recharge')
  /**
   * 完成支付后的订单状态
   */
  const [orderStatus, setOrderStatus] = useState<
    'pending' | 'success' | 'failed'
  >('pending')

  /**
   * 当前充值订单的id
   */
  const [orderId, setOrderId] = useState<string>('')
  const { open, data } = useAppSelector(
    createSelector(
      selectModal,
      globalModal => globalModal[GlobalModalKey.MPesaModal],
    ),
  )

  /**
   * 充值hook入参
   */
  const initRechargeHookParam = useMemo(() => {
    return data?.rechargeParams
  }, [data])

  /**
   * 充值参数获取
   */
  const rechargeParams = useAppSelector(
    createSelector(selectWallet, wallet => wallet.rechargeParams),
  )

  /** 调用充值hook */
  const {
    trigger: recharge,
    result: {
      isLoading: isRecharging,
      isSuccess: isRechargingSuccess,
      data: rechargeRes,
    },
  } = useRecharge(initRechargeHookParam)

  /**
   * 请求订单状态接口
   */
  const [getRechargeOrderStatus, { isLoading: isRechargeStatusLoading }] =
    useRechargeStatusMutation()

  const handleClose = () => {
    dispatch(closeModal({ key: GlobalModalKey.MPesaModal }))
  }

  /**
   * 请求接口拿订单状态
   */
  const handleGetRechargeOrderStatus = async () => {
    try {
      const { data } = await getRechargeOrderStatus({
        order_id: orderId,
      }).unwrap()
      setOrderStatus(
        (data as any).state === 'accepted_and_done' ? 'success' : 'failed',
      )
    } catch (error) {
      setOrderStatus('failed')
    }
  }

  const handleRecharge = () => {
    const unVerifyMap = handleValidityCheck()
    if (Object.keys(unVerifyMap).length) {
      return
    }
    recharge({
      ...rechargeParams,
      phone: phone as string,
    })
  }

  const handleDistributeFc = () => {
    if (type === 'recharge') {
      return handleRecharge()
    }

    if (type === 'confirm' && orderStatus === 'pending') {
      return handleGetRechargeOrderStatus()
    }
    handleClose()
  }

  const RechargeRender = () => {
    return (
      <>
        <Flex mb='20px'>
          <Icon as={SharedIcon.Info} boxSize='5' mr='3' color='gray.700' />
          <Text
            color='gray.900'
            textAlign='left'
            lineHeight='16px'
            fontSize='12px'
          >
            {t('SURE_MPESA')}
          </Text>
        </Flex>
        <FormControl>
          <FormLabel color='text.base' mt='4'>
            <Text as='span'>{t('AMOUNT_KES')}</Text>
          </FormLabel>
          <Input
            isDisabled
            value={initRechargeHookParam?.rechargeAmount || 0}
          />
        </FormControl>
        <FormControl isInvalid={!!errorInput?.phone}>
          <FormLabel color='text.base' mt='4'>
            <Text as='span'>{t('WALLET_PHONE_NUMBER')}</Text>
          </FormLabel>
          <InputGroup>
            <InputLeftElement
              borderRadius='6px 0 0 6px'
              left='2px'
              top='2px'
              p='0 12px'
              pointerEvents='none'
              w='max-content'
              h='calc(100% - 4px)'
              lineHeight='initial'
              color='gray.800'
              bg='gray.400'
            >
              {FLAG && (
                <LazyImage w='32px' mr='8px' src='/assets/images/flag.png' />
              )}
              <Text>{PHONE_AREA_CODE}</Text>
            </InputLeftElement>
            <Input
              paddingStart={PHONE_INPUT_PADDING}
              onChange={e => setPhone(e.target.value)}
            />
          </InputGroup>

          <FormErrorMessage textAlign='end'>
            {t(errorInput?.phone || '')}
          </FormErrorMessage>
        </FormControl>
      </>
    )
  }

  const ConfirmRender = () => {
    const { statusColor, statusText, statusDetail, statusIcon } =
      CONFIRM_RENDER_MAP[orderStatus]

    return (
      <Box
        position='relative'
        overflow='hidden'
        textAlign='center'
        bg='gray.300'
        borderRadius='15px'
        p='32px 16px 20px'
        w='calc(100% + 20px)'
        ml='-10px'
        color='gray.900'
      >
        {isRechargeStatusLoading && (
          <Flex
            justifyContent='center'
            alignItems='center'
            top={0}
            left={0}
            position='absolute'
            w='100%'
            h='100%'
            zIndex='1'
            bg='blackAlpha.500'
          >
            <Spinner m='auto' color='prim.500' />
          </Flex>
        )}
        <Box position='relative' w='72px' mx='auto'>
          <LazyImage src={PngRechargeOrderStatus} />
          {statusIcon && (
            <LazyImage
              w='30px'
              position='absolute'
              bottom='2px'
              right='-8px'
              src={statusIcon}
            />
          )}
        </Box>
        <Text
          textStyle='text4'
          m='12px 0 16px'
          fontWeight='800'
          color={statusColor}
        >
          {t(statusText)}
        </Text>
        <Text textStyle='text6'>{t(statusDetail)}</Text>
      </Box>
    )
  }

  const handleValidityCheck = () => {
    const phoneValidity = PHONE_REG({
      value: phone,
    })
    const unVerifyMap = {
      ...(phoneValidity.verify ? {} : { phone: phoneValidity.text }),
    }
    setErrorInput(unVerifyMap)
    return unVerifyMap
  }

  useEffect(() => {
    if (isRechargingSuccess) {
      setType('confirm')
      setOrderId(rechargeRes?.data?.order_id || '')
    }
  }, [isRechargingSuccess, rechargeRes])

  useEffect(() => {
    if (phone !== undefined) {
      handleValidityCheck()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone])

  return (
    <TitledDialog title='' isOpen={open} onClose={handleClose} size='sm'>
      <LazyImage mt='-4px' mb='4' width='123px' mx='auto' src={IconMPesa} />
      {type === 'recharge' ? RechargeRender() : ConfirmRender()}
      <Button
        mt='24px'
        onClick={handleDistributeFc}
        h='46px'
        w='full'
        isLoading={isRecharging || isRechargeStatusLoading}
        loadingText='Loading'
        colorScheme='fill-prim'
        _hover={{
          colorScheme: 'fill-prim',
        }}
      >
        {t(
          type === 'recharge'
            ? 'MAKE_PAYMENT'
            : CONFIRM_RENDER_MAP[orderStatus].btnText,
        )}
      </Button>
      {orderStatus === 'failed' && (
        <Flex
          mt='16px'
          alignItems='center'
          justifyContent='center'
          color='link'
        >
          <Icon as={SharedIcon.Detail} boxSize='5' mr='4px' />
          <Link
            as={RouterLink}
            to='/user/transaction-history'
            state={{ activeTab: 'recharge' }}
            textStyle='text6'
            color='link'
            onClick={() => {
              dispatch(closeModal({ key: GlobalModalKey.MPesaModal }))
              dispatch(closeModal({ key: GlobalModalKey.Wallet }))
            }}
          >
            {t('REACHRGA_TASK_HISTORY')}
          </Link>
        </Flex>
      )}
    </TitledDialog>
  )
}
