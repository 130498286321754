import { HStack, Icon, Link, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useMatch } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  createDrawerOpenSelector,
  DrawerKey,
  toggleDrawerStart,
} from '@/modules/app-layout/app-layout-slice'
import type { IIconLink } from '@/modules/app-layout/types'
import { getIcon } from '@/utils/get-icon'
import { useCustomizedStyle } from '@/utils/hooks'
import { useActionTrigger } from '@/utils/hooks/useActionTrigger'
import { getDeviceType, isSupportedImageUrl } from '@/utils/tools'

import LazyImage from '../lazy-image/LazyImage'
import type { Variant, VariantsStyles } from './constants'
import { NAME, PARTS } from './constants'

const DEVICE = getDeviceType()

export default function ActiveLink(
  props: IIconLink & { variant?: Variant; iconActive?: string },
) {
  const { action, icon, text, variant = 'primary', iconActive } = props
  const actionTrigger = useActionTrigger()
  const { t } = useTranslation()
  const matchCase = useMatch({ path: action?.href ?? '' })
  const match = action?.href ? matchCase : false
  const isLeftDrawerOpen = useAppSelector(
    createDrawerOpenSelector(DrawerKey.Left),
  )
  const dispatch = useAppDispatch()

  const variants: VariantsStyles = {
    primary: {
      container: {
        bg: 'gray.50',
        color: 'gray.900',
        '--icon-color': 'var(--chakra-colors-gray-500)',
        display: 'block',
        borderRadius: 'md',
        w: 'full',
        h: '38px',
        textDecoration: 'none',
        transition: 'none',
        _hover: {
          boxShadow: '0 0 0 2px var(--chakra-colors-prim-500)',
        },
        _activeLink: {
          layerStyle: 'ls-accent',
          color: 'white',
          '--icon-color': 'var(--chakra-colors-white)',
        },
      },
      content: {
        w: 'full',
        h: 'full',
        alignItems: 'center',
        px: '2.5',
      },
      icon: {
        boxSize: '5',
        color: 'var(--icon-color)',
      },
      text: {
        textStyle: 'text3',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    secondary: {
      container: {
        borderRadius: 'md',
        h: '38px',
        mx: '2',
        spacing: '2.5',
        bg: 'gray.50',
        color: 'gray.900',
        '--icon-color': 'var(--chakra-colors-gray-500)',
        textDecoration: 'none',
        _activeLink: {
          bg: 'prim.500',
          color: 'white',
          '--icon-color': 'var(--chakra-colors-white)',
        },
        _hover: {
          bg: 'gray.100',
          _activeLink: {
            bg: 'prim.500',
            color: 'white',
            '--icon-color': 'var(--chakra-colors-white)',
          },
        },
        transition: 'none',
      },
      content: {
        w: 'full',
        h: 'full',
        alignItems: 'center',
        px: '2.5',
      },
      icon: {
        boxSize: '5',
        color: 'var(--icon-color)',
        flexShrink: '0',
        flexBasis: '5',
      },
      text: {
        textStyle: 'text5',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  }

  const styles = useCustomizedStyle(NAME, variants[variant], PARTS, { variant })

  const contentElem = (
    <HStack sx={styles.content} spacing='2.5'>
      {isSupportedImageUrl(icon) ? (
        <LazyImage
          src={match ? iconActive ?? icon : icon}
          objectFit='contain'
          sx={styles.icon}
        />
      ) : (
        <Icon
          as={getIcon(match ? iconActive ?? icon : icon)}
          sx={styles.icon}
        />
      )}
      <Text sx={styles.text}>{t(text)}</Text>
    </HStack>
  )

  // 在app内的网页以及 iOS 平台的浏览器下，隐藏下载应用功能
  if (
    action?.eventName === 'download-app' &&
    ['ios-desktop-icon', 'apk'].includes(DEVICE)
  ) {
    return null
  }

  if (action?.href) {
    return (
      <Link
        role='group'
        sx={styles.container}
        aria-current={match ? 'page' : undefined}
        target={action?.data?.target}
        as={RouterLink}
        to={action?.href}
        onClick={e => {
          // console.log(window.location.pathname)
          if (window.location.pathname === action?.href) {
            if (isLeftDrawerOpen) {
              dispatch(toggleDrawerStart(DrawerKey.Left))
            }
          }
        }}
      >
        {contentElem}
      </Link>
    )
  } else {
    return (
      <Link
        role='group'
        sx={styles.container}
        as='button'
        onClick={actionTrigger(action)}
        variant='unstyled'
        title={t(text) ?? undefined}
      >
        {contentElem}
      </Link>
    )
  }
}
