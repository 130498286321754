import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { isConditionError } from '@/utils/rtk-query/handleError'

import { drawActivityApi } from '../api/drawActivityApi'
import { transPrizeFromBackend } from '../utils'
import type { DrawActivityState, Scene } from './types'

const initialState: DrawActivityState = {
  scene: 'normal',
  rewardPrize: null,
  isPlaying: false,
}

export const drawActivitySlice = createSlice({
  name: 'drawActivity',
  initialState,
  reducers: {
    changeScence: (state, action: PayloadAction<Scene>) => {
      const scene = action.payload
      state.scene = scene
    },
    playStart: state => {
      state.isPlaying = true
    },
    playEnd: state => {
      state.isPlaying = false
      state.scene = 'reward'
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      drawActivityApi.endpoints.drawPlay.matchFulfilled,
      (state, action) => {
        const prize = action.payload?.data?.prize
        if (!prize) return state
        state.rewardPrize = transPrizeFromBackend(prize)
      },
    )
    builder.addMatcher(
      drawActivityApi.endpoints.drawPlay.matchRejected,
      (state, action) => {
        const { error } = action
        if (!isConditionError(error.name)) {
          state.isPlaying = false
        }
      },
    )
  },
})

export const { changeScence, playStart, playEnd } = drawActivitySlice.actions

export const drawActivityReducer = drawActivitySlice.reducer
