import { Box } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'

import i18n from '@/common/locale/i18n'
import { initDynamicI18n } from '@/modules/app/initI18n'
import { useLoader } from '@/utils/hooks'

const initExtraLoad = () => {
  return [i18n.loadNamespaces(['common', 'help'])]
}

export function ExtranalLayout() {
  const [isLoading] = useLoader(initExtraLoad, initDynamicI18n)

  if (isLoading) return null

  return (
    <Box p={{ base: '2.5', md: '5' }}>
      <Outlet />
    </Box>
  )
}
