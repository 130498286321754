import type { CfgListItem } from '../types'

export interface DailyLoginRewardConfig {
  /** 日奖励（连续登录奖励）一周总和 */
  dailyRewardPerWeek: number
}

export type DailyLoginRewardConfigByVipLevel = Record<
  string,
  DailyLoginRewardConfig
>

export function getInitialDailyLoginRewardConfigByVipLevelState(): DailyLoginRewardConfigByVipLevel {
  return {}
}

export function calculateDailyLoginRewardConfigByVipLevelState(
  state: DailyLoginRewardConfigByVipLevel,
  config: CfgListItem,
): void {
  if (state[config.vip_level]) {
    state[config.vip_level].dailyRewardPerWeek += config.reward_amount
  } else {
    state[config.vip_level] = {
      dailyRewardPerWeek: config.reward_amount,
    }
  }
}
