import { usePrevious } from 'ahooks'
import { useCallback, useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { openModal, selectModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { selectUserIsLogin } from '@/modules/user/userSlice'

// 弹出登录弹出框，并等待登录完成
export function useWaitingForLogin(onLogin: () => void) {
  const [isWaitingForLogin, setIsWaitingForLogin] = useState(false)
  const dispatch = useAppDispatch()

  const isLogin = !!useAppSelector(selectUserIsLogin)
  const isLoginPrev = usePrevious(isLogin)

  const isLoginModalOpen = useAppSelector(
    state => selectModal(state)[GlobalModalKey.Login],
  )
  const isLoginModalOpenPrev = usePrevious(isLoginModalOpen)

  useEffect(() => {
    if (isLogin !== isLoginPrev && isLogin && isWaitingForLogin) {
      onLogin()
    }
  }, [dispatch, isLogin, isLoginPrev, isWaitingForLogin, onLogin])

  useEffect(() => {
    if (
      isLoginModalOpenPrev !== isLoginModalOpen &&
      !isLoginModalOpen &&
      isWaitingForLogin
    ) {
      setIsWaitingForLogin(false)
    }
  }, [isLoginModalOpen, isLoginModalOpenPrev, isWaitingForLogin])

  const startWaiting = useCallback(() => {
    dispatch(openModal({ key: GlobalModalKey.Login }))
    setIsWaitingForLogin(true)
  }, [dispatch])

  return startWaiting
}
