import { useEffect } from 'react'

import { isFeatureEnabled } from '@/common/feature'
import { getCurrencyCode } from '@/common/locale/currency'
import { getSocket } from '@/common/ws'
import { useLazyGetRechargeActListQuery } from '@/modules/activity/activityApi'
import { useLazyGetMyFirstLoginRewardQuery } from '@/modules/guide-event/guideEventApi'
import { updateBanlance } from '@/modules/user/userSlice'
import { useUserAuth } from '@/utils/hooks'

import { luckyWheelApi } from '../activity/lucky-wheel/luckyWheelApi'
import { useAppDispatch } from '../app/store'
import walletEvents from '../wallet/events'

const rechargeActivityV2 = isFeatureEnabled('rechargeActivityV2')

export function useBalanceUpdater() {
  const dispatch = useAppDispatch()
  const [GetRechargeActList] = useLazyGetRechargeActListQuery()
  const [getMyFirstLoginReward] = useLazyGetMyFirstLoginRewardQuery()
  const [userId, token] = useUserAuth()

  useEffect(() => {
    const { listen, unlisten } = getSocket()
    listen(
      'topBarBalanceUpdate',
      'balance',
      ({ balance, currency = '', ...rest }) => {
        // 推送消息币种不是当前用户绑定的币种
        const CURRENCY = getCurrencyCode()
        const { typ, pay_out, business_id } = rest
        if (typ === 'recharge') {
          walletEvents.emit('handleOpenConfirmRechargeFinishModal', {
            orderId: business_id,
          })
          updateBanlance(balance)
          if (token && userId) {
            rechargeActivityV2 && GetRechargeActList({ token, user_id: userId })
            getMyFirstLoginReward()
            dispatch(luckyWheelApi.util.invalidateTags(['luckyWheelInfo']))
          }
          return
        }

        if (
          location.pathname.includes('/game/inhouse') ||
          CURRENCY !== currency
        ) {
          return
        }
        updateBanlance(balance)
        // GMP游戏
        // if (location.pathname.includes('/game/self') && pay_out > 0) {
        //   dispatch(
        //     toggleBalanceFloat({
        //       balance: pay_out,
        //     }),
        //   )
        // }
      },
    )

    return () => {
      unlisten('topBarBalanceUpdate', 'balance')
    }
  }, [userId, token])
}
