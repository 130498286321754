import { useMemo } from 'react'

import { getCurrencyCode } from '@/common/locale/currency'
import { useAppSelector } from '@/modules/app/store'
import type { Balance, CurrencyType } from '@/modules/user/types'
import { updateBanlance } from '@/modules/user/userSlice'

const CURRENCY = getCurrencyCode() as CurrencyType

export interface UserInfo {
  userId?: string
  userToken?: string
  userName?: string
  banlance?: Balance
  avatarId?: string
}

export function useUserInfo() {
  const { isLoggedIn, token, userId, user } = useAppSelector(
    state => state.user,
  )

  const userInfo = useMemo<UserInfo>(
    () => ({
      userId,
      userToken: token,
      userName: user?.nick_name,
      banlance: user?.balance_map[CURRENCY],
      avatarId: user?.avatar,
    }),
    [token, userId, user],
  )

  const getIsLogin = (u: UserInfo): u is Required<UserInfo> => {
    return isLoggedIn
  }

  return { userInfo, getIsLogin, updateBanlance, isLogin: isLoggedIn }
}

export default useUserInfo
