import { useEffect, useMemo } from 'react'

import { useAppSelector } from '@/modules/app/store'
import { selectUserIsLogin } from '@/modules/user/userSlice'

import {
  useGetDrawActivityInfoQuery,
  useGetDrawActivityListQuery,
} from './api/drawActivityApi'
import type { DrawpbDrawInfoRsp } from './types'
import { transDrawpbDrawInfoRsp } from './utils'

export function useDrawActivityData({ isOpen }: { isOpen: boolean }) {
  const isLogin = useAppSelector(selectUserIsLogin)
  const listQueryResult = useGetDrawActivityListQuery(undefined, {
    skip: !isLogin,
  })

  useEffect(() => {
    if (isOpen) {
      listQueryResult.refetch()
    }
  }, [isOpen])

  const listData = useMemo(
    () =>
      listQueryResult.data?.data?.list
        ?.filter(item => item.state !== 0)
        .sort(({ weight: a = 0 }, { weight: b = 0 }) => b - a)
        .slice(0, 3) ?? [],
    [listQueryResult.data],
  )

  const [drawAct1, drawAct2, drawAct3] = listData

  const activityInfoQueryResult1 = useGetDrawActivityInfoQuery(
    { task_id: drawAct1?.task_id },
    { skip: !drawAct1 },
  )

  const activityInfoQueryResult2 = useGetDrawActivityInfoQuery(
    { task_id: drawAct2?.task_id },
    { skip: !drawAct2 },
  )

  const activityInfoQueryResult3 = useGetDrawActivityInfoQuery(
    { task_id: drawAct3?.task_id },
    { skip: !drawAct3 },
  )

  const data = useMemo(() => {
    const allData = [
      activityInfoQueryResult1.data,
      activityInfoQueryResult2.data,
      activityInfoQueryResult3.data,
    ].filter(Boolean)
    if (listData.filter(Boolean).length === allData.length) {
      return allData
        .map(res => res?.data as DrawpbDrawInfoRsp)
        .map(transDrawpbDrawInfoRsp)
    } else {
      return undefined
    }
  }, [
    activityInfoQueryResult1.data,
    activityInfoQueryResult2.data,
    activityInfoQueryResult3.data,
    listData,
  ])

  const isLoading = useMemo(() => {
    return [
      listQueryResult.isLoading,
      activityInfoQueryResult1.isLoading,
      activityInfoQueryResult2.isLoading,
      activityInfoQueryResult3.isLoading,
    ].some(Boolean)
  }, [
    listQueryResult.isLoading,
    activityInfoQueryResult1.isLoading,
    activityInfoQueryResult2.isLoading,
    activityInfoQueryResult3.isLoading,
  ])

  const result = useMemo(() => ({ data, isLoading }), [data, isLoading])
  return result
}
