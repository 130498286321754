import Platform from '@/common/platform'

import { EventName, EventNameUtils } from '../const'
import { isTrackFirstRechargeData, isTrackRegisterData } from './event'
import type { Channel, EventMap, ITrackerBase } from './types'

type NativeTrackArgs = {
  key: string
  value: string | number
  currency?: string
  extras?: { [key: string]: string | number }
}

export class TrackerAdjustNative implements ITrackerBase {
  channel: Channel = { name: 'adjust', id: '' }

  eventMap: EventMap = {
    [EventName.ContentView]: ['PAGE_VIEW'], // 浏览
    [EventName.CompleteRegistration]: ['REGISTER'], // 注册
    [EventName.CompletePurchase]: ['FIRST_RECHARGE'], // 充值
  }

  get isAndroid() {
    return !!window.AndroidWebView
  }

  trackSingleEvent(
    event: string,
    originEventName: EventName,
    data?: any,
  ): void {
    const args: NativeTrackArgs = { key: event, value: '' }
    if (EventNameUtils.isLoginOrRegister(originEventName)) {
      if (isTrackRegisterData(data) && this.isAndroid) {
        args.value = data.user_id
        args.extras = data
      }
    } else if (EventNameUtils.isFirstRecharge(originEventName)) {
      args.value = data?.value
      args.currency = data?.currency
      if (isTrackFirstRechargeData(data) && this.isAndroid) {
        args.extras = {
          user_id: data.user_id,
          hig_one: data.hig_one ?? '',
          order_id: data.order_id,
        }
      }
    }
    Platform?.firebaseEvent(args)
  }
}
