import { getDeviceType } from '@/utils/tools'

import type { DiversionItemProps } from '../app/home-page-config-types'
import { STATUS_TYPE } from '../app/home-page-config-types'
import { useAppDispatch } from '../app/store'
import { openModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'

const DEVICE = getDeviceType()
export default function useADDModalControl(
  diversionModalCfg?: DiversionItemProps,
) {
  const dispatch = useAppDispatch()
  const handleAddModalShow = () => {
    if (
      diversionModalCfg?.status === STATUS_TYPE.ON &&
      diversionModalCfg?.language_content &&
      !['apk', 'ios-desktop-icon'].includes(DEVICE)
    ) {
      dispatch(
        openModal({
          key: GlobalModalKey.AppDownloadDiversion,
          data: { modalCfg: diversionModalCfg },
        }),
      )
      return true
    }

    return false
  }
  return {
    handleAddModalShow,
  }
}
