import { useMemo } from 'react'

import { setShushuGameReportData } from '@/common/tracker/shushu'

export default function useShushu() {
  return useMemo(
    () => ({
      setShushuGameReportData,
    }),
    [],
  )
}
