import { setShushuGameReportData } from '@/common/tracker/shushu'

import type { GameListItem } from '../gameInterface'

export function trackToGameEvt(item: GameListItem, labelId = -1, from = '') {
  setShushuGameReportData({
    game_id: item.game_id,
    game_name: item.game_name,
    manufacturer: item.game_manufacturer,
    from: from,
    label_id: labelId,
  })
}

export function trackHomeToGameEvt(item: GameListItem, labelId = -1) {
  trackToGameEvt(item, labelId, 'home-list')
}

export function trackAllGamesToGameEvt(item: GameListItem, labelId = -1) {
  trackToGameEvt(item, labelId, 'inside-list')
}
