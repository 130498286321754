//更换绑定

//
import { useToast } from '@chakra-ui/react'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from '@/modules/app/store'
import { isQueryError } from '@/services/types'

import { useUpdateBindUserPhoneMutation } from './bindPhoneApi'
import {
  setCode,
  setCountDownTime,
  setModalType,
  setShowCountDown,
} from './bindPhoneSlice'
import { VERIFY_CODE_MESSAGE } from './constants'
import type { ChangePhoneType } from './type'
import { ModalType } from './type'
//取消绑定
export const useUpdateBindPhone = () => {
  const [updateBindPhone, updateBindPhoneResult] =
    useUpdateBindUserPhoneMutation()
  const {
    error: updateError,
    isSuccess: updateSuccess,
    isLoading: updateLoading,
  } = updateBindPhoneResult
  const { t } = useTranslation()
  const toast = useToast()

  const dispatch = useAppDispatch()
  //
  const userUpdateBindPhone = useCallback(
    async (params: ChangePhoneType) => {
      try {
        await updateBindPhone(params).unwrap()
      } catch (error) {
        throw new Error('network error')
      }
    },
    [updateBindPhone],
  )
  useEffect(() => {
    //获取失败提示
    if (updateError && isQueryError(updateError)) {
      const text =
        t(VERIFY_CODE_MESSAGE[updateError?.status]) || t('NETWORK_EXCEPTION')
      toast({ status: 'error', title: t(text), duration: 5000 })
      return
    }
    // 核销成功获取成功  跳到changesuccess
    if (updateSuccess) {
      sessionStorage.removeItem('last_phone')
      dispatch(setCountDownTime(0))
      dispatch(setShowCountDown(false))
      dispatch(setCode(''))
      dispatch(setModalType(ModalType.rebindsuccess))
    }
  }, [updateError, updateSuccess, t, toast, dispatch])

  return {
    userUpdateBindPhone,
    updateLoading,
  }
}
