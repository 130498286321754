import { keyBy } from 'lodash-es'

import type {
  CategoryLabelListItem,
  GameListItem,
  ManulacturerListItem,
  SortGame,
} from '../gameInterface'
import { isValidGame } from './getGameData'
import { sortGame2 } from './sortGame'

export type CatesKeyByLabelId = Record<number, CategoryLabelListItem>
export type CatesKeybyLabelType = Record<1 | 2, CategoryLabelListItem[]>

interface GetSortedCateReturn {
  list: Array<CategoryLabelListItem>
  catesKeyByLabelId: CatesKeyByLabelId
  catesKeybyLabelType: CatesKeybyLabelType
}

/**
 * 过滤掉未开启的tab栏以及不在约定label中的: 已经经过排序
 * @param data
 * @returns
 */
export default function getSortedCate(
  cates: CategoryLabelListItem[],
  games: GameListItem[],
  enableManusKeybyId: Record<number, ManulacturerListItem>,
): GetSortedCateReturn {
  const gamesKeybyId = keyBy(games, 'game_id')

  const _cates = cates?.slice()
  _cates.sort((a, b) => a.sort_weight - b.sort_weight)
  const list: GetSortedCateReturn['list'] = []

  const catesKeybyLabelType: GetSortedCateReturn['catesKeybyLabelType'] = {
    1: [],
    2: [],
  }
  const catesKeyByLabelId: GetSortedCateReturn['catesKeyByLabelId'] = {}

  for (const item of _cates) {
    const _labelType = item['label_type']
    const labelId = item['label_id']

    if (item.is_enabled === 1 && item.display_games === 1) {
      const _item = {
        ...item,
        supportCurrency: ['ALL'],
        data: [] as GameListItem[],
        name: item.label_name,
        icon: item.icon,
        providerSort:
          item?.provider_sort?.reduce((arr: ManulacturerListItem[], cur) => {
            if (enableManusKeybyId?.[cur.game_id]) {
              return [...arr, enableManusKeybyId[cur.game_id]]
            }
            return arr
          }, []) || [],
      }
      const _list = [] as GameListItem[]
      const _data = item.game_sort?.slice() as GameListItem[]

      _data?.sort((a, b) => a.sort_weight - b.sort_weight)
      _data?.forEach(subItem => {
        if (isValidGame(gamesKeybyId[subItem.game_id])) {
          // 非下线的游戏
          _list.push({
            ...subItem,
            ...gamesKeybyId[subItem.game_id],
          })
        }
      })
      _item.data = sortGame2(_list)
      list.push(_item)
      catesKeybyLabelType[_labelType].push(_item)
      catesKeyByLabelId[labelId] = _item
    }
  }

  return { list, catesKeyByLabelId, catesKeybyLabelType }
}
