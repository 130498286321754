import { Button, Center, Text } from '@chakra-ui/react'
import { createSelector } from '@reduxjs/toolkit'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import TitledDialog from '@/components/modal/TitledDialog'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { getOpen } from '@/modules/bind-phone/bindPhoneSlice'
import {
  changeAmountModal,
  getShowVirtualModal,
  selectWallet,
} from '@/modules/wallet/walletSlice'

import { closeModal, selectModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'

export default function WalletModal() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { open: isOpen } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.WalletGiftTips],
  )
  const showVirtualModal = useAppSelector(getShowVirtualModal)
  const isParamsDialogOpen = useAppSelector(
    state => selectWallet(state).isParamsDialogOpen,
  )
  const isOpenBindPhoneModal = useAppSelector(getOpen)

  const { open: isOpenBindInfoModal } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.BindInfoModal],
  )

  const { open: isGcashPaymentModalOpen } = useAppSelector(
    createSelector(selectModal, globalModal => globalModal.GcashPaymentModal),
  )

  // 关闭弹窗
  const onClose = useCallback(() => {
    dispatch(closeModal({ key: GlobalModalKey.WalletGiftTips }))
  }, [dispatch])

  /** 点击 */
  const onClick = useCallback(() => {
    onClose()
    dispatch(changeAmountModal())
  }, [])

  return (
    <>
      <TitledDialog
        title={''}
        isOpen={
          isOpen &&
          !showVirtualModal &&
          !isParamsDialogOpen &&
          !isOpenBindPhoneModal &&
          !isOpenBindInfoModal &&
          !isGcashPaymentModalOpen
        }
        onClose={onClose}
        colorScheme='multilayer'
        variant={{ base: 'round', md: 'round' }}
      >
        <Center mb={'20px'}>
          <Text>{t('WITHDRAW_INSUFFICIENT')}</Text>
        </Center>
        <Button
          size='lg'
          w='full'
          onClick={onClick}
          colorScheme='fill-prim'
          h='46px'
        >
          {t('DETAILS')}
        </Button>
      </TitledDialog>
    </>
  )
}
