import { useContext } from 'react'

import { getFeatureFlag, isFeatureEnabled } from '@/common/feature'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import {
  selectServiceEntryConfig,
  toggleLiveChatWigetVisibility,
} from '@/modules/app-layout/app-layout-slice'
import { HomePageConfigContext } from '@/modules/app-layout/AppLayout'
import { GlobalModalKey } from '@/modules/global-modal/types'

import { openModal } from '../global-modal/globalModalSlice'

const serviceListTgLink = getFeatureFlag('serviceListTgLink')

export const useWalletService = () => {
  const dispatch = useAppDispatch()
  const { rechargeModalServiceType } = useAppSelector(selectServiceEntryConfig)

  function useHomePageConfigFloatButtons() {
    const homePageConfig = useContext(HomePageConfigContext)
    return homePageConfig?.floatButtons || []
  }
  const FloatButtons = useHomePageConfigFloatButtons()
  // 悬浮按钮是否配置了客服列表
  const haveServiceList = FloatButtons.some(
    item => item?.action?.eventName === 'service-list',
  )

  return () => {
    if (isFeatureEnabled('newServiceList')) {
      // 新版客服列表
      if (rechargeModalServiceType === 'livechat') {
        dispatch(toggleLiveChatWigetVisibility())
      } else {
        dispatch(openModal({ key: GlobalModalKey.ServiceList }))
      }
    } else {
      // 前端代码是否有客服TG链接配置（需要市场提供，未提供就是不需要）
      if (haveServiceList && !!serviceListTgLink) {
        // 配置了打开客服列表
        dispatch(openModal({ key: GlobalModalKey.ServiceList }))
      } else {
        // 没配置打开客服列表，默认打开live_chat
        dispatch(toggleLiveChatWigetVisibility())
      }
    }
  }
}
