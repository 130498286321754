import { Box, Button, Grid, Text, useDisclosure } from '@chakra-ui/react'
import { t } from 'i18next'
import { useMemo } from 'react'

import service from '@/assets/images/service.png'
import LazyImage from '@/components/lazy-image/LazyImage'
import TitledDialog from '@/components/modal/TitledDialog'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { toggleLiveChatWigetVisibility } from '@/modules/app-layout/app-layout-slice'
import {
  closeModal,
  openModal,
  selectModal,
} from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import ForgetPwdModal from '@/modules/new-login/components/ForgetPwdModal'
import { selectEnablePhoneVerifyLogin } from '@/modules/new-login/loginAndSignUpAuthConfigSlice'
import { AccountType } from '@/modules/new-login/loginApi'
import { getFeatures } from '@/utils/tools'
import { isAllNumber } from '@/utils/validform'

import { LoginModalTab } from '../../LoginModalTab'
import { selectIsEnablePhoneVerifyLogin } from '../newLoginAndSignUpConfigSlice'

const loginAndSignUpV3 = getFeatures('loginAndSignUpV3')

function WrongPasswordModal() {
  const { open, data } = useAppSelector(
    state => selectModal(state)[GlobalModalKey.WrongPassword],
  )
  const dispatch = useAppDispatch()
  const isEnablePhoneVerifyLogin = useAppSelector(
    selectIsEnablePhoneVerifyLogin,
  )

  const isEnablePhoneVerifyLoginV3 = useAppSelector(
    selectEnablePhoneVerifyLogin,
  )
  const {
    isOpen: isPwdForgetModalOpen,
    onClose: handleClosePwdForgetModal,
    onOpen: handleOpenPwdForgetModal,
  } = useDisclosure()

  const { account = '' } = data as { account: string }

  const handleClose = () => {
    dispatch(
      closeModal({
        key: GlobalModalKey.WrongPassword,
      }),
    )
  }

  const liveChatClick = () => {
    dispatch(toggleLiveChatWigetVisibility())
  }

  const serviceClick = () => {
    if (!loginAndSignUpV3) {
      return dispatch(openModal({ key: GlobalModalKey.ResetPassword }))
    }

    if (loginAndSignUpV3) {
      return handleOpenPwdForgetModal()
    }
  }

  const ServiceButton = ({
    w,
    colorKey = 'prim',
  }: {
    w?: string
    colorKey?: string
  }) => {
    return (
      <Button
        w={w}
        textStyle='h6'
        bg={`${colorKey}.500`}
        _active={{
          bg: `${colorKey}.500`,
        }}
        color='white'
        px='11px'
        py='14px'
        fontSize='14px'
        _hover={{
          bg: `${colorKey}.500`,
        }}
        onClick={serviceClick}
      >
        {t('USER_FINDPWD')}
      </Button>
    )
  }

  const LiveChatButton = ({ w }: { w?: string }) => {
    return (
      <Button
        textStyle='h6'
        bg='prim.500'
        w={w}
        _active={{
          bg: 'prim.500',
        }}
        color='white'
        fontSize='14px'
        _hover={{
          bg: 'prim.500',
        }}
        onClick={liveChatClick}
      >
        {t('GAME_LOAD_SERVICE')}
      </Button>
    )
  }

  const ToPhoneVerifyLoginButton = () => {
    return (
      <Button
        textStyle='h6'
        bg='seco.500'
        _active={{
          bg: 'seco.500',
        }}
        color='white'
        fontSize='14px'
        _hover={{
          bg: 'seco.500',
        }}
        onClick={() => {
          handleClose()
          dispatch(
            openModal({
              key: GlobalModalKey.Login,
              data: {
                index: LoginModalTab.SignIn,
                tab: 'phone',
                defaultInput: account,
              },
            }),
          )
        }}
      >
        {t('LOGIN_VERIFY')}
      </Button>
    )
  }

  const enablePhoneVerifyLogin = useMemo(() => {
    return (
      (!loginAndSignUpV3 && isEnablePhoneVerifyLogin) ||
      (loginAndSignUpV3 && isEnablePhoneVerifyLoginV3)
    )
  }, [isEnablePhoneVerifyLogin, isEnablePhoneVerifyLoginV3])

  const ShowButton = ({ account }: { account: string }) => {
    if (account.includes('@')) {
      return (
        <>
          <ServiceButton
            w='full'
            colorKey={loginAndSignUpV3 ? 'prim' : 'seco'}
          />
          {!loginAndSignUpV3 && <LiveChatButton />}
        </>
      )
    } else {
      if (isAllNumber(account)) {
        if (!loginAndSignUpV3) {
          return (
            <>
              {enablePhoneVerifyLogin && <ToPhoneVerifyLoginButton />}
              <LiveChatButton />
            </>
          )
        }

        return (
          <>
            {enablePhoneVerifyLogin && <ToPhoneVerifyLoginButton />}
            <ServiceButton />
          </>
        )
      }
    }
    return null
  }

  const buttonNumber = useMemo(() => {
    if (account.includes('@')) {
      return loginAndSignUpV3 ? 1 : 2
    }
    if (isAllNumber(account)) {
      return enablePhoneVerifyLogin ? 2 : 1
    }
  }, [account, enablePhoneVerifyLogin])
  return (
    <TitledDialog
      title={t('EXCHANGE_TITLE')}
      isOpen={open}
      onClose={handleClose}
    >
      <Text textAlign='center'>
        {t(
          !account.includes('@') && enablePhoneVerifyLogin
            ? 'WRONG_REGISTER1'
            : 'WRONG_REGISTER',
        )}
      </Text>
      <Grid
        templateColumns={`repeat(${buttonNumber},1fr)`}
        gap='1.5'
        w='full'
        mt='10'
      >
        <ShowButton account={account} />
      </Grid>

      {loginAndSignUpV3 && (
        <Box w='full' textAlign='center' mb='-16px'>
          <Box
            m='16px auto 0'
            display={'inline-flex'}
            alignItems={'center'}
            as='button'
            onClick={liveChatClick}
          >
            <LazyImage
              src={service}
              mr='5px'
              w='24px'
              h='24px'
              cursor='pointer'
              alt=''
            />
            <Box
              color={'#1376FA'}
              fontSize={'12px'}
              textDecoration={'underline'}
            >
              {t('MENU_ONLINE_SERVICE')}
            </Box>
          </Box>
        </Box>
      )}

      {isPwdForgetModalOpen && (
        <ForgetPwdModal
          accountValue={account}
          accountType={
            account.includes('@') ? AccountType.AT_EMAIL : AccountType.AT_PHONE
          }
          isOpen={isPwdForgetModalOpen}
          onClose={handleClosePwdForgetModal}
        />
      )}
    </TitledDialog>
  )
}

export default WrongPasswordModal
