import {
  Box,
  type SystemStyleObject,
  useRadio,
  type UseRadioProps,
} from '@chakra-ui/react'
import type { FC, PropsWithChildren } from 'react'

import type { RadioValues } from './AmountControl'

interface RadioBoxProps extends PropsWithChildren, UseRadioProps {
  sx?: SystemStyleObject
}

export const RadioBox: FC<
  RadioBoxProps &
    SystemStyleObject & {
      onclick?: (v: RadioValues) => void
    }
> = ({ children, sx, mb, onclick, ...rest }) => {
  const { getInputProps, getRadioProps } = useRadio(rest)
  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Box as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        sx={{ ...sx, mb }}
        onClick={() => onclick && onclick(input.value)}
      >
        {children}
      </Box>
    </Box>
  )
}
