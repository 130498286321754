import type { ComponentWithAs, IconProps } from '@chakra-ui/react'
import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useClipboard,
  useToast,
} from '@chakra-ui/react'
import type { MouseEventHandler } from 'react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useCustomizedStyle } from '@/utils/hooks'

export default function CopyInput(props: {
  text: string
  variant?: React.ComponentProps<typeof Input>['variant']
  colorScheme?: React.ComponentProps<typeof Input>['colorScheme']
  size?: React.ComponentProps<typeof Input>['colorScheme']
  type?: string
  icon?: ComponentWithAs<'svg', IconProps>
  cb?: () => void
  showEdit?: boolean
  onEdit?: () => void
  valueClick?: () => void
}) {
  const {
    text,
    variant,
    colorScheme,
    size,
    type = 'copy',
    cb,
    showEdit,
    onEdit,
    valueClick,
    icon,
  } = props
  const { t } = useTranslation()
  const styles = useCustomizedStyle(
    'CopyInput',
    {
      field: {
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflow: 'hidden',
        pr: showEdit ? '16' : '10',
      },
    },
    ['field'],
    { variant, colorScheme, size },
  )
  const toast = useToast()
  const { onCopy } = useClipboard(text)
  const handleCopy = useCallback(
    (e: any) => {
      e.preventDefault()
      e.stopPropagation()
      switch (type) {
        case 'copy':
          onCopy()
          toast({
            title: t('COMMON_CONTENT_CANCEL'),
            status: 'success',
          })
          break
        case 'edit':
          cb && cb()
          break
        case 'click':
          cb && cb()
          break
      }
    },
    [toast, onCopy, t, type, cb],
  )

  return (
    <InputGroup
      size={size}
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        valueClick && valueClick()
      }}
    >
      <Input
        readOnly={true}
        value={text}
        variant={variant}
        cursor='default'
        sx={styles.field}
        pointerEvents='none'
      />
      <InputRightElement w='auto' right='2.5'>
        {showEdit && (
          <Icon
            as={SharedIcon.Edit}
            boxSize='4'
            mr='3'
            color='prim.500'
            cursor='pointer'
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              onEdit && onEdit()
            }}
          />
        )}
        <IconButton
          onClick={handleCopy}
          colorScheme='prim'
          borderRadius='6px'
          minW='7'
          h='7'
          size='xs'
          icon={
            <Icon
              boxSize={4}
              as={icon || (type === 'edit' ? SharedIcon.Edit : SharedIcon.Copy)}
            />
          }
          aria-label='copy'
        />
      </InputRightElement>
    </InputGroup>
  )
}
