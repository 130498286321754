import { extendTheme } from '@chakra-ui/react'
import { withProse } from '@nikolovlazar/chakra-ui-prose'

import tokenColors from '@/websites/current/styles/color/tokenColors.json'
import colors from '@/websites/current/styles/colors.json'
import global from '@/websites/current/styles/global.json'
import layerStyles from '@/websites/current/styles/layerStyles.json'
import proseStyles from '@/websites/current/styles/proseStyles.json'
import radii from '@/websites/current/styles/radius.json'
import semanticColors from '@/websites/current/styles/semanticColors.json'
import textStyles from '@/websites/current/styles/textStyles.json'

import components from './components'

const theme = extendTheme(
  {
    styles: {
      global,
    },
    colors,
    radii,
    textStyles,
    layerStyles,
    components,
    semanticTokens: {
      colors: {
        ...semanticColors,
        ...tokenColors,
      },
    },
  },
  withProse(proseStyles),
)

export default theme
