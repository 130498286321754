import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react'
import { type FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import maintenanceImage from '@/assets/images/maintenance-image.png'
import { getVariable } from '@/common/env'
import { getCurrencyCode } from '@/common/locale/currency'
import LazyImage from '@/components/lazy-image/LazyImage'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import type { GameListItem } from '@/modules/game/gameInterface'
import { selectGameList } from '@/modules/game/gameSlice'
import Game from '@/modules/game/third-game/Game'
import ThirdPartGameBox from '@/modules/game/third-game/ThirdPartGameBox'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { selectUserIsLogin } from '@/modules/user/userSlice'
import SpinLoading from '@/routes/Loading'
import { useMobile } from '@/utils/hooks'
import useUserInfo from '@/utils/hooks/useUserInfo'

import { useLiveGameInfoMutation, useSlotGameInfoMutation } from './api'

const SlotGames = ['doubleSpeed'] as const
export type SlotGameType = (typeof SlotGames)[number]

export function isValidGame(game: string): boolean {
  return Array.prototype.includes.call(SlotGames, game)
}

const defaultCategory = 'roulette'

const OperateAction: FC<{
  onClick: (type: number) => void
}> = ({ onClick }) => {
  return (
    <Box height='50px'>
      <Button
        colorScheme='red'
        size='md'
        mr={50}
        onClick={() => {
          onClick(0)
        }}
      >
        Play
      </Button>
      <Button
        colorScheme='blue'
        size='md'
        onClick={() => {
          onClick(1)
        }}
      >
        Demo
      </Button>
    </Box>
  )
}

/** 视频展示区 */
const VideoShow: FC<{ videoSrc: string; isMobile: boolean }> = ({
  videoSrc,
  isMobile,
}) => {
  return <Game gameUrl={videoSrc} isMobile={isMobile} />
}

/** loading展示 */
export const Loading: FC = () => {
  //
  return (
    <Box
      position='absolute'
      w='100%'
      h='100%'
      left='0'
      top='0px'
      display='flex'
      justifyContent='center'
      alignItems='center'
      style={{
        touchAction: 'none',
      }}
    >
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='blue.400'
        size='xl'
      />
    </Box>
  )
}

/** 系统正在维护 */
export const Maintenance: FC<{ error?: string }> = ({ error }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const goHome = () => {
    navigate('/')
  }
  return (
    <Flex
      direction='column'
      justifyContent='center'
      alignItems='center'
      textAlign='center'
      py='40'
      px='5'
    >
      <LazyImage src={maintenanceImage} alt='' boxSize='20' mb='6' />
      <Text textStyle='text4' color='gray.900' mb='8' w='full' maxW='xs'>
        {error || t('LIVE_URL_FAIL')}
      </Text>
      <Button
        colorScheme='fill-prim'
        onClick={goHome}
        size='lg'
        w='full'
        maxW='xs'
      >
        {t('Home')}
      </Button>
    </Flex>
  )
}

/** 渲染组件 */
const SlotAndLiveGame: FC = () => {
  const isLogin = !!useAppSelector(selectUserIsLogin)
  const [slotGameInfo] = useSlotGameInfoMutation()
  const [liveGameInfo] = useLiveGameInfoMutation()
  const { userInfo } = useUserInfo()
  const params = useParams()
  const gameList = useAppSelector(selectGameList)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const gameType = params?.gameType
  const game_id = params?.gameId // 游戏里面的id
  const curLiveGame = useMemo(() => {
    if (game_id) {
      const curLiveGame = gameList.find(
        item => item.game_id === Number(game_id),
      )
      return (curLiveGame || {}) as GameListItem
    } else {
      return {} as GameListItem
    }
  }, [gameList, game_id])

  const isMobile = useMobile()

  const pathname = location.pathname

  const [isShowGame, setShowGame] = useState(false)
  const [videoSrc, setVideoSrc] = useState('')
  const [isError, setIsError] = useState(false) // 请求接口是否错误
  const [isLoading, setLoading] = useState(false)
  /** 获取slot游戏资源信息 */
  const fetchSlotOrLiveRes = useCallback(
    async (params: object, type: string) => {
      setLoading(true)
      try {
        const func = type === 'live' ? liveGameInfo : slotGameInfo
        const { data } = await func(params).unwrap()
        if (type === 'slot' && data?.link) {
          setVideoSrc(data?.link)
        } else if (type === 'live' && (data?.entry || data?.entryEmbedded)) {
          setVideoSrc(isMobile ? data?.entry || '' : data?.entryEmbedded || '')
        } else {
          setIsError(true)
        }
        setShowGame(!isShowGame)
      } catch (e) {
        setIsError(true)
        setShowGame(!isShowGame)
      }
      setLoading(false)
    },
    [isMobile, isShowGame, liveGameInfo, slotGameInfo],
  )
  useEffect(() => {
    if (!isLogin) {
      navigate('/')
      setTimeout(() => {
        dispatch(openModal({ key: GlobalModalKey.Login }))
      }, 1000)
      return
    }
    // 如果是live游戏，进入页面就播放视频
    if (gameType === 'live' || pathname === '/live') {
      const game = curLiveGame?.expand?.game_uuid || defaultCategory
      const live_params = getVariable('LIVE_PARAMS') || {}
      const params = {
        // ...getGameParams(),
        token: userInfo?.userToken || '',
        user_id: userInfo?.userId || '',
        game,
        channel_mobile: isMobile,
        channel_wrapped: !isMobile,
        currency: getCurrencyCode()?.toLowerCase(),
        ...live_params,
        game_id,
      }
      fetchSlotOrLiveRes(params, 'live')
    }
  }, [
    gameType,
    game_id,
    gameList,
    pathname,
    userInfo?.userId,
    userInfo?.userToken,
    isMobile,
    curLiveGame,
    isLogin,
    // fetchSlotOrLiveRes,
  ])

  /**
   * 演示/播放按钮
   */
  const onDemoOrPlay = useCallback(
    (demo: number) => {
      const exit_url = `http://${window.location.host}`
      const params = {
        token: userInfo?.userToken || '',
        user_id: userInfo?.userId || '',
        game_id,
        // game: curLiveGame?.expand?.game_uuid || '',
        // currency: getCurrencyCode() || '',
        data: {
          game: curLiveGame?.expand?.game_uuid || '',
          demo,
          exit_url,
        },
      }
      fetchSlotOrLiveRes(params, 'slot')
    },
    [
      curLiveGame?.expand?.game_uuid,
      fetchSlotOrLiveRes,
      userInfo?.userId,
      userInfo?.userToken,
    ],
  )

  const GameContent = useMemo(() => {
    if (isLoading) {
      return <SpinLoading />
    }
    if (isShowGame || gameType === 'live' || pathname === '/live') {
      if (isError) {
        return <Maintenance />
      } else {
        return <Game gameUrl={videoSrc} isMobile={isMobile} />
      }
    } else {
      return (!isLoading && <OperateAction onClick={onDemoOrPlay} />) || null
    }
  }, [
    gameType,
    isError,
    isLoading,
    isShowGame,
    onDemoOrPlay,
    pathname,
    videoSrc,
    isMobile,
  ])

  if (isValidGame('none')) {
    return <div>error page: invalid game</div>
  } else {
    return (
      <Box pt={9}>
        <ThirdPartGameBox gameUrl={videoSrc} customGameContent={GameContent} />
      </Box>
    )
  }
}

export default SlotAndLiveGame
