import { EventName } from '../const'
import type { Channel, ITrackerBase } from './types'

export class TrackerKwai implements ITrackerBase {
  channel: Channel

  eventMap = {
    [EventName.ContentView]: ['contentView'], // 浏览
    [EventName.CompleteRegistration]: ['completeRegistration'], // 注册
    [EventName.CompletePurchase]: ['purchase'], // 充值
  }

  constructor(private channelId: string) {
    this.channel = { name: 'kwaiq', id: channelId }
  }

  trackSingleEvent(
    event: string,
    _originEventName: EventName,
    data?: any,
  ): void {
    kwaiq.instance(this.channelId).track(event, data)
  }
}
