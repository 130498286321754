import { Box, Image, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { gsap } from 'gsap'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useRef } from 'react'

import entryBg from '@/assets/images/money-rain/entry_bg.png'
import moneyTop from '@/assets/images/money-rain/money_top.png'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { openModal } from '@/modules/global-modal/globalModalSlice'
import { GlobalModalKey } from '@/modules/global-modal/types'
import { selectUserIsLogin } from '@/modules/user/userSlice'
import { projectName } from '@/websites/current/property.json'

import useMoneyRain from '../hooks/useMoneyRain'
import {
  selectNextAct,
  selectStartingAct,
  selectUserDrawCount,
} from '../store/monyRainSlice'
import type { MoneyRainAct } from '../types/moneyRainTypes'

function PhiluckyMoneyRainEntryButton() {
  const bigPrizeRef = useRef<HTMLImageElement>(null)
  const moneyTopRef = useRef<HTMLImageElement>(null)

  const bigPrizeTextRef = useRef<HTMLDivElement>(null)
  const moneyTopTextRef = useRef<HTMLDivElement>(null)

  const { handleShowMoneyRainRulesModal, handleShowMoneyRainGameModal } =
    useMoneyRain()
  const isUserLogin = useAppSelector(selectUserIsLogin)

  const userDrawCount = useAppSelector(selectUserDrawCount)
  const startingAct = useAppSelector(selectStartingAct)
  const nextAct = useAppSelector(selectNextAct)
  const dispatch = useAppDispatch()

  const actStatus = useMemo(() => {
    if (startingAct && userDrawCount === 0) {
      return 'starting'
    }
    if (nextAct) {
      return 'noStart'
    }
    return 'noAct'
  }, [nextAct, startingAct, userDrawCount])

  const getStatusRenderContent = useCallback(
    (data: MoneyRainAct | undefined) => {
      switch (actStatus) {
        case 'starting':
          return t('EVENT_CASHRAIN_BEGIN')
        case 'noStart':
          return data ? (
            <Box>
              <Text>{t('EVENT_CASHRAIN_NEXTTIME')}</Text>
              <Text>{dayjs(data.start_time * 1000).format('HH:mm')}</Text>
            </Box>
          ) : null
        case 'noAct':
          return <Text mt='-2px'>{t('EVENT_CASHRAIN_TITTLE1')}</Text>
      }
    },
    [actStatus],
  )

  const renderData: MoneyRainAct | undefined = useMemo(() => {
    if (startingAct && userDrawCount === 0) {
      return startingAct
    }
    if (nextAct) {
      return nextAct
    }
    return undefined
  }, [nextAct, startingAct, userDrawCount])

  function startImageAnimationSequence() {
    // 创建一个新的时间线
    const tl = gsap.timeline({
      repeat: -1, // 无限重复
    })

    // 第一个动画
    tl.fromTo(
      bigPrizeRef.current,
      { autoAlpha: 1, scale: 1, y: 0, display: 'block' },
      {
        autoAlpha: 0,
        y: 10,
        scale: 0.5,
        duration: 0.3,
        delay: 1.5,
        ease: 'power1.inOut',
        display: 'none',
      },
    )
      // 第二个动画，紧接第一个动画结束后开始
      .fromTo(
        moneyTopRef.current,
        {
          autoAlpha: 0,
          scale: 0.5,
          y: 10,
          display: 'none',
        },
        {
          autoAlpha: 1,
          scale: 1,
          y: 0,
          duration: 0.3,
          ease: 'power1.inOut',
          display: 'block',
        },
      )
      .to(moneyTopRef.current, {
        autoAlpha: 0,
        scale: 0.5,
        y: 10,
        duration: 0.3,
        ease: 'power1.inOut',
        display: 'none',
        delay: 1.5,
      })
      .fromTo(
        bigPrizeRef.current,
        {
          autoAlpha: 0,
          scale: 0.5,
          y: 10,
          display: 'none',
        },
        {
          autoAlpha: 1,
          y: 0,
          scale: 1,
          duration: 0.3,
          ease: 'power1.inOut',
          display: 'block',
        },
      )
  }

  function startTextAnimationSequence() {
    const tl = gsap.timeline({
      repeat: -1, // 无限重复
    })

    // 第一个动画
    tl.fromTo(
      bigPrizeTextRef.current,
      { y: 0, display: 'block', autoAlpha: 1 },
      {
        y: 10,
        autoAlpha: 0,
        display: 'none',
        duration: 0.3,
        delay: 1.5,
        ease: 'power1.inOut',
      },
    )
      // 第二个动画，紧接第一个动画结束后开始
      .fromTo(
        moneyTopTextRef.current,
        {
          autoAlpha: 0,
          display: 'none',
          y: 10,
        },
        {
          autoAlpha: 1,
          display: 'block',
          duration: 0.3,
          ease: 'power1.inOut',
          y: 0,
        },
      )
      .to(moneyTopTextRef.current, {
        autoAlpha: 0,
        display: 'none',
        duration: 0.3,
        ease: 'power1.inOut',
        delay: 1.5,
        y: 10,
      })
      .fromTo(
        bigPrizeTextRef.current,
        {
          autoAlpha: 0,
          display: 'none',
          y: 10,
        },
        {
          autoAlpha: 1,
          display: 'block',
          duration: 0.3,
          y: 0,
          ease: 'power1.inOut',
        },
      )
  }

  useEffect(() => {
    startImageAnimationSequence()
    startTextAnimationSequence()
  }, [])

  return (
    <Box
      w='66px'
      h='76px'
      cursor='pointer'
      onClick={() => {
        if (!isUserLogin) {
          dispatch(
            openModal({
              key: GlobalModalKey.Login,
            }),
          )
          return
        }
        const now = dayjs().unix().valueOf()
        if (renderData) {
          if (now < renderData.start_time || userDrawCount !== 0) {
            handleShowMoneyRainRulesModal()
          } else {
            handleShowMoneyRainGameModal()
          }
        } else {
          handleShowMoneyRainRulesModal()
        }
      }}
    >
      {/* 图片区域 */}
      <Box
        width='60px'
        margin='0 auto'
        height='25px'
        position='relative'
        zIndex='1'
        cursor='pointer'
      >
        <Image
          src='/assets/images/money-rain/big_prize.png'
          position='absolute'
          top='0'
          ref={bigPrizeRef}
          objectFit='cover'
          //   display='none'
        />
        <Image
          src={moneyTop}
          position='absolute'
          top='0'
          ref={moneyTopRef}
          display='none'
          objectFit='cover'
        />
      </Box>
      <Box
        position='relative'
        zIndex='2'
        w='66px'
        h='51'
        cursor='pointer'
        marginTop='-2px'
        bg={`url(${entryBg}) no-repeat`}
        bgSize='cover'
        display='flex'
        bgPos='center'
        alignItems='center'
        justifyContent='center'
      >
        <Text
          fontSize='11px'
          color='white'
          fontWeight='800'
          textAlign='center'
          ref={bigPrizeTextRef}
        >
          {t('EVENT_CASHRAIN_TITTLE1')}
        </Text>

        <Box
          fontSize='11px'
          color='white'
          fontWeight='800'
          textAlign='center'
          ref={moneyTopTextRef}
          display='none'
          cursor='pointer'
        >
          {getStatusRenderContent(renderData)}
        </Box>
      </Box>
    </Box>
  )
}

export default PhiluckyMoneyRainEntryButton
