export interface Reward {
  /**
   *Amount of coding
   */
  bet_target?: number

  /**
   *Amount of gold coins
   */
  num?: number

  /**
   *Currency type
   */
  currency?: string

  /**
   *Reward type
   */
  type?: string
  /**
   *Reward validity period
   */
  expire_day?: number
}

export interface Message {
  /**
   *Button copy
   */
  button_name?: string
  /**
   *Message time
   */
  created_at?: number
  /**
   *Message guid
   */
  guid?: string
  /**
   *Whether it has been read
   */
  is_read?: number
  /**
   *Jump/click address
   */
  jump_url?: string
  /**
   *Message multilingual key
   */
  message_key?: string
  /**
   *Message parameters
   */
  params?: Record<string, string | number>
  /**
   *Template message guid
   */
  template_guid?: string
  /**
   *Message title key
   */
  title_key?: string

  /**
   *Message version 0: old version (system site message) 1: new version (customized site message)
   */
  message_version?: number

  /**
   *Whether the reward has been claimed 0 Not claimed 1 Received
   */
  is_claim_rewards?: number

  /**
   *Reward list
   */
  rewards?: Reward

  /**
   *Display type 0 None 1 Jump link 2 Reward
   */
  show_type?: number

  /**
   *Message content
   */

  message?: Record<string, Record<string, string>>

  /**
   *Validity period of on-site message storage (in days)
   */
  effect_time?: number

  /**
   * whether first recharge message
   */
  is_first_recharge?: number
}

export enum MessageShowType {
  None = 0,
  Jump = 1,
  Reward = 2,
}
