import { useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getVariable } from '@/common/env'
import { getCurrencyCode } from '@/common/locale/currency'
import { trackClickRecharge } from '@/common/tracker'
import { deviceMap } from '@/modules/activity/type'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { useRechargeMutation } from '@/modules/wallet/walletApi'
import {
  addVirtualOrder,
  getJoinActFlag,
  getQuestType,
  getVirtualOrderList,
  openParamsModal,
  selectIsFromAct,
  selectRechargeAmount,
  setCurrentVirtualItem,
  setHandleType,
  setQrcodeInfo,
  setQuestType,
  setRechargedAmount,
  setRechargeFormParams,
  setShowFullPageLoading,
  switchVirtualModal,
} from '@/modules/wallet/walletSlice'
import { isQueryError } from '@/services/types'
import { useUserAuth } from '@/utils/hooks/useUserAuth'
import { localStorage } from '@/utils/localStorage'
import { getDeviceType, getFeatures, isNotLink } from '@/utils/tools'

import { openModal } from '../global-modal/globalModalSlice'
import { GlobalModalKey } from '../global-modal/types'
import { setTipsInfo } from '../recharge-activity/rechargeActSlice'
import { TipsModalStatus } from '../recharge-activity/type'
import { RechargeErrorFromType, RechargeQuestType } from './interface'
import useRechargeActivity from './useRechargeActivity'
import { useRechargeWays } from './useRechargeWays'
import { handlePIXSubmitInfo } from './utils'
import {
  CODE_MAP,
  delay,
  MAX_RETYR_COUNT,
  rechargeErrorCode,
} from './walletContansts'

const RECHARGE_ERROR_TIPS = getVariable('RECHARGE_ERROR_TIPS')
const thirdPaymentEmbedding = getFeatures('thirdPaymentEmbedding')

const device = getDeviceType()

type RechargeData = {
  [key: string]: string | number
}

export const useRecharge = (options?: {
  rechargeAmount?: number
  taskId?: string
  taskGift?: number
  giftId?: number
}) => {
  const dispatch = useAppDispatch()
  const { userSaveInfoUpdate, paramasErrorHandal } = useRechargeWays()
  const [userId, token] = useUserAuth()
  const is_from_recharge_act = useAppSelector(selectIsFromAct)
  const _rechargeAmount = useAppSelector(selectRechargeAmount)
  const rechargeAmount = is_from_recharge_act
    ? _rechargeAmount
    : options?.rechargeAmount ?? _rechargeAmount
  const taskId = options?.taskId || ''
  const taskGift = options?.taskGift || -1
  const giftId = options?.giftId || -1
  const virtualOrderList = useAppSelector(getVirtualOrderList)
  const joinActFlag = useAppSelector(getJoinActFlag)
  const questType = useAppSelector(getQuestType)
  const toast = useToast()
  const { t } = useTranslation()
  const [recharge, result] = useRechargeMutation()
  const { isLoading, error: rechargeError } = result
  const { getActItemByAmount, actId } = useRechargeActivity()
  //重试
  const [errorCount, setErrorCount] = useState(1)
  const [errorParams, setErrorParams] = useState({} as RechargeData)
  //区分常规充值和充值活动充值
  const [errorFrom, setErrorFrom] = useState(RechargeErrorFromType.Normal)

  const trigger = useCallback(
    async (data: RechargeData, from = RechargeErrorFromType.Normal) => {
      if (
        isLoading ||
        Object.keys(data).length === 0 ||
        (errorCount >= MAX_RETYR_COUNT && questType === RechargeQuestType.retry)
      )
        return
      // return
      setErrorParams(data)
      setErrorFrom(from)
      const info = getActItemByAmount(Number(rechargeAmount))
      const maxBenefitTaskId = joinActFlag ? String(info?.id || '') : '-1'
      const _task_id = is_from_recharge_act ? actId : taskId || maxBenefitTaskId
      //if GCASHORIGIN
      const IS_GCASHORIGIN_RECHARGE =
        `${data?.typ}`?.toLocaleUpperCase() === 'GCASHORIGIN'
      const params: Parameters<typeof recharge>[0] = {
        user_id: userId,
        token,
        currency: getCurrencyCode(),
        amount: rechargeAmount.toString(),
        task_id: _task_id || '',
        device: deviceMap[device] || '',
        ...handlePIXSubmitInfo(data),
        data: handlePIXSubmitInfo(data),
        ...(taskGift > 0 || giftId > 0
          ? {
              task_gift: taskGift > 0 ? taskGift : giftId,
              gift_type: taskGift > 0 ? 1 : 2,
            }
          : {}),
      }
      try {
        /**
         * 点击充值埋点
         */
        trackClickRecharge()
        const result = await recharge(params).unwrap()
        const {
          order_id,
          pay_method: { cashier, virtual_account, code_img },
        } = result.data
        if (
          IS_GCASHORIGIN_RECHARGE &&
          cashier &&
          !isNotLink(cashier) &&
          !['ios-desktop-icon', 'ios'].includes(device)
        ) {
          dispatch(
            openModal({
              key: GlobalModalKey.GcashPaymentModal,
              data: { orderId: order_id, cashier },
            }),
          )
          return
        }
        dispatch(setRechargedAmount(params.amount))
        if (cashier && !isNotLink(cashier)) {
          if (
            thirdPaymentEmbedding &&
            !['ios-desktop-icon', 'ios'].includes(device)
          ) {
            dispatch(
              openModal({
                key: GlobalModalKey.GcashPaymentModal,
                data: { orderId: order_id, cashier },
              }),
            )
            return
          }
          window.location.href = cashier
        }
        // 二维码
        if (code_img) {
          userSaveInfoUpdate(data)
          dispatch(
            setQrcodeInfo({
              ...data,
              qrcode: code_img,
            }),
          )
        }
        if (virtual_account) {
          const order = {
            order_id,
            start_time: new Date().valueOf() + 1000 * 60 * 30,
            status: 'pending',
            user_id: userId,
            amount: params.amount,
            typ: params.data.typ || '',
            ...virtual_account,
          }
          dispatch(addVirtualOrder(order))
          dispatch(setCurrentVirtualItem(order))
          dispatch(switchVirtualModal())
          localStorage.setItem(
            'virtualOrderList',
            JSON.stringify([...virtualOrderList, order]),
          )
        }
      } catch (error) {
        if (isQueryError(error)) {
          dispatch(setQuestType('retry'))
        }
      } finally {
        if (errorFrom === RechargeErrorFromType.RecharageAct)
          sessionStorage.setItem(
            'recahrge_act_time',
            String(new Date().valueOf()),
          )
      }
    },
    [
      isLoading,
      errorCount,
      questType,
      getActItemByAmount,
      rechargeAmount,
      joinActFlag,
      taskId,
      userId,
      token,
      recharge,
      userSaveInfoUpdate,
      dispatch,
      virtualOrderList,
      errorFrom,
      taskGift,
      is_from_recharge_act,
      giftId,
    ],
  )

  const _error_retry = useCallback(async () => {
    dispatch(setQuestType(RechargeQuestType.request))
    if (
      questType === RechargeQuestType.request &&
      errorCount >= MAX_RETYR_COUNT
    ) {
      setErrorCount(1)
      return
    }
    if (
      rechargeError &&
      errorCount < MAX_RETYR_COUNT &&
      questType === RechargeQuestType.retry
    ) {
      dispatch(
        setShowFullPageLoading({
          loading: true,
          text: 'WAIT_RECHARGE',
        }),
      )
      await delay()
      trigger(errorParams, errorFrom)
      setErrorCount(pre => pre + 1)
    }
    if (errorCount >= MAX_RETYR_COUNT && !isLoading) {
      //全屏loading
      dispatch(
        setShowFullPageLoading({
          loading: false,
          text: '',
        }),
      )
      if (errorFrom === RechargeErrorFromType.Normal) {
        if (Number(rechargeError?.status) === 1001) {
          paramasErrorHandal()
        }
        // 只有B系列有下面的提示
        if (RECHARGE_ERROR_TIPS && rechargeErrorCode?.[rechargeError?.status]) {
          toast({
            status: 'error',
            title: t(rechargeErrorCode?.[rechargeError?.status] || ''),
          })
          dispatch(setHandleType('recharge'))
          dispatch(
            setRechargeFormParams({
              data: {
                ...errorParams,
              },
            }),
          )
          dispatch(openParamsModal())
          return
        }
        const text =
          t(CODE_MAP[rechargeError?.status]) || t('NETWORK_EXCEPTION')
        toast({ status: 'error', title: t(text) })
      }
      if (errorFrom === RechargeErrorFromType.RecharageAct) {
        dispatch(
          setTipsInfo({
            open: true,
            type: TipsModalStatus.fail,
          }),
        )
      }
      await delay(100)
      setErrorCount(1)
    }
  }, [
    questType,
    errorCount,
    rechargeError,
    isLoading,
    dispatch,
    trigger,
    errorParams,
    errorFrom,
    t,
    toast,
    paramasErrorHandal,
  ])

  useEffect(() => {
    if (questType === 'retry') _error_retry()
  }, [questType, errorCount])

  return { trigger, result }
}
