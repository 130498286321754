import type { InformationListProps, RuleListProps } from './interface'

export const informationList: InformationListProps[] = [
  {
    title: `Número CLABE`,
    key: 'account_no',
  },
  {
    title: 'Codigo Bancario',
    key: 'provider',
  },
  {
    title: `Nombre del Beneficiario`,
    key: 'name',
  },
]

export const informationListSpei2: InformationListProps[] = [
  {
    title: `Número CLABE`,
    key: 'account_no',
  },
]

export const ruleList: RuleListProps = {
  title: `Proceso`,
  rules: [
    {
      text: `Paso 1: Por favor, No realice transferencias múltiples al recargar, para evitar retrasos en la recarga.`,
    },
    {
      text: `Paso 2: Copia la información de la CLABE que se te asignó.`,
    },
    {
      text: `Paso 3: Entra a la app de tu banco, pega la información de la CLABE, luego completa el monto de tu recarga y paga.`,
    },
    {
      text: `Paso 4: Compruebe si has recibido el pago. (Puede haber algún retraso, si aún no lo has recibido, vuelva a comprobarlo más tarde o póngase en contacto con el servicio de atención al cliente).`,
    },
  ],
}

export const ruleListSpei2: RuleListProps = {
  title: `Proceso`,
  rules: [
    {
      text: `Paso 1: Copia la información de la CLABE que se te asignó.`,
    },
    {
      text: `Paso 2: Ingrese a la APP de tu banco, pegue la información de la CLABE, luego ingrese el monto de su recarga y pague. (Tenga en cuenta que la cantidad de recarga debe ser la misma que la cantidad pedida, de lo contrario se producirá un fallo de recarga;)`,
    },
    {
      text: `Paso 3: Compruebe si has recibido el pago. (Puede haber algún retraso, si aún no lo has recibido, vuelva a comprobarlo más tarde o póngase en contacto con el servicio de atención al cliente).`,
    },
  ],
}
