import { Box, Image } from '@chakra-ui/react'
import { useContext, useMemo } from 'react'

import AppIcon from '@/websites/current/public/assets/images/about-icon/app-icon.png'
import CustomerService from '@/websites/current/public/assets/images/customer-service.png'

import type { Message } from '../../types'
import { MessageShowType } from '../../types'
import NewMessageContext from './NewMessageContext'
import NewMessageReward from './NewMessageReward'

const NormalIcon = ({ message }: { message: Message }) => {
  const iconSrc = useMemo(() => {
    if (message.title_key === 'RECHARGE_SUCCESS_TITLE') {
      return CustomerService
    }
    return AppIcon
  }, [message.title_key])
  return <Image src={iconSrc} w='10' h='10' borderRadius='4px' />
}

function NewMessageIcon() {
  const { message } = useContext(NewMessageContext)
  const isMessageHasReward = message.show_type === MessageShowType.Reward

  return (
    <>
      {!isMessageHasReward ? (
        <NormalIcon message={message} />
      ) : (
        <NewMessageReward message={message} />
      )}
    </>
  )
}

export default NewMessageIcon
