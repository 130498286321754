import './GameCardExtraText.scss'

const GameCardExtraText = ({ text }: { text: string | number }) => {
  return (
    <div className='extra-text-wrapper'>
      <div className='content'>
        <span>{text}</span>
      </div>
    </div>
  )
}

export default GameCardExtraText
