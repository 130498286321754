import type { ImageProps, SystemStyleObject } from '@chakra-ui/react'
import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Grid,
  HStack,
  Icon,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useInViewport } from 'ahooks'
import type { FC, PropsWithChildren } from 'react'
import {
  createRef,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import gcIcon from '@/assets/images/gc-icon.png'
import gcNewIcon from '@/assets/images/gc-new-icon.svg'
import loterj from '@/assets/images/nice-loterj.png'
import { getVariable } from '@/common/env'
import { isFeatureEnabled } from '@/common/feature'
import LazyImage from '@/components/lazy-image/LazyImage'
import { HomePageConfigContext } from '@/modules/app-layout/AppLayout'
import { getIcon } from '@/utils/get-icon'
import { useMobile } from '@/utils/hooks'
import { useActionTrigger } from '@/utils/hooks/useActionTrigger'
import useShushu from '@/utils/hooks/useShushu'
import { getConfigLng } from '@/utils/tools'
import appConfig from '@/websites/current/pages/layout/app-config.json'

import type { IBaseLink, IImageLink, ILinkGroup } from './types'
import { isFeatureEnabledV2 } from '@/common/featureSwiitch'

interface FooterProps {
  aboutCompany?: string
}

const { linkGroups = [], sponsors = [] } = appConfig.footerNav
const isBottomRecommend = isFeatureEnabled('bottomRecommend')

const lng = getConfigLng()

function _Footer(props: FooterProps) {
  const { aboutCompany = '' } = props
  const isMobile = useMobile()
  const { t } = useTranslation()
  const ref = createRef<HTMLDivElement>()
  const [inView] = useInViewport(ref)
  const [exist, setExist] = useState(!!inView)
  const gcLink = getVariable('GC_URl')
  function useHomePageConfigBottomRecommend() {
    const homePageConfig = useContext(HomePageConfigContext)
    return homePageConfig?.bottomRecommend || []
  }
  const bottomRecommend = useHomePageConfigBottomRecommend()
  const links = useMemo(() => {
    return linkGroups.map((link, index) => {
      if (index === 0 && isBottomRecommend) {
        const info = {
          key: 'footer-games-1',
          title: 'FOOTER_GAMES',
          children: bottomRecommend.map(item => {
            const { title } = item?.language_content?.[lng] || {}
            return {
              key: item?.id,
              action: item?.action || {},
              text: title || '',
            }
          }),
        }
        return <Group key={info.key} data={info} isConfig={true} />
      }
      return <Group key={link.key} data={link} />
    })
  }, [bottomRecommend])

  useEffect(() => {
    if (inView && !exist) {
      setExist(true)
    }
  }, [inView, exist])

  if (!exist)
    return (
      <Box
        position='relative'
        ref={ref}
        pt={['4', '4', '8']}
        pb={{ base: '28', md: '10' }}
        w='full'
        overflow='hidden'
        bg='bg.appLayout.footer'
      />
    )
  const onGoToGc = () => {
    if (!gcLink) return
    window.open(gcLink)
  }
  return (
    <Box
      ref={ref}
      pt={['4', '4', '8']}
      pb={{ base: '28', md: '10' }}
      w='full'
      position='relative'
      overflow='hidden'
      bg='bg.appLayout.footer'
    >
      <Container maxWidth='container.lg'>
        <HStack
          alignItems='flex-start'
          justifyContent='space-between'
          flexWrap='wrap'
          spacing='0'
        >
          <HStack
            flex='1 1'
            alignItems='flex-start'
            justifyContent='space-between'
            minWidth={['full', 'sm']}
            mb={['5', '5', '8']}
          >
            {links}
            {!isMobile ? (
              <Flex
                flexWrap='wrap'
                justifyContent='center'
                gap='2.5'
                width='50%'
                flexDirection='column'
              >
                <Text textStyle='text3' color='text.secondary'>
                  {t('COOPERATION')}
                </Text>
                <Grid
                  gridTemplateColumns='repeat(5, 1fr)'
                  gridRowGap='7'
                  marginTop='4'
                >
                  {sponsors.map(item => (
                    <FooterImageLink
                      {...item}
                      key={item.key}
                      width='22'
                      height='8'
                    />
                  ))}
                </Grid>
              </Flex>
            ) : null}
          </HStack>

          {isMobile ? (
            <VStack width='full'>
              <Text textStyle='text3' color='text.secondary' width='full'>
                {t('COOPERATION')}
              </Text>
              <Grid
                gridTemplateColumns='repeat(5, 1fr)'
                gridRowGap={['1', '1', '7']}
                marginTop={['0', '0', '4']}
                width='full'
              >
                {sponsors.map(item => (
                  <FooterImageLink
                    {...item}
                    key={item.key}
                    width='16'
                    height='8'
                  />
                ))}
              </Grid>
            </VStack>
          ) : null}
        </HStack>

        <Divider my={['3', '3', '4']} borderColor='gray.600' opacity='0.2' />

        {isMobile ? (
          <>
            <HStack>
              <Center
                border='1px'
                borderRadius='full'
                color='seco.500'
                boxSize='7'
              >
                <Icon as={getIcon('Over18')} boxSize='5' />
              </Center>
              <LazyImage
                src={isFeatureEnabledV2('enable_new_cert_text') ? gcNewIcon : gcIcon}
                width='20'
                objectFit='fill'
                marginLeft='2'
                noLazy={false}
                onClick={onGoToGc}
              />
              {isFeatureEnabledV2('enable_new_footer_text') && (
                <LazyImage
                  src={loterj}
                  width='64px'
                  objectFit='fill'
                  marginLeft='2'
                  noLazy={false}
                />
              )}
            </HStack>
            <Text
              textStyle='text6'
              color='text.base'
              marginTop='3'
              dangerouslySetInnerHTML={{
                __html: t(aboutCompany)!,
              }}
              sx={{ '& > a': { color: 'prim.500' } }}
            />
          </>
        ) : (
          <HStack alignItems='center'>
            <HStack
              flexBasis={{
                lg: '22%',
                md: '30%',
              }}
              flexShrink='0'
            >
              <Center
                border='1px'
                borderRadius='full'
                color='seco.500'
                boxSize='10'
              >
                <Icon as={getIcon('Over18')} boxSize='7' />
              </Center>
              {isFeatureEnabledV2('enable_new_footer_text') ? (
                <>
                  <LazyImage
                    src={isFeatureEnabledV2('enable_new_cert_text') ? gcNewIcon : gcIcon}
                    width='24'
                    objectFit='fill'
                    marginLeft='8px'
                    noLazy={false}
                    cursor={gcLink ? 'pointer' : ''}
                    onClick={onGoToGc}
                  />
                  <LazyImage
                    src={loterj}
                    width='52px'
                    objectFit='fill'
                    marginLeft='8px'
                    noLazy={false}
                  />
                </>
              ) : (
                <LazyImage
                  src={isFeatureEnabledV2('enable_new_cert_text') ? gcNewIcon : gcIcon}
                  width='24'
                  objectFit='fill'
                  marginLeft='5'
                  noLazy={false}
                  cursor={gcLink ? 'pointer' : ''}
                  onClick={onGoToGc}
                />
              )}
            </HStack>
            <Text
              textStyle='text6'
              color='text.base'
              textAlign='left'
              flexGrow='1'
              dangerouslySetInnerHTML={{
                __html: t(aboutCompany)!,
              }}
              sx={{ '& > a': { color: 'prim.500' } }}
            />
          </HStack>
        )}
      </Container>
    </Box>
  )
}

const styles: SystemStyleObject = {
  textAlign: 'left',
  color: 'gray.600',
  // cursor: action ? 'point' : 'default',
  cursor: 'default',
  textDecoration: 'none',
  _hover: {
    background: 'none',
  },
}

function _FooterLink(
  props: IBaseLink & {
    onClick?: () => void
  },
) {
  const { t } = useTranslation()
  const { action, onClick } = props
  const text = t(props.text)

  if (action?.href) {
    return (
      <Link
        as={RouterLink}
        to={action.href}
        title={text}
        sx={styles}
        cursor='pointer'
        onClick={onClick}
      >
        <Text textStyle='text6' cursor='pointer' color='text.base'>
          {text}
        </Text>
      </Link>
    )
  }

  return (
    <ButtonLink action={action} onClick={onClick}>
      <Text textStyle='text6' cursor='pointer' color='text.base'>
        {text}
      </Text>
    </ButtonLink>
  )
}
const FooterLink = memo(_FooterLink)

const ButtonLink: FC<
  PropsWithChildren<{
    action: any
    onClick?: () => void
  }>
> = ({ action, children, onClick }) => {
  const actionTrigger = useActionTrigger()

  const action_a = actionTrigger(action)
  const handleClick = (e: any) => {
    action_a(e)
    onClick && onClick()
  }

  return (
    <Button
      onClick={handleClick}
      variant='unstyled'
      title={children as string}
      sx={styles}
    >
      {children}
    </Button>
  )
}

type FooterImageLinkProps = IImageLink &
  Pick<ImageProps, 'width' | 'height' | 'w' | 'h'>

function FooterImageLink(props: FooterImageLinkProps) {
  const { action, imageSrc, width, height } = props
  const actionTrigger = useActionTrigger()
  const { t } = useTranslation()
  const text = t(props.text)
  const contentElem = (
    <LazyImage
      src={imageSrc}
      alt={text}
      width={width}
      height={height as string}
      loading='lazy'
      noLazy={false}
      objectFit='scale-down'
    />
  )

  const styles: SystemStyleObject = {
    cursor: action ? 'point' : 'default',
    _hover: {
      background: 'none',
    },
  }

  if (action?.href) {
    return (
      <Link as={RouterLink} to={action.href} title={text} sx={styles}>
        {contentElem}
      </Link>
    )
  } else {
    return (
      <Button
        onClick={actionTrigger(action)}
        variant='unstyled'
        title={text}
        sx={styles}
      >
        {contentElem}
      </Button>
    )
  }
}

const Footer = memo(_Footer)

export default Footer

const Group: FC<{ data: ILinkGroup; isConfig?: boolean }> = ({
  data,
  isConfig,
}) => {
  const { t } = useTranslation()
  const { setShushuGameReportData } = useShushu()

  return (
    <VStack
      key={data.key}
      alignItems='flex-start'
      flex='1'
      spacing={['1.5', '1.5', '2']}
    >
      <Text textStyle='text3' color='text.secondary'>
        {t(data.title)}
      </Text>
      <VStack alignItems='flex-start' spacing={['1.5', '1.5', '2']}>
        {data.children.map((item, index) => {
          const handleClick =
            data.key === 'footer-games-1'
              ? () => {
                  setShushuGameReportData({
                    from: 'button-nav',
                    game_name: item.key!,
                    game_id: item.key!,
                    manufacturer: 'inhouse',
                  })
                }
              : undefined
          if (isConfig) {
            return (
              <ButtonLink
                action={item?.action || {}}
                key={index}
                onClick={handleClick}
              >
                <Text textStyle='text6' cursor='pointer' color='text.base'>
                  {item?.text || ''}
                </Text>
              </ButtonLink>
            )
          } else {
            return <FooterLink key={item.key} {...item} onClick={handleClick} />
          }
        })}
      </VStack>
    </VStack>
  )
}
