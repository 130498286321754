import type { PayloadAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash-es'

import store from './store'

export const generateAutoActions = <T>(actions: any): T => {
  const dispatch = () => {
    return store.dispatch
  }

  const result = {} as any
  Object.keys(actions).forEach(ac => {
    result[ac] = function (payload: any) {
      dispatch()(actions[ac](payload))
    }
  })

  return result
}

export interface UpdateAtType {
  path: string
  data: unknown
}

export function updateAt<S>(state: S, action: PayloadAction<UpdateAtType>) {
  const { path, data } = action.payload
  if (!path) return data as any
  const _path = path.split('.')
  let next = state as any

  for (let i = 0, len = _path.length; i < len; i++) {
    if (i !== _path.length - 1) {
      if (!next[_path[i]]) {
        next[_path[i]] = {}
      }
      next = next[_path[i]]
    } else {
      next[_path[i]] = cloneDeep(data)
    }
  }
  return state
}
