import { useAppDispatch, useAppSelector } from '../app/store'
import ParamsModal from './paramsModal'
import { closeParamsModal, selectWallet } from './walletSlice'

export default function ParamsModalControl() {
  const dispatch = useAppDispatch()

  const isParamsDialogOpen = useAppSelector(
    state => selectWallet(state).isParamsDialogOpen,
  )

  return (
    <ParamsModal
      open={isParamsDialogOpen}
      onClose={() => dispatch(closeParamsModal())}
    />
  )
}
