import { keyframes } from '@chakra-ui/react'

const refresh = keyframes`
0% {  transform: rotate(0) }
50% {  transform: rotate(180deg)}
100% {  transform:  rotate(360deg)}
`
export const refreshAnimation = `${refresh} 2s infinite linear`

const amount = keyframes`
  0% {  transform:translate(-50%,-50%) rotate(0) }
  50% {  transform: translate(-50%,-50%) rotate(180deg)}
  100% {  transform: translate(-50%,-50%) rotate(360deg)}
`
export const amountAnimation = `${amount} 5s infinite linear`
